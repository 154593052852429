import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SideBarContainer } from './style/Sidebar.styles';
import Logo from '../../../../assest/images/signup_login_logo.svg';
import Banner from '../../../../assest/images/signin-login-pic.svg';
import { useTranslation } from 'react-i18next';

const SideBar = () => {
  const { t } = useTranslation(['common']);

  const navigate = useNavigate();
  return (
    <SideBarContainer>
      <nav>
        <div className="logo-container">
          <img src={Logo} alt="Lectie Logo" onClick={() => navigate('/')} />
        </div>
        <ul className="nav-ul">
          <li>
            <Link to="/pricing"> {t('Pricing')} </Link>
          </li>
          <li>
            <Link to="/contact"> {t('Contact us')} </Link>
          </li>
          <li>
            <Link to="/faq"> FAQ </Link>
          </li>
        </ul>
      </nav>

      <div className="banner-container">
        <img src={Banner} alt="Login Signup banner" />
      </div>

      <div className="signup-login-text">
        <h3>{t('Share')}</h3>
        <p>
          {t(
            'Calendar recipients are able to download and access them easily.'
          )}
        </p>
      </div>
    </SideBarContainer>
  );
};

export default SideBar;
