import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
const initialState = {
  yearPicked: null,
  templateName: '',
  customColors: [
    {
      id: 1,
      color: '#FFDAC0',
      label: 'Birthday',
      customId: 1,
    },
    {
      id: 2,
      color: '#FFEDAC',
      label: 'Anniversary ',
      customId: 2,
    },
    { id: 3, color: '#BAFFC1', label: 'Meetings', customId: 3 },
    { id: 4, color: '#B4DFFF', label: 'Work out', customId: 4 },
    { id: 5, color: '#FDB8B8', label: 'Home work', customId: 5 },
    { id: 6, color: '#FFCEEC', label: 'Holiday', customId: 6 },
  ],
  share: false,
  web: '',
  twitter: '',
  linkedin: '',
  facebook: '',
  templateId: 0,
  footer: {},
  calendarPlan: '',
};

export const draft = createSlice({
  name: 'draft',
  initialState,
  reducers: {
    generate: (state, action) => {
      return { ...initialState, ...action.payload };
    },
    AddMonth: (state, { payload }) => {
      const newPayload = JSON.parse(JSON.stringify(payload));

      return {
        ...state,
        months: [...newPayload],
        // months: newPayload,
      };
    },
    changeCoverImage: (state, { payload }) => {
      console.log(payload);

      return {
        ...state,
        coverImg: payload,
      };
    },
    statusUpdate: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    reloadState: (state) => {
      return {
        ...state,
        ...initialState,
      };
    },
    updateCustomColor: (state, { payload }) => {
      return {
        ...state,
        customColors: [...payload],
      };
    },
    addYearToDraft: (state, { payload }) => {
      return {
        ...state,
        months: [...state.months, ...payload],
      };
    },

    addUpdateDraftCalendarYears: (state, { payload }) => {
      return {
        ...state,
        months: [...state.months, ...payload],
      };
    },

    sortDraftMonths: (state, { payload }) => {
      const newArray = [...state.months];
      const sortedMonth = newArray.sort((a, b) => a?.id - b?.id);

      return {
        ...state,
        months: [...sortedMonth],
      };
    },

    DeleteDateDraftCalendarYear: (state, { payload }) => {
      const newMonth = state.months.filter(
        (month) => dayjs(month?.date).format('YYYY') !== payload.toString()
      );

      return {
        ...state,
        months: [...newMonth],
      };
    },
  },
});

export const {
  generate,
  AddMonth,
  changeCoverImage,
  statusUpdate,
  reloadState,
  updateCustomColor,
  updateYears,
  addYearToDraft,
  addUpdateDraftCalendarYears,
  DeleteDateDraftCalendarYear,
  sortDraftMonths,
} = draft.actions;

export default draft.reducer;

// Convert 1:23am to military time
const convertTime = (time) => {
  let s = time;
  let hour = s.split(':')[0];
  let minutes = s.split(':')[1].split('').splice(0, 2).join('');
  let seconds = '00';
  let region = s.split(':')[1].split('').splice(2, 4).join('');

  if (region === 'am' && hour === '12') hour = '00';
  else if (region === 'pm' && hour === '12') hour = '12';
  else if (region === 'pm' && hour !== '12') {
    hour = (Number(hour) + 12).toString();
  }
  // console.log(`${dayjs(startTime).format('h:mma')} server`)
  return `${hour}:${minutes}:${seconds}`;
};
