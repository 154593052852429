import React from 'react';
import { TWrapper } from './Privacy.Styles';
import { useTranslation } from 'react-i18next';

import {
  adMobLink,
  adSenseLink,
  analyticsGoogleLink,
  analyticsMixpanelLink,
  analytiFirebaseLink,
  appInfo,
  appInfoList,
  appInfoP1,
  appInfoP2,
  appleStoreLink,
  biometric,
  businessTransfer,
  BussinessPurpose,
  BussinessPurposeList,
  BussinessPurposeP1,
  BussinessPurposeP2,
  califoniaPrivacy1,
  califoniaPrivacy2,
  califoniaPrivacy3,
  califoniaRights1,
  califoniaRights2,
  ccpaList,
  ccpaPrivacy,
  ccpaPrivacy1,
  ccpaPrivacy2,
  ccpaRights,
  ccpaRightsList,
  ccpaRightsList2,
  ccpaRightsList3,
  ccpaRightsListSub,
  ccpaRightsListSub1,
  ccpaRightsListSub2,
  ccpaRightsListSubSub,
  changePrivacy1,
  changePrivacy2,
  changePrivacy3,
  childPrivacy,
  commercailList,
  commercailP1,
  commercailP2,
  commercailP3,
  CompanyData,
  contactList,
  customer,
  DataProtect,
  DataProtectRights,
  definateP3,
  defination,
  definationCont,
  education,
  emailMaketing,
  exerciseData,
  facebookInsight1,
  facebookInsight2,
  facebookInsight3,
  facebookInsightLink,
  facebookLink,
  facebookLink1,
  facebookLink2,
  facebookLink2P,
  GDPRLink,
  GDPRP1,
  GDPRRight,
  googlePlayLink,
  hubSpotLink,
  identify,
  infoProcess,
  interfaceDrawn,
  interpret,
  lawEnforce,
  legalRequirementList,
  linkWebsite1,
  linkWebsite2,
  minor1,
  minor2,
  minor3,
  mobile,
  mobileList,
  mobileP2,
  nestedCcpaList,
  payments,
  payments2,
  performance,
  personalCompanyInfo,
  personalData,
  personalDataList,
  personalP1,
  personalP2,
  privacyGDPR,
  privacyGDPR2,
  protectedCat,
  requestData,
  retentionData,
  retentionData2,
  rightPolicyList,
  salesInfo,
  salesInfoList,
  salesInfoP2,
  salesInfoP3,
  securityData,
  sellPersonal1,
  sellPersonal2,
  sellPersonal3,
  sendGridLink,
  sharePerson,
  sharePersonList,
  sourceList,
  thirdPartyList,
  thirdPartyP1,
  thirdPartyP2,
  thirdPartyP3,
  track,
  transferData,
  transferData0,
  transferData1,
  UsageP1,
  UsageP2,
  UsageP3,
  verifyData,
  verifyDataP1,
  verifyDataP2,
  verifyDataP3,
  website,
  websiteList,
  websiteP1,
} from './Services/GDPRServices';
import {
  discloseInfo,
  firstParagraph,
  geoData,
  giveDisclose,
  googleAnalysis,
  googleP1,
  googleP2,
  googleP3,
  googleP4,
  googleP5,
  googleServicesLink,
  infoCollectedP1,
  infoCollectedP2,
  infoCollectionList,
  personalInfo,
  sensitiveInfo,
  sensitiveInfoP2,
  socialInfo1,
  socialInfo2,
  socialInfo3,
  socialInfo4,
  optIn,
  optInPara,
  overseasDisclosureP1,
  overseasDisclosureP2,
  rights1,
  Storage1,
  Storage2,
  Cookies1,
  Cookies2,
  webLinks,
  Amendment,
} from './Services/PrivacySectionService';

import {
  getLists,
  getBoldLists,
  getLinkText,
  getListLinks,
} from './subRoutine';

function PrivacySection() {
  const { t } = useTranslation(['privacy-policy']);

  return (
    <>
      <TWrapper>
        <h3>{t('Privacy')}</h3>
        <p>{t(firstParagraph)}</p>
      </TWrapper>

      <TWrapper>
        <h5>{t('The information we collect')}</h5>
        <p>
          {' '}
          <span className="bold-text">{t('Personal information:')}</span>
          {t(infoCollectedP1)}
        </p>
        <p>{t(infoCollectedP2)}</p>
        <ul className="flex-column"> {getLists(infoCollectionList)}</ul>

        <p>
          {' '}
          <span className="bold-text">
            {' '}
            {t('Sensitive information :')}
          </span>{' '}
          {t(sensitiveInfo)}
        </p>

        <p> {t(sensitiveInfoP2)} </p>
      </TWrapper>

      <TWrapper>
        <h5>{t('How we collect personal information')}</h5>
        <p>
          {t(
            'We collect personal information in a variety of ways, including:'
          )}
        </p>
        <ul className="flex-column">{getBoldLists(personalInfo)}</ul>
      </TWrapper>

      <TWrapper>
        <h5>Personal information from social network accounts</h5>
        <p>{t(socialInfo1)}</p>
        <p>{t(socialInfo2)}</p>
        <p>{t(socialInfo3)}</p>
        <p>{t(socialInfo4)}</p>
      </TWrapper>

      <TWrapper>
        <h5>
          {t(
            'Use of Google API Services (this section applies to personal information accessed through Google API Services)'
          )}
        </h5>
        <p>
          '
          {t(
            'We use Google’s Application Programming Interface (API) Service to enable you to sync your calendar to our mobile application.'
          )}
          '
        </p>
        <p>
          {getLinkText(googleServicesLink)} &nbsp;
          {t(
            'including the Limited Use requirements (set out in the Google API Services User Data Policy).'
          )}
        </p>
        <p>{t(googleP1)}</p>
        <p>{t(googleP2)}</p>
        <p>{t(googleP3)}</p>
        <p>{t(googleP4)}</p>
        <p>{t(googleP5)}</p>
      </TWrapper>

      <TWrapper>
        <h5>{t('Use of geo-localisation data')} </h5>
        <p>
          {t(
            'We collect your precise or approximate location via our mobile application for the following purposes:'
          )}
        </p>

        <ul className="flex-column">{getLists(geoData)}</ul>
      </TWrapper>

      <TWrapper>
        <h5>
          {t('Why we collect, hold, use and disclose personal information')}
        </h5>
        <p>
          {t(
            'We may collect, hold, use and disclose personal information for the following purposes:'
          )}
        </p>
        <ul className="flex-column">{getLists(discloseInfo)}</ul>
      </TWrapper>

      <TWrapper>
        <h5>{t('Our disclosures of personal information to third parties')}</h5>
        <p>{t('We may disclose personal information to:')}</p>
        <ul className="flex-column">{getLists(giveDisclose)}</ul>

        <p>{getBoldLists(googleAnalysis)}</p>
        <p>
          {' '}
          {getLinkText(optIn)} {optInPara}
        </p>
      </TWrapper>

      <TWrapper>
        <h5>{t('Overseas disclosure')}</h5>
        <p>{t(overseasDisclosureP1)} </p>
        <p>{t(overseasDisclosureP2)}</p>
      </TWrapper>

      <TWrapper>
        <h5>{t('Your rights and controlling your personal information')}</h5>
        <ul className="flex-column">{getBoldLists(rights1)}</ul>
      </TWrapper>

      <TWrapper>
        <h5>{t('Storage and security')}</h5>

        <p>{t(Storage1)}</p>
        <p>{t(Storage2)}</p>
      </TWrapper>

      <TWrapper>
        <h5>{t('Cookies')}</h5>

        <p> {t(Cookies1)}</p>
        <p> {t(Cookies2)}</p>
      </TWrapper>

      <TWrapper>
        <h5>{t('Links to other websites')}</h5>
        <p>{t(webLinks)}</p>
      </TWrapper>

      <TWrapper>
        <h5>{t('Amendments')}</h5>

        <p>{t(Amendment)}</p>
      </TWrapper>

      <TWrapper>
        <div className="end-remark-TC">
          <h5>{t('For any questions or notices, please contact us at:')}</h5>
          <p> Timebly Pty Ltd t/as Lectie ABN 56 651 956 964</p>
          <p>Email: support@lectie.com</p>
          <p>
            {t('Last update:')} 13 {t('December')} 2021
          </p>
          <p>© {t('LegalVision')} ILP Pty Ltd</p>
        </div>
      </TWrapper>

      <>
        <TWrapper>
          <h3>USA & GDPR</h3>
        </TWrapper>

        <TWrapper>
          <p>
            {' '}
            {t('Last updated')}: {t('March')} 17, 2022
          </p>

          <p>{t(privacyGDPR)}</p>
          <p>{t(privacyGDPR2)}</p>
        </TWrapper>

        <TWrapper>
          <h3>{t('Interpretation and Definitions')}</h3>
          <h5>{t('Interpretation')}</h5>
          <p>{t(interpret)}</p>
        </TWrapper>

        <TWrapper>
          <h5>{t('Definitions')}</h5>
          <p> {t('For the purposes of this Privacy Policy:')} </p>

          <ul className="flex-column">{getBoldLists(defination)}</ul>
          <p>{t(personalP1)}</p>
          <p>{t(personalP2)}</p>
          <ul>{getBoldLists(definationCont)}</ul>
          <p>{t(definateP3)}</p>
        </TWrapper>

        <TWrapper>
          <h3>{t('Collecting and Using Your Personal Data')}</h3>
          <h4>{t('Types of Data Collected')}</h4>
          <h5>{t('Personal Data')}</h5>
          <p>{t(personalData)}</p>
          <ul className="flex-column">{getLists(personalDataList)}</ul>
        </TWrapper>

        <TWrapper>
          <h5>{t('Usage Data')}</h5>
          <p>
            {t('Usage Data is collected automatically when using the Service.')}
          </p>

          <p>{t(UsageP1)}</p>
          <p>{t(UsageP2)}</p>
          <p>{t(UsageP3)}</p>
        </TWrapper>

        <TWrapper>
          <h5>{t('Information from Third-Party Social Media Services')}</h5>

          <p>{t(thirdPartyP1)} </p>

          <ul className="flex-column">{getLists(thirdPartyList)}</ul>

          <p>{t(thirdPartyP2)}</p>

          <p>{t(thirdPartyP3)}</p>
        </TWrapper>

        <TWrapper>
          <h5>{t('Information Collected while Using the Application')}</h5>
          <p>{t(appInfo)}</p>

          <ul className="flex-column">{getLists(appInfoList)}</ul>
          <p>{t(appInfoP1)}</p>
          <p>{t(appInfoP2)}</p>
        </TWrapper>

        <TWrapper>
          <h4>{t('Use of Your Personal Data')}</h4>
          <p>
            {t('The Company may use Personal Data for the following purposes:')}
          </p>

          <ul className="flex-column">{getBoldLists(CompanyData)}</ul>
          <p>
            {t(
              'We may share Your personal information in the following situations:'
            )}
          </p>
          <ul className="flex-column">{getBoldLists(personalCompanyInfo)}</ul>
        </TWrapper>

        <TWrapper>
          <h4>{t('Retention of Your Personal Data')}</h4>
          <p> {t(retentionData)}</p>
          <p> {t(retentionData2)}</p>
        </TWrapper>

        <TWrapper>
          <h4>{t('Transfer of Your Personal Data')}</h4>
          <p> {t(transferData)}</p>
        </TWrapper>

        <TWrapper>
          <h4>{t('Disclosure of Your Personal Data')}</h4>

          <h5>{t('Business Transactions')}</h5>
          <p>{t(businessTransfer)}</p>

          <h5>{t('Law enforcement')}</h5>
          <p>{t(lawEnforce)}</p>

          <h5>{t('Other legal requirements')}</h5>
          <ul className="flex-column">{getLists(legalRequirementList)}</ul>

          <h5>{t('Security of Your Personal Data')}</h5>

          <p>{t(securityData)}</p>
        </TWrapper>

        <TWrapper>
          <h3>
            {t('Detailed Information on the Processing of Your Personal Data')}
          </h3>

          <p>{t(infoProcess)}</p>

          <h4> {t('Analytics')} </h4>
          <p>
            {t(
              'We may use third-party Service providers to monitor and analyze the use of our Service.'
            )}
          </p>

          <h5>Google {t('Analytics')}</h5>

          <p>{getLinkText(analyticsGoogleLink)}</p>

          <h5>{t('Firebase')}</h5>
          <p>{getLinkText(analytiFirebaseLink)}</p>

          <h5>{t('Mixpanel')}</h5>
          <p>{getLinkText(analyticsMixpanelLink)}</p>

          <h4>{t('Advertising')}</h4>
          <p>
            {t(
              'We may use Service Providers to show advertisements to You to help support and maintain Our Service.'
            )}
          </p>

          <h5>{t('Google AdSense and DoubleClick Cookie')}</h5>

          <p> {getLinkText(adSenseLink)}</p>

          <h5>{t('AdMob by Google')}</h5>
          <p>{getLinkText(adMobLink)}</p>

          <h4>{t('Email Marketing')}</h4>

          <p>{t(emailMaketing)}</p>
          <p>
            {t(
              'We may use Email Marketing Service Providers to manage and send emails to You.'
            )}
          </p>

          <h5>{t('SendGrid')}</h5>

          <p>{getLinkText(sendGridLink)}</p>

          <h5>Hubspot</h5>
          <p>{getLinkText(hubSpotLink)}</p>

          <h4>{t('Payments')}</h4>
          <p>{t(payments)}</p>
          <p>{t(payments2)}</p>

          <h5>Apple Store In-App {t('Payments')}</h5>

          <p>{getLinkText(appleStoreLink)}</p>
          <h5>Google Play In-App {t('Payments')}</h5>
          <p>{getLinkText(googlePlayLink)}</p>

          <h4>{t('Usage, Performance and Miscellaneous')}</h4>
          <h5>
            {t(
              'We may use third-party Service Providers to provide better improvement of our Service.'
            )}
          </h5>
          <h5 style={{ marginLeft: '20px', fontWeight: 'bold' }}>
            {t('Google Places')}
          </h5>
          <p style={{ marginLeft: '20px' }}>
            {t(
              'Google Places is a service that returns information about places using HTTP requests. It is operated by Google'
            )}
          </p>
          <p style={{ marginLeft: '20px' }}>
            {t(
              'Google Places service may collect information from You and from Your Device for security purposes.'
            )}
          </p>
          <p style={{ marginLeft: '20px' }}>{getLinkText(performance)}</p>
        </TWrapper>

        <TWrapper>
          <h3>{t('GDPR Privacy')}</h3>

          <h4>{t('Legal Basis for Processing Personal Data under GDPR')}</h4>
          <p>
            {t('We may process Personal Data under the following conditions:')}
          </p>

          <ul className="flex-column">{getBoldLists(GDPRLink)}</ul>
          <p> {t(GDPRP1)}</p>

          <h3>{t('Your Rights under the GDPR')}</h3>
          <p>{t(GDPRRight)}</p>
          <p>
            {t(
              'You have the right under this Privacy Policy, and by law if You are within the EU, to:'
            )}
          </p>
          <ul className="flex-column">{getBoldLists(rightPolicyList)}</ul>

          <h4>{t('Exercising of Your GDPR Data Protection Rights')}</h4>

          <p>{t(DataProtectRights)}</p>
          <p>{t(DataProtect)}</p>
        </TWrapper>

        <TWrapper>
          <h3>{t('Facebook Fan Page')}</h3>

          <h4>{t('Data Controller for the Facebook Fan Page')}</h4>
          <p>
            {getLinkText(facebookLink)}{' '}
            {t(
              'the Company and the operator of the social network Facebook are Joint Controllers.'
            )}
          </p>
          <p>{getLinkText(facebookLink1)}</p>

          <p>
            {getLinkText(facebookLink2)}
            {t(facebookLink2P)}
          </p>

          <h4>{t('Facebook Insights')}</h4>
          <p>{t(facebookInsight1)}</p>
          <p>{t(facebookInsight2)}</p>

          <p>{t(facebookInsight3)}</p>
          <p> {getLinkText(facebookInsightLink)}</p>
        </TWrapper>

        <TWrapper>
          <h3>{t('CCPA Privacy')}</h3>

          <p>{t(ccpaPrivacy)}</p>
          <h4>{t('Categories of Personal Information Collected')}</h4>

          <p>{t(ccpaPrivacy1)}</p>
          <p>{t(ccpaPrivacy2)}</p>

          <h5>{t('Category A: Identifiers.')}</h5>
          <p>{t(identify)}</p>

          <h5>
            {t(
              'Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))'
            )}
          </h5>
          <p>{t(customer)}</p>
          <h5>
            {t(
              'Category C: Protected classification characteristics under California or federal law.'
            )}
          </h5>
          <p>{protectedCat}</p>

          <h5>
            <b>Category D: </b>
            Commercial information.{' '}
          </h5>
          <p>
            Examples: Records and history of products or services purchased or
            considered. Collected: Yes.
          </p>
          <h5>{t('Category E: Biometric information.')}</h5>

          <p>{t(biometric)}</p>

          <h5>
            {t('Category F: Internet or other similar network activity.')}
          </h5>
          <p>
            {t(
              'Examples: Interaction with our Service or advertisement. Collected: Yes.'
            )}
          </p>

          <h5>{t('Category G: Geolocation data.')}</h5>
          <p>{t('Examples: Approximate physical location. Collected: Yes.')}</p>

          <h5>{t('Category H: Sensory data.')}</h5>
          <p>
            {t(
              'Examples: Audio, electronic, visual, thermal, olfactory, or similar information. Collected: No.'
            )}
          </p>

          <h5>
            {t('Category I: Professional or employment-related information.')}
          </h5>

          <p>
            {t(
              'Examples: Current or past job history or performance evaluations. Collected: No.'
            )}
          </p>

          <h5>
            {t(
              'Category J: Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).'
            )}
          </h5>
          <p>{t(education)}</p>
          <h5>
            {t('Category K: Inferences drawn from other personal information.')}
          </h5>
          <p>{t(interfaceDrawn)}</p>

          <p>{t('Under CCPA, personal information does not include:')}</p>
          <ul className="flex-column">
            {getLists(ccpaList)}

            <ul className="flex-column">{getLists(nestedCcpaList)}</ul>
          </ul>

          <h4>{t('Sources of Personal Information')}</h4>
          <p>
            {t(
              'We obtain the categories of personal information listed above from the following categories of sources:'
            )}
          </p>
          <ul className="flex-column">{getBoldLists(sourceList)}</ul>
          <h4>
            {t(
              'Use of Personal Information for Business Purposes or Commercial Purposes'
            )}
          </h4>
          <p>{t(BussinessPurpose)}</p>
          <ul className="flex-column">{getLists(BussinessPurposeList)}</ul>
          <p> {t(BussinessPurposeP1)}</p>
          <p>{t(BussinessPurposeP2)}</p>

          <h4>
            {t(
              'Disclosure of Personal Information for Business Purposes or Commercial Purposes'
            )}
          </h4>
          <p>{t(commercailP1)}</p>
          <ul className="flex-column">{getLists(commercailList)}</ul>
          <p>{t(commercailP2)}</p>
          <p>{t(commercailP3)}</p>

          <h4>{t('Sale of Personal Information')}</h4>
          <p>{t(salesInfo)}</p>
          <p>{t(salesInfoP2)}</p>
          <p>{t(salesInfoP3)}</p>
          <ul className="flex-column">{getLists(salesInfoList)}</ul>

          <h4>{t('Share of Personal Information')}</h4>
          <p>{t(sharePerson)}</p>
          <ul className="flex-column">{getLists(sharePersonList)}</ul>

          <h4>
            {t('Sale of Personal Information of Minors Under 16 Years of Age')}
          </h4>
          <p>{t(minor1)}</p>
          <p>{t(minor2)}</p>
          <p>{t(minor3)}</p>

          <h4>{t('Your Rights under the CCPA')}</h4>

          <p>{ccpaRights}</p>
          <ul className="flex-column">
            {getBoldLists(ccpaRightsList)}

            <ul className="flex-column">
              {getLists(ccpaRightsListSub)}
              <ul>{getLists(ccpaRightsListSubSub)}</ul>
            </ul>
          </ul>
          <ul className="flex-column">
            {getBoldLists(ccpaRightsList2)}
            <ul className="flex-column">{getLists(ccpaRightsListSub1)}</ul>
          </ul>
          <ul className="flex-column">
            {getBoldLists(ccpaRightsList3)}

            <ul className="flex-column">{getLists(ccpaRightsListSub2)}</ul>
          </ul>

          <h4>{t('Exercising Your CCPA Data Protection Rights')}</h4>
          <p>
            {t(
              'In order to exercise any of Your rights under the CCPA, and if You are a California resident, You can contact Us:'
            )}
          </p>
          <ul className="flex-column">{getLists(exerciseData)}</ul>
          <p>{t('Your request to Us must:')}</p>
          <ul className="flex-column">{getLists(requestData)}</ul>
          <p>
            {t(
              'We cannot respond to Your request or provide You with the required information if we cannot:'
            )}
          </p>
          <ul className="flex-column">{getLists(verifyData)}</ul>

          <p>{t(verifyDataP1)}</p>
          <p>{t(verifyDataP2)}</p>
          <p>{t(verifyDataP3)}</p>

          <h4>{t('Do Not Sell My Personal Information')}</h4>
          <p>{t(sellPersonal1)}</p>
          <p>{t(sellPersonal2)}</p>
          <p>{t(sellPersonal3)}</p>

          <h4>{t('Website')}</h4>
          <p>{t(website)}</p>

          <ul className="flex-column">{getListLinks(websiteList)}</ul>

          <p>{t(websiteP1)}</p>

          <h4>{t('Mobile Devices')}</h4>
          <p>{t(mobile)}</p>

          <ul className="flex-column">{getLists(mobileList)}</ul>

          <p>{t(mobileP2)}</p>
        </TWrapper>

        <TWrapper>
          <h3>
            {t(
              'Do Not Track Policy as Required by California Online Privacy Protection Act (CalOPPA)'
            )}
          </h3>
          <p>{t('Our Service does not respond to Do Not Track signals.')}</p>
          <p>{t(track)}</p>
        </TWrapper>

        <TWrapper>
          <h3>{t("Children's Privacy")}</h3>

          <p>{t(childPrivacy)}</p>
        </TWrapper>

        <TWrapper>
          <h3>
            {t(
              "Your California Privacy Rights (California's Shine the Light law)'"
            )}
          </h3>
          <p>{t(califoniaRights1)}</p>
          <p>{t(califoniaRights2)}</p>
        </TWrapper>

        <TWrapper>
          <h3>
            {t(
              'California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)'
            )}
          </h3>

          <p>{t(califoniaPrivacy1)}</p>
          <p>{t(califoniaPrivacy2)}</p>
          <p>{t(califoniaPrivacy3)}</p>
        </TWrapper>
        <TWrapper>
          <h3>{t('Links to Other Websites')}</h3>
          <p>{t(linkWebsite1)}</p>
          <p>{t(linkWebsite2)}</p>
        </TWrapper>

        <TWrapper>
          <h3>{t('Changes to this Privacy Policy')}</h3>
          <p>{t(changePrivacy1)}</p>
          <p>{t(changePrivacy2)}</p>
          <p>{t(changePrivacy3)}</p>
        </TWrapper>
        <TWrapper>
          <h3>{t('Contact Us')}</h3>
          <p>
            {t(
              'If you have any questions about this Privacy Policy, You can contact us:'
            )}
          </p>
          <p className="bottom_list_contact">{getListLinks(contactList)}</p>
        </TWrapper>
      </>
    </>
  );
}

export default PrivacySection;
