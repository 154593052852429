import styled from 'styled-components';

export const HomeFAQContianer = styled.div`
  width: 90%;
  max-width: 762px;
  margin: 0 auto;
  padding: 2rem 0;

  .home_wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    color: #0a0a0a;
    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 2rem;
      line-height: 65px;
      color: #0a0a0a;
      text-align: center;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 33px;
      text-align: center;
    }

    .btn_container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
      grid-gap: 2rem;
      width: 80%;
      margin: 0 auto;
      button {
        height: 52px;
        border-radius: 12px;
        padding: 12px;
      }

      & button:nth-child(1) {
        background: #2667b1;
        color: #fff;
      }

      & button:nth-child(2) {
        border: 1px solid #2667b1;
        background-color: transparent;
      }
    }

    .img_container {
      margin-top: 1rem;
      width: 90%;
      max-width: 450px;
      img {
        width: 100%;
      }
    }
  }
`;
