import React from 'react';
import { Helmet } from 'react-helmet';

function ExtendHelmet({ Title }) {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{Title}</title>
      <link
        href="https://fonts.googleapis.com/css?family=Raleway"
        rel="stylesheet"
      />
    </Helmet>
  );
}

export default ExtendHelmet;
