import React from 'react';
import DefaultCover from '../../assest/images/defaultCalendarCover.png';
import LazyLoad from 'react-lazy-load';
import styled from 'styled-components';

const CoverImage = ({ beskpoke }) => {
  return (
    <CoverImageContainer>
      <div className="month-cover">
        <LazyLoad height={'100%'} width={'100%'} offset={200}>
          <img
            src={
              !beskpoke?.coverImg?.src ? DefaultCover : beskpoke?.coverImg?.src
            }
            alt="Cover Photo"
          />
        </LazyLoad>

        {!beskpoke?.coverImg?.src && (
          <div className="text-content">
            <h1>{beskpoke?.yearPicked}</h1>

            <h1>CALENDAR</h1>
            <p>Stay on top of your schedule with ease</p>
          </div>
        )}
      </div>
    </CoverImageContainer>
  );
};

const CoverImageContainer = styled.section`
  position: relative;
  margin-bottom: 5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  .month-cover {
    width: 95%;
    border-radius: 0px 0px 20px 20px;
    position: relative;

    img {
      width: 100%;
      height: auto;
      border-radius: 20px;
    }
  }

  .text-content {
    position: absolute;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.3rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;

    h1 {
      font-size: clamp(1.5rem, 3rem, 1.8rem);
      font-weight: bold;
    }

    p {
      font-size: clamp(1rem, 1.5rem, 1.2rem);
    }
  }

  @media (max-width: 1024px) {
    .month-cover {
      width: 100%;
      height: auto;
      border-radius: 20px;
    }
  }

  @media (max-width: 824px) {
    .text-content {
      width: 90%;
    }
  }
`;

export default CoverImage;
