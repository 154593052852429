/* eslint eqeqeq: 0 */
import { useEffect, useRef, useState, forwardRef } from 'react';
import dayjs from 'dayjs';
import weekdayPlugin from 'dayjs/plugin/weekday';
import objectPlugin from 'dayjs/plugin/toObject';
import isTodayPlugin from 'dayjs/plugin/isToday';
import { ChevronRight, ChevronLeft, Share } from 'react-feather';
import LazyLoad from 'react-lazy-load';
import './styles/month.css';
// eslint-disable-next-line
import { saveCalendarEvent } from '../../functions';
import { Dialog } from '@material-ui/core';
import ShareComponet from '../component/share';
import ShareEvent from './shareEvent';
import ShareDark from '../../assest/images/ShareDark.svg';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FaLocationDot } from 'react-icons/fa6';

const Month = forwardRef((props, ref) => {
  const { data, defaultCover, status, calendarPlan } = props;
  const { t } = useTranslation(['dashboard']);

  const [selected, setSelected] = useState(0);
  const [currentEvent, setCurrentEvent] = useState({
    events: [],
    index: 0,
    disabledNext: false,
    disabledPrev: false,
  });
  const now = dayjs(data?.date).locale('es');

  dayjs.extend(weekdayPlugin);
  dayjs.extend(objectPlugin);
  dayjs.extend(isTodayPlugin);
  // eslint-disable-next-line
  const [currentMonth, setCurrentMonth] = useState(now);
  const [arrayOfDays, setArrayOfDays] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [eventID, setEventID] = useState('');
  const beskpoke = useSelector((state) => state.bespoke);

  // Back to calendar func
  const back = () => {
    setSelected(0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Open share functionality
  const handleClickOpen = () => {
    console.log('Opened');
    setOpen(true);
    setIsOpen(!isOpen);
  };
  // Previous Button
  const prevBtn = () => {
    let index = currentEvent.index - 1;
    let disabledPrev = currentEvent.index === 0;
    setCurrentEvent({
      ...currentEvent,
      index: disabledPrev ? 0 : index,
      disabledPrev: disabledPrev,
      disabledNext: false,
    });
  };
  // Next Button
  const nextBtn = () => {
    let index = currentEvent.index + 1;
    let disabledNext = currentEvent.index === currentEvent?.events.length - 1;
    setCurrentEvent({
      ...currentEvent,
      index: disabledNext ? currentEvent.index : index,
      disabledNext: disabledNext,
      disabledPrev: false,
    });
  };

  // console.log('shuga ', currentEvent?.events[currentEvent.index]?.id);
  let eID = currentEvent?.events[currentEvent.index]?.id;
  // setEventID(eID);
  // Event Card
  const EventCard = () => {
    const { t } = useTranslation(['dashboard']);
    return (
      <div className="event-card">
        <div
          className={`nav-con ${
            currentEvent?.events.length < 2 ? 'nav-hidden' : ''
          }`}
        >
          <div
            className={`navigator ${
              currentEvent?.events.length < 2 ? 'hidden' : ''
            }`}
          >
            <ChevronLeft
              color="#000000"
              size={25}
              onClick={() => (currentEvent.disabledPrev ? null : prevBtn())}
              style={{ cursor: 'pointer' }}
            />
            <div className="navigator-title">{t('Events')}</div>
            <ChevronRight
              color="#000000"
              size={25}
              onClick={() => (currentEvent.disabledNext ? null : nextBtn())}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </div>
        <div className="calender-event">
          <div className="event-content">
            <h6 className="date">
              {dayjs(
                currentEvent?.events[currentEvent.index]?.startDate
              ).format('DD')}{' '}
              {t(
                `${dayjs(
                  currentEvent?.events[currentEvent.index]?.startDate
                ).format('MMMM')}`
              )}{' '}
              {dayjs(
                currentEvent?.events[currentEvent.index]?.startDate
              ).format('YYYY')}
            </h6>
            <div className="event-details">
              <div
                className="event-dot"
                // style={{background: currentEvent?.events[0]?.color}}
              ></div>
              <div className="event-schedule-con">
                <div className="event-schedule">
                  <p>
                    {dayjs(
                      currentEvent?.events[currentEvent.index]?.startTime
                    ).format('h:mma')}{' '}
                    -{' '}
                    {dayjs(
                      currentEvent?.events[currentEvent.index]?.endTime
                    ).format('h:mma')}
                  </p>
                  <h6>{currentEvent?.events[currentEvent.index]?.title}</h6>
                </div>

                {currentEvent?.events[currentEvent.index]?.location !== '' && (
                  <div className=" location">
                    <FaLocationDot />

                    <p className="note_desc">
                      {currentEvent?.events[currentEvent.index]?.location}
                    </p>
                  </div>
                )}
                <div className="note">
                  <p className="note_desc">
                    {currentEvent?.events[currentEvent.index]?.description}
                  </p>
                </div>
              </div>
            </div>
            <div className="btn-con">
              {currentEvent?.events[currentEvent.index]?.link ? (
                <a
                  href={currentEvent?.events[currentEvent.index]?.link}
                  target="_blank"
                >
                  <div className="join-btn">{t('Join')}</div>
                </a>
              ) : null}

              {status === 'published' && (
                <div
                  className="add-btn"
                  onClick={() => {
                    let val = saveCalendarEvent(
                      currentEvent?.events[currentEvent.index]
                    );
                    val[0] === true
                      ? toast.success(t(val[1]))
                      : toast.error(t(val[1]));
                  }}
                >
                  {t('Add to calendar')}
                </div>
              )}
              {status === 'published' && calendarPlan === 'Premium' && (
                <div
                  className="add-btn flex items-center justify-center  px-5"
                  onClick={() => {
                    handleClickOpen();
                  }}
                >
                  <img
                    src={ShareDark}
                    alt="Share icon"
                    width={25}
                    height={25}
                  />
                  <p className="text-[14px] text-[#0A0A0A] text-center px-1  py-2 cursor-pointer">
                    {t('Share')}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="event-img-con">
            <div className="event-img">
              <LazyLoad height={'100%'} width={'100%'} offset={200}>
                <img
                  src={
                    currentEvent?.events[currentEvent.index]?.image
                      ? currentEvent?.events[currentEvent.index]?.image
                      : require('../../assest/images/Rectangle19.png')
                  }
                  className="event-img"
                  alt="event"
                />
              </LazyLoad>
            </div>
            <div className="calendar-btn" onClick={() => back()}>
              {t('Back to calendar')}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Calendar header
  const renderHeader = () => {
    return (
      <div className="header row flex-middle">
        <h2 ref={ref} style={{ visibility: 'hidden' }}>
          {currentMonth.format('YYYY')}
        </h2>

        <div className="col col-center">
          <span>
            {t(currentMonth.format('MMMM'))} {currentMonth.format('YYYY')}
          </span>
        </div>
      </div>
    );
  };
  // Render day
  const renderDays = () => {
    const dateFormat = 'ddd';
    const days = [];

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {t(now.weekday(i).format(dateFormat))}
        </div>
      );
    }
    return <div className="days row">{days}</div>;
  };
  // All days of the week

  const getAllDays = () => {
    let currentDate = currentMonth.startOf('month').weekday(0);
    const nextMonth = currentMonth.add(1, 'month').month();

    let allDates = [];
    let weekDates = [];

    let weekCounter = 1;

    while (currentDate.weekday(0).toObject().months !== nextMonth) {
      const formated = formateDateObject(currentDate);
      weekDates.push(formated);
      if (weekCounter === 7) {
        allDates.push({ dates: weekDates });
        weekDates = [];
        weekCounter = 0;
      }

      weekCounter++;
      currentDate = currentDate.add(1, 'day');
    }

    setArrayOfDays(allDates);
  };
  useEffect(() => {
    getAllDays();
    // eslint-disable-next-line
  }, [currentMonth]);
  // Each cells of the calendar

  const renderCells = () => {
    const rows = [];
    let days = [];

    arrayOfDays.forEach((week, index) => {
      week.dates.forEach((d, i) => {
        days.push(
          <div
            className={`col cell ${!d.isCurrentMonth ? 'disabled' : ''}`}
            key={i}
          >
            <div
              className={`inner-cell ${
                d.isCurrentMonth
                  ? selected == d?.day
                    ? 'select-date'
                    : ''
                  : ''
              }`}
              onClick={() => {
                if (d?.day == selected) {
                  setSelected(0);
                } else {
                  setSelected(d?.day);
                  setCurrentEvent({
                    events: d?.events,
                    index: 0,
                    disabledNext: false,
                    disabledPrev: false,
                  });
                }
              }}
            >
              <span className="number">{d.day}</span>
              <div className="dots">
                {d.isCurrentMonth
                  ? d?.events?.map((item, i) => (
                      <div
                        className="dot"
                        style={{ background: item.color }}
                        key={i}
                      ></div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        );
      });
      rows.push(
        <div className="row" key={index}>
          {days}
        </div>
      );
      days = [];
    });

    return <div className="body">{rows}</div>;
  };
  // Format each day object

  const formateDateObject = (date) => {
    const clonedObject = { ...date.toObject() };

    // Event for teh day
    const events = data?.events.filter(
      (e) =>
        dayjs(date).format('DD-MM-YY') === dayjs(e.startDate).format('DD-MM-YY')
    );
    const formatedObject = {
      day: clonedObject.date,
      month: clonedObject.months,
      year: clonedObject.years,
      isCurrentMonth: clonedObject.months === currentMonth.month(),
      date: date,
      events: events,
    };

    return formatedObject;
  };
  // calendar style Selected
  const calendarCon = {
    justifyContent: 'center',
  };
  // calendar style Selected
  const calendarStyle = {
    width: '80%',
  };

  /* ====== MAIN SECTION RETURN  ======= */
  return (
    <div>
      <div className="month-cover">
        <LazyLoad height={'100%'} width={'100%'} offset={200}>
          <img
            src={
              data.background && data.background !== 'string'
                ? data.background
                : defaultCover.image
            }
            className="month-cover"
            alt="cover"
          />
        </LazyLoad>
      </div>

      <div
        className="calendar-content"
        style={
          selected == 0 || currentEvent?.events.length == 0 ? calendarCon : null
        }
      >
        {selected == 0 || currentEvent?.events.length == 0 ? null : (
          <EventCard />
        )}
        <div
          className={`calendar ${
            selected == 0 || currentEvent?.events.length == 0
              ? ''
              : 'mobile-calendar'
          }`}
          style={
            selected == 0 || currentEvent?.events.length == 0
              ? calendarStyle
              : null
          }
        >
          {renderHeader()}
          {renderDays()}
          {renderCells()}
        </div>
      </div>

      {/* Call new calendar */}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ShareEvent data={eID} />
      </Dialog>
    </div>
  );
});

export default Month;
