import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazy-load';
import styled from 'styled-components';

import Filter from '../../../assest/images/filter.png';
import TemplateTwoMonth from './component/templateTwoMonth';
import { useSelector } from 'react-redux';

const DraftTemplateTwo = () => {
  const beskpokeDraft = useSelector((state) => state.draft);
  let years = [beskpokeDraft?.year];
  return (
    <TemplateTwoContainer>
      <TemplateTwoMonth beskpoke={beskpokeDraft} years={years} />
    </TemplateTwoContainer>
  );
};

export default DraftTemplateTwo;

const TemplateTwoContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 3.5rem;

  .label-main-wrapper {
    width: 90%;
    max-width: 1230px;
    margin: 0 auto;
    .label-main-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;

      .events_label {
        color: #111111;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: bolder;
        font-size: 1rem;
      }

      .color_container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        cursor: pointer;

        .color {
          display: block;
          width: 32px;
          height: 100%;
        }

        p {
          padding-left: 0.8rem;
          padding-right: 0.8rem;
          height: 100%;
          font-weight: 550;
          font-size: 0.9rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .label_filter {
      width: 46px;
      height: 43px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
`;
