import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TemplateFourContainer from './style/TemplateFourStyle';
import LazyLoad from 'react-lazy-load';

import { makeStyles, Grid } from '@material-ui/core';
import { BallTriangle } from 'react-loader-spinner';
import { getMonth } from '../../../util';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import Button from '../../component/bespoke-core/utils/utilComponents/Button';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import FooterTvGuide from '../../component/bespoke-core/calendarComponent/TvGuideStudio/FooterTvGuide';
import {
  setCountPrevYears,
  setMonthNumber,
  setMonthNumberFromYear,
  setNextMonthNumber,
  setPrevMonthNumber,
} from '../../../store/features/monthSlice';
import getDaysInMonths from '../../component/bespoke-core/utils/utilComponents/getDaysInMonths';
import client from '../../../services';
import { toast } from 'react-toastify';
import { setTVDraft } from '../../../store/reducers/draftTVEvents';
import { useParams } from 'react-router-dom';
import BtnVariantGroup from '../../component/ButtonGroup/BtnVariantGroup';
import { getCountyCode, userGeolocation } from '../../../functions';

dayjs.extend(weekOfYear);

const useStyles = makeStyles({
  root: {
    background: '#fff',
    padding: '20px',
  },
  loader: {
    width: '100%',
    height: '100%',
  },
});

const DraftViewTemplateFour = () => {
  const beskpoke = useSelector((state) => state.draft);
  const { t } = useTranslation(['dashboard']);
  const classes = useStyles();
  const { id } = useParams();
  const [showDrop, setShowDrop] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const { monthNumber } = useSelector((state) => state.month);

  const TvDraft = useSelector((state) => state.tvGuideDraft);

  const [months, setMonths] = useState([
    getMonth(
      monthNumber,
      beskpoke?.status === 'published'
        ? TvDraft?.yearPicked
        : beskpoke?.yearPicked
    ),
  ]);

  // const [queryStartDate, setQueryStartDate] = useState('');
  const [queryStartDate, setQueryStartDate] = useState('');
  let [weekCounter, setWeekCounter] = useState(0);
  let dateFormated = dayjs(new Date(dayjs().year(), monthNumber)).format(
    'YYYY MMMM'
  );

  const allMonths = () => {
    let newAllMonths = [];
    for (let i = 0; i <= 11; i += 1) {
      newAllMonths.push(
        getMonth(
          i,
          beskpoke?.status === 'published'
            ? TvDraft?.yearPicked
            : beskpoke?.yearPicked
        )
      );
    }

    let newMonth = getDaysInMonths(newAllMonths);
    setMonths(newMonth);
  };

  // Update Draft res base on startdate
  const getDraftCalendarFromStartDate = async (startDate) => {
    setIsLoading(true);

    let country = new userGeolocation().getUserCountry();

    let { CountryCode } = getCountyCode(country) || 'NGN';

    console.log(startDate, ' This is satrt date ');

    await client
      .get(
        `/Souvenir/${beskpoke?.id}?startDate=${startDate}&webDraft=webDraft&country=${CountryCode}`
      )
      .then((res) => {
        dispatch(setTVDraft(res?.data));

        setIsLoading(false);

        console.log(' This is res ', res?.data);
      })
      .catch((e) => {
        console.log(e);
        toast.error(e?.response?.data?.Message ?? e?.message);
        setIsLoading(false);
        // setPublishing(false);
      });
  };

  useEffect(() => {
    allMonths();
    dispatch(setMonthNumber(0));
    setWeekCounter(0);
    setQueryStartDate(`${beskpoke?.yearPicked}-01-01T00:00:00`);
    getDraftCalendarFromStartDate(queryStartDate);
  }, []);

  const handleOpenEvent = (index) => {
    if (showDrop.includes(index)) {
      let newList = showDrop.filter((val) => val !== index);
      setShowDrop([...newList]);
    } else {
      setShowDrop((prev) => [...prev, index]);
    }
  };

  return (
    <TemplateFourContainer>
      {!TvDraft?.loadingDraft ? (
        <>
          {/* Month Switcher */}
          <div className="month-inc-dec flex a-center">
            <p className="t-sm">
              {TvDraft?.events.length <= 0
                ? dateFormated
                : dayjs(TvDraft?.events[0]?.startDate).format('MMMM YYYY')}
            </p>
          </div>

          <section className="view_container">
            <div className="weeks_view_container">
              <div>
                <BsChevronLeft
                  onClick={() => {
                    let startDateVal = '';

                    if (monthNumber !== 0 && weekCounter > 0) {
                      setWeekCounter((prevState) => prevState - 1);

                      if (TvDraft?.from !== TvDraft?.to) {
                        startDateVal = dayjs(TvDraft?.from).format(
                          'YYYY-MM-DDT00:00:00'
                        );
                      } else {
                        startDateVal = months[monthNumber][
                          weekCounter - 1
                        ][0].format('YYYY-MM-DDT00:00:00');
                      }

                      getDraftCalendarFromStartDate(startDateVal);
                    } else if (monthNumber !== 0 && weekCounter === 0) {
                      dispatch(setPrevMonthNumber());
                      setWeekCounter(months[monthNumber - 1]?.length - 1);
                      if (TvDraft?.from !== TvDraft?.to) {
                        startDateVal = dayjs(TvDraft?.from).format(
                          'YYYY-MM-DDT00:00:00'
                        );
                      } else {
                        startDateVal = months[monthNumber - 1][
                          months[monthNumber - 1]?.length - 1
                        ][0].format('YYYY-MM-DDT00:00:00');
                      }

                      getDraftCalendarFromStartDate(startDateVal);
                    } else if (monthNumber === 0 && weekCounter > 0) {
                      setWeekCounter((prevState) => prevState - 1);
                      if (TvDraft?.from !== TvDraft?.to) {
                        startDateVal = dayjs(TvDraft?.from).format(
                          'YYYY-MM-DDT00:00:00'
                        );
                      } else {
                        startDateVal = months[monthNumber][
                          weekCounter - 1
                        ][0].format('YYYY-MM-DDT00:00:00');
                      }
                      getDraftCalendarFromStartDate(startDateVal);
                    }
                  }}
                />
              </div>

              <div className="weeks_items_container">
                {months[monthNumber] !== undefined ? (
                  <div className="week_items">
                    {months[monthNumber][weekCounter].map((day, indexDay) => (
                      <div key={indexDay} className="week_item">
                        <p>{t(day.format('ddd'))}</p>
                        <p
                          style={
                            parseInt(day.format('M')) !== monthNumber + 1
                              ? { color: 'gray' }
                              : {}
                          }
                        >
                          {day.format('D')}
                        </p>
                      </div>
                    ))}
                  </div>
                ) : (
                  ''
                )}
              </div>
              {TvDraft?.from !== TvDraft?.to && (
                <div>
                  <BsChevronRight
                    onClick={() => {
                      if (weekCounter < months[monthNumber]?.length - 1) {
                        setWeekCounter((prevState) => prevState + 1);

                        getDraftCalendarFromStartDate(
                          dayjs(TvDraft?.to).format('YYYY-MM-DDT00:00:00')
                        );
                      } else if (monthNumber <= 10) {
                        setWeekCounter(0);
                        dispatch(setNextMonthNumber());
                        getDraftCalendarFromStartDate(
                          dayjs(TvDraft?.to).format('YYYY-MM-DDT00:00:00')
                        );
                      }
                    }}
                  />
                </div>
              )}
            </div>

            {!loading ? (
              <section className="events_container">
                {TvDraft?.events.length > 0 ? (
                  <>
                    {TvDraft?.events.map((event) => (
                      <div
                        className="event_container"
                        onClick={() => handleOpenEvent(event?.id)}
                      >
                        <div className="outer_events">
                          <div className="event_summary">
                            {!showDrop.includes(event?.id) && (
                              <>
                                <div className="date_time">
                                  <p className="date">
                                    {dayjs(event?.startDate).format('DD')}
                                  </p>
                                  <p>{dayjs(event?.startTime).format('hA')}</p>
                                </div>
                                <p className="title">{event?.title}</p>
                              </>
                            )}
                          </div>

                          <div className="chev_new">
                            {event?.isNewEvent && (
                              <BtnVariantGroup
                                label={'New event'}
                                variant={1}
                                icon=""
                                link=""
                              />
                            )}

                            {showDrop.includes(event?.id) ? (
                              <BiChevronUp />
                            ) : (
                              <BiChevronDown />
                            )}
                          </div>
                        </div>

                        {showDrop.includes(event?.id) && (
                          <div className="drop_event">
                            <div className="drop_writeup">
                              <h3 className="sub_title">{event?.subTitle}</h3>

                              <div className="season_tv">
                                <p className="season">
                                  Season {event?.season} - Episode{' '}
                                  {event?.episode}
                                </p>

                                <p className="caution">
                                  {' '}
                                  TV-{event?.programCaution}{' '}
                                </p>
                              </div>

                              <p className="description">
                                {event?.description}
                              </p>
                            </div>

                            <div className="img_container">
                              <img
                                src={
                                  !event?.image
                                    ? CalendarDefaultCover
                                    : event?.image
                                }
                                alt="event picture"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  <p
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '1.6rem',
                    }}
                  >
                    {' '}
                    No Data{' '}
                  </p>
                )}
              </section>
            ) : (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classes.loader}
              >
                <Grid item xs={2} md={2} sm={2} lg={2}>
                  <BallTriangle
                    height={'100%'}
                    width={'100%'}
                    radius={4}
                    color="#2667b1"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle=""
                    visible={true}
                  />
                </Grid>
              </Grid>
            )}
          </section>

          <FooterTvGuide />
        </>
      ) : (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.loader}
        >
          <Grid item xs={2} md={2} sm={2} lg={2}>
            <BallTriangle
              height={'100%'}
              width={'100%'}
              radius={6}
              color="#2667b1"
              ariaLabel="ball-triangle-loading"
              wrapperClass={{}}
              wrapperStyle=""
              visible={true}
            />
          </Grid>
        </Grid>
      )}
    </TemplateFourContainer>
  );
};

export default DraftViewTemplateFour;
