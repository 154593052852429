// Arrange month days in weeks for particular month
const getDaysInMonths = (months) => {
  const finalmonths = [];

  for (let currentMonth of months) {
    let arrMonth = currentMonth.slice(0, currentMonth.length - 1);
    let week = [];

    for (let weekDay of currentMonth[5]) {
      if (weekDay.format('MM') === currentMonth[2][1].format('MM')) {
        week.push(weekDay);
      }
    }

    if (week.length <= 0) {
      finalmonths.push(arrMonth);
    } else {
      finalmonths.push(currentMonth);
    }
  }

  return finalmonths;
};

export default getDaysInMonths;
