import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  templateName: 'Church',
  coverImg: {
    id: 0,
    src: 'https://bespoke-image.s3.amazonaws.com/08dacd49-bc10-42ff-83bc-d8622a0000f7/cover/imageUW5OQWB5L4',
    coverType: 0,
  },
  months: [
    {
      date: '2021-1',
      background: '',
      events: [
        {
          id: '126eade-f765-dedd-c7d5-33e2dede3878',
          title: 'Fiddle',
          startDate: '2021-01-07T23:00:00.000Z',
          endDate: '2021-01-07T23:00:00.000Z',
          startTime: '2021-01-07T7:37:00',
          endTime: '2021-01-07T8:37:00',
          description: 'This is a test from the account',
          link: 'http://lectie.com',
          color: '#FF7F09',
          image:
            'https://bespoke-image.s3.amazonaws.com/08dacd49-bc10-42ff-83bc-d8622a0000f7/cover/imageUPNTHMJU2T',
        },
      ],
    },
    {
      date: '2021-2',
      background: '',
      events: [],
    },
    {
      date: '2021-3',
      background: '',
      events: [],
    },
    {
      date: '2021-4',
      background: '',
      events: [],
    },
    {
      date: '2021-5',
      background: '',
      events: [
        {
          id: '126eade-f765-dedd-c7d5-33e2dede3878',
          title: 'May Event',
          startDate: '2021-05-07T23:00:00.000Z',
          endDate: '2021-05-07T23:00:00.000Z',
          startTime: '2021-05-07T7:37:00',
          endTime: '2021-05-07T8:37:00',
          description: 'This is a test from the account',
          link: 'http://lectie.com',
          color: '#FF7F09',
          image:
            'https://bespoke-image.s3.amazonaws.com/08dacd49-bc10-42ff-83bc-d8622a0000f7/cover/imageUPNTHMJU2T',
        },
      ],
    },
    {
      date: '2021-6',
      background: '',
      events: [],
    },
    {
      date: '2021-7',
      background: '',
      events: [],
    },
    {
      date: '2021-8',
      background: '',
      events: [],
    },
    {
      date: '2021-9',
      background: '',
      events: [],
    },
    {
      date: '2021-10',
      background: '',
      events: [],
    },
    {
      date: '2021-11',
      background: '',
      events: [],
    },
    {
      date: '2021-12',
      background: '',
      events: [],
    },
  ],
  coverView: true,
  customColors: [
    {
      id: 1,
      color: '#FFDAC0',
      label: 'Birthday',
      customId: 1,
    },
    {
      id: 2,
      color: '#FFEDAC',
      label: 'Anniversary ',
      customId: 2,
    },
    { id: 3, color: '#BAFFC1', label: 'Meetings', customId: 3 },
    { id: 4, color: '#B4DFFF', label: 'Work out', customId: 4 },
    { id: 5, color: '#FDB8B8', label: 'Home work', customId: 5 },
    { id: 6, color: '#FFCEEC', label: 'Holiday', customId: 6 },
  ],
  templateId: '',
  share: false,
  web: '',
  twitter: '',
  linkedin: '',
  facebook: '',
  templateId: '',
  footer: {},
  calendarPlan: '',
};

export const beskpoke = createSlice({
  name: 'bespoke',
  initialState,
  reducers: {
    loadBespoke: (state, action) => {
      return {
        ...state,
        ...initialState,
        ...action.payload,
      };
    },
    setCoverView: (state, { payload }) => {
      return {
        ...state,
        coverView: payload,
      };
    },
    setCustomColors: (state, { payload }) => {
      return {
        ...state,
        customColors: payload,
      };
    },
    updateBespokeMonths: (state, { payload }) => {
      return {
        ...state,
        months: payload,
      };
    },

    addYearToBepoke: (state, { payload }) => {
      return {
        ...state,
        months: [...state.months, ...payload],
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loadBespoke,
  setCoverView,
  setCustomColors,
  updateBespokeMonths,
  addYearToBepoke,
} = beskpoke.actions;

export default beskpoke.reducer;
