import React from 'react';
import { GridPitchContainer } from './Main.styles';
import GridPitchComponent from './GridPitchComponent';
import BlueCicle from '../../assest/images/home_pitch_circle.png';

// Data

const dataOne = [
  {
    title: 'Craft and Share',
    content:
      'Elevate your events with photo-rich calendars that tell a story. Create personal memories, or let your brand shine with unique, shareable calendars.',
  },
  {
    title: 'Public and Private Calendars',
    content:
      'Tailor your sharing. Create public calendars for community subscriptions, or keep it intimate with private sharing options.',
  },
  {
    title: 'Engage in Real-Time',
    content:
      'Blend the lines between calendar and social media. Like, comment, share, and revel in the communal excitement leading up to each event.',
  },
];

const dataTwo = [
  {
    title: `Smart 'Status' Feed`,
    content:
      'Curate your event feed. Stay informed and feel the heartbeat of upcoming events and personal milestone.',
  },
  {
    title: 'One-Tap Join',
    content:
      'Make virtual participation effortless. Join online events directly, ensuring you’re always just a tap away from community connection.',
  },
  {
    title: 'Stay connected',
    content:
      'Create a world where connections are seamless, experiences are shared, and inspiration knows no bounds.',
  },
];

// Main Component
const GridPitch = () => {
  return (
    <GridPitchContainer>
      <div className="circle_design_grid_pitch">
        <img src={BlueCicle} alt="Blue circle design" />
      </div>
      <section className="main_container">
        <section className="section_container">
          {dataOne.map(({ title, content }) => (
            <GridPitchComponent title={title} content={content} />
          ))}
        </section>
        <section className="section_container">
          {dataTwo.map(({ title, content }) => (
            <GridPitchComponent
              showBottomStar={true}
              title={title}
              content={content}
            />
          ))}
        </section>
      </section>
    </GridPitchContainer>
  );
};

export default GridPitch;
