import React from 'react';
import BrokenLink from '../../assest/images/broken_link_logo.svg';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const TemplateErrorPage = () => {
  const { t } = useTranslation(['dashboard']);
  const navigate = useNavigate();

  return (
    <BrokenContainer className="broken_link_container">
      <div className="broken_link__header">
        <h1>Ohh, Ohh We Are Sorry</h1>
        <p className="broken_link_text">
          {t('The link is broken, lets guide you back')}
        </p>
      </div>

      <div className="btn_container">
        <button className="btn_right" onClick={() => navigate('/dashboard/')}>
          {t('Dashboard')}
        </button>
      </div>

      <div className="img_container">
        <img src={BrokenLink} alt="Broken link picture" />
      </div>
    </BrokenContainer>
  );
};

export default TemplateErrorPage;

const BrokenContainer = styled.div`
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding: 2rem 0;
  margin-top: 5rem;

  .broken_link__header {
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 2rem;
      color: #0a0a0a;
      text-align: center;
    }

    p {
      font-weight: 400;
      font-size: 0.8rem;
      line-height: 33px;
      text-align: center;
      color: #0a0a0a;
    }
  }

  .btn_container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    button {
      padding: 12px;
      width: 230px;
      font-size: 0.9rem;
      text-align: center;
      color: #fff;
      background: #2667b1;
      border-radius: 12px;
      cursor: pointer;
    }
  }
  .img_container {
    margin-top: 4rem;
    max-width: 330px;

    img {
      width: 100%;
    }
  }
`;
