// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: 'AIzaSyCubYDrPnsn9r9H4BgJiWstK7XTmAUwb4c',
//   authDomain: 'bespoke-cc4a2.firebaseapp.com',
//   projectId: 'bespoke-cc4a2',
//   storageBucket: 'bespoke-cc4a2.appspot.com',
//   messagingSenderId: '773153921642',
//   appId: '1:773153921642:web:5efe33f98958193836c813',
//   measurementId: 'G-358VHY60CL',
// };
const firebaseConfig = {
  apiKey: 'AIzaSyCubYDrPnsn9r9H4BgJiWstK7XTmAUwb4c',
  authDomain: 'bespoke-cc4a2.firebaseapp.com',
  projectId: 'bespoke-cc4a2',
  storageBucket: 'bespoke-cc4a2.appspot.com',
  messagingSenderId: '773153921642',
  appId: '1:773153921642:web:5efe33f98958193836c813',
  measurementId: 'G-358VHY60CL',
};
// const firebaseConfig = {
//   apiKey: "AIzaSyAixuAO0ojTIGPCvxzb7TFqWoWz0MtND9c",
//   authDomain: "lectie-95696.firebaseapp.com",
//   projectId: "lectie-95696",
//   storageBucket: "lectie-95696.appspot.com",
//   messagingSenderId: "897303185634",
//   appId: "1:897303185634:web:473280c3f03c6db077b6bb",
//   measurementId: "G-7B67MWQ1KZ"
// };

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const messaging = getMessaging();

// Background message listener
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      // console.log('payload', payload);
      resolve(payload);
    });
  });

// Request Device Token
export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey: process.env.REACT_APP_PUSH_NOTIFICATION,
  })
    .then((currentToken) => {
      if (currentToken) {
        // console.log('current token for client: ', currentToken);
        localStorage.setItem('device_token', currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          'No registration token available. Request permission to generate one.'
        );
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};
