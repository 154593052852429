import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { getMonth } from '../../../util';
import Dialog from '@material-ui/core/Dialog';
import ShareEvent from '../../component/shareEvent';
import { loadEventsGroup } from '../../../store/reducers/templateEvents';
import TemplateThreeMonth from './component/templateThreeMonth';
import {
  setCustomColors,
  updateBespokeMonths,
} from '../../../store/reducers/bespokeFilter';
import Tick from '../../../assest/images/tick_label.png';
import client from '../../../services';
import { toast } from 'react-toastify';
import RectSkeletonLoader from '../RectSkeleton';
import { getCountyCode, userGeolocation } from '../../../functions';

const BespokeViewTemplateThree = () => {
  let max = 2;
  let min = 0;
  var theme = Math.floor(Math.random() * (max - min + 1)) + min;
  const dispatch = useDispatch();

  const beskpoke = useSelector((state) => state.bespoke);
  const beskpokeFilter = useSelector((state) => state.beskpokeFilter);

  console.log('BKP ', beskpoke);
  console.log('BKPFilter ', beskpokeFilter);

  const defaultImage = useSelector((state) => state.default);
  const [months, setMonths] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  // This is to get the days of the week
  const [week, setWeek] = useState([]);
  let allYear = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  // Group Event and Filtered Event on See More
  const eventsGroup = useSelector((state) => state.eventSlice);
  const [moreEvent, setMoreEvent] = useState([]);
  const [eventPopup, setEventPopup] = useState(false);
  const [popIndex, setPopIndex] = useState(0);
  const [shareOpen, setShareOpen] = useState(false);

  // Styles

  // Share Event
  const handleClickOpenShare = () => {
    setShareOpen(true);
  };
  const handleClose = () => {
    setShareOpen(false);
  };

  const updateCustomColors = (id) => {
    let customColors = beskpokeFilter?.customColors.map((color) => {
      if (color?.id === id) {
        return {
          ...color,
          isPresent: color.isPresent === true ? false : true,
        };
      }
      return { ...color };
    });

    dispatch(setCustomColors(customColors));
  };

  const updateCustomColorsApi = async () => {
    console.log(' IN color api ');
    setIsLoading(true);
    const isPresentColors = beskpokeFilter?.customColors.filter(
      (color) => color?.isPresent === true
    );

    let country = new userGeolocation().getUserCountry();
    let { CountryCode } = getCountyCode(country) || 'NGN';

    let bodyData = {
      colors: [...beskpokeFilter?.customColors],
      country: CountryCode,
    };

    let year = beskpoke?.yearPicked;

    // Send customColors to end point
    await client
      .post(
        `/Souvenir/souvenirMonthsByYear/${beskpoke?.id}?year=${year}`,
        bodyData
      )
      .then((res) => {
        // Get Response and save to Bespoke months

        console.log('Rizot 3 is ', res?.data);

        dispatch(updateBespokeMonths(res.data));

        setIsLoading(false);
        toast.success('Event Filtered Successfully');
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err?.message);
      });
  };

  return (
    <BespokeViewTemplateThreeContainer>
      <section className="label-main-wrapper">
        <ul className="label-main-container">
          <li className="events_label">Events label:</li>
          {beskpokeFilter?.customColors.map(
            ({ id, label, color, isPresent }) => (
              <li
                key={id}
                className="color_container"
                onClick={() => updateCustomColors(id)}
              >
                <span className="color" style={{ backgroundColor: color }}>
                  {isPresent === true && <img src={Tick} alt="checkbox tick" />}
                </span>
                <p style={{ backgroundColor: color, opacity: '0.7' }}>
                  {label}
                </p>
              </li>
            )
          )}
          <li
            className="label_filter"
            onClick={() => {
              if (!isLoading) {
                console.log('Pointer');
                updateCustomColorsApi();
              }
            }}
          >
            <span className="filter_btn">Filter events</span>
          </li>
        </ul>
      </section>

      {!isLoading ? (
        <TemplateThreeMonth
          popIndex={popIndex}
          setPopIndex={setPopIndex}
          eventPopup={eventPopup}
          setEventPopup={setEventPopup}
          moreEvent={moreEvent}
          setMoreEvent={setMoreEvent}
          beskpoke={beskpokeFilter}
          defaultImage={defaultImage}
          theme={theme}
          handleClickOpenShare={handleClickOpenShare}
        />
      ) : (
        <RectSkeletonLoader />
      )}
      {/* Share PopUp */}

      <Dialog
        open={shareOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ShareEvent data={moreEvent[popIndex]?.id} />
      </Dialog>
    </BespokeViewTemplateThreeContainer>
  );
};

export default BespokeViewTemplateThree;

const BespokeViewTemplateThreeContainer = styled.section`
  width: 95%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  gap: 6rem;

  .event_pop_container {
    z-index: 4;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .event_content {
      position: relative;
      z-index: 6;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 90%;
      max-width: 540px;
      margin: 0 auto;
      padding: 2rem 0;
      box-shadow: 4px -3px 22px 12px rgba(17, 17, 17, 0.25),
        -5px 6px 22px 12px rgba(17, 17, 17, 0.25);
      border-radius: 40px;
      height: 540px;

      overflow-y: scroll;
      overflow-wrap: break-word;
      -ms-overflow-style: none;
      scrollbar-width: 10px;
      overflow: -moz-scrollbars-none;

      &::-webkit-scrollbar {
        width: 0 !important;
        display: none;
      }

      .heading {
        display: flex;
        align-items: center;
        align-self: flex-end;
        gap: 1.5rem;
        padding-right: 2rem;
        position: fixed;
        z-index: 7;

        svg {
          font-size: 2rem;
          cursor: pointer;
        }
      }

      .btn_container {
        margin-top: 1rem;
        align-self: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        width: 100%;

        .event_pop_btn,
        .event_pop_btn_join {
          text-decoration: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 12px;
          gap: 12px;
          width: 70%;
          min-width: 200px;
          height: 54px;
          background: #2667b1;
          border: 1px solid #2667b1;
          border-radius: 12px;
          flex: none;
          order: 1;
          flex-grow: 0;
          color: #fff;
        }

        .event_pop_btn_join {
          background-color: transparent;

          color: #111111;
        }
      }

      .image {
        max-width: 400px;
        width: 90%;
        height: auto;
        margin: 1rem auto;
        align-self: center;
      }

      .body_event_pop {
        display: flex;
        flex-direction: column;
        gap: 1.3rem;
        margin-top: 2.4rem;

        h2 {
          font-family: 'Nunito';
          font-style: normal;
          font-weight: 700;
          font-size: 2rem;
          line-height: 44px;
          color: #000000;
          text-align: center;
          width: 90%;
          max-width: 350px;
          height: 100%;
          max-height: 200px;
          overflow-y: scroll;
          overflow-wrap: break-word;
          -ms-overflow-style: none;
          scrollbar-width: none;
          overflow: -moz-scrollbars-none;
          margin: 0 auto;

          &::-webkit-scrollbar {
            width: 0 !important;
            display: none;
          }
        }

        .description {
          p {
            width: 90%;
          }
        }
        .event_details {
          align-self: center;
          display: flex;
          gap: 1rem;
          width: 100%;
          max-width: 470px;
          margin: 0 auto;

          .reminders_container {
            display: flex;
            flex-direction: column;
            gap: 0.4rem;
          }

          .inc_dec {
            display: flex;
            justify-content: space-between;
            padding: 0 0.5rem;

            .left,
            .right {
              width: 2.2rem;
              height: 2.2rem;
              padding: 0.5rem;
              border-radius: 100%;
              font-weight: bold;
              box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
                rgba(17, 17, 26, 0.1) 0px 8px 24px,
                rgba(17, 17, 26, 0.1) 0px 16px 56px;
            }

            .left {
              align-self: center;
            }
            .right {
              align-self: center;
            }
          }

          .event_detail_para {
            max-height: 150px;
            height: 100%;
            overflow-y: scroll;
            overflow-wrap: break-word;
            -ms-overflow-style: none;
            scrollbar-width: none;
            overflow: -moz-scrollbars-none;

            &::-webkit-scrollbar {
              width: 0 !important;
              display: none;
            }
          }

          .location {
            svg {
              font-size: 5rem;
            }
          }

          .detail_container {
            width: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            gap: 1.2rem;

            .event_detail {
              display: flex;
              align-items: center;
              gap: 1.2rem;

              svg {
                font-size: 1.5rem;
              }
            }
            .time_container {
              .time {
                display: flex;
                flex-direction: column;
                gap: 0.2rem;

                span {
                  font-style: normal;
                  font-weight: 700;
                  line-height: 22px;
                  color: #0a0a0a;
                  flex: none;
                  order: 0;
                  flex-grow: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .image_container {
    img {
      border-radius: 0px 0px 20px 20px;
      height: auto;
      width: 100%;
      image-rendering: crisp-edges;
    }
  }

  .grid_sys_week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }

  .grid_sys_month {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-auto-rows: minmax(100px, auto);
    flex: 1;
    width: 100%;
  }

  .calendar_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: -3rem;
    width: 100%;

    .calendar {
      width: 100%;
    }
    .calendar_month {
      font-size: 1.6rem;
    }

    .week_day_mobile {
      display: none;
    }

    .weeks {
      border: 1px solid #000;
    }

    .monthContainer {
      width: 100%;
    }
  }
  display: flex;
  flex-direction: column;
  gap: 3.5rem;

  .label-main-wrapper {
    width: 95%;
    max-width: 1350px;
    margin: 0 auto;
    .label-main-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 1.2rem;

      .events_label {
        color: #111111;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: bolder;
        font-size: 1rem;
      }

      .color_container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        cursor: pointer;

        .color {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 100%;
        }

        p {
          padding-left: 0.8rem;
          padding-right: 0.8rem;
          height: 100%;
          font-weight: 550;
          font-size: 0.9rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .label_filter {
      cursor: pointer;
      width: 123px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      border: 1px solid #dadada;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      border-radius: 4px;
      .filter_btn {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 24px;
        color: #0a0a0a;
      }
    }
  }

  @media screen and (max-width: 640px) {
    .week_day_mobile {
      display: block !important;
    }
    .label-main-wrapper {
      display: none;
    }

    .week_day_desktop {
      display: none;
    }

    .image_container {
      img {
        height: 260px;
        image-rendering: crisp-edges;
      }
    }
  }
`;

const DayTemplateContainer = styled.div`
  height: 147px;
  overflow-y: hidden;
  padding: 0.2rem 0 1rem 0.2rem;
  border: 1px solid #333;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  background-color: ${({ color }) => (color ? '#000' : '#fff')};

  .date_day {
    margin: 0.5rem 0.2rem;
    color: #161518;
  }

  .events_container {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    .event {
      position: relative;
      display: flex;
      align-items: center;
      gap: 0.2rem;
      cursor: pointer;
      margin-bottom: 0.1rem;
      width: 100%;
      .color {
        width: 7px;
        height: 7px;
        border-radius: 100%;
      }

      .time,
      .title {
        height: 100%;
        font-size: 0.8rem;
        white-space: nowrap;
        overflow-x: hidden;
      }
      .title {
        width: 60%;
        text-overflow: ellipsis;
      }
      .time {
        width: 40%;
        color: #464646;
      }
    }
  }
  .see_more {
    margin-top: 0.7rem;
    font-size: 0.85rem;
    cursor: pointer;
  }

  @media screen and (max-width: 1055px) {
    .events_container {
      .event {
        .hide_mobile {
          display: none;
        }
        .title {
          width: 90%;
        }
      }
    }
  }
`;
