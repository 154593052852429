import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  id: '08db7c99-81b1-4ff1-8004-3bbdf945f849',
  calendarPlan: 'Premium',
  privacy: 'owner',
  qrCode: '',
  sendNotification: true,
  share: false,
  slogan: '5',
  status: 'published',
  templateId: '4',
  templateName: '5',
  to: '',
  twitter: '',
  yearPicked: '2024',
  loadingDraft: false,
  events: [],
};

export const tvGuideDraft = createSlice({
  name: 'tvGuideDraft',
  initialState,
  reducers: {
    reloadTvDraft: (state) => {
      return {
        ...state,
        ...initialState,
      };
    },
    setTVDraft: (state, { payload }) => {
      return {
        ...initialState,
        ...payload,
      };
    },
    setLoadingDraft: (state, { payload }) => {
      return {
        ...state,
        loadingDraft: payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTVDraft, setLoadingDraft, reloadTvDraft } =
  tvGuideDraft.actions;

export default tvGuideDraft.reducer;
