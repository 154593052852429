import React, { useEffect } from 'react';
// import Footer from '../../Home/body/Footer';
import Nav from '../../Home/Nav';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/Footer';

const TermsConditions = () => {
  const { t } = useTranslation(['terms']);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Nav />
      <div className="bg-white dark:bg-white">
        <div className="py-2 lg:py-2 px-4 mx-auto max-w-screen-md">
          <h2 className="mb-4 text-2xl tracking-tight font-extrabold text-center text-[#2667B1] dark:text-[#ed7d31]">
            {t('LECTIE – Terms and Conditions')}
          </h2>

          <section>
            <div className="flex space-x-4">
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">1</h2>
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">
                {t('Our Disclosures')}
              </h2>
            </div>
            <div className="flex space-x-4">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                &nbsp; &nbsp; &nbsp;
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs border border-black p-2">
                {t(
                  'Our complete terms and conditions are contained below, but some important points for you to know before you become a user are set out below:'
                )}
                <br /> -{' '}
                {t(
                  'We may amend these Terms and the features of the Platform at any time, by providing written notice to you'
                )}
                ;
                <br /> -{' '}
                {t(
                  'We will handle your personal information in accordance with our privacy policy, available on the Platform'
                )}
                ;
                <br /> -{' '}
                {t(
                  'Our liability under these Terms is limited to any fees paid by you for use of the Platform, and we will not be liable for Consequential Loss, any loss that is a result of a Third Party Service, or any loss or corruption of data'
                )}
                ;
                <br /> -{' '}
                {t(
                  'We may terminate your Membership at any time by giving 30 days’ written notice to you. Nothing in these terms limit your rights under the Australian Consumer Law.'
                )}
              </p>
            </div>

            <div className="flex space-x-4">
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">2</h2>
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">
                {t('Introduction')}
              </h2>
            </div>
            <div className="flex space-x-4">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                2.1
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t('These terms and conditions')}{' '}
                <span className="font-bold text-xs">{t('(Terms)')} </span>
                {t(
                  'are entered into between Timebly Pty Ltd t/as Lectie ABN 56 651 956 964 (we, us or our) and you, together the Parties and each a Party. We provide a timetable application, events RSVP management and social media platform (Platform).'
                )}
              </p>
            </div>
            <div className="flex space-x-4">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                2.2
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'We provide a timetable application, events RSVP management and social media platform (Platform).'
                )}{' '}
              </p>
            </div>

            <div className="flex space-x-4">
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">3</h2>
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">
                {t('Acceptance and Platform License')}
              </h2>
            </div>

            <div className="flex space-x-4">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                3.1
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'You accept these Terms by downloading our mobile application, using the Platform or otherwise accepting these Terms as set out on the Platform.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                3.2
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'You must be at least 4 years old to use the Platform and if you are between 4 and 16 years old, you must have consent or permission from your parent(s) or guardian to use the Platform.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                3.3
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'We may amend these Terms at any time, by providing written notice to you. By clicking “I accept” or continuing to use the Platform after the notice or 30 days after notification (whichever date is earlier), you agree to the amended Terms.'
                )}{' '}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                3.4
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'If you do not agree to the amendment, you may terminate your Membership in accordance with the Termination clause. If you access or download our mobile application from (1) the Apple App Store, you agree to any Usage Rules set forth in the App Store Terms of Service or (2) the Google Play Store, you agree to the Android, Google Inc. Terms and Conditions including the Google Apps Terms of Service.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                3.5
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'Subject to your compliance with these Terms, we grant you a personal, non-exclusive, royalty-free, revocable, worldwide, non-transferable licence to download and use our Platform in accordance with these Terms. All other uses are prohibited without our prior written consent.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                3.6
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  "When using the Platform, you must not do or attempt to do anything that is unlawful or inappropriate, including: <br />{' '} (a) anything that would constitute a breach of an individual’s privacy (including uploading private or personal information without an individual's consent) or any other legal rights"
                )}
                ; <br />
                (b) {t(
                  'posting any inappropriate content including nudity'
                )}; <br />
                (c){' '}
                {t(
                  'using the Platform to defame, harass, threaten, menace or offend any person, including using the Platform to send unsolicited electronic messages (spamming)'
                )}
                ; <br />
                (d){' '}
                {t(
                  'tampering with or modifying the Platform (including by transmitting viruses and using trojan horses)'
                )}
                ; <br />
                (e){' '}
                {t(
                  'using data mining, robots, screen scraping or similar data gathering and extraction tools on the Platform; or'
                )}{' '}
                <br />
                (f){' '}
                {t(
                  'facilitating or assisting a third party to do any of the above acts.'
                )}
              </p>
            </div>
            <div className="flex space-x-4">
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">4</h2>
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">
                {t('Lectie Services')}
              </h2>
            </div>

            <div className="flex space-x-4">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                4.1
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'After downloading our mobile application or while using our web application, you must login to create an account (Account) to access the Platform’s features.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                4.2
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'You may login using your email or using your Facebook, Google or other social media network account (Social Media Account). If you create an Account using your Social Media Account, you authorise us to access certain information on your Social Media Account as set out on the Platform.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                4.3
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'You can choose to sync and integrate various third party services (such as calendars, social media and contact lists) as set out on the Platform. It is your choice what you link and you can change your mind at any time and delink the third party service.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                4.4
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'Many features of the Platform allow you to share information with other people. You choose your privacy settings and decide who can view different pieces of content. The other user may need an account on Lectie in order to view certain information you make available to them.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                4.5
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'All personal information you provide to us will be treated in accordance with our Privacy Policy.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                4.6
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'You agree to provide and maintain up to date information in your Account and to not share your Account password with any other person. Your Account is personal and you must not transfer or provide it to others.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                4.7
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'You are responsible for keeping your Account details and your username and password confidential and you will be liable for all activity on your Account, including purchases made using your Account details. You agree to immediately notify us of any unauthorised use of your Account.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                4.8
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'When you create an Account, you may also be required to select a membership (Membership). You may choose between different tiers of Membership with different services and different membership periods as set out on our Platform.'
                )}
              </p>
            </div>
            <div className="flex space-x-4">
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">5</h2>
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">
                {t('Our Intellectual Property')}
              </h2>
            </div>

            <div className="flex space-x-4">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                5.1
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'You acknowledge and agree that any Intellectual Property or content (including copyright and trademarks) available on the Platform, the Platform itself, and any algorithms or machine learning models used on the Platform (Our Intellectual Property) will at all times vest, or remain vested, in us.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                5.2
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'We authorise you to use Our Intellectual Property solely for your personal use. You must not exploit Our Intellectual Property for any other purpose, nor allow, aid or facilitate such use by any third party. You may only access Our Intellectual Property on your personal device, and you may not use Our Intellectual Property for any commercial purpose.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                4.3
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t('You must not, without our prior written consent:')}
                <br /> (a)
                {t(
                  'copy, in whole or in part, any of Our Intellectual Property'
                )}
                ;
                <br /> (b)
                {t(
                  'reproduce, retransmit, distribute, disseminate, sell, publish, broadcast or circulate any of Our Intellectual Property to any third party; or'
                )}
                <br /> (c)
                {t(
                  'breach any intellectual property rights connected with the Platform, including (without limitation) altering or modifying any of Our Intellectual Property, causing any of Our Intellectual Property to be framed or embedded in another website, or creating derivative works from any of Our Intellectual Property.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                5.4
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'This clause will survive the termination or expiry of your Membership.'
                )}
              </p>
            </div>

            <div className="flex space-x-4">
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">6</h2>
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">
                {t('Your Data')}
              </h2>
            </div>

            <div className="flex space-x-4">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                6.1
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'You own all data, information or content you upload into the Platform (Your Data).'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                6.2
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'You grant us a limited licence to copy, transmit, store, backup and/or otherwise access or use Your Data to:'
                )}{' '}
                <br /> (a)
                {t(
                  'communicate with you (including to send you information we believe may be of interest to you)'
                )}
                ; <br /> (b){' '}
                {t(
                  'supply the Platform to you and otherwise perform our obligations under these Terms'
                )}
                ; <br /> (c) {'diagnose problems with the Platform'};<br /> (d){' '}
                {t('enhance and otherwise modify the Platform')};
                <br /> (e) {t('perform Analytics')}; <br /> (f){' '}
                {t(
                  'develop other services,provided we de-identify Your Data; and'
                )}{' '}
                <br /> (g){' '}
                {t(
                  'as reasonably required to perform our obligations under these Terms.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p
                className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs
             "
              >
                6.3
              </p>
              <p
                className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs
             "
              >
                {t(
                  'You agree that you are solely responsible for all of Your Data that you make available on or through the Platform. You represent and warrant that:'
                )}
                <br /> (a)
                {t(
                  'nor the posting, uploading, publication, submission oryou are either the sole and exclusive owner of Your Data or you have all rights, licences, consents and releases that are necessary to grant to us the rights in Your Data (ascontemplated by these Terms); and'
                )}{' '}
                <br /> (a){' '}
                {t(
                  'neither Your Data transmission of Your Data or our use of Your Data on, through or by means of our Platform will infringe, misappropriate or violate a third party’s intellectual property rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                6.4
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'You acknowledge and agree that we may monitor, analyse and compile statistical and performance information based on and/or related to your use of the Platform, in an aggregated and anonymised format (Analytics). You acknowledge and agree that we own all rights in the Analytics, and that we may use the Analytics for our own internal business purposes, provided that the Analytics do not contain any identifying information.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                6.5
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'We do not endorse or approve, and are not responsible for, any of Your Data.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                6.6
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'You acknowledge and agree that the Platform and the integrity and accuracy of the Lectie Services is reliant on the accuracy and completeness of Your Data, and the provision by you of Your Data that is inaccurate or incomplete may affect the use, output and operation of the Platform.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                6.7
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'This clause will survive the termination or expiry of your Membership.'
                )}
              </p>
            </div>
            <div className="flex space-x-4">
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">7</h2>
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">
                {t('Warranties')}{' '}
              </h2>
            </div>

            <div className="flex space-x-4">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                7.1
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t('You represent, warrant and agree that:')}
                <br /> (a){' '}
                {t(
                  'you will not use our Platform, including Our Intellectual Property, in any way that competes with our business;'
                )}
                <br /> (b){' '}
                {t(
                  'there are no legal restrictions preventing you from entering into these Terms;'
                )}
                <br /> (c){' '}
                {t(
                  'all information and documentation that you provide to us in connection with these Terms is true, correct and complete; and'
                )}
                <br /> (d){' '}
                {t(
                  'you have not relied on any representations or warranties made by us in relation to the Platform (including as to whether the Platform is or will be fit or suitable for your particular purposes), unless expressly stipulated in these Terms.'
                )}
              </p>
            </div>
            <div className="flex space-x-4">
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">8</h2>
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">
                {t('Australian Customer Law')}
              </h2>
            </div>

            <div className="flex space-x-4">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                8.1
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'Certain legislation, including the Australian Consumer Law (ACL) in the Competition and Consumer Act 2010 (Cth), and similar consumer protection laws and regulations, may confer you with rights, warranties, guarantees and remedies relating to the provision of the Platform by us to you which cannot be excluded, restricted or modified (Consumer Law Rights).'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                8.2
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'If the ACL applies to you as a consumer, nothing in these Terms excludes your Consumer Law Rights as a consumer under the ACL. You agree that our Liability for the Platform provided to an entity defined as a consumer under the ACL is governed solely by the ACL and these Terms.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                8.3
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'Subject to your Consumer Law Rights, we exclude all express and implied warranties, and all material, work and services (including the Platform) are provided to you without warranties of any kind, either express or implied, whether in statute, at law or on any other basis.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                8.4
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'This clause will survive the termination or expiry of your Membership.'
                )}
              </p>
            </div>
            <div className="flex space-x-4">
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">9</h2>
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">
                {t('Liability')}
              </h2>
            </div>

            <div className="flex space-x-4">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                9.1
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'You acknowledge and agree that the Platform may be reliant on, or interface with third party systems that are not provided by us (for example, cloud storage providers, third parties whose APIs we use such as Google) (Third Party Services). To the maximum extent permitted by law, we shall have no Liability for any Third Party Services, or any unavailability of the Platform due to a failure of the Third Party Services.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                9.2
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'You acknowledge and agree that data loss is an unavoidable risk when using any software. To the extent you input any data into the Platform, you agree to maintain a backup copy of any data you input into the Platform.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                9.3
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'To the maximum extent permitted by law, we shall have no Liability to you for any loss or corruption of data, or any scheduled or emergency maintenance that causes the Platform to be unavailable.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                9.4
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'Despite anything to the contrary, to the maximum extent permitted by law:'
                )}
                <br /> (a){' '}
                {t(
                  'you agree to indemnify us for any Liability we incur due to your breach of the Acceptance and Platform Licence clause and the Intellectual Property clause of these Terms;'
                )}
                <br /> (b){' '}
                {t('neither Party will be liable for Consequential Loss;')}
                <br /> (c){' '}
                {t(
                  'each Party’s liability for any Liability under these Terms will be reduced proportionately to the extent the relevant Liability was caused or contributed to by the acts or omissions of the other Party or any of that Party’s personnel, including any failure by that Party to mitigate its losses; and'
                )}
                <br /> (d){' '}
                {t(
                  'our aggregate liability for any Liability arising from or in connection with these Terms will be limited to us repaying you the amount of the Fees paid by you to us during the term of your Membership.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                9.5
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'This clause will survive the termination or expiry of your Membership.'
                )}
              </p>
            </div>

            <div className="flex space-x-4">
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">10</h2>
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">
                {t('Termination')}
              </h2>
            </div>

            <div className="flex space-x-4">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                10.1
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'Cancellation of Memberships: You may request to cancel your Membership at any time by notifying us via email or via ‘cancel my membership’ feature in your Account or on the mobile application.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                6.2
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'We may terminate your Membership at any time by giving 30 days’ written notice to you (Termination for Convenience).'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                10.3
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'A Membership will terminate immediately upon written notice by a Party (Non-Defaulting Party) if:'
                )}
                <br /> (a){' '}
                {t(
                  'the other Party (Defaulting Party) breaches a material term of these Terms and if that breach is capable of remedy, has not been remedied within 10 Business Days of'
                )}
                <br /> (b){' '}
                {t(
                  'the Defaulting Party being notified of the breach by the Non-Defaulting Party; or the Defaulting Party is unable to pay its debts as they fall due.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                10.4
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'Should we suspect that you are in breach of these Terms (including where we suspect you have posted inappropriate content such as nudity or where we suspect that you have been spamming), we may suspend your access to the Platform while we investigate the suspected breach. If our investigation shows that you have posted inappropriate content or that you have been, we will terminate your Account immediately.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                10.5
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'Upon expiry or termination of your Membership we will remove your access to the Platform.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                10.6
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'Termination of a Membership will not affect any rights or liabilities that a Party has accrued under these Terms.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                10.7
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'This clause will survive the termination or expiry of your Membership.'
                )}
              </p>
            </div>
            <div className="flex space-x-4">
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">11</h2>
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">
                {t('Notice Regarding Apple')}
              </h2>
            </div>

            <div className="flex space-x-4">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                11.1
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'To the extent that you are using or accessing our Platform on an iOS device, you further acknowledge and agree to the terms of clause. You acknowledge that these Terms are between you us only, not with Apple Inc. (Apple), and Apple is not responsible for the Platform and any content available on the Platform.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                11.2
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'Apple has no obligation to furnish you with any maintenance and support services with respect to our Platform.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                11.3
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'If our mobile application fails to conform to any applicable, you may notify Apple and Apple will refund the purchase price of the mobile application to you. To the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the mobile and any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any will be our responsibility.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                11.4
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                Apple is not responsible for addressing any claims by you or any
                party relating to our mobile application or your use of our
                mobile application, including but not limited to: (1) liability
                claims; (2) any claim that our mobile application fails to
                conform to any applicable legal or regulatory requirement; and
                (3) claims arising under consumer protection or similar
                legislation.
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                11.5
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'Apple is not responsible for the investigation, defence,settlement and discharge of any third-party claim that our mobile application infringes that third party’s intellectual property rights.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                11.6
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  ' You agree to comply with any applicable third-party terms when using our mobile application.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                11.7
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'Apple and Apple subsidiaries are third-party beneficiaries of these Terms, and upon your acceptance of these Terms, Apple will the right (and will be deemed to have accepted the right) to enforce these Terms against you as a third-party beneficiary these Terms.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                11.8
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'You hereby represent and warrant that: (1) you are not located a country that is subject to a U.S. Government embargo, or has been designated by the U.S. Government as a "terrorist supporting" country; and (2) you are not listed on any U.S. Government list of prohibited or restricted parties.'
                )}
              </p>
            </div>
            <div className="flex space-x-4">
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">12</h2>
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">
                {t('General')}
              </h2>
            </div>

            <div className="flex space-x-4">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                12.1
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'Assignment: Subject to the below clause, a Party must not assign or deal with the whole or any part of its rights or obligations under these Terms without the prior written consent of the other (such consent is not to be unreasonably withheld).'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                12.2
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'Assignment of Debt: You agree that we may assign or transfer any debt owed by you to us, arising under or in connection with these Terms, to a debt collector, debt collection agency, or third party.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                12.3
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'Disputes: A Party may not commence court proceedings relating to a dispute without first meeting with the other Party to seek (in good faith) to resolve the dispute, failing which the Parties to engage a mediator to attempt to resolve the dispute. The costs of the mediation will be shared equally between the Parties. Nothing in this clause will operate to prevent a Party seeking urgent injunctive or equitable relief from a court appropriate jurisdiction.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                12.4
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'Entire Terms: Subject to your Consumer Law Rights, these Terms contains the entire understanding between the Parties and the Parties agree that no representation or statement has been made to, or relied upon by, either of the Parties, except as expressly stipulated in these Terms, and these Terms supersedes all previous discussions, communications, negotiations, understandings, representations, warranties, commitments and agreements, in respect of its subject matter.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                12.5
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'Force Majeure: To the maximum extent permitted by law, we shall no Liability for any event or circumstance outside of our reasonable control.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                12.6
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'Governing law: This Agreement is governed by the laws of Queensland. Each Party irrevocably and unconditionally submits to the exclusive jurisdiction of the courts operating in Queensland and any courts entitled to hear appeals from those courts and waives any right to object to proceedings being brought in those courts.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                12.7
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'Notices: Any notice given under these Terms must be in writing addressed to us at the details set out below or to you at the details provided in your Account. Any notice may be sent by standard post or email, and will be deemed to have been served on the expiry of 48 hours in the case of post, or at the time of in the case of transmission by email.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                12.8
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'Privacy: We agree to comply with the legal requirements of the Privacy Principles as set out in the Privacy Act 1988 (Cth) and any other applicable legislation or privacy guidelines.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                12.9
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'as narrowly as necessary to allow it to be valid or enforceable, failing which, that provision (or that part of that provision) be severed from these Terms without affecting the validity enforceability of the remainder of that provision or the provisions in these Terms.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p
                className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs
             "
              >
                12.10
              </p>
              <p
                className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs
             "
              >
                {t(
                  'Third party sites: The Platform may contain links to websites operated by third parties. Unless we tell you otherwise, we do not control, endorse or approve, and are not responsible for, the content on those websites. We recommend that you make your investigations with respect to the suitability of those websites. If you purchase goods or services from a third party website linked from the Platform, such third party provides the goods and services to you, not us. We may receive a benefit (which may include a referral fee or a commission) should you visit certain third-party websites via a link on the Platform (Affiliate Link) or for featuring certain products or services on the Platform. We will make it clear by notice to you which (if any) products or services we receive a benefit to feature on the Platform, or which (if any) third party links are Affiliate Links.'
                )}
              </p>
            </div>

            <div className="flex space-x-4">
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">13</h2>
              <h2 className="dark:text-[#ed7d31] text-sm font-bold">
                {t('Definitions')}
              </h2>
            </div>

            <div className="flex space-x-4">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                13.1
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'Assignment: Subject to the below clause, a Party must not assign or deal with the whole or any part of its rights or obligations under these Terms without the prior written consent of the other Party (such consent is not to be unreasonably withheld).'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                13.2
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'Intellectual Property means any domain names, know-how,inventions, processes, trade secrets or confidential information; or circuit layouts, software, computer programs, databases or source codes, including any application, or right to apply, for registration of, and any improvements, or modifications of, the foregoing.'
                )}
              </p>
            </div>
            <div className="flex space-x-4 ">
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                13.3
              </p>
              <p className="mb-2 lg:mb-2 font-light text-left text-gray-500 dark:text-gray-600 sm:text-xs">
                {t(
                  'Liability means any expense, cost, liability, loss, damage, claim, notice, entitlement, investigation, demand, proceeding or judgment (whether under statute, contract, equity, tort (including negligence), indemnity or otherwise), howsoever arising, whether direct or indirect and/or whether present, unascertained, future or contingent and whether involving a third party or a party to these Terms or otherwise.'
                )}
              </p>
            </div>
          </section>
          <section>
            <p className="text-xs font-bold" style={{ lineHeight: 2 }}>
              {t('For any questions or notices, please contact us at:')}
            </p>
            <p className="text-xs" style={{ lineHeight: 2 }}>
              Timebly Pty Ltd t/as Lectie ABN 56 651 956 964
            </p>
            <p className="text-xs font-bold" style={{ lineHeight: 2 }}>
              {t('Email')}: support@lectie.com
            </p>
            <p className="text-xs font-bold" style={{ lineHeight: 2 }}>
              {t('Last update:')} 22 {t('November')} 2022
            </p>
            <p className="text-xs font-bold" style={{ lineHeight: 2 }}>
              © LegalVision ILP Pty Ltd
            </p>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsConditions;
