import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  makeStyles,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  Button,
  TextField,
  FormHelperText,
} from '@material-ui/core';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { RotatingLines } from 'react-loader-spinner';
import MasterCard from '../../assest/images/master_card.svg';
import VisaCard from '../../assest/images/visa.svg';
import VerveCard from '../../assest/images/verve.svg';
import DiscoverCard from '../../assest/images/discover.svg';
import './common.css';
import { toast } from 'react-toastify';
import client from '../../services';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../helpers/firebase';
import { updateUserSub } from '../../store/reducers/userReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { StripeContainer } from './style/subscription.style';
import SucessPage from './SucessPage';

const CARD_OPTIONS = {
  style: {
    base: {
      fontSize: '14px',
      color: '#424770',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: 'grey',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

// Material Ui Styles
const useStyles = makeStyles((theme) => ({
  TextInput: {
    borderRadius: 10,
    width: '100%',
  },
  btn: {
    clear: 'both',
    border: 'none',
    color: '#fff',
    maxWidth: '100px',
    width: '70%',
    height: '30px',
    margin: '0 auto',
    background: '#2667b1',
    borderRadius: '12px',
    cursor: 'pointer',
    '& .MuiButton-label': {
      fontSize: '14px',
    },
  },
  img: {
    display: 'flex',
    flexDirection: 'row',
  },
  warning: {
    color: '#ea0f0f',
    fontFamily: 'Roboto',
    fontSize: '0.8rem',
  },
}));

function StripePaymentForm(props) {
  const { t } = useTranslation(['dashboard']);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const [paidSuccessful, setPaidSuccessful] = useState(false);
  const userDeatls = useSelector((state) => state.user);

  const [user, setUser] = useState({
    email: userDeatls?.email,
    phone: userDeatls?.phone,
  });

  console.log('STAATE IS ', state);
  //  Payment display details
  const [selected] = useState(state);
  const [variation] = useState(true);

  const classes = useStyles();
  const [processing, setProcessing] = useState(false);

  // Stripe Payment init
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    setProcessing(true);
    e.preventDefault();
    const card = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: card,
      billing_details: {
        email: user.email,
        phone: user.phone ? user.phone : null,
      },
    });

    if (!error) {
      const { id } = paymentMethod;

      const data = {
        subscription: selected?.subscriptionSelected?.id,
        bespokeId: selected?.id,
        paymentId: id,
        currency: selected?.subscriptionSelected?.currency,
        qty: selected?.quantity,
      };

      const addMoreCalendarData = {
        subscription: selected?.subscriptionSelected?.id,
        paymentId: id,
        currency: state?.currency,
        qty: selected?.quantity,
      };

      console.log(' payload ', selected?.addPlans ? addMoreCalendarData : data);

      // Sucess function
      // Success Function should show only when successful

      await client
        .post(
          `/Subscription/verifySubscriptionPayment`,
          selected?.addPlans ? addMoreCalendarData : data
        )
        .then((res) => {
          // console.log(res?.data, 'subscription payment');
          setProcessing(false);
          logEvent(analytics, 'Payments', { payment_id: id ?? '' });
          dispatch(updateUserSub(selected?.subscriptionSelected));
          // Sucess function

          setPaidSuccessful(true);
        })
        .catch((e) => {
          console.log(e);
          setProcessing(false);
          toast.error(e.response?.data?.Message ?? e?.message);
        });
    } else {
      console.log(error.message);
      setProcessing(false);
      toast.error(error.response?.data?.Message ?? error?.message);
    }
  };

  return (
    <StripeContainer>
      {!paidSuccessful ? (
        <React.Fragment>
          <Typography style={{ textAlign: 'center' }} variant="h6" gutterBottom>
            {t('Card Details')}
          </Typography>
          <div class="grid h-full  place-items-center">
            <form onSubmit={handleSubmit} className="w-3/4">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <label>{t('Card Number')}</label>
                  <CardNumberElement
                    id="cardNumber"
                    className={classes.cardNumber}
                    options={CARD_OPTIONS}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>{t('Expiry Date')}</label>
                  <CardExpiryElement id="expiry" options={CARD_OPTIONS} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>CVV</label>
                  <CardCvcElement id="cvc" options={CARD_OPTIONS} />
                  <FormHelperText>
                    {t('Last three digits on signature strip')}
                  </FormHelperText>
                </Grid>
                <Grid container>
                  <Grid item md={4} lg={4} xl={4}>
                    <p>{t('Cards we accept')}</p>
                  </Grid>
                  <Grid item md={8} lg={10} xl={10} className={classes.img}>
                    <Grid item md={2}>
                      <img src={MasterCard} alt="master card" />
                    </Grid>
                    <Grid item md={2}>
                      <img src={VisaCard} alt="visa card" />
                    </Grid>
                    <Grid item md={2}>
                      <img src={VerveCard} alt="verve card" />
                    </Grid>
                    <Grid item md={2}>
                      <img src={DiscoverCard} alt="discover card" />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Typography className={classes.warning}>
                    {t(
                      'Warning!!: We don’t save any information about you and neither does our payment gateway. Lectie will never ask you for any private details.'
                    )}
                  </Typography>
                </Grid>

                {/* Summary Section */}

                <section className="payment_summary_container">
                  <h2>{t('Payment Summary')}</h2>
                  <div className="summary">
                    <div className="summary_item">
                      <span className="bold_tag">{t('Plan')}: </span>{' '}
                      <p> {selected?.subscriptionName}</p>
                    </div>
                    <div className="summary_item">
                      {' '}
                      <span className="bold_tag">{t('Amount')}: </span>
                      <p>
                        {selected?.subscriptionSelected?.currency}{' '}
                        {selected?.subscriptionSelected?.price}
                      </p>
                    </div>
                  </div>
                  <div className="summary">
                    <div className="summary_item">
                      <span className="bold_tag">{t('Billing')}: </span>{' '}
                      <p> 1 {variation ? 'Year' : 'Month'}</p>
                    </div>
                  </div>

                  <div className="total_container">
                    <span className="total_tag">{t('Total Amount')}: </span>
                    <p>
                      {selected?.subscriptionSelected?.currency}{' '}
                      {selected?.subscriptionSelected?.price *
                        selected?.quantity}
                    </p>
                  </div>
                </section>
                <Grid item xs={12}>
                  <Button
                    className={classes.btn}
                    disabled={processing || !stripe}
                    startIcon={
                      <RotatingLines
                        strokeColor="#fff"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="20"
                        visible={processing}
                      />
                    }
                    variant="contained"
                    color="primary"
                    style={{ textTransform: 'none' }}
                    onClick={(e) => handleSubmit(e)}
                  >
                    {processing ? t('Processing') : t('Proceed')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </React.Fragment>
      ) : (
        <SucessPage />
      )}
    </StripeContainer>
  );
}

export default StripePaymentForm;
