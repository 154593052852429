import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import cx from 'classnames';
import '../payment/style/share.css';
import PlanLogo from '../../assest/images/plan_logo.svg';
import { colors } from '@mui/material';
import { useTranslation } from 'react-i18next';
import client from '../../services';
import { useUser } from '../../authenicationProvider';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Skeleton from '@mui/material/Skeleton';
import styled from 'styled-components';

// Styles
const useStyles = makeStyles({
  root: {
    margin: '0 auto',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1000px',
    maxWidth: '100%',
    overflowX: 'scroll',
  },

  shareCon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  btn: {
    marginRight: 15,
    background: '#2667B1',
    width: 80,
    borderRadius: 8,
    textAlign: 'center',
    fontSize: 16,
    height: '100%',
    position: 'absolute',
    right: 0,
    color: '#fff',
  },

  text: {
    textAlign: 'center',
    marginBottom: 15,
    fontFamily: 'Nunito',
  },
  headerText: {
    color: '#ffffff',
    fontSize: 22,
    fontFamily: 'Nunito',
    fontWeight: '700',
    padding: '10px',

    background: '#43ABF9',
    width: '1000px',
    maxWidth: '100%',
  },
  planImg: {
    position: 'absolute',
    top: '1px',
    right: '10px',
    color: '#ffffff',
  },
  flexPlan: {
    display: 'flex',
    gap: '0.7rem',
    alignItems: 'center',
    padding: '0.2rem 0.6rem',
    borderRadius: '2px',
  },
  ProPlan: {
    background: '#43ABF9',
  },
  PremiumPlan: {
    background: '#DCAB2F',
  },
  FreePlan: {
    background: '#2667B1',
  },
});

export default function ChooseTemplate({
  handleClick,
  setSelectedTemplate,
  selectedTemplate,
  handleClickOpenTemplate,
  error,
  setTemplatePlan,
}) {
  const { t } = useTranslation(['dashboard']);
  const classes = useStyles();
  const [templateStructure, setTemplateStructure] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedButton, setSelectedButton] = React.useState('');

  const setTemplates = async () => {
    setIsLoading(true);
    await client
      .get('/Souvenir/souvenirtemplateplans')
      .then((res) => {
        // Get Response and save to Bespoke months
        console.log(res?.data, 'Templatyes');
        setTemplateStructure([...res.data]);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err?.message);
      });
  };

  useEffect(() => {
    setTemplates();
  }, []);

  return (
    <ChooseTemplateConatiner>
      <div className="template_header">
        {t('Select a calender template')}{' '}
        {error && (
          <span
            className="error-msg"
            style={{
              textAlign: 'center',
              marginTop: '0.8rem',
              fontSize: '1.2rem',
            }}
          >
            t('Please select a template')
          </span>
        )}
      </div>

      <section className="template_list_container">
        {isLoading ? (
          <div className="template_struc">
            {[0, 1, 2, 3].map((item) => (
              <Skeleton
                variant="rectangular"
                className="p-4 w-64"
                height={300}
              />
            ))}
          </div>
        ) : (
          <>
            <div className="template_struc">
              {templateStructure.map(
                ({ templateId, image, templateSubscriptionPlan }) => (
                  <div
                    className="relative"
                    onClick={() => {
                      console.log('Tem id clicked', templateId);
                      setSelectedButton(templateId);
                      setSelectedTemplate(templateId);
                      setTemplatePlan(templateSubscriptionPlan);
                    }}
                    style={
                      selectedButton !== ''
                        ? {
                            border:
                              selectedButton === templateId
                                ? '3px solid #2667B1'
                                : 'none',
                          }
                        : { border: 'none' }
                    }
                  >
                    <div
                      className="bg-contain bg-center h-44  w-44  bg-no-repeat"
                      style={{
                        backgroundImage: `url(${image})`,
                      }}
                    ></div>

                    {
                      {
                        Basic: (
                          <div
                            className={cx([
                              classes.planImg,
                              classes.flexPlan,
                              classes.FreePlan,
                            ])}
                          >
                            {t('Free')}
                          </div>
                        ),
                        Pro: (
                          <div
                            className={cx([
                              classes.planImg,
                              classes.flexPlan,
                              classes.ProPlan,
                            ])}
                          >
                            <img src={PlanLogo} alt="Plan logo" />
                            <span>{t('Pro')}</span>
                          </div>
                        ),
                        Premium: (
                          <div
                            className={cx([
                              classes.planImg,
                              classes.flexPlan,
                              classes.PremiumPlan,
                            ])}
                          >
                            <img src={PlanLogo} alt="Plan logo" />
                            <span>{t('Premium')}</span>
                          </div>
                        ),
                        PremiumPRO: (
                          <div
                            className={cx([
                              classes.planImg,
                              classes.flexPlan,
                              classes.PremiumPlan,
                            ])}
                          >
                            <img src={PlanLogo} alt="Plan logo" />
                            <span>{t('Premium')}</span>
                          </div>
                        ),
                      }[templateSubscriptionPlan]
                    }

                    <p
                      className="text-center text-sm py-1 text-white bg-[#2667B1] cursor-pointer"
                      style={
                        selectedButton !== ''
                          ? {
                              backgroundColor:
                                selectedButton === templateId
                                  ? '#2667B1'
                                  : '#B3DCFB',
                            }
                          : { backgroundColor: '#B3DCFB' }
                      }
                      onClick={() => {
                        console.log('Tem id clicked', templateId);
                        setSelectedButton(templateId);
                        setSelectedTemplate(templateId);
                        setTemplatePlan(templateSubscriptionPlan);
                      }}
                    >
                      {selectedButton === templateId
                        ? t('selected')
                        : t('select')}
                    </p>
                  </div>
                )
              )}
            </div>
          </>
        )}
      </section>

      <div className="btn_container">
        <button
          onClick={() => {
            handleClickOpenTemplate(false);
            setIsLoading(false);
          }}
          className="px-10 py-1  border border-[#2667B1] hover:bg-[#2667B1] text-gray-800 hover:text-white text-sm font-medium rounded-md"
        >
          {t('Back')}
        </button>

        <button
          className="px-10 py-1  border border-[#2667B1] bg-[#2667B1] text-white hover:text-white text-sm font-medium rounded-md"
          onClick={() => {
            if (selectedTemplate !== '') {
              handleClick();
            } else {
              toast.info(t('Please select a template'));
            }
          }}
        >
          {t('Next')}
        </button>
      </div>
    </ChooseTemplateConatiner>
  );
}

const ChooseTemplateConatiner = styled.section`
  .template_header {
    background: #43abf9;
    padding: 10px;
    text-align: center;
    color: #fff;
    font-size: 1.3rem;
    height: 50px;
    min-height: 20px !important;
    overflow-x: hidden;
  }

  .template_list_container {
    margin-top: 0.5rem;
    overflow-x: scroll;
    width: 100%;
    height: 270px;

    .template_struc {
      display: flex;
      gap: 1rem;
      overflow-y: hidden;
    }
  }

  .btn_container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 0.6rem;
  }
`;
