export const startTime = [
  { time: '12:00am' },
  { time: '12:15am' },
  { time: '12:30am' },
  { time: '12:45am' },
  { time: '1:00am' },
  { time: '1:15am' },
  { time: '1:30am' },
  { time: '1:45am' },
  { time: '2:00am' },
  { time: '2:15am' },
  { time: '2:30am' },
  { time: '2:45am' },
  { time: '3:00am' },
  { time: '3:15am' },
  { time: '3:30am' },
  { time: '3:45am' },
  { time: '4:00am' },
  { time: '4:15am' },
  { time: '4:30am' },
  { time: '4:45am' },
  { time: '5:00am' },
  { time: '5:15am' },
  { time: '5:30am' },
  { time: '5:45am' },
  { time: '6:00am' },
  { time: '6:15am' },
  { time: '6:30am' },
  { time: '6:45am' },
  { time: '7:00am' },
  { time: '7:15am' },
  { time: '7:30am' },
  { time: '7:45am' },
  { time: '8:00am' },
  { time: '8:15am' },
  { time: '8:30am' },
  { time: '8:45am' },
  { time: '9:00am' },
  { time: '9:15am' },
  { time: '9:30am' },
  { time: '9:45am' },
  { time: '10:00am' },
  { time: '10:15am' },
  { time: '10:30am' },
  { time: '10:45am' },
  { time: '11:00am' },
  { time: '11:15am' },
  { time: '11:30am' },
  { time: '11:45am' },
  { time: '12:00pm' },
  { time: '12:15pm' },
  { time: '12:30pm' },
  { time: '12:45pm' },
  { time: '1:00pm' },
  { time: '1:15pm' },
  { time: '1:30pm' },
  { time: '1:45pm' },
  { time: '2:00pm' },
  { time: '2:15pm' },
  { time: '2:30pm' },
  { time: '2:45pm' },
  { time: '3:00pm' },
  { time: '3:15pm' },
  { time: '3:30pm' },
  { time: '3:45pm' },
  { time: '4:00pm' },
  { time: '4:15pm' },
  { time: '4:30pm' },
  { time: '4:45pm' },
  { time: '5:00pm' },
  { time: '5:15pm' },
  { time: '5:30pm' },
  { time: '5:45pm' },
  { time: '6:00pm' },
  { time: '6:15pm' },
  { time: '6:30pm' },
  { time: '6:45pm' },
  { time: '7:00pm' },
  { time: '7:15pm' },
  { time: '7:30pm' },
  { time: '7:45pm' },
  { time: '8:00pm' },
  { time: '8:15pm' },
  { time: '8:30pm' },
  { time: '8:45pm' },
  { time: '9:00pm' },
  { time: '9:15pm' },
  { time: '9:30pm' },
  { time: '9:45pm' },
  { time: '10:00pm' },
  { time: '10:15pm' },
  { time: '10:30pm' },
  { time: '10:45pm' },
  { time: '11:00pm' },
  { time: '11:15pm' },
  { time: '11:30pm' },
  { time: '11:45pm' },
];

export const endTime = [
  { time: '11:00am (30 mins)' },
  { time: '11:15am (45 mins)' },
  { time: '11:30am (1 hr)' },
  { time: '12:00pm (1.5 hrs)' },
  { time: '12:30pm (2 hrs)' },
  { time: '1:00pm (2.5 hrs)' },
  { time: '1:30pm (3 hrs)' },
  { time: '2:00pm (3.5 hrs)' },
  { time: '2:30pm (4 hrs)' },
  { time: '3:00pm (4.5 hrs)' },
  { time: '3:30pm (5 hrs)' },
  { time: '4:00pm (5.5 hrs)' },
  { time: '4:30pm (6 hrs)' },
  { time: '5:00pm (6.5 hrs)' },
  { time: '5:30pm (7 hrs)' },
  { time: '6:00pm (7.5 hrs)' },
  { time: '6:30pm (8 hrs)' },
  { time: '7:00pm (8.5 hrs)' },
  { time: '7:30pm (9 hrs)' },
  { time: '8:00pm (9.5 hrs)' },
  { time: '8:30pm (10 hrs)' },
  { time: '9:00pm (10.5 hrs)' },
  { time: '9:30pm (11 hrs)' },
  { time: '10:00pm (11.5 hrs)' },
  { time: '10:30pm (12 hrs)' },
  { time: '11:00pm (12.5 hrs)' },
  { time: '11:30pm (13 hrs)' },
  { time: '12:00am (13.5 hrs)' },
  { time: '12:30am (14 hrs)' },
  { time: '1:00am (14.5 hrs)' },
  { time: '1:30am (15 hrs)' },
  { time: '2:00am (15.5 hrs)' },
  { time: '2:30am (16 hrs)' },
  { time: '3:00am (16.5 hrs)' },
  { time: '3:30am (17 hrs)' },
  { time: '4:00am (17.5 hrs)' },
  { time: '4:30am (18 hrs)' },
  { time: '5:00am (18.5 hrs)' },
  { time: '5:30am (19 hrs)' },
  { time: '6:00am (19.5 hrs)' },
  { time: '6:30am (20 hrs)' },
  { time: '7:00am (20.5 hrs)' },
  { time: '7:30am (21 hrs)' },
  { time: '8:00am (21.5 hrs)' },
  { time: '8:30am (22 hrs)' },
  { time: '9:00am (22.5 hrs)' },
  { time: '9:30am (23 hrs)' },
  { time: '10:00am (23.5 hrs)' },
];
