import React from 'react';
import styled from 'styled-components';
import Skeleton from '@mui/material/Skeleton';

const RectSkeletonLoader = () => {
  return (
    <TemplateThreeMonthContainer>
      <section className="calendars-container">
        <Skeleton variant="rectangular" className="calendar-container" />
      </section>
    </TemplateThreeMonthContainer>
  );
};

export default RectSkeletonLoader;

const TemplateThreeMonthContainer = styled.section`
  .calendars-container {
    width: 90%;
    max-width: 1230px;
    height: 550px;
    margin: 0 auto;

    .calendar-container {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
      width: 100%;
      height: 100%;

      .month_title {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 800;
        font-size: 1.5rem;
        line-height: 44px;
        text-align: center;
        color: #0a0a0a;
      }

      .week-container {
        display: grid;
        grid-template-columns: repeat(7, 1fr);

        p {
          font-size: 1rem;
          padding: 0.8rem 0;
          text-align: center;
        }
      }
    }
  }

  @media screen and (max-width: 675px) {
    .calendars-container {
      grid-template-columns: 1fr;
    }
  }
`;
