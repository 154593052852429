import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
import moment from 'moment';
dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(advanced);
const initialState = {
  defaultDate: '2018-01-01T18:00:00',
  coverImg: [],
  events: [],
  shaga:[]
};

export const wallet = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    subYear: (state) => {
      let subDate = dayjs(state.defaultDate).subtract(1, 'year').toDate();
      return { ...state, defaultDate: subDate };
    },
    addYear: (state) => {
      let subDate = dayjs(state.defaultDate).add(1, 'year').toDate();
      return { ...state, defaultDate: subDate };
    },
    navDate: (state, { payload }) => {
      const { defaultDate, coverImg, events } = payload;

      let eventArr = [];

      for (var i = 0; i < events.length; i++) {
        let event = {
          id: events[i]?.id,
          title: events[i]?.title,
          start: moment(events[i]?.startTime).toDate(),
          end: moment(events[i]?.endTime).toDate(),
          description: events[i]?.description,
          img: events[i]?.image
            ? events[i]?.image
            : require('../../assest/images/Rectangle19.png'),
          hexCode: events[i]?.color,
          resourceId: 3,
        };
        eventArr.push(event);
      }
      return {
        ...state,
        defaultDate: dayjs(defaultDate).format('YYYY-MM-DDTHH:mm:ss'),
        coverImg: [...coverImg],
        events: [...eventArr],
      };
    },
    setDefaultYear: (state, { payload }) => {
      return {
        ...state,
        defaultDate: dayjs(payload).format('YYYY-MM-DDTHH:mm:ss'),
      };
    },
    calendarNav: (state, { payload }) => {
      const { coverImg, events } = payload;

      let eventArr = [];
      console.log('nav fired', events);
      for (var i = 0; i < events.length; i++) {
        let event = {
          id: events[i]?.id,
          title: events[i]?.title,
          start: events[i]?.startTime,
          end: events[i]?.endTime,
          description: events[i]?.description,
          img: events[i]?.image
            ? events[i]?.image
            : require('../../assest/images/Rectangle19.png'),
          hexCode: events[i]?.color,
          resourceId: 3,
        };
        eventArr.push(event);
        console.log(eventArr, 'for loop');
      }
      return {
        ...state,
        coverImg: [...coverImg],
        events: [...eventArr],
      };
    },
  },
});

export const { subYear, addYear, navDate, setDefaultYear, calendarNav } =
  wallet.actions;

export default wallet.reducer;
