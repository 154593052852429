import React from 'react';

import { CheckoutContainer, CheckoutWrapper } from './Index.style';
import MasterCard from '../../assest/images/master_card.svg';
import VisaCard from '../../assest/images/visa.svg';
import VerveCard from '../../assest/images/verve.svg';
import DiscoverCard from '../../assest/images/discover.svg';
import { useState } from 'react';
import { handleError } from './service';

const Checkout = ({ func }) => {
  const [name, setName] = useState('Samuel Momoh');

  const [email, setEmail] = useState('Bespoke@lectie.com');

  const [phone, setPhone] = useState('08166723207');

  const [checkForm, setCheckForm] = useState({
    check: false,
  });

  //  Handle display error
  const showErr = (errVal) => {
    for (const el of errVal) {
      switch (el) {
        case 'name':
          setName({ ...name, err: true });
          break;

        case 'phone':
          setPhone({ ...phone, err: true });
          break;

        default:
          return 'err';
      }
    }
  };

  const sendFormData = (formData) => {
    setName({ err: false, name: '' });
    setPhone({ err: false, phone: '' });
    setEmail('Bespoke@lectie.com');
    setCheckForm({ check: false });
  };

  const handleCheckSubmit = (event) => {
    event.preventDefault();

    const formData = {
      name: name.name,
      email: email,
      phone: phone.phone,
      check: checkForm.check,
    };
    // Validate Form
    let result = handleError(formData);

    // Dispaly error message from validation result
    result.length === 2 ? showErr(result[1]) : sendFormData(formData);

    // Code to handle submit
    // console.log(formData);
    // If success move to publish

    // After Success move to Stepper share(2)
    func(2);
  };

  return (
    <CheckoutContainer>
      <CheckoutWrapper>
        <section className="total_balance_section">
          <h4>Deposite Breakdown</h4>

          <ul>
            <li>
              <div className="label_prize">Plan:</div>
              <div>Premium</div>
            </li>
            <li>
              <div className="label_prize">Billing:</div>
              <div> 1 year</div>
            </li>
          </ul>

          <ul>
            <li>
              <div className="label_prize">Amount: </div>
              <div>$23</div>
            </li>
            <li>
              <div className="label_prize">Taxes: </div>
              <div> $23</div>
            </li>
          </ul>
          <ul className="total_mobile">
            <li>
              <div className="total">Total: </div>
              <div> $23</div>
            </li>
          </ul>
        </section>

        <section className="form_section">
          <form onSubmit={(e) => handleCheckSubmit(e)}>
            <div className="input_container">
              <label>Full Name</label>
              <input
                type="text"
                placeholder="Jane Doe"
                className="text_input"
                value={checkForm.name}
                onChange={(e) =>
                  setName({
                    err: false,
                    name: e.target.value,
                  })
                }
              />

              {name.err && (
                <span className="error-msg">Please enter an email</span>
              )}
            </div>

            <div className="input_container">
              <label>E-mail</label>
              <input
                type="email"
                placeholder="Bespoke@lectie.com"
                className="text_input"
                value={email}
                readOnly
              />
            </div>

            <div className="input_container">
              <label>Phone Number</label>
              <input
                type="text"
                placeholder="12345678"
                className="text_input"
                value={checkForm.phone}
                onChange={(e) =>
                  setPhone({
                    err: false,
                    phone: e.target.value,
                  })
                }
              />
              {phone.err && (
                <span className="error-msg">Please enter an email</span>
              )}
            </div>

            <div className="input_container_checkbox">
              <input
                type="checkbox"
                placeholder="12345678"
                className="check_input"
                checked={checkForm.check}
                onChange={(e) =>
                  setCheckForm({
                    check: e.target.checked,
                  })
                }
              />

              <p>Remenmber this details next time</p>
            </div>

            <section className="card_collect">
              <p>Card we accept</p>

              <section className="card_collect_details">
                <div className="card_wrap_img_container">
                  <img src={MasterCard} alt="master card" />
                  <img src={VisaCard} alt="visa card" />

                  <img src={VerveCard} alt="verve card" />
                  <img src={DiscoverCard} alt="discover card" />
                </div>
              </section>
            </section>

            <section className="pricing_section">
              <div className="basic">
                <p>Basic Yearly</p>
                <p>$23</p>
              </div>
              <div className="basic pro">
                <p>Pro Yearly</p>
                <p>
                  $23 <span>10% off</span>
                </p>
              </div>
              <div className="basic premium">
                <p>Premium Yearly</p>
                <p>
                  $23 <span>12% off</span>
                </p>
              </div>
            </section>

            <p className="warning">
              Warning!!: We don’t save any information about you and neither
              does our payment gateway. Lectie will never ask for you for any
              private details.
            </p>

            <button type="submit" className="form_btn">
              Proceed
            </button>
          </form>
        </section>
      </CheckoutWrapper>
    </CheckoutContainer>
  );
};

export default Checkout;
