import React, { Fragment, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  makeStyles,
  Modal,
  Backdrop,
  Fade,
  CardContent,
  CardMedia,
  CardHeader,
  Card,
  Avatar,
  IconButton,
  Typography,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { Calendar, DateLocalizer, momentLocalizer } from 'react-big-calendar';
import * as dates from './utils';
import dayjs from 'dayjs';
import {
  navDate,
  setDefaultYear,
  calendarNav,
} from '../../store/reducers/calendarReducer';
import client from '../../services';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  calendarview: {
    // display:'flex',
    // justifyContent:'center',
    width: '100%',
    margin: '20px 0px 20px 0px',
    maxHeight: '620px',
    overflowY: 'scroll',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fff',
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  desc: {
    maxHeight: '300px',
    overflowY: 'scroll',
  },
}));

const mLocalizer = momentLocalizer(moment);

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: '#FFF',
    },
  });
const Views = {
  MONTH: 'month',
  WEEK: 'week',
  // DAY: 'day',
  AGENDA: 'agenda',
};

export default function CalendarView({
  localizer = mLocalizer,
  setProcessing,
  processing,
}) {
  const [open, setOpen] = React.useState(false);
  const [eventModelData, setEventModelData] = useState(null);
  // Redux state
  const dispatch = useDispatch();
  const calendar = useSelector((state) => state.wallet);

  const { components, defaultDate, max, views } = useMemo(
    () => ({
      components: {
        timeSlotWrapper: ColoredDateCellWrapper,
      },
      defaultDate: new Date(calendar?.defaultDate),
      max: dates.add(dates.endOf(new Date(2018, 17, 1), 'day'), -1, 'hours'),
      views: Object.keys(Views).map((k) => Views[k]),
    }),
    []
  );

  // Get more events list
  const getEventList = async (date) => {
    setProcessing(true);

    let loadDate = dayjs(date).format('YYYY-MM');
    console.log('calling api', loadDate);
    await client
      .post(`/Souvenir/eventlist?dateOnly=${loadDate}`)
      .then((res) => {
        console.log(res?.data, 'get bespoke calendar');
        dispatch(calendarNav(res?.data));
        setProcessing(false);
      })
      .catch((e) => {
        console.log(e);
        setProcessing(false);
      });
  };

  const loadNewYear = async (date) => {
    // Set the default so calendar can be updated
    dispatch(setDefaultYear(moment(date).toDate()));
    // Call api to load more the date exceed the the default year load more
    if (
      dayjs(calendar?.defaultDate).format('YYYY-MM') !==
      dayjs(date).format('YYYY-MM')
    ) {
      // Call api for event for the year
      console.log('new year', date);
      getEventList(date);
    }
  };

  // Get an event pass as a props
  function eventStyle(event) {
    var backgroundColor = event.hexCode;
    var style = {
      backgroundColor: backgroundColor,
      color: '#2A2A2A',
      border: '0px',
      display: 'block',
    };
    return {
      style: style,
    };
  }

  const classes = useStyles();

  const handleOpen = (props) => {
    console.log(props);
    setEventModelData(props);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // setEventModelData(null);
  };
  var defaultMessages = {
    // date: 'Date',
    // time: 'Time',
    // event: 'Event',
    // allDay: 'All Day',
    // week: 'Week',
    // work_week: 'Work Week',
    // day: 'Day',
    // month: 'Month',
    // previous: 'Back',
    // next: 'Next',
    // yesterday: 'Yesterday',
    // tomorrow: 'Tomorrow',
    // today: 'Today',
    agenda: 'Schedule',
    // noEventsInRange: 'There are no events in this range.',
    // showMore: function showMore(total) {
    //   return "+" + total + " more";
    // }
  };
  return (
    <>
      <Fragment>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.calendarview}
        >
          {processing ? (
            <Skeleton
              variant="rect"
              animation="wave"
              width={'100%'}
              className="rect-skeleton"
            />
          ) : (
            <Calendar
              date={calendar?.defaultDate}
              selectable
              resizable
              components={components}
              events={calendar?.events}
              startAccessor={(event) => {
                return new Date(event.start);
              }}
              endAccessor={(event) => {
                return new Date(event.end);
              }}
              defaultView="month"
              localizer={localizer}
              step={100}
              views={views}
              eventPropGetter={eventStyle}
              popup
              max={max}
              onNavigate={(date) => loadNewYear(date)}
              onSelectEvent={handleOpen}
              messages={defaultMessages}
            />
          )}
        </Grid>
      </Fragment>
      {/* Event Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Card className={classes.paper}>
            <CardHeader
              avatar={
                <Avatar
                  aria-label="recipe"
                  className={classes.avatar}
                  style={{ background: eventModelData?.hexCode }}
                >
                  {eventModelData?.title?.toUpperCase()?.match(/^\w?/gim)}
                </Avatar>
              }
              action={
                <IconButton aria-label="settings" onClick={handleClose}>
                  <Close />
                </IconButton>
              }
              title={eventModelData?.title}
              subheader={`${dayjs(eventModelData?.start).format(
                'dddd, MMM D'
              )} - ${dayjs(eventModelData?.start).format('h:mm A')}`}
            />
            <CardMedia
              className={classes.media}
              image={eventModelData?.img}
              title={eventModelData?.title}
            />
            <CardContent>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                className={classes.desc}
              >
                {eventModelData?.description}
              </Typography>
            </CardContent>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}
CalendarView.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
  showDemoLink: PropTypes.bool,
};
