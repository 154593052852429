import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazy-load';
import styled from 'styled-components';
import TemplateTwoMonth from './component/templateTwoMonth';
import { useDispatch, useSelector } from 'react-redux';
import Tick from '../../../assest/images/tick_label.png';
import client from '../../../services';
import { toast } from 'react-toastify';
import {
  DeleteDateBespokeFilterCalendarYear,
  addYearFilterToBepoke,
  setCustomColors,
  sortBespokeFilterMonths,
  updateBespokeMonths,
} from '../../../store/reducers/bespokeFilter';
import { getCountyCode, userGeolocation } from '../../../functions';

const BespokeTemplateTwo = () => {
  const dispatch = useDispatch();
  const beskpoke = useSelector((state) => state.bespoke);
  const beskpokeFilter = useSelector((state) => state.beskpokeFilter);
  const [isLoading, setIsLoading] = useState(false);

  const updateCustomColors = (id) => {
    let customColors = beskpokeFilter?.customColors.map((color) => {
      if (color?.id === id) {
        return {
          ...color,
          isPresent: color.isPresent === true ? false : true,
        };
      }
      return { ...color };
    });

    dispatch(setCustomColors(customColors));
  };

  const updateCustomColorsApi = async () => {
    setIsLoading(true);
    const isPresentColors = beskpokeFilter?.customColors.filter(
      (color) => color?.isPresent === true
    );

    let country = new userGeolocation().getUserCountry();
    let { CountryCode } = getCountyCode(country) || 'NGN';

    console.log(isPresentColors, ' Color present');

    let bodyData = {
      colors: [...beskpokeFilter?.customColors],
      country: CountryCode,
    };

    let year = beskpokeFilter?.yearPicked;

    // Send customColors to end point
    await client
      .post(
        `/Souvenir/souvenirMonthsByYear/${beskpokeFilter?.id}?year=${year}`,
        bodyData
      )
      .then((res) => {
        // Call function

        console.log(
          ' This is Data tem 3 filgtered ',
          res.data,
          '  ',
          beskpokeFilter?.customColors
        );

        dispatch(updateBespokeMonths(res.data));

        setIsLoading(false);
        toast.success('Event Filtered Successfully');
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err?.message);
      });
  };

  return (
    <TemplateTwoContainer>
      <section className="label-main-wrapper">
        <ul className="label-main-container">
          <li className="events_label">Events label:</li>
          {beskpokeFilter?.customColors.map(
            ({ id, label, color, isPresent }) => (
              <li
                key={id}
                className="color_container"
                onClick={() => updateCustomColors(id)}
              >
                <span className="color" style={{ backgroundColor: color }}>
                  {isPresent === true && <img src={Tick} alt="checkbox tick" />}
                </span>
                <p style={{ backgroundColor: color, opacity: '0.7' }}>
                  {label}
                </p>
              </li>
            )
          )}
          <li
            className="label_filter"
            onClick={() => {
              if (!isLoading) {
                console.log('Pointer');
                updateCustomColorsApi();
              }
            }}
          >
            <span className="filter_btn">Filter events</span>
          </li>
        </ul>
      </section>

      <TemplateTwoMonth beskpoke={beskpokeFilter} isLoading={isLoading} />
    </TemplateTwoContainer>
  );
};

export default BespokeTemplateTwo;

const TemplateTwoContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 3.5rem;

  .label-main-wrapper {
    width: 90%;
    max-width: 1230px;
    margin: 0 auto;
    .label-main-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;

      .events_label {
        color: #111111;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: bolder;
        font-size: 1rem;
      }

      .color_container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        cursor: pointer;

        .color {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 100%;
        }

        p {
          padding-left: 0.8rem;
          padding-right: 0.8rem;
          height: 100%;
          font-weight: 550;
          font-size: 0.9rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .label_filter {
      cursor: pointer;
      width: 123px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      border: 1px solid #dadada;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      border-radius: 4px;
      .filter_btn {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 24px;
        color: #0a0a0a;
      }
    }
  }

  @media screen and (max-width: 543px) {
    .label-main-wrapper {
      display: none;
    }
  }
`;
