/*===============================================================
  | This is the Global Style file, It Hold Styles Generally     |
  | Shared by all other feature, ie It is the base section for  |
  | Extending functionalities on the Styles Components on other |
  | related files.                                              |
  ===============================================================*/

import styled from 'styled-components';

export const primaryColor = '#2667B1';
export const secondaryColor = '#F89F31';
export const darkSecondaryColor = '#EF6D02';

// ========== Container ============
const Container = styled.div`
  width: 100%;
  padding: 12px 16px;

  p {
    line-height: 1.4rem;
    font-size: 1rem;
    font-family: Nunito;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
  }

  *,
  *::after,
  *::before {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
  }
  h1 {
    font-family: Nunito;
    font-size: 2.6rem;
    font-weight: 700;
    line-height: 65px;
    letter-spacing: 0em;
    color: #1d1d1f;
  }

  h2 {
    font-family: Nunito;
    font-size: clamp(1.9rem, 2.2rem, 2.5rem);
    font-weight: 700;
    line-height: 55px;
    letter-spacing: 0em;

    color: #1d1d1f;
  }

  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  a {
    background-color: transparent;
  }
`;

// ============= Wrapper =============
export const Wrapper = styled.div`
  max-width: 1440px;
  width: 95%;
  margin: 0 auto;

  h1,
  h2,
  h3 {
    color: ${primaryColor};
  }
`;

// =========== Base Button =============
export const Button = styled.button`
  border: none;
  color: #fff;
  background-color: transparent;
  padding: 0.3rem 0.8rem;
  cursor: pointer;
`;

// ====== Section Button Extends Base button ============
export const SectionButton = styled(Button)`
  color: #fff;
  font-weight: bold;
  border: none;
  padding: 8px 25px;
  padding-top: 10px;
  font-size: 1.2rem;
  border-radius: 18px;
  background-color: ${primaryColor};
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.55);
  transform: scale(1);
  transition: transform 0.5s ease-in-out;

  &:hover,
  &:focus {
    transform: scale(1.1);
    box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.35);
  }
`;

export default Container;
