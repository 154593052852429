import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Select, MenuItem, FormControl } from '@material-ui/core';
import EventCard from './card2';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  grid: {
    maxHeight: '80vh',
    overflowY: 'scroll',
  },
  form: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 45,
    padding: '0px 10px',
    width: '100%',
  },
  text: {
    marginRight: 10,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px',
    color: '#151515',
    lineHeight: '45px',
  },
  select: {
    width: 110,
    color: '#2667B1',
    fontSize: '18px',
    textAlign: 'center',
  },
  title: {
    padding: '0px 10px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '30px',
    color: '#0A0A0A',
  },
}));

export default function DraftList() {
  const { t } = useTranslation(['dashboard']);

  const covers = useSelector((state) => state.drafts.covers);
  const classes = useStyles();
  const [value, setValue] = React.useState('All');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  console.log(covers, 'Here sis covers');
  return (
    <div>
      <div className={cx([classes.title, 'title-reponsive'])}>
        <h1>{t('Calendar Drafts')}</h1>
      </div>
      {/* <div className={classes.form}>
                <h6 className={classes.text}>Filter:</h6>
                <FormControl >
                    <Select
                        disableUnderline
                        className={classes.select}
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={value}
                        onChange={handleChange}

                    >
                        <MenuItem className={classes.option} value="All" selected={true}>All Events</MenuItem>
                        <MenuItem className={classes.option} value={'Day'}>Day</MenuItem>
                        <MenuItem className={classes.option} value={'Month'}>Month</MenuItem>
                        <MenuItem className={classes.option} value={'Year'}>Year</MenuItem>
                    </Select>
                </FormControl>

            </div> */}
      <Grid container spacing={1} className={classes.grid}>
        <Grid item sm={5} xs={12} md={4} lg={4} xl={4}>
          <EventCard data={covers} />
        </Grid>
      </Grid>
      {/* <Grid container spacing={1} className={classes.grid}>
        {covers?.map((item, i) => (
          <Grid item sm={5} xs={12} md={4} lg={4} xl={4} key={i}>
            <EventCard data={item} />
          </Grid>
        ))}
      </Grid> */}
    </div>
  );
}
