import styled from 'styled-components';
import Container, {
  primaryColor,
  secondaryColor,
  SectionButton,
  Wrapper,
} from '../Global_Styles';
const hamBugerAnimationTimer = '0.3s ease-in-out forwards';
const mobileMenuFadeinEven = '0.5s ease-in-out';
const mobileMenuFadeinOdd = '1.2s ease-in-out';

//  ====== NAVBAR CONTAINER =========
const NavBarContainer = styled(Container)`
  background-color: ${primaryColor};
  position: fixed;
  padding: 0;
  top: 0;
  width: 100%;
  z-index: 3;
`;

// =========== NarBar Wrapper ============
export const NavBarWrapper = styled(Wrapper)`
  justify-content: space-between;
  padding: 15px 0 2px 0;
  width: 100%;
  position: relative;

  .Logo-Link-Wrapper {
    gap: 4rem;

    .logo-image-container {
      width: 7.25rem;
      height: 2.875rem;
      z-index: 3;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    ul {
      gap: 0.5rem;
    }

    .Demo-Links {
      a {
        text-decoration: none;
      }
    }
  }

  .Login-Signup-Lang-Wrapper {
    gap: 2rem;

    /* position: relative; */

    .btn_started_nav {
      text-decoration: none;
      width: 138px;
      height: 44px;
      padding: 8px 28px 8px 28px;
      border-radius: 12px;
      background: #43abf9;
      color: #fff;
      align-items: center;
      justify-content: center;
      z-index: 3;
    }

    .Login-Signup {
      align-items: center;
      gap: 3rem;
      z-index: 5;

      .login-link {
        width: 138px;
        height: 44px;
        padding: 8px 28px 8px 28px;
        border-radius: 12px;
        border: 1px solid #ffffff;
        align-items: center;
        justify-content: center;
        /* SIGNUP BUTTON LINK STYLE DESKTOP */
        & a:nth-child(1) {
          padding: 12px;
          gap: 8px;
          width: 120px;
          height: 44px;
          background: #f89f31;
          border-radius: 8px;
          color: #fff;
          text-align: center;
        }

        /* LOGIN BUTTON LINK STYLE DESKTOP */
        & a:nth-child(2) {
          padding: 12px;
          gap: 8px;
          width: 120px;
          height: 44px;
          background: transparent;
          border: 2px solid #f89f31;
          border-radius: 8px;
          color: #fff;
          text-align: center;
        }

        gap: 30px;
        a {
          color: #fff;
          text-decoration: none;
          display: block;
          border: 1px solid transparent;
          padding: 0.5rem 0.96875rem;

          &:hover,
          &:focus {
            border: 1px solid ${secondaryColor};
            border-radius: 0.75rem;
          }
        }
      }
      .Lang {
        cursor: pointer;
        gap: 0.5rem;
        font-size: 1rem;
        color: #fff;
        border: 1px solid transparent;
        padding: 0.5rem 0.96875rem;
      }
    }
    .HamBurger {
      display: none;
      gap: 0.4em;
      width: 50px;
      cursor: pointer;
      span {
        width: 30px;
        height: 3px;
        border-radius: 18px;
        background-color: #fff;
      }

      & span:nth-child(even) {
        margin-left: 7px;
      }
    }
  }

  /* General */
  ul {
    display: ${({ url }) => (url ? 'flex' : 'none')};

    gap: 2.5rem;
    li {
      list-style: none;
      cursor: pointer;
      color: #fff;
      border: 1px solid transparent;
      padding: 0.5rem 0.96875rem;

      a {
        text-decoration: none;
        color: inherit;
        font-size: 1rem;
      }

      &:hover,
      &:focus {
        border: 1px solid ${secondaryColor};
        border-radius: 0.75rem;
      }
    }
  }

  .Mobile-Menu-Border {
    display: none;
  }

  // ======== NAvBar Media Query ===========
  // ======== Hambuger Shows here ==========
  @media screen and (max-width: 1020px) {
    .Logo-Link-Wrapper {
      font-size: 1rem;

      .Demo-Links {
        position: absolute;
        left: 0;
        top: 5rem;
        z-index: 3;
        display: ${({ menu }) => (menu ? 'flex' : 'none')};
        flex-direction: column;
        /* ====== Menu Link Animation ===== */
        & li:nth-child(even) {
          animation: FadeinOdd ${mobileMenuFadeinOdd};

          @keyframes FadeinOdd {
            from {
              opacity: 0;
            }

            to {
              opacity: 1;
            }
          }
        }

        & li:nth-child(odd) {
          animation: FadeinEven ${mobileMenuFadeinEven};

          @keyframes FadeinEven {
            from {
              opacity: 0;
            }

            to {
              opacity: 1;
            }
          }
        }
      }
    }

    .Login-Signup-Lang-Wrapper {
      z-index: 2;

      .btn_started_nav {
        padding-top: 0.85rem;
        padding-left: 2.5rem;
        position: absolute;
        left: 5px;
        z-index: 3;
        top: ${({ url }) => (url ? '  25rem' : '6rem')};
      }

      .Login-Signup {
        z-index: 3;

        .login-link {
          gap: 0rem;
          flex-direction: column;
          position: absolute;
          left: 5px;
          top: ${({ url }) => (url ? '  29rem' : '10rem')};

          a {
            margin-bottom: 1.5rem;
          }

          /* ====== Menu Link Animation ===== */
          & a:nth-child(2) {
            animation: FadeinEven ${mobileMenuFadeinEven};

            @keyframes FadeinEven {
              from {
                opacity: 0;
              }

              to {
                opacity: 1;
              }
            }
          }

          & a:nth-child(1),
          & li:nth-child(3) {
            animation: FadeinOdd ${mobileMenuFadeinOdd};

            @keyframes FadeinOdd {
              from {
                opacity: 0;
              }

              to {
                opacity: 1;
              }
            }
          }
        }
      }

      .HamBurger {
        display: flex;

        .childOne {
          position: relative;
          animation: rollLeft ${hamBugerAnimationTimer};
          @keyframes rollLeft {
            from {
              transform: rotate(-25deg);
              top: 1px;
            }

            to {
              transform: rotate(-45deg);
              top: 8px;
            }
          }
        }

        .childTwo {
          animation: slideOut ${hamBugerAnimationTimer};
          @keyframes slideOut {
            from {
              margin-left: 10px;
            }

            to {
              margin-left: 6rem;
            }
          }
        }

        .childThree {
          position: relative;
          animation: rollRight ${hamBugerAnimationTimer};
          @keyframes rollRight {
            from {
              transform: rotate(25deg);
            }

            to {
              transform: rotate(45deg);
              top: -10px;
            }
          }
        }

        /* ===== Revert Hamburger Animation ===== */
        .childOneRevert {
          position: relative;
          animation: rollLeftRevert ${hamBugerAnimationTimer};
          @keyframes rollLeftRevert {
            from {
              transform: rotate(45deg);
              top: 8px;
            }

            to {
              transform: rotate(0deg);
              top: 0;
            }
          }
        }

        .childTwoRevert {
          animation: slideIn ${hamBugerAnimationTimer};
          @keyframes slideIn {
            from {
              margin-left: 6rem;
            }
            to {
              margin-left: 7px;
            }
          }
        }

        .childThreeRevert {
          position: relative;
          animation: rollRightRevert ${hamBugerAnimationTimer};
          @keyframes rollRightRevert {
            from {
              transform: rotate(-45deg);
            }

            to {
              transform: rotate(0deg);
              top: 0;
            }
          }
        }
      }

      .Mobile-Menu-Border {
        background-color: ${primaryColor};
        border: 1px solid #fff;
        border-bottom-left-radius: 20%;
        border-top-right-radius: 20%;
        height: 95vh;
        width: 55vw;
        max-width: 18rem;
        position: absolute;
        top: 0rem;
        left: 0rem;
        z-index: 2;
        animation: dropDown 0.4s ease-in-out forwards;

        @keyframes dropDown {
          from {
            top: -55rem;
          }

          to {
            top: 0rem;
          }
        }
      }
    }

    /* Generalize  */
    .Logo-Link-Wrapper ul,
    .Login-Signup,
    .Mobile-Menu-Border,
    .btn_started_nav {
      display: ${({ menu }) => (menu ? 'block' : 'none')};
    }
  }

  @media screen and (max-width: 745px) {
    .Login-Signup-Lang-Wrapper {
      .Login-Signup {
        ul {
          left: 30px;
        }
      }
    }
  }

  @media screen and (max-width: 292px) {
    .Login-Signup-Lang-Wrapper {
      .Mobile-Menu-Border {
        width: 80%;
      }
    }
  }
`;

//  ====== Banner/Hero CONTAINER =========
export const BannerContainer = styled(Container)`
  padding-top: 5rem;
  margin-top: -9rem;
  background-color: ${primaryColor};
  position: relative;
  padding-bottom: 0;

  .first_circle,
  .first_circle_mobile {
    position: absolute;
    right: 0;
    top: 0;
  }

  .first_circle_mobile {
    display: none;
  }

  .circle_design {
    position: absolute;
    bottom: -10px;
    left: -10px;
    overflow: hidden;
  }

  @media screen and (max-width: 1094px) {
    .circle_design {
      display: none;
    }

    .first_circle {
      display: none;
    }

    .first_circle_mobile {
      display: block;
    }
  }
`;

// ============ Banner Wrapper ==============
export const BannerWrapper = styled(Wrapper)`
  color: #fff;
  justify-content: center;
  gap: 2.75rem;
  width: 100%;
  margin-top: 2rem;

  .Star {
    position: absolute;
    left: 30px;
    top: 400px;
  }

  .Star2 {
    position: absolute;
    top: 70px;
    right: 30px;
  }
  .Banner-Text {
    gap: 2.25rem;
    h1 {
      font-size: 2.875rem;
      font-weight: bold;
      line-height: 3.366875rem;
      color: #fff;
    }
    p {
      max-width: 40.5rem;
      line-height: 1.75rem;
      font-size: 1.3rem;
    }

    .ActionButton {
      gap: 20px;
      max-width: 31rem;
      width: 100%;
      align-self: center;

      .Download-Button-One,
      .Download-Button-Two {
        width: 100%;
        height: 4rem;

        a {
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .btn_started_banner {
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 5rem;
      gap: 12px;
      max-width: 300px;
      background: #43abf9;
      border-radius: 12px;
      text-decoration: none;
      outline: none;
      color: #fff;
    }
  }

  .Image-Showcase {
    max-width: 46.4375rem;
    gap: 1rem;
    justify-content: center;
    width: 90%;
    background-color: #e4e1ea;
    border-radius: 32px 32px 0px 0px;
    display: flex;
    align-items: center;

    .Image-Showcase-inner {
      background-color: #fff;
      width: 100%;
      height: 100%;
      justify-content: center;
      border-radius: 32px 32px 0px 0px;

      iframe {
        width: 100%;
        height: 100%;
        border-radius: 32px 32px 0px 0px;
      }
    }

    /* overflow: hidden; */

    .Image-One,
    .Image-Three {
      max-width: 13.75rem;
      margin-top: 4rem;
      img {
        background-color: transparent;
        width: 100%;
      }
    }

    .Image-Two {
      max-width: 17rem;
      img {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: 1140px) {
    .Image-Showcase {
      height: 250px;
      /* overflow: hidden; */
    }
  }

  // ============= Media Query ======
  @media screen and (max-width: 586px) {
    gap: 2rem;
    .Banner-Text {
      h1 {
        font-size: 2.3rem;
      }
      p {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
    .Image-Showcase {
      margin-top: -10px;

      .Image-One,
      .Image-Three {
        margin-top: 5rem;
      }

      .Image-Two {
        margin-top: -3rem;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .Image-Showcase {
      margin-top: -5px;
      /* height: 290px; */

      .Image-One,
      .Image-Three {
        margin-top: 6rem;
      }

      .Image-Two {
        margin-top: 0rem;
      }
    }
  }
`;

// ============ Banner Button ==================
export const Bannerbutton = styled(SectionButton)`
  background-color: ${secondaryColor};
  max-width: 340px;
  width: 80%;
  background-color: ${secondaryColor};
`;

export default NavBarContainer;
