import { useNode, useEditor } from '@craftjs/core';
import React from 'react';
import styled from 'styled-components';

import { VideoSettings } from './VideoSettings';

const ImgDiv = styled.div`
  width: 100%;
  height: 100%;
  > div {
    height: 100%;
  }
  iframe {
    pointer-events: ${(props) => (props.enabled ? 'none' : 'auto')};
    // width:100%!important;
    // height:100%!important;
  }
`;

export const Video = (props) => {
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  const { imgLink } = props;

  return (
    <ImgDiv ref={connect} enabled={enabled}>
         <img
        src={imgLink}
          style={{width: "100%", height:"100%"}}
          alt='Click and Upload image from the customize menu'
      />
    </ImgDiv>
  );
};

Video.craft = {
  displayName: 'Image',
  props: {
    imgLink: 'https://t3.ftcdn.net/jpg/02/18/21/86/360_F_218218632_jF6XAkcrlBjv1mAg9Ow0UBMLBaJrhygH.jpg',
  },
  related: {
    toolbar: VideoSettings,
  },
};
