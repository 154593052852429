import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { BallTriangle } from 'react-loader-spinner';

const useStyles = makeStyles({
  root: {
    background: '#fff',
    padding: '20px',
  },
  loader: {
    width: '100%',
    height: '50px',
    zIndex: '4',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const YearLoading = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={classes.loader}
    >
      <Grid item xs={2} md={2} sm={2} lg={2} className={classes.center}>
        <BallTriangle
          height={'10%'}
          width={'100%'}
          radius={3}
          color="#2667b1"
          ariaLabel="ball-triangle-loading"
          wrapperClass={{}}
          wrapperStyle=""
          visible={true}
        />
      </Grid>
    </Grid>
  );
};

export default YearLoading;
