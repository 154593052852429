import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
const initialState = {
  yearPicked: null,
  templateName: '',
  customColors: [
    {
      id: 1,
      color: '#FFDAC0',
      label: 'Birthday',
      customId: 1,
    },
    {
      id: 2,
      color: '#FFEDAC',
      label: 'Anniversary ',
      customId: 2,
    },
    { id: 3, color: '#BAFFC1', label: 'Meetings', customId: 3 },
    { id: 4, color: '#B4DFFF', label: 'Work out', customId: 4 },
    { id: 5, color: '#FDB8B8', label: 'Home work', customId: 5 },
    { id: 6, color: '#FFCEEC', label: 'Holiday', customId: 6 },
  ],
  share: false,
  web: '',
  twitter: '',
  linkedin: '',
  facebook: '',
  templateId: '',
  footer: {},
};

export const beskpokeFilter = createSlice({
  name: 'beskpokeFilter',
  initialState,
  reducers: {
    loadBespokeFilter: (state, { payload }) => {
      return {
        ...initialState,
        ...payload,
      };
    },

    setCustomColors: (state, { payload }) => {
      return {
        ...state,
        customColors: payload,
      };
    },
    updateBespokeMonths: (state, { payload }) => {
      return {
        ...state,
        months: payload,
      };
    },
    addYearFilterToBepoke: (state, { payload }) => {
      return {
        ...state,
        months: [...state.months, ...payload],
      };
    },

    addUpdateBespokeFilterCalendarYears: (state, { payload }) => {
      return {
        ...state,
        months: [...state.months, ...payload],
      };
    },

    sortBespokeFilterMonths: (state, { payload }) => {
      const newArray = [...state.months];
      const sortedMonth = newArray.sort((a, b) => a?.id - b?.id);

      return {
        ...state,
        months: [...sortedMonth],
      };
    },

    DeleteDateBespokeFilterCalendarYear: (state, { payload }) => {
      const newMonth = state.months.filter(
        (month) => dayjs(month?.date).format('YYYY') !== payload.toString()
      );

      return {
        ...state,
        months: [...newMonth],
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loadBespokeFilter,
  setCustomColors,
  updateBespokeMonths,
  addYearFilterToBepoke,
  addUpdateBespokeFilterCalendarYears,
  sortBespokeFilterMonths,
  DeleteDateBespokeFilterCalendarYear,
} = beskpokeFilter.actions;

export default beskpokeFilter.reducer;
