import React, { useState, createContext, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Oval } from 'react-loader-spinner';
import './index.css';
import client from './services';
import { store } from './store/index';
import { loadUser } from './store/reducers/userReducer';
import { useParams } from 'react-router-dom';

export const UserContext = createContext([]);

export const useUser = () => useContext(UserContext);
import i18next from 'i18next';

export const UserWrapper = ({ children }) => {
  const [user, setUser] = useState(null);
  const [working, setWorking] = useState(true);
  const [lang, setLang] = useState(localStorage.getItem('i18nextLng'));

  //Set language
  i18next.changeLanguage(`${lang}`);

  // Check if user session exist
  const tokenKey = 'auth_token';
  const authToken = localStorage.getItem(tokenKey);

  // Mobile Session
  const queryString = window?.location?.search;
  const urlParams = new URLSearchParams(queryString);
  const mobileToken = urlParams?.get('auth');

  // Set Authorization Token
  localStorage.setItem('mobileAuth', mobileToken);

  // console.log(mobileToken, "query string");

  console.log(authToken, 'auth');
  const userSession = async () => {
    if (authToken || mobileToken) {
      // Make API to get user details and set to local storage
      await client
        .get('/Account/profile')
        .then((res) => {
          // set Authorization token to state
          var user = {
            ...res.data,
          };
          setWorking(false);
          setUser(true);
          store.dispatch(loadUser(user));
          console.log(user, 'user details');
        })
        .catch((e) => {
          console.log(e);
          setUser(null);
          setWorking(false);
        });
    } else {
      console.log('no login user');
      setTimeout(() => {
        setUser(null);
        setWorking(false);
      }, 3000);
    }
  };

  useEffect(() => {
    userSession();
    // eslint-disable-next-line
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, setLang, lang }}>
      {working ? (
        <div className="preload">
          <Oval
            height={50}
            width={50}
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        children
      )}
    </UserContext.Provider>
  );
};
