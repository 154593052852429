import { useLocation, useNavigate } from 'react-router-dom';
import template0 from '../../../assest/images/Free1.png';
import template1 from '../../../assest/images/Free2.png';
import template2 from '../../../assest/images/Politician3.png';
import template3 from '../../../assest/images/Template4-img.png';
import template4 from '../../../assest/images/TVGuide5.png';
import crown from '../../../assest/images/crown.png';

import './templatePreview.css';
import ChooseTemplate from '../../create-page/chooseTemplate';

import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import cx from 'classnames';
import '../../payment/style/share.css';
import PlanLogo from '../../../assest/images/plan_logo.svg';
import { colors } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../authenicationProvider';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Skeleton from '@mui/material/Skeleton';
import styled from 'styled-components';
import client from '../../../services';
import {
  getCountyCode,
  getDigitInUnit,
  userGeolocation,
} from '../../../functions';
import { dark } from '@material-ui/core/styles/createPalette';

// Styles
const useStyles = makeStyles({
  root: {
    margin: '0 auto',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1000px',
    maxWidth: '100%',
    overflowX: 'scroll',
  },
  shareCon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  btn: {
    marginRight: 15,
    background: '#2667B1',
    width: 80,
    borderRadius: 8,
    textAlign: 'center',
    fontSize: 16,
    height: '100%',
    position: 'absolute',
    right: 0,
    color: '#fff',
  },

  text: {
    textAlign: 'center',
    marginBottom: 15,
    fontFamily: 'Nunito',
  },
  headerText: {
    color: '#ffffff',
    fontSize: 22,
    fontFamily: 'Nunito',
    fontWeight: '700',
    padding: '10px',

    background: '#43ABF9',
    width: '1000px',
    maxWidth: '100%',
  },
  planImg: {
    position: 'absolute',
    top: '8px',
    right: '20px',
    color: '#ffffff',
  },
  flexPlan: {
    display: 'flex',
    gap: '0.7rem',
    alignItems: 'center',
    padding: '0.2rem 0.6rem',
    borderRadius: '2px',
  },
  ProPlan: {
    background: '#43ABF9',
  },
  PremiumPlan: {
    background: '#DCAB2F',
  },
  FreePlan: {
    background: '#2667B1',
  },
});

const TemplatePreview = () => {
  const navigate = useNavigate();

  const { state } = useLocation();
  const {
    saveDate,
    saveTitle,
    slogan,
    cover,
    navRoute,
    templateId,
    templatePlan,
  } = state;

  const template_array = [
    template0,
    template1,
    template2,
    template3,
    template4,
  ];

  const { t } = useTranslation(['dashboard']);
  const classes = useStyles();
  const [templateStructure, setTemplateStructure] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedButton, setSelectedButton] = useState(templateId);
  const [selectedTemplate, setSelectedTemplate] = useState(templateId);

  const [error, setError] = useState(false);
  const [TemplatePlan, setTemplatePlan] = useState(templatePlan);
  const [planPrice, setPlanPrice] = useState({});

  const handlePlanClick = () => {
    if (selectedTemplate === 2 || selectedTemplate === '2') {
      return navigate('/dashboard/upload-cover', {
        state: {
          saveDate: saveDate,
          saveTitle: saveTitle,
          slogan: slogan,
          cover: cover,
          navRoute: navRoute,
          templateId: selectedTemplate,
          templatePlan: TemplatePlan,
        },
      });
    } else {
      return navigate('/dashboard/create-settings', {
        state: {
          saveDate: saveDate,
          saveTitle: saveTitle,
          slogan: slogan,
          cover: cover,
          navRoute: navRoute,
          templateId: selectedTemplate,
          templatePlan: TemplatePlan,
        },
      });
    }
  };

  const setTemplates = async () => {
    setIsLoading(true);
    await client
      .get('/Souvenir/souvenirtemplateplans')
      .then((res) => {
        // Get Response and save to Bespoke months

        setTemplateStructure([...res.data]);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err?.message);
      });
  };

  const handlePlanDetails = async () => {
    // Load plans

    // var type = variation ? 'Yearly' :"Monthly";
    var type = 'Yearly';
    const currentUserLocation = new userGeolocation().getUserCountry(); // Eg Nigeria

    console.log(currentUserLocation, ' Country hrrerer ');
    let { CountryCode } = getCountyCode(currentUserLocation) || 'NGN';

    await client
      .get(`/Subscription/Subscription/${type}/${CountryCode}`)
      .then((res) => {
        // Response
        const details = {};

        res?.data?.subscriptions.forEach((element) => {
          let elObj = {
            subscriptionName: element?.subscriptionName,
            currency: element?.currency,
            price: element?.free ? 'Free' : element?.price,
          };
          details[element?.subscriptionName] = elObj;
        });

        setPlanPrice(details);
      })
      .catch((e) => {
        // Loading false
        // setIsLoading(false);
      });
  };

  useEffect(() => {
    setTemplates();
    handlePlanDetails();
  }, []);

  return (
    <div>
      <div className="template-preview">
        <div className="top-template mt-4">
          <div className="top-left">
            <img
              src={
                selectedButton
                  ? template_array[selectedButton]
                  : template_array[selectedTemplate]
              }
              alt="tempate-image"
            />
          </div>

          <div className="top-right font-body">
            <div className="text-center">
              <div className="flex justify-center items-center space-x-1">
                {' '}
                <img src={crown} alt="Plan logo" width="30px" />
                <p className="font-bold "> {TemplatePlan} Plan</p>
              </div>

              <p>
                {TemplatePlan == 'Basic'
                  ? 'Free'
                  : TemplatePlan == 'Pro'
                  ? `${getDigitInUnit(planPrice['Pro']?.currency)} ${
                      planPrice['Pro']?.price
                    }/year`
                  : TemplatePlan == 'Premium'
                  ? `${getDigitInUnit(planPrice['Pro']?.currency)} ${
                      planPrice['Premium']?.price
                    }/year`
                  : ''}
              </p>

              <p className="text-[14px] pt-4 pb-12 ">
                {TemplatePlan == 'Basic'
                  ? 'This is a simple template that shows/indicates important events'
                  : TemplatePlan == 'Pro'
                  ? 'This is a simple template to indicate important events with an option to filter them to see specific events.'
                  : TemplatePlan == 'Premium'
                  ? 'This is a simple template that displays your TV programs and channels for your viewers.'
                  : ''}
              </p>
            </div>
            <div className="text-center mt-8">
              <button
                type="button"
                className="text-white bg-[#2667B1] hover:bg-[#2667B1]/55 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-12 py-2   dark:bg-[#2667B1] dark:hover:bg-[#2667B1] focus:outline-none "
                onClick={handlePlanClick}
              >
                {t('Use this template')}
              </button>
            </div>
          </div>
        </div>
      </div>

      <ChooseTemplateConatiner>
        <div className="mt-4 ml-7 ">
          <h1 className="font-body font-extrabold">{t('Other templates:')} </h1>
        </div>

        <section className="template_list_container px-4">
          {isLoading ? (
            <div className="template_struc">
              {[0, 1, 2, 3].map((item) => (
                <Skeleton
                  variant="rectangular"
                  className="p-4 w-64"
                  height={300}
                />
              ))}
            </div>
          ) : (
            <>
              <div className="template_struc">
                {templateStructure.map(
                  ({ templateId, image, templateSubscriptionPlan }) => (
                    <div
                      className="relative"
                      onClick={() => {
                        setSelectedButton(templateId);
                        setSelectedTemplate(templateId);
                        setTemplatePlan(templateSubscriptionPlan);
                      }}
                      style={
                        selectedButton !== ''
                          ? {
                              border:
                                selectedButton === templateId
                                  ? '3px solid #2667B1'
                                  : 'none',
                            }
                          : { border: 'none' }
                      }
                    >
                      <div
                        className="bg-contain bg-center h-64 p-4 w-64  bg-no-repeat"
                        style={{
                          backgroundImage: `url(${image})`,
                        }}
                      ></div>

                      {
                        {
                          Basic: (
                            <div
                              className={cx([
                                classes.planImg,
                                classes.flexPlan,
                                classes.FreePlan,
                              ])}
                            >
                              {t('Free')}
                            </div>
                          ),
                          Pro: (
                            <div
                              className={cx([
                                classes.planImg,
                                classes.flexPlan,
                                classes.ProPlan,
                              ])}
                            >
                              <img src={PlanLogo} alt="Plan logo" />
                              <span>{t('Pro')}</span>
                            </div>
                          ),
                          Premium: (
                            <div
                              className={cx([
                                classes.planImg,
                                classes.flexPlan,
                                classes.PremiumPlan,
                              ])}
                            >
                              <img src={PlanLogo} alt="Plan logo" />
                              <span>{t('Premium')}</span>
                            </div>
                          ),
                          PremiumPRO: (
                            <div
                              className={cx([
                                classes.planImg,
                                classes.flexPlan,
                                classes.PremiumPlan,
                              ])}
                            >
                              <img src={PlanLogo} alt="Plan logo" />
                              <span>{t('Premium')}</span>
                            </div>
                          ),
                        }[templateSubscriptionPlan]
                      }

                      <p
                        className="text-center text-sm py-1 text-white bg-[#2667B1] cursor-pointer"
                        style={
                          selectedButton !== ''
                            ? {
                                backgroundColor:
                                  selectedButton === templateId
                                    ? '#2667B1'
                                    : '#B3DCFB',
                              }
                            : { backgroundColor: '#B3DCFB' }
                        }
                        onClick={() => {
                          setSelectedButton(templateId);
                          setSelectedTemplate(templateId);
                          setTemplatePlan(templateSubscriptionPlan);
                        }}
                      >
                        {t('Select')}
                      </p>
                    </div>
                  )
                )}
              </div>
            </>
          )}
        </section>
      </ChooseTemplateConatiner>
    </div>
  );
};

const ChooseTemplateConatiner = styled.section`
  .template_header {
    background: #43abf9;
    padding: 10px;
    text-align: center;
    color: #fff;
    font-size: 1.3rem;
  }

  .template_list_container {
    margin-top: 1rem;
    overflow-x: scroll;
    width: 100%;

    .template_struc {
      display: flex;
      gap: 1rem;
    }
  }

  .btn_container {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    margin-bottom: 2rem;
  }
`;

export default TemplatePreview;
