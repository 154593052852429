import styled from 'styled-components';

export const MainLayoutContainer = styled.div`
  .main-contaner {
    display: flex;
    width: 100%;
    height: calc(100vh + 250px);

    /* Out let */
    .outlet {
      position: relative !important;
      overflow-y: scroll;
      width: 100%;
      padding-bottom: 3rem;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    /* Side bar */
    .sidebar {
      margin-top: -4.5rem;
      padding-top: 1rem;
      color: #fff;
      background-color: #2667b1;
      width: 253px;

      .top {
        display: flex;
        flex-direction: column;
        padding: 1rem 4.5rem 2rem 4rem;
        gap: 1rem;

        .logo {
          width: 110px;
          height: 42px;
          cursor: pointer;
        }

        p {
          font-size: 0.65rem;
          line-height: 22px;
        }
      }
      hr {
        border: 1px solid #fff;
      }

      nav {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 2rem 4.5rem 2rem 4rem;

        ul {
          display: flex;
          flex-direction: column;
          gap: 3rem;
          li {
            list-style: none;

            a {
              cursor: pointer;
              text-decoration: none;
              color: #fff;
              display: flex;
              gap: 20px;
              padding-left: 5px;
              p {
                font-size: 1rem;
              }
            }
            a.active {
              border-left: 4px solid #43abf9;
              width: fit-content;
            }
          }
        }

        .logout {
          margin-top: 8.25rem;
          display: flex;
          gap: 20px;
          cursor: pointer;
          p {
            font-size: 1rem;
          }
        }
      }
    }
  }

  /* Notification */
  .right-side {
    position: relative;

    .profile-gear {
      .notice_profile {
        display: flex;
        align-items: center;
        gap: 2rem;

        .back_notice {
          position: fixed;
          height: 100vh;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 6;
        }

        .profile-container {
          /* Remove Hike on profile : It not looking ok */

          .back_profile {
            position: fixed;
            height: 100vh;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 6;
          }

          .profile_drop_container {
            .Hike {
              position: absolute;
              top: 40px;
              left: 4px;
              width: 20px;
              z-index: 9;
              display: none;
            }

            .parent_dropdown {
              top: 50px;
            }
          }
        }

        .notification_container {
          position: absolute;
          top: 0;

          .Hike {
            position: absolute;
            top: 36px;
            width: 20px;
            left: -3px;
            z-index: 11;
          }

          .notification-info {
            display: flex;
            flex-direction: column;
            gap: 20px;
            background-color: #fff;
            color: #0a0a0a;
            position: absolute;
            height: 170px;
            width: 230px;
            top: 52px;
            left: -200px;
            z-index: 10;
            overflow-y: scroll;
            -ms-overflow-style: none; /* for Internet Explorer, Edge */
            scrollbar-width: none; /* for Firefox */
            padding: 10px 20px;
            box-shadow: 2px 15px 46px 6px rgba(0, 0, 0, 0.29);
            -webkit-box-shadow: 2px 15px 46px 6px rgba(0, 0, 0, 0.29);
            -moz-box-shadow: 2px 15px 46px 6px rgba(0, 0, 0, 0.29);
            visibility: hidden;

            animation: visible 1.5s ease-in-out forwards;

            @keyframes visible {
              from {
                visibility: hidden;
              }

              to {
                visibility: visible;
              }
            }

            &::-webkit-scrollbar {
              display: none; /* for Chrome, Safari, and Opera */
            }

            .notification-wrapper {
              display: flex;
              flex-direction: column;
              gap: 20px;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              /* &:hover {
          transform: scale(1.08);
          transition: transform 0.5s ease-in-out;
        } */

              .notice-img-container {
                display: none;
                max-width: 6rem;
                max-height: 6rem;
                img {
                  width: 100%;
                }
              }

              .notice-text {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding-top: 10px;

                h5 {
                  font-size: 0.9rem;
                  font-weight: bolder;
                }

                .message {
                  display: flex;
                  align-items: center;

                  p {
                    font-size: 0.8rem;
                    overflow: hidden;
                  }
                }

                .see_more {
                  font-weight: 700;
                  font-size: 0.8rem;
                  line-height: 22px;
                  color: #2667b1;
                  text-align: right;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }

  /* Tablet */
  @media screen and (max-width: 859px) {
    .right-side {
      position: relative;
    }

    .main-contaner {
      .sidebar {
        width: 203px;
        margin-top: -4.7rem;
        .top,
        nav {
          padding: 2rem 2.5rem 2rem 2rem;
        }

        .show-nav {
          display: block;
          ul li a {
            img {
              width: 23px;
              height: 23px;
            }
            p {
              font-size: 0.9rem;
            }
          }

          .notification {
            margin-bottom: 2rem;
            img {
              display: none;
            }
          }
        }
      }
    }
  }

  /* Mobile */
  @media screen and (max-width: 610px) {
    .main-contaner {
      padding-bottom: 30px;
      .sidebar {
        position: absolute;
        top: 0;
        left: -10px;
        z-index: 5;
        margin-top: 4.1rem;
        border-radius: 0px 8px 0px 0px;
        width: ${({ sideBarMobile }) => (sideBarMobile ? '250px' : '0px')};

        hr {
          display: none;
        }

        .top {
          display: none;

          .logo,
          p {
            display: none;
          }
        }

        .uppper-nav {
          display: none;

          ul li a {
            p {
              display: none;
            }
          }
          .logout {
            p {
              display: none;
            }
          }
        }
        .show-nav {
          margin-top: -1rem;
          padding: 3rem 2rem 2rem 2rem;
          opacity: ${({ sideBarMobile }) => (sideBarMobile ? 1 : 0)};
          animation: Opa 0.2s forwards ease-in-out;

          @keyframes Opa {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }

          .notification {
            margin-bottom: 2rem;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            align-items: center;
            justify-content: center;

            img {
              align-self: flex-start;
              display: block;
            }
          }

          .mode-show {
            display: flex;
            align-items: flex-start;
            gap: 12px;
            p {
              font-size: 1rem;
            }
            .mode {
              align-self: center;
              width: 45px;
              margin-bottom: 4rem;
            }
          }

          ul li a {
            align-items: center;
            p {
              display: block;
              font-size: 1.2rem;
            }
          }
          .logout {
            p {
              display: block;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }

  /* Smaller Mobile */

  @media screen and (max-width: 330px) {
    /* Notification */
    .right-side {
      position: relative;

      .parent_dropdown {
        width: 200px !important;
      }

      .profile-gear {
        .notice_profile {
          .notification_container {
            .Hike {
              position: absolute;
              top: 36px;
              width: 20px;
              left: -2px;
              z-index: 11;
            }

            .notification-info {
              top: 52px;
              left: -150px;
            }
          }
        }
      }
    }
  }
`;
