import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import InputField from './InputField';
import BtnForgetPassword from './BtnForgetPassword';
import { Link, useNavigate } from 'react-router-dom';
import client from '../../services';

const RequestForgetPassword = ({ setIsMailed, setUserMail }) => {
  const inputRef = useRef();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [btnIsActive, setBtnIsActive] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEmailSubmit = () => {
    setLoading(true);
    const data = { emailaddress: inputValue };

    client
      .post('/Account/forgotpassword', data)
      .then((res) => {
        console.log(res.data, ' All right good');
        setIsMailed(true);
        setLoading(false);
        setUserMail(inputValue);
      })
      .catch((err) => {
        setLoading(false);
        setEmailError(true);
        console.log(' Error ', err);
      });
  };
  return (
    <RequestForgotPassowrdContaner>
      <h1>Forgotten Password</h1>

      <p>
        {' '}
        To reset your password, please enter the email address you may have used
        with Studio.
      </p>

      <InputField
        inputValue={inputValue}
        setInputValue={setInputValue}
        setEmailError={setEmailError}
        emailError={emailError}
        ref={inputRef}
        text={'Continue'}
        func={handleEmailSubmit}
        isActive={btnIsActive}
        setBtnIsActive={setBtnIsActive}
        current={inputRef.current}
        input={inputValue.trim()}
        loading={loading}
      />

      <p className="back-login">
        Go back to <span onClick={() => navigate('/login')}>Login</span>{' '}
      </p>
    </RequestForgotPassowrdContaner>
  );
};

export default RequestForgetPassword;

const RequestForgotPassowrdContaner = styled.div`
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  height: 100%;
  margin: 0 auto;
  padding: 2rem 1.5rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;

  h1 {
    color: rgba(38, 103, 177, 1);
    font-family: Nunito;
    font-size: 2rem;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center;
  }

  p {
    text-align: center;
  }

  .back-login,
  .back-login span {
    font-size: 1.4rem;
    font-family: Nunito;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
  }

  .back-login span {
    color: rgba(38, 103, 177, 1);
  }
`;
