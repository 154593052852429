import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

// ====== GEt FAQ =========
export const GetMainFAQ = (List, funcCall) => {
  const { t } = useTranslation(['faq']);

  const FAQ = List.map(({ title, id, contentList }) => (
    <div className="FAQParentContainer parent" key={id} id={id}>
      <div className="Question">
        <p>{t(title)}</p>
        <span onClick={() => funcCall(id)}>
          <AiOutlineMinusCircle className="minus hide" />{' '}
          <AiOutlinePlusCircle className="plus" />
        </span>
      </div>

      <div
        className="Answer content hide"
        typeof="button"
        onClick={() => funcCall(id)}
      >
        {contentList.map(({ id, title, li }) => (
          <div className="innerContent" key={id}>
            <p>{t(title)}</p>
            <ul>
              {li.map((val, idx) => (
                <li key={idx}>{t(val)}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  ));

  return FAQ;
};
