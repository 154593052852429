import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Header from '../../component/bespoke_header/Header';

import { useDispatch, useSelector } from 'react-redux';
import { BallTriangle } from 'react-loader-spinner';
import { useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import ShareEvent from '../../component/shareEvent';

import { loadEventsGroup } from '../../../store/reducers/templateEvents';
import TemplateOneMonth from './component/templateOneMonth';
import { Navigate } from 'react-router-dom';
import { getMonth } from '../../../util';
import getDaysInMonths from '../../component/bespoke-core/utils/utilComponents/getDaysInMonths';
import dayjs from 'dayjs';
import YearLoading from '../YearLoading';
import client from '../../../services';
import DefaultCover from '../../../assest/images/defaultCalendarCover.png';

import { addYearToDraft } from '../../../store/reducers/CurrentDraft';
import LazyLoad from 'react-lazy-load';
import { getCountyCode, userGeolocation } from '../../../functions';

const useStyles = makeStyles({
  root: {
    background: '#fff',
    padding: '20px',
  },
  loader: {
    width: '100%',
    height: '100vh',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  coverImgContainer: {
    marginTop: '0px',
    height: '85vh',
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
  },

  imgCover: {
    width: '100%',
    height: '400px',
    imageRendering: 'pixelated',
  },
  moveCalendarBtn: {
    padding: '16px 15px',
    width: '230px',
    fontSize: '0.9rem',
    textAlign: 'center',
    color: '#ffffff',
    background: '#2667b1',
    borderRadius: '12px',
    cursor: 'pointer',
    alignSelf: 'center',
  },
});

const DraftViewTemplateOne = () => {
  let max = 2;
  let min = 0;
  var theme = Math.floor(Math.random() * (max - min + 1)) + min;
  const dispatch = useDispatch();
  const beskpokeDraft = useSelector((state) => state.draft);
  let defaultImage = useSelector((state) => state.default);

  // Dependant
  const [months, setMonths] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  // Group Event and Filtered Event on See More

  const [moreEvent, setMoreEvent] = useState([]);
  const [eventPopup, setEventPopup] = useState(false);
  const [popIndex, setPopIndex] = useState(0);
  const [shareOpen, setShareOpen] = useState(false);

  // Back to Dashboard for empty bespoke
  if (beskpokeDraft.months?.length < 1) {
    return <Navigate to="/dashboard" replace />;
  }

  // This is to get the days of the week
  const [week, setWeek] = useState([]);
  let allYear = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  // Calendar scroll
  const [yearsInCalendar, setYearsInCalendar] = useState([]);
  const [loadingYear, setLoadingYear] = useState(false);
  let years = [beskpokeDraft?.year];

  const view = useRef();
  const ref = useRef();

  // Number of years in bespoke
  let number_of_years_in_draft = beskpokeDraft?.months.length / 12;

  let newDefaultImage = { ...defaultImage };

  // Retructure default images for calendar
  //  To accommodate for other years
  let count = 0;
  for (let i = 12; i <= beskpokeDraft?.months.length - 1; i++) {
    newDefaultImage[i] = defaultImage[count];
    count++;
    if (count > 11) {
      count = 0;
    }
  }

  // Styles
  const classes = useStyles();

  // Share Event
  const handleClickOpenShare = () => {
    setShareOpen(true);
  };
  const handleClose = () => {
    setShareOpen(false);
  };

  // Arrange besoke dates and events
  const getBespoke = async (id) => {
    setIsLoading(true);

    // weeks For first year
    let year = parseInt(beskpokeDraft?.yearPicked);

    // Get week days
    const weekObj = getMonth(0, year);
    setWeek(...weekObj);

    // Get all Calendar for the year
    let monthsInYear = allYear.map((val) => getMonth(val, year));

    let otherMonthsInYears = [];

    for (let i = 0; i < yearsInCalendar.length; i++) {
      let otherYear = parseInt(
        new Date(
          new Date().setFullYear(
            new Date(year.toString()).getFullYear() + (i + 1)
          )
        ).getFullYear()
      );

      let otherYearItem = allYear.map((val) => getMonth(val, otherYear));
      otherMonthsInYears = [...otherMonthsInYears, ...otherYearItem];
    }

    let arrangemonths = getDaysInMonths([
      ...monthsInYear,
      ...otherMonthsInYears,
    ]);

    setMonths(arrangemonths);

    let monthEventsGroup = beskpokeDraft?.months.map(({ events }) => [events]);
    dispatch(loadEventsGroup(monthEventsGroup));

    setIsLoading(false);
  };

  // Get all year bresent in bespoke
  const saveAllPresentYear = () => {
    console.log(months, ' Months');
    let allYear = [];
    for (const month of beskpokeDraft?.months) {
      allYear.includes(dayjs(month?.date).format('YYYY'))
        ? null
        : allYear.push(dayjs(month?.date).format('YYYY'));
    }

    setYearsInCalendar(allYear);
  };

  useEffect(() => {
    saveAllPresentYear();

    getBespoke();
  }, [beskpokeDraft?.months]);

  // Get new calendar on scroll
  const getNewCalendar = async (year) => {
    console.log('.... In Func Call');
    setLoadingYear(true);

    let country = new userGeolocation().getUserCountry();
    let { CountryCode } = getCountyCode(country) || 'NGN';

    let bodyData = {
      colors: [],
      country: CountryCode,
    };

    await client
      .post(
        `/Souvenir/souvenirMonthsByYear/${beskpokeDraft?.id}?year=${year}`,
        bodyData
      )
      .then((res) => {
        console.log(' Added Year Prev   ', res.data);

        dispatch(addYearToDraft(res.data));
        // Delete Year (Next year)
        years.push(year);

        setLoadingYear(false);
      })
      .catch((err) => {
        console.log(err, ' Error');
        setLoadingYear(false);
      });
  };

  useEffect(() => {
    let countObsevrver = 0;

    const observer = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];

      let dateVal = ref.current?.textContent;

      !years.includes(dateVal) ? years.push(dateVal) : null;

      let yearToGet = new Date(
        new Date().setFullYear(new Date(dateVal).getFullYear() + 1)
      )
        .getFullYear()
        .toString();

      if (
        years.includes(yearToGet) === false &&
        entry.isIntersecting === true &&
        countObsevrver >= 1
      ) {
        getNewCalendar(yearToGet);
      }

      countObsevrver = countObsevrver + 1;
    });

    observer.observe(view.current);
  }, []);

  console.log(beskpokeDraft, ' Draft');
  return (
    <>
      <TemplateOneMonth
        popIndex={popIndex}
        setPopIndex={setPopIndex}
        isLoading={isLoading}
        eventPopup={eventPopup}
        setEventPopup={setEventPopup}
        moreEvent={moreEvent}
        setMoreEvent={setMoreEvent}
        week={week}
        setWeek={setWeek}
        months={months}
        beskpoke={beskpokeDraft}
        defaultImage={newDefaultImage}
        theme={theme}
        handleClickOpenShare={handleClickOpenShare}
        clendarNumber={number_of_years_in_draft}
        ref={ref}
      />
      {/* Share PopUp */}

      <h2 ref={view} style={{ visibility: 'hidden' }}>
        The End
      </h2>

      {loadingYear && <YearLoading />}

      <Dialog
        open={shareOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ShareEvent data={moreEvent[popIndex]?.id} />
      </Dialog>
    </>
  );
};

export default DraftViewTemplateOne;
