import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Month from './component/month';
import Header from './component/bespoke_header/Header3';
import { makeStyles, Grid, Dialog } from '@material-ui/core';
import { BallTriangle } from 'react-loader-spinner';
import client from '../services';
import { loadEvent } from '../store/reducers/event';
import { reloadState } from '../store/reducers/CurrentDraft';
import styled from 'styled-components';
import BrokenLink from '../assest/images/broken_link_logo.svg';
import LazyLoad from 'react-lazy-load';
import { BsArrowDown } from 'react-icons/bs';
import './component/styles/month.css';
import FrameImage from '../assest/images/Frame.svg';
import ShareDark from '../assest/images/ShareDark.svg';
import Calendar from '../assest/images/calendar.svg';
import Watch from '../assest/images/watch.svg';
import Location from '../assest/images/location.svg';
import Attach from '../assest/images/Attach.svg';
import dayjs from 'dayjs';
import ShareEvent from './component/shareEvent';
import { copy } from '../functions/index';
import { FaLocationDot } from 'react-icons/fa6';

const useStyles = makeStyles({
  root: {
    background: '#fff',
    padding: '20px',
  },
  loader: {
    width: '100%',
    height: '100vh',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

function EventView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  // console.log(params, 'url param');
  const beskpoke = useSelector((state) => state.bespoke);
  const event = useSelector((state) => state.event);
  const defaultImage = useSelector((state) => state.default);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  // Fetch event details
  const getEvent = async (id) => {
    if (id !== '00000000-0000-0000-0000-000000000000') {
      await client
        .get(`/event/${id}`)
        .then((res) => {
          console.log(res?.data, 'get event');
          dispatch(loadEvent(res?.data));
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setError(true);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params?.id) {
      dispatch(reloadState());
      getEvent(params?.id);
    }
  }, []);

  const urlify = (text) => {
    var urlRegex = /(https?:\/\/[^ ]*)/;
    var url = text.match(urlRegex)[1];
    return url;
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Open share functionality
  const handleClickOpen = () => {
    setOpen(true);
    setIsOpen(!isOpen);
  };

  ('08dc102f-143d-4094-876c-8d1610f5fcb9');
  ('00000000-0000-0000-0000-000000000000');

  return !error ? (
    loading ? (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.loader}
      >
        <Grid item xs={2} md={2} sm={2} lg={2} className={classes.center}>
          <BallTriangle
            height={'75%'}
            width={'75%'}
            radius={5}
            color="#2667b1"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
          />
        </Grid>
      </Grid>
    ) : (
      <>
        <Header />
        <div className="max-w-7xl mx-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div className="max-w-lg w-full sm:w-1/2 lg:w-1/2 py-6 px-3">
              <div className="bg-white shadow-xl rounded-lg overflow-hidden">
                <div
                  className="bg-cover bg-center h-64 p-4"
                  style={{
                    backgroundImage: `url(${event?.image || FrameImage})`,
                  }}
                ></div>

                {params?.id !== '00000000-0000-0000-0000-000000000000' ? (
                  <>
                    <div className="p-4">
                      <p class="text-2xl  text-[#43ABF9] font-bold font-body">
                        {' '}
                        {event?.title}
                      </p>
                    </div>
                    <div class="flex p-4 border-t border-gray-200 text-gray-700">
                      <div class="flex-1 inline-flex items-center">
                        <div className="flex">
                          {event?.link && (
                            <a
                              href={urlify(event?.link)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {' '}
                              <button className="bg-transparent hover:bg-[#2667B1] text-[#2667B1]  text-sm hover:text-white py-1 mr-2 px-4 border font-body border-[#2667B1] hover:border-transparent rounded">
                                Join meeting
                              </button>
                            </a>
                          )}
                          <div
                            onClick={() => {
                              handleClickOpen();
                            }}
                          >
                            <img src={ShareDark} alt="Share icon" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" p-4  text-gray-700 md:flex md:space-x-2 space-y-2 md:space-y-0 ">
                      <div className="flex space-x-2  border border-[#43ABF9] p-2">
                        <img
                          src={Calendar}
                          alt="Share icon"
                          width={20}
                          height={20}
                        />
                        <div>
                          <p className="text-[#0A0A0A] text-xs font-body">
                            Start date:{' '}
                            {dayjs(event?.startDate).format('Do MMMM, YYYY')}
                          </p>
                          <p className="text-[#0A0A0A] text-xs font-body ">
                            End date:{' '}
                            {dayjs(event?.endDate).format('Do MMMM, YYYY')}
                          </p>
                        </div>
                      </div>
                      <div className="flex space-x-2 border border-[#43ABF9] p-2">
                        <img src={Watch} alt="Share icon" />
                        <div>
                          <p className="text-[#0A0A0A] text-xs font-body">
                            Start time:{' '}
                            {dayjs(event?.startTime).format('h:mma')}{' '}
                          </p>
                          <p className="text-[#0A0A0A] text-xs font-body ">
                            End time: {dayjs(event?.endTime).format('h:mma')}{' '}
                          </p>
                        </div>
                      </div>
                    </div>

                    {(event?.location !== undefined ||
                      event?.location !== '') && (
                      <div class="flex p-4">
                        <FaLocationDot />
                        <p className=" text-xs font-body px-4">
                          {event?.location}
                        </p>
                      </div>
                    )}
                    <div className="p-4">
                      <p className="text-gray-700 font-body font-bold">
                        Event Description
                      </p>
                      <p className="text-gray-700 text-xs font-body">
                        {event?.description}
                      </p>
                    </div>
                    <div class="flex p-4  text-gray-700">
                      {event?.link && (
                        <div className="flex md:w-3/4 space-x-2 p-2  border border-[#0A0A0A] rounded-md">
                          <img
                            src={Attach}
                            alt="Share icon"
                            width={20}
                            height={20}
                          />
                          <div>
                            <p className="text-[#2B2A30]  text-xs font-body  ">
                              {event?.link}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>

                    <div class="flex p-4  text-gray-700">
                      <div className="flex ">
                        {event?.link && (
                          <button
                            onClick={() => {
                              copy(event?.link);
                            }}
                            className="bg-[#2667B1] hover:bg-[#1e528d] text-white  font-body text-sm  py-1 px-4 mr-2 rounded"
                          >
                            Copy URL
                          </button>
                        )}
                        {event?.link && (
                          <a
                            href={urlify(event?.link)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {' '}
                            <button
                              // onClick={() => urlify(event?.link)}
                              className="bg-transparent hover:bg-[#2667B1] text-[#2667B1]  text-sm hover:text-white py-1 mr-2 px-4 border font-body border-[#2667B1] hover:border-transparent rounded"
                            >
                              Join meeting
                            </button>
                          </a>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div class="flex p-4 border-t border-gray-200 text-gray-700 text-2xl">
                    You can not Share an Holiday Event
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <ShareEvent data={params?.id} />
        </Dialog>

        {/* <div className="bespoke_title_container">
          <h2 className="bespoke_heading">{beskpoke?.templateName}</h2>
          <p className="bespoke_title_year">{beskpoke?.yearPicked}</p>
        </div> */}
      </>
    )
  ) : (
    <BrokenContainer className="broken_link_container">
      <div className="broken_link__header">
        <h1>Ohh, Ohh We Are Sorry</h1>
        <p className="broken_link_text">
          The link is broken, lets guide you back
        </p>
      </div>

      <div className="btn_container">
        <button className="btn_right" onClick={() => navigate('/dashboard/')}>
          Dashboard
        </button>
      </div>

      <div className="img_container">
        <img src={BrokenLink} alt="Broken link picture" />
      </div>
    </BrokenContainer>
  );
}

export default EventView;

const BrokenContainer = styled.div`
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding: 2rem 0;
  margin-top: 5rem;

  .broken_link__header {
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 2rem;
      color: #0a0a0a;
      text-align: center;
    }

    p {
      font-weight: 400;
      font-size: 0.8rem;
      line-height: 33px;
      text-align: center;
      color: #0a0a0a;
    }
  }

  .btn_container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    button {
      padding: 12px;
      width: 230px;
      font-size: 0.9rem;
      text-align: center;
      color: #fff;
      background: #2667b1;
      border-radius: 12px;
      cursor: pointer;
    }
  }
  .img_container {
    margin-top: 4rem;
    max-width: 330px;

    img {
      width: 100%;
    }
  }
`;

const YearCoverContainer = styled.div`
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .bespoke_title_container {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    .bespoke_heading {
      font-family: 'Sofia', cursive;
      font-weight: 400;
      font-size: 2.5rem;
      color: rgba(197, 113, 14, 1);
      text-align: center;
    }

    .bespoke_title_year {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 800;
      font-size: 1.5rem;
      text-align: center;
    }
  }

  .year_cover_img_container {
    width: 100%;
    height: 400px;
    .year_cover_image {
      width: 100%;
      height: 400px;
      image-rendering: pixelated;
    }
  }

  .bespoke_scroll_indicator_container {
    align-self: center;
    background: #43abf9;
    border-radius: 20px;
    height: 56px;
    width: 230px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    svg {
      color: #fff;
      font-size: 1rem;
      animation: bounce 0.9s ease-in-out infinite;
      position: relative;

      @keyframes bounce {
        0% {
          top: -10px;
        }

        50% {
          top: -7px;
        }

        75% {
          top: -4px;
        }

        100% {
          top: 0;
        }
      }
    }
  }
`;
