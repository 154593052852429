import React from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import Signup from './signup/signup';
import Login from './login/Login';
import Financial from './template/financial';
import MainLayout from './MainLayout';
import ProtectedRoute from './ProtectedRoute';
import Home from './Home';
import Setting from './settings/Setting';
import FAQ from './faq/FAQ';
import Profile from './Profile';
import Help from './help/Help';
import Subscription from './subscription/Subscription';
import Plans from './plans/Plans';
import UpgradePlans from './upgradePlan/UpgradePlans';
import BespokeCore from './component/bespoke-core/BespokeCore';
import Wallet from './wallet/Wallet';
import Studio from './coverEditor';
import CreatePage from './create-page/CreatePage';
import DraftView from './draftView';
import EditCoverImage from './create-page/EditCoverImage';
import ChangePassword from './changePassword';
import { useUser } from '../authenicationProvider';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import PaymentStepper from './payment';
import DraftList from './drafts';
import Privacy from './privacy';
import BespokeView from './bespokeView';
import EventView from './eventView';
import NotificationPage from './notification/NotificationPage';
import Checkout from './checkout';
import Contact from './contact/contact';
import TermsConditions from './component/terms/termsCondtion';
import HomeNotFound from './home_not_found/HomeNotFound';
import Widget from './component/widget/widget';
import PricingPage from './Home/Pricing';

import BespokeViewTemplateOne from '../routes/template/templateOne/bespokeViewTemplateOne';

import CreateSettings from './create-settings/CreateSettings';
import DraftViewTemplateOne from './template/templateOne/draftViewTemplateOne';
import UpgradeSummary from './upgrade-summary/UpgradeSummary';
import StripeCheckout from './stripe-checkout';
import PoliticsFooter from './template/templateTwo/politic-footer/politicsFooter';
import BespokeCoreCover from './component/bespoke-core/BespokeCoreCover';
import TemplatePreview from './component/template-preview/TemplatePreview';
import UpdateSettings from './update-settings/UpdateSettings';
import SucessPageScreen from './component/SuccessPageView/SucessPageScreen';
import UploadCoverImg from './create-page/UploadCoverImg';
import RequestForgotPassowrd from './forgotPassword/ForgotPassword';
import ForgotPassowrd from './forgotPassword/ForgotPassword';
import AccountDeletion from './Account_Deletion/AccountDeletion';

const Markup = () => {
  const { user } = useUser();

  return (
    <>
      <Routes>
        <Route index element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="faq" element={<FAQ />} />
        <Route path="signup/:bespokeId" element={<Signup />} />
        <Route path="login/:bespokeId" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassowrd />} />
        <Route path="password/reset/:token" element={<ForgotPassowrd />} />
        <Route path="financial" element={<Financial />} />
        <Route path="contact" element={<Contact />} />
        <Route path="terms" element={<TermsConditions />} />

        <Route path="widget/:id" element={<Widget />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route exact path="/linkedin" element={<LinkedInCallback />} />
        <Route path="/bespoke/event/:id" element={<EventView />} />
        <Route path="/bespoke/calendar/:id" element={<BespokeView />} />
        <Route path="/bespoke/calendar/:id/:auth" element={<BespokeView />} />

        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/account-deletion" element={<AccountDeletion />} />
        <Route element={<ProtectedRoute user={user} />}>
          <Route path="draft/view" element={<DraftView />} />
          <Route path="payment/:id" element={<PaymentStepper />} />
          <Route path="checkout/:id" element={<Checkout />} />
          <Route path="/studio/cover" element={<BespokeCoreCover />} />
          <Route path="/studio/event" element={<BespokeCore />} />
          <Route path="/studio" element={<Studio />} />
        </Route>

        <Route path="dashboard" element={<MainLayout />}>
          <Route element={<ProtectedRoute user={user} />}>
            <Route path="/dashboard/" element={<Wallet />} />
            <Route path="profile" element={<Profile />} />
            <Route path="notification" element={<NotificationPage />} />
            <Route path="pwd-reset" element={<ChangePassword />} />
            <Route path="setting" element={<Setting />} />

            <Route path="help" element={<Help />} />
            <Route path="subscription" element={<Subscription />} />
            <Route path="upgrade-summary" element={<UpgradeSummary />} />
            <Route path="stripe-checkout" element={<StripeCheckout />} />
            <Route path="success/view" element={<SucessPageScreen />} />

            <Route path="plans" element={<Plans />} />
            <Route path="upgrade-plans" element={<UpgradePlans />} />
            <Route path="create" element={<CreatePage />} />
            <Route path="create-settings" element={<CreateSettings />} />
            <Route path="update-settings" element={<UpdateSettings />} />
            <Route path="politics-footer" element={<PoliticsFooter />} />
            <Route path="template-preview" element={<TemplatePreview />} />

            <Route path="cover" element={<EditCoverImage />} />
            <Route path="upload-cover" element={<UploadCoverImg />} />
            <Route path="draft/list" element={<DraftList />} />
          </Route>
        </Route>
        <Route path="*" element={<HomeNotFound />} />
      </Routes>
    </>
  );
};

export default Markup;
