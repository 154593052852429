import styled from 'styled-components';
const bgPrimary = '#2d78c3';
export const SideBarContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 2rem;
  background: ${bgPrimary};
  border-radius: 0px 20px 20px 0px;
  max-width: 668px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  nav {
    max-width: 451px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    gap: 1.1rem;
    align-items: center;

    .logo-container {
      width: 150px;

      img {
        width: 100%;
        cursor: pointer;
      }
    }

    .nav-ul {
      display: flex;
      gap: 2.5rem;
      align-items: center;

      li {
        a {
          font-size: 0.9rem;
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }

  .banner-container {
    max-width: 424px;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }

  .signup-login-text {
    color: #fff;
    text-align: center;
    max-width: 302px;
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    gap: 10px;
    h3 {
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 20px;
    }
    p {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 400;
      font-size: 0.8rem;
    }
  }

  @media screen and (max-width: 990px) {
    display: none;
  }
`;
