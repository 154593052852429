import styled from 'styled-components';

export const LoginContainer = styled.div`
  width: 100%;
  max-width: 1440px;
`;

export const LoginWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .right-side-form {
    margin: 0 auto;
    max-width: 660px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1.9rem;
    height: 100vh;

    form {
      padding-top: 1rem;
      padding-bottom: 20px;
      width: 100%;
      max-width: 520px;
      margin: 0 auto;
      background: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
      h2 {
        color: #2667b1;
        text-align: center;
        font-size: 1rem;
      }

      .main-form {
        max-width: 400px;
        width: 100%;
        font-family: 'Roboto', sans-serif;
        margin: 0 auto;
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .remember-me-check {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .check-container {
            display: flex;
            gap: 0.8rem;
            align-items: center;

            p {
              font-size: 0.9rem;
            }
          }

          .forgot-password {
            display: flex;
            align-items: center;
            font-size: 0.7rem;
            color: #2667b1;
            cursor: pointer;
          }
        }

        .input-container {
          width: 100%;
          p {
            font-size: 0.7rem;
          }
          input[type='text'],
          input[type='password'] {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 6px;
            gap: 12px;
            width: 100%;
            background: #ffffff;
            border: 1px solid #161616;
            border-radius: 8px;
            font-size: 0.9rem;

            &::placeholder {
              color: #d3d3d3;
            }
          }
        }

        .password-input {
          display: flex;
          align-items: center;
          gap: 3px;
          border: 1px solid #161616;
          border-radius: 8px;
          padding: 1.5px;

          input[type='password'] {
            border: none;

            &::placeholder {
              text-align: center;
              color: #252423;
            }
            &:focus {
              outline: none;
            }
          }
        }

        .state-tc {
          font-weight: 400;
          font-size: 0.8rem;
          line-height: 22px;

          a {
            font-size: 0.8rem;
            margin-left: 10px;
            text-decoration: none;
            color: #2667b1;
          }
        }
      }
    }

    .button-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;

      p {
        font-size: 0.9rem;
        margin-top: 1rem;
        a {
          margin-left: 0.5rem;
          font-size: 0.8rem;

          color: #75abe5;
          cursor: pointer;
        }
      }
      button {
        border: none;
        color: #fff;
        max-width: 270px;
        width: 100%;
        height: 47px;
        margin: 0 auto;
        background: #2667b1;
        border-radius: 12px;
        cursor: pointer;
      }
    }

    .alt-signup {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 1.3rem;
      .the-or {
        text-align: center;
        font-weight: 700;
        font-size: 1.3rem;
      }

      .alt-direct-signup {
        cursor: pointer;
        border: 1px solid #000000;
        border-radius: 12px;
        display: flex;
        justify-content: space-between;
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
        align-items: center;
        padding: 7px;

        p {
          width: 70%;
          text-align: left;
          font-size: 0.7rem;
        }
      }
      .google-btn {
        display: flex;
        justify-content: center;
        cursor: pointer;
        border-radius: 12px;
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
        padding: 7px;
      }
      .nsm7Bb-HzV7m-LgbsSe {
        width: 400px !important;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    gap: 1rem;
  }

  @media screen and (max-width: 990px) {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(15%, 3%);
    width: 80vw;

    .right-side-form {
      width: 100%;
    }
  }

  @media screen and (max-width: 544px) {
    width: 90vw;
    transform: translate(6%, 3%);
    .right-side-form {
      form {
        .main-form {
          width: 80%;
        }
      }

      .alt-signup {
        width: 80%;
        margin: 0 auto;
      }
    }

    .button-container {
      width: 80%;
      margin: 0 auto;
    }
  }
`;
