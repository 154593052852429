import axios from 'axios';

const DEVURL = 'https://bespoke-calendar-dev-api.lectie.com/api'; //Testing
const LIVEURL = 'https://bespoke-calendar-api.lectie.com/api'; // live Env
const tokenKey = 'auth_token';

// LIVEURL
const client = axios.create({
  baseURL: process.env.NODE_ENV == 'development' ? DEVURL : LIVEURL,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  transformRequest: [
    function (data, headers) {
      headers['Authorization'] = `Bearer ${
        localStorage.getItem(tokenKey) || localStorage.getItem('mobileAuth')
      }`;
      return JSON.stringify(data);
    },
  ],
});

export default client;
