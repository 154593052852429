import dayjs from 'dayjs';
import React from 'react';
import { useSelector } from 'react-redux';
import { getDay } from '../../../../util';
import { DayContainer } from './styles/calendar.Styles';

// func --> THis is to open the model,set Selected Date and set start date
//funcEvent -->  This get the calendar event of that day clicked using its id
const Day = ({ day, func, months, funcEvent, year }) => {
  const { monthNumber } = useSelector((state) => state.month);
  const customColorState = useSelector((state) => state.customColorState);
  const bespoke = useSelector((state) => state.draft);

  let allEvent = [];
  let newEvent = [];
  let baseArr = [];
  for (const month of months) {
    for (let i = 0; i < month['events'].length; i++) {
      allEvent.push(month['events'][i]);
      baseArr.push(month['events'][i]);
    }
  }

  newEvent = JSON.parse(JSON.stringify(allEvent));

  if (newEvent.length > 0) {
    for (const event of newEvent) {
      event['startDate'] = dayjs(event['startDate']).format('DD MM YYYY');
    }
  }

  return (
    <DayContainer
      className={`day-month`}
      typeof="button"
      onClick={(e) => {
        e.stopPropagation();
        console.log(day, ' THis is day --- Year ', year.toString());
        if (day.format('YYYY') === year.toString()) {
          func(day);
        }
      }}
    >
      <header className="flex flex-col">
        <p
          className={`day t-sm  ${getDay(day, null) ? 'highlight' : 'down'}  ${
            day.format('M') === dayjs(months[monthNumber]?.date).format('M')
              ? ''
              : 'disabled'
          }`}
        >
          {day.format('DD')}
        </p>

        {newEvent.map((evt, i) => {
          return (
            evt['startDate'] === day.format('DD MM YYYY') && (
              <div
                className={`flex event-tag a-center ${
                  day.format('YYYY') === year.toString() ? '' : 'hide-evt'
                }`}
                key={i}
                onClick={(e) => {
                  console.log(
                    day.format('YYYY'),
                    '   Years Year  ',
                    year.toString()
                  );

                  e.stopPropagation();

                  funcEvent(evt['id'], baseArr[i]);
                }}
              >
                <span
                  className="event-color"
                  style={{ backgroundColor: evt['color'] }}
                ></span>
                <p className="event t-sm" typeof="button">
                  {' '}
                  {evt['title']}
                </p>
              </div>
            )
          );
        })}
      </header>
    </DayContainer>
  );
};

export default Day;
