import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {} from '@material-ui/core/';
import { Save } from 'react-feather';
import moment from 'moment';
import client from '../../services';
import CircularProgress from '@material-ui/core/CircularProgress';
import { blue } from '@material-ui/core/colors';
import { generate } from '../../store/reducers/CurrentDraft';
import { setCalendarMonth } from '../../store/features/calendarSlice';

const useStyles = makeStyles({
  root: {
    padding: 10,
  },
  media: {
    height: 180,
  },
  actionCard: {
    height: 65,
  },
  wrapper: {
    position: 'relative',
  },
  progress: {
    color: blue[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
});

export default function EventCard(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { id, templateName, coverImg, privacy, createdAt, templateId } =
    props?.data;
  const classes = useStyles();

  // Get Bespoke Details
  const getBespoke = async (id) => {
    setLoading(true);
    await client
      .get(`/Souvenir/draft/${id}`)
      .then((res) => {
        console.log(res?.data, 'get draft calendar');
        let beskpoke = res?.data;
        dispatch(generate(beskpoke));
        dispatch(setCalendarMonth(beskpoke?.months));
        // navigate(`/studio/event`);

        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  return (
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault();
        // getBespoke(id);
        navigate('/draft/view', {
          state: {
            id,
            templateId,
          },
        });
      }}
    >
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={coverImg?.src}
            title={templateName}
          />
        </CardActionArea>
        <CardActions className={classes.actionCard}>
          <div className={classes.wrapper}>
            <Save size={36} />
            {/*loading && (
              <CircularProgress size={48} className={classes.progress} />
            )*/}
          </div>
          <div>
            <Typography variant="body2" color="textSecondary" component="p">
              {templateName}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {privacy} {moment(createdAt).fromNow()}
            </Typography>
          </div>
        </CardActions>
      </Card>
    </a>
  );
}
