import styled from 'styled-components';

export const CreateSettingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .public-option-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 1.5rem;

    .option-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  }
`;
