import styled from 'styled-components';
// import Container, { primaryColor } from '../Global_Styles';

const primaryColor = '#2667B1';

export const PrivacyContainer = styled.div`
  padding-top: 55px;
  padding-bottom: 35px;
  align-items: center;
  width: 90%;
  max-width: 1224px;
  margin: 0 auto;

  h3 {
    text-align: center;
  }

  .policy-options {
    margin-top: 2rem;
    gap: 20px;
    align-items: center;

    .switcher {
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      width: 100%;
      max-width: 290px;
      padding: 10px;
      border-radius: 18px;
      color: #fff;
      font-weight: bold;
    }
  }

  @media screen and (max-width: 580px) {
    h3 {
      font-size: 1rem;
    }

    .policy-options {
      display: flex;
      flex-direction: column;
    }
  }

  @media screen and (min-width: 745px) {
    .policy-options {
      flex-direction: row;
      justify-content: center;
    }
    h3 {
      font-size: 1rem;
    }
  }
`;

// To be moved
export const TWrapper = styled.div`
  width: 100%;
  max-width: 1184px;
  margin: 0 auto;
  margin-bottom: 2rem;

  .flex-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  h3 {
    margin: 30px 0;
    font-size: 1.7rem;

    font-weight: bold;
  }

  h5,
  h4 {
    color: ${primaryColor};
    margin: 15px 0;
  }

  h5 {
    font-size: 1rem;
    color: #000;
  }

  h4 {
    font-size: 1.1rem;
    color: #000;
    font-weight: bold;
  }

  p {
    font-size: 0.9rem;
    margin-top: 15px;
    margin-bottom: 7px;
  }

  span {
    line-height: 1.3rem;
    display: block;
    padding-bottom: 8px;
  }

  li,
  span {
    a {
      font-size: 1rem;
      margin-right: 7px;
      text-decoration: none;
      color: ${primaryColor};
    }
  }

  .bold-text {
    font-weight: bold;
    display: inline;
    margin-right: 5px;
  }

  ul {
    margin: 6px 0;

    li {
      list-style: disc;
    }
  }

  ul,
  ol {
    padding-left: 18px;
    gap: 10px;

    li {
      margin-bottom: 10px;
      font-size: 0.9rem;
      line-height: 1.3rem;
      flex-wrap: wrap;
      text-align: justify;
    }
  }

  .sub {
    color: red;
    font-size: 0.9rem;
    line-height: 1.3rem;
    flex-wrap: wrap;
    text-align: justify;
    padding-left: 5px;
    list-style: lower-alpha;
    margin-bottom: 10px;
  }

  .single {
    margin-top: 10px;
  }

  .end-remark-TC {
    h5 {
      color: #000;
      font-size: 1rem;
      margin-bottom: 5px;
    }
    p {
      padding: 0;
    }
  }
  .bottom_list_contact {
    li {
      list-style: none;
    }
  }
  @media screen and (max-width: 320px) {
    width: 95%;
    h3 {
      margin-top: 15px;
    }

    span,
    li,
    p {
      text-align: justify;
    }
  }
`;
