import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Day from './Day';

import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import LazyLoad from 'react-lazy-load';
import { useDropzone } from 'react-dropzone';
import {
  setPrevMonthNumber,
  setNextMonthNumber,
  setMonthNumber,
  setCountNextYears,
  setCountPrevYears,
  setMonthNumberFromYear,
  setcountNumberOfYears,
  setCountNumberOfYears,
} from '../../../../store/features/monthSlice';
import {
  setSelectedDate,
  setEndDate,
} from '../../../../store/features/timeSlice';
import Button from '../utils/utilComponents/Button';
import { FiChevronLeft, FiChevronRight, FiEdit } from 'react-icons/fi';
import {
  MonthContainer,
  RightSideCalendarContainer,
} from './styles/calendar.Styles';
import {
  setShowAddMonthImages,
  setShowEventModel,
  setShowMonthModel,
} from '../../../../store/features/modalSlice';
import { getTime } from '../../../../util';
import {
  getCalendarEvent,
  setMonthImage,
  removeSelectedEvent,
  setSelectedEvent,
  addUpdateCalendarYears,
  DeleteDateCalendarYear,
  sortMonths,
} from '../../../../store/features/calendarSlice';
import { AddMonth } from '../../../../store/reducers/CurrentDraft';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import client from '../../../../services';
import Dialog from '@material-ui/core/Dialog';
import { Grid, Button as MButton } from '@material-ui/core';
import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {
  canvasPreview,
  extractImageFileExtensionFromBase64,
  UploadImageToS3,
  seizer,
} from '../../../../functions';
import { useDebounceEffect } from './useDebounceEffect';
import uuid from 'react-uuid';
import { RotatingLines } from 'react-loader-spinner';
import { BsFillCloudUploadFill } from 'react-icons/bs';
import './styles/uploadModal.css';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(1)',
    },
    '25%': {
      transform: 'scale(1.03)',
    },
    '50%': {
      transform: 'scale(1.06)',
    },
    '75%': {
      transform: 'scale(1.07)',
    },
    '100%': {
      transform: 'scale(1.1)',
    },
  },

  loaderSkeletonMonth: {
    height: '22rem',
    width: '100%',
  },

  loader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  loaderSkeleton: {
    height: '7rem',
    width: '100%',
  },
  next: {
    background: '#2667B1',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2666B1',
    },
    previewImage: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    btnCon: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
    },
    previewImage: {},
  },
  floatBtn: {
    position: 'fixed',
    bottom: '100px',
    right: '3%',
    zIndex: '1000px',
    backgroundColor: '#2667B1',
    width: '4rem',
    height: '4rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',

    boxShadow: '0 0 0.5rem rgba(38, 103, 177,0.3)',
    animation: '$ripple 1.2s infinite ease-in-out',
  },

  floatBtnSvg: { fontSize: '1.5rem', color: '#ffffff' },
}));
const Month = ({ month }) => {
  const { t } = useTranslation(['dashboard']);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [temImg, setTemImg] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { monthNumber, countNumberOfYears } = useSelector(
    (state) => state.month
  );
  const { months, yearPicked } = useSelector((state) => state.calendar);
  const draft = useSelector((state) => state.draft);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [aspect, setAspect] = useState(16 / 9);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [numberOfYears, setNumberOfYears] = useState(months.length / 12);
  const [yearsInCalendar, setYearsInCalendar] = useState([]);
  const [loadingYear, setLoadingYear] = useState(false);

  // To redirect to dasboard when array for mont is empty
  let dateFormated = dayjs(new Date(dayjs().year(), monthNumber)).format(
    'YYYY MMMM'
  );

  const saveAllPresentYear = () => {
    console.log(months, ' Months');
    let allYear = [];
    for (const month of months) {
      allYear.includes(dayjs(month?.date).format('YYYY'))
        ? null
        : allYear.push(dayjs(month?.date).format('YYYY'));
    }

    setNumberOfYears(allYear.length);

    setYearsInCalendar(allYear);
  };

  useEffect(() => {
    if (draft['months'].length <= 0) {
      navigate('/dashboard/create');
    } else {
      dispatch(AddMonth(months));
    }
    setShowAddMonthImages(false);
    saveAllPresentYear();
  }, [months]);

  useEffect(() => {
    // Set init state of month
    // number and count number of years
    // Due to persisitent state

    dispatch(setMonthNumber(0));
    dispatch(setCountNumberOfYears());
  }, []);

  const handleEvent = (date) => {
    //  To set day clicked  in Obj format
    dispatch(removeSelectedEvent(null));
    dispatch(setSelectedDate(date));
    dispatch(setEndDate(date));
    dispatch(setShowEventModel(true));
    dispatch(setShowMonthModel(false));
  };

  const handleEventEdit = (event, details) => {
    // Passing Id of event)
    console.log(details, 'day with event on it ', event);
    dispatch(setSelectedEvent(details));
    dispatch(setSelectedDate(details?.startDate));
    dispatch(setShowEventModel(true));
    // dispatch(getCalendarEvent(event));
  };

  const imgUser = draft['months']?.filter((val, i) => {
    if (i === monthNumber) return val;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  // Drop zone for image upload
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = async () => {
          // Send Content to server
          const binaryStr = reader.result;

          var i = new Image();
          i.onload = function () {
            setAspect(seizer(i.width, i.height));
            if (i.width < 1300 && i.height < 1300) {
              setCrop({
                unit: '%', // Can be 'px' or '%'
                x: 0,
                y: 0,
                width: 100,
                height: 100,
              });
            } else {
              setCrop(
                centerAspectCrop(i.width, i.height, seizer(i.width, i.height))
              );
            }
          };
          i.src = binaryStr;
          setTemImg(binaryStr);
          setCrop(undefined); // Set an immediate crop region
          handleClickOpen();
        };

        reader.readAsDataURL(file);
      });
    },
    [monthNumber]
  );

  const {
    // open,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.png'],
    },
  });

  // Image Function
  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 100,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      // setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  // Upload image
  const uploadCover = async () => {
    setLoading(true);
    if (completedCrop?.width && completedCrop?.height && imgRef.current) {
      const canvas = document.createElement('canvas');
      canvasPreview(imgRef.current, canvas, completedCrop);

      const fileext = extractImageFileExtensionFromBase64(temImg);
      const imageData64 = canvas.toDataURL('image/' + fileext);

      const souvenirId = draft?.id;
      const monthId = months[monthNumber]?.id;

      canvas.toBlob(
        async function (blob) {
          await client
            .post(
              `/Souvenir/image-upload-update/${souvenirId}?monthId=${monthId}`,
              imageData64
            )
            .then((res) => {
              console.log(res.data, 'This is response');
              dispatch(
                setMonthImage({ monthNumber, binaryStr: res?.data?.picture })
              );
              setLoading(false);
              handleClose();
            })
            .catch((e) => {
              console.log(e);
              toast.error(
                t(
                  'Image upload failed, Please try again with a smaller Image file'
                )
              );
              setLoading(false);
              handleClose();
            });
        },
        'image/jpeg',
        0.5
      );
    } else {
      console.log('error');
    }
  };

  const handleGetMoreYear = async (curYearVal, { chevron } = {}) => {
    const bespokeId = draft?.id;

    if (chevron === true) {
      setLoadingYear(true);
    }
    // Check year presence
    if (!yearsInCalendar.includes(curYearVal)) {
      // Call this only when it the year Switcher Right chevron clicked

      // Perform api call
      await client
        .post(
          `/Souvenir/create-bespokeMonth/${bespokeId}?CurrentYear=${curYearVal}`
        )
        .then((res) => {
          console.log(
            ' Next  year Called ',
            res.data?.months,
            ' In year not pesent'
          );
          dispatch(addUpdateCalendarYears(res.data?.months));
          setLoadingYear(false);
        })
        .catch((err) => {
          console.log(err, ' Error');
          setLoadingYear(false);
        });
    } else {
      await client
        .post(
          `/Souvenir/create-bespokeMonth/${bespokeId}?CurrentYear=${curYearVal}`
        )
        .then((res) => {
          console.log(' Prev  year Called ', res.data?.months);

          // Delete Year (Next year)
          dispatch(DeleteDateCalendarYear(curYearVal));

          dispatch(addUpdateCalendarYears(res.data?.months));
          dispatch(sortMonths());

          setLoadingYear(false);
        })
        .catch((err) => {
          console.log(err, ' Error');
          setLoadingYear(false);
        });
    }
  };

  console.log(yearsInCalendar, ' Year picked');
  return (
    <React.Fragment>
      <RightSideCalendarContainer className="flex flex-col">
        <div className="edit-background flex flex-col">
          {loading ? (
            <Skeleton variant="rect" className={classes.loaderSkeleton} />
          ) : (
            <div
              className={
                imgUser?.length !== 0 && imgUser[0]['background']
                  ? 'month-cover'
                  : 'img-container'
              }
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {imgUser?.length !== 0 && imgUser[0]['background'] ? (
                <LazyLoad height={'100%'} width={'100%'} offset={200}>
                  <img
                    className="month-cover"
                    src={`${imgUser[0]['background']}`}
                    // src={temImg}
                    alt="background cover"
                  />
                </LazyLoad>
              ) : (
                <div className="text-container">
                  {' '}
                  {isDragAccept && (
                    <span>t("Drop the calendar cover here...")</span>
                  )}
                  {isDragReject && (
                    <span>t("calendar cover ought to be a png file")</span>
                  )}
                  {!isDragActive && <span>{t('Click to upload image')}</span>}
                </div>
              )}
            </div>
          )}
        </div>

        {/* Main Calendar */}

        {!loadingYear ? (
          <>
            {/* Year Switcher */}
            <div className="month-inc-dec flex a-center">
              {countNumberOfYears > 1 && monthNumber != 0 && (
                <Button
                  content={<FiChevronLeft />}
                  func={() => {
                    let curYearVal = dayjs(months[monthNumber]?.date).format(
                      'YYYY'
                    );

                    const prevYear = new Date(
                      new Date().setFullYear(
                        new Date(curYearVal.toString()).getFullYear() - 1
                      )
                    )
                      .getFullYear()
                      .toString();

                    dispatch(setCountPrevYears());
                    dispatch(setMonthNumberFromYear());
                    handleGetMoreYear(prevYear, { chevron: true });
                  }}
                />
              )}

              <p className="t-sm">
                {' '}
                {dayjs(months[monthNumber]?.date).format('YYYY')}
              </p>

              <Button
                content={<FiChevronRight />}
                func={() => {
                  let curYearVal = dayjs(months[monthNumber]?.date).format(
                    'YYYY'
                  );

                  const nextYear = new Date(
                    new Date().setFullYear(
                      new Date(curYearVal.toString()).getFullYear() + 1
                    )
                  )
                    .getFullYear()
                    .toString();

                  dispatch(setCountNextYears());
                  dispatch(setMonthNumberFromYear());
                  handleGetMoreYear(nextYear, { chevron: true });
                }}
              />
            </div>

            {/* Month Switcher */}
            <div className="month-inc-dec flex a-center">
              {monthNumber >= 1 && (
                <Button
                  content={<FiChevronLeft />}
                  func={() => {
                    dispatch(setPrevMonthNumber());

                    let curYearVal = dayjs(months[monthNumber]?.date).format(
                      'YYYY'
                    );

                    let prevYear = new Date(
                      new Date().setFullYear(
                        new Date(curYearVal.toString()).getFullYear() - 1
                      )
                    )
                      .getFullYear()
                      .toString();

                    let oldYear = new Date(
                      new Date().setFullYear(new Date().getFullYear() - 1)
                    )
                      .getFullYear()
                      .toString();

                    console.log(
                      parseInt(prevYear),
                      ' !== ',
                      parseInt(oldYear),
                      '   prev and old  ',
                      curYearVal
                    );

                    if (
                      dateFormated.split(' ')[1] === 'August' &&
                      parseInt(prevYear) !== parseInt(oldYear)
                    ) {
                      handleGetMoreYear(prevYear);
                    }
                  }}
                />
              )}
              <p className="t-sm">{t(dateFormated.split(' ')[1])}</p>
              <p className="t-sm date-show-small-screen">
                {dayjs(months[monthNumber]?.date).format('YYYY')}
              </p>
              {monthNumber <= months.length - 1 && (
                <Button
                  content={<FiChevronRight />}
                  func={() => {
                    dispatch(setNextMonthNumber());

                    // Calls this function when month is October
                    if (dateFormated.split(' ')[1] === 'June') {
                      let curYearVal = dayjs(months[monthNumber]?.date).format(
                        'YYYY'
                      );

                      const nextYear = new Date(
                        new Date().setFullYear(
                          new Date(curYearVal.toString()).getFullYear() + 1
                        )
                      )
                        .getFullYear()
                        .toString();

                      handleGetMoreYear(nextYear);
                    }
                  }}
                />
              )}
            </div>

            <div className="weeks">
              {month[0].map((row, i) => (
                <div className="t-center t-sm" key={i}>
                  {t(row.format('ddd'))}
                </div>
              ))}
            </div>
            <MonthContainer>
              {month.map((row, id) => (
                <React.Fragment key={id}>
                  {row.map((day, idx) => (
                    <Day
                      day={day}
                      key={idx}
                      func={handleEvent}
                      months={months}
                      year={dayjs(months[monthNumber]?.date).format('YYYY')}
                      funcEvent={handleEventEdit}
                    />
                  ))}
                </React.Fragment>
              ))}
            </MonthContainer>
          </>
        ) : (
          <div className={classes.monthLoader}>
            <Grid>
              <Skeleton
                variant="rect"
                className={classes.loaderSkeletonMonth}
              />
            </Grid>
          </div>
        )}
      </RightSideCalendarContainer>

      <Dialog
        // fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid item md={12} lg={12}>
          <ReactCrop
            style={{ width: '100%', height: '100%', overflowY: 'scroll' }}
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            aspect={aspect}
            locked={true}
            onComplete={(c) => setCompletedCrop(c)}
            className="react_crop"
            // disabled={true}
          >
            <img
              src={temImg}
              loading="lazy"
              style={{ width: '100%', height: '100%' }}
              ref={imgRef}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </Grid>

        <section className={classes.floatBtn} onClick={() => uploadCover()}>
          {loading ? (
            <RotatingLines
              strokeColor="#fff"
              strokeWidth="5"
              animationDuration="0.75"
              width="20"
              visible={loading}
            />
          ) : (
            <BsFillCloudUploadFill className={classes.floatBtnSvg} />
          )}
        </section>
      </Dialog>
    </React.Fragment>
  );
};

export default Month;
