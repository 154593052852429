import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CalendarHeaderContainer } from './styles/calendar.Styles';
import { getTodayDate } from '../../../../util';
import Profile from '../../../../assest/images/profile.jpg';
import Setting from '../../../../assest/images/setting.svg';
import Clock from '../utils/utilComponents/Clock';
import LogoMobile from '../../../../assest/images/logo-small.svg';
import Logo from '../../../../assest/images/logo-white.svg';
import MobileMenu from '../../../../assest/images/menu.svg';
import WalletLogo from '../../../../assest/images/wallet-logo.svg';
import ProfileLogo from '../../../../assest/images/profile-logo.svg';
import SettingLogo from '../../../../assest/images/setting-logo.svg';
import ChatLogo from '../../../../assest/images/chat-logo.svg';
import QuestionLogo from '../../../../assest/images/question-logo.svg';
import { FiX } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { Avatar } from '@material-ui/core';
import ProfileDropDown from '../../profile_drop_down/ProfileDropDown';
import { useTranslation } from 'react-i18next';

const CalendarHeader = () => {
  const { t } = useTranslation(['dashboard']);
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const user = useSelector((state) => state.user);
  const [profileShow, setProfileShow] = useState(false);
  const handleProfileShow = () => {
    setProfileShow(false);
  };

  return (
    <CalendarHeaderContainer className="flex j-btw a-center" show={showMenu}>
      <div className="left-side-header flex a-center">
        <div className="big-logo">
          <img src={Logo} alt="small logo" onClick={() => navigate('/')} />
        </div>

        <div className="mobile-menu">
          <img
            src={LogoMobile}
            alt="small logo"
            onClick={() => navigate('/')}
          />
        </div>
      </div>

      <div className="date-time">
        <p>
          {t('Date')}: {getTodayDate()}
        </p>
      </div>

      <div className="right-side-header flex a-center">
        <div className="profile-image">
          {user?.picture ? (
            <Avatar
              alt="Picture"
              src={`${user?.picture}?${new Date()}`}
              className="smallImg"
              onClick={() => setProfileShow((prevState) => !prevState)}
            />
          ) : (
            <Avatar
              className="smallImg"
              onClick={() => setProfileShow((prevState) => !prevState)}
            >
              {user?.firstName !== ''
                ? user?.firstName[0]
                : user?.organisationName[0].toUpperCase()}
            </Avatar>
          )}
          {profileShow && (
            <div>
              <div
                className="back_profile"
                onClick={() => setProfileShow(false)}
              ></div>
              <ProfileDropDown move="-180" func={handleProfileShow} />
            </div>
          )}
        </div>
      </div>
    </CalendarHeaderContainer>
  );
};

export default CalendarHeader;
