import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HeaderContainer } from './Header.style';
import Logo from '../../../assest/images/logo-white.svg';
import Toggle from '../../../assest/images/light-mode.svg';
import Share from '../../../assest/images/share_img.svg';
import Copy from '../../../assest/images/copy.svg';
import Save from '../../../assest/images/save.svg';
import { copy } from '../../../functions/index';
import ShareComponet from '../share';
import Dialog from '@material-ui/core/Dialog';
import { useSelector, useDispatch } from 'react-redux';
// import { AddMonth } from '../../../store/reducers/CurrentDraft';
import { generate } from '../../../store/reducers/CurrentDraft';
import { setCalendarMonth } from '../../../store/features/calendarSlice';
import { useUser } from '../../../authenicationProvider';
import client from '../../../services';
import { toast } from 'react-toastify';
import { RotatingLines } from 'react-loader-spinner';
import { FiEdit, FiSave } from 'react-icons/fi';
import { BiCodeAlt } from 'react-icons/bi';
import { Avatar } from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import ProfileDropDown from '../profile_drop_down/ProfileDropDown';
import { useTranslation } from 'react-i18next';

const Header2 = () => {
  const { t } = useTranslation(['dashboard']);
  const { user } = useUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const beskpoke = useSelector((state) => state.bespoke);

  const [open, setOpen] = React.useState(false);
  // Loading State
  const [processing, setProcessing] = useState(false);
  const [profileShow, setProfileShow] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const userDetails = useSelector((state) => state.user);

  const { id, templateName, coverImg, privacy, createdAt, userId, share } =
    beskpoke;

  console.log('beckly ', beskpoke);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Profile menu show
  const handleProfileShow = () => {
    setProfileShow(false);
  };

  // Save Bespoke Calendar
  const saveFunc = async () => {
    setProcessing(true);
    // Check user is login
    if (!user) {
      navigate(`/signup/${beskpoke?.id}`);
    } else {
      await client
        .post(`/Souvenir/savesouvenirvault?souvenirId=${beskpoke?.id}`)
        .then((res) => {
          setProcessing(false);

          toast.success(t('Bespoke Successfully Added to calendar'));
          navigate('/dashboard');
        })
        .catch((e) => {
          setProcessing(false);
          toast.error(e?.response?.data?.Message ?? e?.message);
        });
    }
  };

  return (
    <HeaderContainer>
      <div className="main-wrapper">
        <div className="logo-container">
          <img src={Logo} alt="logo lectie" onClick={() => navigate('/')} />
        </div>

        <div className="right-side header2_right_side">
          <div className="save-copy">
            {(userId == userDetails?.id && user) || share ? (
              <Tooltip title={t('Share')} placement="bottom-start">
                <div
                  className="icon share_icon_container"
                  onClick={() => handleClickOpen()}
                >
                  <img src={Share} alt="Share icon" />
                </div>
              </Tooltip>
            ) : null}

            {beskpoke?.userId == userDetails?.id && user ? (
              <Tooltip title={t('Embed link')} placement="bottom-start">
                <div className="icon" typeof="button">
                  <BiCodeAlt
                    onClick={() => {
                      if (
                        userDetails?.subscription?.subscriptionName == 'Basic'
                      ) {
                        toast.warning(
                          'Please Upgrade your plan to embed bespoke calendar on your website'
                        );
                      } else {
                        var embed =
                          process.env.NODE_ENV == 'development'
                            ? `<iframe width="640" height="1000" src="${process.env.REACT_APP_API_URL}/widget/${beskpoke?.id}" frameborder="0" allowfullscreen></iframe>`
                            : `<iframe width="640" height="1000" src="${process.env.REACT_APP_LIVE_API_URL}/widget/${beskpoke?.id}" frameborder="0" allowfullscreen></iframe>`;
                        copy(`${embed}`);
                      }
                    }}
                  />
                </div>
              </Tooltip>
            ) : null}
            {beskpoke?.userId == userDetails?.id && user ? (
              <Tooltip title={t('Edit bespoke')} placement="bottom-start">
                <div className="icon" typeof="button">
                  <FiEdit
                    onClick={() => {
                      dispatch(generate(beskpoke));
                      dispatch(setCalendarMonth(beskpoke?.months));
                      navigate(`/studio/event`);
                    }}
                  />
                </div>
              </Tooltip>
            ) : null}
            {/* {beskpoke?.userId == userDetails?.id ? null : (
              <Tooltip title={t("Save")} placement="bottom-start">
                <div
                  className="icon"
                  onClick={() => (processing ? null : saveFunc())}
                >
                  {processing ? (
                    <RotatingLines
                      strokeColor="#fff"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="20"
                      visible={processing}
                    />
                  ) : (
                    <FiSave />
                  )}
                </div>
              </Tooltip>
            )} */}
          </div>
          {user && (
            <div className="profile-image heading_profile_image">
              {userDetails?.picture ? (
                <Avatar
                  alt="Picture"
                  src={`${userDetails?.picture}?${new Date()}`}
                  className="smallImg"
                  onClick={() => {
                    setProfileShow((prevState) => !prevState);
                    setDropDown(false);
                  }}
                />
              ) : (
                <Avatar
                  className="smallImg"
                  onClick={() => {
                    setProfileShow((prevState) => !prevState);
                    setDropDown(false);
                  }}
                >
                  {userDetails?.firstName !== ''
                    ? userDetails?.firstName[0]
                    : userDetails?.organisationName[0].toUpperCase()}
                </Avatar>
              )}
              {profileShow && (
                <div>
                  <div
                    className="back_profile"
                    onClick={() => setProfileShow(false)}
                  ></div>

                  <ProfileDropDown move="-55" func={handleProfileShow} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ShareComponet data={beskpoke} />
      </Dialog>
    </HeaderContainer>
  );
};

export default Header2;
