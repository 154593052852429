import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  EmailIcon,
} from 'react-share';
import cx from 'classnames';
import './style/share.css';
import { copy } from '../../functions/index';

// Styles
const useStyles = makeStyles({
  root: {
    margin: '0 auto',
    background: '#fff',
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 110px)',
  },
  shareCon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  qr: {
    width: '100%',
    height: '100%',
  },
  inputCon: {
    marginTop: 15,
    display: 'flex',
    height: 44,
    width: '100%',
    position: 'relative',
  },
  btn: {
    marginRight: 15,
    background: '#2667B1',
    width: 80,
    borderRadius: 8,
    textAlign: 'center',
    fontSize: 16,
    height: '100%',
    position: 'absolute',
    right: 0,
    color: '#fff',
  },
  input: {
    width: 'calc(100% - 80px)',
    padding: '5px 2px 5px',
    border: '1px solid #2667B1',
    height: '100%',
  },
  shareIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    textAlign: 'center',
    marginBottom: 15,
  },
  headerText: {
    color: '#2A2A2A',
    fontSize: 28,
    fontFamily: 'Raleway',
    fontWeight: '700',
  },
});

export default function Share() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container justifyContent="center">
        <Grid item md={6} sm={12} lg={6} xs={12} className={classes.shareCon}>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={cx([classes.text, classes.headerText, 'header-text'])}
          >
            Congratulations, you have successfully created your event
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.text}
          >
            Scan the barcode code to share invite
          </Typography>
          <Grid item sm={5} md={6} xs={6}>
            <img
              src={require('../../assest/images/qr-code.png')}
              alt="barcode"
              className={classes.qr}
            />
          </Grid>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.text}
          >
            Share and Invite your friends
          </Typography>
          <Grid container spacing={0} justifyContent="center">
            <Grid item md={2} sm={2} xs={2} className={classes.shareIcon}>
              <FacebookShareButton url="lectie.com">
                <FacebookIcon size={40} round />
              </FacebookShareButton>
            </Grid>
            <Grid item md={2} sm={2} xs={2} className={classes.shareIcon}>
              <TwitterShareButton url="lectie.com">
                {/* <TwitterIcon size={40} round /> */}
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 41 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="0.5" width="40" height="40" rx="20" fill="#43ABF9" />
                  <path
                    d="M18.1663 12.666H12.333L19.2163 21.8452L12.708 29.3327H14.9163L20.2397 23.2085L24.833 29.3327H30.6663L23.493 19.7677L29.6663 12.666H27.458L22.4697 18.4043L18.1663 12.666ZM25.6663 27.666L15.6663 14.3327H17.333L27.333 27.666H25.6663Z"
                    fill="white"
                  />
                </svg>
              </TwitterShareButton>
            </Grid>
            <Grid item md={2} sm={2} xs={2} className={classes.shareIcon}>
              <TelegramShareButton url="lectie.com">
                <TelegramIcon size={40} round />
              </TelegramShareButton>
            </Grid>
            <Grid item md={2} sm={2} xs={2} className={classes.shareIcon}>
              <EmailShareButton
                url="lectie.com"
                subject={'Bespoke Invitation'}
                body={`This is the body of the email, content writer provide content`}
              >
                <EmailIcon size={40} round />
              </EmailShareButton>
            </Grid>
          </Grid>
          <div className={classes.inputCon}>
            <input
              className={classes.input}
              id="outlined-adornment-amount"
              value={
                'https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox/FMfcgzGqRGgWbFNpDzFQMrqlnvhwzRJD'
              }
            />
            <button
              onClick={() =>
                copy(
                  'https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox/FMfcgzGqRGgWbFNpDzFQMrqlnvhwzRJD'
                )
              }
              className={classes.btn}
            >
              Copy
            </button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
