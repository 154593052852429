import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Typography, Button } from '@material-ui/core';
import { BallTriangle } from 'react-loader-spinner';
import client from '../../services';
import cx from 'classnames';
import '../payment/style/share.css';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loadDrafts } from '../../store/reducers/draftReducer';
import CalendarDefaultCover from '../../assest/images/dashboardCalendarFrame.png';

const useStyles = makeStyles({
  root: {
    background: '#fff',
    padding: '20px',
  },
  loader: {
    width: '100%',
    height: '100%',
  },
});

export default function ChooseUpgrade({
  handleClickOpenTemplate,
  userLocation,
  error,
}) {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [souvenirs, setSouvenirs] = useState([]);
  const covers = useSelector((state) => state.files.covers);

  const classes = useStyles();
  const dispatch = useDispatch();

  // Load Bespoke calendars

  const loadCalendars = async () => {
    await client
      .get(`Souvenir/publishedsouvenir?souvenirStatus=publish`)
      .then((res) => {
        let data = res.data;
        const filteredObjects = data.filter(
          (item) =>
            item.subscription.subscriptionName != 'Premium' &&
            item.privacy !== 'shared'
        );

        console.log(filteredObjects, '  filter objects');

        setSouvenirs(filteredObjects);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadCalendars();
  }, []);

  const next = (id, subName) => {
    return navigate('/dashboard/upgrade-plans', {
      state: { souvId: id, subName },
    });
  };

  return (
    <div className="">
      <div className="fixed left-0 top-0 flex h-full w-full px-4 items-center justify-center bg-black  bg-opacity-50 ">
        <div className="max-h-full w-full max-w-xl  sm:rounded-2xl md:px-0 sm:mx-2  overflow-y-auto bg-white">
          <div className="w-full">
            <div className="static top-[120px] right-[400px]">
              <button
                className="w-8 h-8 font-bold font-body rounded-full
                       bg-[#2667B1] hover:bg-blue-500 text-white"
                onClick={() => handleClickOpenTemplate(false)}
              >
                X
              </button>
            </div>
            <div className=" my-4 max-w-[500px] mx-auto ">
              <div className="mb-8">
                <h1 className="mb-4 text-center text-2xl font-body font-extrabold">
                  Select a Calendar to upgrade{' '}
                </h1>
              </div>

              <div className="space-y-4 ">
                {loading ? (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    className={classes.loader}
                  >
                    <Grid item xs={2} md={2} sm={2} lg={2}>
                      <BallTriangle
                        height={'100%'}
                        width={'100%'}
                        radius={5}
                        color="#2667b1"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <div className="space-y-4  h-64 overflow-scroll">
                    {souvenirs &&
                      souvenirs?.map((item, i) => (
                        <div
                          className=" bg-white pr-4 h-14  border rounded-lg w-full font-semibold flex justify-between items-center "
                          key={i}
                        >
                          <div className="overflow-hidden rounded-l-lg h-full ">
                            <img
                              src={
                                !item?.coverImg?.src
                                  ? CalendarDefaultCover
                                  : item?.coverImg?.src
                              }
                              width="100"
                              className="h-full mr-2"
                            />
                          </div>
                          <p className="text-ellipsis overflow-hidden w-1/3 text-sm px-2">
                            {item.templateName}{' '}
                          </p>
                          <Button
                            variant="contained"
                            // color="primary"
                            style={{
                              textTransform: 'none',
                              background: '#2667B1',
                              color: '#fff',
                              fontSize: 12,
                              padding: '0 44px',
                            }}
                            onClick={() => {
                              next(item.id, item.subscription.subscriptionName);
                              handleClickOpenTemplate(false);
                            }}
                          >
                            Upgrade
                          </Button>{' '}
                        </div>
                      ))}
                  </div>
                )}

                <div className="text-center w-3/4 mx-auto font-body font-bold">
                  <fieldset class="border-t border-black">
                    <legend class="mx-auto px-2 text-black text-xl ">Or</legend>
                  </fieldset>
                  <p className="py-4">Create a new calendar</p>
                  <Button
                    variant="contained"
                    // color="primary"
                    style={{
                      textTransform: 'none',
                      background: '#2667B1',
                      color: '#fff',
                      fontSize: 12,
                      padding: '0 64px',
                    }}
                    onClick={() => {
                      navigate('/dashboard/create');
                    }}
                  >
                    Create calendar
                  </Button>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
