import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { getDigitInUnit } from '../../functions';

const useStyles = makeStyles({
  root: {
    width: '100%',
    '& :hover': {
      background: '#2667B1',
      color: '#fff',
    },
    cursor: 'pointer',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    textAlign: 'center',
    color: 'inherit',
    fontFamily: 'Nunito',
    fontWeight: 'bold',
  },
  pos: {
    marginBottom: 12,
    textAlign: 'center',
    color: 'inherit',
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: '34px',
  },
  selected: {
    background: '#2667B1',
    color: '#fff',
  },
  cardTxt: {
    fontSize: 14,
    color: 'inherit',
    fontFamily: 'Nunito',
    fontWeight: '900',
    padding: 8,
  },
  bottomBar: {
    borderBottom: '2px solid hsl(240,8%,85%,0.583)',
  },
  TopBar: {
    borderTop: '2px solid hsl(240,8%,85%,0.583)',
  },
  oneOff: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '22px',
    color: 'rgba(234, 15, 15, 0.8)',
    marginBottom: '5px',
  },
  discount: {
    display: 'block',
    marginTop: '10px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '32px',
    lineHeight: '33px',
    color: 'rgba(234, 15, 15, 0.8)',
  },
});

// Price Symbol

export default function PriceCard(props) {
  const { t } = useTranslation(['common']);
  const classes = useStyles();
  const { plan, selected, curSymbol, variation } = props;

  return (
    <Card
      className={cx([
        classes.root,
        selected?.id == plan?.id ? classes.selected : null,
      ])}
    >
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {t(plan?.subscriptionName)} {variation ? 'Yearly' : 'Monthly'}
        </Typography>
        <Grid item md={12}>
          <Typography className={classes.pos} color="textSecondary">
            {plan?.currency}{' '}
            {curSymbol === 'NGN' ? getDigitInUnit(plan?.price) : plan?.price}
          </Typography>
        </Grid>

        <Grid md={12}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={cx([classes.bottomBar, classes.TopBar])}
          >
            <Grid md={10}>
              <Typography
                className={classes.cardTxt}
                color="textSecondary"
                gutterBottom
              >
                {t('Number of calendars')}
              </Typography>
            </Grid>
            <Grid md={2}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                {plan?.calender_Number}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.bottomBar}
          >
            <Grid md={10}>
              <Typography
                className={classes.cardTxt}
                color="textSecondary"
                gutterBottom
              >
                {t('Calendar hosting')}
              </Typography>
            </Grid>
            <Grid md={2}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                {plan?.calender_Hosting ? t('Yes') : t('No')}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.bottomBar}
          >
            <Grid md={10}>
              <Typography
                className={classes.cardTxt}
                color="textSecondary"
                gutterBottom
              >
                {t('Live calendar updates')}
              </Typography>
            </Grid>
            <Grid md={2}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                {plan?.calender_Update ? t('Yes') : t('No')}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.bottomBar}
          >
            <Grid md={10}>
              <Typography
                className={classes.cardTxt}
                color="textSecondary"
                gutterBottom
              >
                {t('Widget to embed on website')}
              </Typography>
            </Grid>
            <Grid md={2}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                {plan?.embed_Widget ? t('Yes') : t('No')}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.bottomBar}
          >
            <Grid md={8}>
              <Typography
                className={classes.cardTxt}
                color="textSecondary"
                gutterBottom
              >
                {t('Size of audience')}
              </Typography>
            </Grid>
            <Grid md={4}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                {/* {plan?.audience_Size} */}
                {t('Unlimited')}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.bottomBar}
          >
            <Grid md={8}>
              <Typography
                className={classes.cardTxt}
                color="textSecondary"
                gutterBottom
              >
                {t('Lectie watermark on website')}
              </Typography>
            </Grid>
            <Grid md={4}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                {plan?.subscriptionName === 'Basic'
                  ? 'N/A'
                  : plan?.watermark
                  ? t('Yes')
                  : t('No')}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.bottomBar}
          >
            <Grid md={8}>
              <Typography
                className={classes.cardTxt}
                color="textSecondary"
                gutterBottom
              >
                {t('Reminder notifications for events')}
              </Typography>
            </Grid>
            <Grid md={4}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                {plan?.reminder_notification}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.bottomBar}
          >
            <Grid md={8}>
              <Typography
                className={classes.cardTxt}
                color="textSecondary"
                gutterBottom
              >
                {t('Private calendar')}
              </Typography>
            </Grid>
            <Grid md={4}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                {plan?.private_Calendar ? 'Yes' : 'No'}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.bottomBar}
          >
            <Grid md={8}>
              <Typography
                className={classes.cardTxt}
                color="textSecondary"
                gutterBottom
              >
                {t('Add social media linked Icons')}
              </Typography>
            </Grid>
            <Grid md={4}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                {plan?.socal_media_link_icon ? t('Yes') : t('No')}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.bottomBar}
          >
            <Grid md={8}>
              <Typography
                className={classes.cardTxt}
                color="textSecondary"
                gutterBottom
              >
                {t('Calendar recipients can share event invites')}
              </Typography>
            </Grid>
            <Grid md={4}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                {plan?.receipicent_share_event ? t('Yes') : t('No')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
