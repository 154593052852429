import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles'

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'acumin-pro',
      'Arial',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  }
});

export default theme;
