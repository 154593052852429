import React, { useState, useEffect } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { BallTriangle } from 'react-loader-spinner';
import { useSelector, useDispatch } from 'react-redux';
import './wallet/wallet.css';
import WalletDashboard from './wallet-dashbaord/WalletDashboard';
import DraftList from './wallet/draft';
import client from '../services';
import { loadDrafts } from '../store/reducers/draftReducer';
import { generate } from '../store/reducers/CurrentDraft';
import { setCalendarMonth } from '../store/features/calendarSlice';
import { setTVDraft } from '../store/reducers/draftTVEvents';

const useStyles = makeStyles({
  root: {
    background: '#fff',
    padding: '20px',
  },
  loader: {
    width: '100%',
    height: '100%',
  },
});
export default function Draft() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const covers = useSelector((state) => state.drafts.covers);
  const classes = useStyles();

  // Load Bespoke calendars
  const loadCalendars = async () => {
    await client
      .get(`Souvenir/draftsouvenir?souvenirStatus=draft`)
      .then((res) => {
        console.log('Drafts are Draft view', res.data);
        dispatch(loadDrafts(res?.data));

        if (res?.data?.templateId === '4') {
          dispatch(setTVDraft(res?.data));
        } else {
          dispatch(generate(res?.data));
          dispatch(setCalendarMonth(res?.data?.months));
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log('Error found is ', e);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadCalendars();
  }, [loading]);

  return !loading & (covers?.length == 0) ? (
    <WalletDashboard />
  ) : loading ? (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.loader}
    >
      <Grid item xs={2} md={2} sm={2} lg={2}>
        <BallTriangle
          height={'100%'}
          width={'100%'}
          radius={5}
          color="#2667b1"
          ariaLabel="ball-triangle-loading"
          wrapperClass={{}}
          wrapperStyle=""
          visible={true}
        />
      </Grid>
    </Grid>
  ) : (
    <div className={classes.root}>
      <DraftList />
    </div>
  );
}
