import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import cx from 'classnames';

const useStyles = makeStyles((theme) => ({
  grid: {
    margin: '18px 0px 18px 0px ',
  },
  img: {
    width: '100%',
    height: '18rem',
  },
}));

export default function Slider({ processing }) {
  const calendarImgs = useSelector((state) => state.wallet.coverImg);
  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.grid}>
      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
        <img
          className={cx([classes.img, 'slider-image'])}
          src={require('../../assest/images/dashboard_image.png')}
          srcSet={require('../../assest/images/dashboard_image.png')}
          alt={'cover image'}
          loading="lazy"
        />
      </Grid>
    </Grid>
  );
}
