import React from 'react';
import styled from 'styled-components';

const VerifyInputField = ({ val, inputValues, func, isError }) => {
  return (
    <VerifyInputFieldCntainer isError={isError}>
      <input
        type="number"
        value={inputValues[val]}
        onChange={(e) => func(e, val)}
      />
    </VerifyInputFieldCntainer>
  );
};

export default VerifyInputField;

const VerifyInputFieldCntainer = styled.section`
  input {
    width: 3rem;
    height: 4rem;
    padding: 12px;
    border-radius: 8px;
    scroll-behavior: none;
    overflow: hidden;
    border: 1px solid
      ${({ isError }) =>
        isError ? 'rgba(234, 15, 15, 1)' : 'rgba(22, 22, 22, 1)'};
    background-color: rgba(255, 255, 255, 1);
    color: rgba(22, 22, 22, 1);
    font-size: 1rem;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }

    &:focus {
      outline: none;
      border: 1px solid rgba(38, 103, 177, 0.6);
    }
  }
`;
