import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Month from '../../component/month';
import styled from 'styled-components';
import LazyLoad from 'react-lazy-load';
import { BsArrowDown } from 'react-icons/bs';
import '../../component/styles/month.css';
import client from '../../../services';
import { addYearToBepoke } from '../../../store/reducers/bespokeView';
import YearLoading from '../YearLoading';
import { getCountyCode, userGeolocation } from '../../../functions';

function BespokeViewTemplateZero() {
  const beskpoke = useSelector((state) => state.bespoke);
  const defaultImage = useSelector((state) => state.default);
  const [loadingYear, setLoadingYear] = useState(false);
  const dispatch = useDispatch();

  console.log(defaultImage, 'default image ere');

  let max = 2;
  let min = 0;
  var theme = Math.floor(Math.random() * (max - min + 1)) + min;

  let newDefaultImage = { ...defaultImage };

  let count = 0;
  for (let i = 12; i <= beskpoke?.months.length - 1; i++) {
    newDefaultImage[i] = defaultImage[count];
    count++;
    if (count > 11) {
      count = 0;
    }
  }

  let years = [];

  const view = useRef();
  const ref = useRef();

  const getNewCalendar = async (year) => {
    console.log('.... In Func Call');
    setLoadingYear(true);

    let country = new userGeolocation().getUserCountry();
    let { CountryCode } = getCountyCode(country) || 'NGN';

    let bodyData = {
      colors: [],
      country: CountryCode,
    };

    await client
      .post(
        `/Souvenir/souvenirMonthsByYear/${beskpoke?.id}?year=${year}`,
        bodyData
      )
      .then((res) => {
        dispatch(addYearToBepoke(res.data));
        // Delete Year (Next year)
        years.push(year);
        console.log(years, ' Years');
        setLoadingYear(false);
      })
      .catch((err) => {
        console.log(err, ' Error');
        setLoadingYear(false);
      });
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];

      let dateVal = ref.current?.textContent;

      console.log(' Tis is hs nnj  === ', dateVal);

      !years.includes(dateVal) ? years.push(dateVal) : null;

      let yearToGet = new Date(
        new Date().setFullYear(new Date(dateVal).getFullYear() + 1)
      )
        .getFullYear()
        .toString();

      if (
        years.includes(yearToGet) === false &&
        entry.isIntersecting === true
      ) {
        console.log('entry', entry);
        console.log(yearToGet, ' Get year  ', dateVal);
        console.log('entry.isIntersecting', entry.isIntersecting);

        getNewCalendar(yearToGet);

        console.log('Call function........');
      }
    });
    observer.observe(view.current);
  }, []);

  return (
    <>
      <div className="bespoke-con">
        {beskpoke.months.map((item, i) => (
          <Month
            data={item}
            key={i}
            defaultCover={newDefaultImage[i][theme]}
            status={beskpoke?.status}
            calendarPlan={beskpoke?.calendarPlan}
            ref={ref}
          />
        ))}
      </div>

      <h2 ref={view} style={{ visibility: 'hidden' }}>
        The End
      </h2>

      {loadingYear && <YearLoading />}
    </>
  );
}

export default BespokeViewTemplateZero;

const YearCoverContainer = styled.div`
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .year_cover_img_container {
    width: 100%;
    height: 400px;
    .year_cover_image {
      width: 100%;
      height: 400px;
      image-rendering: pixelated;
    }
  }

  .bespoke_scroll_indicator_container {
    align-self: center;
    background: #43abf9;
    border-radius: 20px;
    height: 56px;
    width: 230px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    svg {
      color: #fff;
      font-size: 1rem;
      animation: bounce 0.9s ease-in-out infinite;
      position: relative;

      @keyframes bounce {
        0% {
          top: -10px;
        }

        50% {
          top: -7px;
        }

        75% {
          top: -4px;
        }

        100% {
          top: 0;
        }
      }
    }
  }
`;
