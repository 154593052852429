import { configureStore, combineReducers } from '@reduxjs/toolkit';
import bespoke from './reducers/bespokeView';
import beskpokeFilter from './reducers/bespokeFilter';
import customColorState from './reducers/customColorState';
import event from './reducers/event';
import studioSlice from './features/studioSlice';
import tvGuideDraft from './reducers/draftTVEvents';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

import monthSlice from './features/monthSlice';
import smallMonthSlide from './features/smallMonthSlice';
import modalSlice from './features/modalSlice';
import timeSlice from './features/timeSlice';
import calendarSlice from './features/calendarSlice';
import wallet from './reducers/calendarReducer';
import files from './reducers/fileReducer';
import draft from './reducers/CurrentDraft';
import userReducer from './reducers/userReducer';
import defaultCover from './reducers/defaultCover';
import drafts from './reducers/draftReducer';
import eventsSlice from './reducers/templateEvents';
import localforage from 'localforage';

const persistConfig = {
  key: 'root',
  whitelist: ['draft', 'calendar'],
  version: 1,
  storage: localforage,
};

const rootReducer = combineReducers({
  month: monthSlice,
  smallMonth: smallMonthSlide,
  calendarModal: modalSlice,
  startEventModal: timeSlice,
  calendar: calendarSlice,
  bespoke: bespoke,
  event: event,
  wallet: wallet,
  studio: studioSlice,
  files: files,
  draft: draft,
  drafts: drafts,
  user: userReducer,
  default: defaultCover,
  eventSlice: eventsSlice,
  beskpokeFilter: beskpokeFilter,
  customColorState: customColorState,
  tvGuideDraft: tvGuideDraft,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
