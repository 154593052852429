import React, { useEffect } from 'react';
import styled from 'styled-components';
import Header from '../../component/bespoke_header/Header';

import { useDispatch, useSelector } from 'react-redux';
import { BallTriangle } from 'react-loader-spinner';
import { useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import ShareEvent from '../../component/shareEvent';

import { loadEventsGroup } from '../../../store/reducers/templateEvents';
import TemplateThreeMonth from './component/templateThreeMonth';
import { Navigate } from 'react-router-dom';
import { getMonth } from '../../../util';
import dayjs from 'dayjs';
import getDaysInMonths from '../../component/bespoke-core/utils/utilComponents/getDaysInMonths';
import { addYearToBepoke } from '../../../store/reducers/bespokeView';
import client from '../../../services';

const useStyles = makeStyles({
  root: {
    background: '#fff',
    padding: '20px',
  },
  loader: {
    width: '100%',
    height: '100vh',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  coverImgContainer: {
    marginTop: '0px',
    height: '85vh',
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
  },

  imgCover: {
    width: '100%',
    height: '400px',
    imageRendering: 'pixelated',
  },
  moveCalendarBtn: {
    padding: '16px 15px',
    width: '230px',
    fontSize: '0.9rem',
    textAlign: 'center',
    color: '#ffffff',
    background: '#2667b1',
    borderRadius: '12px',
    cursor: 'pointer',
    alignSelf: 'center',
  },
});

const DraftViewTemplateThree = () => {
  let max = 2;
  let min = 0;
  var theme = Math.floor(Math.random() * (max - min + 1)) + min;
  const dispatch = useDispatch();

  const beskpokeDraft = useSelector((state) => state.draft);
  const defaultImage = useSelector((state) => state.default);

  // Group Event and Filtered Event on See More

  const [moreEvent, setMoreEvent] = useState([]);
  const [eventPopup, setEventPopup] = useState(false);
  const [popIndex, setPopIndex] = useState(0);
  const [shareOpen, setShareOpen] = useState(false);

  // Back to Dashboard for empty bespoke
  if (beskpokeDraft.months?.length < 1) {
    return <Navigate to="/dashboard" replace />;
  }

  // Styles
  const classes = useStyles();

  // Share Event
  const handleClickOpenShare = () => {
    setShareOpen(true);
  };
  const handleClose = () => {
    setShareOpen(false);
  };

  return (
    <>
      <TemplateThreeMonth
        popIndex={popIndex}
        setPopIndex={setPopIndex}
        eventPopup={eventPopup}
        setEventPopup={setEventPopup}
        moreEvent={moreEvent}
        setMoreEvent={setMoreEvent}
        beskpoke={beskpokeDraft}
        defaultImage={defaultImage}
        theme={theme}
        handleClickOpenShare={handleClickOpenShare}
      />
      {/* Share PopUp */}

      <Dialog
        open={shareOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ShareEvent data={moreEvent[popIndex]?.id} />
      </Dialog>
    </>
  );
};

export default DraftViewTemplateThree;
