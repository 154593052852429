import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid, Button } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import { Check } from 'react-feather';
import { useTranslation } from 'react-i18next';
import PrivateSucessPage from './PrivateSucessPage';
import { useSelector } from 'react-redux';

const styles = (theme) => ({
  listItem: {
    padding: `${theme.spacing.unit}px 0`,
  },
  total: {
    fontWeight: '700',
  },
  title: {
    marginTop: theme.spacing.unit * 2,
  },
  btn: {
    clear: 'both',
    border: 'none',
    color: '#fff',
    // maxWidth: '100px',
    width: '70%',
    height: '30px',
    margin: '0 auto',
    background: '#2667b1',
    borderRadius: '12px',
    cursor: 'pointer',
    '& .MuiButton-label': {
      fontSize: '14px',
    },
  },
  title: {
    fontSize: 14,
    textAlign: 'center',
    color: 'inherit',
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontWeight: 'bold',
    fontSize: '24px',
  },
  des: {
    textAlign: 'center',
    color: 'inherit',
    fontFamily: 'Nunito',
  },
  btnCon: {
    marginTop: '2rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  checkBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '6rem',
    height: '6rem',
    borderRadius: '50%',
    border: '1px solid #2667B1',
  },
  ckeckCon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 15,
  },
});

function SucessPage(props) {
  const { t } = useTranslation(['dashboard']);
  // To test
  const draft = useSelector((state) => state?.draft);
  const navigate = useNavigate();
  const params = useParams();
  const { classes } = props;


  return (
    <React.Fragment>
      {draft?.calender !== 'private' ? (
        <Grid container justifyContent="center">
          <Grid item md={12} className={classes.ckeckCon}>
            <div className={classes.checkBox}>
              <Check color="#2667B1" size={24} />
            </div>
          </Grid>

          <Grid item md={10}>
            <Typography variant="h5" gutterBottom className={classes.title}>
              {t('Thanks for purchasing a plan')}
            </Typography>
            <Typography variant="subtitle1" className={classes.des}>
              {t(
                'Please wait while we process your payment, it usually takes 30 seconds - 1 minute to complete transaction.'
              )}
            </Typography>
          </Grid>
          <Grid item md={6} sm={10} xs={10}>
            <div className={classes.btnCon}>
              {/*
              <Button
              variant="contained"
              color="primary"
              className={classes.btn}
              style={{ textTransform: 'none' }}
              onClick={() => navigate(`/bespoke/calendar/${params.id}`)}
            >
              View Calendar
            </Button>
  */}
              <Button
                variant="contained"
                color="primary"
                className={classes.btn}
                style={{ textTransform: 'none' }}
                onClick={() => navigate(`/dashboard`)}
              >
                {t('View Status')}
              </Button>
            </div>
          </Grid>
        </Grid>
      ) : (
        <PrivateSucessPage props={props} />
      )}
    </React.Fragment>
  );
}

SucessPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SucessPage);
