import { FormControl, InputLabel, Select, Box } from '@material-ui/core';
import React from 'react';

export const ToolbarDropdown = ({ title, value, onChange, children }) => {
  return (
    <Box sx={{ minWidth: 120 }}>
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{title}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label="Fonts"
        onChange={(e) => onChange(e.target.value)}
      >
   {children}
      </Select>
    </FormControl>
  </Box>
  );
};
