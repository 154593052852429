import React from 'react';
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { ChevronLeft, ChevronRight } from 'react-feather';
import dayjs from 'dayjs';
import {
  addYear,
  subYear,
  navDate,
} from '../../store/reducers/calendarReducer';
import { useSelector, useDispatch } from 'react-redux';
import client from '../../services';
import Skeleton from '@material-ui/lab/Skeleton';
const useStyles = makeStyles({
  calendarNav: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  year: {
    fontSize: '38px',
    padding: '0px 5px 0px 5px',
    textAlign: 'center',
  },
  icon: {
    marginTop: 8,
  },
});

function Nav(props) {
  const { setProcessing, processing } = props;
  const dispatch = useDispatch();
  const defaultDate = useSelector((state) => state.wallet?.defaultDate);
  const classes = useStyles();

  const getEventList = async (date) => {
    setProcessing(true);
    let trimDate = dayjs(date).format('YYYY-MM');
    console.log('calling api', trimDate);
    await client
      .post(`/Souvenir/eventlist?dateOnly=${trimDate}`)
      .then((res) => {
        console.log(res?.data, 'get bespoke calendar');
        dispatch(navDate(res?.data));
        setProcessing(false);
      })
      .catch((e) => {
        console.log(e);
        setProcessing(false);
      });
  };
  return (
    <>
      <div className={classes.calendarNav}>
        {/* {
    processing?
    <Skeleton variant="text" animation="wave" width={112} height={30}/>
    : */}
        <>
          <button
            onClick={async () => {
              let date = dayjs(defaultDate).subtract(1, 'year').toDate();

              if (
                dayjs(defaultDate).format('YYYY') !== dayjs(date).format('YYYY')
              ) {
                // Call api for event for the year
                console.log('new year', date);
                getEventList(date);
              }
              dispatch(subYear());
            }}
          >
            <ChevronLeft size={24} className={cx([classes.icon, 'nav-icon'])} />
          </button>

          <span className={classes.year}>
            {dayjs(defaultDate).format('YYYY')}
          </span>
          <button
            onClick={() => {
              let date = dayjs(defaultDate).add(1, 'year').toDate();
              if (
                dayjs(defaultDate).format('YYYY') !== dayjs(date).format('YYYY')
              ) {
                // Call api for event for the year
                console.log('new year', date);
                getEventList(date);
              }
              dispatch(addYear());
            }}
          >
            <ChevronRight
              size={24}
              className={cx([classes.icon, 'nav-icon'])}
            />
          </button>
        </>
        {/* } */}
      </div>
    </>
  );
}
export default Nav;
