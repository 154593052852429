import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import ShareComponet from '../component/share';
// import { setDeleteEvent } from "../../../../store/features/calendarSlice";
import { Dialog } from '@material-ui/core/';
import client from '../../services';
import { toast } from 'react-toastify';
import {
  Save,
  MoreVertical,
  Share,
  Copy,
  Trash2,
  Settings,
} from 'react-feather';
import moment from 'moment';
import classNames from 'classnames';
import { copy } from '../../functions';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { deleteCoverEvent } from '../../store/reducers/fileReducer';
import { useUser } from '../../authenicationProvider';
import selectTemplate from '../../helpers/templateSelector';
import { useTranslation } from 'react-i18next';
import CalendarDefaultCover from '../../assest/images/dashboardCalendarFrame.png';

const useStyles = makeStyles({
  root: {
    padding: 10,
  },
  imagContainer: {
    position: 'relative',
  },
  calendarInfo: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '4',
    width: '100%',
    textAlign: 'center',
  },
  calendarInfoBold: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
  },
  media: {
    height: 180,
  },
  actionCard: {
    height: 65,
    position: 'relative',
    padding: '0 !important',
  },
  footerCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  footerStarter: {
    display: 'flex',
    width: '100%',
  },
});

export default function EventCard(props) {
  const { t } = useTranslation(['dashboard']);
  const navigate = useNavigate();
  const beskpoke = useSelector((state) => state.bespoke);
  const userDetails = useSelector((state) => state.user);
  const { user } = useUser();

  const dispatch = useDispatch();
  const {
    id,
    templateName,
    coverImg,
    privacy,
    createdAt,
    userId,
    share,
    templateId,
    yearPicked,
  } = props?.data;
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const updateSettingsClick = () => {
    return navigate('/dashboard/update-settings', {
      state: {
        id,
      },
    });
  };

  const closeMenu = () => {
    setIsOpen(false);
  };
  const closeMenuRef = useDetectClickOutside({ onTriggered: closeMenu });

  const shareLink =
    process.env.NODE_ENV == 'development'
      ? `${process.env.REACT_APP_API_URL}/bespoke/calendar/${id}`
      : `${process.env.REACT_APP_LIVE_API_URL}/bespoke/calendar/${id}`;

  const openMore = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOpen = () => {
    console.log('Opened');
    setOpen(true);
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  const deleteSouvenir = async (id) => {
    setLoading(true);
    await client
      .delete(`/Souvenir/deletesouvneir/${id}`, {
        status: true,
      })
      .then((res) => {
        toast.success(t('Souvenir deleted'));
        setLoading(false);
        setIsOpen(!isOpen);
        dispatch(deleteCoverEvent(id));

        // fetchNotice();
      })
      .catch((e) => {
        console.log(e?.response?.data?.Message ?? e?.message);
        setLoading(false);
        setIsOpen(!isOpen);
      });
  };

  // NOte the tempate name should be passed in
  // selectTemplate

  console.log(coverImg, ' this is cover image');
  return (
    <>
      <Card className={classes.root}>
        <div
          onClick={(e) => {
            console.log(templateId, 'Here is template ID');

            e.preventDefault();
            navigate(`/bespoke/calendar/${id}`);
            // navigate(selectTemplate(id, '1'));
          }}
          className={classes.imagContainer}
        >
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image={!coverImg?.src ? CalendarDefaultCover : coverImg?.src}
              title={templateName}
            />
          </CardActionArea>
          {!coverImg?.src && (
            <div className={classes.calendarInfo}>
              <p className={classes.calendarInfoBold}>{yearPicked}</p>
              <p className={classes.calendarInfoBold}>Calendar</p>
            </div>
          )}
        </div>
        <CardActions className={classes.actionCard} ref={closeMenuRef}>
          <div className={classes.footerCard}>
            <div className={classes.footerStarter}>
              <Save size={40} />
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '12rem',
                }}
              >
                <Typography
                  noWrap
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  {templateName}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  @
                  {props?.data?.user?.accountType === 'individual'
                    ? props.data.user.firstName
                    : props?.data?.user?.organisationName}{' '}
                  {privacy} {moment(createdAt).fromNow()}
                </Typography>
              </div>
            </div>
            <div onClick={() => openMore()}>
              <MoreVertical size={20} />
            </div>
            {isOpen && (
              <div className="absolute top-[-150px] grid grid-cols-1 z-10 overflow-hidden text-left right-[30px] bg-[#fff] rounded-lg shadow-md  ">
                <div className="flex items-center px-5 border-solid border-b border-[#43ABF9] ">
                  <Save size={20} />
                  <p className="  text-[14px] text-[#0A0A0A] font-semibold  px-1 py-2 cursor-pointer">
                    {templateName}
                  </p>
                </div>

                {(userId == userDetails?.id && user) || share ? (
                  <div
                    className="flex items-center hover:bg-gray-100 px-5"
                    onClick={() => {
                      handleClickOpen();
                    }}
                  >
                    <Share size={20} />
                    <p className="text-[14px] text-[#0A0A0A] text-left px-1  py-2 cursor-pointer">
                      {t('Share')}
                    </p>
                  </div>
                ) : null}

                <div
                  className="flex items-center  px-5 hover:bg-gray-100 "
                  onClick={() => {
                    copy(`${shareLink}`);
                    toast.success(t('Copied to clipboard'), {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      theme: 'colored',
                    });
                    setIsOpen(!isOpen);
                  }}
                >
                  <Copy size={20} />
                  <p className="text-[14px] text-[#0A0A0A]  px-1  py-2 cursor-pointer">
                    {t('Copy Link')}
                  </p>
                </div>
                {(userId == userDetails?.id && user) || share ? (
                  <div
                    className="flex items-center px-5 text-left hover:bg-gray-100 "
                    onClick={updateSettingsClick}
                  >
                    <Settings size={20} />
                    <p className="text-[14px] text-[#0A0A0A]  px-1  py-2 cursor-pointer">
                      {t('Setting')}
                    </p>
                  </div>
                ) : null}
                <div
                  className="flex items-center px-5 text-left hover:bg-gray-100 "
                  onClick={() => {
                    deleteSouvenir(id);
                  }}
                >
                  <Trash2 size={20} className="text-[#EA0F0F]" />
                  <p className="text-[14px] text-[#EA0F0F]  px-1  py-2 cursor-pointer">
                    {t('Delete Calendar')}
                  </p>
                </div>
              </div>
            )}
          </div>
        </CardActions>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ShareComponet data={props?.data} />
      </Dialog>
    </>
  );
}
