import styled from 'styled-components';
import img from '../../assest/images/nav_image.svg';

export const NavContainer = styled.nav`
  width: 100%;
  height: 210px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url(${img});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${({ curls }) => (!curls ? '  #2667b1' : '')};

  margin-bottom: 4rem;

  .main-content {
    width: 90%;
    margin-top: -70px !important;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4rem 0;
    padding-top: 2.5rem;

    .left-side-nav {
      display: flex;
      align-items: center;
      gap: 4rem;
      color: #fff;

      .logo-container-mobile {
        display: none;
      }

      .logo-container,
      .logo-container-mobile {
        cursor: pointer;
        width: 110px;
        height: 56px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .left-nav {
        display: flex;
        align-items: center;
        gap: 2.5rem;
        color: #fff;

        li {
          color: #fff;

          a {
            text-decoration: none;
            color: #fff;
            font-weight: 400;
            font-size: 0.9rem;
            line-height: 28px;
          }
        }
      }
    }
    .right-side-nav {
      display: flex;
      align-items: center;
      gap: 20px;
      color: #fff;
      position: relative;

      .translation {
        display: flex;
        align-items: center;
        gap: 0.3rem;
        cursor: pointer;

        svg {
          font-size: 1.5rem;
        }
      }
      .lang_dropdown {
        position: absolute;
        right: 0;
        top: 2.5rem;
        background: #ffffff;
        padding: 16px;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
          rgba(17, 17, 26, 0.1) 0px 8px 24px,
          rgba(17, 17, 26, 0.1) 0px 16px 48px;

        display: flex;
        flex-direction: column;
        gap: 1rem;

        z-index: 3;

        li {
          color: #0a0a0a;
          cursor: pointer;
        }
      }

      button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 15px;
        width: 128px;
        height: 44px;
        background-color: #43abf9;
        border-radius: 12px;
        color: #f6f6f6;
        font-size: 0.9rem;
      }

      /* .login-button {
        border: 1px solid #43abf9;
        border-radius: 12px;
        background-color: transparent;
      } */

      .signup-button {
        width: 100%;
      }
    }

    .ham-menu {
      display: none;
    }
  }
  .mobile_menu_section {
    display: none;
  }

  @media screen and (max-width: 1050px) {
    padding-top: 4rem;
    height: 130px;
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 4rem;
    background-color: #2667b1;

    .mobile_menu_section {
      display: ${({ showMobile }) => (showMobile ? 'block' : 'none')};
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 999;
      overflow-y: scroll;

      padding: 1rem 2rem;
      background: #fff;
      .mobile_menu__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo-container__mobile {
          width: 150px;
        }

        svg {
          font-size: 2rem;
        }
      }

      .translation_container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 0.6rem;

        .translation {
          display: flex;
          align-items: center;
          gap: 0.3rem;
          cursor: pointer;
          margin-top: 1rem;
          font-size: 1.5rem;

          svg {
            font-size: 1.5rem;
          }
        }
        .lang_dropdown {
          margin-top: 0rem;
          background: #ffffff;
          padding: 16px;
          box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
            rgba(17, 17, 26, 0.1) 0px 8px 24px,
            rgba(17, 17, 26, 0.1) 0px 16px 48px;

          display: flex;
          flex-direction: column;
          gap: 1rem;
          li {
            color: #0a0a0a;
            cursor: pointer;
          }
        }
      }

      ul {
        margin-top: 4rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        li {
          list-style-type: none;
          a {
            font-style: normal;
            font-weight: 400;
            font-size: 1.5rem;
            line-height: 24px;
            color: #2667b1;
          }
          p {
            font-style: normal;
            font-weight: 400;
            font-size: 1.5rem;
            line-height: 24px;
            color: #ea0f0f;
          }
        }
      }
    }

    .main-content {
      .left-side-nav {
        .logo-container {
          display: none;
        }

        .logo-container-mobile {
          display: block;
        }

        .left-nav {
          display: none;
        }
      }

      .right-side-nav {
        display: none;
      }

      .ham-menu {
        display: block;
      }
    }
  }

  @media screen and (max-width: 576px) {
    height: 150px;
    background-color: transparent;
  }
`;
