import styled from 'styled-components';

export const PaymentStepperContainer = styled.div`
  .MuiStepIcon-root.MuiStepIcon-completed {
    /* color: #1afe31;

    svg {
      background-color: red;
    }

    path {
      color: green;
    } */
  }

  .MuiStepIcon-root {
    /* color: #2667b1; */
  }
`;

export const CheckoutContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const CheckoutWrapper = styled.div`
  width: 90%;
  max-width: 1440px;
  margin: 5rem auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 5rem;

  .total_balance_section {
    padding: 2rem;
    background: #f6f6f6;
    display: flex;
    flex-direction: column;

    align-items: center;

    h4 {
      margin-bottom: 3rem;
      color: #0a0a0a;
      font-weight: 700;
      font-size: 24px;
      line-height: 2rem;
    }

    ul {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 2rem;
      align-items: flex-start;
      margin-bottom: 2rem;

      .label_prize {
        font-weight: 700;
        color: #0a0a0a;
      }
      .total {
        color: #43abf9;
      }
    }

    ul li {
      list-style-type: none;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      text-align: left;
      gap: 6rem;

      div {
        width: 70px;
        align-self: flex-start;
      }
    }
  }

  .form_section {
    form {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      width: 100%;

      .input_container {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;

        label {
          color: #161616;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.5rem;
        }

        .text_input {
          height: 47px;
          background: #ffffff;
          border: 1px solid #161616;
          border-radius: 8px;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.5rem;
          padding: 0.3rem;
        }

        .text_input:placeholder {
          color: #d3d3d3;
        }

        .text_input:focus {
          outline: none;
        }
      }

      .input_container_checkbox {
        display: flex;
        align-items: center;
        gap: 1rem;

        .check_input {
          width: 20px;
          height: 20px;
        }

        p {
          font-weight: 400;
          font-size: 1rem;
          line-height: 1rem;
          text-align: center;
          color: #111111;
        }
      }
    }

    .card_collect {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      width: 100%;

      .card_collect_details {
        display: flex;
        gap: 100px;

        .card_wrap_img_container {
          display: flex;
          align-items: center;
          gap: 20px;

          width: 55px;

          img {
            width: 100%;
          }
        }
      }
    }

    .pricing_section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;

      .basic {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1.3rem;

        gap: 5px;
        border: 2px solid #0a0a0a;
        border-radius: 8px;
        width: 200px;
        height: 102px;

        p {
          color: #0a0a0a;
          font-weight: 700;
          font-size: 0.8rem;
          line-height: 2rem;

          span {
            color: rgba(234, 15, 15, 0.8);
            font-size: 0.85rem;
          }
        }
      }

      .pro {
        background: #2667b1;
        border: 2px solid #ffffff;
        p {
          color: #fff;
        }
      }
    }

    .warning {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 0.8rem;
      line-height: 24px;
      color: #ea0f0f;
    }

    .form_btn {
      width: 300px;
      height: 52px;
      margin: 0 auto;
      left: 859px;
      top: 970px;
      color: #fff;
      background: #2667b1;
      border-radius: 12px;
    }
  }

  @media screen and (max-width: 586px) {
    position: relative;
    gap: 6rem;
    .total_balance_section {
      position: sticky;

      top: 0;
      ul {
        flex-direction: row;

        li {
          justify-content: flex-start;
          gap: 0;
        }
      }

      .total_mobile {
        width: 100%;

        justify-content: space-between;

        li {
          width: 100%;
          justify-content: space-between;
        }
      }
    }
  }

  @media screen and (max-width: 850px) {
    .form_section {
      form {
        .card_collect {
          flex-direction: column;

          .card_collect_details {
            display: flex;
            gap: 100px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 985px) {
    grid-gap: 3rem;
    .form_section {
      form {
        .card_collect {
          width: 100%;
          flex-wrap: wrap;
          align-items: flex-start;
          gap: 20px;
          .card_collect_details {
            display: flex;
            gap: 100px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1150px) {
    .form_section {
      form {
        .pricing_section {
          .basic {
            padding: 1rem;
            text-align: center;

            p {
              font-size: 0.7rem;
              line-height: 1.1rem;

              span {
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }
  }
`;
