import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  0: [
    {
      image: require('../../assest/month/work1.png'),
      tag: 'work',
    },
    {
      image: require('../../assest/month/inspire1.png'),
      tag: 'inspiration',
    },
    {
      image: require('../../assest/month/normal1.png'),
      tag: 'normal',
    },
  ],
  1: [
    {
      image: require('../../assest/month/work2.png'),
      tag: 'work',
    },
    {
      image: require('../../assest/month/inspire2.png'),
      tag: 'inspiration',
    },
    {
      image: require('../../assest/month/normal2.png'),
      tag: 'normal',
    },
  ],
  2: [
    {
      image: require('../../assest/month/work3.png'),
      tag: 'work',
    },
    {
      image: require('../../assest/month/inspire3.png'),
      tag: 'inspiration',
    },
    {
      image: require('../../assest/month/normal3.png'),
      tag: 'normal',
    },
  ],
  3: [
    {
      image: require('../../assest/month/work4.png'),
      tag: 'work',
    },
    {
      image: require('../../assest/month/inspire4.png'),
      tag: 'inspiration',
    },
    {
      image: require('../../assest/month/normal4.png'),
      tag: 'normal',
    },
  ],
  4: [
    {
      image: require('../../assest/month/work5.png'),
      tag: 'work',
    },
    {
      image: require('../../assest/month/inspire5.png'),
      tag: 'inspiration',
    },
    {
      image: require('../../assest/month/normal5.png'),
      tag: 'normal',
    },
  ],
  5: [
    {
      image: require('../../assest/month/work6.png'),
      tag: 'work',
    },
    {
      image: require('../../assest/month/inspire6.png'),
      tag: 'inspiration',
    },
    {
      image: require('../../assest/month/normal6.png'),
      tag: 'normal',
    },
  ],
  6: [
    {
      image: require('../../assest/month/work7.png'),
      tag: 'work',
    },
    {
      image: require('../../assest/month/inspire7.png'),
      tag: 'inspiration',
    },
    {
      image: require('../../assest/month/normal7.png'),
      tag: 'normal',
    },
  ],
  7: [
    {
      image: require('../../assest/month/work8.png'),
      tag: 'work',
    },
    {
      image: require('../../assest/month/inspire8.png'),
      tag: 'inspiration',
    },
    {
      image: require('../../assest/month/normal8.png'),
      tag: 'normal',
    },
  ],
  8: [
    {
      image: require('../../assest/month/work9.png'),
      tag: 'work',
    },
    {
      image: require('../../assest/month/inspire9.png'),
      tag: 'inspiration',
    },
    {
      image: require('../../assest/month/normal9.png'),
      tag: 'normal',
    },
  ],
  9: [
    {
      image: require('../../assest/month/work10.png'),
      tag: 'work',
    },
    {
      image: require('../../assest/month/inspire10.png'),
      tag: 'inspiration',
    },
    {
      image: require('../../assest/month/normal10.png'),
      tag: 'normal',
    },
  ],
  10: [
    {
      image: require('../../assest/month/work11.png'),
      tag: 'work',
    },
    {
      image: require('../../assest/month/inspire11.png'),
      tag: 'inspiration',
    },
    {
      image: require('../../assest/month/normal11.png'),
      tag: 'normal',
    },
  ],
  11: [
    {
      image: require('../../assest/month/work12.png'),
      tag: 'work',
    },
    {
      image: require('../../assest/month/inspire12.png'),
      tag: 'inspiration',
    },
    {
      image: require('../../assest/month/normal12.png'),
      tag: 'normal',
    },
  ],
};

export const defaultCover = createSlice({
  name: 'default',
  initialState,
  reducers: {
    loadDefault: (state) => {
      return state;
    },
  },
});

export const { loadDefault } = defaultCover.actions;

export default defaultCover.reducer;
