import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import client from '../services';
import bespokeView, {
  loadBespoke,
  setCoverView,
} from '../store/reducers/bespokeView';
import { reloadState } from '../store/reducers/CurrentDraft';

import { useUser } from '../authenicationProvider';
import facebook from '../assest/images/facebook.svg';
import Linkedin from '../assest/images/linkedin.svg';
import web from '../assest/images/web.svg';
// import twitter from '../assest/images/twitter.svg';
import Header from './component/bespoke_header/Header2';

// Import Templates
import BespokeViewTemplateZero from './template/templateZero/bespokeViewTemplateZero';
import BespokeViewTemplateOne from './template/templateOne/bespokeViewTemplateOne';
import { useTranslation } from 'react-i18next';
import BespokeTemplateTwo from './template/templateTwo/BespokeTemplateTwo';
import TemplateErrorPage from './template/TemplateErrorPage';
import TemplateLoading from './template/TemplateLoading';
import { loadBespokeFilter } from '../store/reducers/bespokeFilter';
import { loadCustomColorState } from '../store/reducers/customColorState';

import BespokeViewTemplateFour from './template/templateFour/BespokeViewTemplateFour';
import { setTVDraft } from '../store/reducers/draftTVEvents';

import BespokeViewTemplateThree from './template/templateThree/bespokeViewTemplateThree';
import { toast } from 'react-toastify';
import SucessPage from './checkout/SucessPage';
import CoverImage from './template/CoverImage';
import Twitter from '../assest/images/x-dark.svg';
import { userGeolocation, getCountyCode } from '../functions';

function BespokeView() {
  const [template, setTemplate] = useState('0');
  const { t } = useTranslation(['dashboard']);
  const dispatch = useDispatch();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const beskpoke = useSelector((state) => state.bespoke);
  const beskpokeDraft = useSelector((state) => state.draft);
  const [showSuccessPage, setShowSuccessPage] = useState(false);

  // Fetch bespoke Details
  const getBespoke = async (id) => {
    let country = new userGeolocation().getUserCountry();

    let { CountryCode, Code } = getCountyCode(country) || 'NGN';

    await client
      .get(`/Souvenir/${id}?country=${Code}`)
      .then((res) => {
        console.log(res?.data, ' This is data hear ');
        dispatch(loadBespoke(res?.data));
        dispatch(loadBespokeFilter(res?.data));

        if (res?.data.templateId !== '4') {
          // Template Id 4 has no custom color
          dispatch(
            loadCustomColorState({
              customColors: res?.data?.customColors,
            })
          );
        }
        //Get template from res
        //if template is empty
        // then fall back to template 0
        setTemplate(res?.data?.templateId ? res?.data?.templateId : '0');
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (params?.id) {
      dispatch(reloadState());
      getBespoke(params?.id);
    }
    window.scrollTo(0, 0);
  }, []);

  return !error ? (
    loading ? (
      <TemplateLoading />
    ) : (
      <>
        {
          <>
            <Header />
            <BespokeHeader>
              <div className="bespoke_title_container">
                <p className="bespoke_title_year">{beskpoke?.yearPicked}</p>
                <h2 className="bespoke_heading">{beskpoke?.templateName}</h2>
                <p className="bespoke_slogan">{beskpoke?.slogan}</p>
              </div>
            </BespokeHeader>

            <CoverImage beskpoke={beskpoke} />
          </>
        }
        {(beskpoke?.calendarPlan === 'Premium' ||
          beskpoke?.calendarPlan === 'Pro') && (
          <>
            {(beskpoke?.web ||
              beskpoke?.linkedin ||
              beskpoke?.facebook ||
              beskpoke?.twitter) && (
              <div className="flex  md:left-[25%] md:right-[25%] shadow-lg shadow-black bg-white rounded-t-lg bottom-0 items-center justify-center z-10 fixed  py-2">
                <p className=" pr-4">{t('Stay connected with us')}</p>
                {beskpoke?.web && (
                  <a
                    href={beskpoke?.web}
                    target="_blank"
                    rel="noopener"
                    aria-label="web"
                  >
                    <div className="grid justify-items-center px-2">
                      <img src={web} alt="insta icon" width={25} height={25} />
                      <small className="text-xs" style={{ fontSize: 12 }}>
                        {t('Web')}
                      </small>
                    </div>
                  </a>
                )}
                {beskpoke?.linkedin && (
                  <a
                    href={beskpoke?.linkedin}
                    target="_blank"
                    rel="noopener"
                    aria-label="Linkedin"
                  >
                    <div className="grid justify-items-center px-2">
                      <img
                        src={Linkedin}
                        alt="linkedin icon"
                        width={25}
                        height={25}
                      />
                      <small className="text-xs" style={{ fontSize: 12 }}>
                        {t('Linkedin')}
                      </small>
                    </div>
                  </a>
                )}
                {beskpoke?.facebook && (
                  <a
                    href={beskpoke?.facebook}
                    target="_blank"
                    rel="noopener"
                    aria-label="Facebook"
                  >
                    <div className="grid justify-items-center px-2">
                      <img
                        src={facebook}
                        alt="facebook icon"
                        width={25}
                        height={25}
                      />
                      <small className="text-xs" style={{ fontSize: 12 }}>
                        {t('Facebook')}
                      </small>
                    </div>
                  </a>
                )}
                {beskpoke?.twitter && (
                  <a
                    href={beskpoke?.twitter}
                    target="_blank"
                    rel="noopener"
                    aria-label="twitter"
                  >
                    <div className="grid justify-items-center px-2">
                      <img
                        src={Twitter}
                        alt="twitter icon"
                        width={25}
                        height={25}
                      />
                      <small className="text-xs" style={{ fontSize: 12 }}>
                        {t('Twitter')}
                      </small>
                    </div>
                  </a>
                )}
              </div>
            )}
          </>
        )}
        {
          {
            0: <BespokeViewTemplateZero />,
            1: <BespokeViewTemplateOne />,
            2: <BespokeTemplateTwo />,

            4: <BespokeViewTemplateFour />,

            3: <BespokeViewTemplateThree />,
          }[template]
        }
      </>
    )
  ) : (
    <TemplateErrorPage />
  );
}

export default BespokeView;

const BespokeHeader = styled.div`
  .bespoke_title_container {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    margin-bottom: 3rem;

    .bespoke_heading {
      font-family: 'Sofia', cursive;
      font-weight: 400;
      font-size: 80px;
      color: rgba(197, 113, 14, 1);
      text-align: center;
      width: 100%;
      max-width: 1024px;
      margin: 0 auto;
      height: 100%;
      max-height: 200px;
      overflow-y: scroll;
      line-height: 126.08px;

      overflow-wrap: break-word;

      -ms-overflow-style: none;
      scrollbar-width: none;
      overflow: -moz-scrollbars-none;

      &::-webkit-scrollbar {
        width: 0 !important;
        display: none;
      }
    }

    .bespoke_title_year {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 800;
      font-size: 48px;
      text-align: center;
      line-height: 65.47px;
    }
    .bespoke_slogan {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 38.19px;
      text-align: center;
    }
    .bespoke_subtitle_year {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      text-align: center;
    }
  }
`;
