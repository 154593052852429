import styled from 'styled-components';

export const ProfileDropContainer = styled.div`
  position: absolute;
  top: 60px;

  left: ${({ pos }) => (pos ? `${pos}px` : '-0px')};
  height: 100%;
  padding: 2rem 1.5rem 1.5rem 1.3rem;
  height: 350px;
  z-index: 9;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 27px;
  color: #0a0a0a;
  background: #fff;
  width: 220px;
  box-shadow: -3px 5px 20px -3px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: -3px 5px 20px -3px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: -3px 5px 20px -3px rgba(0, 0, 0, 0.7);

  .profile_drop_wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    li {
      list-style: none;
      display: flex;
      gap: 1rem;
      align-items: center;
      cursor: pointer;

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
`;
