/* eslint eqeqeq: 0 */
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import weekdayPlugin from 'dayjs/plugin/weekday';
import objectPlugin from 'dayjs/plugin/toObject';
import isTodayPlugin from 'dayjs/plugin/isToday';
import { ChevronRight, ChevronLeft } from 'react-feather';
import LazyLoad from 'react-lazy-load';
import './styles/month.css';
// eslint-disable-next-line
import { saveCalendarEvent } from '../../functions';

const Month = (props) => {
  const { bespoke } = props;
  const [selected, setSelected] = useState(0);
  const [data, setData] = useState(bespoke[0])
  const [currentEvent, setCurrentEvent] = useState({
    events: [],
    index: 0,
    disabledNext: false,
    disabledPrev: false,
  });
  const now = dayjs(data?.date).locale('es');

  dayjs.extend(weekdayPlugin);
  dayjs.extend(objectPlugin);
  dayjs.extend(isTodayPlugin);
  // eslint-disable-next-line
  const [currentMonth, setCurrentMonth] = useState(now);
  const [arrayOfDays, setArrayOfDays] = useState([]);

  // Back to calendar func
  const back = () => {
    setSelected(0);
  };
  // Previous Button
  const prevBtn = () => {
    let index = currentEvent.index - 1;
    let disabledPrev = currentEvent.index === 0;
    setCurrentEvent({
      ...currentEvent,
      index: disabledPrev ? 0 : index,
      disabledPrev: disabledPrev,
      disabledNext: false,
    });
  };
  // Next Button
  const nextBtn = () => {
    let index = currentEvent.index + 1;
    let disabledNext = currentEvent.index === currentEvent?.events.length - 1;
    setCurrentEvent({
      ...currentEvent,
      index: disabledNext ? currentEvent.index : index,
      disabledNext: disabledNext,
      disabledPrev: false,
    });
  };
  // Navigation buttons
  const nextMonthNav = () => {
    if (dayjs(currentMonth).get('month') != 11) {
      const plus = currentMonth.add(1, "month");
      setData(bespoke[plus.get('month')])
      setSelected(0);
      setCurrentMonth(plus);
    }
  };
  const prevMonthNav = () => {
    if (dayjs(currentMonth).get('month') != 0) {
      const minus = currentMonth.subtract(1, "month");
      setData(bespoke[minus.get('month')])
      setSelected(0);
      setCurrentMonth(minus);
    }
  };
  // Event Card
  const EventCard = () => {
    return (
      <div className="event-card">
        <div
          className={`nav-con ${currentEvent?.events.length < 2 ? 'nav-hidden' : ''
            }`}
        >
          <div
            className={`navigator ${currentEvent?.events.length < 2 ? 'hidden' : ''
              }`}
          >
            <ChevronLeft
              color="#000000"
              size={25}
              onClick={() => (currentEvent.disabledPrev ? null : prevBtn())}
              style={{ cursor: 'pointer' }}
            />
            <div className="navigator-title">Events</div>
            <ChevronRight
              color="#000000"
              size={25}
              onClick={() => (currentEvent.disabledNext ? null : nextBtn())}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </div>
        <div className="calender-event">
          <div className="event-content">
            <h6 className="date">
              {dayjs(
                currentEvent?.events[currentEvent.index]?.startDate
              ).format('DD MMMM, YYYY')}
            </h6>
            <div className="event-details">
              <div
                className="event-dot"
              // style={{background: currentEvent?.events[0]?.color}}
              ></div>
              <div className="event-schedule-con">
                <div className="event-schedule">
                  <p>
                    {dayjs(
                      currentEvent?.events[currentEvent.index]?.startTime
                    ).format('h:mma')}{' '}
                    -{' '}
                    {dayjs(
                      currentEvent?.events[currentEvent.index]?.endTime
                    ).format('h:mma')}
                  </p>
                  <h6>{currentEvent?.events[currentEvent.index]?.title}</h6>
                </div>
                <div className='note'>
                  <p>
                    {currentEvent?.events[currentEvent.index]?.description}
                  </p>
                </div>
              </div>
            </div>
            <div className="btn-con">
              {
                currentEvent?.events[currentEvent.index]?.link ?
                  <a href={currentEvent?.events[currentEvent.index]?.link} target="_blank">
                    <div
                      className="join-btn"
                    >
                      Join
                    </div>
                  </a>
                  :
                  null
              }

              <div
                className="add-btn"
                onClick={() => {
                  saveCalendarEvent(currentEvent?.events[currentEvent.index]);
                  // console.log(currentEvent?.events[currentEvent.index]);
                }}
              >
                Add to calendar
              </div>
            </div>
          </div>
          <div className="event-img-con">
            <div className="event-img">
              <LazyLoad height={'100%'} width={'100%'} offset={200}>
                <img
                  src={currentEvent?.events[currentEvent.index]?.image ? currentEvent?.events[currentEvent.index]?.image : require('../../assest/images/Rectangle19.png')}
                  className="event-img"
                  alt="event"
                />
              </LazyLoad>
            </div>
            <div className="calendar-btn" onClick={() => back()}>
              Back to calendar
            </div>
          </div>
        </div>
      </div>
    );
  };
  // Calendar header
  const renderHeader = () => {
    const dateFormat = 'MMMM YYYY';

    return (
      <div className="header row flex-middle" style={{ justifyContent: 'center' }}>
        <div className=" col-start">
          <div className="icon" onClick={() => prevMonthNav()}>
            chevron_left
          </div>
        </div>
        <div className=" col-center">
          <span>{currentMonth.format(dateFormat)}</span>
        </div>
        <div className="col-end" onClick={() => nextMonthNav()}>
          <div className="icon">chevron_right</div>
        </div>
      </div>
    );
  };
  // Render day
  const renderDays = () => {
    const dateFormat = 'ddd';
    const days = [];

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {now.weekday(i).format(dateFormat)}
        </div>
      );
    }
    return <div className="days row">{days}</div>;
  };
  // All days of the week

  const getAllDays = () => {
    let currentDate = currentMonth.startOf('month').weekday(0);
    const nextMonth = currentMonth.add(1, 'month').month();

    let allDates = [];
    let weekDates = [];

    let weekCounter = 1;

    while (currentDate.weekday(0).toObject().months !== nextMonth) {
      const formated = formateDateObject(currentDate);
      weekDates.push(formated);
      if (weekCounter === 7) {
        allDates.push({ dates: weekDates });
        weekDates = [];
        weekCounter = 0;
      }

      weekCounter++;
      currentDate = currentDate.add(1, 'day');
    }

    setArrayOfDays(allDates);
  };
  useEffect(() => {
    getAllDays();
    // eslint-disable-next-line
  }, [currentMonth]);
  // Each cells of the calendar
  const renderCells = () => {
    const rows = [];
    let days = [];

    arrayOfDays.forEach((week, index) => {
      week.dates.forEach((d, i) => {
        days.push(
          <div
            className={`col cell ${!d.isCurrentMonth ? 'disabled' : ''}`}
            key={i}
          >
            <div
              className={`inner-cell ${d.isCurrentMonth
                ? selected == d?.day
                  ? 'select-date'
                  : ''
                : ''
                }`}
              onClick={() => {
                if (d?.day == selected) {
                  setSelected(0);
                } else {
                  setSelected(d?.day);
                  setCurrentEvent({
                    events: d?.events,
                    index: 0,
                    disabledNext: false,
                    disabledPrev: false,
                  });
                }
              }}
            >
              <span className="number">{d.day}</span>
              <div className="dots">
                {d.isCurrentMonth
                  ? d?.events?.map((item, i) => (
                    <div
                      className="dot"
                      style={{ background: item.color }}
                      key={i}
                    ></div>
                  ))
                  : null}
              </div>
            </div>
          </div>
        );
      });
      rows.push(
        <div className="row" key={index}>
          {days}
        </div>
      );
      days = [];
    });

    return <div className="body">{rows}</div>;
  };
  // Format each day object

  const formateDateObject = (date) => {
    const clonedObject = { ...date.toObject() };
    // Event for teh day
    const events = data.events.filter(
      (e) =>
        dayjs(date).format('DD-MM-YY') === dayjs(e.startDate).format('DD-MM-YY')
    );
    const formatedObject = {
      day: clonedObject.date,
      month: clonedObject.months,
      year: clonedObject.years,
      isCurrentMonth: clonedObject.months === currentMonth.month(),
      date: date,
      events: events,
    };

    return formatedObject;
  };
  // calendar style Selected
  const calendarCon = {
    justifyContent: 'center',
  };
  // calendar style Selected
  const calendarStyle = {
    width: '80%',
  };

  /* ====== MAIN SECTION RETURN  ======= */
  return (
    <div>
      {/* <div className="month-cover">
        <LazyLoad height={'100%'} width={'100%'} offset={200}>
          <img src={data.background ? data.background : defaultCover.image} className="month-cover" alt="cover" />
        </LazyLoad>
      </div> */}

      <div
        className="calendar-content"
        style={
          selected == 0 || currentEvent?.events.length == 0 ? calendarCon : null
        }
      >
        {selected == 0 || currentEvent?.events.length == 0 ? null : (
          <EventCard />
        )}
        <div
          className={`calendar ${selected == 0 || currentEvent?.events.length == 0
            ? ''
            : 'mobile-calendar'
            }`}
          style={
            selected == 0 || currentEvent?.events.length == 0
              ? calendarStyle
              : null
          }
        >
          {renderHeader()}
          {renderDays()}
          {renderCells()}
        </div>
      </div>
    </div>
  );
};

export default Month;
