export const MainFAQList = [
  {
    id: 1,
    title: `How do I sign up?`,
    contentList: [
      {
        id: 1,
        title: 'With your email address',
        li: [
          `Fill out the "Email" field with your email address.`,
          `Fill out your "Last Name" and "First Name" if it is an individual account, if it is an organisation account provide organisation name.`,
          `Enter your password in the "Password" field, then enter it again in the password confirmation field.`,
          `Accept Privacy Policy and Terms of Use.`,
          `Your studio calendar is ready to use.`,
        ],
      },

      {
        id: 2,
        title: 'Facebook, Google and LinkedIn accounts',
        li: [
          `You can log in to Lectie using your Facebook, Google or LinkedIn accounts by clicking the respective icons and following the prompts to complete log in.`,
          `Complete brief profile registration.`,
          `Accept Privacy Policy and Terms of Use.`,
          `Start using the app.`,
        ],
      },
    ],
  },

  {
    id: 2,
    title: `How do I link my other calendar to Studio?`,
    contentList: [
      {
        id: 1,
        title: '',
        li: [
          `You can’t link other calendars to studio, but you can link studio to other calendars.`,
        ],
      },
    ],
  },

  {
    id: 3,
    title: `How do I create a calendar?`,
    contentList: [
      {
        id: 1,
        title: '',
        li: [
          `You must be logged in or signed in before you can create a calendar on studio.`,
          `Once logged in, select "Create" from the navigation menu on the left to start creating a calendar.`,
        ],
      },
    ],
  },

  {
    id: 4,
    title: `How do I share a calendar?`,
    contentList: [
      {
        id: 1,
        title: '',
        li: [
          `Before you can shared a calendar, the calendar needs to be published. If published you can share a calendar by selecting the share button on the top right corner of the calendar or through the pop up success message.`,
        ],
      },
    ],
  },

  {
    id: 5,
    title: `Can I link my Bespoke calendar to other calendars?`,
    contentList: [
      {
        id: 1,
        title: '',
        li: [
          `Yes you can link your published or shared calendar to other calendars.`,
        ],
      },
    ],
  },

  {
    id: 6,
    title: `Does Bespoke have a default calendar?`,
    contentList: [
      {
        id: 1,
        title: '',
        li: [`There is no default calendar in Bespoke calendar.`],
      },
    ],
  },

  {
    id: 7,
    title: `Can I customize a calendar?`,
    contentList: [
      {
        id: 1,
        title: '',
        li: [
          `Yes you can, If you are the owner of the calendar, you can customize it.`,
        ],
      },
    ],
  },

  {
    id: 8,
    title: `How do I customize my calendar?`,
    contentList: [
      {
        id: 1,
        title: '',
        li: [
          `A calendar can be customized if you are the owner or creator by clicking on published calendar in vault or draft and make the necessary changes.`,
        ],
      },
    ],
  },

  {
    id: 9,
    title: `How do I make use of the Bespoke calendar?`,
    contentList: [
      {
        id: 1,
        title: '',
        li: [
          `To the end user, it acts as a notification. When a user updates their shared calendar, it notifies them and when that happens, you may utilize the calendar by using the app (which is the ideal approach) or you can add it to your local calendar (Google calendar, Apple Calendar)`,
        ],
      },
    ],
  },

  {
    id: 10,
    title: `How do I invite friends to the Bespoke calendar?`,
    contentList: [
      {
        id: 1,
        title: '',
        li: [
          `When the calendars are published, only then can you invite people by sharing the link to them.`,
        ],
      },
    ],
  },

  {
    id: 11,
    title: `What calendars will I find under ‘Vault’?`,
    contentList: [
      {
        id: 1,
        title: '',
        li: [`Only calendars published by you and calendars shared to you`],
      },
    ],
  },

  {
    id: 12,
    title: `What calendars will I find under ‘draft’?`,
    contentList: [
      {
        id: 1,
        title: '',
        li: [`Only unpublished calendars created by you.`],
      },
    ],
  },

  {
    id: 13,
    title: `How do I edit published calendars on my dashboard?`,
    contentList: [
      {
        id: 1,
        title: '',
        li: [
          `You can only edit calendars you create and not shared calendars Click on vaults to see published calendars. Clicking on a published bespoke calendar displays a view of the calendar with an edit icon in the top right corner. Once clicked, the user can update the calendar.`,
        ],
      },
    ],
  },

  {
    id: 14,
    title: `How do I edit unpublished calendars on my dashboard?`,
    contentList: [
      {
        id: 1,
        title: '',
        li: [
          `Click on drafts to access unpublished calendars. Clicking on a published bespoke calendar displays a view of the calendar with an edit icon in the top right corner. Once clicked, the user can update the calendar.`,
        ],
      },
    ],
  },

  {
    id: 15,
    title: `How do I navigate to calendars from the dashboard?`,
    contentList: [
      {
        id: 1,
        title: '',
        li: [
          `The calendar will be in "vault" if it has been shared or published, and it will be in "draft" if none of those things have happened.`,
        ],
      },
    ],
  },

  {
    id: 16,
    title: `How do I filter my calendar?`,
    contentList: [
      {
        id: 1,
        title: '',
        li: [
          `The filter can be found in the vault. You can filter based on months in a particular year`,
        ],
      },
    ],
  },

  {
    id: 17,
    title: `What are the subscription plans in the Bespoke calendar?`,
    contentList: [
      {
        id: 1,
        title: '',
        li: [
          `The subscription plans on the bespoke calendar are Pro and Premium.`,
        ],
      },
    ],
  },

  {
    id: 18,
    title: `Where can I find my notifications on studio?`,
    contentList: [
      {
        id: 1,
        title: '',
        li: [
          `When you sign up, the notification icon will appear to the right of your profile picture.`,
        ],
      },
    ],
  },

  {
    id: 19,
    title: `In what scenario will I receive notifications from Bespoke calendar?`,
    contentList: [
      {
        id: 1,
        title: 'Upon account creation, a notification is sent',
        li: [
          `Update/change of password`,
          `When a bespoke calendar is published`,
          `When a bespoke calendar is edited/updated`,
          `When a calendar is subscribed to`,
          `When invited to a private calendar`,
          `When a user follows you`,
          `When you are invited to a private event`,
          `When a user accepts your connection request`,
          `When a user sends a connection request`,
        ],
      },
    ],
  },

  {
    id: 20,
    title: `How do I upload images for a calendar?`,
    contentList: [
      {
        id: 1,
        title:
          'You can only upload images if you are the one who published the calendar or the owner of the account',
        li: [
          `On the dashboard, on the navigation bar at the left, click on ‘create’`,
          `Click the ‘+’ icon to upload images from your storage or drag and drop the image.`,
        ],
      },
    ],
  },

  {
    id: 21,
    title: `How do I change the cover image for a month?`,
    contentList: [
      {
        id: 1,
        title: '',
        li: [
          `We provide a default image in the absence of a cover image.`,
          `When setting the event on the calendar, at the left is a button ‘change cover image’`,
          `Click on the button and upload desired cover image`,
        ],
      },
    ],
  },

  {
    id: 22,
    title: `How do I reset my password?`,
    contentList: [
      {
        id: 1,
        title: '',
        li: [
          `Click on "reset password" on the dashboard's navigation bar on the left.`,
          `In the appropriate fields, enter your current password, your old password, and the confirm password.`,
        ],
      },
    ],
  },
];
