export const privacyGDPR =
  'This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.';

export const privacyGDPR2 =
  'We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.';

export const interpret =
  'The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.';

export const defination = [
  {
    id: 1,
    bold: 'Account',
    text: 'means a unique account created for You to access our Service or parts of our Service.',
  },

  {
    id: 2,
    bold: 'Affiliate',
    text: 'means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.',
  },

  {
    id: 3,
    bold: 'Application',
    text: 'means the software program provided by the Company downloaded by You on any electronic device, named Lectie',
  },

  {
    id: 4,
    bold: 'Business,',
    text: "for the purpose of the CCPA (California Consumer Privacy Act), refers to the Company as the legal entity that collects Consumer's personal information and determines the purposes and means of the processing of Consumer's personal information, or on behalf of which such information is collected and that alone, or jointly with others, determines the purposes and means of the processing of consumer's personal information, that does business in the State of California.",
  },

  {
    id: 5,
    bold: 'Company',
    text: '(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Lectie Pty Ltd, Level 1/390 St Kilda Rd, Melbourne, VIC 3004. For the purpose of the GDPR, the Company is the Data Controller.',
  },

  {
    id: 6,
    bold: 'Consumer,',
    text: 'for the purpose of the CCPA (California Consumer Privacy Act), means a natural person who is a California resident. A resident, as defined in the law, includes (1) every individual who is in the USA for other than a temporary or transitory purpose, and (2) every individual who is domiciled in the USA who is outside the USA for a temporary or transitory purpose.',
  },
  {
    id: 7,
    bold: 'Country',
    text: 'refers to: Victoria, Australia',
  },

  {
    id: 8,
    bold: 'Data Controller,',
    text: 'for the purposes of the GDPR (General Data Protection Regulation), refers to the Company as the legal person which alone or jointly with others determines the purposes and means of the processing of Personal Data.',
  },
  {
    id: 9,
    bold: 'Device',
    text: 'means any device that can access the Service such as a computer, a cellphone or a digital tablet.',
  },

  {
    id: 10,
    bold: 'Do Not Track',
    text: '(DNT) is a concept that has been promoted by US regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing internet users to control the tracking of their online activities across websites.',
  },
  {
    id: 11,
    bold: 'Facebook Fan Page',
    text: 'is a public profile named Lectie specifically created by the Company on the Facebook social network, accessible from Https://www.facebook.com/lectie',
  },
  {
    id: 12,
    bold: 'Personal Data',
    text: 'is any information that relates to an identified or identifiable individual.',
  },
];

export const personalP1 =
  'For the purposes of GDPR, Personal Data means any information relating to You such as a name, an identification number, location data, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity.';

export const personalP2 =
  'For the purposes of the CCPA, Personal Data means any information that identifies, relates to, describes or is capable of being associated with, or could reasonably be linked, directly or indirectly, with You.';

export const definationCont = [
  {
    id: 13,
    bold: 'Sale,',
    text: "for the purpose of the CCPA (California Consumer Privacy Act), means selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a Consumer's personal information to another business or a third party for monetary or other valuable consideration.",
  },

  {
    id: 14,
    bold: 'Service',
    text: 'refers to the Application.',
  },

  {
    id: 15,
    bold: 'Service Provider',
    text: 'means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used. For',
  },
  {
    id: 16,
    bold: 'Third-party Social Media Service',
    text: 'refers to any website or any social network website through which a User can log in or create an account to use the Service.',
  },
  {
    id: 17,
    bold: 'Usage Data',
    text: 'refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).',
  },
  {
    id: 18,
    bold: 'You',
    text: 'means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.',
  },
];

export const definateP3 =
  'Under GDPR (General Data Protection Regulation), You can be referred to as the Data Subject or as the User as you are the individual using the Service.';

export const personalData =
  'While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:';

export const personalDataList = [
  {
    id: 1,
    text: 'Email address',
  },

  {
    id: 2,
    text: 'First name and last name',
  },

  {
    id: 3,
    text: 'Phone number',
  },

  {
    id: 4,
    text: 'Address, State, Province, ZIP/Postal code, City',
  },
  {
    id: 5,
    text: 'Usage Data',
  },
];

export const UsageP1 =
  "Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.";

export const UsageP2 =
  'When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.';

export const UsageP3 =
  'We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.';

export const thirdPartyP1 =
  'The Company allows You to create an account and log in to use the Service through the following Third-party Social Media Services:';

export const thirdPartyP2 = `If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as Your name, Your email address, Your activities or Your contact list associated with that account.`;

export const thirdPartyP3 = `You may also have the option of sharing additional information with the Company through Your Third-Party Social Media Service's account. If You choose to provide such information and Personal Data, during registration or otherwise, You are giving the Company permission to use, share, and store it in a manner consistent with this Privacy Policy.`;
export const thirdPartyList = [
  {
    id: 1,
    text: 'Google',
  },
  {
    id: 2,
    text: 'Facebook',
  },
  {
    id: 3,
    text: 'Twitter',
  },
];

export const appInfo = `While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:`;

export const appInfoList = [
  {
    id: 1,
    text: 'Information regarding your location',
  },
  {
    id: 2,
    text: "Information from your Device's phone book (contacts list)",
  },
  {
    id: 3,
    text: "Pictures and other information from your Device's camera and photo library",
  },
];

export const appInfoP1 = `We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.`;

export const appInfoP2 = `You can enable or disable access to this information at any time, through Your Device settings.`;

export const CompanyData = [
  {
    id: 1,
    bold: 'To provide and maintain our Service,',
    text: 'including to monitor the usage of our Service.',
  },
  {
    id: 2,
    bold: 'To manage Your Account:',
    text: `to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.`,
  },
  {
    id: 3,
    bold: 'For the performance of a contract:',
    text: `the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.`,
  },
  {
    id: 4,
    bold: 'To contact You:',
    text: `To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.`,
  },
  {
    id: 5,
    bold: 'To provide You',
    text: `with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.`,
  },
  {
    id: 6,
    bold: 'To manage Your requests:',
    text: 'To attend and manage Your requests to Us.',
  },
  {
    id: 7,
    bold: 'For business transfers:',
    text: `We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.`,
  },
  {
    id: 8,
    bold: 'For other purposes:',
    text: `We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.`,
  },
];

export const personalCompanyInfo = [
  {
    id: 1,
    bold: 'With Service Providers:',
    text: `We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to show advertisements to You to help support and maintain Our Service, for payment processing, to contact You.`,
  },
  {
    id: 2,
    bold: 'For business transfers:',
    text: `We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.`,
  },
  {
    id: 3,
    bold: 'With Affiliates:',
    text: `We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.`,
  },
  {
    id: 4,
    bold: 'With business partners:',
    text: `We may share Your information with Our business partners to offer You certain products, services or promotions.`,
  },
  {
    id: 5,
    bold: 'With other users:',
    text: `when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.`,
  },
  {
    id: 6,
    bold: 'With Your consent:',
    text: `We may disclose Your personal information for any other purpose with Your consent.`,
  },
];

export const retentionData = `The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.`;

export const retentionData2 = `The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.`;

export const transferData = `Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.`;

export const transferData0 = `Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.`;

export const transferData1 = `The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.`;

export const businessTransfer = `If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.`;

export const lawEnforce = `Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).`;

export const legalRequirement = `The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:`;

export const legalRequirementList = [
  {
    id: 1,
    text: 'Comply with a legal obligation',
  },
  {
    id: 2,
    text: 'Protect and defend the rights or property of the Company',
  },
  {
    id: 3,
    text: 'Prevent or investigate possible wrongdoing in connection with the Service',
  },
  {
    id: 4,
    text: 'Protect the personal safety of Users of the Service or the public',
  },
  {
    id: 5,
    text: 'Protect against legal liability',
  },
];

export const securityData = `The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.`;

export const infoProcess = `The Service Providers We use may have access to Your Personal Data. These third-party vendors collect, store, use, process and transfer information about Your activity on Our Service in accordance with their Privacy Policies.`;

export const analyticsGoogle = [
  {
    id: 1,
    bold: 'Google Analytics',
    text: ``,
  },
];

export const analyticsGoogleLink = [
  {
    id: 1,
    text_before_link: `Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.You may opt-out of certain Google Analytics features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in`,
    link: ' https://policies.google.com/privacy',
    content: 'their Privacy Policy',
  },
  {
    id: 2,
    text_before_link:
      'For more information on the privacy practices of Google, please visit',
    content: 'the Google Privacy  & Terms web page',
    link: 'https://policies.google.com/privacy',
  },
];

export const analytiFirebaseLink = [
  {
    id: 1,
    text_before_link: `Firebase is an analytics service provided by Google Inc. You may opt-out of certain Firebase features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their`,
    content: `Privacy Policy:`,
    link: 'https://policies.google.com/privacy',
  },
  {
    id: 2,
    text_before_link: `We also encourage you to review the`,
    content: `Google's policy for safeguarding your data:`,
    link: 'https://support.google.com/analytics/answer/6004245',
  },
  {
    id: 3,
    text_before_link: `For more information on what type of information Firebase collects, please visit the`,
    content: `How Google uses data when you use our partner's sites or apps webpage:`,
    link: 'https://policies.google.com/technologies/partner-sites',
  },
];

export const analyticsMixpanelLink = [
  {
    id: 1,
    text_before_link: `Mixpanel is provided by Mixpanel Inc. You can prevent Mixpanel from using your information for analytics purposes by opting-out.`,
    link: ' https://mixpanel.com/optout/',
    content: 'To opt-out of Mixpanel service,',
  },
  {
    id: 2,
    text_before_link:
      'For more information on what type of information Mixpanel collects, please visit the',
    content: 'Terms of Use page of Mixpanel:',
    link: 'https://mixpanel.com/terms/',
  },
];

export const adSenseLink = [
  {
    id: 1,
    text_before_link: `Google, as a third party vendor, uses cookies to serve ads on our Service. Google's use of the DoubleClick cookie enables it and its partners to serve ads to our users based on their visit to our Service or other websites on the Internet. You may opt out of the use of the DoubleClick Cookie for interest-based advertising by visiting the`,
    link: 'http://www.google.com/ads/preferences/',
    content: 'by visiting the Google Ads Settings web page',
  },
];

export const adMobLink = [
  {
    id: 1,
    text_before_link: `AdMob by Google is provided by Google Inc. You can opt-out from the AdMob by Google service by following the`,
    link: 'https://support.google.com/ads/answer/2662922?hl=en',
    content: 'instructions',
  },

  {
    id: 2,
    text_before_link: `described by Google: For more information on how Google uses the collected information, please visit the`,
    link: 'https://policies.google.com/technologies/partner-sites',
    content: `How Google uses data when you use our partner's sites or app page:`,
  },

  {
    id: 3,
    text_before_link: `or Vist the`,
    link: 'https://policies.google.com/privacy',
    content: `the Privacy Policy of Google:`,
  },
];

export const emailMaketing = `We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and other information that may be of interest to You. You may opt-out of receiving any, or all, of these communications from Us by following the unsubscribe link or instructions provided in any email We send or by contacting Us.`;

export const sendGridLink = [
  {
    id: 1,
    text_before_link: ``,
    link: 'https://www.twilio.com/legal/privacy',
    content: 'Their Privacy Policy can be viewed',
  },
];

export const hubSpotLink = [
  {
    id: 1,
    text_before_link: ``,
    link: `https://legal.hubspot.com/privacy-
    policy?utm_id=480781422897&amp;utm_medium=paid&amp;utm_source=google&amp;utm_term
    =marketing_%252Bhubspot_EN&amp;utm_campaign=Marketing_MQLs_EN_APAC_ANZ_B
    rand-
    HubSpot_b_c_campaignid11654962040_agid116369339674_google&amp;utm_content=
    &amp;hsa_ver=3&amp;hsa_net=adwords&amp;hsa_acc=2734776884&amp;hsa_kw=%252Bhubspot&amp;hs
    a_grp=116369339674&amp;hsa_mt=b&amp;hsa_cam=11654962040&amp;hsa_ad=48078142289
    7&amp;hsa_tgt=kwd-
    23761999250&amp;hsa_src=g&amp;gclid=CjwKCAjwlcaRBhBYEiwAK341jeJlbVFtEt2FYDFb4
    p_vtGrfWkOkwUPkpe5TAsXPzh5h5yhn83CWCRoC8kgQAvD_BwE&amp;_ga=2.11606324
    .1673588778.1647498551-
    893373947.1647498551&amp;_gac=1.222054250.1647498551.CjwKCAjwlcaRBhBYEiw
    AK341jeJlbVFtEt2FYDFb4p_vtGrfWkOkwUPkpe5TAsXPzh5h5yhn83CWCRoC8kgQA
    vD_BwE`,
    content: 'Their Privacy Policy can be viewed',
  },
];

export const payments = `We may provide paid products and/or services within the Service. In that case, we may use third-party services for payment processing (e.g. payment processors).`;

export const payments2 = `We will not store or collect Your payment card details. That information is provided directly to Our third-party payment processors whose use of Your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.`;

export const appleStoreLink = [
  {
    id: 1,
    text_before_link: ``,
    link: 'https://www.apple.com/legal/privacy/en-ww/',
    content: 'Their Privacy Policy can be viewed',
  },
];

export const googlePlayLink = [
  {
    id: 1,
    text_before_link: ``,
    link: 'https://www.google.com/policies/privacy/',
    content: 'Their Privacy Policy can be viewed',
  },
];

export const performance = [
  {
    id: 1,
    text_before_link: `The information gathered by Google Places is held in accordance with the`,
    link: 'https://www.google.com/intl/en/policies/privacy/',
    content: 'Privacy Policy of Google',
  },
];

export const GDPRLink = [
  {
    id: 1,
    bold: 'Consent: ',
    text: `You have given Your consent for processing Personal Data for one or more specific purposes.`,
  },
  {
    id: 2,
    bold: 'Performance of a contract: ',
    text: `Provision of Personal Data is necessary for the performance of an agreement with You and/or for any pre-contractual obligations thereof.`,
  },

  {
    id: 3,
    bold: 'Legal obligations: ',
    text: `Processing Personal Data is necessary for compliance with a legal obligation to which the Company is subject.`,
  },
  {
    id: 4,
    bold: 'Vital interests:',
    text: `Processing Personal Data is necessary in order to protect Your vital interests or of another natural person.`,
  },
  {
    id: 5,
    bold: 'Public interests:',
    text: `Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Company.`,
  },
  {
    id: 6,
    bold: 'Legitimate interests:',
    text: `Processing Personal Data is necessary for the purposes of the legitimate interests pursued by the Company.`,
  },
];

export const GDPRP1 = `In any case, the Company will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.`;

export const GDPRRight = `The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise Your rights.`;

export const rightPolicyList = [
  {
    id: 1,
    bold: 'Request access to Your Personal Data.',
    text: `The right to access, update or delete the information We have on You. Whenever made possible, you can access, update or request deletion of Your Personal Data directly within Your account settings section. If you are unable to perform these actions yourself, please contact Us to assist You. This also enables You to receive a copy of the Personal Data We hold about You.`,
  },
  {
    id: 2,
    bold: `Request correction of the Personal Data that We hold about You.`,
    text: `You have the right to have any incomplete or inaccurate information We hold about You corrected.`,
  },
  {
    id: 3,
    bold: `Object to processing of Your Personal Data.`,
    text: `This right exists where We are relying on a legitimate interest as the legal basis for Our processing and there is something about Your particular situation, which makes You want to object to our processing of Your Personal Data on this ground. You also have the right to object where We are processing Your Personal Data for direct marketing purposes.`,
  },
  {
    id: 4,
    bold: `Request erasure of Your Personal Data.`,
    text: `You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it.`,
  },
  {
    id: 5,
    bold: `Request the transfer of Your Personal Data.`,
    text: `We will provide to You, or to a third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies to automated information which You initially provided consent for Us to use or where We used the information to perform a contract with You.`,
  },
  {
    id: 6,
    bold: `Withdraw Your consent.`,
    text: `You have the right to withdraw Your consent on using your Personal Data. If You withdraw Your consent, We may not be able to provide You with access to certain specific functionalities of the Service.`,
  },
];

export const DataProtectRights = `You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us. Please note that we may ask You to verify Your identity before responding to such requests. If You make a request, We will try our best to respond to You as soon as possible.`;

export const DataProtect = `You have the right to complain to a Data Protection Authority about Our collection and use of Your Personal Data. For more information, if You are in the European Economic Area (EEA), please contact Your local data protection authority in the EEA.`;

export const facebookData1 = `The Company is the Data Controller of Your Personal Data collected while using the Service. As operator of the Facebook Fan Page Https://www.facebook.com/lectie, the Company and the operator of the social network Facebook are Joint Controllers.`;

export const facebookLink = [
  {
    id: 1,
    text_before_link: `The Company is the Data Controller of Your Personal Data collected while using the Service. As operator of the`,
    link: `Https://www.facebook.com/lectie`,
    content: 'Facebook Fan Page',
  },
];

export const facebookLink1 = [
  {
    id: 1,
    text_before_link: `The Company has entered into agreements with Facebook that define the terms for use of the Facebook Fan Page, among other things. These terms are mostly based on the`,
    link: `https://www.facebook.com/terms.php`,
    content: `Facebook Terms of Service:`,
  },
];

export const facebookLink2 = [
  {
    id: 1,
    text_before_link: `Visit the`,
    link: `https://www.facebook.com/terms.php`,
    content: `Facebook Terms of Service:`,
  },
];

export const facebookLink2P = `for more information about how Facebook manages Personal data or contact Facebook online, or by mail: Facebook, Inc. ATTN, Privacy Operations, 1601 Willow Road, Menlo Park, CA 94025, United States.`;

export const facebookInsight1 = `We use the Facebook Insights function in connection with the operation of the Facebook Fan Page and on the basis of the GDPR, in order to obtain anonymized statistical data about Our users.`;

export const facebookInsight2 = `For this purpose, Facebook places a Cookie on the device of the user visiting Our Facebook Fan Page. Each Cookie contains a unique identifier code and remains active for a period of two years, except when it is deleted before the end of this period.`;

export const facebookInsight3 = `Facebook receives, records and processes the information stored in the Cookie, especially when the user visits the Facebook services, services that are provided by other members of the Facebook Fan Page and services by other companies that use Facebook services.`;

export const facebookInsightLink = [
  {
    id: 1,
    text_before_link: `For more information on the privacy practices of Facebook, please visit`,
    link: `https://www.facebook.com/privacy/explanation`,
    content: `Facebook Privacy Policy`,
  },
];

export const ccpaPrivacy = `This privacy notice section for California residents supplements the information contained in Our Privacy Policy and it applies solely to all visitors, users, and others who reside in the State of California.`;

export const ccpaPrivacy1 = `We collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular Consumer or Device. The following is a list of categories of personal information which we may collect or may have been collected from California residents within the last twelve (12) months.`;

export const ccpaPrivacy2 = `Please note that the categories and examples provided in the list below are those defined in the CCPA. This does not mean that all examples of that category of personal information were in fact collected by Us, but reflects our good faith belief to the best of our knowledge that some of that information from the applicable category may be and may have been collected. For example, certain categories of personal information would only be collected if You provided such personal information directly to Us.`;

export const identify = `Examples: A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, drivers license number, passport number, or other similar identifiers. Collected: Yes.`;

export const customer = `Examples: A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver's license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with other categories. Collected: Yes.`;

export const protectedCat = `Examples: Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information). Collected: No.`;

export const biometric = `Examples: Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data. Collected: No.`;

export const education = `Examples: Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records. Collected: No.`;

export const interfaceDrawn = `Examples: Profile reflecting a person's preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes. Collected: No.`;

export const ccpaList = [
  {
    id: 1,
    text: 'Publicly available information from government records',
  },
  {
    id: 2,
    text: 'Deidentified or aggregated consumer information',
  },
  {
    id: 3,
    text: `Information excluded from the CCPA's scope, such as:`,
  },
];

export const nestedCcpaList = [
  {
    id: 1,
    text: `Health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data`,
  },
  {
    id: 2,
    text: `Personal Information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994`,
  },
];

export const sourceList = [
  {
    id: 1,
    bold: 'Directly from You.',
    text: `For example, from the forms You complete on our Service, preferences You express or provide through our Service, or from Your purchases on our Service.`,
  },
  {
    id: 2,
    bold: `Indirectly from You.`,
    text: `For example, from observing Your activity on our Service.`,
  },
  {
    id: 3,
    bold: `Automatically from You.`,
    text: `For example, through cookies We or our Service Providers set on Your Device as You navigate through our Service.`,
  },
  {
    id: 4,
    bold: `From Service Providers.`,
    text: ` For example, third-party vendors to monitor and analyze the use of our Service, third-party vendors to provide advertising on our Service, third-party vendors for payment processing, or other third-party vendors that We use to provide the Service to You.`,
  },
];

export const BussinessPurpose = `We may use or disclose personal information We collect for "business purposes&quot; or "commercial purposes&quot; (as defined under the CCPA), which may include the following examples:`;

export const BussinessPurposeList = [
  {
    id: 1,
    text: `To operate our Service and provide You with our Service.`,
  },
  {
    id: 2,
    text: `To provide You with support and to respond to Your inquiries, including to investigate and address Your concerns and monitor and improve our Service.`,
  },
  {
    id: 3,
    text: `To fulfill or meet the reason You provided the information. For example, if You share Your contact information to ask a question about our Service, We will use that personal information to respond to Your inquiry. If You provide Your personal information to purchase a product or service, We will use that information to process Your payment and facilitate delivery.`,
  },
  {
    id: 4,
    text: `To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.`,
  },
  {
    id: 5,
    text: `As described to You when collecting Your personal information or as otherwise set forth in the CCPA.`,
  },
  {
    id: 6,
    text: `For internal administrative and auditing purposes.`,
  },
  {
    id: 7,
    text: `To detect security incidents and protect against malicious, deceptive, fraudulent or illegal activity, including, when necessary, to prosecute those responsible for such activities.`,
  },
];

export const BussinessPurposeP1 = `Please note that the examples provided above are illustrative and not intended to be exhaustive. For more details on how we use this information, please refer to the "Use of Your Personal Data"; section.`;

export const BussinessPurposeP2 = `If We decide to collect additional categories of personal information or use the personal information We collected for materially different, unrelated, or incompatible purposes We will update this Privacy Policy.`;

export const commercailP1 = `We may use or disclose and may have used or disclosed in the last twelve (12) months the following categories of personal information for business or commercial purposes:`;
export const commercailList = [
  {
    id: 1,
    text: `Category A: Identifiers`,
  },
  {
    id: 2,
    text: `Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))`,
  },
  {
    id: 3,
    text: `Category D: Commercial information`,
  },
  {
    id: 4,
    text: `Category F: Internet or other similar network activity`,
  },
  {
    id: 5,
    text: 'Category G: Geolocation data',
  },
];

export const commercailP2 = `Please note that the categories listed above are those defined in the CCPA. This does not mean that all examples of that category of personal information were in fact disclosed, but reflects our good faith belief to the best of our knowledge that some of that information from the applicable category may be and may have been disclosed.`;

export const commercailP3 = `When We disclose personal information for a business purpose or a commercial purpose, We enter a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for any purpose except performing the contract.`;

export const salesInfo = `As defined in the CCPA, "sell" and "sale" mean selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a consumer's personal information by the business to a third party for valuable consideration. This means that We may have received some kind of benefit in return for sharing personal information, but not necessarily a monetary benefit.`;

export const salesInfoP2 = `Please note that the categories listed below are those defined in the CCPA. This does not mean that all examples of that category of personal information were in fact sold, but reflects our good faith belief to the best of our knowledge that some of that information from the applicable category may be and may have been shared for value in return.`;

export const salesInfoP3 = `We may sell and may have sold in the last twelve (12) months the following categories of personal information:`;

export const salesInfoList = [
  {
    id: 1,
    text: `Category A: Identifiers`,
  },

  {
    id: 2,
    text: `Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))`,
  },
  {
    id: 3,
    text: `Category D: Commercial information`,
  },
  {
    id: 4,
    text: `Category F: Internet or other similar network activity`,
  },
  {
    id: 5,
    text: `Category G: Geolocation data`,
  },
];

export const sharePerson = `We may share Your personal information identified in the above categories with the following categories of third parties:`;

export const sharePersonList = [
  {
    id: 1,
    text: `Service Providers`,
  },
  {
    id: 2,
    text: `Payment processors`,
  },
  {
    id: 3,
    text: `Our affiliates`,
  },
  {
    id: 4,
    text: `Our business partners`,
  },
  {
    id: 5,
    text: `Third party vendors to whom You or Your agents authorize Us to disclose Your personal information in connection with products or services We provide to You`,
  },
];

export const minor1 = `We do not knowingly collect personal information from minors under the age of 16 through our Service, although certain third party websites that we link to may do so. These third- party websites have their own terms of use and privacy policies and we encourage parents and legal guardians to monitor their children's Internet usage and instruct their children to never provide information on other websites without their permission.`;

export const minor2 = `We do not sell the personal information of Consumers We actually know are less than 16 years of age, unless We receive affirmative authorization (the &quot;right to opt-in&quot;) from either the Consumer who is between 13 and 16 years of age, or the parent or guardian of a Consumer less than 13 years of age. Consumers who opt-in to the sale of personal information may opt-out of future sales at any time. To exercise the right to opt-out, You (or Your authorized representative) may submit a request to Us by contacting Us.`;

export const minor3 = `If You have reason to believe that a child under the age of 13 (or 16) has provided Us with personal information, please contact Us with sufficient detail to enable Us to delete that information.`;

export const ccpaRights = `The CCPA provides California residents with specific rights regarding their personal information. If You are a resident of California, You have the following rights:`;

export const ccpaRightsList = [
  {
    id: 1,
    bold: `The right to notice.`,
    text: `You have the right to be notified which categories of Personal Data are being collected and the purposes for which the Personal Data is being used.`,
  },
  {
    id: 2,
    bold: `The right to request.`,
    text: `Under CCPA, You have the right to request that We disclose information to You about Our collection, use, sale, disclosure for business purposes and share of personal information. Once We receive and confirm Your request, We will disclose to You:`,
  },
];

export const ccpaRightsListSub = [
  {
    id: 1,
    text: `The categories of personal information We collected about You`,
  },
  {
    id: 2,
    text: `The categories of sources for the personal information We collected about You`,
  },
  {
    id: 3,
    text: `Our business or commercial purpose for collecting or selling that personal information`,
  },
  {
    id: 4,
    text: `The categories of third parties with whom We share that personal information`,
  },
  {
    id: 5,
    text: `The specific pieces of personal information We collected about You`,
  },
  {
    id: 6,
    text: `If we sold Your personal information or disclosed Your personal information for a business purpose, We will disclose to You:`,
  },
];

export const ccpaRightsListSubSub = [
  {
    id: 1,
    text: `The categories of personal information categories sold`,
  },
  {
    id: 2,
    text: `The categories of personal information categories disclosed`,
  },
];

export const ccpaRightsList2 = [
  {
    id: 3,
    bold: `The right to say no to the sale of Personal Data (opt-out).`,
    text: `You have the right to direct Us to not sell Your personal information. To submit an opt-out request please contact Us.`,
  },
  {
    id: 4,
    bold: `The right to delete Personal Data.`,
    text: `You have the right to request the deletion of Your Personal Data, subject to certain exceptions. Once We receive and confirm Your request, We will delete (and direct Our Service Providers to delete) Your personal information from our records, unless an exception applies. We may deny Your deletion request if retaining the information is necessary for Us or Our Service Providers to:`,
  },
];

export const ccpaRightsListSub1 = [
  {
    id: 7,
    text: `Complete the transaction for which We collected the personal information, provide a good or service that You requested, take actions reasonably anticipated within the context of our ongoing business relationship with You, or otherwise perform our contract with You.`,
  },
  {
    id: 8,
    text: `Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.`,
  },
  {
    id: 9,
    text: `Debug products to identify and repair errors that impair existing intended functionality.`,
  },
  {
    id: 10,
    text: `Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.`,
  },
  {
    id: 11,
    text: `Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).`,
  },
  {
    id: 12,
    text: `Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if You previously provided informed consent.`,
  },
  {
    id: 13,
    text: `Enable solely internal uses that are reasonably aligned with consumer expectations based on Your relationship with Us.`,
  },
  {
    id: 14,
    text: `Comply with a legal obligation.`,
  },
  {
    id: 15,
    text: `Make other internal and lawful uses of that information that are compatible with the context in which You provided it.`,
  },
];

export const ccpaRightsList3 = [
  {
    id: 5,
    bold: `The right not to be discriminated against. You`,
    text: `You have the right not to be discriminated against for exercising any of Your consumer's rights, including by:`,
  },
];

export const ccpaRightsListSub2 = [
  {
    id: 1,
    text: `Denying goods or services to You`,
  },
  {
    id: 2,
    text: `Charging different prices or rates for goods or services, including the use of discounts or other benefits or imposing penalties`,
  },
  {
    id: 3,
    text: `Providing a different level or quality of goods or services to You`,
  },
  {
    id: 4,
    text: `Suggesting that You will receive a different price or rate for goods or services or a different level or quality of goods or services`,
  },
];

export const exerciseData = [
  {
    id: 1,
    text: `By email: Techsupport@lectie.com`,
  },
  {
    id: 2,
    text: `By visiting this page on our website: Www.lectie.com`,
  },
];

export const requestData = [
  {
    id: 1,
    text: `Provide sufficient information that allows Us to reasonably verify You are the person about whom We collected personal information or an authorized representative`,
  },
  {
    id: 2,
    text: `Describe Your request with sufficient detail that allows Us to properly understand, evaluate, and respond to it`,
  },
];

export const verifyData = [
  {
    id: 1,
    text: `Verify Your identity or authority to make the request`,
  },
  {
    id: 2,
    text: `And confirm that the personal information relates to You`,
  },
];

export const verifyDataP1 = `We will disclose and deliver the required information free of charge within 45 days of receiving Your verifiable request. The time period to provide the required information may be extended once by an additional 45 days when reasonably necessary and with prior notice.`;

export const verifyDataP2 = `Any disclosures We provide will only cover the 12-month period preceding the verifiable request's receipt.`;
export const verifyDataP3 = `For data portability requests, We will select a format to provide Your personal information that is readily usable and should allow You to transmit the information from one entity to another entity without hindrance.`;

export const sellPersonal1 = `You have the right to opt-out of the sale of Your personal information. Once We receive and confirm a verifiable consumer request from You, we will stop selling Your personal information. To exercise Your right to opt-out, please contact Us.`;

export const sellPersonal2 = `The Service Providers we partner with (for example, our analytics or advertising partners) may use technology on the Service that sells personal information as defined by the CCPA law. If you wish to opt out of the use of Your personal information for interest-based advertising purposes and these potential sales as defined under CCPA law, you may do so by following the instructions below.`;

export const sellPersonal3 = `Please note that any opt out is specific to the browser You use. You may need to opt out on every browser that You use.`;

export const website = `You can opt out of receiving ads that are personalized as served by our Service Providers by following our instructions presented on the Service:`;

export const websiteList = [
  {
    id: 1,
    text_before_link: ``,
    link: `http://www.networkadvertising.org/choices/`,
    content: `The NAI's opt-out platform:`,
  },
  {
    id: 2,
    text_before_link: ``,
    link: `http://www.youronlinechoices.com/`,
    content: `The EDAA's opt-out platform`,
  },
  {
    id: 3,
    text_before_link: ``,
    link: `http://optout.aboutads.info/?c=2&amp;lang=EN`,
    content: `The DAA's opt-out platform:`,
  },
];

export const websiteP1 = `The opt out will place a cookie on Your computer that is unique to the browser You use to opt out. If you change browsers or delete the cookies saved by your browser, You will need to opt out again.`;

export const mobile = `Your mobile device may give You the ability to opt out of the use of information about the apps You use in order to serve You ads that are targeted to Your interests:`;

export const mobileList = [
  {
    id: 1,
    text: `"Opt out of Interest-Based Ad" or "Opt out of Ads Personalization" on Android devices`,
  },
  {
    id: 2,
    text: `"Limit Ad Tracking" on iOS devices`,
  },
];

export const mobileP2 = `You can also stop the collection of location information from Your mobile device by changing the preferences on Your mobile device.`;

export const track = `However, some third party websites do keep track of Your browsing activities. If You are visiting such websites, You can set Your preferences in Your web browser to inform websites that You do not want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of Your web browser.`;

export const childPrivacy = `Minors less than 16 years old require parental permission and supervision whilst using our platform.`;

// export const childPrivacy2 = `If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.`;

export const califoniaRights1 = `Under California Civil Code Section 1798 (California's Shine the Light law), California residents with an established business relationship with us can request information once a year about sharing their Personal Data with third parties for the third parties' direct marketing purposes.`;

export const califoniaRights2 = `If you'd like to request more information under the California Shine the Light law, and if You are a California resident, You can contact Us using the contact information provided below.`;

export const califoniaPrivacy1 = `California Business and Professions Code Section 22581 allows California residents under the age of 18 who are registered users of online sites, services or applications to request and obtain removal of content or information they have publicly posted.`;

export const califoniaPrivacy2 = `To request removal of such data, and if You are a California resident, You can contact Us
using the contact information provided below, and include the email address associated
with Your account.`;

export const califoniaPrivacy3 = `Be aware that Your request does not guarantee complete or comprehensive removal of content or information posted online and that the law may not permit or require removal in certain circumstances.`;

export const linkWebsite1 = `Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.`;

export const linkWebsite2 = `We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.`;

export const changePrivacy1 = `We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.`;

export const changePrivacy2 = `We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.`;
export const changePrivacy3 = `You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.`;

export const contactList = [
  {
    id: 1,
    text_before_link: 'By email: support@lectie.com',
    link: '',
    content: '',
  },
  {
    id: 2,
    text_before_link: 'By visiting this page on our website:',
    link: 'https://www.lectie.com',
    content: 'www.lectie.com',
  },
];
