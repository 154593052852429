import { createSlice, current } from '@reduxjs/toolkit';
import Logo from '../../assest/images/logo-big.svg';
const initialState = {
  email: '',
  firstName: '',
  lastName: '',
  organisationName: '',
  authorizationToken: '',
  password: '',
  accountType: '',
  picture: '',
  phone: '',
  countryAbbr: '',
  countryCode: '',
  dob: null,
  userName: '',
  type: 'default',
  deviceToken: '',
  status: true,
  isvalid: false,
  calender_usage: 0,
  subscription: null,
  notification: {
    count: 2,
    recent: [],
  },
  allNotification: {
    count: 0,
    notifications: [],
  },
};
// Notification Mock
// let mock = {
//   notification: {
//     count: 5,
//     recent: [
//       {
//         image: `${Logo}`,
//         title: 'Bespoke creation',
//         body: 'You created a bespoke, This is the begining of a great fun, Lets do more!!! ',
//       },
//       {
//         image: `${Logo}`,
//         title: 'Bespoke creation',
//         body: 'You created a bespoke, This is the begining of a great fun, Lets do more!!! ',
//       },
//       {
//         image: `${Logo}`,
//         title: 'Purchased Plan',
//         body: 'You just purchased the premium plan, Welcome to the elite group.',
//       },
//     ],
//   },
// };

// let dbmock = {
//   allNotification: {
//     count: 5,
//     notifications: [
//       {
//         image: `${Logo}`,
//         title: 'Bespoke creation',
//         body: 'You created a bespoke, This is the begining of a great fun, Lets do more!!! ',
//       },
//       {
//         image: `${Logo}`,
//         title: 'Bespoke creation',
//         body: 'You created a bespoke, This is the begining of a great fun, Lets do more!!! ',
//       },
//       {
//         image: `${Logo}`,
//         title: 'Purchased Plan',
//         body: 'You just purchased the premium plan, Welcome to the elite group.',
//       },
//     ],
//   },
// };

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loadUser: (state, action) => {
      // console.log(action.payload,"user from reducer")
      return {
        ...state,
        ...initialState,
        ...action.payload,
      };
    },
    profileImageUpdate: (state, { payload }) => {
      return {
        ...state,
        picture: payload,
      };
    },
    profileUpdate: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    deviceToken: (state, { payload }) => {
      return {
        ...state,
        deviceToken: payload,
      };
    },
    newNotification: (state, { payload }) => {
      if (payload?.type === 'firebase') {
        return {
          ...state,
          notification: {
            ...state.notification,
            count: state.notification.count + 1,
            recent: [...state?.notification?.recent, payload?.notification],
          },
        };
      }

      return {
        ...state,
        notification: {
          ...state.notification,
          count: payload?.count,
          recent: [...payload?.notification],
        },
      };
    },

    updateUsage: (state, { payload }) => {
      return {
        ...state,
        calender_usage:
          state?.calender_usage > 0 ? state?.calender_usage - 1 : 0,
      };
    },
    updateUserSub: (state, { payload }) => {
      return {
        ...state,
        calender_usage: payload?.calender_Number - 1,
        isvalid: true,
        subscription: {
          ...payload,
        },
      };
    },
    cancelSubscription: (state, { payload }) => {
      console.log('payload cancel syb', payload);
      console.log('state', state);
      return {
        ...state,
        ...payload,
      };
    },
    fetchSubscription: (state, { payload }) => {
      console.log('payload cancel syb', payload);
      console.log('state', state);
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const {
  loadUser,
  profileImageUpdate,
  profileUpdate,
  deviceToken,
  newNotification,
  getAllNotification,
  updateUsage,
  updateUserSub,
  cancelSubscription,
  fetchSubscription,
} = user.actions;

export default user.reducer;
