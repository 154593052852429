import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Episode, InputField } from './helper';
import { seizer } from '../../../../../functions';
import {
  canvasPreview,
  extractImageFileExtensionFromBase64,
} from '../../../../../functions';
import Dialog from '@material-ui/core/Dialog';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import LazyLoad from 'react-lazy-load';
import { toast } from 'react-toastify';
import client from '../../../../../services';
import { BiChevronDown } from 'react-icons/bi';
import Dot from '../../../../../assest/images/blue_dot.png';
import EpisodeImg from '../../../../../assest/images/episode.png';
import TvProgram from '../../../../../assest/images/tv.png';
import ProgramDescrib from '../../../../../assest/images/describ_program.png';
import ProgramClock from '../../../../../assest/images/clock_program.png';
import ProgramReoccur from '../../../../../assest/images/reoccure_program.png';
import ProgramLink from '../../../../../assest/images/program_link.png';

import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { BsFillCloudUploadFill } from 'react-icons/bs';

import Skeleton from '@material-ui/lab/Skeleton';
import { RotatingLines } from 'react-loader-spinner';
import { TimePicker, DatePicker } from '@material-ui/pickers';

// Material styles
const useStyles = makeStyles((theme) => ({
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(1)',
    },
    '25%': {
      transform: 'scale(1.03)',
    },
    '50%': {
      transform: 'scale(1.06)',
    },
    '75%': {
      transform: 'scale(1.07)',
    },
    '100%': {
      transform: 'scale(1.1)',
    },
  },
  loader: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  loaderSkeleton: {
    height: '7rem',
  },
  floatBtn: {
    position: 'fixed',
    bottom: '100px',
    right: '3%',
    zIndex: '1000px',
    backgroundColor: '#2667B1',
    width: '4rem',
    height: '4rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    boxShadow: '0 0 0.5rem rgba(38, 103, 177,0.3)',
    animation: '$ripple 1.2s infinite ease-in-out',
  },

  floatBtnSvg: { fontSize: '1.5rem', color: '#ffffff' },
}));

const ProgramDetailsForm = ({
  formData,
  setFormData,
  handleChange,
  errForm,
  setErrForm,
}) => {
  const { t } = useTranslation(['dashboard']);

  const [isOpenReoccur, setIsOpenReoccuring] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [aspect, setAspect] = useState(16 / 9);
  const [temImg, setTemImg] = useState(null);
  const imgRef = useRef(null);
  const classes = useStyles();

  const handleDialogClose = () => {
    setIsOpenReoccuring(false);

    setOpen(false);
    setLoading(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const recurringStyles = {
    cursor: 'pointer',
    padding: '0.3rem',
  };

  // Event image upload

  // Image upoad for Event Form
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = async () => {
        // setLoading(true);
        // Send Content to server
        const binaryStr = reader.result;
        var i = new Image();
        i.onload = function () {
          setAspect(seizer(i.width, i.height));
          if (i.width < 1300 && i.height < 1300) {
            setCrop({
              unit: '%', // Can be 'px' or '%'
              x: 0,
              y: 0,
              width: 100,
              height: 100,
            });
          } else {
            setCrop(
              centerAspectCrop(i.width, i.height, seizer(i.width, i.height))
            );
          }
        };

        i.src = binaryStr;
        setTemImg(binaryStr);
        setCrop(undefined); // Set an immediate crop region
        handleClickOpen();
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    // open,
  } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.png'],
    },
  });

  // Image Function
  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 100,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }
  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
    }
  }

  // Upload image
  const uploadCover = async () => {
    setLoading(true);
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imgRef.current
      //  &&
      // previewCanvasRef.current
    ) {
      // const canvasRef = previewCanvasRef.current;

      const canvas = document.createElement('canvas');
      canvasPreview(imgRef.current, canvas, completedCrop);

      const fileext = extractImageFileExtensionFromBase64(temImg);
      const imageData64 = canvas.toDataURL('image/' + fileext);

      canvas.toBlob(
        async function (blob) {
          await client
            .post('/Souvenir/image-upload', imageData64)
            .then((res) => {
              setFormData((prevState) => ({
                ...prevState,
                image: res?.data?.picture,
              }));
              setLoading(false);
              handleDialogClose();
            })
            .catch((e) => {
              console.log(e);
              toast.error(
                'Image upload failed, Please try again with a smaller Image file'
              );
              setLoading(false);
              handleDialogClose();
            });
        },
        'image/jpeg',
        0.5
      );
    } else {
      console.log('error');
      // toast.error("");
    }
  };
  return (
    <ProgramDetailFormContainer>
      <div className="form_item">
        <span className="img_icon">
          <img src={Dot} alt="dot for title" className="dot_img" />
        </span>
        <div className="form_input_title">
          <InputField
            placeholder="Add Title"
            name="title"
            value={formData?.title}
            handleChange={handleChange}
            errForm={errForm}
            setErrForm={setErrForm}
          />
          <InputField
            placeholder="Subtitle"
            name="subTitle"
            value={formData?.subTitle}
            handleChange={handleChange}
            errForm={errForm}
            setErrForm={setErrForm}
          />
        </div>
      </div>

      <div className="form_item">
        <span className="title_dot">
          <img src={EpisodeImg} alt="episode icon" />
        </span>
        <div className="form_input_episode">
          <span>
            <p>Season</p>

            <Episode
              name="season"
              value={formData?.season}
              handleChange={handleChange}
              errForm={errForm}
              setErrForm={setErrForm}
            />
          </span>

          <span>
            <p>Episode</p>

            <Episode
              name="episode"
              value={formData?.episode}
              handleChange={handleChange}
              errForm={errForm}
              setErrForm={setErrForm}
            />
          </span>
        </div>
      </div>

      <div className="form_item">
        <span className="title_dot">
          <img src={TvProgram} alt="Tv icon" />
        </span>
        <div className="form_input_program">
          <p>Program caution</p>

          <select
            name="programCaution"
            value={formData?.programCaution}
            onChange={(e) => handleChange(e)}
          >
            <option value="PG">PG</option>
            <option value="PG-13">PG-13</option>
            <option value="NC-17">NC-17</option>
            <option value="R">R</option>
          </select>
        </div>
      </div>

      <div className="form_item">
        <span className="img_icon">
          <img src={ProgramDescrib} alt="Paragraph icon" />
        </span>
        <div className="form_input_title">
          <InputField
            placeholder="Program description"
            name="description"
            value={formData?.description}
            handleChange={handleChange}
            errForm={errForm}
            setErrForm={setErrForm}
          />
        </div>
      </div>

      <div className="form_item">
        <span className="img_icon_date">
          <img src={ProgramClock} alt="clock icon" />
        </span>

        <div className="date_container">
          <div className="date_item">
            <p>Start Date: </p>
            <DatePicker
              value={formData?.startDate}
              onChange={(e) => {
                setFormData((prevState) => ({
                  ...prevState,
                  startDate: e,
                  endDate: e,
                }));
              }}
            />
          </div>
          <div className="date_item">
            <p>End Date: </p>
            <DatePicker
              value={formData?.endDate}
              minDate={formData?.startDate}
              onChange={(e) => {
                setFormData((prevState) => ({
                  ...prevState,
                  endDate: e,
                }));
              }}
            />
          </div>

          <div className="time_container">
            <div className="time_item">
              <p>Start: </p>
              <TimePicker
                value={formData?.startTime}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    startTime: e,
                  }));
                  if (
                    new Date(formData?.startDate).getTime() ==
                    new Date(formData?.endDate).getTime()
                  ) {
                    let endTime = dayjs(e).add(1, 'hour');
                    setFormData((prevState) => ({
                      ...prevState,
                      endTime: endTime,
                    }));
                  } else {
                    let endTime = dayjs(formData?.endDate)
                      .set('hour', dayjs(new Date(e)).get('h') + 1)
                      .set('minute', dayjs(new Date(e)).get('minutes'))
                      .set('second', dayjs(new Date(e)).get('second'));
                    setFormData((prevState) => ({
                      ...prevState,
                      endTime: endTime,
                    }));
                  }
                }}
              />
            </div>
            <div className="time_item">
              <p>End: </p>
              <TimePicker
                value={formData?.endTime}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    endTime: e,
                  }));
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="form_item repeat">
        <span className="img_icon">
          <img src={ProgramReoccur} alt="Reoccuring icon" />
        </span>

        <div
          className="reoccuring_container"
          onClick={() => setIsOpenReoccuring(true)}
        >
          <p>
            {formData?.repeat === null ||
            formData?.repeat === '' ||
            formData?.repeat === 'Do Not Repeat'
              ? t('Do Not Repeat')
              : formData?.repeat === 'forthNight'
              ? t('Fortnight')
              : t(
                  formData?.repeat.charAt(0).toUpperCase() +
                    formData?.repeat.slice(1)
                )}
          </p>
          <BiChevronDown />
        </div>

        <Dialog
          open={isOpenReoccur}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div
            className="reoccur_drop_down"
            style={{
              width: '240px',
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
            }}
          >
            <div
              onClick={() => {
                setFormData((prevState) => ({
                  ...prevState,
                  repeat: 'Do Not Repeat',
                }));
                setIsOpenReoccuring(false);
              }}
              style={recurringStyles}
            >
              {t('Do Not Repeat')}
            </div>
            <div
              onClick={() => {
                setFormData((prevState) => ({
                  ...prevState,
                  repeat: 'Daily',
                }));
                setIsOpenReoccuring(false);
              }}
              style={recurringStyles}
            >
              {t('Daily')}
            </div>
            <div
              onClick={() => {
                setFormData((prevState) => ({
                  ...prevState,
                  repeat: 'Weekly',
                }));
                setIsOpenReoccuring(false);
              }}
              style={recurringStyles}
            >
              {t('Weekly')}
            </div>
            <div
              onClick={() => {
                setFormData((prevState) => ({
                  ...prevState,
                  repeat: 'Fortnight',
                }));
                setIsOpenReoccuring(false);
              }}
              style={recurringStyles}
            >
              {t('Fortnight')}
            </div>
            <div
              onClick={() => {
                setFormData((prevState) => ({
                  ...prevState,
                  repeat: 'Monthly',
                }));
                setIsOpenReoccuring(false);
              }}
              style={recurringStyles}
            >
              {t('Monthly')}
            </div>
          </div>
        </Dialog>
      </div>

      <div className="form_item_brief">
        <div className="add-image flex a-center">
          {/* IMAGE UPLOAD */}
          {loading ? (
            <div className={classes.loader}>
              <Grid item xs={10} sm={10} md={8} lg={8}>
                <Skeleton variant="rect" className={classes.loaderSkeleton} />
              </Grid>
            </div>
          ) : !formData?.image ? (
            <Grid item xs={12} sm={12} md={12} lg={12} className="drop">
              <div {...getRootProps()} className="drop-zone">
                <input {...getInputProps()} />
                {isDragAccept && <p>{t('Drop the month cover here...')}</p>}
                {isDragReject && (
                  <p>{t('Month cover ought to be a png file')}</p>
                )}
                {!isDragActive && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {t(
                      'Select or Drop your cover image for current event here'
                    )}
                  </Typography>
                )}
              </div>
            </Grid>
          ) : (
            <div className="preview" {...getRootProps()}>
              <input {...getInputProps()} />
              <Grid item className="grid">
                <LazyLoad height={'100%'} width={'100%'} offset={200}>
                  <img
                    src={formData?.image}
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </LazyLoad>
              </Grid>
            </div>
          )}
        </div>
        <span className="brief">
          Images used here will appear on your status
        </span>
      </div>

      <div className="form_item_brief">
        <div className="form_item ">
          <span className="img_icon">
            <img
              src={ProgramLink}
              alt="program Page link"
              className="dot_img"
            />
          </span>
          <div className="form_input_title">
            <InputField
              placeholder="Program thriller url"
              name="link"
              value={formData?.link}
              handleChange={handleChange}
              errForm={errForm}
              setErrForm={setErrForm}
            />
          </div>
        </div>
        <span>Video used here will appear on your status</span>
      </div>

      {/* IMage Crop Modal*/}
      <Dialog
        // fullScreen
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid item md={12} lg={12}>
          <ReactCrop
            style={{ width: '100%', height: '100%', overflowY: 'scroll' }}
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            aspect={aspect}
            locked={true}
            onComplete={(c) => setCompletedCrop(c)}
            className="react_crop"
            // disabled={true}
          >
            <img
              src={temImg}
              loading="lazy"
              style={{ width: '100%', height: '100%' }}
              ref={imgRef}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </Grid>

        <section className={classes.floatBtn} onClick={() => uploadCover()}>
          {loading ? (
            <RotatingLines
              strokeColor="#fff"
              strokeWidth="5"
              animationDuration="0.75"
              width="20"
              visible={loading}
            />
          ) : (
            <BsFillCloudUploadFill className={classes.floatBtnSvg} />
          )}
        </section>
      </Dialog>
    </ProgramDetailFormContainer>
  );
};

const ProgramDetailFormContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  .form_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.2rem;
    width: 100%;

    .form_input_title {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      width: 100%;
    }

    .form_input_episode {
      display: flex;
      gap: 1rem;

      span {
        display: flex;
        gap: 1rem;
        align-items: center;
      }
    }

    .form_input_program {
      display: flex;
      gap: 1rem;
      align-items: center;

      select {
        width: 100px;
        padding: 3px;
      }
    }

    .date_container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .date_item {
        display: flex;
        align-items: center;
        gap: 1rem;
        p {
          font-size: 1rem;
        }
        .MuiInputBase-input {
          width: 150px;
        }
      }

      .time_container {
        display: flex;
        /* flex-direction: column; */
        gap: 0.8rem;
        .time_item {
          display: flex;
          align-items: center;
          gap: 0.3rem;
          p {
            font-size: 1rem;
          }
          .MuiInputBase-root {
            width: 85px;
          }
        }
      }
    }

    .reoccuring_container {
      display: flex;
      gap: 1.5rem;
      align-items: center;
      cursor: pointer;
    }

    .img_icon {
      margin-top: 5px;
    }

    .img_icon_date {
      min-width: 24px;
      min-height: 24px;
    }
  }

  .repeat {
    .reoccur_drop_down {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      width: 150px;
      height: 250px;
      border: 2px solid red;
      background: #fff;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
      /* position: absolute;
          top: 0;
          left: 10%;
          z-index: 30; */
      padding: 5px 0px;
    }
  }

  .form_item_brief {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;

    span {
      color: #2667b1;
      font-size: 0.8rem;
    }
  }
`;

export default ProgramDetailsForm;
