import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const DayComponent = ({
  day,
  key,
  curMonth,
  monthEvent,
  handleEvent,
  handleEventEdit,
}) => {
  const { t } = useTranslation(['dashboard']);

  return (
    <>
      <DayComponentContainer
        key={key}
        onClick={(e) => {
          e.stopPropagation();
          if (day.format('MM') === curMonth) {
            handleEvent(day);
          }
        }}
      >
        <p
          className="date_day"
          style={
            curMonth === day.format('MM') ? { opacity: '1' } : { opacity: '0' }
          }
        >
          {day.format('D')}
        </p>

        {monthEvent?.map((evt) => {
          if (evt.startDate === day.format('YYYY-MM-DDTHH:mm:ss')) {
            return (
              <div
                className="events_container"
                key={key}
                onClick={(e) => {
                  e.stopPropagation();
                  console.log(evt, 'clicked event');
                  handleEventEdit(evt['id'], evt);
                }}
              >
                <div className="event">
                  <span
                    className="color"
                    style={{ backgroundColor: evt['color'] }}
                  ></span>
                  <span className="time hide_mobile">
                    {dayjs(evt?.startTime).format('hh:mma')}
                  </span>
                  <span className="title">{evt.title}</span>
                </div>
              </div>
            );
          }
        })}
      </DayComponentContainer>
    </>
  );
};

const DayComponentContainer = styled.div`
  height: 147px;
  overflow-y: hidden;
  padding: 0.2rem 0 1rem 0.2rem;
  border: 1px solid #333;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  background-color: ${({ color }) => (color ? color : '#fff')};

  .date_day {
    margin: 0.5rem 0.2rem;
    color: #161518;
  }

  .events_container {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    .event {
      position: relative;
      display: flex;
      align-items: center;
      gap: 0.2rem;
      cursor: pointer;
      margin-bottom: 0.1rem;
      width: 100%;
      .color {
        width: 7px;
        height: 7px;
        border-radius: 100%;
      }

      .time,
      .title {
        height: 100%;
        font-size: 0.8rem;
        white-space: nowrap;
        overflow-x: hidden;
      }
      .title {
        width: 60%;
        text-overflow: ellipsis;
      }
      .time {
        width: 40%;
        color: #464646;
      }
    }
  }
  .see_more {
    margin-top: 0.7rem;
    font-size: 0.85rem;
    cursor: pointer;
  }

  @media screen and (max-width: 1055px) {
    .events_container {
      .event {
        .hide_mobile {
          display: none;
        }
        .title {
          width: 90%;
        }
      }
    }
  }
`;

export default DayComponent;
