export const colorArr = [
  {
    id: 1,
    color: '#FFDAC0',
  },
  {
    id: 2,
    color: '#FFEDAC',
  },
  { id: 3, color: '#FFCEEC' },
  { id: 4, color: '#BAFFC1' },
  { id: 5, color: '#B4DFFF' },
  { id: 6, color: '#FDB8B8' },
];
