import React from 'react';
import { useSelector} from 'react-redux'
import Month from "../component/month.js";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Financial() {
    const beskpokeData = useSelector((state) => state.bespoke)
    const beskpokeDraft = useSelector((state) => state.draft)
    return (
        <>
            <div className='bespoke-con'>
                {/* <FinancialCalendar data={beskpokeData} /> */}
                {
                    beskpokeData.months.map((item, i) => <Month data={item} key={i} />)
                }
            </div>
            <ToastContainer />
        </>
    );
}

export default Financial;
