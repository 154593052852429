import React from 'react';
import { Link } from 'react-router-dom';

import { WalletDashboardContainer } from './style/WalletDashboard.style';
import WalletBanner from '../../assest/images/wallet-banner.svg';
import { useTranslation } from 'react-i18next';

const WalletDashboard = () => {
  const { t } = useTranslation(['dashboard']);
  return (
    <WalletDashboardContainer>
      <div className="main-wrap">
        <h2>{t('Create a calender to save in your vault')}</h2>

        <div className="img-container">
          <img src={WalletBanner} alt="Wallet banner" />
        </div>

        <p>{t('You are all caught up')}</p>

        <div className="button-container">
          <Link to="/dashboard/create" className="button">
            {t('Create')}
          </Link>
        </div>
      </div>
    </WalletDashboardContainer>
  );
};

export default WalletDashboard;
