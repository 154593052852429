import React, { useState, useCallback, useRef } from 'react';
import { makeStyles, TextField, Box, InputAdornment } from '@material-ui/core';
import FacebookIcon from '@mui/icons-material/Facebook';
import Language from '@mui/icons-material/Language';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useDispatch, useSelector } from 'react-redux';
import '../create-page/style/create.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useDropzone } from 'react-dropzone';
import moment from 'moment';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { generate, statusUpdate } from '../../store/reducers/CurrentDraft';
import { useLocation, useNavigate } from 'react-router-dom';
import { RotatingLines } from 'react-loader-spinner';

import {
  setCalendarMonth,
  setInitialMonthArray,
} from '../../store/features/calendarSlice';
import { handleError } from '../create-page/CreatePageService';
import Switch from 'react-switch';
import 'react-lazy-load-image-component/src/effects/blur.css';

import '../component/bespoke-core/calendarComponent/styles/uploadModal.css';
import '../component/bespoke-core/calendarComponent/styles/uploadModal.css';
import { useTranslation } from 'react-i18next';
import { CreateSettingContainer } from './CreateSetting.style';
import InviteSetting from '../update-settings/InviteSetting';

import client from '../../services';
import { setTVDraft } from '../../store/reducers/draftTVEvents';
import { getCountyCode, userGeolocation } from '../../functions';
import { useUser } from '../../authenicationProvider';

const useStyles = makeStyles((theme) => ({
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(1)',
    },
    '25%': {
      transform: 'scale(1.03)',
    },
    '50%': {
      transform: 'scale(1.06)',
    },
    '75%': {
      transform: 'scale(1.07)',
    },
    '100%': {
      transform: 'scale(1.1)',
    },
  },

  root: {
    background: '#fff',
    padding: '20px',
  },
  drop: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  preview: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  loader: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  loaderSkeleton: {
    height: '18rem',
  },
  covers: {
    marginTop: 20,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  imageList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  nextCon: {
    marginTop: 10,
  },
  reactSwitch: {
    verticalAlign: 'middle',
    marginLeft: '4px',
  },
  next: {
    background: '#2667B1',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2666B1',
    },
  },
  TextInput: {
    borderRadius: 10,
    width: '100%',
    height: 50,
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      '& fieldset': {
        border: '2px solid #2667b1',
      },
      '&.Mui-focused fieldset': {
        border: '2px solid #2667b1',
      },
    },
  },
  TextInputSocial: {
    borderRadius: 10,
    width: '100%',
    marginBottom: 15,
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      height: 50,
      '& fieldset': {
        border: '1px solid #000000',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #2667b1',
      },
    },
  },

  floatBtn: {
    position: 'fixed',
    bottom: '100px',
    right: '3%',
    zIndex: '1000px',
    backgroundColor: '#2667B1',
    width: '4rem',
    height: '4rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',

    boxShadow: '0 0 0.5rem rgba(38, 103, 177,0.3)',
    animation: '$ripple 1.2s infinite ease-in-out',
  },

  floatBtnSvg: { fontSize: '1.5rem', color: '#ffffff' },
}));

const CreateSettings = () => {
  const { t } = useTranslation(['dashboard']);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);

  const [image, setImage] = useState(false);
  const [title, setTitle] = useState(false);
  const [facebook, setFacebook] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [twitter, setTwitter] = useState('');
  const [web, setWeb] = useState('');
  const [checkedShare, setCheckedShare] = useState(false);
  const [addShare, setAddShare] = useState(false);
  const [weberrorMsg, setWebErrorMsg] = useState('');
  const [fberrorMsg, setFbErrorMsg] = useState('');
  const [linkedinerrorMsg, setLinkedinErrorMsg] = useState('');
  const [twittererrorMsg, setTwitterErrorMsg] = useState('');
  const beskpokeDraft = useSelector((state) => state.draft);
  const [processing, setProcessing] = useState(false);
  const [calendarType, setCalendarType] = useState({
    calender: '',
    lectieUsers: [],
  });

  const classes = useStyles();

  const { state } = useLocation();

  let {
    saveDate,
    saveTitle,
    slogan,
    cover,
    navRoute,
    templateId,
    templatePlan,
  } = state; // Read values passed on state

  // Recall draft if created.(Preview)
  const handleGetDraftPreview = async (id) => {
    await client
      .get(`/Souvenir/draft/${id}`)
      .then((res) => {
        dispatch(generate(res?.data));
        dispatch(setCalendarMonth(res?.data?.months));
        setProcessing(false);
        navigate('/draft/view');
      })
      .catch((err) => {
        console.log(err?.response?.data?.Message ?? err?.message);
        setProcessing(false);
      });
  };

  // Create Tem4 Structure
  const setNewDraftTem4 = async (id) => {
    let country = new userGeolocation().getUserCountry();

    let { CountryCode } = getCountyCode(country) || 'NGN';

    // Empty the state from prev data
    dispatch(setTVDraft({ events: [] }));
    // Set new  Draft struct
    client
      .get(`/Souvenir/${id}?webDraft=webDraft&country=${CountryCode}`)
      .then((res) => {
        dispatch(setTVDraft(res?.data));
        setProcessing(false);
        navigate('/draft/view');
      })
      .catch((e) => {
        console.log(e);
        console.log(e?.response?.data?.Message ?? e?.message);

        setProcessing(false);
      });
    // End set draft
  };

  // Save Function
  const save = async (bespoke) => {
    setProcessing(true);
    var data = {
      ...bespoke,
    };

    if (beskpokeDraft?.templateId === '4' || beskpokeDraft?.templateId === 4) {
      await client
        .post(`/Souvenir/create-souvenir/tvguide`, data)
        .then((res) => {
          dispatch(statusUpdate(res?.data));
          dispatch(setCalendarMonth(res?.data?.months));
          setProcessing(false);

          dispatch(generate(bespoke));

          setNewDraftTem4(res?.data?.id);
        })
        .catch((e) => {
          console.log(e?.response?.data?.Message ?? e?.message);
          setPublishing(false);
        });
    } else {
      await client
        .post('/Souvenir/create-bespoke', data)
        .then((res) => {
          dispatch(statusUpdate(res?.data));
          dispatch(setCalendarMonth(res?.data?.months));
          dispatch(generate(bespoke));

          handleGetDraftPreview(res?.data?.id);
        })
        .catch((e) => {
          console.log(e);
          console.log(e?.response?.data?.Message ?? e?.message);
          setProcessing(false);
        });
    }
  };

  // Crop Image
  const [crop, setCrop] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleShareChange = (nextChecked) => {
    setCheckedShare(nextChecked);
  };
  const handleAddShare = (nextChecked) => {
    setAddShare(nextChecked);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = async () => {
          // Send Content to server
          const binaryStr = reader.result;
          setTemImg(binaryStr);
          setCrop(undefined); // Set an immediate crop region
          handleClickOpen();
        };
        reader.readAsDataURL(file);
      });
    },
    [dispatch]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.png'],
    },
  });

  const goBack = () => {
    navigate(-1);
  };

  function validURL(str) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  }

  const next = () => {
    if (!validURL(web.trim()) && web.trim() !== '') {
      setWebErrorMsg('Not a valid url');
      return;
    }
    if (!validURL(linkedin.trim()) && linkedin.trim() !== '') {
      setLinkedinErrorMsg('Not a valid url');
      return;
    }
    if (!validURL(facebook.trim()) && facebook.trim() !== '') {
      setFbErrorMsg('Not a valid url');
      return;
    }
    if (!validURL(twitter.trim()) && twitter.trim() !== '') {
      setTwitterErrorMsg('Not a valid url');
      return;
    }

    const formData = {
      date: saveDate,
      title: saveTitle,
      slogan: slogan,
      image: cover,
    };

    if (saveDate && navRoute && saveTitle) {
      dispatch(setInitialMonthArray(saveDate.getFullYear()));
      let months = [];
      let dateStart = moment(saveDate);
      let dateEnd = moment(saveDate).add(12, 'month');

      // Genrate months in month array
      while (dateEnd.diff(dateStart, 'months') >= 1) {
        let month = {
          date: dayjs(dateStart.toDate()).format('YYYY-M'),
          background: '',
          events: [],
        };

        months.push(month);
        dateStart.add(1, 'month');
      }
      let bespoke = {
        ...calendarType,
        templateName: saveTitle,
        yearPicked: saveDate.getFullYear(),
        slogan: slogan,
        coverImg: {
          id: 0,
          src: cover,
          coverType: 0,
        },
        months: months,
        share: checkedShare,
        web: web.trim() || '',
        twitter: twitter.trim() || '',
        linkedin: linkedin.trim() || '',
        facebook: facebook.trim() || '',
        customColors: [
          {
            id: 1,
            color: '#FFDAC0',
            label: 'Birthday',
            isPresent: true,
            customId: 1,
          },
          {
            id: 2,
            color: '#FFEDAC',
            label: 'Anniversary ',
            isPresent: true,
            customId: 2,
          },
          {
            id: 3,
            color: '#FFCEEC',
            label: 'Holiday',
            isPresent: true,
            customId: 3,
          },
          {
            id: 4,
            color: '#BAFFC1',
            label: 'Birthday',
            isPresent: true,
            customId: 4,
          },
          {
            id: 5,
            color: '#B4DFFF',
            label: 'Birthday',
            isPresent: true,
            customId: 5,
          },
          {
            id: 6,
            color: '#FDB8B8',
            label: 'Birthday',
            isPresent: true,
            customId: 6,
          },
        ],
        templateId: templateId,
        calendarPlan: templatePlan,
        status: 'draft',
      };

      if (templateId === 3) {
        return navigate('/studio/cover', {
          state: {
            data: bespoke,
          },
        });
      } else {
        save(bespoke);
      }
    } else {
      // Handle Error
      let result = handleError(formData);
      for (const el of result[1]) {
        switch (el) {
          case 'date':
            setDate(true);
            break;

          case 'image':
            setImage(true);
            break;

          case 'title':
            setTitle(true);
            break;

          default:
            return 'err';
        }
      }
    }
  };

  return (
    <CreateSettingContainer className={classes.root}>
      <div className="text-center">
        <h1 className="text-[#2667B1] text-xl py-2">
          {t('Settings (Optional)')}
        </h1>
        <p className="text-xs">{t('To skip this step click on create')} </p>
      </div>

      <section className="public-option-section">
        <div className="option-section">
          <div
            style={{
              margin: '0 auto',
              backgroundColor: '#43ABF9',
              padding: '20px',
            }}
          >
            <div className="flex justify-between items-center">
              <p className="text-sm text-white">
                {t('Receiver can also share this calendar')}
              </p>

              <Switch
                onChange={handleShareChange}
                checked={checkedShare}
                className="reactSwitch"
                onColor="#2667B1"
                onHandleColor="#ffffff"
                handleDiameter={10}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={15}
                width={40}
                id="material-switch"
              />
            </div>
            <p className="text-xs text-white w-3/4 py-2">
              {t(
                'Clicking on this will also allow users to share this calendar with their friends.'
              )}
            </p>
          </div>
          {templatePlan === 'Premium' && (
            <div
              style={{
                margin: '0 auto',
                marginTop: 40,
                backgroundColor: '#43ABF9',
                padding: '20px',
              }}
            >
              <div className="flex justify-between items-center">
                <p className="text-sm text-white">
                  {t('Add your social media link')}
                </p>

                <Switch
                  onChange={handleAddShare}
                  checked={addShare}
                  className="reactSwitch"
                  onColor="#2667B1"
                  onHandleColor="#ffffff"
                  handleDiameter={10}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={15}
                  width={40}
                  id="material-switch"
                />
              </div>
              <p className="text-xs text-white w-3/4 py-2">
                {t(
                  'Your social details will be visible on your calendar and anyone you share your calendar with'
                )}
              </p>
            </div>
          )}
          {addShare && (
            <Box
              sx={{
                margin: '0 auto',
                border: '1px solid #43ABF9',
                padding: '20px',
                width: '100%',
              }}
              className="md:w-1/2"
            >
              <div className="flex justify-between items-center">
                <h2 className="font-semibold">
                  {t('Add your social media link')}
                </h2>
              </div>
              <p className="mb-4 ">
                {t('This will be visible on your calendar')}
              </p>
              <TextField
                id="input-with-icon-textfield"
                className={classes.TextInputSocial}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Language style={{ fill: '#43ABF9' }} />{' '}
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                placeholder="https://www.website.com"
                error={weberrorMsg}
                helperText={weberrorMsg === '' ? '' : weberrorMsg}
                onChange={(e) => setWeb(e.target.value)}
              />
              <TextField
                id="input-with-icon-textfield"
                className={classes.TextInputSocial}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* <TwitterIcon style={{ fill: '#1D9BF0' }} />{' '} */}
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 41 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.5"
                          width="40"
                          height="40"
                          rx="20"
                          fill="#43ABF9"
                        />
                        <path
                          d="M18.1663 12.666H12.333L19.2163 21.8452L12.708 29.3327H14.9163L20.2397 23.2085L24.833 29.3327H30.6663L23.493 19.7677L29.6663 12.666H27.458L22.4697 18.4043L18.1663 12.666ZM25.6663 27.666L15.6663 14.3327H17.333L27.333 27.666H25.6663Z"
                          fill="white"
                        />
                      </svg>
                    </InputAdornment>
                  ),
                }}
                placeholder="https://bepoke.studio/twitter"
                error={twittererrorMsg}
                helperText={twittererrorMsg === '' ? '' : twittererrorMsg}
                variant="outlined"
                onChange={(e) => setTwitter(e.target.value)}
              />
              <TextField
                id="input-with-icon-textfield"
                className={classes.TextInputSocial}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LinkedInIcon style={{ fill: '#0A66C2' }} />{' '}
                    </InputAdornment>
                  ),
                }}
                placeholder="https://bepoke.studio/linkedin"
                error={linkedinerrorMsg}
                helperText={linkedinerrorMsg === '' ? '' : linkedinerrorMsg}
                variant="outlined"
                onChange={(e) => setLinkedin(e.target.value)}
              />
              <TextField
                id="input-with-icon-textfield"
                className={classes.TextInputSocial}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FacebookIcon style={{ fill: '#43ABF9' }} />{' '}
                    </InputAdornment>
                  ),
                }}
                placeholder="https://bepoke.studio/facebook"
                error={fberrorMsg}
                helperText={fberrorMsg === '' ? '' : fberrorMsg}
                variant="outlined"
                onChange={(e) => setFacebook(e.target.value)}
              />
            </Box>
          )}{' '}
        </div>
        {(user?.calender_usage === 0 || templatePlan !== 'Basic') && (
          <InviteSetting setCalendarType={setCalendarType} />
        )}
      </section>

      <div className="text-center mt-8">
        <button
          type="button"
          className="text-gray-900 bg-white border border-[#2667B1] focus:outline-none focus:ring-gray-200 font-medium rounded-lg text-sm px-9 py-1 mr-4 mb-2 dark:bg-white dark:text-black "
          onClick={goBack}
        >
          {t('Back')}
        </button>
        <button
          disabled={processing}
          type="button"
          className="text-white bg-[#2667B1] hover:bg-[#2667B1]/55 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-9 py-1 mr-4 mb-2 dark:bg-[#2667B1] dark:hover:bg-[#2667B1] focus:outline-none "
          onClick={() => {
            if (templateId !== 2) {
              next();
            } else {
              navigate('/dashboard/politics-footer', {
                state: {
                  ...calendarType,
                  saveDate,
                  saveTitle,
                  cover,
                  navRoute,
                  templateId,
                  templatePlan,
                  socialData: {
                    linkedin,
                    twitter,
                    facebook,
                    web,
                    checkedShare,
                  },
                },
              });
            }
          }}
        >
          {t('Create')}{' '}
          {templateId !== 2 && processing && (
            <RotatingLines
              strokeColor="#fff"
              strokeWidth="5"
              animationDuration="0.75"
              width="20"
              visible={processing}
            />
          )}
        </button>
      </div>
    </CreateSettingContainer>
  );
};

export default CreateSettings;
