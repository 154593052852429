import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SignupConatainer, SignUpWrapper } from './style/Signup.styles';
import Facebook from '../../assest/images/facebook-logo.svg';
import Linkedin from '../../assest/images/linkedin-logo.svg';
import SideBar from '../component/global_signup_login/sidebar/SideBar';
import ToggleUser from '../component/global_signup_login/toggle/ToggleUser';
import {
  makeStyles,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  FormHelperText,
  Button,
} from '@material-ui/core';
import { Lock, VisibilityOff, Visibility } from '@material-ui/icons';
import { RotatingLines } from 'react-loader-spinner';
import { loadUser } from '../../store/reducers/userReducer';
import { useUser } from '../../authenicationProvider';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';
import { requestForToken, analytics } from '../../helpers/firebase';
import client from '../../services';
import { toast } from 'react-toastify';
import { getAuth, signInWithPopup, FacebookAuthProvider } from 'firebase/auth';
import { logEvent } from 'firebase/analytics';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  TextInput: {
    borderRadius: 10,
    width: '100%',
  },
}));

const Signup = () => {
  const { t } = useTranslation(['common']);

  const params = useParams();
  useEffect(() => {
    requestForToken();
    console.log(params, 'signup route props');
  }, []);

  const { setUser } = useUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [toggleUser, setToggleUser] = useState(false);
  // Loading State
  const [processing, setProcessing] = useState(false);
  // Error Validation
  let errorsObj = {
    email: '',
    isemail: false,
    password: '',
    ispassword: false,
    confirmPassword: '',
    isconfirmPassword: false,
    firstName: '',
    isfirstName: false,
    lastName: '',
    islastName: false,
    organisationName: '',
    isorganisation: false,
    showConfirmPassword: false,
    showPassword: false,
    isreferral: false,
    referralCode: '',
  };
  const [errors, setErrors] = useState(errorsObj);

  // Login Data
  const [values, setValues] = useState({
    email: '',
    firstName: '',
    lastName: '',
    organisationName: '',
    authorizationToken: '',
    password: '',
    confirmPassword: '',
    referralCode: '',
    accountType: toggleUser ? 'organisation' : 'individual',
    deviceToken: localStorage.getItem('device_token'),
    type: 'default',
    bespokeID: params?.bespokeId,
  });
  // Value change
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });

    if (event.target.value !== '') {
      setErrors({ ...errors, [prop]: '', [`is${prop}`]: false });
    }
    // else{
    //   setErrors({...errors, [`is${prop}`]: true})
    // }
  };
  // Password visibility
  const handleClickShowPassword = (prop) => (event) => {
    setErrors({ ...errors, [prop]: !errors[prop] });
  };
  // Handle mouse down
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const setIndividual = () => {
    setToggleUser(false);
    setValues({ ...values, accountType: 'individual' });
  };

  const setOrganization = () => {
    setToggleUser(true);
    setValues({ ...values, accountType: 'organisation' });
  };
  // Submit function
  const onSubmit = async () => {
    setProcessing(true);
    let error = false;
    const errorObj = { ...errorsObj };
    if (values.email === '') {
      errorObj.email = 'Email is Required';
      errorObj.isemail = true;
      error = true;
    }
    if (values.password === '') {
      errorObj.password = 'Password is Required';
      errorObj.ispassword = true;
      error = true;
    }
    if (values?.password?.length < 8) {
      errorObj.password = 'Password can not be less than 8 characters ';
      errorObj.ispassword = true;
      error = true;
    }
    if (values.password !== values.confirmPassword) {
      errorObj.confirmPassword =
        'Password and confirm password must be the same';
      errorObj.isconfirmPassword = true;
      error = true;
    }

    if (values.firstName === '' && toggleUser == false) {
      errorObj.firstName = 'Firstname is Required';
      errorObj.isfirstName = true;
      error = true;
    }
    if (values.lastName === '' && toggleUser == false) {
      errorObj.lastName = 'Lastname is Required';
      errorObj.islastName = true;
      error = true;
    }
    if (values.organisationName === '' && toggleUser == true) {
      errorObj.organisationName = 'Organisation name is Required';
      errorObj.isorganisation = true;
      error = true;
    }
    if (error) {
      setTimeout(() => {
        setProcessing(false);
        setErrors(errorObj);
      }, 3000);
      console.log('They is error with the user entry');
    } else {
      let data = {
        ...values,
      };
      console.log(data, 'send data');
      await client
        .post('/Account/register', data)
        .then((res) => {
          console.log(res.data, 'res');
          // set Authorization token to state
          var user = {
            ...data,
            ...res.data,
            confirmPassword: '',
            password: '',
          };
          console.log(user, 'user');
          console.log(user?.userToken, 'auth');
          let tokenKey = 'auth_token';
          localStorage.setItem(tokenKey, user?.userToken);
          setUser(true);
          dispatch(loadUser(user));
          setProcessing(false);
          logEvent(analytics, 'User', { email: user?.email ?? '' });
          navigate('/dashboard');
          // if (params?.bespokeId) {
          //   toast.success('Bespoke Saved Sucessfuly');
          // }
        })
        .catch((e) => {
          console.log(e);
          setProcessing(false);
          toast.error(e?.response?.data?.Message ?? e?.message);
        });
    }
  };

  // Facebook Login
  const fbLogin = async () => {
    setProcessing(true);
    const provider = new FacebookAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        // The signed-in user info.
        const user = result.user;

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        let userData = {
          email: user.email,
          firstName: user.displayName?.split(' ')[0],
          lastName: user.displayName?.split(' ')[1],
          organisationName: '',
          authorizationToken: accessToken,
          password: '',
          confirmPassword: '',
          picture: user?.photoURL,
          accountType: toggleUser ? 'organisation' : 'individual',
          deviceToken: localStorage.getItem('device_token'),
          type: 'fb',
          bespokeID: params?.bespokeId,
        };
        console.log(userData, 'user profile');
        // set Authorization token to state
        let data = {
          ...userData,
        };

        // set Authorization token to state

        await client
          .post('/Account/sociallogin', data)
          .then((res) => {
            // set Authorization token to state
            let user = {
              ...data,
              ...res.data,
              confirmPassword: '',
              password: '',
            };

            let tokenKey = 'auth_token';
            localStorage.setItem(tokenKey, user?.userToken);
            setUser(true);
            dispatch(loadUser(user));
            setProcessing(false);
            logEvent(analytics, 'User', { email: user?.email ?? '' });
            navigate('/dashboard');
            // if (params?.bespokeId) {
            //   toast.success('Bespoke Saved Sucessfuly');
            // }
          })
          .catch((e) => {
            setProcessing(false);
            toast.error(e?.response?.data?.Message ?? e?.message);
          });
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error?.code;
        const errorMessage = error?.message;
        // The email of the user's account used.
        const email = error?.customData?.email;
        // The AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error);
        console.log(errorMessage);
        setProcessing(false);
      });
  };

  // Linkedin Login
  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    redirectUri:
      process.env.NODE_ENV == 'development'
        ? process.env.REACT_APP_LINKEDIN_REDIRECT_URL
        : process.env.REACT_APP_LINKEDIN_REDIRECT_URL_PRODUCTION,
    scope: 'r_emailaddress,r_liteprofile',

    onSuccess: async (code) => {
      console.log('This is linkedIn Code', code);
      let userData = {
        email: '',
        firstName: '',
        lastName: '',
        organisationName: '',
        authorizationToken: code,
        password: '',
        confirmPassword: '',
        picture: '',
        accountType: toggleUser ? 'organisation' : 'individual',
        deviceToken: localStorage.getItem('device_token'),
        type: 'linkedin',
        bespokeID: params?.bespokeId,
      };
      console.log(userData);
      let data = {
        ...userData,
      };
      await client
        .post('/Account/sociallogin', data)
        .then((res) => {
          let user = {
            ...data,
            ...res.data,
            confirmPassword: '',
            password: '',
          };

          // set Authorization token to state
          let tokenKey = 'auth_token';
          localStorage.setItem(tokenKey, user?.userToken);
          setUser(true);
          dispatch(loadUser(user));
          setProcessing(false);
          logEvent(analytics, 'User', { email: user?.email ?? '' });
          navigate('/dashboard');
          // if (params?.bespokeId) {
          //   toast.success('Bespoke Saved Sucessfuly');
          // }
          // if (params?.bespokeId) {
          //   navigate(`/bespoke/calendar/${params?.bespokeId}`);
          // } else {
          //   navigate('/dashboard');
          // }
        })
        .catch((e) => {
          setProcessing(false);
          toast.error(e?.response?.data?.Message ?? e?.message);
        });
    },
    onError: (error) => {
      console.log(error);
      setProcessing(false);
    },
  });

  // Google Login
  const googleLogin = async (e) => {
    var token = e?.credential;
    var decoded = jwt_decode(token);

    console.log('This is Google Token', decoded);
    let userData = {
      email: decoded?.email,
      firstName: decoded?.given_name,
      lastName: decoded?.family_name,
      organisationName: '',
      authorizationToken: '',
      password: '',
      confirmPassword: '',
      picture: decoded?.picture,
      accountType: toggleUser ? 'organisation' : 'individual',
      deviceToken: localStorage.getItem('device_token'),
      type: 'google',
      bespokeID: params?.bespokeId,
    };
    console.log(userData);
    let data = {
      ...userData,
    };
    await client
      .post('/Account/sociallogin', data)
      .then((res) => {
        let user = {
          ...data,
          ...res.data,
          confirmPassword: '',
          password: '',
        };
        // set Authorization token to state
        console.log('user', user);
        let tokenKey = 'auth_token';
        localStorage.setItem(tokenKey, user?.userToken);
        setUser(true);
        dispatch(loadUser(user));
        setProcessing(false);
        logEvent(analytics, 'User', { email: user?.email ?? '' });
        navigate('/dashboard');
        // if (params?.bespokeId) {
        //   toast.success('Bespoke Saved Sucessfuly');
        // }
      })
      .catch((e) => {
        setProcessing(false);
        toast.error(e?.response?.data?.Message ?? e?.message);
      });
  };

  return (
    <SignupConatainer>
      <SignUpWrapper toggle={toggleUser}>
        <SideBar />
        <div className="right-side-form">
          <form>
            <ToggleUser
              setIndividual={setIndividual}
              setOrganization={setOrganization}
              head={'Sign Up'}
              toggle={toggleUser}
            />
            <div className="main-form">
              <TextField
                className={classes.TextInput}
                value={values.email}
                onChange={handleChange('email')}
                error={errors.isemail}
                helperText={errors.email}
                id="outlined-basic-email"
                label={t('E-mail')}
                variant="outlined"
              />

              {toggleUser === false && (
                <div className="fullname-input">
                  <TextField
                    className={classes.TextInput}
                    value={values.firstName}
                    onChange={handleChange('firstName')}
                    error={errors.isfirstName}
                    helperText={errors.firstName}
                    id="outlined-basic-firstname"
                    label={t('First Name')}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.TextInput}
                    value={values.lastName}
                    onChange={handleChange('lastName')}
                    error={errors.islastName}
                    helperText={errors.lastName}
                    id="outlined-basic-lastname"
                    label={t('Last Name')}
                    variant="outlined"
                  />
                </div>
              )}
              {toggleUser === true && (
                <TextField
                  className={classes.TextInput}
                  value={values.organisationName}
                  onChange={handleChange('organisationName')}
                  error={errors.isorganisation}
                  helperText={errors.organisationName}
                  id="outlined-basic-organisation"
                  label={t('Organization Name')}
                  variant="outlined"
                />
              )}

              <FormControl variant="outlined">
                <InputLabel
                  error={errors.ispassword}
                  htmlFor="outlined-adornment-password"
                >
                  {t('Password')}
                </InputLabel>
                <OutlinedInput
                  error={errors.ispassword}
                  id="outlined-adornment-password"
                  type={errors.showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange('password')}
                  placeholder={t('Minimum of 8 characters')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword('showPassword')}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {errors.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <Lock />
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={100}
                />
                <FormHelperText error={errors.ispassword}>
                  {errors.password}
                </FormHelperText>
              </FormControl>

              <FormControl variant="outlined">
                <InputLabel
                  error={errors.isconfirmPassword}
                  htmlFor="outlined-adornment-password"
                >
                  {t('Confirm Password')}
                </InputLabel>
                <OutlinedInput
                  error={errors.isconfirmPassword}
                  id="outlined-adornment-confirm-password"
                  type={errors.showConfirmPassword ? 'text' : 'password'}
                  value={values.confirmPassword}
                  onChange={handleChange('confirmPassword')}
                  placeholder={t('Minimum of 8 characters')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword('showConfirmPassword')}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {errors.showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <Lock />
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={170}
                />
                <FormHelperText error={errors.isconfirmPassword}>
                  {errors.confirmPassword}
                </FormHelperText>
              </FormControl>

              <TextField
                className={classes.TextInput}
                value={values.referralCode}
                onChange={handleChange('referralCode')}
                error={errors.isreferral}
                helperText={errors.referralCode}
                id="outlined-basic-organisation"
                label={t('Referral Code (Optional)')}
                variant="outlined"
              />

              <p className="state-tc">
                {t('By continuning you agree to our')}
                <a href="/terms">{t('terms')}</a> {t('of use and')}{' '}
                <a href="/privacy-policy">{t('privacy policy')}</a>
              </p>
            </div>
            <div className="button-container">
              <Button
                disabled={processing}
                startIcon={
                  <RotatingLines
                    strokeColor="#fff"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="20"
                    visible={processing}
                  />
                }
                variant="contained"
                color="primary"
                style={{ textTransform: 'none' }}
                onClick={() => onSubmit()}
              >
                {processing ? t('Processing') : t('Sign Up')}
              </Button>
              <p
                className="state-tc"
                onClick={(e) => {
                  e.preventDefault();
                  if (params?.bespokeId) {
                    navigate(`/login/${params?.bespokeId}`);
                  } else {
                    navigate(`/login`);
                  }
                }}
              >
                {t('Already have an account')}
                <a href="#" target="_blank">
                  {t('Login')}
                </a>
              </p>
            </div>
            <div className="alt-signup">
              <p className="the-or">{t('Or')}</p>
              <div className="google-btn">
                <GoogleLogin
                  text="continue_with"
                  theme="filled_black"
                  shape="pill"
                  onSuccess={(credentialResponse) => {
                    setProcessing(true);
                    googleLogin(credentialResponse);
                  }}
                  onError={() => {
                    console.log('Login Failed');
                    setProcessing(false);
                  }}
                />
              </div>

              <div className="alt-direct-signup" onClick={() => fbLogin()}>
                <img src={Facebook} alt="google logo" />
                <p>{t('Continue with Facebook')}</p>
              </div>
              {/* <div className="alt-direct-signup" onClick={linkedInLogin}>
                <img src={Linkedin} alt="google logo" />
                <p>{t('Continue with Linkedin')}</p>
              </div> */}
            </div>
          </form>
        </div>
      </SignUpWrapper>
    </SignupConatainer>
  );
};

export default Signup;
