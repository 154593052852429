import React from 'react';
import { useTranslation } from 'react-i18next';
import { MainWrapper, AboutContainer } from './Main.styles';
import 'react-alice-carousel/lib/alice-carousel.css';
import LectieVault from '../../assest/images/lectie_vault.svg';
import TickImg from '../../assest/images/TickIcon.svg';

function LectieVaultDemo() {
  const { t } = useTranslation(['about']);
  return (
    <AboutContainer className="flex column">
      <MainWrapper className="flex column">
        <div className="About-Container flex a-center">
          <div className="content-callaction flex column">
            <h2 className="lectie_vault">{t('Transform Your Experience')}</h2>
            <p className="header_para">
              {t(
                `Embark on a journey where productivity meets community spirit. Download Lectie, and turn every day into a celebration of shared moments and cherished memories.`
              )}
            </p>
            <div className="flex points_section">
              <div>
                <img src={TickImg} alt="bespoke" />
              </div>{' '}
              <p>
                {t(
                  `Stay seamlessly connected, always in the loop, and be a part of a community that embraces the transformative power of shared experiences.`
                )}
              </p>
            </div>

            <div className="flex points_section">
              <div>
                {' '}
                <img src={TickImg} alt="bespoke" />
              </div>{' '}
              <p>
                {t(
                  `Connect with your customers, friends and family on a deeper level, create personalized and engaging experiences, and build trust and loyalty.`
                )}
              </p>
            </div>

            <a
              href="/dashboard/"
              target="_blank"
              rel="noreferrer"
              className="flex btn_started_vault"
            >
              {t('Get started')}
            </a>
          </div>

          <div className="image-Text-Wrapper flex column">
            <div className="vault_img_container">
              <img src={LectieVault} alt="bespoke" />
            </div>
          </div>
        </div>
      </MainWrapper>
    </AboutContainer>
  );
}

export default LectieVaultDemo;
