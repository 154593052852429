import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { Tabs, Tab, Paper, Box } from '@material-ui/core';
import FileList from './files';
import DraftList from './draft';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: '100%',
    // padding: '10px'
  },
  grid: {
    // maxHeight: "80vh",
    // overflowY: "none",
    overflow: 'hidden',
  },

  option: {
    textAlign: 'center',
  },
  title: {
    padding: '40px 5px 10px 10px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '30px',
    color: '#0A0A0A',
    marginTop: '20px',
  },
  subTitle: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '25px',
    color: '#696969',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function EventList() {
  const { t } = useTranslation(['dashboard']);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <div className={cx([classes.title, 'title-reponsive'])}>
        <h1>{t('Calendars')}</h1>
      </div>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <FileList />
        {/* <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="basic tabs example"
        >
          <Tab label="Calendars" style={{ width: '50px' }} {...a11yProps(0)} />
          <Tab label="Draft" {...a11yProps(0)} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <FileList />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DraftList />
        </TabPanel> */}
      </Box>
    </div>
  );
}
