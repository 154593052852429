import styled from 'styled-components';

export const HeaderContainer = styled.div`
  background: #2667b1;
  width: 100%;

  padding: 10px 0;
  margin-bottom: 10px;
  .main-wrapper {
    position: relative;
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .mobile_menu {
      .hide_desktop {
        display: none;
      }
    }

    .logo-container {
      cursor: pointer;
      width: 120px;
      height: 45px;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      svg {
        display: none;
      }

      img {
        width: 100%;
      }
    }
    .profile_drop_wrapper_header_1 {
      display: none;
    }

    .right-side {
      display: flex;
      gap: 1rem;
      align-items: center;
      position: relative;

      .smallImg {
        cursor: pointer;
      }

      .heading_profile_image {
        .back_profile {
          position: fixed;
          height: 100vh;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 6;
        }
      }

      .toggle-container {
        cursor: pointer;
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        img {
          width: 100%;
        }
      }

      .save-copy {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        padding: 10px 0;
        color: #fff;
        .share_icon_container {
          width: 75px;
          height: 75px;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .icon {
          width: 35.4px;
          height: 35.4px;
          cursor: pointer;
        }

        button {
          width: 100px;
          height: 40px;
          top: 20px;
          background: #43abf9;
          border-radius: 8px;
        }
      }
    }
    .box-view {
      display: none;
    }
  }

  .header2_right_side {
    .profile-image {
      cursor: pointer;
    }
    .save-copy {
      display: flex;
      align-items: center;
      gap: 10px !important;
    }
    .icon {
      border: 2px solid transparent;
      svg {
        font-size: 26.3px;
        color: #fff !important;
        margin-top: 3px;
      }
    }
  }

  @media screen and (max-width: 645px) {
    .header_no_logic {
      .parent_dropdown {
        top: 60px;
        left: -200px !important;
      }
    }
  }
  @media screen and (max-width: 543px) {
    .main-wrapper {
      .mobile_menu {
        position: fixed;
        height: 100%;
        width: 260px;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: scroll;
        padding-bottom: 4rem;
        background-color: #fff;
        z-index: 7;
        padding-top: 2rem;
        border-radius: 0px 20px 20px 0px;

        .close_icon {
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            font-size: 3rem;
          }
        }

        .hide_desktop {
          display: flex;
          position: relative;
          top: 4rem;
          flex-direction: column;
          gap: 1.3rem;

          h2 {
            margin-bottom: 2rem;
            padding-bottom: 1.5rem;
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 22px;
            text-align: center;
            color: #111111;
            border-bottom: 0.5px solid #dadada;
          }

          svg {
            color: #000;
          }
          .icon {
            svg {
              font-size: 1.5rem;
            }
          }

          .menu_item {
            display: flex;
            align-items: center;
            gap: 1.3rem;
            padding-left: 1rem;
            span {
              display: block;
            }
          }

          .label_container {
            margin-top: 3rem;
            padding-top: 2rem;
            border-top: 0.5px solid #dadada;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 2rem;

            .label_header {
              display: flex;
              align-items: center;
              gap: 1.1rem;

              button {
                cursor: pointer;
                padding: 0.4rem;
                font-size: 0.9rem;
                background: #ffffff;
                border: 0.451537px solid #dadada;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 1.80615px;
              }

              h3 {
                font-family: 'Nunito';
                font-style: normal;
                font-weight: 700;
                font-size: 1.3rem;
                line-height: 19px;

                color: #111111;
              }
            }

            .labels {
              display: flex;
              flex-direction: column;
              gap: 1.2rem;

              li {
                display: flex;
                align-items: center;
                list-style-type: none;
                gap: 1.2rem;

                .color {
                  height: 25px;
                  width: 25px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 3px;
                }
              }
            }
          }
        }
      }

      .logo-container {
        width: 120px;
        height: 40px;

        svg {
          display: block;
          width: 50%;
          height: 60%;
          color: #fff;
        }
        img {
          width: 50%;
          height: 100%;
        }
      }
      .right-side {
        .hide_mobile {
          display: none;
        }
      }

      .header1 {
        width: 190px;
        position: absolute;
        top: 3.2rem;
        right: 20px;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 1rem;
        background-color: #fff;
        z-index: index 10px;
        box-shadow: -3px 5px 20px -3px rgba(0, 0, 0, 0.7);
        -webkit-box-shadow: -3px 5px 20px -3px rgba(0, 0, 0, 0.7);
        -moz-box-shadow: -3px 5px 20px -3px rgba(0, 0, 0, 0.7);
      }
      .hide_header1 {
        display: none;
        border: 2px solid red;
      }

      .box-view {
        display: flex;
        color: #fff;
        font-size: 2rem;
        box-shadow: -3px 5px 20px -3px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: -3px 5px 20px -3px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: -3px 5px 20px -3px rgba(0, 0, 0, 0.3);
      }

      .parent_dropdown {
        left: -140px;
        padding-bottom: 0;
      }
    }
  }
`;
