import React, { useEffect, useState } from 'react';
import { EventContainer, EventWrapper } from './styles/calendar.Styles';
import { setShowEventModel } from '../../../../store/features/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FiTrash2, FiX } from 'react-icons/fi';
import { buttonBlueColor } from '../globalStyles/globalStyles.styles';
import EventForm from './EventForm';
import {
  setCalendarEditEvent,
  setCalendarEditRepeatedEvent,
  setCalendarEditReminder,
  setCalendarEvent,
  setDeleteEvent,
  setDeleteRepeatedEvent,
  setSigleEventEdit,
  setDeleteEventRecurring,
} from '../../../../store/features/calendarSlice';
import { useTranslation } from 'react-i18next';
import {
  deleteEvent,
  deleteEventRecurring,
  updateEventRecurring,
} from '../../../../functions';

// EditOption is from the BespokeCore.js
// It used the presence of selected Event in glogal state
//  to know if the event form is for edited or for adding new event
const EventModal = ({ editOption }) => {
  const { t } = useTranslation(['dashboard']);

  const dispatch = useDispatch();
  const [showDelete, setShowDelete] = useState(false);
  const [selectedRepeatDelete, setSelectedRepeatedDelete] = useState(false);
  const [selectedRepeatUpdate, setSelectedRepeatedUpdate] = useState(false);
  const [isMainRepeat, setIsMainRepeat] = useState(false);
  const [newEventObject, setNewEventObject] = useState();
  const [formLoading, setIsFormLoading] = useState(false);
  const draft = useSelector((state) => state?.draft);

  // To know when to show popup or not base on reoccuring selected value
  const [occurVal, setOccurVal] = useState('Do Not Repeat');

  useEffect(() => {
    if (editOption) {
      setShowDelete(true);
    }
  }, []);

  const handleUpdatePopup = () => {
    setSelectedRepeatedUpdate(true);
    setIsMainRepeat(true);
    setSelectedRepeatedDelete(false);
  };

  // console.log(newEventObject, 'this is new object')
  return (
    <EventContainer color={buttonBlueColor}>
      <EventWrapper className="b">
        {/* Event All or Present Delete*/}
        {isMainRepeat && (
          <section
            className="all_one_main_container"
            onClick={() => {
              setIsMainRepeat(false);
              setSelectedRepeatedDelete(false);
              setSelectedRepeatedUpdate(false);
            }}
          >
            {selectedRepeatDelete && (
              <div className="all_one_container">
                <span
                  onClick={() => {
                    const callDispatch = () => {
                      dispatch(
                        setDeleteRepeatedEvent(
                          document.querySelector('.hideCommonInput').value
                        )
                      );
                      dispatch(setShowEventModel(false));
                    };

                    deleteEventRecurring(
                      callDispatch,

                      setIsFormLoading,

                      {
                        bespokeId: draft?.id,
                        commonId:
                          document.querySelector('.hideCommonInput').value,
                      }
                    );
                  }}
                >
                  {t('Delete All Events')}
                </span>
                <span
                  onClick={() => {
                    console.log(
                      {
                        evtId: document.querySelector('.hideCommonInput').value,
                        evtDate: document.querySelector('.startDate').value,
                      },
                      'payload'
                    );

                    const callDispatch = () => {
                      dispatch(
                        setDeleteEventRecurring({
                          evtId:
                            document.querySelector('.hideCommonInput').value,
                          evtDate: document.querySelector('.startDate').value,
                        })
                      );

                      dispatch(setShowEventModel(false));
                    };

                    deleteEventRecurring(
                      callDispatch,
                      setIsFormLoading,

                      {
                        bespokeId: draft?.id,
                        commonId:
                          document.querySelector('.hideCommonInput').value,
                        evtDate: document.querySelector('.startDate').value,
                      }
                    );
                  }}
                >
                  {' '}
                  {t('Delete Present Event')}
                </span>
              </div>
            )}

            {selectedRepeatUpdate && (
              <div className="all_one_container">
                <span
                  onClick={() => {
                    const callDispatch = () => {
                      dispatch(setCalendarEditRepeatedEvent(newEventObject));

                      // dispatch(setCalendarEditEvent(newEventObject));

                      // dispatch(
                      //   setDeleteRepeatedEvent(
                      //     document.querySelector('.hideCommonInput').value
                      //   )
                      // );

                      console.log(newEventObject, 'Event obj edit');

                      dispatch(setShowEventModel(false));
                    };

                    updateEventRecurring(
                      callDispatch,
                      setNewEventObject,
                      setIsFormLoading,
                      newEventObject,
                      {
                        bespokeId: draft?.id,
                        commonId: newEventObject?.events[0]?.commonId,
                      }
                    );
                  }}
                >
                  {t('Update All Events')}
                </span>
                <span
                  onClick={() => {
                    const callDispatch = () => {
                      if (
                        newEventObject?.events[0]?.repeat ===
                        newEventObject?.events[0]?.prevRepeat
                      ) {
                        dispatch(
                          setDeleteEventRecurring({
                            evtId: newEventObject?.events[0]?.commonId,
                            evtDate: newEventObject?.events[0]?.startDate,
                          })
                        );
                        console.log(newEventObject, 'update data');
                        dispatch(setSigleEventEdit(newEventObject));

                        dispatch(setShowEventModel(false));
                      } else {
                        // Delete other recurring
                        dispatch(
                          setDeleteRepeatedEvent(
                            newEventObject?.events[0]?.commonId
                          )
                        );
                        dispatch(setCalendarEditEvent(newEventObject));
                        dispatch(setShowEventModel(false));
                      }
                    };

                    updateEventRecurring(
                      callDispatch,
                      setNewEventObject,
                      setIsFormLoading,
                      newEventObject,
                      {
                        bespokeId: draft?.id,
                        commonId: newEventObject?.events[0]?.commonId,
                        evtDate: newEventObject?.events[0]?.startDate,
                      }
                    );
                  }}
                >
                  {' '}
                  {t('Update Present Event')}
                </span>
              </div>
            )}
          </section>
        )}

        {/* Event All or Present Update*/}

        <>
          <header className="flex a-center model_head">
            {showDelete &&
              !formLoading &&
              document.querySelector('.hideInput').value !==
                '00000000-0000-0000-0000-000000000000' && (
                <FiTrash2
                  onClick={async () => {
                    if (
                      document.querySelector('.hideCommonInput').value === '' ||
                      occurVal === 'Do Not Repeat'
                    ) {
                      // Set dispatch call
                      const callDispatch = () => {
                        dispatch(
                          setDeleteEvent(
                            document.querySelector('.hideInput').value
                          )
                        );
                        dispatch(setShowEventModel(false));
                      };

                      // delete event end point
                      deleteEvent(
                        document.querySelector('.hideInput').value,
                        callDispatch,
                        setIsFormLoading
                      );
                    } else {
                      setIsMainRepeat((prevState) => !prevState);
                      setSelectedRepeatedDelete((prevState) => !prevState);
                      setSelectedRepeatedUpdate(false);
                    }
                  }}
                />
              )}

            {!formLoading && (
              <button
                className="flex a-center model_head"
                type="button"
                onClick={() => dispatch(setShowEventModel(false))}
              >
                <FiX />
              </button>
            )}
          </header>
          <EventForm
            editOption={showDelete}
            handleUpdatePopup={() => handleUpdatePopup()}
            setNewEventObject={setNewEventObject}
            setOccurVal={setOccurVal}
            setCalendarEditReminder={setCalendarEditReminder}
            setIsFormLoading={setIsFormLoading}
            formLoading={formLoading}
          />
        </>
      </EventWrapper>
    </EventContainer>
  );
};

export default EventModal;
