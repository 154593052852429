import { Editor, Frame, Element } from '@craftjs/core';
import React from 'react';

import { Viewport, RenderNode } from '../studio/editor';
import { Container, Text } from '../studio/selectors';
import { Button } from '../studio/selectors/Button';
import { Contact, Contents} from '../studio/selectors/contact';
import { Video } from '../studio/selectors/Video';
import {IconsCover} from '../studio/selectors/CoverIcons'




function Studio() {
  return (
        <Editor
          resolver={{
            Container,
            Text,
            Button,
            Video,
            Contact,
            Contents,
            IconsCover,
          }}
          enabled={false}
          onRender={RenderNode}
        >
          <Viewport>
            <Frame>
              <Element
                canvas
                is={Container}
                width="780"
                height="80vh"
                background={{ r: 0, g: 0, b: 0, a: 0 }}
                padding={['0', '0', '0', '0']}
                custom={{ displayName: 'Cover' }}
              >



              </Element>
            
            </Frame>
          </Viewport>
        </Editor>
  );
}

export default Studio;
