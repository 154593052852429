import LinkedIn from '../../../assest/images/linkedIn-white-dark.svg';
import Facebook from '../../../assest/images/facebook-dark.svg';
import Twitter from '../../../assest/images/x-dark.svg';
import Instagram from '../../../assest/images/Instagram-dark.svg';

const ServiceLinks = [
  { id: 1, title: 'T&Cs', path: '/terms' },
  { id: 2, title: 'Privacy', path: '/Privacy' },
];

export const socialMedia = [
  {
    name: 'Linkedin',
    id: 1,
    image: `${LinkedIn}`,
    link: 'https://www.linkedin.com/company/lectie/',
    alt: 'Linkedin media link',
  },
  {
    name: 'Instagram',
    id: 2,
    image: `${Instagram}`,
    link: 'https://www.instagram.com/lectieofficial/',
    alt: 'Instagram media Link',
  },

  {
    name: 'Twitter',
    id: 3,
    image: `${Twitter}`,
    link: 'https://twitter.com/lectieofficial',
    alt: 'Twitter media link',
  },
  {
    name: 'Facebook',
    id: 4,
    image: `${Facebook}`,
    link: 'https://web.facebook.com/lectieofficial',
    alt: 'Facebook media link',
  },
];

export const account = [
  {
    name: 'Profile',
    id: 1,
    link: '/dashboard/profile/',
    alt: 'Profile  link',
  },
  {
    name: 'Vault',
    id: 2,
    link: '/dashboard/',
    alt: 'Vault  link',
  },
];

export default ServiceLinks;
