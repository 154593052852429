import styled from 'styled-components';

export const WalletDashboardContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-top: 3rem;

  .main-wrap {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      font-weight: 400;
      font-size: 1rem;
      margin-bottom: 1.375rem;
      text-align: center;
    }
    p {
      font-size: 1rem;
      font-weight: bold;
    }

    .img-container {
      width: 90%;

      img {
        width: 100%;
        object-fit: cover;
        image-rendering: crisp-edges;
      }
    }

    .button-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;
      a {
        text-align: center;
        text-decoration: none;
        border: none;
        width: 12.6875rem;
        cursor: pointer;
        padding: 12px;
        color: #fff;
        background: #2667b1;
        border-radius: 12px;
      }
    }
  }

  @media screen and (max-width: 290px) {
    .main-wrap {
      h2 {
        font-size: 0.9rem;
      }
    }
  }
`;
