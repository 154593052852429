import { useNode, useEditor } from '@craftjs/core';
import React from 'react';
import { ListItemIcon} from '@material-ui/core';

import { IconsSettings } from './IconsSetting';


export const IconsCover = (props) => {
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  props = {
    ...props,
  };
  const {
    fontSize,
    color,
    children,
  } = props;

  return (
         <ListItemIcon 
         ref={connect}
    style={{
      fontSize: fontSize,
        color: `rgba(${Object.values(color)})`,
        minWidth: '30px',
         height: 'auto',
      }}
     >
       {children}
    </ListItemIcon>
  );
};

IconsCover.craft = {
  displayName: 'Icons',
  props: {
    fontSize: '15',
    color: { r: 92, g: 90, b: 90, a: 1 },
    text: 'Icon',
    minWidth: '30px',
    height: 'auto',
  },
  related: {
    toolbar: IconsSettings,
  },
};
