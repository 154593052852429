import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import SideBar from '../component/global_signup_login/sidebar/SideBar';
import { LoginContainer, LoginWrapper } from './styles/Login.styles';
import Facebook from '../../assest/images/facebook-logo.svg';
import Linkedin from '../../assest/images/linkedin-logo.svg';
import Google from '../../assest/images/google-logo.svg';

import {
  makeStyles,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  FormHelperText,
  Button,
} from '@material-ui/core';
import { Lock, VisibilityOff, Visibility } from '@material-ui/icons';
import { RotatingLines } from 'react-loader-spinner';
import { loadUser } from '../../store/reducers/userReducer';
import { useUser } from '../../authenicationProvider';
import { GoogleLogin } from '@react-oauth/google';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import jwt_decode from 'jwt-decode';
import client from '../../services';
import { toast } from 'react-toastify';
import { getAuth, signInWithPopup, FacebookAuthProvider } from 'firebase/auth';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  TextInput: {
    borderRadius: 10,
    width: '100%',
  },
}));

const Login = () => {
  const { t } = useTranslation(['common']);

  const params = useParams();
  const { setUser } = useUser();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  // Loading State
  const [processing, setProcessing] = useState(false);
  // Error Validation
  let errorsObj = {
    email: '',
    isemail: false,
    password: '',
    ispassword: false,
    showPassword: false,
  };
  const [errors, setErrors] = useState(errorsObj);

  // Login Data
  const [values, setValues] = useState({
    email: '',
    authorizationToken: '',
    deviceToken: localStorage?.getItem('device_token'),
    password: '',
    type: 'default',
    bespokeID: params?.bespokeId,
  });

  // Value change
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });

    if (event.target.value !== '') {
      setErrors({ ...errors, [prop]: '', [`is${prop}`]: false });
    }
    // else{
    //   setErrors({...errors, [`is${prop}`]: true})
    // }
  };
  // Password visibility
  const handleClickShowPassword = (prop) => (event) => {
    setErrors({ ...errors, [prop]: !errors[prop] });
  };
  // Handle mouse down
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Submit function
  const onSubmit = async () => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (values.email === '') {
      errorObj.email = 'Email is Required';
      errorObj.isemail = true;
      error = true;
    }
    if (values.password === '') {
      errorObj.password = 'Password is Required';
      errorObj.ispassword = true;
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      setTimeout(() => {
        setProcessing(false);
        setErrors(errorObj);
      }, 3000);
      console.log('They is error with the user entry');
    } else {
      setProcessing(true);

      var { authorizationToken, ...data } = values;
      // console.log('THis is before Api data', data);
      await client
        .post('/Account/emaillogin', data)
        .then((res) => {
          // To remove password from the data before saving to state
          // Stored in redux store, eliminate security brigde
          console.log('Login response ', res.data);
          data = {
            email: data.email,
            type: data.type,
          };

          // set Authorization token to state
          var user = {
            ...data,
            ...res.data,
            deviceToken: localStorage.getItem('device_token'),
          };
          console.log(user, 'user');
          // console.log(user?.userToken, 'auth');
          let tokenKey = 'auth_token';
          localStorage.setItem(tokenKey, user?.userToken);
          setUser(true);
          dispatch(loadUser(user));
          setProcessing(false);
          navigate('/dashboard');
          // if (params?.bespokeId) {
          //   navigate(`/bespoke/calendar/${params?.bespokeId}`);
          // } else {
          //   navigate('/dashboard');
          // }
        })
        .catch((e) => {
          console.log(e);
          setProcessing(false);
          toast.error(e?.response?.data?.Message ?? e?.message);
        });
    }
  };

  // Facebook Login
  const fbLogin = async () => {
    setProcessing(true);
    const provider = new FacebookAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        // The signed-in user info.
        const user = result.user;

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        let userData = {
          email: user.email,
          firstName: user.displayName?.split(' ')[0],
          lastName: user.displayName?.split(' ')[1],
          organisationName: '',
          authorizationToken: accessToken,
          password: '',
          confirmPassword: '',
          picture: user?.photoURL,
          deviceToken: localStorage.getItem('device_token'),
          type: 'fb',
          bespokeID: params?.bespokeId,
        };
        // console.log(userData, 'user profile');

        let data = {
          ...userData,
        };

        // set Authorization token to state

        await client
          .post('/Account/sociallogin', data)
          .then((res) => {
            // set Authorization token to state
            let user = {
              ...data,
              ...res.data,
            };

            let tokenKey = 'auth_token';
            localStorage.setItem(tokenKey, user?.userToken);
            setUser(true);
            dispatch(loadUser(user));
            setProcessing(false);
            navigate('/dashboard');
            // if (params?.bespokeId) {
            //   navigate(`/bespoke/calendar/${params?.bespokeId}`);
            // } else {
            //   navigate('/dashboard');
            // }
          })
          .catch((e) => {
            setProcessing(false);
            toast.error(e?.response?.data?.Message ?? e?.message);
          });
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error?.code;
        const errorMessage = error?.message;
        // The email of the user's account used.
        const email = error?.customData?.email;
        // The AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error);
        console.log(errorMessage);
        setProcessing(false);
      });
  };

  // Google Login
  const googleLogin = async (e) => {
    var token = e?.credential;
    var decoded = jwt_decode(token);

    // console.log(decoded);
    let userData = {
      email: decoded?.email,
      firstName: decoded?.given_name,
      lastName: decoded?.family_name,
      organisationName: '',
      authorizationToken: '',
      password: '',
      confirmPassword: '',
      picture: decoded?.picture,
      type: 'google',
      bespokeID: params?.bespokeId,
    };
    // console.log(userData);

    let data = {
      ...userData,
    };

    await client
      .post('/Account/sociallogin', data)
      .then((res) => {
        // set Authorization token to state
        let user = {
          ...data,
          ...res.data,
        };

        let tokenKey = 'auth_token';
        localStorage.setItem(tokenKey, user?.userToken);
        setUser(true);
        dispatch(loadUser(user));
        setProcessing(false);
        navigate('/dashboard');
        // if (params?.bespokeId) {
        //   navigate(`/bespoke/calendar/${params?.bespokeId}`);
        // } else {
        //   navigate('/dashboard');
        // }
      })
      .catch((e) => {
        setProcessing(false);
        toast.error(e?.response?.data?.Message ?? e?.message);
      });
  };
  // Linkedin
  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    redirectUri:
      process.env.NODE_ENV == 'development'
        ? process.env.REACT_APP_LINKEDIN_REDIRECT_URL
        : process.env.REACT_APP_LINKEDIN_REDIRECT_URL_PRODUCTION,
    scope: 'r_emailaddress,r_liteprofile',
    onSuccess: async (code) => {
      // console.log('This is token', code);
      let userData = {
        email: '',
        firstName: '',
        lastName: '',
        organisationName: '',
        authorizationToken: code,
        password: '',
        confirmPassword: '',
        picture: '',
        deviceToken: localStorage.getItem('device_token'),
        type: 'linkedin',
        bespokeID: params?.bespokeId,
      };
      // console.log(userData, 'user profile');
      let data = {
        ...userData,
      };

      setProcessing(true);

      // set Authorization token to state
      await client
        .post('/Account/sociallogin', data)
        .then((res) => {
          // set Authorization token to state
          let user = {
            ...data,
            ...res.data,
          };

          let tokenKey = 'auth_token';
          localStorage.setItem(tokenKey, user?.userToken);
          setUser(true);
          dispatch(loadUser(user));
          setProcessing(false);
          navigate('/dashboard');
          // if (params?.bespokeId) {
          //   navigate(`/bespoke/calendar/${params?.bespokeId}`);
          // } else {
          //   navigate('/dashboard');
          // }
        })
        .catch((e) => {
          setProcessing(false);
          toast.error(e?.response?.data?.Message ?? e?.message);
        });
    },
    onError: (error) => {
      console.log(error);
      setProcessing(false);
    },
  });

  return (
    <LoginContainer>
      <LoginWrapper>
        <SideBar />

        <div className="right-side-form">
          <form>
            <h2>{t('Login')}</h2>
            <div className="main-form">
              <TextField
                className={classes.TextInput}
                value={values.email}
                onChange={handleChange('email')}
                error={errors.isemail}
                helperText={errors.email}
                id="outlined-basic-email"
                label={t('E-mail')}
                variant="outlined"
              />

              <FormControl variant="outlined">
                <InputLabel
                  error={errors.ispassword}
                  htmlFor="outlined-adornment-password"
                >
                  {t('Password')}
                </InputLabel>
                <OutlinedInput
                  error={errors.ispassword}
                  id="outlined-adornment-password"
                  type={errors.showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange('password')}
                  placeholder={t('Enter Password')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword('showPassword')}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {errors.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <Lock />
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={100}
                />
                <FormHelperText error={errors.ispassword}>
                  {errors.password}
                </FormHelperText>
              </FormControl>

              <div className="remember-me-check">
                <div className="check-container">
                  <input type="checkbox" name="remember-me" />{' '}
                  <p>{t('Keep me logged in')} </p>
                </div>

                <p
                  className="forgot-password"
                  onClick={(e) => {
                    navigate(`/forgot-password`);
                  }}
                >
                  {t('Forgot password')}
                </p>
              </div>
            </div>
            <div className="button-container">
              <Button
                startIcon={
                  <RotatingLines
                    strokeColor="#fff"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="20"
                    visible={processing}
                  />
                }
                variant="contained"
                color="primary"
                style={{ textTransform: 'none' }}
                onClick={() => onSubmit()}
              >
                {processing ? t('Processing') : t('Login')}
              </Button>
              <p
                className="state-tc"
                onClick={(e) => {
                  e.preventDefault();
                  if (params?.bespokeId) {
                    navigate(`/signup/${params?.bespokeId}`);
                  } else {
                    navigate(`/signup`);
                  }
                }}
              >
                {t("Don't have an account")}
                <a href="#" target="_blank">
                  {t('Signup')}
                </a>
              </p>
            </div>

            <div className="alt-signup">
              <p className="the-or">{t('Or')}</p>
              <div className="google-btn">
                <GoogleLogin
                  text="continue_with"
                  theme="filled_black"
                  shape="pill"
                  onSuccess={(credentialResponse) => {
                    setProcessing(true);
                    googleLogin(credentialResponse);
                  }}
                  onError={() => {
                    console.log('Login Failed');
                    setProcessing(false);
                  }}
                />
              </div>
              {/* <div className="alt-direct-signup">
                <img src={Google} alt="google logo" />
                <p>{t("Continue with Google")}</p>
              </div> */}

              <div className="alt-direct-signup" onClick={() => fbLogin()}>
                <img src={Facebook} alt="google logo" />
                <p>{t('Continue with Facebook')}</p>
              </div>

              {/* <div className="alt-direct-signup" onClick={linkedInLogin}>
                <img src={Linkedin} alt="google logo" />
                <p>{t('Continue with Linkedin')}</p>
              </div> */}
            </div>
          </form>
        </div>
      </LoginWrapper>
    </LoginContainer>
  );
};

export default Login;
