import React, { useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { setMonthImage } from '../../../../store/features/calendarSlice';
import client from '../../../../services';
import Dialog from '@material-ui/core/Dialog';
import { Grid, Button as MButton } from '@material-ui/core';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { RotatingLines } from 'react-loader-spinner';
import { BsFillCloudUploadFill } from 'react-icons/bs';
import './styles/uploadModal.css';
import { useDropzone } from 'react-dropzone';
import LazyLoad from 'react-lazy-load';
import {
  canvasPreview,
  extractImageFileExtensionFromBase64,
  UploadImageToS3,
  seizer,
} from '../../../../functions';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
const useStyles = makeStyles((theme) => ({
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(1)',
    },
    '25%': {
      transform: 'scale(1.03)',
    },
    '50%': {
      transform: 'scale(1.06)',
    },
    '75%': {
      transform: 'scale(1.07)',
    },
    '100%': {
      transform: 'scale(1.1)',
    },
  },

  loader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  loaderSkeleton: {
    height: '7rem',
    width: '100%',
  },
  next: {
    background: '#2667B1',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2666B1',
    },
    previewImage: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    btnCon: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
    },
    previewImage: {},
  },
  floatBtn: {
    position: 'fixed',
    bottom: '100px',
    right: '3%',
    zIndex: '1000px',
    backgroundColor: '#2667B1',
    width: '4rem',
    height: '4rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',

    boxShadow: '0 0 0.5rem rgba(38, 103, 177,0.3)',
    animation: '$ripple 1.2s infinite ease-in-out',
  },

  floatBtnSvg: { fontSize: '1.5rem', color: '#ffffff' },
}));

// Function Component
const UploadMonthCover = ({ monthNumber, draft, months }) => {
  const { t } = useTranslation(['dashboard']);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [temImg, setTemImg] = useState(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [aspect, setAspect] = useState(16 / 9);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);

  /**
   * ==========================
   * Month Cover upload
   * =========================
   */

  // open month cover dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  // close month cover dialog
  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  // Get image for the month
  const imgUser = draft['months']?.filter((val, i) => {
    if (i === monthNumber) return val;
  });

  // Drop zone for image upload
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = async () => {
          // Send Content to server
          const binaryStr = reader.result;

          var i = new Image();
          i.onload = function () {
            setAspect(seizer(i.width, i.height));
            if (i.width < 1300 && i.height < 1300) {
              setCrop({
                unit: '%', // Can be 'px' or '%'
                x: 0,
                y: 0,
                width: 100,
                height: 100,
              });
            } else {
              setCrop(
                centerAspectCrop(i.width, i.height, seizer(i.width, i.height))
              );
            }
          };
          i.src = binaryStr;
          setTemImg(binaryStr);
          setCrop(undefined); // Set an immediate crop region
          handleClickOpen();
        };

        reader.readAsDataURL(file);
      });
    },
    [monthNumber]
  );

  const {
    // open,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.png'],
    },
  });

  // Image Function
  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 100,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      // setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  // Upload image
  const uploadCover = async () => {
    setLoading(true);
    if (completedCrop?.width && completedCrop?.height && imgRef.current) {
      const canvas = document.createElement('canvas');
      canvasPreview(imgRef.current, canvas, completedCrop);

      const fileext = extractImageFileExtensionFromBase64(temImg);
      const imageData64 = canvas.toDataURL('image/' + fileext);
      // console.log(imageData64, 'image');

      const souvenirId = draft?.id;
      const monthId = months[monthNumber]?.id;

      canvas.toBlob(
        async function (blob) {
          await client
            .post(
              `/Souvenir/image-upload-update/${souvenirId}?monthId=${monthId}`,
              imageData64
            )
            .then((res) => {
              dispatch(
                setMonthImage({ monthNumber, binaryStr: res?.data?.picture })
              );
              setLoading(false);
              handleClose();
            })
            .catch((e) => {
              console.log(e);
              toast.error(
                t(
                  'Image upload failed, Please try again with a smaller Image file'
                )
              );
              setLoading(false);
              handleClose();
            });
        },
        'image/jpeg',
        0.5
      );
    } else {
      console.log('error');
    }
  };
  return (
    <UploadmonthCoverContainer>
      <div className="edit-background flex flex-col">
        {loading ? (
          <Skeleton variant="rect" className={classes.loaderSkeleton} />
        ) : (
          <div
            className={
              imgUser?.length !== 0 && imgUser[0]['background']
                ? 'month-cover'
                : 'img-container'
            }
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {imgUser?.length !== 0 && imgUser[0]['background'] ? (
              <LazyLoad height={'100%'} width={'100%'} offset={200}>
                <img
                  className="month-cover"
                  src={`${imgUser[0]['background']}`}
                  // src={temImg}
                  alt="background cover"
                />
              </LazyLoad>
            ) : (
              <div className="text-container">
                {' '}
                {isDragAccept && (
                  <span>t("Drop the calendar cover here...")</span>
                )}
                {isDragReject && (
                  <span>t("calendar cover ought to be a png file")</span>
                )}
                {!isDragActive && <span>{t('Click to upload image')}</span>}
              </div>
            )}
          </div>
        )}
      </div>

      <Dialog
        // fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid item md={12} lg={12}>
          <ReactCrop
            style={{ width: '100%', height: '100%', overflowY: 'scroll' }}
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            aspect={aspect}
            locked={true}
            onComplete={(c) => setCompletedCrop(c)}
            className="react_crop"
            // disabled={true}
          >
            <img
              src={temImg}
              loading="lazy"
              style={{ width: '100%', height: '100%' }}
              ref={imgRef}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </Grid>

        <section className={classes.floatBtn} onClick={() => uploadCover()}>
          {loading ? (
            <RotatingLines
              strokeColor="#fff"
              strokeWidth="5"
              animationDuration="0.75"
              width="20"
              visible={loading}
            />
          ) : (
            <BsFillCloudUploadFill className={classes.floatBtnSvg} />
          )}
        </section>
      </Dialog>
    </UploadmonthCoverContainer>
  );
};

export default UploadMonthCover;

const UploadmonthCoverContainer = styled.section`
  .edit-background {
    gap: 0.5rem;
    margin-bottom: 1.375rem;

    .img-container {
      height: 7rem;
      /* height: auto; */
      width: 100%;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        ${'' /* object-fit: cover; */}
      }

      .text-container {
        width: 100%;
        height: 7rem;
        border: 2px dashed #ddd;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2.5rem 0;
        h4 {
          text-align: center;
          width: 100%;
          height: 100%;
        }
      }
    }
    p {
      align-self: flex-end;
      justify-content: flex-end;
      font-size: 1rem;
      color: #094b5f;
      gap: 0.3rem;
      max-width: 200px;
      cursor: pointer;
      svg {
        width: 16px;
        height: 16px;
        color: #8c8c8c;
      }
    }
  }
`;
