import { Element, useNode, Canvas } from '@craftjs/core';
import React from 'react';
import { Box, List, ListItem} from '@material-ui/core';

import {Email, Call, Place} from '@material-ui/icons'

import { Container } from '../Container';
import { IconsCover } from '../CoverIcons';
import { Text } from '../Text';

export const Contents = ({ children, ...props }) => {
  const {
    connectors: { connect },
  } = useNode();
  return (
    <div title="only-text" ref={connect}  {...props} >
      {children}
    </div>
  );
};

Contents.craft = {
  
  rules: {
    canMoveIn: (nodes) => nodes.every((node) => node.data.type === Text),
  },
};

// Cover Ions

;

export const Contact = (props) => {

  return (
    <Container {...props}>
      <Box sx={{ width: '100%', maxWidth: 360,}} style={{ boxShadow:"none"}}>
      <nav aria-label="main mailbox folders">
        <List>
          <ListItem >

              <IconsCover color={{ r: 92, g: 90, b: 90, a: 1 }} fontSize= '20px' >
              <Place fontSize='inherit'/>
              </IconsCover>
   
              <Element canvas id="address" is={Contents}>
              <Text fontSize="12" textAlign="left" text="No 23 NTA road, chuba road, Port harcourt" />
             </Element>
          </ListItem>
          <ListItem>
          <IconsCover color={{ r: 92, g: 90, b: 90, a: 1 }} fontSize= '20px'>
                <Email fontSize='inherit'/>
                 </IconsCover>
              <Element canvas id="email" is={Contents}>
              <Text fontSize="12" textAlign="left" text="example@bespoke.com" />
             </Element>
          </ListItem>
          <ListItem>

          <IconsCover color={{ r: 92, g: 90, b: 90, a: 1 }} fontSize= '20px'>
                <Call fontSize='inherit'/>
                </IconsCover>
    
              <Element canvas id="phone" is={Contents}>
              <Text fontSize="12" textAlign="left" text="08166723207" />
             </Element>
          </ListItem>
        </List>
      </nav>
    </Box>
    </Container>
  );
};

Contact.craft = {
  ...Container.craft,
  displayName: 'Contact',
};
