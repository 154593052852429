import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setMonthImage } from '../../../../../store/features/calendarSlice';
import { setShowAddMonthImages } from '../../../../../store/features/modalSlice';
import {
  FileUploadModalConatinaer,
  FileUploadModalWrapper,
} from './style/FileUploadModal.style';
import FileUpload from '../../../../file-upload/FileUpload';
import { FiX } from 'react-icons/fi';

// FUNCTION
const FileUploadModal = ({ monthNumber }) => {
  const dispatch = useDispatch();

  const updateUploadedFiles = (files) =>
    setNewUserInfo({ ...newUserInfo, profileImages: files });
  return (
    <FileUploadModalConatinaer>
      <FileUploadModalWrapper>
        <div className="cancel">
          <FiX
            typeof="button"
            onClick={() => dispatch(setShowAddMonthImages(false))}
          />
        </div>

        <FileUpload monthNumber={monthNumber} />
      </FileUploadModalWrapper>
    </FileUploadModalConatinaer>
  );
};

export default FileUploadModal;
