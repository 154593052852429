import { createSlice, current } from '@reduxjs/toolkit';
import uuid from 'react-uuid';
import dayjs from 'dayjs';
import weekdayPlugin from 'dayjs/plugin/weekday';
import objectPlugin from 'dayjs/plugin/toObject';
import isTodayPlugin from 'dayjs/plugin/isToday';
import customParseFormat from 'dayjs/plugin/customParseFormat';

//Plugins
dayjs.extend(weekdayPlugin);
dayjs.extend(objectPlugin);
dayjs.extend(isTodayPlugin);
dayjs.extend(customParseFormat);

// Initail State
const initialState = {
  months: [],
  selectedEvent: '',
  yearPicked: dayjs().year(),
};

function repeatedFormulae(date, events, months) {
  // Const Variables
  const currentMonth = dayjs(date).locale('es');
  const startDate = dayjs(events?.startDate).locale('es');
  const monthIndex = dayjs(date).get('month');

  // To get the time only in Hours, Minutes and Seconds

  //--- StartTime
  const startTimeHour = dayjs(events?.startTime).get('hour');
  const startTimeMinute = dayjs(events?.startTime).get('minute');
  const startTimeSecond = dayjs(events?.startTime).get('second');

  // --- EndTime
  const endTimeHour = dayjs(events?.endTime).get('hour');
  const endTimeMinute = dayjs(events?.endTime).get('minute');
  const endTimeSecond = dayjs(events?.endTime).get('second');

  // Variable parameters
  var curDay;
  var curMonth;
  var EventYear;
  var nextYear;
  var prevYear;
  var monthCounter;
  var dayCounter;
  var weekDay;
  var event;
  var newEvent;

  switch (events?.repeat) {
    case 'Daily':
      curDay = startDate;
      curMonth = currentMonth;
      nextYear = currentMonth.add(1, 'year').year();
      monthCounter = curMonth.get('month');
      dayCounter = curDay.get('date');

      while (curMonth.year() < nextYear) {
        while (dayCounter <= curMonth.daysInMonth()) {
          event = {
            ...events,
            startDate: dayjs(events.startDate)
              .set('month', monthCounter)
              .set('date', dayCounter)
              .format('YYYY-MM-DDTHH:mm:ss'),
            endDate: dayjs(events.endDate)
              .set('month', monthCounter)
              .set('date', dayCounter)
              .format('YYYY-MM-DDTHH:mm:ss'),
            startTime: dayjs(events.startDate)
              .set('month', monthCounter)
              .set('date', dayCounter)
              .set('hour', startTimeHour)
              .set('minute', startTimeMinute)
              .set('second', startTimeSecond)
              .format('YYYY-MM-DDTHH:mm:ss'),
            endTime: dayjs(events.endDate)
              .set('month', monthCounter)
              .set('date', dayCounter)
              .set('hour', endTimeHour)
              .set('minute', endTimeMinute)
              .set('second', endTimeSecond)
              .format('YYYY-MM-DDTHH:mm:ss'),
          };

          // Precaution aganist diff in days in month
          if (
            dayjs(
              dayjs(events.startDate).set('month', monthCounter)
            ).daysInMonth() >= dayjs(events.startDate).get('date')
          ) {
            newEvent = months[monthCounter]['events'];
            newEvent.push(event);
          }

          dayCounter++;
        }
        // Reset the day counter
        if ((dayCounter = curMonth.daysInMonth())) {
          dayCounter = 1;
        }
        monthCounter++;
        curMonth = curMonth.add(1, 'month');
      }

      break;

    case 'Weekly':
      curDay = startDate;
      curMonth = currentMonth;
      nextYear = currentMonth.add(1, 'year').year();
      monthCounter = curMonth.get('month');
      dayCounter = curDay.get('date');
      weekDay = curDay.get('day');
      while (curMonth.year() < nextYear) {
        while (dayCounter <= curMonth.daysInMonth()) {
          if (
            weekDay ==
            dayjs(events.startDate)
              .set('month', monthCounter)
              .set('date', dayCounter)
              .get('day')
          ) {
            event = {
              ...events,
              startDate: dayjs(events.startDate)
                .set('month', monthCounter)
                .set('date', dayCounter)
                .format('YYYY-MM-DDTHH:mm:ss'),
              endDate: dayjs(events.endDate)
                .set('month', monthCounter)
                .set('date', dayCounter)
                .format('YYYY-MM-DDTHH:mm:ss'),
              startTime: dayjs(events.startDate)
                .set('month', monthCounter)
                .set('date', dayCounter)
                .set('hour', startTimeHour)
                .set('minute', startTimeMinute)
                .set('second', startTimeSecond)
                .format('YYYY-MM-DDTHH:mm:ss'),
              endTime: dayjs(events.endDate)
                .set('month', monthCounter)
                .set('date', dayCounter)
                .set('hour', endTimeHour)
                .set('minute', endTimeMinute)
                .set('second', endTimeSecond)
                .format('YYYY-MM-DDTHH:mm:ss'),
            };

            // Precaution aganist diff in days in month
            if (
              dayjs(
                dayjs(events.startDate).set('month', monthCounter)
              ).daysInMonth() >= dayjs(events.startDate).get('date')
            ) {
              newEvent = months[monthCounter]['events'];
              newEvent.push(event);
            }
          }

          dayCounter++;
        }
        // Reset the day counter
        if ((dayCounter = curMonth.daysInMonth())) {
          dayCounter = 1;
        }
        monthCounter++;
        curMonth = curMonth.add(1, 'month');
      }
      break;

    // Case Fourth Night
    case 'Fortnight':
      var weekCounter = 2;
      curDay = startDate;
      curMonth = currentMonth;
      nextYear = currentMonth.add(1, 'year').year();
      monthCounter = curMonth.get('month');
      dayCounter = curDay.get('date');
      weekDay = curDay.get('day');
      while (curMonth.year() < nextYear) {
        while (dayCounter <= curMonth.daysInMonth()) {
          if (
            weekDay ==
            dayjs(events.startDate)
              .set('month', monthCounter)
              .set('date', dayCounter)
              .get('day')
          ) {
            event = {
              ...events,
              startDate: dayjs(events.startDate)
                .set('month', monthCounter)
                .set('date', dayCounter)
                .format('YYYY-MM-DDTHH:mm:ss'),
              endDate: dayjs(events.endDate)
                .set('month', monthCounter)
                .set('date', dayCounter)
                .format('YYYY-MM-DDTHH:mm:ss'),
              startTime: dayjs(events.startDate)
                .set('month', monthCounter)
                .set('date', dayCounter)
                .set('hour', startTimeHour)
                .set('minute', startTimeMinute)
                .set('second', startTimeSecond)
                .format('YYYY-MM-DDTHH:mm:ss'),
              endTime: dayjs(events.endDate)
                .set('month', monthCounter)
                .set('date', dayCounter)
                .set('hour', endTimeHour)
                .set('minute', endTimeMinute)
                .set('second', endTimeSecond)
                .format('YYYY-MM-DDTHH:mm:ss'),
            };

            // To make sure Forth night starts
            // from the Day, created from
            if (weekCounter < 2) weekCounter = weekCounter + 1;

            // Perform the actual push when in forth Night
            if (
              dayjs(
                dayjs(events.startDate).set('month', monthCounter)
              ).daysInMonth() >= dayjs(events.startDate).get('date') &&
              weekCounter === 2
            ) {
              newEvent = months[monthCounter]['events'];
              newEvent.push(event);
              weekCounter = 0;
            }
          }
          dayCounter++;
        }
        // Reset the day counter
        if ((dayCounter = curMonth.daysInMonth())) {
          dayCounter = 1;
        }
        monthCounter++;
        curMonth = curMonth.add(1, 'month');
      }
      break;

    // Case Monthly
    case 'Monthly':
      curMonth = currentMonth;
      nextYear = currentMonth.add(1, 'year').year();
      monthCounter = curMonth.get('month');

      while (curMonth.year() < nextYear) {
        event = {
          ...events,
          startDate: dayjs(events.startDate)
            .set('month', monthCounter)
            .format('YYYY-MM-DDTHH:mm:ss'),
          endDate: dayjs(events.endDate)
            .set('month', monthCounter)
            .format('YYYY-MM-DDTHH:mm:ss'),
          startTime: dayjs(events.startDate)
            .set('month', monthCounter)
            .set('hour', startTimeHour)
            .set('minute', startTimeMinute)
            .set('second', startTimeSecond)
            .format('YYYY-MM-DDTHH:mm:ss'),
          endTime: dayjs(events.endDate)
            .set('month', monthCounter)
            .set('hour', endTimeHour)
            .set('minute', endTimeMinute)
            .set('second', endTimeSecond)
            .format('YYYY-MM-DDTHH:mm:ss'),
        };

        // Precaution aganist diff in days in month
        if (
          dayjs(
            dayjs(events.startDate).set('month', monthCounter)
          ).daysInMonth() >= dayjs(events.startDate).get('date')
        ) {
          newEvent = months[monthCounter]['events'];
          newEvent.push(event);
        }

        monthCounter++;
        curMonth = curMonth.add(1, 'month');
      }

      break;

    // Last Catch
    default:
      event = {
        ...events,
      };
      newEvent = months[monthIndex]['events'];
      newEvent.push(event);
      break;
  }
}

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setCalendarMonth: (state, { payload }) => {
      let year = dayjs(payload[0]?.date).get('year');
      return {
        ...state,
        yearPicked: year,
        months: [...payload],
      };
    },
    setInitialMonthArray: (state, { payload }) => {
      state.yearPicked = payload;
      state.months = [];
      // To only genrate for current year
      for (let i = 1; i <= 12; i++) {
        let yearMonth = payload.toString() + '-' + i;
        let obj = {
          date: yearMonth,
          background: 'test',
          background: '',
          events: [],
        };
        state.months.push(obj);
      }
    },

    setCalendarEditReminder: (state, { payload }) => {
      console.log('Hey here ', payload);
    },

    setSigleEventEdit: (state, { payload }) => {
      let monthIndex = dayjs(payload['date']).get('month');

      let monthArr = [...state.months];

      monthArr[monthIndex]['events'].push(...payload['events']);
      // Spread the previous state
      let newArr = {
        ...state,
        months: [...monthArr],
      };
      state = newArr;
    },

    setCalendarEvent: (state, { payload }) => {
      let monthArr = [...state.months];

      let monthIndex = monthArr.findIndex(
        (currentValue) => currentValue?.date === payload['date']
      );

      if (
        payload?.events[0].repeat === 'Do Not Repeat' ||
        payload?.events[0].repeat === ''
      ) {
        monthArr[monthIndex]['events'].push(...payload['events']);
        // Spread the previous state
        let newArr = {
          ...state,
          months: [...monthArr],
        };
        state = newArr;
      } else {
        repeatedFormulae(payload?.date, ...payload?.events, state.months);
      }
    },
    setCalendarEditEvent: (state, { payload }) => {
      // TO check if edited event needs repeating or not
      // base on its commonId
      //and the repeat value kicks off in the else

      if (
        payload?.events[0].repeat === 'Do Not Repeat' ||
        !payload?.events[0].repeat
      ) {
        for (const month of state.months) {
          let index = state.months.indexOf(month);
          for (const evt of state.months[index]['events']) {
            let idx = state.months[index]['events'].indexOf(evt);

            // Commonid is empty to disassociate repeated event
            // that is changed inidivdual, so it becomes its own unit
            if (evt['id'] === payload['events'][0]['id']) {
              state.months[index]['events'][idx] = {
                ...payload['events'][0],
                commonId: '',
              };
            }
          }
        }
      } else {
        repeatedFormulae(payload?.date, ...payload?.events, state.months);
      }
    },
    setCalendarEditRepeatedEvent: (state, { payload }) => {
      // To store the start of the date
      let newDate = '';
      // If repeat is same as prevRepeat
      if (payload?.events[0]?.prevRepeat === payload?.events[0]?.repeat) {
        // To make event with repeat type of Do Not Repeat, not have a commonId
        if (
          payload?.events[0]?.repeat !== 'Do Not Repeat' &&
          payload?.events[0]?.repeat !== ''
        ) {
        }
        for (const month of state.months) {
          let index = state.months.indexOf(month);
          let newEvents = month['events'].map((evt) => {
            if (evt['commonId'] === payload?.events[0]?.commonId) {
              return {
                ...payload?.events[0],
                id: evt?.id,
                startDate: evt?.startDate,
                endDate: evt?.endDate,
              };
            }
            return evt;
          });
          state.months[index]['events'] = newEvents;
          newEvents = [];
        }
      } else {
        let objUpdate = {};

        // To make event with repeat type of Do Not Repeat, not have a commonId
        if (
          payload?.events[0]?.repeat !== 'Do Not Repeat' &&
          payload?.events[0]?.repeat !== '' &&
          payload?.events[0]?.repeat
        ) {
        } else {
          newDate = payload?.date;
        }
        for (const month of state.months) {
          let index = state.months.indexOf(month);

          // To get the date of the first event(evt) with same commonID
          for (const evt of state.months[index]['events']) {
            if (evt?.commonId === payload?.events[0]?.commonId) {
              objUpdate = {
                events: [
                  {
                    ...payload?.events[0],

                    startDate:
                      payload?.events[0]?.repeat === 'Do Not Repeat' ||
                      !payload?.events[0]?.repeat
                        ? payload?.events[0].startDate
                        : evt?.startDate,
                    endDate:
                      payload?.events[0]?.repeat === 'Do Not Repeat' ||
                      !payload?.events[0]?.repeat
                        ? payload?.events[0].endDate
                        : evt?.endDate,
                  },
                ],
              };
              if (objUpdate?.events) break;
            }
          }
          if (objUpdate?.events) break;
        }

        if (
          payload?.events[0]?.repeat !== 'Do Not Repeat' &&
          payload?.events[0]?.repeat &&
          payload?.events[0]?.repeat !== ''
        ) {
          newDate = dayjs(objUpdate?.events[0]?.startDate).format('YYYY-M');
        }

        console.log(newDate, 'In repeat here');

        // Delete old common id before creation of new
        for (const month of state.months) {
          let index = state.months.indexOf(month);
          let newEvents = month['events'].filter(
            (evt) => evt['commonId'] !== payload?.events[0]?.commonId
          );
          state.months[index]['events'] = newEvents;
        }

        // create Event starting from the first event Date
        repeatedFormulae(newDate, ...objUpdate?.events, state.months);
      }
    },

    setSelectedEvent: (state, { payload }) => {
      state.selectedEvent = payload;
    },
    removeSelectedEvent: (state, { payload }) => {
      return {
        ...state,
        selectedEvent: '',
      };
    },
    getCalendarEvent: (state, { payload }) => {
      for (const month of state.months) {
        for (const event of month['events']) {
          if (event['id'] === payload) {
            state.selectedEvent = event;
          }
        }
      }
    },
    setDeleteEvent: (state, { payload }) => {
      // Delete by id
      for (const month of state.months) {
        let index = state.months.indexOf(month);
        let newEvents = month['events'].filter((evt) => evt['id'] !== payload);
        state.months[index]['events'] = newEvents;
      }
    },
    setDeleteEventRecurring: (state, { payload }) => {
      // Delete by startDate
      for (const month of state.months) {
        let index = state.months.indexOf(month);
        let newEvents = month['events'].filter(
          (evt) => evt?.startDate !== payload?.evtDate
        );
        state.months[index]['events'] = newEvents;
      }
    },
    setDeleteRepeatedEvent: (state, { payload }) => {
      // Delete base on common id
      for (const month of state.months) {
        let index = state.months.indexOf(month);
        let newEvents = month['events'].filter(
          (evt) => evt['commonId'] !== payload
        );
        state.months[index]['events'] = newEvents;
      }
    },

    setMonthImage: (state, actions) => {
      const monthCount = actions.payload['monthNumber'] + 1;
      for (const month of state.months) {
        if (monthCount.toString() === dayjs(month['date']).format('M')) {
          month['background'] = actions.payload['binaryStr'];
        }
      }
    },

    addUpdateCalendarYears: (state, { payload }) => {
      return {
        ...state,
        months: [...state.months, ...payload],
      };
    },

    sortMonths: (state, { payload }) => {
      const newArray = [...state.months];
      const sortedMonth = newArray.sort((a, b) => a?.id - b?.id);

      return {
        ...state,
        months: [...sortedMonth],
      };
    },

    DeleteDateCalendarYear: (state, { payload }) => {
      const newMonth = state.months.filter(
        (month) => dayjs(month?.date).format('YYYY') !== payload.toString()
      );

      return {
        ...state,
        months: [...newMonth],
      };
    },
  },
});

export const {
  setCalendarEvent,
  setSelectedEvent,
  setDeleteEvent,
  getCalendarEvent,
  setCalendarEditReminder,
  setInitialMonthArray,
  setMonthImage,
  setCalendarEditEvent,
  setCalendarMonth,
  removeSelectedEvent,
  setDeleteRepeatedEvent,
  setCalendarEditRepeatedEvent,
  setSigleEventEdit,
  setDeleteEventRecurring,
  addUpdateCalendarYears,
  DeleteDateCalendarYear,
  sortMonths,
} = calendarSlice.actions;
export default calendarSlice.reducer;
