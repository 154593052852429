import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setShowStudioMenu } from '../store/features/studioSlice';
import styled from 'styled-components';

import Clock from './component/bespoke-core/utils/utilComponents/Clock';
import { FiX } from 'react-icons/fi';
import Logo from '../assest/images/logo-big.svg';

import Hike from '../assest/images/polygon.svg';
import Menu from '../assest/images/menu.svg';
import { makeStyles, Avatar, Badge } from '@material-ui/core';
import { NotificationsActive } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';
import ProfileDropDown from './component/profile_drop_down/ProfileDropDown';
import { useNavigate } from 'react-router-dom';
import { newNotification } from '../store/reducers/userReducer';
import client from '../services';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  smallImg: {
    width: '2.5rem',
    height: '2.5rem',
  },
}));

const MainLayoutHeader = () => {
  const { t } = useTranslation(['dashboard']);
  const [showNotification, setShowNotification] = useState(false);
  const [showProfileDrop, setShowProfileDrop] = useState(false);
  const navigate = useNavigate();
  const classes = useStyles();
  const { showMenu } = useSelector((state) => state.studio);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleNotificationShow = () => {
    setShowNotification(false);

    // Code for showing notification
  };

  useEffect(() => {
    async function fetchNotice() {
      await client
        .get('/Notification/userNotification')
        .then((res) => {
          let data = res.data;
          dispatch(newNotification(data));
        })
        .catch((e) => {
          console.log(
            ' NOtification error ',
            e?.response?.data?.Message ?? e?.message
          );
          // toast.error(e?.response?.data?.Message ?? e?.message);
        });
    }

    fetchNotice();
  }, []);

  const handleProfileDrop = (value) => {
    setShowProfileDrop((prevState) => value);
  };

  return (
    <Container>
      <nav className="nav">
        {/* Do not remove helps with styling */}
        <div className="date-time"></div>

        <div className="right-side">
          <div className="profile-gear">
            <div className="menu-mobile-flex">
              <div className="ham-mobile">
                {showMenu === false && (
                  <img
                    src={Menu}
                    alt="Lectie logo"
                    className="mobile-menu"
                    typeof="button"
                    onClick={() => dispatch(setShowStudioMenu(true))}
                  />
                )}

                {showMenu === true && (
                  <FiX
                    typeof="button"
                    onClick={() => dispatch(setShowStudioMenu(false))}
                  />
                )}
              </div>

              <div className="notice_profile">
                {/*Hide Notification */}

                <div className="notification">
                  <Badge
                    color="secondary"
                    badgeContent={user?.notification?.count}
                    showZero
                    onClick={() => {
                      if (user?.notification?.count <= 0) {
                        navigate('notification');
                      } else {
                        setShowNotification((prevState) => !prevState);
                        setShowProfileDrop(false);
                      }
                    }}
                  >
                    <NotificationsActive />
                  </Badge>

                  {showNotification && user?.notification?.count > 0 && (
                    <>
                      <div
                        className="back_notice"
                        onClick={() => handleNotificationShow()}
                      ></div>
                      <div className="notification_container">
                        <div className="Hike">
                          <img src={Hike} alt="Hike" />
                        </div>

                        <div className="notification-info">
                          {user?.notification?.recent && (
                            <div
                              className="notification-wrapper"
                              onClick={() => {
                                handleNotificationShow();
                                navigate('notification');
                              }}
                            >
                              <div className="notice-text">
                                <h5>
                                  {
                                    user.notification.recent[
                                      user.notification.recent.length - 1
                                    ]?.title
                                  }
                                </h5>
                                <div className="message">
                                  <p>
                                    {
                                      user.notification.recent.find(
                                        (item) => item.isRead === false
                                      )?.summary
                                    }
                                  </p>
                                </div>

                                {user?.notification?.count > 1 ? (
                                  <p className="see_more">
                                    {user?.notification?.count - 1} {t('more')}
                                  </p>
                                ) : user?.notification?.count === 1 ? (
                                  <p className="see_more">{t('None left')}</p>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {/* Profile Container */}

                <div className="profile-container">
                  {/* {console.log(user?.picture)} */}
                  {user?.picture ? (
                    <Avatar
                      src={user?.picture}
                      alt="profile picture"
                      className={classes.smallImg}
                      onClick={() => {
                        setShowProfileDrop((prevState) => !prevState);
                        setShowNotification(false);
                      }}
                    />
                  ) : (
                    <Avatar
                      className={classes.smallImg}
                      onClick={() => {
                        setShowProfileDrop((prevState) => !prevState);
                        setShowNotification(false);
                      }}
                    >
                      {user?.firstName !== ''
                        ? user?.firstName[0]
                        : user?.organisationName[0]}
                    </Avatar>
                  )}

                  {showProfileDrop && (
                    <div className="profile_drop_container">
                      <div
                        className="back_profile"
                        onClick={() => setShowProfileDrop(false)}
                      ></div>
                      <div className="Hike">
                        <img src={Hike} alt="Hike" />
                      </div>
                      <ProfileDropDown move="-170" func={handleProfileDrop} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </Container>
  );
};

export default MainLayoutHeader;

const Container = styled.div`
  color: #fff;

  .nav {
    float: right;
    clear: both;
    background: #2667b1;
    padding: 5px 4rem;
    padding-left: 5rem;

    width: 85%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .date-time {
      p,
      .clock {
        font-size: 0.8rem;
      }
    }
    .right-side {
      margin-right: -3rem;
      display: flex;
      align-items: center;
      gap: 5rem;

      .mode {
        width: 45px;
      }

      .profile-container {
        cursor: pointer;
      }

      .profile-gear {
        display: flex;
        align-items: center;
        gap: 1.5rem;

        .notice-profile {
          display: flex;
          gap: 2rem;
          align-items: center;
        }

        .menu-mobile-flex {
          .ham-mobile {
            display: none;
            img {
              display: none;
            }
          }
          .profile-container {
            border: none;
            position: relative;
            .parent_dropdown {
              top: 55px;
            }
          }
        }
      }
    }
  }

  /* Media Query */

  @media screen and (max-width: 1124px) {
    .nav {
      width: 79%;
      padding-left: 4.5rem;
    }
  }

  @media screen and (max-width: 859px) {
    .nav {
      width: 81%;
      padding-left: 2rem;
      padding-right: 5rem;

      .date-time {
        p,
        .clock {
          font-size: 0.7rem;
        }
      }

      .right-side {
        .mode {
          width: 35px;
        }
        .profile-gear {
        }
        .profile-container-img {
          width: 32px;
          height: 32px;
        }
      }
    }
  }

  @media screen and (max-width: 719px) {
    .nav {
      padding-left: 2.6rem;
    }
  }

  @media screen and (max-width: 610px) {
    .nav {
      width: 100%;
      height: 68px;
      display: flex;

      .date-time {
        display: none;
      }

      .right-side {
        gap: 2rem;
        margin-left: -1.5rem;

        .mode {
          display: none;
        }
        .profile-gear {
          .menu-mobile-flex {
            display: flex;
            margin-left: -10px;
            align-items: center;
            z-index: 9;
            width: 95vw;
            justify-content: space-between;
            .ham-mobile {
              display: flex;

              align-items: center;

              img {
                display: block;
              }

              .mobile-menu,
              svg {
                width: 32px;
                height: 32px;
              }

              .logo {
                width: 94px;
                height: 30px;
              }
            }
            .profile-container-img {
              width: 50px;
              height: 50px;
            }
          }
        }
      }
    }
  }
`;
