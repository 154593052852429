import React, { useState, useEffect } from 'react';

import { PrivacyContainer } from './Privacy.Styles';
import PrivacySection from './PrivacySection';
import { useTranslation } from 'react-i18next';

import Nav from '../Home/Nav';

function Privacy() {
  const { t } = useTranslation(['privacy-policy']);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Nav />
      <PrivacyContainer>
        <h3>{t('LECTIE – PRIVACY POLICY')}</h3>

        <PrivacySection />
      </PrivacyContainer>
    </>
  );
}

export default Privacy;
