import styled from 'styled-components';

export const NotificationContianer = styled.div`
  padding: 2rem;
`;

export const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 33px;
      color: #0a0a0a;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 27px;
      color: #0a0a0a;
    }
  }

  .skeleton_container {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;

    .skeleton_loader {
      height: 120px;
    }
  }

  .message_wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-direction: column;
    gap: 1.3rem;
    height: 100vh;
    overflow-y: scroll;

    & .message_box:nth-child(odd) {
      background: #f6f6f6;
    }

    .message_box {
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      box-shadow: 0px 1px 4px rgba(17, 17, 17, 0.25);
      padding: 0.5rem 1.5rem;

      .message_box__header_content {
        display: flex;
        justify-content: space-between;

        align-items: center;
        gap: 1.2rem;
        .read_status {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
        .unread {
          background: #1afe31;
        }

        .read {
          background: #d2d2d2;
        }

        .message_box__text {
          display: flex;
          flex-direction: column;
          gap: 2px;

          h5 {
            font-weight: 700;
            font-size: 1rem;
            line-height: 27px;
            color: #2667b1;
          }

          p {
            font-weight: 400;
            font-size: 0.8rem;
            line-height: 27px;
            color: #0a0a0a;
          }

          .message_summary__content {
          }
        }
      }
      .message_info {
        display: flex;
        flex-direction: column;

        .message_info__date {
          font-style: normal;
          font-size: 0.8rem;
          line-height: 27px;
          color: #0a0a0a;
        }

        .unread_date {
          font-weight: 700;
        }

        .read_date {
          font-weight: 600;
        }

        .message_info__content {
          font-style: normal;
          font-weight: 400;
          font-size: 0.7rem;
          line-height: 27px;
          color: #0a0a0a;
          padding: 0.2rem 1rem;
          max-width: 450px;
        }
      }

      .message_time_options {
        font-size: 1rem;

        svg {
          font-size: 1.5rem;
          cursor: pointer;
        }
      }

      .modal {
        z-index: 2;
        width: 240px;

        position: absolute;
        top: 85%;
        right: 12px;
        background-color: #fff;

        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
          rgba(0, 0, 0, 0.23) 0px 3px 6px;

        .modal_content {
          cursor: pointer;
          display: flex;
          align-items: flex-start;
          gap: 0.6rem;
          padding: 1rem;

          &:hover {
            background-color: #f6f6f6;
          }

          svg {
            font-size: 1.3rem;
          }

          .delete_svg {
            color: #ea0f0f;
          }
          .bell_svg {
            color: #0a0a0a;
            font-size: 2rem;
            margin-top: -7px;
          }

          .modal_desc {
            display: flex;
            flex-direction: column;
            gap: 0.3rem;

            h3 {
              font-family: "Nunito";
              font-style: normal;
              font-weight: 600;
            }
            p {
              font-size: 0.9rem;
            }

            .modal_delete {
              color: #ea0f0f;
            }

            .modal_turn-off {
              color: #0a0a0a;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 745px) {
    .message_wrapper {
      .message_box {
        flex-direction: column;
      }
    }
  }

  @media screen and (max-width: 745px) {
    .message_wrapper {
      .message_box {
        padding-top: 0.7rem;
        .message_box__header_content {
          flex-direction: column;

          .message_box__text {
            flex-direction: row;
            gap: 0.55rem;
            align-items: flex-end;
          }
        }
      }
    }
  }
`;
