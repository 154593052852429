import React, { useEffect, useRef } from 'react';
import Nav from '../Home/Nav';
import { MainFAQList } from './faq.service';
import { GetMainFAQ } from './Faq.subroutine';
import { FAQContainer, FAQWrapper } from './style/FAQ.style';
import { useTranslation } from 'react-i18next';

const FAQ = () => {
  const { t } = useTranslation(['faq']);

  const faqFeatures = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Calling point is in Faq.subroutine
  // as funcCall
  const handleToggle = (id) => {
    const parent = faqFeatures.current;
    const children = parent.querySelectorAll('.parent');

    children.forEach((child) => {
      const isChild = child.querySelector('.content.hide');

      if (id.toString() === child.id) {
        if (isChild !== null) {
          child.querySelector('.content').classList.remove('hide');
          child.querySelector('.minus').classList.remove('hide');
          child.querySelector('.Question').classList.add('blue');
          child.querySelector('.plus').classList.add('hide');
        } else {
          child.querySelector('.content').classList.add('hide');
          child.querySelector('.minus').classList.add('hide');
          child.querySelector('.Question').classList.remove('blue');
          child.querySelector('.plus').classList.remove('hide');
        }
      } else {
        child.querySelector('.content').classList.add('hide');
        child.querySelector('.minus').classList.add('hide');
        child.querySelector('.Question').classList.remove('blue');
        child.querySelector('.plus').classList.remove('hide');
      }
    });
  };

  return (
    <>
      <Nav curls={true} />
      <FAQContainer>
        <div className="faq_header">
          <h2>{t('FAQs')}</h2>
          <h3>{t('Frequently Asked Questions')}</h3>
        </div>

        <FAQWrapper ref={faqFeatures}>
          {GetMainFAQ(MainFAQList, handleToggle)}
        </FAQWrapper>
      </FAQContainer>
      ;
    </>
  );
};

export default FAQ;
