import React, { useEffect, useState } from 'react';
import { getMonth } from '../../../util';
import {
  reloadMonthNumber,
  setMonthNumber,
} from '../../../store/features/monthSlice';
import { setSmallMonthNumber } from '../../../store/features/smallMonthSlice';
import { useSelector, useDispatch } from 'react-redux';
import EventModal from './calendarComponent/EventModal';
import {
  CalendarMainConatiner,
  CalendarWrapper,
} from './calendarComponent/styles/calendar.Styles';
import CalendarHeader from './calendarComponent/CalendarHeader';
import SideBar from './calendarComponent/SideBar';
import Month from './calendarComponent/Month';
import FileUploadModal from './calendarComponent/file-upload-modal/FileUploadModal';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import {
  setShowAddMonthImages,
  setShowEventModel,
  setShowMonthModel,
  setShowTimeModel,
} from '../../../store/features/modalSlice';
import TvGuideStudio from './calendarComponent/TvGuideStudio/TvGuideStudio';
import { makeStyles, Grid } from '@material-ui/core';
import { BallTriangle } from 'react-loader-spinner';

const useStyles = makeStyles({
  root: {
    background: '#fff',
    padding: '20px',
  },
  loader: {
    width: '100%',
    height: '100%',
  },
});

function BespokeCore() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const draft = useSelector((state) => state.draft);
  const [loadingUpdateColor, setLoadingUpdateColor] = useState(false);
  const classes = useStyles();
  const { selectedEvent, yearPicked, months } = useSelector(
    (state) => state.calendar
  );

  const [currentMonth, setCurrentMonth] = useState(getMonth(0, yearPicked));
  const { monthNumber } = useSelector((state) => state.month);
  const [daysOfMonth, setDaysOfMonth] = useState(currentMonth);
  const { showEvent, showAddMonthImages } = useSelector(
    (state) => state.calendarModal
  );

  // Tv guide Template true
  const [tvGuide] = useState(
    draft?.templateId === '4' || draft?.templateId === 4 ? true : false
  );

  // Arrange month days in weeks for particular month
  const daysInMonth = () => {
    let arrMonth = currentMonth.slice(0, currentMonth.length - 1);
    let week = [];

    for (let weekDay of currentMonth[5]) {
      if (weekDay.format('MM') === currentMonth[2][1].format('MM')) {
        week.push(weekDay);
      }
    }

    if (week.length <= 0) {
      setDaysOfMonth((prevState) => arrMonth);
    } else {
      setDaysOfMonth((prevState) => currentMonth);
    }
  };

  // To redirect to dasboard when array for mont is empty
  useEffect(() => {
    if (draft['months'].length <= 0) {
      navigate('/dashboard/create');
    }
    dispatch(setShowEventModel(false));
    dispatch(setShowTimeModel(false));
    dispatch(setShowMonthModel(false));
    dispatch(setShowAddMonthImages(false));

    dispatch(reloadMonthNumber());
  }, []);

  useEffect(() => {
    setCurrentMonth(getMonth(monthNumber, yearPicked));
    daysInMonth();
  }, [monthNumber]);

  // Arranges months approriately
  useEffect(() => {
    daysInMonth();
  }, [currentMonth]);

  useEffect(() => {
    dispatch(setMonthNumber(0));
    dispatch(setSmallMonthNumber(0));
  }, [dispatch]);

  return (
    <>
      {showEvent && <EventModal editOption={selectedEvent ? true : false} />}
      {showAddMonthImages && <FileUploadModal monthNumber={monthNumber} />}
      <CalendarMainConatiner>
        <CalendarHeader />
        {tvGuide ? (
          <TvGuideStudio month={daysOfMonth} />
        ) : !loadingUpdateColor ? (
          <CalendarWrapper>
            <SideBar ColorLoader={setLoadingUpdateColor} />
            <Month month={daysOfMonth} />
          </CalendarWrapper>
        ) : (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.loader}
          >
            <Grid item xs={2} md={2} sm={2} lg={2}>
              <BallTriangle
                height={'100%'}
                width={'100%'}
                radius={5}
                color="#2667b1"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            </Grid>
          </Grid>
        )}
      </CalendarMainConatiner>
    </>
  );
}

export default BespokeCore;
