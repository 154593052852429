import styled from 'styled-components';
import Container, {
  darkSecondaryColor,
  SectionButton,
  secondaryColor,
  Wrapper,
} from '../components/Global_Styles';

export const MainWrapper = styled(Wrapper)`
  // ==== Same with Get Socializing ====
  .rolls {
    display: none;
    margin-bottom: 20px;
  }

  .video_wrapper_container {
    margin-top: 13rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    .video_heading {
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      h2 {
        color: rgba(10, 10, 10, 1);
        font-size: 2.3rem;
      }
      p {
        color: rgba(67, 171, 249, 1);
        font-size: 1.5rem;
      }
    }
    .video-Wrapper_item {
      max-width: 866px;
      margin: 0 auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      .frame_container {
        width: 100%;
        max-width: 650px;
        height: 330px;
        iframe {
          width: 100%;
          height: 100%;
          align-self: center;
        }
      }
    }
  }

  .Features-Wrapper {
    /* gap: 6.25rem; */
    justify-content: space-between;

    .Feature {
      gap: 7rem;
      justify-content: space-between;
      margin: 0 auto;
      margin-top: 3rem;

      .Image-Wrapper {
        max-width: 30.25rem;
        width: 100%;
        img {
          width: 100%;

          object-fit: contain;
        }
      }

      .Text-Feature {
        gap: 1.25rem;

        p {
          line-height: 2rem;
        }
        width: 100%;
        h3 {
          font-size: 1.6rem;
          font-weight: bold;
          color: rgba(7, 7, 7, 1);
        }
      }

      &:nth-child(odd) {
        flex-direction: row-reverse;
      }

      .btn_started {
        color: #fff;
        font-size: 1.2rem;
        border: none;
        outline: none;
        padding: 12px;
        width: 250px;
        height: 52px;
        margin: 0 auto;
        background: #2667b1;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
      }
    }
  }

  .About-Container {
    margin-top: 10rem;
    justify-content: space-between;
    gap: 6rem;

    .image-Text-Wrapper {
      gap: 0.75rem;
      width: 100%;

      img {
        width: 90%;
      }

      text-align: right;

      h4 {
        color: ${secondaryColor};
        text-align: center;
      }
    }

    .content-callaction {
      gap: 40px;
      width: 100%;

      .points_section {
        gap: 1.5rem;
        color: #4f4f54;

        img {
          width: 65px;
          height: 65px;
        }

        .header_para {
          font-family: Nunito;
          font-size: 1.8rem;
          font-weight: 500;
          line-height: 25px;
          letter-spacing: 0em;
        }

        p {
          font-family: Nunito;
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0em;
        }
      }

      .btn_started_vault {
        max-width: 380px;
        height: 56px;
        padding: 12px;
        border-radius: 12px;
        justify-content: center;
        align-items: center;
        background: #43abf9;
        color: #fff;
        text-decoration: none;
        font-size: 1.3rem;
      }

      p {
        font-size: 1.2rem;
        line-height: 2rem;
      }

      .lectie_vault {
        font-size: 2rem;
        color: rgba(7, 7, 7, 1);
      }

      .ActionButton {
        gap: 20px;
        max-width: 31rem;
        width: 100%;

        .Download-Button-One,
        .Download-Button-Two {
          width: 100%;
          a {
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }

  /* General */
  .Text-Container {
    text-align: center;

    margin-bottom: 5rem;
    gap: 1.5rem;

    h2 {
      font-size: 2.625rem;
      line-height: 3.0625rem;
    }

    .img_container_about {
      max-width: 296px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    .img_container_design {
      max-width: 676px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }

    p {
      width: 80%;
      max-width: 955px;
      margin: 0 auto;
      line-height: 1.9rem;
    }
  }

  p {
    font-size: 1.2rem;
    line-height: 1.75rem;
  }

  // ========== Media Query ======
  @media screen and (max-width: 1020px) {
    .Features-Wrapper {
      flex-direction: column;

      .Feature {
        flex-direction: column !important;
        gap: 3rem;
        .Text-Feature {
          h3 {
            text-align: center;
            font-size: 2rem;
          }
          p {
            text-align: justify;
          }
        }
      }
    }
    .About-Container {
      .content-callaction {
        order: 1;

        h2 {
          font-size: 1.95rem !important;
          text-align: center;
        }
      }

      .image-Text-Wrapper {
        order: 0;
        .vault_img_container {
          align-self: center;
        }
      }
    }
  }
`;

export const GetSocialContainer = styled(Container)`
  padding-top: 5.5rem;
  margin-top: 0.6rem;
  position: relative;

  .circle_design,
  .circle_design_mobile {
    position: absolute;
    right: 0;
    top: -10px;
  }

  .circle_design_mobile {
    display: none;
  }

  .header_social {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 2.5rem;
      line-height: 44px;
      text-align: center;
      color: #1d1d1f;
    }

    p {
      width: 70%;
      min-width: 259px;
      margin: 0 auto;
      text-align: center;
    }
  }

  @media screen and (max-width: 586px) {
    .desktop_culs {
      display: none;
    }

    .header_social {
      p {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 1094px) {
    .circle_design {
      display: none;
    }

    .circle_design_mobile {
      display: block;
      width: 90px;
      height: 98px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export const AboutContainer = styled(Container)`
  .About-Container {
    justify-content: space-between;
    gap: 3rem;
  }

  margin-top: 4.5rem;

  @media screen and (max-width: 925px) {
    .About-Container {
      flex-direction: column;
    }
  }
`;

export const AboutLectieContainer = styled(MainWrapper)`
  margin-bottom: 5rem;
  margin-top: 10rem;

  .other_product_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 1rem;
    .other_header {
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 2.5rem;
        line-height: 44px;
        text-align: center;
        color: #2667b1;
      }

      .img_container_others {
        max-width: 676px;
        margin: 0 auto;
        img {
          width: 100%;
        }
      }
    }

    .main_content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 8rem;
      grid-auto-flow: dense;
      align-items: center;

      .text_section {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        h3 {
          font-weight: 700;
          font-size: 2rem;
          line-height: 28px;
          color: #070707;
          text-align: left;
          color: rgba(38, 103, 177, 1);
        }

        p {
          font-style: normal;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 22px;
          color: #0a0a0a;
          text-align: left;
          line-height: 2rem;
        }

        button {
          outline: none;
          padding: 12px;
          gap: 12px;
          width: 100%;
          height: 52px;
          margin: 0 auto;
          background: #2667b1;
          border: 2px solid #2667b1;
          border-radius: 12px;
          color: #fff;
          cursor: pointer;
        }
      }

      .image_section {
        /* max-width: 452px; */
        /* width: 100%; */
        /* height: 376px; */

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 978px) {
    .other_product_wrapper {
      .main_content {
        /* flex-direction: column;
         */
        grid-template-columns: 1fr;

        .text_section {
          width: 90%;
          margin: 0 auto;

          p {
            text-align: left;
          }
        }

        .image_section {
          width: 100%;
        }
      }
    }
  }
`;

// Not Use
export const FAQContainer = styled(Container)`
  padding-top: 5.5rem;
  padding-bottom: 15rem;
  margin-bottom: 5rem;
  width: 100%;
  height: 80vh;
  position: relative;

  .upShread {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100vw;
    height: 80%;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .downShread {
    position: absolute;
    z-index: -2;
    left: 0;
    bottom: -10%;
    width: 100vw;
    height: 80%;
    img {
      width: 100%;
      height: 100%;
    }
  }

  //====== Media Query =====

  @media screen and (max-width: 995px) {
    .downShread {
      bottom: -25%;
    }
  }

  @media screen and (max-width: 586px) {
    .downShread {
      bottom: -30%;
    }
  }
  @media screen and (max-width: 395px) {
    .downShread {
      bottom: -50%;
    }
  }

  @media screen and (max-width: 290px) {
    height: 100vh;
    margin-bottom: 12rem;
  }
`;

export const FAQWrapper = styled(Wrapper)`
  justify-content: center;
  h2 {
    text-align: center;
    color: #fff;
    font-size: 2.3rem;
    margin-top: 3rem;
    margin-bottom: 3.75rem;
  }

  .buttonRestrict {
    width: 100%;
    max-width: 670px;
    margin: 0 auto;
    justify-content: flex-end;
  }

  .FAQFeatures-Wrapper {
    .Feature-Wrapper {
      width: 100%;
      max-width: 670px;
      margin: 0 auto;
      padding: 0 0 0 0;
      background-color: #fff;
      border-radius: 12px;

      .FAQFeture {
        justify-content: space-between;
        border-radius: 12px;
        padding: 0 2rem;
        background-color: #fff;
        height: 55px;
        margin-bottom: 20px;
        box-shadow: 0px 3px 3px 2px rgba(0, 0, 0, 0.4);
      }

      p {
        font-weight: bold;
      }
      svg {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }
  }
  .content {
    text-align: justify;
    padding: 0.7rem 2rem;
    margin-bottom: 20px;

    animation: slide 0.75s ease-in-out;

    @keyframes slide {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  .hide {
    display: none;
  }

  @media screen and (max-width: 395px) {
    .FAQFeatures-Wrapper {
      .Feature-Wrapper {
        p {
          font-size: 0.8rem;
        }
      }
    }
  }
  @media screen and (max-width: 290px) {
    .FAQFeatures-Wrapper {
      .Feature-Wrapper .FAQFeture {
        padding: 10px;
        p {
          width: 100%;
          line-height: 15px;
          font-size: 0.7rem;
        }
      }
    }
  }
`;

export const FAQButtonSection = styled(SectionButton)`
  width: 100%;
  width: 300px;
  height: 52px;
  background: #f89f31;
  border-radius: 12px;
  background-color: ${secondaryColor};
`;

export const CallActionContainer = styled(Container)`
  padding-top: 5rem;
`;

export const CallActionWrapper = styled(Wrapper)`
  width: 100%;

  .CallActionImage-Container {
    max-width: 27.060625rem;
    width: 100%;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }

  .ActionButton {
    width: 100%;
    max-width: 41rem;
    padding-top: 20px;
    margin: 0 auto;
    gap: 20px;
    .Download-Button-One,
    .Download-Button-Two {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }

  p {
    color: ${darkSecondaryColor};
    text-align: center;
    margin-top: 15px;
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 30px;
  }

  //====== Media Query =====

  @media screen and (max-width: 395px) {
    margin-top: 5rem;

    .ActionButton {
      gap: 1rem;
    }
  }

  @media screen and (max-width: 778px) {
    .ActionButton {
      max-width: 25rem;
    }
  }
`;

export const WhyLectieContainer = styled(Container)`
  padding-top: 10.5rem;
`;

export const WhyLectieWrapper = styled(MainWrapper)`
  padding-bottom: 10.5rem;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 2.5rem;
    text-align: center;
    color: #2667b1;
  }
  .WhyLectie-Features-Wrapper {
    gap: 4.75rem;
    .WhyLectie-Image-Wrapper {
      max-width: 24.1875rem;

      .Image-Container-Back {
        max-width: 18.8125rem;

        img {
          width: 100%;
        }
      }
      .Image-Container-Front {
        max-width: 14.8125rem;
        padding-top: 8.5625rem;
        margin-left: -7rem;
        img {
          width: 100%;
        }
      }
    }

    .WhyLectie-Text-Wrapper {
      width: 100%;
      .WhyLectie-Text-Container {
        gap: 2.5rem;
        .Lectie-Text-Container-ColumnOne,
        .Lectie-Text-Container-ColumnTwo {
          gap: 4rem;
          .feature {
            gap: 1.25rem;

            h3 {
              font-size: 1.5rem;
            }
            p {
              text-align: justify;
              font-size: 1.2rem;
            }

            .Fadein1 {
              animation: fadein 8s ease-in-out forwards;
              opacity: 0;
            }

            .Fadein2 {
              animation: fadein 10s ease-in-out forwards;
              opacity: 0;
            }

            .Fadein3 {
              animation: fadein 12s ease-in-out forwards;
              opacity: 0;
            }

            @keyframes fadein {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  // ======= Media Query ======
  @media screen and (max-width: 586px) {
    .WhyLectie-Features-Wrapper {
      flex-direction: column;

      .WhyLectie-Text-Wrapper {
        .WhyLectie-Text-Container {
          flex-direction: column;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .WhyLectie-Features-Wrapper {
      flex-direction: column;

      .WhyLectie-Image-Wrapper {
        margin: 0 auto;
      }
    }
  }
`;

export const LectiePitchContainer = styled.section`
  background: #43abf9;
  color: #ffffff;

  height: 100%;
  width: 100%;
  padding: 2rem 0;
  margin-top: 5rem;
  position: relative;

  .right_circle_design {
    position: absolute;
    right: 0;
    top: -0.6rem;
    z-index: 1;
  }

  .left_bottom_circle_design,
  .left_bottom_circle_design_mobile {
    position: absolute;
    left: 0;
    bottom: -0.6rem;
    z-index: 1;
  }

  .left_bottom_circle_design_mobile {
    display: none;
  }

  .main_container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    gap: 5rem;
    align-items: center;
    width: 95%;
    margin: 0 auto;
    position: relative;
    z-index: 2;

    .description_container {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      h3 {
        font-family: Nunito;
        font-size: clamp(2.4rem, 2.9rem, 3rem);
        font-weight: 400;
        line-height: 65px;
        letter-spacing: -0.025em;
      }

      .small_descrip {
        font-family: Nunito;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
      }

      .small_text_container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));

        gap: 1rem;

        .img_text {
          width: 100%;

          gap: 0.8rem;

          img {
            width: 2rem;
            height: 2rem;
          }
        }
      }

      .ActionButton {
        gap: 20px;
        max-width: 31rem;
        width: 100%;

        .Download-Button-One,
        .Download-Button-Two {
          width: 100%;
          height: 64px;
          a {
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .img_container {
      img {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 1094px) {
    padding-bottom: 7rem;

    .right_circle_design {
      height: 138px;
      width: 130px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .left_bottom_circle_design {
      display: none;
    }

    .left_bottom_circle_design_mobile {
      display: block;
      height: 120px;
      width: 120px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media screen and (max-width: 1020px) {
    .description_container {
      order: 1;
    }

    .img_container {
      order: 0;
    }
  }
`;

export const GridPitchContainer = styled.section`
  width: 100%;
  position: relative;
  padding-bottom: 12rem;

  .circle_design_grid_pitch {
    position: absolute;
    left: 0;
    bottom: -0.6rem;
  }

  .main_container {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    margin-top: 5rem;

    .section_container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));

      .section-item {
        border-right: 2px solid #e4e1ea;
        border-bottom: 2px solid #e4e1ea;
        position: relative;

        gap: 1rem;
        padding: 2rem;

        .top_left_star {
          position: absolute;
          top: 0;
          left: 0;
          margin-left: -0.5rem;
          margin-top: -0.7rem;
        }

        .top_right_star {
          position: absolute;
          top: 0;
          right: 0;
          margin-right: -0.6rem;
          margin-top: -0.7rem;
        }

        .bottom_right_star {
          position: absolute;
          bottom: 0;
          right: 0;
          margin-right: -0.6rem;
          margin-bottom: -0.7rem;
          z-index: 1;
        }

        .bottom_left_star {
          position: absolute;
          bottom: 0;
          left: 0;
          margin-left: -0.5rem;
          margin-bottom: -0.7rem;
        }

        h3 {
          font-family: Nunito;
          font-size: 1.55rem;
          font-weight: 500;
          line-height: 33px;
          letter-spacing: 0em;
        }

        p {
          font-family: Nunito;
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }
  }

  @media screen and (max-width: 1094px) {
    padding-bottom: 1rem;
    .circle_design_grid_pitch {
      display: none;
    }
  }
`;
