import React, { useEffect, useRef, useState } from 'react';
import { FaLocationDot } from 'react-icons/fa6';
import styled from 'styled-components';

const LocationMark = ({ location, setLocation }) => {
  const inputRef = useRef();
  let service;
  const [locationList, setLocationList] = useState([]);
  const [borderOff, setBorderOff] = useState(false);

  // My styled
  const displaySuggestions = (predictions, status) => {
    setLocationList([]);
    let items = [];

    if (status == google.maps.places.PlacesServiceStatus.OK) {
      predictions.forEach((prediction) => {
        console.log(prediction.description, 'Hello');
        items.push(prediction.description);
      });

      setLocationList(items);
      setBorderOff(true);
    }
  };

  const getAutoComplete = () => {
    let qs = { input: `${location}` };
    service = new google.maps.places.AutocompleteService();
    service.getQueryPredictions(qs, displaySuggestions);
  };

  useEffect(() => {
    service = new google.maps.places.AutocompleteService();
  }, []);

  return (
    <LocationMarkContainer showBorder={borderOff}>
      <div className="input_section">
        <FaLocationDot />
        <input
          id="autocomplete"
          ref={inputRef}
          value={location}
          type="text"
          placeholder="Enter location"
          onChange={(e) => {
            setLocation(e.target.value);
            getAutoComplete();
          }}
        />
      </div>

      {locationList !== [] && location !== '' && (
        <div className="suggestions">
          {locationList.map((local, index) => (
            <div
              className="location_item"
              key={index}
              onClick={() => {
                setLocation(local);

                setLocationList([]);
                setBorderOff(false);
              }}
            >
              <FaLocationDot />
              <p>{local}</p>
            </div>
          ))}
        </div>
      )}
    </LocationMarkContainer>
  );
};

export default LocationMark;

const LocationMarkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  .input_section {
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }

  .suggestions {
    padding: 0.5rem;
    display: ${({ showBorder }) => (showBorder ? 'flex' : 'none')};
    flex-direction: column;
    gap: 0.7rem;
    box-shadow: ${({ showBorder }) =>
      showBorder
        ? 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
        : ''};

    .location_item {
      display: flex;
      align-items: center;
      gap: 0.3rem;
      line-break: normal;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
      svg,
      p {
        font-size: 0.7rem;
      }
    }
  }
`;
