import React from 'react';
import { useTranslation } from 'react-i18next';
import { BannerContainer, BannerWrapper } from './Header.styles';
import Star from '../../../assest/images/Star1.svg';
import PlayStore from '../../../assest/images/playstore.png';
import AppleStore from '../../../assest/images/applestore.png';
import BottomCircle from '../../../assest/images/home_banner_down_circle.png';
import TopCircle from '../../../assest/images/homepage_top_circle.png';

import TopCircleMobile from '../../../assest/images/mobile_nav_cicle.png';

function Banner() {
  const { t } = useTranslation(['banner']);
  return (
    <>
      <BannerContainer className="flex column home-fam" id="homeTop">
        <div className="circle_design">
          <img src={BottomCircle} alt="circle design " />
        </div>

        <div className="first_circle">
          <img src={TopCircle} alt="Cicle here" />
        </div>

        <div className="first_circle_mobile">
          <img src={TopCircleMobile} alt="Cicle here" />
        </div>

        <BannerWrapper className="flex column a-center">
          <span className="Star">
            <img src={Star} alt="Star icon" />
          </span>
          <span className="Star2">
            <img src={Star} alt="Star icon" />
          </span>
          <div className="Banner-Text flex column t-center">
            <h1>{t('Your Social Calendar Revolution')}</h1>
            <p>
              {t(
                `Unlock a vibrant world where the calendar is not just a tool but a community hub. With Lectie, experience the innovative intersection of scheduling and social connectivity. Dive into an ecosystem where every date is an invitation to connect, engage, and celebrate together.`
              )}
            </p>

            <div className="ActionButton flex">
              <div className="Download-Button-One">
                <a
                  href="https://play.google.com/store/apps/details?id=com.lectie.vault"
                  className="button-img-container"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Google Play Store"
                >
                  {' '}
                  <img src={PlayStore} alt="download playstore" />{' '}
                </a>
              </div>

              <div className="Download-Button-Two">
                <a
                  href="https://apps.apple.com/ng/app/lectie-vault/id1670873307"
                  className="button-img-container"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Apple Store"
                >
                  {' '}
                  <img src={AppleStore} alt="download apple store" />{' '}
                </a>
              </div>
            </div>

            <a
              href="/dashboard/"
              target="_blank"
              rel="noreferrer"
              className="btn_started_banner"
            >
              {t('Create')}
            </a>
          </div>

          <div className="Image-Showcase">
            <div className="Image-Showcase-inner flex a-center">
              <iframe
                src="https://www.youtube.com/embed/cAwOxWAgzd4"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullscreen
              ></iframe>
            </div>
          </div>
        </BannerWrapper>
      </BannerContainer>
    </>
  );
}

export default Banner;
