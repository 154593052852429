import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import AWS from 'aws-sdk';
import uuid from 'react-uuid';
import client from '../services';
import { setCalendarEvent } from '../store/features/calendarSlice';
import { setShowEventModel } from '../store/features/modalSlice';
import { useDispatch } from 'react-redux';
const { countries, zones } = require('moment-timezone/data/meta/latest.json');

// Convert date
function convertDate(date) {
  var event = dayjs(date).format(`YYYYMMDDTHHmmss`);
  return event;
}

//Check if it is a valid Youtube URL
function isValidYoutubeURL(url) {
  // Check if the URL starts with "http://" or "https://".
  if (!/^https?:\/\//.test(url)) {
    return false;
  }

  // Check if the URL contains "youtube.com" or "youtu.be".
  if (!/youtube\.com|youtu\.be/.test(url)) {
    return false;
  }

  // Check if the URL contains a "v=" parameter.
  if (!/\b(v=)\b/.test(url)) {
    return false;
  }

  // Return true if the URL passes all of the above checks.
  return true;
}
// Save event to calendar
export const saveCalendarEvent = (event) => {
  try {
    // Create the .ics URL
    let url = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      'DTSTART:' + convertDate(event?.startTime),
      'DTEND:' + convertDate(event?.endTime),
      'SUMMARY:' + event?.title,
      'DESCRIPTION:' + event?.description,
      'BEGIN:VALARM',
      'TRIGGER:-PT15M',
      'REPEAT:1',
      'DURATION:PT15M',
      'ACTION:DISPLAY',
      'DESCRIPTION:Reminder',
      'END:VALARM',
      'END:VEVENT',
      'END:VCALENDAR',
    ].join('\n');

    let a = document.createElement('a');
    a.download = 'event.ics';
    a.href = encodeURI('data:text/calendar;charset=utf8,' + url);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    return [true, 'Event request sucessfully'];
  } catch (e) {
    return [false, 'Please try again, an error has occur'];
  }
};

// Multiple Events Functions
export const saveMultipleEvent = (events) => {
  var SEPARATOR = '\n';
  var calendarEvents = [];
  var calendarStart = ['BEGIN:VCALENDAR', 'VERSION:2.0'].join(SEPARATOR);
  var calendarEnd = SEPARATOR + 'END:VCALENDAR';
  try {
    events.map((event) =>
      calendarEvents.push(
        [
          'BEGIN:VEVENT',
          'DTSTART:' + convertDate(event?.startTime),
          'DTEND:' + convertDate(event?.endTime),
          'SUMMARY:' + event?.title,
          'DESCRIPTION:' + event?.description,
          'BEGIN:VALARM',
          'TRIGGER:-PT15M',
          'REPEAT:1',
          'DURATION:PT15M',
          'ACTION:DISPLAY',
          'DESCRIPTION:Reminder',
          'END:VALARM',
          'END:VEVENT',
        ].join(SEPARATOR)
      )
    );
    var calendar =
      calendarStart + SEPARATOR + calendarEvents.join(SEPARATOR) + calendarEnd;
    // console.log(calendar);

    let a = document.createElement('a');
    a.download = 'events.ics';
    a.href = encodeURI('data:text/calendar;charset=utf8,' + calendar);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    return toast.success('Event request successfull', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'colored',
    });
  } catch (e) {
    return toast.error('Please try again, an error has occured', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'colored',
    });
  }
};

// Copy to clipboard
export const copy = (text) => {
  navigator.clipboard.writeText(text);
};

// Image functions

// Preview

const TO_RADIANS = Math.PI / 180;

export async function canvasPreview(
  image,
  canvas,
  crop,
  scale = 1,
  rotate = 0
) {
  // console.log(canvas,"canvas")
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  // const pixelRatio = window.devicePixelRatio
  const pixelRatio = 1;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
}
// Extract an Base64 Image's File Extension
export function extractImageFileExtensionFromBase64(base64Data) {
  return base64Data.substring(
    'data:image/'.length,
    base64Data.indexOf(';base64')
  );
}
// S3 bucket upload
AWS.config.update({
  accessKeyId: 'AKIAVQIR4XVP67SGJEXZ',
  secretAccessKey: '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',
});

const bucket = new AWS.S3({
  params: { Bucket: 'bespoke-image' },
  region: 'ap-southeast-2',
});
export const UploadImageToS3 = async (source) => {
  // Conver source
  // var jpegFile64 = source.replace(/^data:image\/(png|jpeg);base64,/, "");
  // var mime = mime || 'image/jpeg';
  // var sliceSize = 1024;
  // var byteChars = window.atob(source);
  // var byteArrays = [];

  // for (var offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
  //     var slice = byteChars.slice(offset, offset + sliceSize);

  //     var byteNumbers = new Array(slice.length);
  //     for (var i = 0; i < slice.length; i++) {
  //         byteNumbers[i] = slice.charCodeAt(i);
  //     }

  //     var byteArray = new Uint8Array(byteNumbers);

  //     byteArrays.push(byteArray);
  // }

  // const mimeImag =  new Blob(byteArrays, {type: mime});
  const params = {
    ACL: 'public-read',
    Body: source,
    Bucket: 'bespoke-image',
    Key: uuid(),
    ContentEncoding: 'base64',
    ContentType: 'image/jpeg',
  };
  return new Promise((resolve, reject) => {
    bucket.upload(params, function (err, data) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
};

export const seizer = (width, height) => {
  if (width < 1300 && height < 1300) {
    return undefined;
  } else if (height > width) {
    return 16 / 15;
  } else {
    return 16 / 8;
  }
};

// Time Diffrence
export const timeDiff = (timeStamp) => {
  const options = [
    {
      season: 'y',
      shows: 'yrs',
      show: 'yr',
    },
    {
      season: 'M',
      shows: 'mons',
      show: 'mon',
    },
    {
      season: 'w',
      shows: 'wks',
      show: 'wk',
    },
    {
      season: 'd',
      shows: 'days',
      show: 'day',
    },
    {
      season: 'h',
      shows: 'hrs',
      show: 'hr',
    },
    {
      season: 'm',
      shows: 'mins',
      show: 'min',
    },
    {
      season: 's',
      shows: 'secs',
      show: 'sec',
    },
  ];

  // Get current Date
  let currentDate = dayjs(new Date());
  let noticeDate = dayjs(timeStamp);

  // Loop through to return at not undefind

  for (let val of options) {
    let diff = currentDate.diff(noticeDate, val?.season);

    let seasonDesc = diff > 1 ? val?.shows : val?.show;
    if (diff >= 1) return `${diff}${seasonDesc}`;
  }
};

export const handleError = (formData) => {
  let isPass = true;
  let failValue = [];

  Object.entries(formData).forEach(([key, val]) => {
    if (val === '' || val === ' ' || val === null) {
      failValue.push(key);
      isPass = false;
    }
  });

  return isPass === true ? ['success'] : [isPass, failValue];
};

export const getDigitInUnit = (digit = 7456219800) => {
  digit = digit.toString().split('');

  let threesArr = [];
  let digitArr = [];
  let result = '';

  for (let i = digit.length - 1; i >= 0; i -= 1) {
    threesArr.push(digit[i]);
    if (threesArr.length === 3 || i === 0) {
      digitArr.push(threesArr.reverse());
      threesArr = [];
    }
  }
  digitArr.reverse();

  for (let i = 0; i < digitArr.length; i += 1) {
    let val =
      i === digitArr.length - 1
        ? digitArr[i].join('')
        : digitArr[i].join('') + ',';
    result += val;
  }
  return result;
};

// Email Invitation function

export const emailInvite = (to, subject, message, setErrorAlert) => {
  if (to.length === 0) {
    setErrorAlert('Please add at least one email to proceed');
    return;
  }
  let emailString =
    typeof to == 'string' ? to?.replace(/,*$/, '')?.trim() : to?.join();

  let txt = `
  mailto:${emailString}?subject=${subject}&body=${message}
  `;
  const link = document.createElement('a');
  link.href = txt;
  link.click();
};

// Update create Event Backend
export const updateEvent = async (
  eventId,
  bespokeId,
  objData,
  funcDispatch,
  setIsFormLoading
) => {
  setIsFormLoading(true);
  await client
    .put(
      `/event/updatePublicEvent?eventId=${eventId}&souvenirId=${bespokeId}`,
      objData
    )
    .then((res) => {
      funcDispatch();
      setIsFormLoading(false);

      console.log(res?.data, ' this is rrsposnse from edi === ');
    })
    .catch((err) => {
      toast.error(err?.response?.data?.Message ?? err?.message);
      setIsFormLoading(false);
    });
};

export const updateEventRecurring = async (
  funcDispatch,
  setNewEventObject,
  setIsFormLoading,
  newEventObject,
  { bespokeId, commonId, evtDate } = {}
) => {
  setIsFormLoading(true);
  console.log(newEventObject?.events[0], 'Event sent to BE');
  console.log(
    newEventObject,
    bespokeId,
    commonId,
    evtDate,
    'Event update occur Data'
  );
  await client
    .put(
      `/event/updatePublicEvent?souvenirId=${bespokeId}&` +
        `commonId=${commonId}` +
        `${evtDate ? `&date=${evtDate}` : ''}`,
      newEventObject?.events[0]
    )
    .then((res) => {
      console.log(res?.data, 'Recurr respone');
      funcDispatch();
      setNewEventObject({ ...newEventObject, events: [{ ...res?.date }] });
      setIsFormLoading(false);
    })
    .catch((err) => {
      toast.error(err?.response?.data?.Message ?? err?.message);
      setIsFormLoading(false);
    });
};

// Delete create Event Backend
export const deleteEvent = async (eventId, funcDispatch, setIsFormLoading) => {
  setIsFormLoading(true);
  await client
    .delete(`/event/deleteBespokeEvent?eventId=${eventId}`)
    .then((res) => {
      funcDispatch();
      setIsFormLoading(false);
    })
    .catch((err) => {
      toast.error(err?.response?.data?.Message ?? err?.message);
      setIsFormLoading(false);
    });
};

export const deleteEventRecurring = async (
  funcDispatch,
  setIsFormLoading,
  { bespokeId, commonId, evtDate } = {}
) => {
  setIsFormLoading(true);

  await client
    .delete(
      `/event/deleteBespokeEvent?souvenirId=${bespokeId}&` +
        `commonId=${commonId}` +
        `${evtDate ? `&date=${evtDate}` : ''}`
    )
    .then((res) => {
      console.log(res?.data, 'Recurr respone');
      funcDispatch();

      setIsFormLoading(false);
    })
    .catch((err) => {
      toast.error(err?.response?.data?.Message ?? err?.message);
      setIsFormLoading(false);
    });
};

// Get User Country

export class userGeolocation {
  constructor() {
    this.timeZoneCityToCountry = {};
    Object.keys(zones)?.forEach((z) => {
      const cityArr = z?.split('/');
      const city = cityArr[cityArr?.length - 1];
      this.timeZoneCityToCountry[city] =
        countries[zones[z]?.countries[0]]?.name;
    });

    this.userRegion = null;
    this.userCity = null;
    this.userCountry = null;
    this.userTimeZone = null;

    if (Intl) {
      this.userTimeZone = Intl.DateTimeFormat().resolvedOptions()?.timeZone;
      const tzArr = this.userTimeZone?.split('/');
      this.userRegion = tzArr[0];
      this.userCity = tzArr[tzArr.length - 1];
      this.userCountry = this.timeZoneCityToCountry[this.userCity];
    }
  }

  getUserCountry() {
    return this.userCountry;
  }

  getUserRegion() {
    return this.userRegion;
  }

  getUserTimezone() {
    return this.userTimeZone;
  }

  getUserCity() {
    return this.userCity;
  }
}

export const getCountyCode = (county) => {
  const countyCode = {
    'New Zealand': {
      CountryCode: 'NZ',
      Currency: 'New Zealand Dollars',
      Code: 'NZD',
    },
    'Cook Islands': {
      CountryCode: 'CK',
      Currency: 'New Zealand Dollars',
      Code: 'NZD',
    },
    Niue: {
      CountryCode: 'NU',
      Currency: 'New Zealand Dollars',
      Code: 'NZD',
    },
    Pitcairn: {
      CountryCode: 'PN',
      Currency: 'New Zealand Dollars',
      Code: 'NZD',
    },
    Tokelau: {
      CountryCode: 'TK',
      Currency: 'New Zealand Dollars',
      Code: 'NZD',
    },
    Australian: {
      CountryCode: 'AU',
      Currency: 'Australian Dollars',
      Code: 'AUD',
    },
    'Christmas Island': {
      CountryCode: 'CX',
      Currency: 'Australian Dollars',
      Code: 'AUD',
    },
    'Cocos (Keeling) Islands': {
      CountryCode: 'CC',
      Currency: 'Australian Dollars',
      Code: 'AUD',
    },
    'Heard and Mc Donald Islands': {
      CountryCode: 'HM',
      Currency: 'Australian Dollars',
      Code: 'AUD',
    },
    Kiribati: {
      CountryCode: 'KI',
      Currency: 'Australian Dollars',
      Code: 'AUD',
    },
    Nauru: {
      CountryCode: 'NR',
      Currency: 'Australian Dollars',
      Code: 'AUD',
    },
    'Norfolk Island': {
      CountryCode: 'NF',
      Currency: 'Australian Dollars',
      Code: 'AUD',
    },
    Tuvalu: {
      CountryCode: 'TV',
      Currency: 'Australian Dollars',
      Code: 'AUD',
    },
    'American Samoa': {
      CountryCode: 'AS',
      Currency: 'Euros',
      Code: 'EUR',
    },
    Andorra: {
      CountryCode: 'AD',
      Currency: 'Euros',
      Code: 'EUR',
    },
    Austria: {
      CountryCode: 'AT',
      Currency: 'Euros',
      Code: 'EUR',
    },
    Belgium: {
      CountryCode: 'BE',
      Currency: 'Euros',
      Code: 'EUR',
    },
    Finland: {
      CountryCode: 'FI',
      Currency: 'Euros',
      Code: 'EUR',
    },
    France: {
      CountryCode: 'FR',
      Currency: 'Euros',
      Code: 'EUR',
    },
    'French Guiana': {
      CountryCode: 'GF',
      Currency: 'Euros',
      Code: 'EUR',
    },
    'French Southern Territories': {
      CountryCode: 'TF',
      Currency: 'Euros',
      Code: 'EUR',
    },
    Germany: {
      CountryCode: 'DE',
      Currency: 'Euros',
      Code: 'EUR',
    },
    Greece: {
      CountryCode: 'GR',
      Currency: 'Euros',
      Code: 'EUR',
    },
    Guadeloupe: {
      CountryCode: 'GP',
      Currency: 'Euros',
      Code: 'EUR',
    },
    Ireland: {
      CountryCode: 'IE',
      Currency: 'Euros',
      Code: 'EUR',
    },
    Italy: {
      CountryCode: 'IT',
      Currency: 'Euros',
      Code: 'EUR',
    },
    Luxembourg: {
      CountryCode: 'LU',
      Currency: 'Euros',
      Code: 'EUR',
    },
    Martinique: {
      CountryCode: 'MQ',
      Currency: 'Euros',
      Code: 'EUR',
    },
    Mayotte: {
      CountryCode: 'YT',
      Currency: 'Euros',
      Code: 'EUR',
    },
    Monaco: {
      CountryCode: 'MC',
      Currency: 'Euros',
      Code: 'EUR',
    },
    Netherlands: {
      CountryCode: 'NL',
      Currency: 'Euros',
      Code: 'EUR',
    },
    Portugal: {
      CountryCode: 'PT',
      Currency: 'Euros',
      Code: 'EUR',
    },
    Reunion: {
      CountryCode: 'RE',
      Currency: 'Euros',
      Code: 'EUR',
    },
    Samoa: {
      CountryCode: 'WS',
      Currency: 'Euros',
      Code: 'EUR',
    },
    'San Marino': {
      CountryCode: 'SM',
      Currency: 'Euros',
      Code: 'EUR',
    },
    Slovenia: {
      CountryCode: 'SI',
      Currency: 'Euros',
      Code: 'EUR',
    },
    Spain: {
      CountryCode: 'ES',
      Currency: 'Euros',
      Code: 'EUR',
    },
    'Vatican City State (Holy See)': {
      CountryCode: 'VA',
      Currency: 'Euros',
      Code: 'EUR',
    },
    'South Georgia and the South Sandwich Islands': {
      CountryCode: 'GS',
      Currency: 'Sterling',
      Code: 'GBP',
    },
    'United Kingdom': {
      CountryCode: 'GB',
      Currency: 'Sterling',
      Code: 'GBP',
    },
    Jersey: {
      CountryCode: 'JE',
      Currency: 'Sterling',
      Code: 'GBP',
    },
    'British Indian Ocean Territory': {
      CountryCode: 'IO',
      Currency: 'USD',
      Code: 'USD',
    },
    Guam: {
      CountryCode: 'GU',
      Currency: 'USD',
      Code: 'USD',
    },
    'Marshall Islands': {
      CountryCode: 'MH',
      Currency: 'USD',
      Code: 'USD',
    },
    'Micronesia Federated States of': {
      CountryCode: 'FM',
      Currency: 'USD',
      Code: 'USD',
    },
    'Northern Mariana Islands': {
      CountryCode: 'MP',
      Currency: 'USD',
      Code: 'USD',
    },
    Palau: {
      CountryCode: 'PW',
      Currency: 'USD',
      Code: 'USD',
    },
    'Puerto Rico': {
      CountryCode: 'PR',
      Currency: 'USD',
      Code: 'USD',
    },
    'Turks and Caicos Islands': {
      CountryCode: 'TC',
      Currency: 'USD',
      Code: 'USD',
    },
    'United States': {
      CountryCode: 'US',
      Currency: 'USD',
      Code: 'USD',
    },
    'United States Minor Outlying Islands': {
      CountryCode: 'UM',
      Currency: 'USD',
      Code: 'USD',
    },
    'Virgin Islands (British)': {
      CountryCode: 'VG',
      Currency: 'USD',
      Code: 'USD',
    },
    'Virgin Islands (US)': {
      CountryCode: 'VI',
      Currency: 'USD',
      Code: 'USD',
    },
    'Hong Kong': {
      CountryCode: 'HK',
      Currency: 'HKD',
      Code: 'HKD',
    },
    Canada: {
      CountryCode: 'CA',
      Currency: 'Canadian Dollar',
      Code: 'CAD',
    },
    Japan: {
      CountryCode: 'JP',
      Currency: 'Japanese Yen',
      Code: 'JPY',
    },
    Afghanistan: {
      CountryCode: 'AF',
      Currency: 'Afghani',
      Code: 'AFN',
    },
    Albania: {
      CountryCode: 'AL',
      Currency: 'Lek',
      Code: 'ALL',
    },
    Algeria: {
      CountryCode: 'DZ',
      Currency: 'Algerian Dinar',
      Code: 'DZD',
    },
    Anguilla: {
      CountryCode: 'AI',
      Currency: 'East Caribbean Dollar',
      Code: 'XCD',
    },
    'Antigua and Barbuda': {
      CountryCode: 'AG',
      Currency: 'East Caribbean Dollar',
      Code: 'XCD',
    },
    Dominica: {
      CountryCode: 'DM',
      Currency: 'East Caribbean Dollar',
      Code: 'XCD',
    },
    Grenada: {
      CountryCode: 'GD',
      Currency: 'East Caribbean Dollar',
      Code: 'XCD',
    },
    Montserrat: {
      CountryCode: 'MS',
      Currency: 'East Caribbean Dollar',
      Code: 'XCD',
    },
    'Saint Kitts': {
      CountryCode: 'KN',
      Currency: 'East Caribbean Dollar',
      Code: 'XCD',
    },
    'Saint Lucia': {
      CountryCode: 'LC',
      Currency: 'East Caribbean Dollar',
      Code: 'XCD',
    },
    'Saint Vincent Grenadines': {
      CountryCode: 'VC',
      Currency: 'East Caribbean Dollar',
      Code: 'XCD',
    },
    Argentina: {
      CountryCode: 'AR',
      Currency: 'Peso',
      Code: 'ARS',
    },
    Armenia: {
      CountryCode: 'AM',
      Currency: 'Dram',
      Code: 'AMD',
    },
    Aruba: {
      CountryCode: 'AW',
      Currency: 'Netherlands Antilles Guilder',
      Code: 'ANG',
    },
    'Netherlands Antilles': {
      CountryCode: 'AN',
      Currency: 'Netherlands Antilles Guilder',
      Code: 'ANG',
    },
    Azerbaijan: {
      CountryCode: 'AZ',
      Currency: 'Manat',
      Code: 'AZN',
    },
    Bahamas: {
      CountryCode: 'BS',
      Currency: 'Bahamian Dollar',
      Code: 'BSD',
    },
    Bahrain: {
      CountryCode: 'BH',
      Currency: 'Bahraini Dinar',
      Code: 'BHD',
    },
    Bangladesh: {
      CountryCode: 'BD',
      Currency: 'Taka',
      Code: 'BDT',
    },
    Barbados: {
      CountryCode: 'BB',
      Currency: 'Barbadian Dollar',
      Code: 'BBD',
    },
    Belarus: {
      CountryCode: 'BY',
      Currency: 'Belarus Ruble',
      Code: 'BYR',
    },
    Belize: {
      CountryCode: 'BZ',
      Currency: 'Belizean Dollar',
      Code: 'BZD',
    },
    Benin: {
      CountryCode: 'BJ',
      Currency: 'CFA Franc BCEAO',
      Code: 'XOF',
    },
    'Burkina Faso': {
      CountryCode: 'BF',
      Currency: 'CFA Franc BCEAO',
      Code: 'XOF',
    },
    'Guinea-Bissau': {
      CountryCode: 'GW',
      Currency: 'CFA Franc BCEAO',
      Code: 'XOF',
    },
    'Ivory Coast': {
      CountryCode: 'CI',
      Currency: 'CFA Franc BCEAO',
      Code: 'XOF',
    },
    Mali: {
      CountryCode: 'ML',
      Currency: 'CFA Franc BCEAO',
      Code: 'XOF',
    },
    Niger: {
      CountryCode: 'NE',
      Currency: 'CFA Franc BCEAO',
      Code: 'XOF',
    },
    Senegal: {
      CountryCode: 'SN',
      Currency: 'CFA Franc BCEAO',
      Code: 'XOF',
    },
    Togo: {
      CountryCode: 'TG',
      Currency: 'CFA Franc BCEAO',
      Code: 'XOF',
    },
    Bermuda: {
      CountryCode: 'BM',
      Currency: 'Bermudian Dollar',
      Code: 'BMD',
    },
    Bhutan: {
      CountryCode: 'BT',
      Currency: 'Indian Rupee',
      Code: 'INR',
    },
    India: {
      CountryCode: 'IN',
      Currency: 'Indian Rupee',
      Code: 'INR',
    },
    Bolivia: {
      CountryCode: 'BO',
      Currency: 'Boliviano',
      Code: 'BOB',
    },
    Botswana: {
      CountryCode: 'BW',
      Currency: 'Pula',
      Code: 'BWP',
    },
    'Bouvet Island': {
      CountryCode: 'BV',
      Currency: 'Norwegian Krone',
      Code: 'NOK',
    },
    Norway: {
      CountryCode: 'NO',
      Currency: 'Norwegian Krone',
      Code: 'NOK',
    },
    'Svalbard and Jan Mayen Islands': {
      CountryCode: 'SJ',
      Currency: 'Norwegian Krone',
      Code: 'NOK',
    },
    Brazil: {
      CountryCode: 'BR',
      Currency: 'Brazil',
      Code: 'BRL',
    },
    'Brunei Darussalam': {
      CountryCode: 'BN',
      Currency: 'Bruneian Dollar',
      Code: 'BND',
    },
    Bulgaria: {
      CountryCode: 'BG',
      Currency: 'Lev',
      Code: 'BGN',
    },
    Burundi: {
      CountryCode: 'BI',
      Currency: 'Burundi Franc',
      Code: 'BIF',
    },
    Cambodia: {
      CountryCode: 'KH',
      Currency: 'Riel',
      Code: 'KHR',
    },
    Cameroon: {
      CountryCode: 'CM',
      Currency: 'CFA Franc BEAC',
      Code: 'XAF',
    },
    'Central African Republic': {
      CountryCode: 'CF',
      Currency: 'CFA Franc BEAC',
      Code: 'XAF',
    },
    Chad: {
      CountryCode: 'TD',
      Currency: 'CFA Franc BEAC',
      Code: 'XAF',
    },
    'Congo Republic of the Democratic': {
      CountryCode: 'CG',
      Currency: 'CFA Franc BEAC',
      Code: 'XAF',
    },
    'Equatorial Guinea': {
      CountryCode: 'GQ',
      Currency: 'CFA Franc BEAC',
      Code: 'XAF',
    },
    Gabon: {
      CountryCode: 'GA',
      Currency: 'CFA Franc BEAC',
      Code: 'XAF',
    },
    'Cape Verde': {
      CountryCode: 'CV',
      Currency: 'Escudo',
      Code: 'CVE',
    },
    'Cayman Islands': {
      CountryCode: 'KY',
      Currency: 'Caymanian Dollar',
      Code: 'KYD',
    },
    Chile: {
      CountryCode: 'CL',
      Currency: 'Chilean Peso',
      Code: 'CLP',
    },
    China: {
      CountryCode: 'CN',
      Currency: 'Yuan Renminbi',
      Code: 'CNY',
    },
    Colombia: {
      CountryCode: 'CO',
      Currency: 'Peso',
      Code: 'COP',
    },
    Comoros: {
      CountryCode: 'KM',
      Currency: 'Comoran Franc',
      Code: 'KMF',
    },
    'Britain (UK)': {
      CountryCode: 'GB',
      Currency: 'Sterling',
      Code: 'GBP',
    },
    'Congo-Brazzaville': {
      CountryCode: 'CD',
      Currency: 'Congolese Frank',
      Code: 'CDF',
    },
    'Costa Rica': {
      CountryCode: 'CR',
      Currency: 'Costa Rican Colon',
      Code: 'CRC',
    },
    'Croatia (Hrvatska)': {
      CountryCode: 'HR',
      Currency: 'Croatian Dinar',
      Code: 'HRK',
    },
    Cuba: {
      CountryCode: 'CU',
      Currency: 'Cuban Peso',
      Code: 'CUP',
    },
    Cyprus: {
      CountryCode: 'CY',
      Currency: 'Cypriot Pound',
      Code: 'CYP',
    },
    'Czech Republic': {
      CountryCode: 'CZ',
      Currency: 'Koruna',
      Code: 'CZK',
    },
    Denmark: {
      CountryCode: 'DK',
      Currency: 'Danish Krone',
      Code: 'DKK',
    },
    'Faroe Islands': {
      CountryCode: 'FO',
      Currency: 'Danish Krone',
      Code: 'DKK',
    },
    Greenland: {
      CountryCode: 'GL',
      Currency: 'Danish Krone',
      Code: 'DKK',
    },
    Djibouti: {
      CountryCode: 'DJ',
      Currency: 'Djiboutian Franc',
      Code: 'DJF',
    },
    'Dominican Republic': {
      CountryCode: 'DO',
      Currency: 'Dominican Peso',
      Code: 'DOP',
    },
    'East Timor': {
      CountryCode: 'TP',
      Currency: 'Indonesian Rupiah',
      Code: 'IDR',
    },
    Indonesia: {
      CountryCode: 'ID',
      Currency: 'Indonesian Rupiah',
      Code: 'IDR',
    },
    Ecuador: {
      CountryCode: 'EC',
      Currency: 'Sucre',
      Code: 'ECS',
    },
    Egypt: {
      CountryCode: 'EG',
      Currency: 'Egyptian Pound',
      Code: 'EGP',
    },
    'El Salvador': {
      CountryCode: 'SV',
      Currency: 'Salvadoran Colon',
      Code: 'SVC',
    },
    Eritrea: {
      CountryCode: 'ER',
      Currency: 'Ethiopian Birr',
      Code: 'ETB',
    },
    Ethiopia: {
      CountryCode: 'ET',
      Currency: 'Ethiopian Birr',
      Code: 'ETB',
    },
    Estonia: {
      CountryCode: 'EE',
      Currency: 'Estonian Kroon',
      Code: 'EEK',
    },
    'Falkland Islands (Malvinas)': {
      CountryCode: 'FK',
      Currency: 'Falkland Pound',
      Code: 'FKP',
    },
    Fiji: {
      CountryCode: 'FJ',
      Currency: 'Fijian Dollar',
      Code: 'FJD',
    },
    'French Polynesia': {
      CountryCode: 'PF',
      Currency: 'CFP Franc',
      Code: 'XPF',
    },
    'New Caledonia': {
      CountryCode: 'NC',
      Currency: 'CFP Franc',
      Code: 'XPF',
    },
    'Wallis and Futuna Islands': {
      CountryCode: 'WF',
      Currency: 'CFP Franc',
      Code: 'XPF',
    },
    Gambia: {
      CountryCode: 'GM',
      Currency: 'Dalasi',
      Code: 'GMD',
    },
    Georgia: {
      CountryCode: 'GE',
      Currency: 'Lari',
      Code: 'GEL',
    },
    Gibraltar: {
      CountryCode: 'GI',
      Currency: 'Gibraltar Pound',
      Code: 'GIP',
    },
    Guatemala: {
      CountryCode: 'GT',
      Currency: 'Quetzal',
      Code: 'GTQ',
    },
    Guinea: {
      CountryCode: 'GN',
      Currency: 'Guinean Franc',
      Code: 'GNF',
    },
    Guyana: {
      CountryCode: 'GY',
      Currency: 'Guyanaese Dollar',
      Code: 'GYD',
    },
    Haiti: {
      CountryCode: 'HT',
      Currency: 'Gourde',
      Code: 'HTG',
    },
    Honduras: {
      CountryCode: 'HN',
      Currency: 'Lempira',
      Code: 'HNL',
    },
    Hungary: {
      CountryCode: 'HU',
      Currency: 'Forint',
      Code: 'HUF',
    },
    Iceland: {
      CountryCode: 'IS',
      Currency: 'Icelandic Krona',
      Code: 'ISK',
    },
    'Iran (Islamic Republic of)': {
      CountryCode: 'IR',
      Currency: 'Iranian Rial',
      Code: 'IRR',
    },
    Iraq: {
      CountryCode: 'IQ',
      Currency: 'Iraqi Dinar',
      Code: 'IQD',
    },
    Israel: {
      CountryCode: 'IL',
      Currency: 'Shekel',
      Code: 'ILS',
    },
    Jamaica: {
      CountryCode: 'JM',
      Currency: 'Jamaican Dollar',
      Code: 'JMD',
    },
    Jordan: {
      CountryCode: 'JO',
      Currency: 'Jordanian Dinar',
      Code: 'JOD',
    },
    Kazakhstan: {
      CountryCode: 'KZ',
      Currency: 'Tenge',
      Code: 'KZT',
    },
    Kenya: {
      CountryCode: 'KE',
      Currency: 'Kenyan Shilling',
      Code: 'KES',
    },
    'Korea North': {
      CountryCode: 'KP',
      Currency: 'Won',
      Code: 'KPW',
    },
    'Korea South': {
      CountryCode: 'KR',
      Currency: 'Won',
      Code: 'KRW',
    },
    Kuwait: {
      CountryCode: 'KW',
      Currency: 'Kuwaiti Dinar',
      Code: 'KWD',
    },
    Kyrgyzstan: {
      CountryCode: 'KG',
      Currency: 'Som',
      Code: 'KGS',
    },
    'Lao PeopleÕs Democratic Republic': {
      CountryCode: 'LA',
      Currency: 'Kip',
      Code: 'LAK',
    },
    Latvia: {
      CountryCode: 'LV',
      Currency: 'Lat',
      Code: 'LVL',
    },
    Lebanon: {
      CountryCode: 'LB',
      Currency: 'Lebanese Pound',
      Code: 'LBP',
    },
    Lesotho: {
      CountryCode: 'LS',
      Currency: 'Loti',
      Code: 'LSL',
    },
    Liberia: {
      CountryCode: 'LR',
      Currency: 'Liberian Dollar',
      Code: 'LRD',
    },
    'Libyan Arab Jamahiriya': {
      CountryCode: 'LY',
      Currency: 'Libyan Dinar',
      Code: 'LYD',
    },
    Liechtenstein: {
      CountryCode: 'LI',
      Currency: 'Swiss Franc',
      Code: 'CHF',
    },
    Switzerland: {
      CountryCode: 'CH',
      Currency: 'Swiss Franc',
      Code: 'CHF',
    },
    Lithuania: {
      CountryCode: 'LT',
      Currency: 'Lita',
      Code: 'LTL',
    },
    Macau: {
      CountryCode: 'MO',
      Currency: 'Pataca',
      Code: 'MOP',
    },
    Macedonia: {
      CountryCode: 'MK',
      Currency: 'Denar',
      Code: 'MKD',
    },
    Madagascar: {
      CountryCode: 'MG',
      Currency: 'Malagasy Franc',
      Code: 'MGA',
    },
    Malawi: {
      CountryCode: 'MW',
      Currency: 'Malawian Kwacha',
      Code: 'MWK',
    },
    Malaysia: {
      CountryCode: 'MY',
      Currency: 'Ringgit',
      Code: 'MYR',
    },
    Maldives: {
      CountryCode: 'MV',
      Currency: 'Rufiyaa',
      Code: 'MVR',
    },
    Malta: {
      CountryCode: 'MT',
      Currency: 'Maltese Lira',
      Code: 'MTL',
    },
    Mauritania: {
      CountryCode: 'MR',
      Currency: 'Ouguiya',
      Code: 'MRO',
    },
    Mauritius: {
      CountryCode: 'MU',
      Currency: 'Mauritian Rupee',
      Code: 'MUR',
    },
    Mexico: {
      CountryCode: 'MX',
      Currency: 'Peso',
      Code: 'MXN',
    },
    'Moldova Republic of': {
      CountryCode: 'MD',
      Currency: 'Leu',
      Code: 'MDL',
    },
    Mongolia: {
      CountryCode: 'MN',
      Currency: 'Tugrik',
      Code: 'MNT',
    },
    Morocco: {
      CountryCode: 'MA',
      Currency: 'Dirham',
      Code: 'MAD',
    },
    'Western Sahara': {
      CountryCode: 'EH',
      Currency: 'Dirham',
      Code: 'MAD',
    },
    Mozambique: {
      CountryCode: 'MZ',
      Currency: 'Metical',
      Code: 'MZN',
    },
    Myanmar: {
      CountryCode: 'MM',
      Currency: 'Kyat',
      Code: 'MMK',
    },
    Namibia: {
      CountryCode: 'NA',
      Currency: 'Dollar',
      Code: 'NAD',
    },
    Nepal: {
      CountryCode: 'NP',
      Currency: 'Nepalese Rupee',
      Code: 'NPR',
    },
    Nicaragua: {
      CountryCode: 'NI',
      Currency: 'Cordoba Oro',
      Code: 'NIO',
    },
    Nigeria: {
      CountryCode: 'NG',
      Currency: 'Naira',
      Code: 'NGN',
    },
    Oman: {
      CountryCode: 'OM',
      Currency: 'Sul Rial',
      Code: 'OMR',
    },
    Pakistan: {
      CountryCode: 'PK',
      Currency: 'Rupee',
      Code: 'PKR',
    },
    Panama: {
      CountryCode: 'PA',
      Currency: 'Balboa',
      Code: 'PAB',
    },
    'Papua New Guinea': {
      CountryCode: 'PG',
      Currency: 'Kina',
      Code: 'PGK',
    },
    Paraguay: {
      CountryCode: 'PY',
      Currency: 'Guarani',
      Code: 'PYG',
    },
    Peru: {
      CountryCode: 'PE',
      Currency: 'Nuevo Sol',
      Code: 'PEN',
    },
    Philippines: {
      CountryCode: 'PH',
      Currency: 'Peso',
      Code: 'PHP',
    },
    Poland: {
      CountryCode: 'PL',
      Currency: 'Zloty',
      Code: 'PLN',
    },
    Qatar: {
      CountryCode: 'QA',
      Currency: 'Rial',
      Code: 'QAR',
    },
    Romania: {
      CountryCode: 'RO',
      Currency: 'Leu',
      Code: 'RON',
    },
    'Russian Federation': {
      CountryCode: 'RU',
      Currency: 'Ruble',
      Code: 'RUB',
    },
    Rwanda: {
      CountryCode: 'RW',
      Currency: 'Rwanda Franc',
      Code: 'RWF',
    },
    'Sao Tome and Principe': {
      CountryCode: 'ST',
      Currency: 'Dobra',
      Code: 'STD',
    },
    'Saudi Arabia': {
      CountryCode: 'SA',
      Currency: 'Riyal',
      Code: 'SAR',
    },
    Seychelles: {
      CountryCode: 'SC',
      Currency: 'Rupee',
      Code: 'SCR',
    },
    'Sierra Leone': {
      CountryCode: 'SL',
      Currency: 'Leone',
      Code: 'SLL',
    },
    Singapore: {
      CountryCode: 'SG',
      Currency: 'Dollar',
      Code: 'SGD',
    },
    'Slovakia (Slovak Republic)': {
      CountryCode: 'SK',
      Currency: 'Koruna',
      Code: 'SKK',
    },
    'Solomon Islands': {
      CountryCode: 'SB',
      Currency: 'Solomon Islands Dollar',
      Code: 'SBD',
    },
    Somalia: {
      CountryCode: 'SO',
      Currency: 'Shilling',
      Code: 'SOS',
    },
    'South Africa': {
      CountryCode: 'ZA',
      Currency: 'Rand',
      Code: 'ZAR',
    },
    'Sri Lanka': {
      CountryCode: 'LK',
      Currency: 'Rupee',
      Code: 'LKR',
    },
    Sudan: {
      CountryCode: 'SD',
      Currency: 'Dinar',
      Code: 'SDG',
    },
    Suriname: {
      CountryCode: 'SR',
      Currency: 'Surinamese Guilder',
      Code: 'SRD',
    },
    Swaziland: {
      CountryCode: 'SZ',
      Currency: 'Lilangeni',
      Code: 'SZL',
    },
    Sweden: {
      CountryCode: 'SE',
      Currency: 'Krona',
      Code: 'SEK',
    },
    'Syrian Arab Republic': {
      CountryCode: 'SY',
      Currency: 'Syrian Pound',
      Code: 'SYP',
    },
    Taiwan: {
      CountryCode: 'TW',
      Currency: 'Dollar',
      Code: 'TWD',
    },
    Tajikistan: {
      CountryCode: 'TJ',
      Currency: 'Tajikistan Ruble',
      Code: 'TJS',
    },
    Tanzania: {
      CountryCode: 'TZ',
      Currency: 'Shilling',
      Code: 'TZS',
    },
    Thailand: {
      CountryCode: 'TH',
      Currency: 'Baht',
      Code: 'THB',
    },
    Tonga: {
      CountryCode: 'TO',
      Currency: 'PaÕanga',
      Code: 'TOP',
    },
    'Trinidad and Tobago': {
      CountryCode: 'TT',
      Currency: 'Trinidad and Tobago Dollar',
      Code: 'TTD',
    },
    Tunisia: {
      CountryCode: 'TN',
      Currency: 'Tunisian Dinar',
      Code: 'TND',
    },
    Turkey: {
      CountryCode: 'TR',
      Currency: 'Lira',
      Code: 'TRY',
    },
    Turkmenistan: {
      CountryCode: 'TM',
      Currency: 'Manat',
      Code: 'TMT',
    },
    Uganda: {
      CountryCode: 'UG',
      Currency: 'Shilling',
      Code: 'UGX',
    },
    Ukraine: {
      CountryCode: 'UA',
      Currency: 'Hryvnia',
      Code: 'UAH',
    },
    'United Arab Emirates': {
      CountryCode: 'AE',
      Currency: 'Dirham',
      Code: 'AED',
    },
    Uruguay: {
      CountryCode: 'UY',
      Currency: 'Peso',
      Code: 'UYU',
    },
    Uzbekistan: {
      CountryCode: 'UZ',
      Currency: 'Som',
      Code: 'UZS',
    },
    Vanuatu: {
      CountryCode: 'VU',
      Currency: 'Vatu',
      Code: 'VUV',
    },
    Venezuela: {
      CountryCode: 'VE',
      Currency: 'Bolivar',
      Code: 'VEF',
    },
    Vietnam: {
      CountryCode: 'VN',
      Currency: 'Dong',
      Code: 'VND',
    },
    Yemen: {
      CountryCode: 'YE',
      Currency: 'Rial',
      Code: 'YER',
    },
    Zambia: {
      CountryCode: 'ZM',
      Currency: 'Kwacha',
      Code: 'ZMK',
    },
    Zimbabwe: {
      CountryCode: 'ZW',
      Currency: 'Zimbabwe Dollar',
      Code: 'ZWD',
    },
    'Aland Islands': {
      CountryCode: 'AX',
      Currency: 'Euro',
      Code: 'EUR',
    },
    Angola: {
      CountryCode: 'AO',
      Currency: 'Angolan kwanza',
      Code: 'AOA',
    },
    Antarctica: {
      CountryCode: 'AQ',
      Currency: 'Antarctican dollar',
      Code: 'AQD',
    },
    'Bosnia and Herzegovina': {
      CountryCode: 'BA',
      Currency: 'Bosnia and Herzegovina convertible mark',
      Code: 'BAM',
    },
    'Congo (Kinshasa)': {
      CountryCode: 'CD',
      Currency: 'Congolese Frank',
      Code: 'CDF',
    },
    Ghana: {
      CountryCode: 'GH',
      Currency: 'Ghana cedi',
      Code: 'GHS',
    },
    Guernsey: {
      CountryCode: 'GG',
      Currency: 'Guernsey pound',
      Code: 'GGP',
    },
    'Isle of Man': {
      CountryCode: 'IM',
      Currency: 'Manx pound',
      Code: 'GBP',
    },
    Laos: {
      CountryCode: 'LA',
      Currency: 'Lao kip',
      Code: 'LAK',
    },
    'Macao S.A.R.': {
      CountryCode: 'MO',
      Currency: 'Macanese pataca',
      Code: 'MOP',
    },
    Montenegro: {
      CountryCode: 'ME',
      Currency: 'Euro',
      Code: 'EUR',
    },
    'Palestinian Territory': {
      CountryCode: 'PS',
      Currency: 'Jordanian dinar',
      Code: 'JOD',
    },
    'Saint Barthelemy': {
      CountryCode: 'BL',
      Currency: 'Euro',
      Code: 'EUR',
    },
    'Saint Helena': {
      CountryCode: 'SH',
      Currency: 'Saint Helena pound',
      Code: 'GBP',
    },
    'Saint Martin': {
      CountryCode: 'MF',
      Currency: 'Netherlands Antillean guilder',
      Code: 'ANG',
    },
    'Saint Pierre and Miquelon': {
      CountryCode: 'PM',
      Currency: 'Euro',
      Code: 'EUR',
    },
    Serbia: {
      CountryCode: 'RS',
      Currency: 'Serbian dinar',
      Code: 'RSD',
    },
    'US Armed Forces': {
      CountryCode: 'USAF',
      Currency: 'US Dollar',
      Code: 'USD',
    },
  };

  return countyCode[`${county}`];
};
