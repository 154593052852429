import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Month from './component/month';
import { Navigate, useNavigate } from 'react-router-dom';
import Header from './component/bespoke_header/Header';
import LazyLoad from 'react-lazy-load';
import { BsArrowDown } from 'react-icons/bs';
import styled from 'styled-components';
import './component/styles/month.css';
import DraftViewTemplateZero from './template/templateZero/draftViewTemplateZero';
import DraftViewTemplateOne from './template/templateOne/draftViewTemplateOne';
import { useTranslation } from 'react-i18next';
import DraftTemplateTwo from './template/templateTwo/DraftTemplateTwo';

import DraftViewTemplateFour from './template/templateFour/DraftViewTemplateFour';

import DraftTemplateThree from './template/templateThree/draftViewTemplateThree';

import { makeStyles, Grid } from '@material-ui/core';
import { BallTriangle } from 'react-loader-spinner';
import UserPrompt from './UserPrompt';
import CoverImage from './template/CoverImage';

const useStyles = makeStyles({
  root: {
    background: '#fff',
    padding: '20px',
  },
  loader: {
    width: '100%',
    height: '100%',
  },
});
function DraftView() {
  const { t } = useTranslation(['dashboard']);
  const classes = useStyles();
  const beskpokeDraft = useSelector((state) => state.draft);
  const defaultImage = useSelector((state) => state.default);
  const [template] = useState(
    beskpokeDraft?.templateId !== '' ? beskpokeDraft?.templateId : '1'
  );
  const [loading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [userPromptVal, setUserPromptVal] = useState(true);

  const handleUserPrompt = () => {
    setUserPromptVal(false);
  };

  // Back to Dashboard for empty bespoke
  // if (beskpokeDraft.months?.length < 1) {
  //   return <Navigate to="/dashboard" replace />;
  // }

  useEffect(() => {
    if (beskpokeDraft?.status == 'published') {
      navigate(`/bespoke/calendar/${beskpokeDraft?.id}`);
    }
  }, []);

  return (
    <>
      {userPromptVal && <UserPrompt func={handleUserPrompt} />}
      <Header />
      <DraftHeader>
        <div className="bespoke_title_container">
          <p className="bespoke_title_year">{beskpokeDraft?.yearPicked}</p>
          <h2 className="bespoke_heading">{beskpokeDraft?.templateName}</h2>
          <p className="bespoke_slogan">{beskpokeDraft?.slogan}</p>
        </div>
      </DraftHeader>

      <CoverImage beskpoke={beskpokeDraft} />

      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.loader}
        >
          <Grid item xs={2} md={2} sm={2} lg={2}>
            <BallTriangle
              height={'100%'}
              width={'100%'}
              radius={5}
              color="#2667b1"
              ariaLabel="ball-triangle-loading"
              wrapperClass={{}}
              wrapperStyle=""
              visible={true}
            />
          </Grid>
        </Grid>
      ) : (
        {
          0: <DraftViewTemplateZero />,
          1: <DraftViewTemplateOne />,
          2: <DraftTemplateTwo />,
          3: <DraftTemplateThree />,
          4: <DraftViewTemplateFour />,
        }[template]
      )}
    </>
  );
}

export default DraftView;

const DraftHeader = styled.div`
  .bespoke_title_container {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    margin-bottom: 3rem;

    .bespoke_heading {
      font-family: 'Sofia', cursive;
      font-weight: 400;
      font-size: 2.5rem;
      color: rgba(197, 113, 14, 1);
      text-align: center;
      width: 100%;
      max-width: 1024px;
      margin: 0 auto;
      height: 100%;
      max-height: 250px;
      overflow-y: scroll;
      overflow-wrap: break-word;

      -ms-overflow-style: none;
      scrollbar-width: none;
      overflow: -moz-scrollbars-none;

      &::-webkit-scrollbar {
        width: 0 !important;
        display: none;
      }
    }

    .bespoke_title_year {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 800;
      font-size: 48px;
      text-align: center;
      line-height: 65.47px;
    }
    .bespoke_slogan {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 38.19px;
      text-align: center;
    }
  }
`;
