import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaRegEyeSlash, FaRegEye } from 'react-icons/fa';
import { CiLock } from 'react-icons/ci';
import client from '../../services';
import { toast } from 'react-toastify';
import BtnForgetPassword from './BtnForgetPassword';
import { useNavigate } from 'react-router-dom';

const ChangePasswordVerification = ({ token }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [inputvalues, setInputValues] = useState({
    password: '',
    confirmPassword: '',
  });
  const [activeBtn, setActiveBtn] = useState();

  const handleEmailChangeSubmit = () => {
    setLoading(true);
    const userToken = Object.values(token).join('');
    const isValid = inputvalues?.password === inputvalues?.confirmPassword;

    const data = {
      token: userToken,
      password: inputvalues?.password,
    };

    if (isValid) {
      client
        .post('/Account/userpasswordrecovery', data)
        .then((res) => {
          setLoading(false);
          navigate('/login');
        })
        .catch((err) => {
          console.log(err, ' error ');
          setLoading(false);
        });
    } else {
      toast.error(' Password does not match');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (inputvalues?.password !== '' && inputvalues?.confirmPassword !== '') {
      setActiveBtn(true);
    } else {
      setActiveBtn(false);
    }
  }, [inputvalues]);

  return (
    <ChangePasswordVerificationContainer>
      <h1>New password</h1>

      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (
            inputvalues?.password !== '' &&
            inputvalues?.confirmPassword !== ''
          ) {
            handleEmailChangeSubmit();
          }
        }}
      >
        <div className="change-pasword-input-container">
          <label>New password</label>

          <div className="input-feild">
            <CiLock />
            <input
              type={isVisible ? 'text' : 'password'}
              placeholder="Minimum of 8 characters"
              className="input-text"
              minLength={6}
              onChange={(e) => {
                setInputValues((prev) => ({
                  ...prev,
                  password: e.target.value,
                }));
              }}
            />
            {isVisible ? (
              <FaRegEyeSlash
                type="button"
                onClick={() => setIsVisible(false)}
              />
            ) : (
              <FaRegEye type="button" onClick={() => setIsVisible(true)} />
            )}
          </div>
        </div>

        <div className="change-pasword-input-container">
          <label>Confirm password</label>

          <div className="input-feild">
            <CiLock />
            <input
              type={isVisible ? 'text' : 'password'}
              placeholder="Minimum of 8 characters"
              className="input-text"
              minLength={6}
              onChange={(e) =>
                setInputValues((prev) => ({
                  ...prev,
                  confirmPassword: e.target.value,
                }))
              }
            />

            {isVisible ? (
              <FaRegEyeSlash
                type="button"
                onClick={() => setIsVisible(false)}
              />
            ) : (
              <FaRegEye
                type="button"
                onClick={() => {
                  setIsVisible(true);
                }}
              />
            )}
          </div>
        </div>

        <BtnForgetPassword
          text={'Confirm'}
          isActive={activeBtn}
          loading={loading}
        />
      </form>
    </ChangePasswordVerificationContainer>
  );
};

export default ChangePasswordVerification;

const ChangePasswordVerificationContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  .change-pasword-input-container {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    width: 100%;

    .input-feild {
      display: flex;
      align-items: center;
      gap: 0.7rem;
      max-width: 400px;
      width: 100%;
      height: 52px;
      padding: 12px;
      border-radius: 8px;
      border: 1px;
      color: #161616;
      background-color: #ffffff;
      border: 1px solid #161616;

      svg {
        font-size: 1.2rem;
        cursor: pointer;
      }

      .input-text {
        width: 100%;
        height: 48px;

        &:focus {
          outline: none;
        }
      }
    }
  }

  @media screen and (max-width: 990px) {
  }
`;
