import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Select, MenuItem, FormControl } from '@material-ui/core';
import EventCard from './card';
import { filterCover } from '../../store/reducers/fileReducer';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  grid: {
    // maxHeight: "80vh",
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 45,
    padding: '0px 10px',
    width: '100%',
    justifyContent: 'space-between',
  },
  textRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 45,
    padding: '0px 10px',
  },
  text: {
    marginRight: 10,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px',
    color: '#151515',
    lineHeight: '45px',
  },
  select: {
    width: 110,
    color: '#2667B1',
    fontSize: '18px',
    textAlign: 'center',
  },
}));

export default function FileList({ controlCancelFunc }) {
  const { t } = useTranslation(['dashboard']);
  const covers = useSelector((state) => state.files.covers);
  const classes = useStyles();
  const [value, setValue] = React.useState('All');
  const [bespokes, setBespoke] = useState(covers);

  console.log('BSP ', bespokes);

  // Direct Change in cover should update state
  useEffect(() => {
    setBespoke(covers);
  }, [covers]);

  // Handle Filter
  const handleChange = (event) => {
    setValue(event.target.value);
    let search = event.target.value;
    let arr = [...covers];
    let filtered;
    if (search?.toLowerCase() == 'all') {
      filtered = arr;
    } else {
      filtered = arr.filter(function (item) {
        return item?.privacy?.includes(search);
      });
    }
    setBespoke(filtered);
  };

  return (
    <div>
      <div className={classes.form}>
        <div className={classes.textRow}>
          <h6 className={classes.text}>{t('Filter')}:</h6>
          <FormControl>
            <Select
              disableUnderline
              className={classes.select}
              labelId="demo-select-small"
              id="demo-select-small"
              value={value}
              onChange={handleChange}
            >
              <MenuItem className={classes.option} value="All" selected={true}>
                {t('All events')}
              </MenuItem>
              <MenuItem className={classes.option} value={'owner'}>
                {t('Created by me')}
              </MenuItem>
              <MenuItem className={classes.option} value={'shared'}>
                {t('Shared with me')}
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <p>
          Available calendar: <span>{bespokes[0].totalCalederLeft}</span>
        </p>
      </div>
      <Grid container spacing={1} className={classes.grid}>
        {bespokes?.map((item, i) => (
          <Grid item sm={5} xs={12} md={4} lg={4} xl={4} key={i}>
            <EventCard data={item} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
