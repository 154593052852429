import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import PriceCard from './priceCard';
import Skeleton from '@material-ui/lab/Skeleton';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  switch: {
    marginBottom: 8,
    display: 'flex',
    justifyContent: 'center',
  },
  headerTitle: {
    fontWeight: 'bold',
    color: '#546E7A',
  },
  header: {
    marginTop: 10,
  },
  notePlans: {
    textAlign: 'center',
    marginTop: '30px',
    marginBottom: '30px',
  },
  notePlansColor: {
    color: '#EA0F0F',
  },
}));

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },

  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#2667B1',
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
    color: '#fff',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#2667B1',
  },
  checked: {},
}))(Switch);

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}))(InputBase);

function Details(props) {
  const { t } = useTranslation(['dashboard']);

  const {
    user,
    setUser,
    plans,
    loading,
    selected,
    setSelected,
    getUserPlans,
    setCurrency,
    currency,
    variation,
    setVariation,
    flutterFunc,
  } = props;
  const classes = useStyles();

  console.log(' THis is big plan == ', typeof plans);

  const currencyFnc = (event) => {
    setCurrency(event.target.value);
    // Call Plans API
    const data = {
      type: variation ? 'yearly' : 'monthly',
      currency: event.target.value,
    };
    getUserPlans(data);
  };
  const handleChange = (event) => {
    setVariation(event.target.checked);
    // Call Plans API
    const data = {
      type: event.target.checked ? 'yearly' : 'monthly',
      currency: currency,
    };
    getUserPlans(data);
    // console.log('subscription type', data);
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {t('User Details')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            disabled={true}
            required
            id="address1"
            name="address1"
            placeholder={t('Email Address')}
            fullWidth
            value={user.email}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="number"
            name="number"
            placeholder={t('Phone Number')}
            fullWidth
            value={user.phone}
            onChange={(event) =>
              setUser({ ...user, phone: event.target.value })
            }
          />
        </Grid>
      </Grid>
      <div className={cx([classes.switch, classes.header])}>
        <Typography variant="h6" gutterBottom className={classes.headerTitle}>
          {t('Our Pricing')}
        </Typography>
      </div>

      <div className={classes.notePlans}>
        <Typography component="p">
          <span className={classes.notePlansColor}>NB:</span>{' '}
          {t('All available plans are subscribed to yearly')}
        </Typography>
      </div>

      <Grid container spacing={2} justifyContent="center">
        {loading ? (
          <Grid item xs={10} md={4} lg={4} sm={4}>
            <Skeleton width={'100%'} height={'25rem'} />
          </Grid>
        ) : (
          plans.map((plan) => (
            <Grid
              item
              xs={10}
              md={4}
              lg={4}
              sm={4}
              onClick={() => {
                setSelected(plan);
                flutterFunc(plan.subscriptionName);
              }}
            >
              <PriceCard
                variation={variation}
                curSymbol={currency}
                plan={plan}
                selected={selected}
              />
            </Grid>
          ))
        )}
      </Grid>
    </React.Fragment>
  );
}

export default Details;
