import React from 'react';
import { HomeFAQContianer } from './HomeNotFound.style';
import Page404 from '../../assest/images/404.svg';
import { useNavigate } from 'react-router-dom';

const HomeNotFound = () => {
  const naviagte = useNavigate();
  return (
    <HomeFAQContianer>
      <div className="home_wrapper">
        <h2>Ohh, Ohh We Are Sorry</h2>
        <p>
          Miss Kitty got out again, She just can’t rest and has tampered with
          this page, lets guide you back.
        </p>

        <div className="btn_container">
          <button type="button" onClick={() => naviagte('/')}>
            Go Back to homepage
          </button>
          <button type="button" onClick={() => naviagte('/contact')}>
            Contact us
          </button>
        </div>
        <div className="img_container">
          <img src={Page404} alt="404 page" />
        </div>
      </div>
    </HomeFAQContianer>
  );
};

export default HomeNotFound;
