import styled from 'styled-components';

const TemplateFourContainer = styled.section`
  .year-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    span {
      font-size: 1.5rem;
    }
  }

  .month-inc-dec {
    padding-top: 10px;
    width: 100%;
    max-width: 12rem;
    margin: 2rem auto;
    margin-bottom: 2.25rem;
    justify-content: space-around;

    button {
      cursor: pointer;
      padding-top: 5px;
    }
    p {
      font-size: 1.25rem;
    }
  }
  .view_container {
    background-color: #fafafa;
    width: 90%;
    max-width: 1440px;

    margin: 0 auto;
    margin-bottom: 3rem;
    .weeks_view_container {
      padding: 1rem 1.5rem;
      display: flex;
      gap: 2rem;
      align-items: center;
      justify-content: space-between;

      svg {
        cursor: pointer;
      }

      .weeks_items_container {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        flex: 1;

        svg {
          cursor: pointer;
        }
        .week_items {
          display: flex;
          gap: 0.8rem;
          align-items: center;
          justify-content: space-around;

          .week_item {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }
  }

  .events_container {
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    width: 100%;
    margin-top: 1rem;
    height: 476px;
    overflow-y: scroll;
    padding-bottom: 2rem;

    .event_container {
      background-color: #fff;
      padding: 0.6rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      .outer_events {
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: space-between;
        width: 100%;
        .chev_new {
          display: flex;
          gap: 2rem;
          align-items: center;
          justify-content: space-between;

          svg {
            font-size: 2.2rem;
          }
        }

        .title {
          color: #111111;
          font-size: 1.3rem;
        }
        .event_summary {
          display: flex;
          gap: 2rem;
          align-items: center;

          .date_time {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.7rem;
            font-size: 1.1rem;
            opacity: 0.5;
            color: #111111;

            .date {
              font-size: 1.3rem;
            }
          }
        }
      }

      .drop_event {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 2rem;
        align-items: center;
        .drop_writeup {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .sub_title {
            color: #2667b1;
            text-decoration: underline;
          }

          .season_tv {
            .season {
              color: #696969;
            }
            .caution {
              color: #111111;
              font-weight: bold;
            }
          }

          .watch_btn {
            display: flex;
            gap: 1.5rem;

            .check {
            }
            .arrow_btn {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 1rem;
              font-size: 1rem;
              color: #2667b1;
              text-decoration: none;

              svg {
                margin-top: 5px;
              }
            }
          }
        }

        .img_container {
          width: 302px;
          height: 193px;
          img {
            image-rendering: crisp-edges;
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 950px) {
    .events_container {
      .event_container {
        .drop_event {
          display: flex;
          flex-direction: column;
          gap: 2rem;

          .drop_writeup {
            width: 100%;
          }

          .img_container {
            width: 100%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 690px) {
    .events_container {
      .event_container {
        .outer_events {
          .event_summary {
            flex-direction: column;
            gap: 0.6rem;

            .date_time {
              .date {
                font-size: 1.7rem;
              }
            }
            .date_time p:nth-child(2) {
              font-size: 1.7rem;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 690px) {
    .view_container {
      .weeks_view_container {
        flex-direction: column;
        svg {
          color: #2667b1;
          font-size: 1.3rem;
        }
      }
    }

    .events_container {
      .event_container {
        .outer_events {
          display: flex;
          flex-direction: column;
          .event_summary {
          }
        }
      }
    }
  }
`;

export default TemplateFourContainer;
