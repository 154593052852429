import React from 'react';
import styled from 'styled-components';
import { RotatingLines } from 'react-loader-spinner';

const BtnForgetPassword = ({ text, isActive, loading }) => {
  return (
    <BtnForgetPasswordContainer isActive={isActive}>
      <button type="submit">
        {text}
        {
          <RotatingLines
            strokeColor="#fff"
            strokeWidth="5"
            animationDuration="0.75"
            width="20"
            visible={loading}
          />
        }
      </button>
    </BtnForgetPasswordContainer>
  );
};

export default BtnForgetPassword;

const BtnForgetPasswordContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;

  button {
    background: ${({ isActive }) =>
      isActive ? 'rgba(38, 103, 177, 0.85)' : 'rgba(175, 175, 175, 1)'};
    color: #fff;
    max-width: 300px;
    width: 100%;
    height: 62px;

    padding: 12px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
