import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HeaderContainer } from './Header.style';
import Logo from '../../../assest/images/logo-white.svg';
import { Button, makeStyles } from '@material-ui/core';


import { useUser } from '../../../authenicationProvider';

import { useSelector, useDispatch } from 'react-redux';
import { statusUpdate, generate } from '../../../store/reducers/CurrentDraft';
import { loadBespoke } from '../../../store/reducers/bespokeView';
import client from '../../../services';
import { RotatingLines } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { FiCodesandbox } from 'react-icons/fi';
import { Avatar } from '@material-ui/core';
import ProfileDropDown from '../profile_drop_down/ProfileDropDown';
import { setCalendarMonth } from '../../../store/features/calendarSlice';
import selectTemplate from '../../../helpers/templateSelector';

// Button styles
const useStyles = makeStyles((theme) => ({
  btn: {
    background: '#43abf9',
    color: '#fff',
    padding: '6px 8px 6px 8px',
    marginRight: 5,
    '&:focus': {
      background: '#43abf0',
    },
    '&:hover': {
      background: '#43abf0',
    },
    '& .MuiButton-label': {
      fontSize: '12px',
    },
    '&:disabled': {
      color: '#fff',
    },
  },
}));
const HeaderNoLogic = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dropDown, setDropDown] = useState(false);
  const { setUser } = useUser();

  // Profile menu show
  const handleProfileShow = () => {
    setProfileShow(false);
  };

  // Loading State
  const [processing, setProcessing] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [profileShow, setProfileShow] = useState(false);
  const beskpokeDraft = useSelector((state) => state.draft);
  const user = useSelector((state) => state.user);
  // mock subscription
  var sub = {
    ...user.subscription,
    usage: 0,
    valid: true,
  };
  const [subscription] = useState(sub);
  const classes = useStyles();

  // Logout

  const logOut = () => {
    // Facebook Signout
    if (user.type == 'fb') {
      window.FB.api('/me/permissions', 'delete', null, () =>
        window.FB.logout(function (response) {
          // user is now logged out
        })
      );
    }
    // Google Signout
    if (user.type == 'google') {
      googleLogout();
    }
    let tokenKey = 'auth_token';
    localStorage.removeItem(tokenKey);
    localStorage.removeItem('mobileAuth');
    setUser(false);
    navigate('/');
  };

  // Publish Function
  const publishFunc = async () => {
    setPublishing(true);
    // Call Publish API
    dispatch(loadBespoke(beskpokeDraft));
    const bespokeId = beskpokeDraft?.id;
    await client
      .post(`/Souvenir/souvenirpublish?souvenirId=${bespokeId}`)
      .then((res) => {
        setPublishing(false);
        navigate(`/bespoke/calendar/${bespokeId}`);
      })
      .catch((e) => {
        console.log(e);
        toast.error(e?.response?.data?.Message ?? e?.message);
        setPublishing(false);
      });
  };

  // Save Function
  const save = async () => {
    setProcessing(true);
    var data = {
      ...beskpokeDraft,
    };
    console.log(data, 'sent save');
    await client
      .post('/Souvenir/create-souvenir', data)
      .then((res) => {
        console.log(res?.data, 'res');
        //  console.log(binaryStr);
        dispatch(statusUpdate(res?.data));
        setProcessing(false);
      })
      .catch((e) => {
        console.log(e);
        toast.error(e?.response?.data?.Message ?? e?.message);
        setProcessing(false);
      });
  };
  // Update Function
  const update = async () => {
    setProcessing(true);
    var data = {
      ...beskpokeDraft,
    };

    await client
      .put(`/Souvenir/souvenir-update?souvenirId=${beskpokeDraft?.id}`, data)
      .then((res) => {
        // console.log(res?.data, 'res updated');
        //  console.log(binaryStr);
        // dispatch(statusUpdate(data))
        if (beskpokeDraft?.status == 'published') {
          navigate(selectTemplate(beskpokeDraft?.id));

          {
            /* `/bespoke/calendar/${beskpokeDraft?.id})*/
          }
        }
        setProcessing(false);
      })
      .catch((e) => {
        console.log(e);
        toast.error(e?.response?.data?.Message ?? e?.message);
        setProcessing(false);
      });
  };
  // useEffect(() => {
  //   if (beskpokeDraft?.id) {
  //     // update();
  //   } else {
  //     save();
  //   }
  // }, []);
  return (
    <HeaderContainer>
      <div className="main-wrapper header_no_logic">
        <div className="logo-container">
          <img src={Logo} alt="logo lectie" onClick={() => navigate('/')} />
        </div>

        <div className={`right-side `}>
          <div
            className="profile-image"
            onClick={() => {
              setProfileShow((prevState) => !prevState);
              setDropDown(false);
            }}
          >
            {user?.picture ? (
              <Avatar
                alt="Picture"
                src={`${user?.picture}?${new Date()}`}
                className="smallImg"
              />
            ) : (
              <Avatar className="smallImg">
                {user?.firstName !== ''
                  ? user?.firstName[0]
                  : user?.organisationName[0].toUpperCase()}
              </Avatar>
            )}
            {profileShow && (
              <ProfileDropDown move="-170" func={handleProfileShow} />
            )}
          </div>
        </div>
      </div>
    </HeaderContainer>
  );
};

export default HeaderNoLogic;
