import React, { useState } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import uuid from 'react-uuid';
import { BiTimeFive } from 'react-icons/bi';
import { FaRegShareSquare } from 'react-icons/fa';
import { GoLocation } from 'react-icons/go';
import { IoMdCloseCircle } from 'react-icons/io';
import { GrAttachment } from 'react-icons/gr';
import dayjs from 'dayjs';
import cx from 'classnames';
import { FaLocationDot } from 'react-icons/fa6';
import { VscCopy } from 'react-icons/vsc';
import { copy } from '../../../../functions/index';
import { useTranslation } from 'react-i18next';
import {
  BsBell,
  BsTextLeft,
  BsChevronRight,
  BsChevronLeft,
} from 'react-icons/bs';
import { saveCalendarEvent } from '../../../../functions';
import {
  setPrevMonthNumber,
  setNextMonthNumber,
  setCountNextYears,
  setCountPrevYears,
  setMonthNumberFromYear,
  reloadMonthNumber,
} from '../../../../store/features/monthSlice';
import { toast } from 'react-toastify';
import {
  DeleteDateBespokeFilterCalendarYear,
  addUpdateBespokeFilterCalendarYears,
  setCustomColors,
  sortBespokeFilterMonths,
  updateBespokeMonths,
} from '../../../../store/reducers/bespokeFilter';
import LazyLoad from 'react-lazy-load';
import Nav from '../../../wallet/nav';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Button } from '@material-ui/core';
import {
  AddMonth,
  DeleteDateDraftCalendarYear,
  addUpdateDraftCalendarYears,
  addYearToDraft,
  sortDraftMonths,
} from '../../../../store/reducers/CurrentDraft';
import {
  setShowAddMonthImages,
  setShowEventModel,
  setShowMonthModel,
} from '../../../../store/features/modalSlice';
import Month from '../../../component/month';
import { ChevronLeft, ChevronRight } from 'react-feather';
import Skeleton from '@mui/material/Skeleton';
import { getMonth } from '../../../../util';
import getDaysInMonths from '../../../component/bespoke-core/utils/utilComponents/getDaysInMonths';
import { loadEventsGroup } from '../../../../store/reducers/templateEvents';
import client from '../../../../services';
import RectSkeleton from '../../RectSkeleton';
import RectSkeletonLoader from '../../RectSkeleton';

const useStyles = makeStyles({
  calendarNav: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  year: {
    fontSize: '38px',
    padding: '0px 5px 0px 5px',
    textAlign: 'center',
  },
  icon: {
    marginTop: 8,
  },
});

const TemplateThreeMonth = ({
  setPopIndex,
  popIndex,
  eventPopup,
  setEventPopup,
  beskpoke,
  defaultImage,
  theme,
  moreEvent,
  setMoreEvent,
  handleClickOpenShare,
}) => {
  const { t } = useTranslation(['dashboard']);

  const dispatch = useDispatch();

  const [currentMonth] = useState(getMonth(0, beskpoke?.yearPicked));
  // Dependant state
  const [months, setMonths] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [yearsInCalendar, setYearsInCalendar] = useState([]);
  const [lengthOfMonths, setLenthOfMonths] = useState(beskpoke?.months.length);
  const [currentYearNumber, setCurrentYearNumber] = useState(1);
  // const [monthNumber, setMonthNumber] = useState(0); // month number
  const [week, setWeek] = useState([]);
  const eventsGroup = useSelector((state) => state.eventSlice);
  // End Dependant state

  const { monthNumber, countNumberOfYears } = useSelector(
    (state) => state.month
  );

  let dateFormated = dayjs(new Date(dayjs().year(), monthNumber)).format(
    'MMMM'
  );

  // Months of Years
  const [numberOfYears, setNumberOfYears] = useState(
    beskpoke?.months.length / 12
  );
  // Months Names
  const monthOfYear = Array(numberOfYears)
    .fill([
      'January',
      ' February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ])
    .flat();

  let monthsCount = Array(numberOfYears)
    .fill([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])
    .flat();

  let allYear = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  const classes = useStyles();

  // See More Function
  const handleSeeMore = (monthIndex, eventDate) => {
    const filteredEvent = eventsGroup?.eventsGroup[monthIndex][0];
    console
      .log('FEVT ', filteredEvent)
      .filter((evt) => evt?.startDate === eventDate)
      .filter((evnt, index) => index > 1);
    setEventPopup(true);
    setPopIndex(0);
    setMoreEvent(filteredEvent);
  };

  // Handle PopUp
  const handlePopup = (eventId, monthId) => {
    console.log('EGrp ', eventsGroup);
    const filteredEvent = eventsGroup?.eventsGroup[monthNumber][0].filter(
      (evt) => evt?.id === eventId
    );
    setEventPopup(true);
    setPopIndex(0);
    setMoreEvent(filteredEvent);
  };

  const saveAllPresentYear = () => {
    let allYearInCalendar = [];
    for (const month of beskpoke?.months) {
      allYearInCalendar.includes(dayjs(month?.date).format('YYYY'))
        ? null
        : allYearInCalendar.push(dayjs(month?.date).format('YYYY'));
    }

    setNumberOfYears(allYearInCalendar.length);
    setLenthOfMonths(beskpoke?.months.length);
    setYearsInCalendar(allYearInCalendar);
  };

  const getMonthsInYear = () => {
    let months = [];
    for (let i = 0; i <= 11; i++) {
      let allMonth = getMonth(i, beskpoke?.yearPicked);
      months.push(allMonth);
    }

    let year = beskpoke?.yearPicked;

    //  Get Weeks
    const weekObj = getMonth(0, year);
    setWeek(...weekObj);

    let otherMonthsInYears = [];

    for (let i = 0; i < yearsInCalendar.length; i++) {
      let otherYear = parseInt(
        new Date(
          new Date().setFullYear(
            new Date(year.toString()).getFullYear() + (i + 1)
          )
        ).getFullYear()
      );

      let otherYearItem = allYear.map((val) => getMonth(val, otherYear));
      otherMonthsInYears = [...otherMonthsInYears, ...otherYearItem];
    }

    let daysInMonths = getDaysInMonths([...months, ...otherMonthsInYears]); //Arrange month function
    setMonths([...daysInMonths]);
  };

  const groupEvents = () => {
    let monthEventsGroup = beskpoke?.months.map(({ events }) => [events]);

    dispatch(loadEventsGroup(monthEventsGroup));
  };

  const getNewCalendar = async (year, { chevron } = {}) => {
    if (chevron === true) {
      setIsLoading(true);
    }

    let bodyData = {
      colors: [...beskpoke?.customColors],
    };

    // Check year presence
    if (!yearsInCalendar.includes(year)) {
      // Call this only when it the year Switcher Right chevron clicked

      // Perform api call
      await client
        .post(
          `/Souvenir/souvenirMonthsByYear/${beskpoke?.id}?year=${year}`,
          bodyData
        )
        .then((res) => {
          console.log(
            ' Next  year Called ',
            res.data,
            ' In year not pesent ',
            year,
            ' ',
            beskpoke?.id
          );

          if (beskpoke?.status === 'draft') {
            dispatch(addUpdateDraftCalendarYears(res.data));
          } else {
            dispatch(addUpdateBespokeFilterCalendarYears(res.data));
          }

          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err, ' Error');

          dispatch(setCountPrevYears());
          dispatch(setMonthNumberFromYear());

          if (chevron === true) {
            toast.info(`${year} is not yet created on your calendar`);
          }
          setIsLoading(false);
        });
    } else {
      await client
        .post(
          `/Souvenir/souvenirMonthsByYear/${beskpoke?.id}?year=${year}`,
          bodyData
        )
        .then((res) => {
          console.log(' Prev  year Called ', res.data);

          // Delete Year (Next year)
          if (beskpoke?.status === 'draft') {
            dispatch(DeleteDateDraftCalendarYear(year));
            dispatch(addUpdateDraftCalendarYears(res.data));
            dispatch(sortDraftMonths());
          } else {
            dispatch(DeleteDateBespokeFilterCalendarYear(year));
            dispatch(addUpdateBespokeFilterCalendarYears(res.data));
            dispatch(sortBespokeFilterMonths());
          }

          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err, ' Error');
          setIsLoading(false);
        });
    }
  };

  // Call all Starting function
  const getBespoke = async (id) => {
    setIsLoading(true);
    saveAllPresentYear();
    getMonthsInYear();
    groupEvents();
    setIsLoading(false);

    console.log(yearsInCalendar, ' year present ');
  };

  useEffect(() => {
    getBespoke();
  }, [beskpoke?.months]);

  useEffect(() => {
    dispatch(reloadMonthNumber());
  }, []);

  return (
    <BespokeViewTemplateThreeContainer>
      <>
        <div className="image_container"></div>
        <section className="calendar_container">
          {/* Year Switcher */}
          <div className="text-center">
            {countNumberOfYears > 1 && monthNumber != 0 && !isLoading && (
              <button
                onClick={() => {
                  // Call previous year
                  let curYearVal = dayjs(months[monthNumber]?.date).format(
                    'YYYY'
                  );

                  let prevYear = new Date(
                    new Date().setFullYear(
                      new Date(curYearVal.toString()).getFullYear() - 1
                    )
                  )
                    .getFullYear()
                    .toString();

                  dispatch(setCountPrevYears());
                  dispatch(setMonthNumberFromYear());
                  if (parseInt(beskpoke?.yearPicked) > parseInt(prevYear)) {
                    prevYear = beskpoke?.yearPicked;
                  }
                  getNewCalendar(prevYear, { chevron: true });
                }}
              >
                <ChevronLeft
                  size={24}
                  className={cx([classes.icon, 'nav-icon'])}
                />
              </button>
            )}

            <span className={[classes.year]}>
              {' '}
              {dayjs(beskpoke?.months[monthNumber]?.date).format('YYYY')}
            </span>

            {!isLoading && (
              <button
                onClick={() => {
                  let curYearVal = dayjs(
                    beskpoke?.months[monthNumber]?.date
                  ).format('YYYY');

                  const nextYear = new Date(
                    new Date().setFullYear(
                      new Date(curYearVal.toString()).getFullYear() + 1
                    )
                  )
                    .getFullYear()
                    .toString();

                  // Increase count
                  dispatch(setCountNextYears());
                  dispatch(setMonthNumberFromYear());

                  //  Call Next Year
                  getNewCalendar(nextYear, { chevron: true });
                }}
              >
                <ChevronRight
                  size={24}
                  className={cx([classes.icon, 'nav-icon'])}
                />
              </button>
            )}
          </div>

          {/* Month switcher */}

          <div className="text-center">
            {!isLoading && (
              <>
                {monthNumber >= 1 && (
                  <button
                    onClick={() => {
                      dispatch(setPrevMonthNumber());

                      let curYearVal = dayjs(months[monthNumber]?.date).format(
                        'YYYY'
                      );

                      let prevYear = new Date(
                        new Date().setFullYear(
                          new Date(curYearVal.toString()).getFullYear() - 1
                        )
                      )
                        .getFullYear()
                        .toString();

                      let oldYear = new Date(
                        new Date().setFullYear(new Date().getFullYear() - 1)
                      )
                        .getFullYear()
                        .toString();

                      if (
                        dateFormated === 'August' &&
                        parseInt(prevYear) !== parseInt(oldYear)
                      ) {
                        getNewCalendar(prevYear);
                      }
                    }}
                  >
                    <ChevronLeft
                      size={24}
                      className={cx([classes.icon, 'nav-icon'])}
                    />
                  </button>
                )}
              </>
            )}

            <span className={[classes.year]}>
              {monthOfYear[monthNumber] === undefined
                ? t('January')
                : t(`${monthOfYear[monthNumber]}`)}
            </span>
            {!isLoading && (
              <>
                {beskpoke?.months.length - 1 !== monthNumber && (
                  <button
                    onClick={() => {
                      dispatch(setNextMonthNumber());

                      // Calls this function when month is October
                      if (t(dateFormated) === 'June') {
                        let curYearVal = dayjs(
                          months[monthNumber]?.date
                        ).format('YYYY');

                        const nextYear = new Date(
                          new Date().setFullYear(
                            new Date(curYearVal.toString()).getFullYear() + 1
                          )
                        )
                          .getFullYear()
                          .toString();

                        getNewCalendar(nextYear);
                      }
                    }}
                  >
                    <ChevronRight
                      size={24}
                      className={cx([classes.icon, 'nav-icon'])}
                    />
                  </button>
                )}
              </>
            )}
          </div>
          <div className="text-right   flex space-x-2 self-end">
            <button className=" text-white text-sm rounded-sm  bg-[#43ABF9] px-4 py-1">
              Month
            </button>
            {/* <button className=" text-black text-sm rounded-sm border border-black  bg-white px-4 py-1">
                Week
              </button> */}
          </div>

          {isLoading ? (
            <RectSkeletonLoader />
          ) : (
            <section className="calendar">
              <div className="weeks grid_sys_week">
                {week && (
                  <>
                    {week.map((row, i) => (
                      <>
                        <div className="week t-center t-sm" key={uuid()}>
                          <span className="week_day_desktop">
                            {t(row.format('dddd'))}
                          </span>
                          <span className="week_day_mobile">
                            {t(row.format('ddd'))}
                          </span>
                        </div>
                      </>
                    ))}
                  </>
                )}
              </div>
              <div className="monthContainer grid_sys_month">
                {months[monthNumber]?.map((row, idx) =>
                  row.map((day) => (
                    <div>
                      <DayTemplateThree
                        day={day}
                        key={uuid()}
                        monthId={0}
                        month={eventsGroup?.eventsGroup[monthNumber][0]}
                        seeMoreFunc={handleSeeMore}
                        popUpFunc={handlePopup}
                        curMonth={months[monthNumber][2][0].format('MM')}
                        events={
                          eventsGroup?.eventsGroup[monthsCount[monthNumber]]
                        }
                      />
                    </div>
                  ))
                )}
              </div>
            </section>
          )}
        </section>
      </>
      {/* Event Popup */}
      {eventPopup === true && (
        <section className="event_pop_container">
          <div
            className="event_content"
            style={{ background: moreEvent[popIndex]?.color }}
          >
            <div className="heading">
              <IoMdCloseCircle onClick={() => setEventPopup(false)} />

              {beskpoke?.status === 'published' &&
                beskpoke?.calendarPlan === 'Premium' && (
                  <FaRegShareSquare onClick={() => handleClickOpenShare()} />
                )}
            </div>

            <div className="body_event_pop">
              <h2>{moreEvent[popIndex]?.title}</h2>
              <img
                src={
                  moreEvent[popIndex]?.image
                    ? moreEvent[popIndex]?.image
                    : require('../../../../assest/images/Rectangle19.png')
                }
                alt="Event Picture"
                className="image"
              />

              <div className="event_details">
                {moreEvent.length > 1 && (
                  <div className="inc_dec">
                    <BsChevronLeft
                      className="left"
                      style={popIndex > 0 ? {} : { visibility: 'hidden' }}
                      onClick={() => {
                        setPopIndex((prevState) => prevState - 1);
                      }}
                    />
                  </div>
                )}

                <div className="detail_container">
                  <div className="time_container event_detail">
                    <BiTimeFive />

                    <div className="time">
                      <p>
                        <span> {t('Date')}:</span>{' '}
                        {dayjs(moreEvent[popIndex].startDate).format('DD')}{' '}
                        {t(dayjs(moreEvent[popIndex].startDate).format('MMMM'))}{' '}
                        {dayjs(moreEvent[popIndex].startDate).format('YYYY')}
                      </p>
                      <p>
                        <span>{t('Time')}: </span>{' '}
                        {dayjs(moreEvent[popIndex]?.startTime).format('h:mma')}{' '}
                        - {dayjs(moreEvent[popIndex]?.endTime).format('h:mma')}
                      </p>
                    </div>
                  </div>
                  <div className="reminder event_detail">
                    <BsBell />
                    <div className="reminders_container">
                      {moreEvent[popIndex]?.reminders ? (
                        moreEvent[popIndex]?.reminders.map((remind, index) => (
                          <p key={index}>
                            {remind?.value} {t(remind?.name)} {t('before')}
                          </p>
                        ))
                      ) : (
                        <p>No Reminder</p>
                      )}
                    </div>
                  </div>
                  <div className="description event_detail">
                    <BsTextLeft />
                    <p className="event_detail_para">
                      {moreEvent[popIndex]?.description
                        ? moreEvent[popIndex]?.description
                        : t('No description')}
                    </p>
                  </div>
                  {moreEvent[popIndex]?.location !== '' && (
                    <div className="description event_detail">
                      <FaLocationDot />
                      <p className="event_detail_para">
                        {moreEvent[popIndex]?.location
                          ? moreEvent[popIndex]?.location
                          : t('No location')}
                      </p>
                    </div>
                  )}
                  <div className="attachment event_detail">
                    <GrAttachment />
                    <p className="event_detail_para">
                      {moreEvent[popIndex]?.link
                        ? moreEvent[popIndex]?.link
                        : t('No link')}
                    </p>
                    {moreEvent[popIndex]?.link && (
                      <VscCopy
                        onClick={() => {
                          copy(moreEvent[popIndex]?.link);
                          toast.success(t('Copied to clipboard'), {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            theme: 'colored',
                          });
                        }}
                      />
                    )}
                  </div>
                </div>
                {moreEvent.length > 1 && (
                  <div className="inc_dec">
                    <BsChevronRight
                      className="right"
                      style={
                        popIndex < moreEvent.length - 1
                          ? {}
                          : { visibility: 'hidden' }
                      }
                      onClick={() => {
                        setPopIndex((prevState) => prevState + 1);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="btn_container">
              <a
                target="_blank"
                className="event_pop_btn"
                onClick={() => {
                  let val = saveCalendarEvent(moreEvent[popIndex]);
                  val[0] === true
                    ? toast.success(t(val[1]))
                    : toast.error(t(val[1]));
                }}
              >
                {t('Add to calendar')}
              </a>
              {moreEvent[popIndex]?.link !== '' && (
                <a
                  href={moreEvent[popIndex]?.link}
                  target="_blank"
                  className="event_pop_btn_join"
                >
                  {t('Join')}
                </a>
              )}
            </div>
          </div>
        </section>
      )}
    </BespokeViewTemplateThreeContainer>
  );
};

// Start of Day Component
const DayTemplateThree = ({
  day,
  key,
  month,
  monthId,
  seeMoreFunc,
  popUpFunc,
  curMonth,
  events,
}) => {
  let count = 0;
  let eventDate = '';

  // To get all event in that day
  const colorCode = month.filter(
    (evt) => evt?.startDate?.split('T')[0] === day.format('YYYY-MM-DD')
  );

  let eventList = events[0].filter(
    (evt) => evt?.startDate === day.format('YYYY-MM-DDTHH:mm:ss')
  );

  return (
    <>
      <DayTemplateContainer
        key={key}
        // style={
        //   colorCode
        //     ? { backgroundColor: colorCode[0]?.color }
        //     : { backgroundColor: "" }
        // }
        style={{
          backgroundColor: eventList.length > 0 ? eventList[0]?.color : '',
          opacity: curMonth !== dayjs(day).format('MM') ? '0.4' : '1',
        }}
      >
        <p
          className="date_day"
          style={
            curMonth === day.format('MM') ? { opacity: '1' } : { opacity: '0' }
          }
        >
          {day.format('D')}
        </p>

        {month?.map((evt) => {
          if (evt.startDate === day.format('YYYY-MM-DDTHH:mm:ss')) {
            let keyId = uuid();

            count = count + 1;
            if (count >= 3) {
              eventDate = evt.startDate;
              return;
            }
            return (
              <div className="events_container" key={keyId}>
                <div
                  className="event"
                  onClick={() => {
                    popUpFunc(evt?.id, monthId);
                  }}
                >
                  <span
                    className="color"
                    style={{ backgroundColor: evt['color'] }}
                  ></span>
                  <span className="time hide_mobile">
                    {dayjs(evt?.startTime).format('hh:mma')}
                  </span>
                  <span className="title">{evt.title}</span>
                </div>
              </div>
            );
          }
        })}
        {count > 2 && (
          <span
            className="see_more"
            onClick={() => seeMoreFunc(monthId, eventDate)}
          >
            {count - 2} More
          </span>
        )}
      </DayTemplateContainer>
    </>
  );
};

export default TemplateThreeMonth;

const BespokeViewTemplateThreeContainer = styled.section`
  width: 95%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  gap: 6rem;

  .event_pop_container {
    z-index: 4;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .event_content {
      position: relative;
      z-index: 6;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 90%;
      max-width: 540px;
      margin: 0 auto;
      padding: 2rem 0;
      box-shadow: 4px -3px 22px 12px rgba(17, 17, 17, 0.25),
        -5px 6px 22px 12px rgba(17, 17, 17, 0.25);
      border-radius: 40px;
      height: 540px;

      overflow-y: scroll;
      overflow-wrap: break-word;
      -ms-overflow-style: none;
      scrollbar-width: 10px;
      overflow: -moz-scrollbars-none;

      &::-webkit-scrollbar {
        width: 0 !important;
        display: none;
      }

      .heading {
        display: flex;
        align-items: center;
        align-self: flex-end;
        gap: 1.5rem;
        padding-right: 2rem;
        position: fixed;
        z-index: 7;

        svg {
          font-size: 2rem;
          cursor: pointer;
        }
      }

      .btn_container {
        margin-top: 1rem;
        align-self: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        width: 100%;

        .event_pop_btn,
        .event_pop_btn_join {
          text-decoration: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 12px;
          gap: 12px;
          width: 70%;
          min-width: 200px;
          height: 54px;
          background: #2667b1;
          border: 1px solid #2667b1;
          border-radius: 12px;
          flex: none;
          order: 1;
          flex-grow: 0;
          color: #fff;
        }

        .event_pop_btn_join {
          background-color: transparent;

          color: #111111;
        }
      }

      .image {
        max-width: 400px;
        width: 90%;
        height: auto;
        margin: 1rem auto;
        align-self: center;
      }

      .body_event_pop {
        display: flex;
        flex-direction: column;
        gap: 1.3rem;
        margin-top: 2.4rem;

        h2 {
          font-family: 'Nunito';
          font-style: normal;
          font-weight: 700;
          font-size: 2rem;
          line-height: 44px;
          color: #000000;
          text-align: center;
          width: 90%;
          max-width: 350px;
          height: 100%;
          max-height: 200px;
          overflow-y: scroll;
          overflow-wrap: break-word;
          -ms-overflow-style: none;
          scrollbar-width: none;
          overflow: -moz-scrollbars-none;
          margin: 0 auto;

          &::-webkit-scrollbar {
            width: 0 !important;
            display: none;
          }
        }

        .description {
          p {
            width: 90%;
          }
        }
        .event_details {
          align-self: center;
          display: flex;
          gap: 1rem;
          width: 100%;
          max-width: 470px;
          margin: 0 auto;

          .reminders_container {
            display: flex;
            flex-direction: column;
            gap: 0.4rem;
          }

          .inc_dec {
            display: flex;
            justify-content: space-between;
            padding: 0 0.5rem;

            .left,
            .right {
              width: 2.2rem;
              height: 2.2rem;
              padding: 0.5rem;
              border-radius: 100%;
              font-weight: bold;
              box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
                rgba(17, 17, 26, 0.1) 0px 8px 24px,
                rgba(17, 17, 26, 0.1) 0px 16px 56px;
            }

            .left {
              align-self: center;
            }
            .right {
              align-self: center;
            }
          }

          .event_detail_para {
            max-height: 150px;
            height: 100%;
            overflow-y: scroll;
            overflow-wrap: break-word;
            -ms-overflow-style: none;
            scrollbar-width: none;
            overflow: -moz-scrollbars-none;

            &::-webkit-scrollbar {
              width: 0 !important;
              display: none;
            }
          }

          .location {
            svg {
              font-size: 5rem;
            }
          }

          .detail_container {
            width: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            gap: 1.2rem;

            .event_detail {
              display: flex;
              align-items: center;
              gap: 1.2rem;

              svg {
                font-size: 1.5rem;
              }
            }
            .time_container {
              .time {
                display: flex;
                flex-direction: column;
                gap: 0.2rem;

                span {
                  font-style: normal;
                  font-weight: 700;
                  line-height: 22px;
                  color: #0a0a0a;
                  flex: none;
                  order: 0;
                  flex-grow: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .image_container {
    img {
      border-radius: 0px 0px 20px 20px;
      height: auto;
      width: 100%;
      image-rendering: crisp-edges;
    }
  }

  .grid_sys_week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }

  .grid_sys_month {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-auto-rows: minmax(100px, auto);
    flex: 1;
    width: 100%;
  }

  .calendar_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: -3rem;
    width: 100%;

    .calendar {
      width: 100%;
    }
    .calendar_month {
      font-size: 1.6rem;
    }

    .week_day_mobile {
      display: none;
    }

    .weeks {
      border: 1px solid #000;
    }

    .monthContainer {
      width: 100%;
    }
  }
  display: flex;
  flex-direction: column;
  gap: 3.5rem;

  .label-main-wrapper {
    width: 95%;
    max-width: 1350px;
    margin: 0 auto;
    .label-main-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 1.2rem;

      .events_label {
        color: #111111;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: bolder;
        font-size: 1rem;
      }

      .color_container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        cursor: pointer;

        .color {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 100%;
        }

        p {
          padding-left: 0.8rem;
          padding-right: 0.8rem;
          height: 100%;
          font-weight: 550;
          font-size: 0.9rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .label_filter {
      cursor: pointer;
      width: 123px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      border: 1px solid #dadada;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      border-radius: 4px;
      .filter_btn {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 24px;
        color: #0a0a0a;
      }
    }
  }

  @media screen and (max-width: 640px) {
    .week_day_mobile {
      display: block !important;
    }
    .label-main-wrapper {
      display: none;
    }

    .week_day_desktop {
      display: none;
    }

    .image_container {
      img {
        height: 260px;
        image-rendering: crisp-edges;
      }
    }
  }
`;

const DayTemplateTwoContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 1fr);

  .day_date {
    padding: 0.8rem 0;
    /* padding-left: 0.3rem; */
    text-align: center;
  }
`;

const DayTemplateContainer = styled.div`
  height: 147px;
  overflow-y: hidden;
  padding: 0.2rem 0 1rem 0.2rem;
  border: 1px solid #333;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  background-color: ${({ color }) => (color ? '#000' : '#fff')};

  .date_day {
    margin: 0.5rem 0.2rem;
    color: #161518;
  }

  .events_container {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    .event {
      position: relative;
      display: flex;
      align-items: center;
      gap: 0.2rem;
      cursor: pointer;
      margin-bottom: 0.1rem;
      width: 100%;
      .color {
        width: 7px;
        height: 7px;
        border-radius: 100%;
      }

      .time,
      .title {
        height: 100%;
        font-size: 0.8rem;
        white-space: nowrap;
        overflow-x: hidden;
      }
      .title {
        width: 60%;
        text-overflow: ellipsis;
      }
      .time {
        width: 40%;
        color: #464646;
      }
    }
  }
  .see_more {
    margin-top: 0.7rem;
    font-size: 0.85rem;
    cursor: pointer;
  }

  @media screen and (max-width: 1055px) {
    .events_container {
      .event {
        .hide_mobile {
          display: none;
        }
        .title {
          width: 90%;
        }
      }
    }
  }
`;
