import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  EmailIcon,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
import cx from 'classnames';
import '../payment/style/share.css';
import { copy } from '../../functions/index';
import { QRCode } from 'react-qrcode-logo';
import html2canvas from 'html2canvas';
import eventImg from '../../assest/images/eventImg.png';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

// Styles
const useStyles = makeStyles({
  root: {
    margin: '0 auto',
    background: '#fff',
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  shareCon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  qr: {
    width: '100%',
    height: '100%',
  },
  inputCon: {
    marginTop: 15,
    display: 'flex',
    height: 44,
    width: '100%',
    position: 'relative',
  },
  btn: {
    marginRight: 15,
    background: '#2667B1',
    width: 80,
    borderRadius: 8,
    textAlign: 'center',
    fontSize: 16,
    height: '100%',
    position: 'absolute',
    right: 0,
    color: '#fff',
  },
  input: {
    width: 'calc(100% - 80px)',
    padding: '5px 2px 5px',
    border: '1px solid #2667B1',
    height: '100%',
  },
  shareIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    textAlign: 'center',
    marginBottom: 15,
    fontFamily: 'Nunito',
  },
  headerText: {
    color: '#2A2A2A',
    fontSize: 28,
    fontFamily: 'Raleway',
    fontWeight: '700',
  },
});

export default function ShareEvent(props) {
  const { t } = useTranslation(['dashboard']);

  const id = props.data;
  const shareText = `Hi, i'd like to share my event with you. Please click here for details`;
  const shareLink =
    process.env.NODE_ENV == 'development'
      ? `${process.env.REACT_APP_API_URL}/bespoke/event/${id}`
      : `${process.env.REACT_APP_LIVE_API_URL}/bespoke/event/${id}`;
  const classes = useStyles();
  const printRef = React.useRef();

  // Download QrCode function
  const handleDownloadImage = async () => {
    const element = printRef.current.firstChild;
    // console.log(element)
    const canvas = await html2canvas(element, { backgroundColor: 'null' });

    const data = canvas.toDataURL('image/jpg');
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = data;
      link.download = 'qrcode.jpg';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };
  return (
    <div className={cx([classes.root, 'content'])}>
      <Grid container justifyContent="center">
        <Grid item md={10} sm={12} lg={10} xs={12} className={classes.shareCon}>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={cx([classes.text, classes.headerText, 'header-text'])}
          >
            {t('Congratulations, you have successfully created your event')}
          </Typography>

          <Typography
            variant="body2"
            color="#000000"
            component="p"
            className={`${classes.text} font-body`}
          >
            {t('Share and Invite your friends')}
          </Typography>

          <div
            className="bg-contain bg-center h-64 p-4 w-64 my-4"
            style={{
              backgroundImage: `url(${eventImg})`,
            }}
          ></div>

          <Grid container spacing={0} justifyContent="center">
            <Grid item md={2} sm={2} xs={2} className={classes.shareIcon}>
              <FacebookShareButton url={shareLink}>
                <FacebookIcon size={36} round />
              </FacebookShareButton>
            </Grid>
            <Grid item md={2} sm={2} xs={2} className={classes.shareIcon}>
              <TwitterShareButton url={`${shareText} - ${shareLink}`}>
                {/* <TwitterIcon size={36} round /> */}
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 41 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="0.5" width="40" height="40" rx="20" fill="#43ABF9" />
                  <path
                    d="M18.1663 12.666H12.333L19.2163 21.8452L12.708 29.3327H14.9163L20.2397 23.2085L24.833 29.3327H30.6663L23.493 19.7677L29.6663 12.666H27.458L22.4697 18.4043L18.1663 12.666ZM25.6663 27.666L15.6663 14.3327H17.333L27.333 27.666H25.6663Z"
                    fill="white"
                  />
                </svg>
              </TwitterShareButton>
            </Grid>
            <Grid item md={2} sm={2} xs={2} className={classes.shareIcon}>
              <TelegramShareButton url={`${shareText} - ${shareLink}`}>
                <TelegramIcon size={36} round />
              </TelegramShareButton>
            </Grid>
            <Grid item md={2} sm={2} xs={2} className={classes.shareIcon}>
              <LinkedinShareButton
                url={shareLink}
                subject={'Bespoke Calendar Invitation'}
                // body={`This is the body of the email, content writer provide content`}
              >
                <LinkedinIcon size={36} round />
              </LinkedinShareButton>
            </Grid>

            <Grid item md={2} sm={2} xs={2} className={classes.shareIcon}>
              <WhatsappShareButton
                url={`${shareText} - ${shareLink}`}
                subject={'Bespoke Calendar Invitation'}
                // body={`This is the body of the email, content writer provide content`}
              >
                <WhatsappIcon size={36} round />
              </WhatsappShareButton>
            </Grid>
          </Grid>
          <div className={classes.inputCon}>
            <input
              className={classes.input}
              id="outlined-adornment-amount"
              value={`${shareLink}`}
            />
            <button
              onClick={() => {
                copy(`${shareLink}`);
                toast.success(t('Copied to clipboard'), {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  theme: 'colored',
                });
              }}
              className={classes.btn}
            >
              Copy
            </button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
