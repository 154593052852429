import React from 'react';
import { BsPlay, BsBell } from 'react-icons/bs';
import styled from 'styled-components';

const BtnVariantGroup = ({
  label,
  func,
  icon = '',
  link = '',
  variant = 1,
}) => {
  const ICON = !icon ? '' : icon === 'bell' ? <BsBell /> : <BsPlay />;

  return (
    <BtnVariantGroupContainer>
      {
        {
          1: (
            <div className="variant_one">
              <BtnSplit link={link} ICON={ICON} label={label} func={func} />
            </div>
          ),
          2: (
            <div className="variant_two">
              <BtnSplit link={link} ICON={ICON} label={label} func={func} />
            </div>
          ),
          3: (
            <div className="variant_three">
              <BtnSplit link={link} ICON={ICON} label={label} func={func} />
            </div>
          ),

          4: (
            <div className="variant_four">
              <BtnSplit link={link} ICON={ICON} label={label} func={func} />
            </div>
          ),
        }[variant]
      }
    </BtnVariantGroupContainer>
  );
};

export default BtnVariantGroup;

const BtnSplit = ({ link, label, ICON, func }) => {
  return (
    <BtnSplitContainer onClick={(e) => e.stopPropagation()}>
      {link ? (
        <a href={link} target="_blank">
          <span>{label}</span>
          {ICON}
        </a>
      ) : (
        <div className="no_link" onClick={() => (func ? func() : {})}>
          <span>{label}</span>
          {ICON}
        </div>
      )}
    </BtnSplitContainer>
  );
};

const BtnSplitContainer = styled.section`
  a,
  .no_link {
    text-decoration: none;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    max-width: 250px;
    height: 35px;
    padding: 1rem 1.2rem;
    border: 1px solid #2667b1;
    cursor: pointer;
    span {
      font-size: 0.8rem;
    }
    svg {
      margin-top: 5px;
      align-self: center;
    }

    span,
    svg {
      color: #fff;
    }
  }
`;

const BtnVariantGroupContainer = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .variant_one {
    a,
    .no_link {
      background: #2667b1;
      border-radius: 8px;
    }
  }

  .variant_two {
    a,
    .no_link {
      background: #2667b1;
      border-radius: 20px;
    }
  }

  .variant_three {
    a,
    .no_link {
      border-radius: 20px;

      span,
      svg {
        color: #0a0a0a;
      }
    }
  }

  .variant_four {
    a,
    .no_link {
      border-radius: 5px;

      span,
      svg {
        color: #0a0a0a;
      }
    }
  }
`;
