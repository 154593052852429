import React, { useEffect, useState } from 'react';
import SideBar from '../component/global_signup_login/sidebar/SideBar';
import {
  ForgotPasswordContainer,
  ForgotPasswordWrapper,
} from './ForgotPassword.style';
import RequestForgetPassword from './RequestForgetPassword';
import VerificationCode from './VerificationCode';
import { useParams } from 'react-router-dom';

const ForgotPassowrd = () => {
  const [isMailed, setIsMailed] = useState(false);
  const [userMail, setUserMail] = useState('');
  const [userToken, setUserToken] = useState({});
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      const tokenObj = {};
      const userData = token.split('');

      // Make token an object
      userData.forEach((val, index) => {
        tokenObj[index] = val;
      });

      setUserToken(tokenObj);
      setIsMailed(true);
    }
  }, []);
  return (
    <ForgotPasswordContainer>
      <ForgotPasswordWrapper>
        <SideBar />

        <div className="right-side-form">
          {!isMailed ? (
            <RequestForgetPassword
              setIsMailed={setIsMailed}
              setUserMail={setUserMail}
            />
          ) : (
            <VerificationCode
              userMail={userMail}
              setIsMailed={setIsMailed}
              userToken={userToken}
            />
          )}
        </div>
      </ForgotPasswordWrapper>
    </ForgotPasswordContainer>
  );
};

export default ForgotPassowrd;
