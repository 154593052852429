import { editableInputTypes } from '@testing-library/user-event/dist/utils';
import React, { useState } from 'react';
import styled from 'styled-components';
import { RotatingLines } from 'react-loader-spinner';
import client from '../../services';
import Nav from '../Home/Nav';
// import Nav from '../home/Nav';

const AccountDeletion = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [countLetter, setCountLetter] = useState(0);
  const [inputvalues, setInputValues] = useState({
    content: '',
    email: '',
  });
  const [error, setError] = useState({
    email: false,
    content: false,
  });

  const handleCountText = (event) => {
    const maxCount = 30;
    let count = event.target.value.split('').length;
    setCountLetter(count);

    if (event.target.value.length > maxCount - 1 && event.key !== 'Backspace') {
      setIsReadOnly(true);
    } else {
      setIsReadOnly(false);
    }
  };

  const handleSubmit = async () => {
    if (inputvalues?.email !== '' && inputvalues?.content !== '') {
      // Call endpoints
      setLoading(true);
      const data = {
        email: inputvalues?.email,
        reason: inputvalues?.content,
      };

      await client
        .post('/Account/account-delete-request', data)
        .then((res) => {
          console.log(res?.data, ' this is response ');

          setLoading(false);
          setEmailSent(true);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err, ' Error ');
        });
    } else {
      if (inputvalues?.email === '') {
        setError((prevState) => ({
          ...prevState,
          email: true,
        }));
      }

      if (inputvalues?.content === '') {
        setError((prevState) => ({
          ...prevState,
          content: true,
        }));
      }
    }
  };
  return (
    <>
      <Nav />
      <AccountDeletionContainer error={error}>
        {!emailSent ? (
          <>
            <div className="header">
              <h2>Delete account</h2>
              <div className="text_description">
                <p>We are sad to let you go.</p>

                <p>Please tell us why you want to delete your account</p>
              </div>
            </div>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="form_input">
                <label>
                  Email address <span className="red">*</span>
                </label>
                <input
                  value={inputvalues?.email}
                  onChange={(e) => {
                    setInputValues((prevState) => ({
                      ...prevState,
                      email: e.target.value,
                    }));

                    setError((prevState) => ({
                      ...prevState,
                      email: false,
                    }));
                  }}
                  type="email"
                  placeholder="bespoke@email.com"
                />
              </div>

              <div className="form_input">
                <label>
                  Why do you want to delete your account{' '}
                  <span className="red">*</span>
                </label>
                <textarea
                  placeholder="Type here"
                  value={inputvalues?.content}
                  onChange={(e) => {
                    setInputValues((prevState) => ({
                      ...prevState,
                      content: e.target.value,
                    }));

                    setError((prevState) => ({
                      ...prevState,
                      content: false,
                    }));
                  }}
                  onKeyDown={(event) => {
                    handleCountText(event);
                  }}
                  readOnly={isReadOnly}
                ></textarea>
                <p className="counter">{countLetter}/1000</p>
              </div>

              <button type="submit">
                <span>Send</span>

                <span>
                  <RotatingLines
                    strokeColor="#fff"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="20"
                    visible={loading}
                  />
                </span>
              </button>
            </form>
          </>
        ) : (
          <section className="email_sent">
            <p>
              Your account has successfully been deactivated, You can recover
              your account within the next 30 days by sending an email to
              <a href="mailto: info.lectie.com"> info.lectie.com</a>
            </p>
          </section>
        )}
      </AccountDeletionContainer>
    </>
  );
};

export default AccountDeletion;

const AccountDeletionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;

  max-width: 850px;
  width: 100%;
  margin: 0 auto;
  margin-top: 3rem;
  padding-bottom: 2rem;

  .header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    .text_description {
      text-align: center;
    }

    h2 {
      font-family: Nunito;
      font-size: 2rem;
      font-weight: 700;
      line-height: 44px;
      letter-spacing: 0em;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    align-items: center;
    width: 90%;

    .form_input {
      display: flex;
      flex-direction: column;
      width: 90%;

      label {
        font-family: Nunito;
        font-size: 1rem;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;

        .red {
          color: red !important;
        }
      }

      .counter {
        display: flex;
        justify-content: flex-end;
      }

      input,
      textarea {
        height: 52px;
        padding: 12px 24px 12px 24px;
        border-radius: 4px;
        border: 1px solid ${({ error }) => (error?.email ? 'red' : '#0a0a0a')};
      }
      textarea {
        min-height: 192px;
        border: 1px solid ${({ error }) => (error?.content ? 'red' : '#0a0a0a')};
      }

      input:focus,
      textarea:focus,
      input:active,
      textarea:active {
        outline: none;
        border: 2px solid #0a0a0a;
      }
    }

    button {
      margin-top: 1rem;
      max-width: 230px;
      width: 100%;
      height: 52px;

      padding: 15px 0px 15px 0px;
      border-radius: 8px;
      background: #2667b1;
      color: #ffffff;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .email_sent {
    margin-top: 2rem;
    text-align: center;
    font-size: 2rem;
    line-height: 2rem;

    a {
      color: #2667b1;
    }
  }
`;
