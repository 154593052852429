import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

// Util Function
const getSuffix = (daySuf) => {
  switch (daySuf) {
    case '1':
      return 'st';
    case '2':
      return 'nd';
    case '3':
      return 'rd';
    default:
      return 'th';
  }
};

export const getMonth = (month = 0, year) => {
  const firstDayOfMonth = dayjs(new Date(year, month, 1)).day();
  let currentMonthCount = 0 - firstDayOfMonth;

  // Two Dimentional array for 5rows and 7 column
  const daysMatrix = new Array(6).fill([]).map(() => {
    return new Array(7).fill(null).map(() => {
      currentMonthCount++;
      return dayjs(new Date(year, month, currentMonthCount));
    });
  });

  return daysMatrix;
};
// To format the date as 14th October 2022
export const getTodayDate = () => {
  const { t } = useTranslation(['dashboard']);

  const date = new Date().toISOString().slice(0, 10);
  let day = date.split('-')[2];
  const monthNumber = dayjs().month();
  const yearData = dayjs(new Date(dayjs().year(), monthNumber)).format('YYYY');
  const monthData = dayjs(new Date(dayjs().year(), monthNumber)).format('MMMM');
  const daySplit = day.split('')[day.length - 1];
  let nth = getSuffix(daySplit);

  if (day >= 10 && day <= 20) nth = 'th';

  return `${day}${nth} ${t(monthData)} ${yearData}`;
};

export const getTemplateDate = (date) => {
  const { t } = useTranslation(['dashboard']);

  const day = dayjs(date).format('DD');
  const monthData = dayjs(date).format('MMMM');
  const yearData = dayjs(date).format('YYYY');
  const daySplit = day.split('')[day.length - 1];
  let nth = getSuffix(daySplit);
  if (day >= 10 && day <= 20) nth = 'th';
  return `${day}${nth}, ${t(monthData)}, ${yearData}`;
};

// To format Time as hh:mm:ss am (For Header timer)
export const getClock = () => {
  let clock = dayjs().format('h:mm:ss a');
  return clock;
};

// To get time format 10:10am (For event Popup)
export const getTime = () => {
  let time = dayjs().format('h:mma');
  return time;
};

// To get current day: To higlight the current day in Calendar
export const getDay = (day, selectedDay) => {
  const format = 'dddd MMMM DD';
  const nowDay = dayjs().format(format);
  const curDay = day.format(format);

  if (nowDay === curDay) {
    return true;
  } else if (selectedDay === curDay) {
    return 'seen';
  }
};
