import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HeaderContainer } from './Header.style';
import Logo from '../../../assest/images/logo-white.svg';
import Toggle from '../../../assest/images/light-mode.svg';
import Share from '../../../assest/images/share_img.svg';
import ShareMobile from '../../../assest/images/shareMobile.png';
import Copy from '../../../assest/images/copy.svg';
import Save from '../../../assest/images/save.svg';
import { copy, getCountyCode, userGeolocation } from '../../../functions/index';
import ShareComponet from '../share';
import Dialog from '@material-ui/core/Dialog';
import { useSelector, useDispatch } from 'react-redux';
// import { AddMonth } from '../../../store/reducers/CurrentDraft';
import { generate } from '../../../store/reducers/CurrentDraft';
import { setCalendarMonth } from '../../../store/features/calendarSlice';
import {
  setCustomColors,
  updateBespokeMonths,
} from '../../../store/reducers/bespokeFilter';
import { useUser } from '../../../authenicationProvider';
import client from '../../../services';
import { toast } from 'react-toastify';
import { RotatingLines } from 'react-loader-spinner';
import { FiEdit, FiSave } from 'react-icons/fi';
import { BiCodeAlt } from 'react-icons/bi';
import { Avatar } from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import ProfileDropDown from '../profile_drop_down/ProfileDropDown';
import { useTranslation } from 'react-i18next';
import { GiHamburgerMenu } from 'react-icons/gi';
import Tick from '../../../assest/images/tick_label.png';
import { IoIosClose } from 'react-icons/io';
import { setTVDraft } from '../../../store/reducers/draftTVEvents';

const Header2 = () => {
  const { t } = useTranslation(['dashboard']);
  const { user } = useUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const beskpoke = useSelector((state) => state.bespoke);
  const beskpokeFilter = useSelector((state) => state.beskpokeFilter);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [open, setOpen] = React.useState(false);
  // Loading State
  const [processing, setProcessing] = useState(false);
  const [profileShow, setProfileShow] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const userDetails = useSelector((state) => state.user);
  console.log(userDetails?.id, 'Bespoke', beskpoke?.userId);
  const { id, templateName, coverImg, privacy, createdAt, userId, share } =
    beskpoke;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Profile menu show
  const handleProfileShow = () => {
    setProfileShow(false);
  };

  // Save Bespoke Calendar
  const saveFunc = async () => {
    setProcessing(true);
    // Check user is login
    if (!user) {
      navigate(`/signup/${beskpoke?.id}`);
    } else {
      await client
        .post(`/Souvenir/savesouvenirvault?souvenirId=${beskpoke?.id}`)
        .then((res) => {
          setProcessing(false);

          toast.success(t('Bespoke Successfully Added to calendar'));
          navigate('/dashboard');
        })
        .catch((e) => {
          setProcessing(false);
          toast.error(e?.response?.data?.Message ?? e?.message);
        });
    }
  };

  const updateCustomColors = (id) => {
    let customColors = beskpokeFilter?.customColors.map((color) => {
      if (color?.id === id) {
        return {
          ...color,
          isPresent: color.isPresent === true ? false : true,
        };
      }
      return { ...color };
    });

    dispatch(setCustomColors(customColors));
  };

  const updateCustomColorsApi = async () => {
    const isPresentColors = beskpokeFilter?.customColors.filter(
      (color) => color?.isPresent === true
    );

    const data = {
      bespokeId: beskpokeFilter?.id,
      colors: [...isPresentColors],
    };

    console.log('Data found is ', data);
    // Send customColors to end point
    await client
      .post('/event/eventFilter', data)
      .then((res) => {
        // Get Response and save to Bespoke months

        dispatch(updateBespokeMonths(res.data?.months));
        toast.success('Event Filtered Successfully');
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const editTvGuide = async () => {
    setProcessing(true);
    let country = new userGeolocation().getUserCountry();

    let { CountryCode } = getCountyCode(country) || 'NGN';

    // Send customColors to end point
    await client
      .get(`/Souvenir/${id}?webDraft=draft&country=${CountryCode}`)
      .then((res) => {
        // Get Response and save to Bespoke months
        setProcessing(false);
        setOpenMobileMenu(false);

        dispatch(generate(res?.data));
        dispatch(setTVDraft(res?.data));
        dispatch(setCalendarMonth(res?.data?.months));

        console.log(res.data, 'tv guide get data');
        navigate(`/studio/event`);
      })
      .catch((err) => {
        console.log(err, 'error from bespoke');
      });
  };
  return (
    <HeaderContainer>
      <div className="main-wrapper">
        <div className="logo-container">
          <GiHamburgerMenu onClick={() => setOpenMobileMenu(true)} />
          <img
            src={Logo}
            alt="logo lectie"
            onClick={() => navigate('/')}
            className="logo"
          />
        </div>

        {/* Mobile Menu*/}

        {openMobileMenu && (
          <section className="mobile_menu">
            <div className="close_icon">
              <IoIosClose onClick={() => setOpenMobileMenu(false)} />
            </div>
            <div className="hide_desktop">
              <h2>{beskpoke?.templateName}</h2>
              <div
                className="menu_item"
                onClick={() => {
                  handleClickOpen();
                  setOpenMobileMenu(false);
                }}
              >
                {(userId == userDetails?.id && user) || share ? (
                  <Tooltip title={t('Share')} placement="bottom-start">
                    <div className="icon share_icon_container">
                      <img src={ShareMobile} alt="Share icon" />
                    </div>
                  </Tooltip>
                ) : null}
                <span>Share</span>
              </div>

              {beskpoke?.userId == userDetails?.id && user ? (
                <div
                  className="menu_item"
                  onClick={() => {
                    if (beskpoke?.calendarPlan !== 'Premium') {
                      toast.warning(
                        t(
                          'Please Upgrade your plan to embed bespoke calendar on your website'
                        )
                      );
                    } else {
                      var embed =
                        process.env.NODE_ENV == 'development'
                          ? `<iframe width="640" height="1000" src="${process.env.REACT_APP_API_URL}/widget/${beskpoke?.id}" frameborder="0" allowfullscreen></iframe>`
                          : `<iframe width="640" height="1000" src="${process.env.REACT_APP_LIVE_API_URL}/widget/${beskpoke?.id}" frameborder="0" allowfullscreen></iframe>`;
                      copy(`${embed}`);
                      toast.success(t('Copied to clipboard'));
                    }
                    setOpenMobileMenu(false);
                  }}
                >
                  <Tooltip title={t('Embed link')} placement="bottom-start">
                    <div className="icon" typeof="button">
                      <BiCodeAlt />
                    </div>
                  </Tooltip>
                  <span>Embed</span>
                </div>
              ) : null}

              {beskpoke?.userId == userDetails?.id && user ? (
                <div
                  className="menu_item"
                  onClick={() => {
                    if (beskpoke?.templateId == '4') {
                      console.log('click edite');
                      editTvGuide();
                    } else {
                      setOpenMobileMenu(false);

                      dispatch(generate(beskpoke));
                      dispatch(setCalendarMonth(beskpoke?.months));
                      navigate(`/studio/event`);
                    }
                  }}
                >
                  <Tooltip title={t('Edit bespoke')} placement="bottom-start">
                    <div className="icon" typeof="button">
                      {processing ? (
                        <RotatingLines
                          strokeColor="#fff"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="20"
                          visible={processing}
                        />
                      ) : (
                        <FiEdit />
                      )}
                    </div>
                  </Tooltip>
                  <span>Edit</span>
                </div>
              ) : null}

              {beskpoke?.userId == userDetails?.id ? null : (
                <div
                  className="menu_item"
                  onClick={() => {
                    processing ? null : saveFunc();
                    setOpenMobileMenu(false);
                  }}
                >
                  <Tooltip title={t('Save')} placement="bottom-start">
                    <div className="icon">
                      {processing ? (
                        <RotatingLines
                          strokeColor="#fff"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="20"
                          visible={processing}
                        />
                      ) : (
                        <FiSave />
                      )}
                    </div>
                  </Tooltip>
                  <span>Save</span>
                </div>
              )}

              {(beskpoke?.templateId === '2' ||
                beskpoke?.templateId === '3') && (
                <section className="label_container">
                  <div className="label_header">
                    <h3>Event labels: </h3>
                    <button
                      onClick={() => {
                        updateCustomColorsApi();
                        setOpenMobileMenu(false);
                      }}
                    >
                      Filter event
                    </button>
                  </div>

                  <div className="labels">
                    {beskpokeFilter?.customColors.map(
                      ({ id, label, color, isPresent }) => (
                        <li
                          key={id}
                          className="color_container"
                          onClick={() => updateCustomColors(id)}
                        >
                          <span
                            className="color"
                            style={{ backgroundColor: color }}
                          >
                            {isPresent === true && (
                              <img src={Tick} alt="checkbox tick" />
                            )}
                          </span>
                          <p>{label}</p>
                        </li>
                      )
                    )}
                  </div>
                </section>
              )}
            </div>
          </section>
        )}

        {/*End of Mobile Section*/}

        <div className="right-side header2_right_side">
          <div className="save-copy hide_mobile">
            {(userId == userDetails?.id && user) || share ? (
              <Tooltip title={t('Share')} placement="bottom-start">
                <div
                  className="icon share_icon_container"
                  onClick={() => handleClickOpen()}
                >
                  <img src={Share} alt="Share icon" />
                </div>
              </Tooltip>
            ) : null}

            {beskpoke?.userId == userDetails?.id && user ? (
              <Tooltip title={t('Embed link')} placement="bottom-start">
                <div className="icon" typeof="button">
                  <BiCodeAlt
                    onClick={() => {
                      if (beskpoke?.calendarPlan !== 'Premium') {
                        toast.warning(
                          t(
                            'Please Upgrade your plan to embed bespoke calendar on your website'
                          )
                        );
                      } else {
                        var embed =
                          process.env.NODE_ENV == 'development'
                            ? `<iframe width="640" height="1000" src="${process.env.REACT_APP_API_URL}/widget/${beskpoke?.id}" frameborder="0" allowfullscreen></iframe>`
                            : `<iframe width="640" height="1000" src="${process.env.REACT_APP_LIVE_API_URL}/widget/${beskpoke?.id}" frameborder="0" allowfullscreen></iframe>`;
                        copy(`${embed}`);
                        toast.success(t('Copied to clipboard'));
                      }
                    }}
                  />
                </div>
              </Tooltip>
            ) : null}
            {beskpoke?.userId == userDetails?.id && user ? (
              <Tooltip title={t('Edit bespoke')} placement="bottom-start">
                <div className="icon" typeof="button">
                  {processing ? (
                    <RotatingLines
                      strokeColor="#fff"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="20"
                      visible={processing}
                    />
                  ) : (
                    <FiEdit
                      onClick={() => {
                        if (beskpoke?.templateId == '4') {
                          console.log('click edite');
                          editTvGuide();
                        } else {
                          dispatch(generate(beskpoke));
                          dispatch(setCalendarMonth(beskpoke?.months));
                          navigate(`/studio/event`);
                        }
                      }}
                    />
                  )}
                </div>
              </Tooltip>
            ) : null}
            {beskpoke?.userId == userDetails?.id ? null : (
              <Tooltip title={t('Save')} placement="bottom-start">
                <div
                  className="icon"
                  onClick={() => (processing ? null : saveFunc())}
                >
                  {processing ? (
                    <RotatingLines
                      strokeColor="#fff"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="20"
                      visible={processing}
                    />
                  ) : (
                    <FiSave />
                  )}
                </div>
              </Tooltip>
            )}
          </div>
          {user && (
            <div className="profile-image heading_profile_image">
              {userDetails?.picture ? (
                <Avatar
                  alt="Picture"
                  src={`${userDetails?.picture}?${new Date()}`}
                  className="smallImg"
                  onClick={() => {
                    setProfileShow((prevState) => !prevState);
                    setDropDown(false);
                  }}
                />
              ) : (
                <Avatar
                  className="smallImg"
                  onClick={() => {
                    setProfileShow((prevState) => !prevState);
                    setDropDown(false);
                  }}
                >
                  {userDetails?.firstName !== ''
                    ? userDetails?.firstName[0]
                    : userDetails?.organisationName[0].toUpperCase()}
                </Avatar>
              )}
              {profileShow && (
                <div>
                  <div
                    className="back_profile"
                    onClick={() => setProfileShow(false)}
                  ></div>

                  <ProfileDropDown move="-55" func={handleProfileShow} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ShareComponet data={beskpoke} />
      </Dialog>
    </HeaderContainer>
  );
};

export default Header2;
