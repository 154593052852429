import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TemplateFourContainer from './style/TemplateFourStyle';
import LazyLoad from 'react-lazy-load';
import { makeStyles, Grid } from '@material-ui/core';
import { BallTriangle } from 'react-loader-spinner';
import { getMonth } from '../../../util';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import Button from '../../component/bespoke-core/utils/utilComponents/Button';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import FooterTvGuide from '../../component/bespoke-core/calendarComponent/TvGuideStudio/FooterTvGuide';
import {
  reloadMonthNumber,
  setCountNextYears,
  setCountPrevYears,
  setMonthNumber,
  setMonthNumberFromYear,
  setNextMonthNumber,
  setPrevMonthNumber,
} from '../../../store/features/monthSlice';
import getDaysInMonths from '../../component/bespoke-core/utils/utilComponents/getDaysInMonths';
import client from '../../../services';
import { toast } from 'react-toastify';
import { setTVDraft } from '../../../store/reducers/draftTVEvents';
import { useParams } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import BtnVariantGroup from '../../component/ButtonGroup/BtnVariantGroup';
import CalendarDefaultCover from '../../../assest/images/dashboardCalendarFrame.png';
import { getCountyCode, userGeolocation } from '../../../functions';

dayjs.extend(weekOfYear);

const useStyles = makeStyles({
  root: {
    background: '#fff',
    padding: '20px',
  },
  loader: {
    width: '100%',
    height: '100%',
  },
});

const BespokeViewTemplateFour = () => {
  const { t } = useTranslation(['dashboard']);
  const classes = useStyles();
  const { id } = useParams();
  const [showDrop, setShowDrop] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const beskpoke = useSelector((state) => state.bespoke);

  const { monthNumber } = useSelector((state) => state.month);
  const TvDraft = useSelector((state) => state.tvGuideDraft);

  const [months, setMonths] = useState([
    getMonth(
      monthNumber,
      beskpoke?.status === 'published'
        ? TvDraft?.yearPicked
        : beskpoke?.yearPicked
    ),
  ]);

  const [queryStartDate, setQueryStartDate] = useState('');
  let [weekCounter, setWeekCounter] = useState(0);
  let dateFormated = dayjs(new Date(dayjs().year(), monthNumber)).format(
    'YYYY MMMM'
  );

  const allMonths = () => {
    let newAllMonths = [];

    for (let i = 0; i <= 11; i += 1) {
      newAllMonths.push(
        getMonth(
          i,
          beskpoke?.status === 'published'
            ? TvDraft?.yearPicked
            : beskpoke?.yearPicked
        )
      );
    }

    let newMonth = getDaysInMonths(newAllMonths);
    setMonths(newMonth);
  };

  // Update Bespoke res base on startdate
  const getCalendarFromStartDate = async (startDate) => {
    setIsLoading(true);
    let country = new userGeolocation().getUserCountry();

    let { CountryCode } = getCountyCode(country) || 'NGN';

    console.log(startDate, ' this is start date ==== ');

    await client
      .get(`/Souvenir/${id}?startDate=${startDate}&country=${CountryCode}`)
      .then((res) => {
        dispatch(setTVDraft(res?.data));

        console.log('DRaft res Here here  ', res?.data);

        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        toast.error(e?.response?.data?.Message ?? e?.message);
        setIsNewYear(false);
        setIsLoading(false);
        // setPublishing(false);
      });
  };

  useEffect(() => {
    allMonths();
    dispatch(setMonthNumber(0));
    setWeekCounter(0);
    setQueryStartDate(`${beskpoke?.yearPicked}-01-01T00:00:00`);
    getCalendarFromStartDate(queryStartDate);
  }, []);

  const handleOpenEvent = (index) => {
    if (showDrop.includes(index)) {
      let newList = showDrop.filter((val) => val !== index);
      setShowDrop([...newList]);
    } else {
      setShowDrop((prev) => [...prev, index]);
    }
  };

  useEffect(() => {
    dispatch(reloadMonthNumber());
  }, []);

  return (
    <TemplateFourContainer>
      <>
        {/* Month Switcher */}
        <div className="month-inc-dec flex a-center">
          <p className="t-sm">
            {dayjs(TvDraft?.events[0]?.startDate).format('MMMM YYYY')}
          </p>
        </div>

        <section className="view_container">
          <div className="weeks_view_container">
            <div>
              <BsChevronLeft
                onClick={() => {
                  let startDateVal = '';
                  if (monthNumber !== 0 && weekCounter > 0) {
                    setWeekCounter((prevState) => prevState - 1);

                    if (TvDraft?.from !== TvDraft?.to) {
                      startDateVal = dayjs(TvDraft?.from).format(
                        'YYYY-MM-DDT00:00:00'
                      );
                    } else {
                      startDateVal = months[monthNumber][
                        weekCounter - 1
                      ][0].format('YYYY-MM-DDT00:00:00');
                    }

                    getCalendarFromStartDate(startDateVal);
                  } else if (monthNumber !== 0 && weekCounter === 0) {
                    dispatch(setPrevMonthNumber());
                    setWeekCounter(months[monthNumber - 1]?.length - 1);
                    if (TvDraft?.from !== TvDraft?.to) {
                      startDateVal = dayjs(TvDraft?.from).format(
                        'YYYY-MM-DDT00:00:00'
                      );
                    } else {
                      startDateVal = months[monthNumber - 1][
                        months[monthNumber - 1]?.length - 1
                      ][0].format('YYYY-MM-DDT00:00:00');
                    }
                    getCalendarFromStartDate(startDateVal);
                  } else if (monthNumber === 0 && weekCounter > 0) {
                    setWeekCounter((prevState) => prevState - 1);

                    if (TvDraft?.from !== TvDraft?.to) {
                      startDateVal = dayjs(TvDraft?.from).format(
                        'YYYY-MM-DDT00:00:00'
                      );
                    } else {
                      startDateVal = months[monthNumber][
                        weekCounter - 1
                      ][0].format('YYYY-MM-DDT00:00:00');
                    }

                    getCalendarFromStartDate(startDateVal);
                  }
                }}
              />
            </div>
            <div className="weeks_items_container">
              {months[monthNumber] !== undefined ? (
                <div className="week_items">
                  {months[0][weekCounter].map((day, indexDay) => (
                    <div key={indexDay} className="week_item">
                      <p>{t(day.format('ddd'))}</p>
                      <p
                        style={
                          parseInt(day.format('M')) !== monthNumber + 1
                            ? { color: 'gray' }
                            : {}
                        }
                      >
                        {day.format('D')}
                      </p>
                    </div>
                  ))}
                </div>
              ) : (
                ''
              )}
            </div>
            {TvDraft?.from !== TvDraft?.to && (
              <div>
                <BsChevronRight
                  onClick={() => {
                    if (weekCounter < months[monthNumber]?.length - 1) {
                      setWeekCounter((prevState) => prevState + 1);

                      getCalendarFromStartDate(
                        dayjs(TvDraft?.to).format('YYYY-MM-DDT00:00:00')
                      );
                    } else if (monthNumber <= 10) {
                      setWeekCounter(0);
                      dispatch(setNextMonthNumber());

                      getCalendarFromStartDate(
                        dayjs(TvDraft?.to).format('YYYY-MM-DDT00:00:00')
                      );
                    }
                  }}
                />
              </div>
            )}
          </div>

          {!loading ? (
            <section className="events_container">
              {TvDraft?.events.length > 0 ? (
                <>
                  {TvDraft?.events.map((event) => (
                    <div
                      className="event_container"
                      onClick={() => handleOpenEvent(event?.id)}
                    >
                      <div className="outer_events">
                        <div className="event_summary">
                          {!showDrop.includes(event?.id) && (
                            <>
                              <div className="date_time">
                                <p className="date">
                                  {dayjs(event?.startDate).format('DD')}
                                </p>
                                <p>{dayjs(event?.startTime).format('hA')}</p>
                              </div>
                              <p className="title">{event?.title}</p>
                            </>
                          )}
                        </div>

                        <div className="chev_new">
                          {event?.isNewEvent && (
                            <BtnVariantGroup
                              label={'New event'}
                              variant={event?.neweventButtonVariant}
                              icon=""
                              link=""
                            />
                          )}

                          {showDrop.includes(event?.id) ? (
                            <BiChevronUp />
                          ) : (
                            <BiChevronDown />
                          )}
                        </div>
                      </div>

                      {showDrop.includes(event?.id) && (
                        <div className="drop_event">
                          <div className="drop_writeup">
                            <h3 className="sub_title">{event?.subTitle}</h3>

                            <div className="season_tv">
                              <p className="season">
                                Season {event?.season} - Episode{' '}
                                {event?.episode}
                              </p>

                              <p className="caution">
                                {' '}
                                TV-{event?.programCaution}{' '}
                              </p>
                            </div>

                            <p className="description">{event?.description}</p>

                            {beskpoke?.status === 'published' && (
                              <div className="watch_btn">
                                <div className="check">
                                  {dayjs(event?.startTime).isBefore(
                                    dayjs(new Date())
                                  ) ? (
                                    <BtnVariantGroup
                                      label={'watch on demand'}
                                      variant={event?.videoButtonVariant}
                                      icon="play"
                                      link={event?.videoUrl}
                                    />
                                  ) : (
                                    <>
                                      {/*

                                event?.isRemindMe === true && (
                                  <BtnVariantGroup
                                    label={'Remind me'}
                                    variant={event?.remindButtonVariant}
                                    icon="bell"
                                    link=""
                                    func={remindMe}
                                  />
                                )
                                  */}{' '}
                                    </>
                                  )}
                                </div>

                                <a
                                  href={event?.programLink}
                                  className="arrow_btn"
                                >
                                  <p>Visit program page </p> <FaArrowRight />
                                </a>
                              </div>
                            )}
                          </div>

                          <div className="img_container">
                            <img
                              src={
                                !event?.image
                                  ? CalendarDefaultCover
                                  : event?.image
                              }
                              alt="event picture"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <p
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '1.6rem',
                  }}
                >
                  {' '}
                  No Data{' '}
                </p>
              )}
            </section>
          ) : (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={classes.loader}
            >
              <Grid item xs={2} md={2} sm={2} lg={2}>
                <BallTriangle
                  height={'100%'}
                  width={'100%'}
                  radius={5}
                  color="#2667b1"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              </Grid>
            </Grid>
          )}
        </section>

        <FooterTvGuide />
      </>
    </TemplateFourContainer>
  );
};

export default BespokeViewTemplateFour;

// 08db7c99-81b1-4ff1-8004-3bbdf945f849
