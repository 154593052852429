import React, { useState } from 'react';
import PrivateSuccessImg from '../../assest/images/success_private.png';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { emailInvite } from '../../functions';
import client from '../../services';
import { RotatingLines } from 'react-loader-spinner';
import { toast } from 'react-toastify';

const PrivateSucessPage = () => {
  const { id } = useParams();
  // const shareText = `Hi, i'd like to share my calendar with you. Please click here for details`
  const { t } = useTranslation(['dashboard']);
  const navigate = useNavigate();
  const [inviteSwitcher, setInviteSwitcher] = useState('lectie');
  const draft = useSelector((state) => state?.draft);
  const user = useSelector((state) => state.user);
  const [emailSearch, setEmailSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [lectieInviteLoading, setLectieInviteLoading] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  // Multiple selection states
  const [selectedInvite, setSelectedInvite] = useState([]);
  const [optionList, setOptionList] = useState([]);
  const [changeTrigger, setChangeTrigger] = useState(false);

  const { firstName, organisationName } = user;
  const shareText = `invited you to their calendar, Would you like to accept the invite?`;
  const shareTextEnd = `Best regards,`;
  const shareLink =
    process.env.NODE_ENV == 'development'
      ? `${process.env.REACT_APP_API_URL}/bespoke/calendar/${id}`
      : `${process.env.REACT_APP_LIVE_API_URL}/bespoke/calendar/${id}`;

  const formattedString = `${firstName || organisationName} ${shareText}\n

  ${shareLink}\n

  ${shareTextEnd}\n

  ${firstName || organisationName}`;

  // Reset Multi select input values
  const resetData = () => {
    setSelectedInvite([]);
    setOptionList([]);
    setEmailSearch('');
  };
  // Search engine
  const getSearchData = (event) => {
    setChangeTrigger(true);
    setTimeout(async () => {
      setLoading(true);
      setChangeTrigger(false);
      if ((changeTrigger === false) & (event.target.value !== '')) {
        await client
          .get(`/Social/fetchuserconnection?search=${event.target.value}`)
          .then((res) => {
            setOptionList([...res?.data]);
            setLoading(false);
            console.log(res.data, 'Data');
          })
          .catch((err) => {
            setLoading(true);
            toast.error(err?.response?.data?.Message ?? err?.message);
          });
      } else {
        setOptionList([]);
      }
    }, 1000);
  };

  // Lectie invite send notification to users email list
  const handleLectieInvite = async (invitees) => {
    setLectieInviteLoading(true);
    let invitedUsers = invitees.map((user) => ({
      firstName: user?.firstName,
      userName: user?.userName,
    }));

    const data = {
      souvenirId: draft?.id,
      lectieUsers: [...invitedUsers],
    };

    await client
      .post(`/Account/lectie-users-invite`, data)
      .then((res) => {
        toast.success(
          `You have successfully invited “${invitees[0]?.email}” to your calendar`
        );
        setLectieInviteLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Message ?? err?.message);
        setLectieInviteLoading(false);
      });
  };
  return (
    <PrivateSucessPageConatiner>
      <div className="success-header">
        <h1>
          Congratulations, you have successfully created and invited your
          friends to your private calendar
        </h1>

        <div>
          <img src={PrivateSuccessImg} alt="Sucess picture" />
        </div>
      </div>

      <div className="invite-container">
        <h2>Invite your friends</h2>

        <div className="invite-switch-container">
          <div onClick={() => setInviteSwitcher('lectie')}>
            <span className="lectie">Lectie</span>
            {inviteSwitcher === 'lectie' && (
              <div className="switcher-divider"></div>
            )}
          </div>
          <div onClick={() => setInviteSwitcher('email')}>
            <span>Email</span>
            {inviteSwitcher === 'email' && (
              <div className="switcher-divider"></div>
            )}
          </div>
        </div>

        <div className="invite-invitee">
          <span className="invite-invitee-email">{user?.email}</span>
          <span>(invitees will receive your invitation via your email)</span>
        </div>
        <div className="form_container">
          <form className="invite-form">
            <div className="invite-form-input-container">
              <input
                type="text"
                placeholder="Invite via email"
                value={emailSearch}
                onChange={(e) => {
                  if (inviteSwitcher === 'lectie') {
                    setOpenSearch(true);
                    getSearchData(e);
                  } else {
                    setSelectedInvite([{ email: e.target.value }]);
                  }
                  setEmailSearch(e.target.value);
                }}
                required
              />
            </div>

            <button
              type="button"
              className="invite-form-btn"
              onClick={() => {
                if (selectedInvite.length > 0) {
                  if (inviteSwitcher === 'email') {
                    let emailList = selectedInvite.map((item) => item?.email);
                    emailInvite(
                      emailList,
                      `You have an invitation from ${
                        firstName || organisationName
                      }`,
                      `${formattedString}`
                    );
                    resetData();
                  } else {
                    handleLectieInvite(selectedInvite);
                    resetData();
                  }
                } else toast.warning('Please select some invitee(s)');
              }}
            >
              {(inviteSwitcher === 'lectie') & lectieInviteLoading ? (
                <RotatingLines
                  strokeColor="#fff"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={lectieInviteLoading}
                />
              ) : (
                'invite'
              )}
            </button>
          </form>

          {emailSearch !== '' && openSearch && inviteSwitcher === 'lectie' && (
            <div className="search_result">
              {!loading ? (
                optionList.length > 0 ? (
                  optionList.map((item) => (
                    <div
                      key={item?.userId}
                      className="search_result_item"
                      onClick={() => {
                        setSelectedInvite([item]);
                        setOpenSearch(false);
                        setEmailSearch(item?.userName);
                      }}
                    >
                      {' '}
                      <div className="search_result_item_img_container">
                        {item?.picture ? (
                          <img src={item?.picture} alt="User picture" />
                        ) : (
                          <div className="no-image"></div>
                        )}
                      </div>
                      <div className="search_result_item_text_container">
                        <div className="search_result_item_names">
                          {item?.organisationName ? (
                            <p>{item?.organisationName}</p>
                          ) : (
                            <>
                              <p>{item?.firstName}</p> <p>{item?.lastName}</p>{' '}
                            </>
                          )}
                        </div>
                        <p className="search_result_item_username">
                          @{item?.userName}
                        </p>{' '}
                      </div>
                    </div>
                  ))
                ) : (
                  <div>
                    <p>No Data Found</p>
                  </div>
                )
              ) : (
                <div className="search_result_spinner">
                  <RotatingLines
                    strokeColor="#2667B1"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="20"
                    visible={loading}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <button
        className="private-success-btn"
        onClick={() => navigate(`/dashboard`)}
      >
        {t('View Status')}
      </button>
    </PrivateSucessPageConatiner>
  );
};

export default PrivateSucessPage;

const PrivateSucessPageConatiner = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  .success-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    h1 {
      font-weight: bold;
      text-align: center;
      margin: 0 auto;
      width: 90%;
      font-size: 1.35rem;
    }
  }

  .invite-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;

    h2 {
      font-weight: bold;
      font-size: 1.25rem;
    }

    .invite-switch-container {
      display: flex;
      gap: 1.3rem;
      align-items: center;
      width: 90px;
      div {
        text-align: center;
        .switcher-divider {
          height: 3px;
          width: 100%;
          background-color: #2667b1;
        }
      }
    }

    .invite-invitee {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
      justify-content: center;

      .invite-invitee-email {
        color: #2667b1;
      }
    }
    .form_container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .invite-form {
        display: flex;
        align-items: center;
        min-height: 60px;
        width: 90%;
        margin: 0 auto;

        .invite-form-input-container {
          border-radius: 8px 0px 0px 12px;
          border: 1px solid #8c8c8c;
          height: 60px;
          width: 100%;

          input {
            border-radius: 8px 0px 0px 12px;
            height: 100%;
            border: none;
            outline: none;
            display: flex;
            justify-content: center;
            padding-left: 0.7rem;
            width: 100%;

            &:active,
            &:focus,
            &:hover {
              border: none;
              outline: none;
            }

            &::placeholder {
              color: #d3d3d3;
            }
          }
        }
      }

      .invite-form-btn {
        height: 60px;
        width: 170px;
        min-width: 80px;
        background-color: #2667b1;
        color: #fff;
        border-radius: 0px 8px 8px 0px;
        text-align: center;
      }
    }

    .search_result {
      width: 90%;
      margin: 0 auto;
      min-height: 150px;
      max-height: 350px;
      overflow-y: scroll;
      padding: 0.5rem;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      cursor: pointer;

      .search_result_spinner {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .search_result_item {
        padding-left: 1rem;
        display: flex;
        gap: 1rem;
        align-items: center;

        .search_result_item_img_container {
          width: 40px;
          height: 40px;

          .no-image {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: #8c8c8c;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        .search_result_item_text_container {
          display: flex;
          flex-direction: column;
          gap: 0.2rem;

          .search_result_item_names {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            color: #1e1e1e;
            font-weight: 400;
          }
          .search_result_item_username {
            color: #8c8c8c;
          }
        }
      }
    }
  }

  .private-success-btn {
    background-color: transparent;
    border: 1px solid #2667b1;
    padding: 16px 20px;
    max-width: 230px;
    min-width: 200px;
    margin: 0 auto;
    border-radius: 12px;
  }

  /* @media screen and (max-width: 540px) {
    .invite-form-input-container {
      .selection-box {
        flex-direction: column-reverse;
        .user_select,
        input {
          min-width: 100%;
          padding-right: 0.8rem;
        }
      }
    }
  } */
`;
