import React, { useRef } from 'react';
import styled from 'styled-components';
import BtnForgetPassword from './BtnForgetPassword';
import { useEffect } from 'react';

const InputField = ({
  inputValue,
  setInputValue,
  setEmailError,
  emailError,
  loading,

  text,
  func,
  isActive,
  setBtnIsActive,
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    // To control when button is active
    if (inputValue !== '') {
      setBtnIsActive(true);
    } else setBtnIsActive(false);
  }, [inputValue]);

  return (
    <InputFieldContainer error={emailError}>
      {emailError && (
        <p className="error-note">
          Sorry, we couldn't recognize the email address you entered
        </p>
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (
            document.activeElement !== inputRef.current &&
            inputValue.trim() !== '' &&
            !loading
          ) {
            func();
            console.log('Submit ---');
          }
        }}
      >
        <div className="input-container">
          <label>E-mail</label>
          <input
            type="email"
            placeholder="bespoke@lectie.com"
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
              setEmailError(false);
            }}
            ref={inputRef}
          />
        </div>

        <BtnForgetPassword text={text} isActive={isActive} loading={loading} />
      </form>
    </InputFieldContainer>
  );
};
export default InputField;

const InputFieldContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  justify-content: center;
  width: 100%;

  .error-note {
    background: rgba(251, 120, 120, 0.62);
    padding: 2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    font-family: Nunito;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(160, 31, 31, 1);
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 600px;
    width: 100%;

    .input-container {
      width: 100%;
    }

    form label {
      color: rgba(22, 22, 22, 1);
      font-family: Nunito;
      font-size: 1rem;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
    }

    input {
      border: 1px solid
        ${({ error }) =>
          error ? 'rgba(251, 120, 120, 0.62)' : 'rgba(22, 22, 22, 1)'};

      height: 57.05px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      font-family: Roboto;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: 0em;
      text-align: left;
      padding: 12px;
      width: 100%;

      &:focus {
        outline: none;
        border: 1px solid rgba(38, 103, 177, 0.6);
      }

      &::placeholder {
        color: rgba(211, 211, 211, 1);
      }
    }
  }
`;
