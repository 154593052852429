import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import './style/create.css';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { changeCoverImage } from '../../store/reducers/CurrentDraft';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toast } from 'react-toastify';
import client from '../../services';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {
  canvasPreview,
  extractImageFileExtensionFromBase64,
  UploadImageToS3,
} from '../../functions';

import { useDebounceEffect } from '../component/bespoke-core/calendarComponent/useDebounceEffect';
import { RotatingLines } from 'react-loader-spinner';
import { BsFillCloudUploadFill } from 'react-icons/bs';
import '../component/bespoke-core/calendarComponent/styles/uploadModal.css';
import Dialog from '@material-ui/core/Dialog';
import { seizer } from '../../functions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(1)',
    },
    '25%': {
      transform: 'scale(1.03)',
    },
    '50%': {
      transform: 'scale(1.06)',
    },
    '75%': {
      transform: 'scale(1.07)',
    },
    '100%': {
      transform: 'scale(1.1)',
    },
  },

  root: {
    background: '#fff',
    padding: '20px',
  },
  drop: {
    marginTop: 90,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  preview: {
    marginTop: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  loader: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  loaderSkeleton: {
    height: '18rem',
  },
  covers: {
    marginTop: 20,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  imageList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },

  btnContainer: {
    marginTop: 70,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  btn: {
    border: 'none',
    background: '#2667B1',
    color: '#fff',
    width: '160px',
    padding: '10px 15px',
    borderRadius: '8px',
  },

  floatBtn: {
    position: 'fixed',
    bottom: '100px',
    right: '3%',
    zIndex: '1000px',
    backgroundColor: '#2667B1',
    width: '4rem',
    height: '4rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',

    boxShadow: '0 0 0.5rem rgba(38, 103, 177,0.3)',
    animation: '$ripple 1.2s infinite ease-in-out',
  },

  floatBtnSvg: { fontSize: '1.5rem', color: '#ffffff' },
}));

const EditCoverImage = () => {
  const { t } = useTranslation(['dashboard']);
  const draftImage = useSelector((state) => state.draft);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [navRoute, setNavRoute] = useState(null);
  const [image, setImage] = useState(false);
  const [cover, setCover] = useState(draftImage?.coverImg?.src);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  // Crop Image
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [aspect, setAspect] = useState(16 / 9);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [temImg, setTemImg] = useState(null);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Upload function
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = async () => {
        // setLoading(true);
        // Send Content to server
        const binaryStr = reader.result;

        var i = new Image();
        i.onload = function () {
          setAspect(seizer(i.width, i.height));
          if (i.width < 1300 && i.height < 1300) {
            setCrop({
              unit: '%', // Can be 'px' or '%'
              x: 0,
              y: 0,
              width: 100,
              height: 100,
            });
          } else {
            setCrop(
              centerAspectCrop(i.width, i.height, seizer(i.width, i.height))
            );
          }
        };
        i.src = binaryStr;

        setTemImg(binaryStr);
        setCrop(undefined); // Set an immediate crop region
        handleClickOpen();
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    // open,
  } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.png'],
    },
  });

  const handleCoverImage = () => {
    if (cover !== null) {
      const obj = {
        id: 0,
        src: cover,
        coverType: 0,
      };
      dispatch(changeCoverImage(obj));
      navigate('/studio/event');
    }
  };

  // Image Function
  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 100,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }
  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      // setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  // Update Bespoke
  const updateBespoke = async (src) => {
    var updateData = {
      ...draftImage,
      coverImg: {
        ...draftImage?.coverImg,
        src: src,
      },
    };

    console.log(JSON.stringify(updateData, 0, 2), 'update data');

    await client
      .put(`/Souvenir/bespoke-update?souvenirId=${draftImage.id}`, updateData)
      .then((res) => {
        setCover(src);
        setNavRoute('studio/event');
        setLoading(false);
        handleClose();
      })
      .catch((e) => {
        console.log(e);
        toast.error(t('Bespoke update failed, Please try again'));
        setLoading(false);
        handleClose();
      });
  };

  // Upload image
  const uploadCover = async () => {
    setLoading(true);
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imgRef.current
      // &&
      // previewCanvasRef.current
    ) {
      // const canvasRef = previewCanvasRef.current;

      const canvas = document.createElement('canvas');
      canvasPreview(imgRef.current, canvas, completedCrop);

      const fileext = extractImageFileExtensionFromBase64(temImg);
      const imageData64 = canvas.toDataURL('image/' + fileext);
      // console.log(imageData64, 'image');

      canvas.toBlob(
        async function (blob) {
          await client
            .post('/Souvenir/image-upload', imageData64)
            .then((res) => {
              if (draftImage.templateId) {
                updateBespoke(res?.data?.picture);
              } else {
                setCover(res?.data?.picture);
                setNavRoute('studio/event');
                setLoading(false);
                handleClose();
              }
            })
            .catch((e) => {
              console.log(e);
              toast.error(
                t(
                  'Image upload failed, Please try again with a smaller Image file'
                )
              );
              setLoading(false);
              handleClose();
            });
        },
        'image/jpeg',
        0.5
      );
    } else {
      console.log('error');
      // toast.error("");
    }
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <div className={classes.loader}>
          <Grid item xs={10} sm={10} md={8} lg={8}>
            <Skeleton variant="rect" className={classes.loaderSkeleton} />
          </Grid>
        </div>
      ) : !cover ? (
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.drop}>
          <div {...getRootProps()} className="drop-zone">
            <input {...getInputProps()} />
            {isDragAccept && <p>{t('Drop the calendar cover here...')}</p>}
            {isDragReject && (
              <p>{t('calendar cover ought to be a png file')}</p>
            )}
            {!isDragActive && (
              <Typography variant="body2" color="textSecondary" component="p">
                {t('Select or Drop your cover image here')}
              </Typography>
            )}
          </div>
        </Grid>
      ) : (
        <div className={classes.preview} {...getRootProps()}>
          <input {...getInputProps()} />
          <Grid item xs={10} sm={10} md={8} lg={8} onClick={() => open}>
            <LazyLoadImage
              className="cover-image"
              alt={'Image cover'}
              effect="blur"
              src={cover}
            />
          </Grid>
        </div>
      )}

      <div className={classes.btnContainer}>
        <button
          type="button"
          className={classes.btn}
          onClick={() => handleCoverImage()}
        >
          {t('Upload')}
        </button>
      </div>

      <Dialog
        // fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid item md={12} lg={12}>
          <ReactCrop
            style={{ width: '100%', height: '100%', overflowY: 'scroll' }}
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            aspect={aspect}
            locked={true}
            onComplete={(c) => setCompletedCrop(c)}
            className="react_crop"
            // disabled={true}
          >
            <img
              src={temImg}
              loading="lazy"
              style={{ width: '100%', height: '100%' }}
              ref={imgRef}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </Grid>

        <section className={classes.floatBtn} onClick={() => uploadCover()}>
          {loading ? (
            <RotatingLines
              strokeColor="#fff"
              strokeWidth="5"
              animationDuration="0.75"
              width="20"
              visible={loading}
            />
          ) : (
            <BsFillCloudUploadFill className={classes.floatBtnSvg} />
          )}
        </section>
      </Dialog>
    </div>
  );
};

export default EditCoverImage;
