import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  customColors: [
    {
      id: 1,
      color: '#FFDAC0',
      label: 'Birthday',
      customId: 1,
    },
    {
      id: 2,
      color: '#FFEDAC',
      label: 'Anniversary ',
      customId: 2,
    },
    { id: 3, color: '#BAFFC1', label: 'Meetings', customId: 3 },
    { id: 4, color: '#B4DFFF', label: 'Work out', customId: 4 },
    { id: 5, color: '#FDB8B8', label: 'Home work', customId: 5 },
    { id: 6, color: '#FFCEEC', label: 'Holiday', customId: 6 },
  ],
};

export const customColorState = createSlice({
  name: 'customColorState',
  initialState,
  reducers: {
    loadCustomColorState: (state, { payload }) => {
      let obj = {};
      for (let color of payload?.customColors) {
        obj[color.customId] = color;
      }

      return {
        ...initialState,
        customColors: { ...obj },
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { loadCustomColorState } = customColorState.actions;

export default customColorState.reducer;
