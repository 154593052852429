import styled from 'styled-components';
import BtnVariantGroup from '../../../ButtonGroup/BtnVariantGroup';
import ProgramLink from '../../../../../assest/images/program_link.png';

export const VariantGroup = ({
  btnName,
  iconName,
  variantNum,
  variantNam,
  setFormData,
}) => {
  return (
    <VariantGroupContainer>
      <BtnVariantGroup label={btnName} icon={iconName} variant={variantNum} />

      <div className="selection">
        {[1, 2, 3, 4].map((val) => (
          <span
            key={val}
            style={
              variantNum === val
                ? { backgroundColor: '#2667B1', color: '#fff' }
                : null
            }
            onClick={() =>
              setFormData((prevState) => ({
                ...prevState,
                [variantNam]: val,
              }))
            }
          >
            {val}
          </span>
        ))}
      </div>
    </VariantGroupContainer>
  );
};

export const InputField = ({
  placeholder,
  name,
  value,
  handleChange,
  errForm,
  setErrForm,
}) => {
  return (
    <GlobalContainer>
      <InputContainer>
        <input
          type="text"
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={(e) => {
            handleChange(e);
            setErrForm((prevState) => ({
              ...prevState,
              [name]: false,
            }));
          }}
          required
        />
      </InputContainer>
      {errForm[`${name}`] === true && (
        <span className="err_msg">Please fill this field</span>
      )}
    </GlobalContainer>
  );
};

export const InputFieldLink = ({
  formData,
  name,
  placeholder,
  handleChange,
  setErrForm,
  errForm,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '1.2rem',
      }}
    >
      <span className="img_icon">
        <img src={ProgramLink} alt="dot for title" className="dot_img" />
      </span>
      <div
        className="form_input_title"
        style={{
          width: '100%',
        }}
      >
        <InputField
          placeholder={placeholder}
          name={name}
          value={formData[`${name}`]}
          handleChange={handleChange}
          errForm={errForm}
          setErrForm={setErrForm}
        />
      </div>
    </div>
  );
};

export const Episode = ({ value, name, handleChange, errForm, setErrForm }) => {
  return (
    <GlobalContainer>
      <EpisodContainer>
        <input
          type="text"
          value={value}
          name={name}
          onChange={(e) => {
            handleChange(e);
            setErrForm((prevState) => ({
              ...prevState,
              [name]: false,
            }));
          }}
          required
        />
      </EpisodContainer>
      {errForm[`${name}`] === true && (
        <span className="err_msg">Please fill this field</span>
      )}
    </GlobalContainer>
  );
};

export const setInvalid = (list, formData, setErrForm) => {
  for (let item of list) {
    if (formData[`${item}`] === '' || formData[`${item}`] === ' ') {
      setErrForm((preState) => ({
        ...preState,
        [item]: true,
      }));
    }
  }
};

// STYLES
const VariantGroupContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  .selection {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    span {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #2667b1;
      font-size: 0.8rem;
      cursor: pointer;
      padding: 0.55rem 0.6rem;
      display: flex;

      align-items: center;
      justify-content: center;
    }
  }
`;

const InputContainer = styled.section`
  border-bottom: 1px solid #161518;
  width: 100%;
  input {
    border: none;
    width: 100%;
    &:focus,
    &:hover,
    &:active {
      border: none;
      outline: none;
    }
  }
`;

const EpisodContainer = styled.section`
  input {
    width: 55px;
    background: #d9d9d9;
    padding: 5px 3px;

    &:focus,
    &:hover,
    &:active {
      border: none;
      outline: none;
    }
  }
`;

const GlobalContainer = styled.section`
  width: 100%;
  .err_msg {
    color: red !important;
  }
`;
