import React, { useEffect, useState } from 'react';
import { getMonth } from '../../../util';
import { setMonthNumber } from '../../../store/features/monthSlice';
import { setSmallMonthNumber } from '../../../store/features/smallMonthSlice';
import { useSelector, useDispatch } from 'react-redux';
import EventModal from './calendarComponent/EventModal';
import {
  CalendarMainConatiner,
  CalendarMainConatinerCover,
  CalendarWrapper,
} from './calendarComponent/styles/calendar.Styles';
import CalendarHeader from './calendarComponent/CalendarHeader';

import { useLocation, useNavigate } from 'react-router-dom';
import {
  setShowAddMonthImages,
  setShowEventModel,
  setShowMonthModel,
  setShowTimeModel,
} from '../../../store/features/modalSlice';
import './calendarLanding.css';
import phone from '../../../assest/images/phone.png';
import gmail from '../../../assest/images/mail.png';
import ln from '../../../assest/images/linked-icon-white.svg';
import twitter from '../../../assest/images/twitter-logo-white.svg';
// import dunno from "../../../assest/images/dunno.jpeg";
import fb from '../../../assest/images/facebook-logo-white.svg';
import logo from '../../../assest/images/logo-big.svg';
import location from '../../../assest/images/location.svg';
import instagram from '../../../assest/images/Instagram.svg';
import youtube from '../../../assest/images/youtube.png';
import backgroundImage from '../../../assest/images/background.jpg';
import { t } from 'i18next';
import { generate, statusUpdate } from '../../../store/reducers/CurrentDraft';
import { setCalendarMonth } from '../../../store/features/calendarSlice';
import { setTVDraft } from '../../../store/reducers/draftTVEvents';
import { RotatingLines } from 'react-loader-spinner';
import client from '../../../services';

function BespokeCoreCover() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const draft = useSelector((state) => state.draft);
  const [processing, setProcessing] = useState(false);
  const beskpoke = useSelector((state) => state.bespoke);

  const { state } = useLocation();

  const { selectedEvent, yearPicked, months } = useSelector(
    (state) => state.calendar
  );

  const [currentMonth, setCurrentMonth] = useState(getMonth(0, yearPicked));
  const { monthNumber } = useSelector((state) => state.month);
  const { showEvent, showAddMonthImages } = useSelector(
    (state) => state.calendarModal
  );

  // To redirect to dasboard when array for mont is empty
  useEffect(() => {
    if (draft['months']?.length <= 0) {
      navigate('/dashboard/create');
    }
    dispatch(setShowEventModel(false));
    dispatch(setShowTimeModel(false));
    dispatch(setShowMonthModel(false));
    dispatch(setShowAddMonthImages(false));
  }, []);

  useEffect(() => {
    setCurrentMonth(getMonth(monthNumber, yearPicked));
  }, [monthNumber]);

  useEffect(() => {
    dispatch(setMonthNumber(0));
    dispatch(setSmallMonthNumber(0));
  }, [dispatch]);

  // Recall draft if created.(Preview)
  const handleGetDraftPreview = async (id) => {
    console.log('Draft id tem ', id);
    await client
      .get(`/Souvenir/draft/${id}`)
      .then((res) => {
        dispatch(generate(res?.data));
        dispatch(setCalendarMonth(res?.data?.months));
        setProcessing(false);
        navigate('/draft/view');
        console.log('Draft id tem res ', res?.data);
      })
      .catch((err) => {
        console.log(err?.response?.data?.Message ?? err?.message);
        setProcessing(false);
      });
  };

  // Save Function
  const save = async (beskpoke) => {
    setProcessing(true);
    var data = {
      ...beskpoke,
    };

    console.log(data, 'Draft before save');

    await client
      .post('/Souvenir/create-bespoke', beskpoke)
      .then((res) => {
        dispatch(statusUpdate(res?.data));
        dispatch(setCalendarMonth(res?.data?.months));
        dispatch(generate(beskpoke));
        console.log('After savcv', res?.data);

        handleGetDraftPreview(res?.data?.id);
      })
      .catch((e) => {
        console.log(e);
        console.log(e?.response?.data?.Message ?? e?.message);
        setProcessing(false);
      });
  };

  let bgImg = backgroundImage;

  return (
    <>
      <CalendarHeader />

      <CalendarMainConatinerCover
        className="main"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, 0.5), rgba(0,0,0,0.5)), url(${draft?.coverImg?.src})`,
        }}
      >
        <div className="sub-container">
          <div className="centered-content">
            <div>
              <p className="fashion">{beskpoke?.templateName}</p>
              <h1>{beskpoke?.yearPicked}</h1>
            </div>
          </div>

          <div className="btn_container">
            <button
              type="button"
              className="text-white bg-[#2667B1] hover:bg-[#2667B1]/55 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-20 py-2   dark:bg-[#2667B1] dark:hover:bg-[#2667B1] focus:outline-none "
              onClick={() => {
                save(state?.data);
              }}
            >
              {t('Create')}{' '}
              {processing && (
                <RotatingLines
                  strokeColor="#fff"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={processing}
                />
              )}
            </button>
          </div>
        </div>
      </CalendarMainConatinerCover>
    </>
  );
}

export default BespokeCoreCover;
