import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  makeStyles,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  Button,
  TextField,
  FormHelperText,
} from '@material-ui/core';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { RotatingLines } from 'react-loader-spinner';
import MasterCard from '../../assest/images/master_card.svg';
import VisaCard from '../../assest/images/visa.svg';
import VerveCard from '../../assest/images/verve.svg';
import DiscoverCard from '../../assest/images/discover.svg';
import './common.css';
import { toast } from 'react-toastify';
import client from '../../services';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../helpers/firebase';
import { updateUserSub } from '../../store/reducers/userReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import UseFlutterwave from '../upgrade-summary/service/useFlutterwave';
import { getCountyCode, userGeolocation } from '../../functions';

const CARD_OPTIONS = {
  style: {
    base: {
      fontSize: '14px',
      color: '#424770',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: 'grey',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

// Material Ui Styles
const useStyles = makeStyles((theme) => ({
  TextInput: {
    borderRadius: 10,
    width: '100%',
  },
  btn: {
    clear: 'both',
    border: 'none',
    color: '#fff',
    maxWidth: '100px',
    width: '70%',
    height: '30px',
    margin: '0 auto',
    background: '#2667b1',
    borderRadius: '12px',
    cursor: 'pointer',
    '& .MuiButton-label': {
      fontSize: '14px',
    },
  },
  img: {
    display: 'flex',
    flexDirection: 'row',
  },
  warning: {
    color: '#ea0f0f',
    fontFamily: 'Roboto',
    fontSize: '0.8rem',
  },
}));

// Price Symbol

function PaymentForm(props) {
  const { t } = useTranslation(['dashboard']);

  const dispatch = useDispatch();
  const params = useParams();
  const {
    func,
    user,
    selected,
    currency,
    variation,
    userLocation,
    flutterDetails,
    loadFlutter,
    setLoadflutter,
    isCheckout,
  } = props;

  // const [flutterDetails, setFlutterDetails] = useState({});
  const [subscriptionRes, setSubscriptionRes] = useState({});

  const [loading, setLoading] = useState(false);

  console.log('Currency ', currency);
  console.log('Props ', props);

  const classes = useStyles();
  const [processing, setProcessing] = useState(false);

  // Stripe Payment
  const stripe = useStripe();
  const elements = useElements();

  // Fetch Subscription
  const fetchSubscription = async (subName) => {
    console.log('SubName ', subName);
    const currentUserLocation = new userGeolocation().getUserCountry();
    let { CountryCode } = getCountyCode(currentUserLocation) || 'NGN';

    console.log(currentUserLocation, ' User location in payment Form');

    await client
      .get(`/Subscription/fetchSubscription/${subName}/${CountryCode}`)
      .then((res) => {
        setSubscriptionRes(res.data);
        // setSubPrice(res.data.price);
        initializeFlutterwave(res?.data?.id);
      })
      .catch((error) => {
        toast.error(error.response?.data?.Message ?? error?.message);
      });
  };

  useEffect(() => {
    // only calls when not
    // coming from checkout page
    if (!isCheckout) {
      fetchSubscription(selected?.subscriptionName);
    }
  }, []);

  const initializeFlutterwave = async (subId) => {
    setLoading(true);

    await client
      .post('/Subscription/intializeFlutterWavePayment', {
        qty: 1,
        subscriptionID: subId,
        souvenirID: beskpokeDraft?.id,
      })
      .then((res) => {
        setLoading(false);
        res?.data;
        setFlutterDetails(res?.data);

        console.log(
          {
            ...res?.data,
            qty: 1,
            subscriptionID: subId,
            souvenirID: selected?.id,
          },
          ' init Futter'
        );

        // setCurrencyValue(flutterDetails?.currency);
      })
      .catch((error) => {
        toast.error(error.response?.data?.Message ?? error?.message);
        setLoading(false);
      });
  };

  const handleSubmit = async (e) => {
    setProcessing(true);
    e.preventDefault();
    const card = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: card,
      billing_details: {
        email: user.email,
        phone: user.phone ? user.phone : null,
      },
    });

    if (!error) {
      // console.log(paymentMethod, 'payment');
      const { id } = paymentMethod;
      const data = {
        subscription: selected?.id,
        bespokeId: params?.id,
        paymentId: id,
        currency: currency,
      };
      // Sucess function
      // Success Function should show only when successful
      // func();
      console.log(data, 'Payment intent');
      await client
        .post(`/Subscription/verifySubscriptionPayment/`, {
          ...data,
        })
        .then((res) => {
          setProcessing(false);
          logEvent(analytics, 'Payments', { payment_id: id ?? '' });
          dispatch(updateUserSub(selected));

          // Sucess function
          func();
        })
        .catch((e) => {
          console.log(e);
          setProcessing(false);
          toast.error(e.response?.data?.Message ?? e?.message);
        });
    } else {
      console.log(error.message);
      setProcessing(false);
      toast.error(error.response?.data?.Message ?? error?.message);
    }
  };

  return (
    <React.Fragment>
      <Typography style={{ textAlign: 'center' }} variant="h6" gutterBottom>
        {t('Card Details')}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {currency !== 'NGN' && (
            <Grid>
              <Grid item xs={12} md={12}>
                <label>{t('Card Number')}</label>
                <CardNumberElement
                  id="cardNumber"
                  className={classes.cardNumber}
                  options={CARD_OPTIONS}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <label>{t('Expiry Date')}</label>
                <CardExpiryElement
                  id="expiry"
                  // onBlur={logEvent('blur')}
                  // onChange={logEvent('change')}
                  // onFocus={logEvent('focus')}
                  // onReady={logEvent('ready')}
                  options={CARD_OPTIONS}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <label>CVV</label>
                <CardCvcElement
                  id="cvc"
                  // onBlur={logEvent('blur')}
                  // onChange={logEvent('change')}
                  // onFocus={logEvent('focus')}
                  // onReady={logEvent('ready')}
                  options={CARD_OPTIONS}
                />
                <FormHelperText>
                  {t('Last three digits on signature strip')}
                </FormHelperText>
              </Grid>
              <Grid container>
                <Grid item md={4} lg={4} xl={4}>
                  <p>{t('Cards we accept')}</p>
                </Grid>
                <Grid item md={8} lg={10} xl={10} className={classes.img}>
                  <Grid item md={2}>
                    <img src={MasterCard} alt="master card" />
                  </Grid>
                  <Grid item md={2}>
                    <img src={VisaCard} alt="visa card" />
                  </Grid>
                  <Grid item md={2}>
                    <img src={VerveCard} alt="verve card" />
                  </Grid>
                  <Grid item md={2}>
                    <img src={DiscoverCard} alt="discover card" />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography className={classes.warning}>
                  {t(
                    'Warning!!: We don’t save any information about you and neither does our payment gateway. Lectie will never ask you for any private details.'
                  )}
                </Typography>
              </Grid>
            </Grid>
          )}

          {/*
          Summary Section
          */}
          <section className="payment_summary_container">
            <h2>{t('Payment Summary')}</h2>
            <div className="summary">
              <div className="summary_item">
                <span className="bold_tag">{t('Plan')}: </span>{' '}
                <p> {selected?.subscriptionName}</p>
              </div>
              <div className="summary_item">
                {' '}
                <span className="bold_tag">{t('Amount')}: </span>
                <p>
                  {selected?.currency} {selected?.price}
                </p>
              </div>
            </div>
            <div className="summary">
              <div className="summary_item">
                <span className="bold_tag">{t('Billing')}: </span>{' '}
                <p> 1 {variation ? 'Year' : 'Month'}</p>
              </div>
            </div>

            <div className="total_container">
              <span className="total_tag">{t('Total Amount')}: </span>
              <p>
                {selected?.currency} {selected?.price}
              </p>
            </div>
          </section>
          <Grid item xs={12}>
            {console.log(currency, ' This is currency here here === ')}
            {currency !== 'NGN' ? (
              <Button
                className={classes.btn}
                disabled={processing || !stripe}
                startIcon={
                  <RotatingLines
                    strokeColor="#fff"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="20"
                    visible={processing}
                  />
                }
                variant="contained"
                color="primary"
                style={{ textTransform: 'none' }}
                onClick={(e) => handleSubmit(e)}
              >
                {processing ? t('Processing') : t('Proceed')}
              </Button>
            ) : (
              <UseFlutterwave
                details={flutterDetails}
                loading={loading}
                loadFlutter={loadFlutter}
                setLoadflutter={setLoadflutter}
              />
            )}
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}

export default PaymentForm;
