import React from 'react';
import styled from 'styled-components';

const UserPrompt = ({ func }) => {
  return (
    <UserPromptContainer
      onClick={() => {
        func();
      }}
    >
      <section className="conent-card">
        <p>
          Get a first glimpse of your calendar! You can personalize it with
          pictures and add your events after publishing.
        </p>

        <button
          onClick={() => {
            func();
          }}
        >
          Continue
        </button>
      </section>
    </UserPromptContainer>
  );
};

const UserPromptContainer = styled.section`
  background-color: rgba(0, 0, 0, 0.7);

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;

  .conent-card {
    width: 80%;
    margin: 0 auto;
    background-color: #fff;
    padding: 4rem 1rem;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    gap: 4rem;

    p {
      text-align: center;
      width: 90%;
      margin: 0 auto;

      font-size: 1.5rem;
      font-weight: 400;
      line-height: 38px;
      letter-spacing: 0em;
    }

    button {
      width: 200px;
      height: 52px;
      padding: 15px 67px 15px 68px;
      border-radius: 8px;
      background-color: #2667b1;
      align-self: center;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
export default UserPrompt;
