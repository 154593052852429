import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HeaderContainer } from './Header.style';
import Logo from '../../../assest/images/logo-white.svg';
import { Button, makeStyles } from '@material-ui/core';

import { useUser } from '../../../authenicationProvider';
import Profile from '../../../assest/images/menu_drop_profile.svg';
import Vault from '../../../assest/images/menu_drop_vault.svg';
import Logout from '../../../assest/images/menu_drop_logout.svg';
import { useSelector, useDispatch } from 'react-redux';
import { statusUpdate, generate } from '../../../store/reducers/CurrentDraft';
import { loadBespoke } from '../../../store/reducers/bespokeView';
import client from '../../../services';
import { RotatingLines } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { FiCodesandbox } from 'react-icons/fi';
import { Avatar } from '@material-ui/core';
import ProfileDropDown from '../profile_drop_down/ProfileDropDown';
import { setCalendarMonth } from '../../../store/features/calendarSlice';
import { updateUsage } from '../../../store/reducers/userReducer';
import selectTemplate from '../../../helpers/templateSelector';
import { useTranslation } from 'react-i18next';
import {
  setLoadingDraft,
  setTVDraft,
} from '../../../store/reducers/draftTVEvents';
import SucessPage from '../../checkout/SucessPage';

// Button styles
const useStyles = makeStyles((theme) => ({
  btn: {
    background: '#43abf9',
    color: '#fff',
    padding: '6px 8px 6px 8px',
    marginRight: 5,
    '&:focus': {
      background: '#43abf0',
    },
    '&:hover': {
      background: '#43abf0',
    },
    '& .MuiButton-label': {
      fontSize: '12px',
    },
    '&:disabled': {
      color: '#fff',
    },
  },
}));
const Header = () => {
  const { t } = useTranslation(['dashboard']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dropDown, setDropDown] = useState(false);
  const { setUser } = useUser();

  const [draftState, setDraftState] = useState(false);

  // Profile menu show
  const handleProfileShow = () => {
    setProfileShow(false);
  };

  // Loading State
  const [processing, setProcessing] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [profileShow, setProfileShow] = useState(false);
  const beskpokeDraft = useSelector((state) => state.draft);
  const user = useSelector((state) => state.user);
  const [showSuccessPage, setShowSuccessPage] = useState(false);

  // mock subscription
  var sub = {
    ...user.subscription,
  };
  const [subscription] = useState(sub);
  const classes = useStyles();

  // Logout

  const logOut = () => {
    // Facebook Signout
    if (user.type == 'fb') {
      window.FB.api('/me/permissions', 'delete', null, () =>
        window.FB.logout(function (response) {
          // user is now logged out
          // console.log(response, 'user status');
        })
      );
    }
    // Google Signout
    if (user.type == 'google') {
      googleLogout();
    }
    let tokenKey = 'auth_token';
    localStorage.removeItem(tokenKey);
    localStorage.removeItem('mobileAuth');
    setUser(false);
    navigate('/');
  };

  // Publish Function
  const publishFunc = async () => {
    setPublishing(true);
    // Call Publish API
    console.log('BspDraft ', beskpokeDraft);

    dispatch(loadBespoke(beskpokeDraft));
    const bespokeId = beskpokeDraft?.id;

    await client
      .post(`/Souvenir/souvenirpublish?souvenirId=${bespokeId}`)
      .then((res) => {
        console.log('Publish clicked ', res);
        // dispatch(statusUpdate(res?.data));
        setPublishing(false);
        dispatch(updateUsage());
        if (beskpokeDraft?.calender === 'private') {
          navigate(`/dashboard/success/view`);
        } else {
          navigate(`/bespoke/calendar/${bespokeId}`);
        }
      })
      .catch((e) => {
        console.log(e);
        console.log(e?.response?.data?.Message ?? e?.message);
        setPublishing(false);
      });
  };

  return (
    <HeaderContainer>
      <div className="main-wrapper">
        <div className="logo-container">
          <img src={Logo} alt="logo lectie" onClick={() => navigate('/')} />
        </div>

        <div className={`right-side `}>
          <div className={`${dropDown ? 'header1' : 'hide_header1'}`}>
            {subscription &&
            user.isvalid &&
            ((beskpokeDraft?.calendarPlan === 'Basic'
              ? user?.calender_usage > 0
              : user[
                  `${beskpokeDraft?.calendarPlan.toLowerCase()}_calender_usage`
                ] > 0) ||
              beskpokeDraft?.status === 'published') ? (
              <Button
                disabled={processing}
                className={classes.btn}
                startIcon={
                  <RotatingLines
                    strokeColor="#fff"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="20"
                    visible={processing || publishing}
                  />
                }
                variant="contained"
                style={{ textTransform: 'none' }}
                onClick={() => {
                  publishFunc();
                }}
              >
                {publishing
                  ? publishing
                    ? t('Publishing')
                    : t('Publish')
                  : processing
                  ? t('Saving')
                  : t('Publish')}
              </Button>
            ) : (
              <Button
                disabled={processing}
                className={classes.btn}
                startIcon={
                  <RotatingLines
                    strokeColor="#fff"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="20"
                    visible={processing}
                  />
                }
                variant="contained"
                style={{ textTransform: 'none' }}
                onClick={() => {
                  console.log(beskpokeDraft, 'Clicked Pay to puiblish');
                  navigate(`/checkout/${beskpokeDraft?.id}`);
                  // publishFunc();
                  setDropDown(false);
                }}
              >
                {processing ? t('Saving') : t('Pay to publish')}
              </Button>
            )}

            {beskpokeDraft?.status !== 'draft' && (
              <Button
                disabled={processing}
                className={classes.btn}
                variant="contained"
                style={{ textTransform: 'none' }}
                onClick={() => {
                  console.log(beskpokeDraft, 'Draft from edit');
                  dispatch(generate(beskpokeDraft));
                  dispatch(setCalendarMonth(beskpokeDraft?.months));
                  navigate(`/studio/event`);
                }}
              >
                {draftState ? (
                  <RotatingLines
                    strokeColor="#fff"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="20"
                    visible={draftState}
                  />
                ) : (
                  t('Edit')
                )}
              </Button>
            )}
          </div>

          <FiCodesandbox
            className="box-view"
            onClick={() => {
              setDropDown((prevState) => !prevState);
              setProfileShow(false);
            }}
          />

          <div className="profile-image heading_profile_image">
            {user?.picture ? (
              <Avatar
                alt="Picture"
                src={`${user?.picture}?${new Date()}`}
                className="smallImg"
                onClick={() => {
                  setProfileShow((prevState) => !prevState);
                  setDropDown(false);
                }}
              />
            ) : (
              <Avatar
                className="smallImg"
                onClick={() => {
                  setProfileShow((prevState) => !prevState);
                  setDropDown(false);
                }}
              >
                {user?.firstName !== ''
                  ? user?.firstName[0]
                  : user?.organisationName[0].toUpperCase()}
              </Avatar>
            )}
            {profileShow && (
              <div>
                <div
                  className="back_profile"
                  onClick={() => setProfileShow(false)}
                ></div>

                <ProfileDropDown move="-40" func={handleProfileShow} />
              </div>
            )}
          </div>
        </div>
      </div>
    </HeaderContainer>
  );
};

export default Header;
