import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import UploadMonthCover from '../UploadMonthCover';
import { makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  setShowAddMonthImages,
  setShowEventModel,
  setShowMonthModel,
} from '../../../../../store/features/modalSlice';
import { AddMonth } from '../../../../../store/reducers/CurrentDraft';
import ButtonColoured from '../../utils/utilComponents/ButtonColored';
import { buttonBlueColor } from '../../globalStyles/globalStyles.styles';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import dayjs from 'dayjs';
import {
  setCountNextYears,
  setCountPrevYears,
  setMonthNumberFromYear,
  setNextMonthNumber,
  setPrevMonthNumber,
} from '../../../../../store/features/monthSlice';
import Button from '../../utils/utilComponents/Button';
import { FiChevronLeft, FiChevronRight, FiEdit } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import DayComponent from './DayComponent';
import EventFormTvGuide from './EventFormTvGuide';
import {
  DeleteDateCalendarYear,
  addUpdateCalendarYears,
  getCalendarEvent,
  removeSelectedEvent,
  setSelectedEvent,
  sortMonths,
} from '../../../../../store/features/calendarSlice';
import {
  setEndDate,
  setSelectedDate,
} from '../../../../../store/features/timeSlice';
import FooterTvGuide from './FooterTvGuide';
import client from '../../../../../services';
import RectSkeleton from '../../../../template/RectSkeleton';
import RectSkeletonLoader from '../../../../template/RectSkeleton';

const useStyles = makeStyles((theme) => ({
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(1)',
    },
    '25%': {
      transform: 'scale(1.03)',
    },
    '50%': {
      transform: 'scale(1.06)',
    },
    '75%': {
      transform: 'scale(1.07)',
    },
    '100%': {
      transform: 'scale(1.1)',
    },
  },

  loader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  loaderSkeleton: {
    height: '7rem',
    width: '100%',
  },
  next: {
    background: '#2667B1',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2666B1',
    },
    previewImage: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    btnCon: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
    },
    previewImage: {},
  },
  floatBtn: {
    position: 'fixed',
    bottom: '100px',
    right: '3%',
    zIndex: '1000px',
    backgroundColor: '#2667B1',
    width: '4rem',
    height: '4rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',

    boxShadow: '0 0 0.5rem rgba(38, 103, 177,0.3)',
    animation: '$ripple 1.2s infinite ease-in-out',
  },

  floatBtnSvg: { fontSize: '1.5rem', color: '#ffffff' },
}));

const TvGuideStudio = ({ month }) => {
  const { t } = useTranslation(['dashboard']);
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { months, yearPicked } = useSelector((state) => state.calendar);
  const draft = useSelector((state) => state.draft);
  const [monthDate, setMonthDate] = useState(month);
  const [showForm, setShowForm] = useState(false);
  const [yearsInCalendar, setYearsInCalendar] = useState([]);
  const [numberOfYears, setNumberOfYears] = useState(months.length / 12);
  const { monthNumber, countNumberOfYears } = useSelector(
    (state) => state.month
  );

  const [loadingYear, setLoadingYear] = useState(false);

  let dateFormated = dayjs(new Date(dayjs().year(), monthNumber)).format(
    'YYYY MMMM'
  );

  const saveAllPresentYear = () => {
    console.log(months, ' Months');
    let allYear = [];
    for (const month of months) {
      allYear.includes(dayjs(month?.date).format('YYYY'))
        ? null
        : allYear.push(dayjs(month?.date).format('YYYY'));
    }

    setNumberOfYears(allYear.length);

    setYearsInCalendar(allYear);
  };

  const handleGetMoreYear = async (curYearVal, { chevron } = {}) => {
    const bespokeId = draft?.id;

    if (chevron === true) {
      setLoadingYear(true);
    }
    // Check year presence
    if (!yearsInCalendar.includes(curYearVal)) {
      // Call this only when it the year Switcher Right chevron clicked

      // Perform api call
      await client
        .post(
          `/Souvenir/create-bespokeMonth/${bespokeId}?CurrentYear=${curYearVal}`
        )
        .then((res) => {
          console.log(
            ' Next  year Called ',
            res.data?.months,
            ' In year not pesent'
          );
          dispatch(addUpdateCalendarYears(res.data?.months));
          setLoadingYear(false);
        })
        .catch((err) => {
          console.log(err, ' Error');
          setLoadingYear(false);
        });
    } else {
      await client
        .post(
          `/Souvenir/create-bespokeMonth/${bespokeId}?CurrentYear=${curYearVal}`
        )
        .then((res) => {
          console.log(' Prev  year Called ', res.data?.months);

          // Delete Year (Next year)
          dispatch(DeleteDateCalendarYear(curYearVal));

          dispatch(addUpdateCalendarYears(res.data?.months));
          dispatch(sortMonths());

          setLoadingYear(false);
        })
        .catch((err) => {
          console.log(err, ' Error');
          setLoadingYear(false);
        });
    }
  };

  useEffect(() => {
    if (draft['months'].length <= 0) {
      navigate('/dashboard/create');
    } else {
      dispatch(AddMonth(months));
    }
    // saveAllPresentYear();
    setShowAddMonthImages(false);
  }, [months]);

  const handlePreview = () => {
    navigate('/draft/view');
  };

  const handleEvent = (date) => {
    //  To set day clicked  in Obj format
    dispatch(removeSelectedEvent(null));
    dispatch(setSelectedDate(date));
    dispatch(setEndDate(date));
    setShowForm(true);
  };

  const handleEventEdit = (event, details) => {
    // Passing Id of event)

    dispatch(setSelectedEvent(details));
    dispatch(setSelectedDate(details?.startDate));
    // dispatch(getCalendarEvent(event));
    setShowForm(true);
  };

  return (
    <TvGuideContainer monthLegth={monthDate.length}>
      <section className="guide_content">
        <section className="header-tv-studio">
          <div className="preview">
            <ButtonColoured
              content={
                draft?.status === t('published') ? t('Preview') : t('Save')
              }
              color={buttonBlueColor}
              func={handlePreview}
            />
          </div>

          <UploadMonthCover
            monthNumber={monthNumber}
            draft={draft}
            months={months}
          />

          <section className="calendar_container">
            {/* Year Switcher */}
            <div className="month-inc-dec flex a-center">
              {countNumberOfYears > 1 && monthNumber != 0 && (
                <>
                  {!loadingYear && (
                    <Button
                      content={<FiChevronLeft />}
                      func={() => {
                        let curYearVal = dayjs(
                          months[monthNumber]?.date
                        ).format('YYYY');

                        const prevYear = new Date(
                          new Date().setFullYear(
                            new Date(curYearVal.toString()).getFullYear() - 1
                          )
                        )
                          .getFullYear()
                          .toString();

                        dispatch(setCountPrevYears());
                        dispatch(setMonthNumberFromYear());
                        handleGetMoreYear(prevYear, { chevron: true });
                      }}
                    />
                  )}
                </>
              )}

              <p className="t-sm">
                {' '}
                {dayjs(months[monthNumber]?.date).format('YYYY')}
              </p>
              {!loadingYear && (
                <Button
                  content={<FiChevronRight />}
                  func={() => {
                    let curYearVal = dayjs(months[monthNumber]?.date).format(
                      'YYYY'
                    );

                    const nextYear = new Date(
                      new Date().setFullYear(
                        new Date(curYearVal.toString()).getFullYear() + 1
                      )
                    )
                      .getFullYear()
                      .toString();

                    dispatch(setCountNextYears());
                    dispatch(setMonthNumberFromYear());
                    handleGetMoreYear(nextYear, { chevron: true });
                  }}
                />
              )}
            </div>

            {/* Month Switcher */}
            <div className="month-inc-dec flex a-center">
              {monthNumber >= 1 && (
                <>
                  {!loadingYear && (
                    <Button
                      content={<FiChevronLeft />}
                      func={() => {
                        dispatch(setPrevMonthNumber());

                        let curYearVal = dayjs(
                          months[monthNumber]?.date
                        ).format('YYYY');

                        let prevYear = new Date(
                          new Date().setFullYear(
                            new Date(curYearVal.toString()).getFullYear() - 1
                          )
                        )
                          .getFullYear()
                          .toString();

                        let oldYear = new Date(
                          new Date().setFullYear(new Date().getFullYear() - 1)
                        )
                          .getFullYear()
                          .toString();

                        console.log(
                          parseInt(prevYear),
                          ' !== ',
                          parseInt(oldYear),
                          '   prev and old  ',
                          curYearVal
                        );

                        if (
                          dateFormated.split(' ')[1] === 'August' &&
                          parseInt(prevYear) !== parseInt(oldYear)
                        ) {
                          handleGetMoreYear(prevYear);
                        }
                      }}
                    />
                  )}
                </>
              )}
              <p className="t-sm">{t(dateFormated.split(' ')[1])}</p>
              <p className="t-sm date-show-small-screen">
                {dayjs(months[monthNumber]?.date).format('YYYY')}
              </p>
              {monthNumber <= months.length - 1 && (
                <>
                  {!loadingYear && (
                    <Button
                      content={<FiChevronRight />}
                      func={() => {
                        dispatch(setNextMonthNumber());

                        // Calls this function when month is October
                        if (dateFormated.split(' ')[1] === 'June') {
                          let curYearVal = dayjs(
                            months[monthNumber]?.date
                          ).format('YYYY');

                          const nextYear = new Date(
                            new Date().setFullYear(
                              new Date(curYearVal.toString()).getFullYear() + 1
                            )
                          )
                            .getFullYear()
                            .toString();

                          handleGetMoreYear(nextYear);
                        }
                      }}
                    />
                  )}
                </>
              )}
            </div>

            {!loadingYear ? (
              <>
                <div className="weeks grid_sys_week">
                  {month && (
                    <>
                      {month[0].map((row, i) => (
                        <>
                          <div className="week t-center t-sm" key={uuid()}>
                            <span className="week_day_desktop">
                              {t(row.format('dddd'))}
                            </span>
                            <span className="week_day_mobile">
                              {t(row.format('ddd'))}
                            </span>
                          </div>
                        </>
                      ))}
                    </>
                  )}
                </div>

                <div className="monthContainer grid_sys_month">
                  {month.map((row, idx) =>
                    row.map((day) => (
                      <DayComponent
                        day={day}
                        key={uuid()}
                        curMonth={month[2][0].format('MM')}
                        monthEvent={months[monthNumber]?.events}
                        handleEvent={handleEvent}
                        handleEventEdit={handleEventEdit}
                      />
                    ))
                  )}
                </div>
              </>
            ) : (
              <RectSkeletonLoader />
            )}
          </section>
        </section>
      </section>
      <FooterTvGuide />
      {showForm && <EventFormTvGuide setShowForm={setShowForm} />}
    </TvGuideContainer>
  );
};

// Start of Day Component

// Styles
const TvGuideContainer = styled.section`
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .guide_content {
    width: 95%;
    max-width: 1440px;
    margin: 0 auto;

    .header-tv-studio {
      width: 100%;
      padding-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .preview {
        width: 90%;
        margin: 0 auto;
      }
    }

    .calendar_container {
      margin-top: 2rem;
      .month-inc-dec {
        padding-top: 10px;
        width: 100%;
        max-width: 12rem;
        margin: 0 auto;
        margin-bottom: 2.25rem;
        justify-content: space-around;

        button {
          cursor: pointer;
          padding-top: 5px;
        }
        p {
          font-size: 1.25rem;
        }
      }
      .weeks {
        border: 1px solid #000;

        .week_day_mobile {
          display: none;
        }
      }

      .monthContainer {
        width: 100%;
      }

      .grid_sys_week {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
      }
      .grid_sys_month {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: ${({ monthDate }) =>
          monthDate === 5 ? 'repeat(5, 1fr)' : 'repeat(6, 1fr)'};
        grid-auto-rows: minmax(100px, auto);
        flex: 1;
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 640px) {
    .week_day_mobile {
      display: block !important;
    }

    .week_day_desktop {
      display: none;
    }
  }
`;

export default TvGuideStudio;
