import React from 'react';
import { LectiePitchContainer } from './Main.styles';
import PlayStore from '../../assest/images/playstore.png';
import AppleStore from '../../assest/images/applestore.png';
import TickFull from '../../assest/images/TickFull.svg';
import LectiePitchImg from '../../assest/images/lectie_pitch.svg';
import TopCircleDesign from '../../assest/images/home_lectie_pitch_white.png';
import BottomCircleDesign from '../../assest/images/home_lectie_pitch_bottom.png';
import BottomCircleDesignMobile from '../../assest/images/vault_bottom.png';
import { useTranslation } from 'react-i18next';

const LectiePitch = () => {
  const { t } = useTranslation(['lectie-pitch']);
  return (
    <LectiePitchContainer>
      <div className="right_circle_design">
        <img src={TopCircleDesign} alt="Circle icon design" />
      </div>

      <div className="left_bottom_circle_design">
        <img src={BottomCircleDesign} alt="Circle icon design" />
      </div>

      <div className="left_bottom_circle_design_mobile">
        <img src={BottomCircleDesignMobile} alt="Circle icon design" />
      </div>

      <section className="main_container">
        <div className="description_container">
          <h3>{t(`Lectie Is Available On Your Mobile Device`)}</h3>

          <p className="dmall_descip">
            {' '}
            {t(
              `Download lectie mobile on your favorite mobile app store.`
            )}{' '}
          </p>

          <div className="ActionButton flex">
            <div className="Download-Button-One">
              <a
                href="https://play.google.com/store/apps/details?id=com.lectie.vault"
                className="button-img-container"
                target="_blank"
                rel="noreferrer"
                aria-label="Google Play Store"
              >
                {' '}
                <img src={PlayStore} alt="download playstore" />{' '}
              </a>
            </div>

            <div className="Download-Button-Two">
              <a
                href="https://apps.apple.com/ng/app/lectie-vault/id1670873307"
                className="button-img-container"
                target="_blank"
                rel="noreferrer"
                aria-label="Apple Store"
              >
                {' '}
                <img src={AppleStore} alt="download apple store" />{' '}
              </a>
            </div>
          </div>

          <div className="small_text_container">
            <div className="flex a-center img_text">
              <img src={TickFull} alt="white tick icon" />
              <p>{t(`Stay connected & Informed`)}</p>
            </div>

            <div className="flex a-center img_text">
              <img src={TickFull} alt="white tick icon" />
              <p>{t(`Automated reminder`)}</p>
            </div>
          </div>
        </div>

        <div className="img_container">
          <img src={LectiePitchImg} alt="Mobile app view display" />
        </div>
      </section>
    </LectiePitchContainer>
  );
};

export default LectiePitch;
