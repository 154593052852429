import { useEditor } from '@craftjs/core';
import { Tooltip, Typography } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';
import styled from 'styled-components';


import RedoSvg from '../../../assest/icons/toolbox/redo.svg';
import UndoSvg from '../../../assest/icons/toolbox/undo.svg';


const HeaderDiv = styled.div`
  width: 100%;
  height: 45px;
  z-index: 99999;
  position: relative;
  padding: 0px 10px;
  background: #2667B1;
  display: flex;
  ${(props) =>
    props.enabled ?
    `
    height: 40px;
  `
  :
  `height: 55px;`}
`;

const Btn = styled.a`
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: 3px;
  color: #fff;
  font-size: 13px;
  svg {
    margin-right: 6px;
    width: 12px;
    height: 12px;
    fill: #fff;
    opacity: 0.9;
  }
`;

const Item = styled.a`
  margin-right: 10px;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    fill: #707070;
  }
  ${(props) =>
    props.disabled &&
    `
    opacity:0.5;
    cursor: not-allowed;
  `}
`;

export const Header = ({snap}) => {
  const { enabled, canUndo, canRedo, actions, query } = useEditor((state, query) => ({
    enabled: state.options.enabled,
    canUndo: query.history.canUndo(),
    canRedo: query.history.canRedo(),
  }));

  return (
    <HeaderDiv className="header text-white transition w-full" enabled={enabled}>
      <div className="items-center flex w-full justify-end">
        {enabled && (
          <div className="flex-1 flex">
            <Tooltip title="Undo" placement="bottom">
              <Item disabled={!canUndo} onClick={() => actions.history.undo()}>
              <img src={UndoSvg} width={18} height={18} />
              </Item>
            </Tooltip>
            <Tooltip title="Redo" placement="bottom">
              <Item disabled={!canRedo} onClick={() => actions.history.redo()}>
              <img src={RedoSvg} width={18} height={18} />
              </Item>
            </Tooltip>
          </div>
        )}
        {!enabled && (
          <div className="flex-1 flex items-center">
              <div>
              <img src={require('../../../assest/images/Logo1.png')} width={30} height={30} />
              </div>
              <div style={{margin: "5px", fontFamily:"Nunito", fontSize:"18px", fontWeight:"800"}}>
            <span>Bespoke</span>
              </div>
          </div>
        )}
        <div className="flex">
          <Btn
            className={cx([
              'transition cursor-pointer',
              {
                'bg-green-400': enabled,
                'bg-primary': !enabled,
              },
            ])}
            onClick={() => {
              actions.setOptions((options) => (options.enabled = !enabled));
            }}
          >

            {enabled ? 'Finish Editing' : 'Edit'}
          </Btn>
          {
            enabled ?
            null
            :
            <Btn
            className='transition cursor-pointer'
            onClick={() => {
              console.log(query.serialize());
             snap()
            }}
            style={{background:"#FB7878", marginLeft: 10}}
          >
          Save
          </Btn>   
          }

        </div>
      </div>
    </HeaderDiv>
  );
};
