import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { BallTriangle } from 'react-loader-spinner';

const useStyles = makeStyles({
  root: {
    background: '#fff',
    padding: '20px',
  },
  loader: {
    width: '100%',
    height: '100vh',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  coverImgContainer: {
    marginTop: '0px',
    height: '85vh',
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
  },

  imgCover: {
    width: '100%',
    height: '400px',
    imageRendering: 'pixelated',
  },
  moveCalendarBtn: {
    padding: '16px 15px',
    width: '230px',
    fontSize: '0.9rem',
    textAlign: 'center',
    color: '#ffffff',
    background: '#2667b1',
    borderRadius: '12px',
    cursor: 'pointer',
    alignSelf: 'center',
  },
});

const TemplateLoading = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.loader}
    >
      <Grid item xs={2} md={2} sm={2} lg={2} className={classes.center}>
        <BallTriangle
          height={'75%'}
          width={'75%'}
          radius={5}
          color="#2667b1"
          ariaLabel="ball-triangle-loading"
          wrapperClass={{}}
          wrapperStyle=""
          visible={true}
        />
      </Grid>
    </Grid>
  );
};

export default TemplateLoading;
