import React, { useState, useCallback, useEffect } from 'react';
import { SubscriptionContainer } from './style/subscription.style';
import { useSelector, useDispatch } from 'react-redux';
import ChooseUpgrade from './chooseUpgrade';

import { makeStyles, Button, Dialog } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import 'react-phone-number-input/style.css';
import {
  cancelSubscription,
  loadUser,
  profileImageUpdate,
  profileUpdate,
} from '../../store/reducers/userReducer';
// const { userDetails } = useSelector((state) => state.user);

import { toast } from 'react-toastify';
import client from '../../services';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Form styles
const useStyles = makeStyles((theme) => ({
  TextInput: {
    borderRadius: 10,
    width: '100%',
  },
}));

const Subscription = () => {
  const { t } = useTranslation(['dashboard']);
  const [loading, setLoading] = useState(false);
  const [subProcessing, setSubProcessing] = useState(false);
  const user = useSelector((state) => state.user);
  const covers = useSelector((state) => state.files.covers);
  const [souvenirs, setSouvenirs] = useState([]);
  const [bespokes, setBespoke] = useState(covers);

  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  // Loading State
  const [processing, setProcessing] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [openTemplate, setOpenTemplate] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [userLoading, setUserloading] = useState(false);
  const [userLocation, setUserLocation] = useState('');

  const loadCalendars = async () => {
    setLoading(true);
    await client
      .get(`Souvenir/publishedsouvenir?souvenirStatus=publish`)
      .then((res) => {
        let data = res.data;

        setSouvenirs(data);
        setLoading(false);
        console.log('SOVUgg ', data);

        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const loadUserDetails = async () => {
    // Make API to get user details and set to local storage
    setUserloading(true);
    await client
      .get('/Account/profile')
      .then((res) => {
        // set Authorization token to state
        var user = {
          ...res.data,
        };
        setUserloading(false);

        dispatch(loadUser(user));
        console.log(user, 'user details');
      })
      .catch((e) => {
        console.log(e);
        setUserloading(false);
      });
  };

  useEffect(() => {
    loadUserDetails();
    loadCalendars();
  }, []);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };
  const handleCloseTemplate = () => {
    setOpenTemplate(false);
    setLoading(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenTemplate = (openTemplate) => {
    setOpenTemplate(openTemplate);
    setIsOpen(!isOpen);
  };

  // Cancel Subscription
  const handleCancelSubscription = async () => {
    setSubProcessing(true);
    await client
      .post('/Subscription/cancelsubscription')
      .then((res) => {
        setSubProcessing(false);
        toast.success('subscription cancelled');
        dispatch(cancelSubscription({ cancelsubscription: true }));
        console.log(res);
      })
      .catch((error) => {
        setSubProcessing(false);
        toast.error(error.response?.data?.Message ?? error?.message);
      });
  };

  return (
    <SubscriptionContainer>
      <div className="main-wrap">
        <h2 className="font-extrabold font-body my-4 text-xl">
          {t('My Subscription')}
        </h2>
        <div className="py-10 px-9 shadow-md shadow-[#407bff47] rounded-2xl">
          {!userLoading && (
            <>
              {/*  NO CALENDAR SUBSCRIPTION */}
              {user?.premium_calender_usage <= 0 &&
                user?.pro_calender_usage <= 0 &&
                user?.calender_usage <= 0 && (
                  <p className="font-body ">
                    {t('You are not subscribed to any calendar')}
                  </p>
                )}

              {/* PREMIUM CALENDAR */}

              {user?.premium_calender_usage > 0 && (
                <p className="font-body ">
                  {' '}
                  {t('You have')} {user.premium_calender_usage}{' '}
                  {user.premium_calender_usage > 1
                    ? t('Premium calendars')
                    : t('Premium calendar')}
                  ,{' '}
                </p>
              )}

              {/* PRO CALENDAR */}
              {user?.pro_calender_usage > 0 && (
                <p className="font-body ">
                  {' '}
                  {t('You have')} {user.pro_calender_usage}{' '}
                  {user.pro_calender_usage > 1
                    ? t('Pro calendars')
                    : t('Pro calendar')}
                </p>
              )}

              {/* BASIC CALENDAR */}
              {user?.calender_usage > 0 && (
                <p className="font-body ">
                  {t('You have')} {user?.calender_usage}{' '}
                  {user.calender_usage > 1
                    ? t('Basic calendars')
                    : t('Basic calendar')}
                </p>
              )}
            </>
          )}

          <div className="mt-8 md:flex md:justify-between ">
            <Button
              variant="contained"
              disabled={loading}
              style={{
                textTransform: 'none',

                background: loading ? '#7a9bbf' : '#2667B1',
                color: '#fff',
                marginBottom: '10px',
              }}
              onClick={() => {
                {
                  souvenirs.length > 0
                    ? handleClickOpenTemplate(true)
                    : navigate('/dashboard/create');
                }
              }}
            >
              {souvenirs.length > 0
                ? t('Upgrade Calendar')
                : t('Create Calendar')}
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              style={{
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #2667B1',
                marginBottom: '10px',
              }}
              onClick={() => navigate('/dashboard/plans')}
            >
              {t('Add more calendars')}
            </Button>
          </div>
        </div>
      </div>
      <Dialog
        open={openTemplate}
        onClose={handleCloseTemplate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <ChooseUpgrade handleClickOpenTemplate={handleClickOpenTemplate} />
      </Dialog>
    </SubscriptionContainer>
  );
};

export default Subscription;
