import styled from 'styled-components';

export const FileUploadModalConatinaer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
`;

export const FileUploadModalWrapper = styled.div`
  width: 80%;
  padding: 2rem;
  background: #fff;

  .cancel {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    svg {
      font-size: 1.5rem;
      cursor: pointer;
    }
  }

  .submit-btn {
    margin-top: 3rem;
    text-align: center;
    background: #2667b1;
    border-radius: 8px;
    width: 200px;
    height: 40px;
    color: #fff;
    cursor: pointer;
  }

  @media screen and (max-width: 290px) {
    width: 95%;
    .submit-btn {
      margin-left: -1rem;
    }

    .image-box {
      border: 2px solid red;
      width: 100%;
    }
  }
`;
