import React, {
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
} from 'react';
import { UpgradeSummaryContainer } from './style/subscription.style';
import { useSelector, useDispatch } from 'react-redux';
import { KeyboardDatePicker } from '@material-ui/pickers';
import 'react-phone-number-input/style.css';
import UseFlutterwave from './service/useFlutterwave';

import { toast } from 'react-toastify';
import client from '../../services';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import stripelogo from '../../assest/images/stripe.svg';
import flutterPower from '../../assest/images/flutterPower.png';
import stripePower from '../../assest/images/stripePower.svg';
import flutterwavelogo from '../../assest/images/flutterwavelogo.svg';
import {
  makeStyles,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  TextField,
  FormHelperText,
} from '@material-ui/core';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { getCountyCode, userGeolocation } from '../../functions';
// Form styles
const useStyles = makeStyles((theme) => ({
  TextInput: {
    borderRadius: 10,
    width: '100%',
  },
  btn: {
    clear: 'both',
    border: 'none',
    color: '#fff',
    maxWidth: '100px',
    width: '70%',
    height: '30px',
    margin: '0 auto',
    background: '#2667b1',
    borderRadius: '12px',
    cursor: 'pointer',
    '& .MuiButton-label': {
      fontSize: '14px',
    },
  },
}));

const UpgradeSummary = () => {
  const { state } = useLocation();
  const {
    plan,
    id,
    moreQty,
    subscriptionSelected,
    userCurrency,
    userLocation,
    calendarQuantity,
    addPlans,
  } = state;

  console.log('Ucurr ', userLocation, ' moreQty ', moreQty);

  const { t } = useTranslation(['dashboard']);
  const [loading, setLoading] = useState(true);
  const [subProcessing, setSubProcessing] = useState(false);
  const [subscriptionRes, setSubscriptionRes] = useState({});
  const [flutterDetails, setFlutterDetails] = useState({});
  const [subPrice, setSubPrice] = useState();
  const [paymentMethod, setPaymentMethod] = useState('stripe');
  const [currencyValue, setCurrencyValue] = useState(userCurrency);
  const [qty] = useState(moreQty);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  // Stripe Payment
  const stripe = useStripe();
  const elements = useElements();

  console.log(userCurrency, ' This is uuser curremcy ');

  // Loading State
  const [processing, setProcessing] = useState(false);
  const [loadFlutter, setLoadflutter] = useState(false);

  // Initialize Flutterwave Upgrade
  const initializeFlutterwave = async (subId) => {
    setLoading(true);
    setSubProcessing(true);

    console.log(' in JUst Flutter  ==== data ', {
      qty: 1,
      subscriptionID: subId,
      souvenirID: id,
    });
    await client
      .post('/Subscription/intializeFlutterWavePayment', {
        qty: 1,
        subscriptionID: subId,
        souvenirID: id,
      })
      .then((res) => {
        console.log(res?.data, ' flutter data ');
        setFlutterDetails(res?.data);

        console.log(flutterDetails, ' flutter Details ');
        setLoading(false);

        // setCurrencyValue(flutterDetails?.currency);
      })
      .catch((error) => {
        toast.error(error.response?.data?.Message ?? error?.message);
        setLoading(false);
      });
  };

  // Initialize Flutterwave Add More
  const initializeFlutterwaveMore = async (subId) => {
    setLoading(true);
    setSubProcessing(true);

    console.log(subId, ' this is sub id ');

    const totalAmount =
      calendarQuantity[`${subscriptionSelected.subscriptionName}`] *
      subscriptionSelected.price;

    console.log(' in Flutter MOre ==== more qty ', moreQty);

    await client
      .post('/Subscription/intializeFlutterWavePayment', {
        qty: moreQty,
        subscriptionID: subId,
      })
      .then((res) => {
        setFlutterDetails({
          ...res?.data,
          amount: totalAmount,
        });

        console.log(res?.data, ' flutter Details  here === ');
        setLoading(false);

        // setCurrencyValue(flutterDetails?.currency);
      })
      .catch((error) => {
        toast.error(error.response?.data?.Message ?? error?.message);
        setLoading(false);
      });
  };

  // Fetch Subscription
  const fetchSubscription = async (subName) => {
    setSubProcessing(true);
    const currentUserLocation = new userGeolocation().getUserCountry();

    let { CountryCode } = getCountyCode(currentUserLocation) || 'NGN';

    await client
      .get(`/Subscription/fetchSubscription/${subName}/${CountryCode}`)
      .then((res) => {
        setSubProcessing(false);
        setSubscriptionRes(res.data);
        setSubPrice(res.data.price);
        {
          addPlans
            ? initializeFlutterwaveMore(res?.data?.id)
            : initializeFlutterwave(res?.data?.id);
        }

        // dispatch(cancelSubscription({ subscription: true }));
        console.log(res);
      })
      .catch((error) => {
        setSubProcessing(false);
        toast.error(error.response?.data?.Message ?? error?.message);
      });
  };

  useEffect(() => {
    fetchSubscription(plan);
  }, [qty, currencyValue]);

  return (
    <UpgradeSummaryContainer>
      <div className="main-wrap font-body ">
        <div className="">
          {!loadFlutter && (
            <button
              class="bg-transparent hover:bg-[#2667B1] text-black font-semibold hover:text-white py-1 px-5 border border-[#2667B1] hover:border-transparent rounded"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          )}
        </div>
      </div>

      <div class="grid h-full  place-items-center">
        <div className=" my-12 py-3 px-3 shadow-md w-full md:w-1/3 shadow-[#407bff47] rounded-xl">
          <h2 className="font-body  text-sm font-extrabold text-center pb-4 ">
            Payment Summary
          </h2>
          <div className=" text-center ">
            <div className="flex justify-between items-center my-3 px-6 text-xs">
              <p className="text-xs font-bold">Plan</p>
              <p className="text-xs   px-2">
                {subscriptionSelected.subscriptionName}
              </p>
            </div>
            <div className="flex justify-between items-center my-3 px-6 text-xs">
              <p className="text-xs font-bold">Billing</p>
              <p className="text-xs px-2">
                {subscriptionRes?.duration || '1 year'}
              </p>
            </div>
            <div className="flex justify-between items-center my-3 px-6 text-xs">
              <p className="text-xs font-bold">Amount</p>
              <p className="text-xs   px-2">
                {subscriptionSelected?.currency} {subscriptionSelected.price}
              </p>
            </div>

            <div className="flex justify-between items-center my-3 px-6 text-xs">
              <p className="text-xs font-bold">Taxes</p>
              <p className="text-xs   px-2">N/A</p>
            </div>
            <div className="flex justify-between items-center my-3 px-6 text-xs">
              <p className="text-xs font-bold">Total Amount</p>
              {subscriptionSelected?.currency}{' '}
              {calendarQuantity[`${subscriptionSelected.subscriptionName}`] *
                subscriptionSelected.price}
            </div>

            {userCurrency === 'NGN' || userLocation === 'NG' ? (
              <UseFlutterwave
                details={flutterDetails}
                loading={loading}
                loadFlutter={loadFlutter}
                setLoadflutter={setLoadflutter}
                isCheckout={false}
              />
            ) : (
              <Grid item xs={12}>
                <Button
                  className={classes.btn}
                  variant="contained"
                  color="primary"
                  style={{ textTransform: 'none' }}
                  // onClick={() => navigate("dashboard/stripe-checkout")}
                  onClick={() => {
                    navigate('/dashboard/stripe-checkout', {
                      state: {
                        ...state,
                        quantity:
                          calendarQuantity[
                            `${subscriptionSelected.subscriptionName}`
                          ],
                      },
                    });
                  }}
                >
                  {processing ? t('Processing') : t('Proceed')}
                </Button>
              </Grid>
            )}
          </div>
        </div>{' '}
        {/* <h2 className="mb-4">Select payment method:</h2> */}
        {userCurrency !== 'NGN' && userLocation !== 'Nigeria' ? (
          <div classsName="mb-4">
            <img
              src={stripelogo}
              width={60}
              height={60}
              onClick={() => setPaymentMethod('stripe')}
              className="mb-2 cursor-pointer "
            />

            <>
              <p className="flex self-end border-2 border-[#2667B1] rounded-sm"></p>
              <img
                src={stripePower}
                width={150}
                height={80}
                className="my-2 cursor-pointer "
              />
            </>
          </div>
        ) : (
          <div className="mt-4">
            <img
              src={flutterwavelogo}
              width={200}
              height={200}
              className="mb-2 cursor-pointer"
            />
            <p className="flex self-end border-2 border-[#2667B1] rounded-sm"></p>
            <img
              src={flutterPower}
              width={150}
              height={80}
              className="my-2 ml-6 cursor-pointer "
            />
          </div>
        )}
      </div>
    </UpgradeSummaryContainer>
  );
};

export default UpgradeSummary;
