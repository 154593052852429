import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AiOutlineClose } from 'react-icons/ai';
import { FiTrash2 } from 'react-icons/fi';
import ButtonTVGuideForm from './ButtonTVGuideForm';
import ProgramDetailsForm from './ProgramDetailsForm';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import { toast } from 'react-toastify';
import {
  setCalendarEditEvent,
  setCalendarEditRepeatedEvent,
  setCalendarEvent,
  setDeleteEvent,
  setDeleteEventRecurring,
  setDeleteRepeatedEvent,
  setSigleEventEdit,
} from '../../../../../store/features/calendarSlice';
import { setShowEventModel } from '../../../../../store/features/modalSlice';
import { setInvalid } from './helper';
import client from '../../../../../services';
import {
  createEvent,
  deleteEvent,
  deleteEventRecurring,
  updateEvent,
  updateEventRecurring,
} from '../../../../../functions';
import { Grid, makeStyles } from '@material-ui/core';
import { BallTriangle } from 'react-loader-spinner';

const useStyles = makeStyles({
  root: {
    background: '#fff',
    padding: '20px',
  },
  loader: {
    width: '100%',
    height: '100%',
  },
});

// Random Colors
const customColors = [
  {
    id: 1,
    color: '#FFDAC0',
    label: 'Birthday',
  },
  {
    id: 2,
    color: '#FFEDAC',
    label: 'Anniversary ',
  },
  { id: 3, color: '#FFCEEC', label: 'Holiday' },
  { id: 4, color: '#BAFFC1', label: 'Birthday' },
  { id: 5, color: '#B4DFFF', label: 'Birthday' },
  { id: 6, color: '#FDB8B8', label: 'Birthday' },
];

const EventFormTvGuide = ({ setShowForm }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation(['dashboard']);
  const draft = useSelector((state) => state?.draft);
  const user = useSelector((state) => state?.user);
  const [programMenu, setProgramMenu] = useState(true);
  const { selectedEvent } = useSelector((state) => state.calendar);
  const { selectedDate } = useSelector((state) => state.startEventModal);
  const [editOption] = useState(selectedEvent ? true : false);
  const [showDeleteIcon] = useState(selectedEvent ? true : false);
  const [selectedRepeatDelete, setSelectedRepeatedDelete] = useState(false);
  const [selectedRepeatUpdate, setSelectedRepeatedUpdate] = useState(false);
  const [isMainRepeat, setIsMainRepeat] = useState(false);
  const [newEventObject, setNewEventObject] = useState();
  const [formFilled, setFormFilled] = useState({
    buttonForm: false,
    programForm: false,
  });
  const [formLoading, setIsFormLoading] = useState(false);

  const checkValidButtonFormList = [
    'programLink',
    'videoUrl',
    'liveVideoUrl',
    'title',
    'subTitle',
    'episode',
    'season',
    'link',
    'programDescription',
  ];

  const [formData, setFormData] = useState({
    id: selectedEvent
      ? selectedEvent['id']
      : '00000000-0000-0000-0000-000000000000',
    title: selectedEvent ? selectedEvent['title'] : '',
    subTitle: selectedEvent ? selectedEvent['subTitle'] : '',
    season: selectedEvent ? selectedEvent['season'] : '',
    episode: selectedEvent ? selectedEvent['episode'] : '',
    programCaution: selectedEvent ? selectedEvent['programCaution'] : 'PG',
    description: selectedEvent ? selectedEvent['description'] : '',
    startDate: selectedEvent ? selectedEvent['startDate'] : selectedDate,
    endDate: selectedEvent ? selectedEvent['endDate'] : selectedDate,
    startTime: selectedEvent ? selectedEvent['startTime'] : 0,
    endTime: selectedEvent ? selectedEvent['endTime'] : 0,
    repeat: selectedEvent ? selectedEvent?.repeat : 'Do Not Repeat',
    image: selectedEvent ? selectedEvent['image'] : '',
    link: selectedEvent ? selectedEvent['link'] : '',
    liveVideoUrl: selectedEvent ? selectedEvent['liveVideoUrl'] : '',
    liveVideoButtonVariant: selectedEvent
      ? selectedEvent['liveVideoButtonVariant']
      : 1,
    videoUrl: selectedEvent ? selectedEvent['videoUrl'] : '',
    videoButtonVariant: selectedEvent ? selectedEvent['videoButtonVariant'] : 1,
    programLink: selectedEvent ? selectedEvent['programLink'] : '',
    isRemindMe: selectedEvent ? selectedEvent['isRemindMe'] : true,
    remindButtonVariant: selectedEvent
      ? selectedEvent['remindButtonVariant']
      : 1,
    isNewEvent: selectedEvent ? selectedEvent['isNewEvent'] : false,
    newEventButtonVariant: selectedEvent
      ? selectedEvent['newEventButtonVariant']
      : 1,
    reminderTime: selectedEvent ? selectedEvent['reminderTime'] : 0,
    commonId: selectedEvent ? selectedEvent['commonId'] : 0,
    color: selectedEvent ? selectedEvent['color'] : '',
    labelId: selectedEvent ? selectedEvent['labelId'] : '',
    reminders: [],
  });

  const [prevRepeatOccur] = useState(formData?.repeat);
  const [oldStartDate] = useState(formData?.startDate);

  const [errForm, setErrForm] = useState({
    programLink: false,
    videoUrl: false,
    liveVideoUrl: false,
    title: false,
    subTitle: false,
    episode: false,
    season: false,
    link: false,
    programDescription: false,
  });

  const handleChange = (event) => {
    let value = event.target.value
      .replace(/[ ]{2,}/gi, ' ') // Remove multiple space
      .replace(/\n +/, '\n'); // Remove space after newline

    if (event.target.name === 'episode' || event.target.name === 'season')
      value = event.target.value.replace(/([a-zA-Z])/g, '');

    setFormData((prevState) => ({
      ...prevState,
      [event.target.name]: value,
    }));
  };

  const handleUpdatePopup = () => {
    setSelectedRepeatedUpdate(true);
    setIsMainRepeat(true);
    setSelectedRepeatedDelete(false);
  };

  useEffect(() => {
    // Set event details if event is not an edited event
    if (selectedEvent === '') {
      setFormData((prevState) => ({
        ...prevState,
        startTime: dayjs(formData?.startDate)
          .set('hour', dayjs(new Date()).get('h'))
          .set('minute', dayjs(new Date()).get('minutes'))
          .set('second', dayjs(new Date()).get('second')),
      }));

      setFormData((prevState) => ({
        ...prevState,
        endTime: dayjs(formData?.endDate)
          .set('hour', dayjs(new Date()).get('h') + 1)
          .set('minute', dayjs(new Date()).get('minutes'))
          .set('second', dayjs(new Date()).get('second')),
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        startTime: dayjs(formData?.startDate)
          .set('hour', dayjs(new Date(formData?.startTime)).get('h'))
          .set('minute', dayjs(new Date(formData?.startTime)).get('minutes'))
          .set('second', dayjs(new Date(formData?.startTime)).get('second')),
      }));
      setFormData((prevState) => ({
        ...prevState,
        endTime: dayjs(formData?.endDate)
          .set('hour', dayjs(new Date(formData?.endTime)).get('h'))
          .set('minute', dayjs(new Date(formData?.endTime)).get('minutes'))
          .set('second', dayjs(new Date(formData?.endTime)).get('second')),
      }));
    }

    setFormData((prevState) => ({
      ...prevState,
      repeat: formData?.repeat,
    }));
  }, [formData?.startDate, formData?.endDate]);

  // Delete Event from Backend
  const deleteEvent = async (eventId) => {
    setIsFormLoading(true);
    await client
      .delete(`/event/deleteBespokeEvent?eventId=${eventId}`)
      .then((res) => {
        console.log(' Event Deletig single event...');
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Message ?? err?.message);
      });
  };

  // Handle submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    let randomNum = Math.floor(Math.random() * (6 - 1 + 1) + 1);
    const data = {
      ...formData,
      color: formData?.color ? formData?.color : customColors[randomNum]?.color,
      labelId: formData?.labelId
        ? formData?.labelId
        : customColors[randomNum]?.id,
      startDate: dayjs(formData?.startDate).format('YYYY-MM-DDTHH:mm:ss'),
      endDate: dayjs(formData?.endDate).format('YYYY-MM-DDTHH:mm:ss'),
      startTime: dayjs(formData?.startTime).format('YYYY-MM-DDTHH:mm:ssZ'),
      endTime: dayjs(formData?.endTime).format('YYYY-MM-DDTHH:mm:ssZ'),
      prevRepeat: prevRepeatOccur,
      userID: user?.id,
    };

    const eventObj = {
      date: dayjs(selectedDate).format('YYYY-M'), //Event month date
      background: 'test',
      events: [{ ...data }],
    };

    // Endpoint date
    const eventId = formData?.id;
    const bespokeId = draft?.id;

    if (!editOption) {
      setIsFormLoading(true);
      await client
        .post(`/event/CreatePublicEvents/${bespokeId}`, eventObj?.events[0])
        .then((res) => {
          console.log(' Inside the create ', {
            ...eventObj,
            events: [{ ...res?.data }],
          });
          dispatch(
            setCalendarEvent({ ...eventObj, events: [{ ...res?.data }] })
          );

          setIsFormLoading(false);
          setShowForm(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.Message ?? err?.message);
          setIsFormLoading(false);
        });
    } else if (
      editOption === true &&
      (formData.repeat === 'Do Not Repeat' ||
        formData.repeat === '' ||
        formData.repeat === null) &&
      !eventObj?.events[0]?.commonId
    ) {
      const callDispatch = () => {
        dispatch(setCalendarEditEvent(eventObj));
        setShowForm(false);
      };
      // call update
      updateEvent(
        eventId,
        bespokeId,
        eventObj?.events[0],
        callDispatch,
        setIsFormLoading
      );
    } else if (
      (formData.repeat !== 'Do Not Repeat' ||
        formData.repeat !== '' ||
        formData.repeat !== null) &&
      oldStartDate !== eventObj?.events[0]?.startDate &&
      eventObj?.events[0]?.commonId
    ) {
      dispatch(
        setDeleteEventRecurring({
          evtId: eventObj?.events[0]?.commonId,
          evtDate: eventObj?.events[0]?.startDate,
        })
      );

      setIsFormLoading(true);
      const dataSet = {
        ...eventObj,
        events: [{ ...eventObj?.events[0], commonId: '' }],
      };

      await client
        .post(`/event/CreatePublicEvents/${bespokeId}`, eventObj?.events[0])
        .then((res) => {
          dispatch(
            setCalendarEvent({ ...dataSet, events: [{ ...res?.data }] })
          );

          setShowForm(false);
          setIsFormLoading(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.Message ?? err?.message);
          setIsFormLoading(false);
        });
    } else if (
      (formData.repeat !== 'Do Not Repeat' ||
        formData.repeat !== '' ||
        formData.repeat !== null) &&
      !eventObj?.events[0]?.commonId
    ) {
      setIsFormLoading(true);

      await client
        .post(`/event/CreatePublicEvents/${bespokeId}`, eventObj?.events[0])
        .then((res) => {
          dispatch(setDeleteEvent(eventObj?.events[0]?.id));
          deleteEvent(eventObj?.events[0]?.id);
          dispatch(
            setCalendarEvent({ ...dataSet, events: [{ ...res?.data }] })
          );
          setShowForm(false);
          setIsFormLoading(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.Message ?? err?.message);
          setIsFormLoading(false);
        });
    } else {
      // Call popup  in Event Model
      handleUpdatePopup();
      setNewEventObject(eventObj);
    }
  };

  const checkFormFilled = () => {
    // buttonForm Form
    if (
      (formData?.programLink !== '') &
      (formData?.programLink !== ' ') &
      ((formData?.videoUrl !== '') & (formData?.videoUrl !== ' ')) &
      ((formData?.liveVideoUrl !== '') & (formData?.liveVideoUrl !== ' '))
    ) {
      setFormFilled((prevState) => ({
        ...prevState,
        buttonForm: true,
      }));
    } else {
      setFormFilled((prevState) => ({
        ...prevState,
        buttonForm: false,
      }));
    }

    if (
      (formData?.title !== '') &
      (formData?.title !== ' ') &
      ((formData?.subTitle !== '') & (formData?.subTitle !== ' ')) &
      ((formData?.episode !== '') & (formData?.episode !== ' ')) &
      ((formData?.season !== '') & (formData?.season !== ' ')) &
      ((formData?.link !== '') & (formData?.link !== ' ')) &
      ((formData?.programDescription !== '') &
        (formData?.programDescription !== ' '))
    ) {
      setFormFilled((prevState) => ({
        ...prevState,
        programForm: true,
      }));
    } else {
      setFormFilled((prevState) => ({
        ...prevState,
        programForm: false,
      }));
    }
  };

  useEffect(() => {
    checkFormFilled();
  }, [formData]);

  const handleDialogClose = () => {
    setIsMainRepeat(false);
    setSelectedRepeatedDelete(false);
    setSelectedRepeatedUpdate(false);
  };

  return (
    <EventFormTvGuideContainer
      onClick={(e) => {
        e.stopPropagation();
        setShowForm(false);
      }}
    >
      <div
        className="modal"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <input
          type="text"
          value={formData?.id}
          placeholder="no id"
          onChange={() => {}}
          className="hideInput"
          hidden
        />
        <input
          type="text"
          value={formData?.commonId}
          placeholder="no id"
          onChange={() => {}}
          className="hideCommonInput"
          hidden
        />

        <input
          type="text"
          value={formData?.startDate}
          placeholder="date"
          onChange={() => {}}
          className="startDate"
          hidden
        />
        {!formLoading ? (
          <>
            <Dialog
              open={isMainRepeat}
              onClose={handleDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <section
                className="all_one_main_container"
                style={{
                  width: '270px',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '1rem',
                  gap: '2rem',
                }}
              >
                {selectedRepeatDelete && (
                  <div
                    className="all_one_container"
                    style={{
                      margin: '1rem',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '2rem',
                    }}
                  >
                    <span
                      onClick={() => {
                        const callDispatch = () => {
                          dispatch(
                            setDeleteRepeatedEvent(
                              document.querySelector('.hideCommonInput').value
                            )
                          );
                          setShowForm(false);
                        };

                        deleteEventRecurring(
                          callDispatch,

                          setIsFormLoading,

                          {
                            bespokeId: draft?.id,
                            commonId:
                              document.querySelector('.hideCommonInput').value,
                          }
                        );
                      }}
                      style={{
                        padding: '0.8rem 0 0.7rem 0.8rem',
                        cursor: 'pointer',
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                      }}
                    >
                      {t('Delete All Events')}
                    </span>
                    <span
                      onClick={() => {
                        const callDispatch = () => {
                          dispatch(
                            setDeleteEventRecurring({
                              evtId:
                                document.querySelector('.hideCommonInput')
                                  .value,
                              evtDate:
                                document.querySelector('.startDate').value,
                            })
                          );

                          setShowForm(false);
                        };

                        deleteEventRecurring(
                          callDispatch,
                          setIsFormLoading,

                          {
                            bespokeId: draft?.id,
                            commonId:
                              document.querySelector('.hideCommonInput').value,
                            evtDate: document.querySelector('.startDate').value,
                          }
                        );
                      }}
                      style={{
                        padding: '0.8rem 0 0.7rem 0.8rem',
                        cursor: 'pointer',
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                      }}
                    >
                      {' '}
                      {t('Delete Present Event')}
                    </span>
                  </div>
                )}

                {selectedRepeatUpdate && (
                  <div
                    className="all_one_container"
                    style={{
                      margin: '1rem',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '2rem',
                    }}
                  >
                    <span
                      onClick={() => {
                        const callDispatch = () => {
                          dispatch(
                            setCalendarEditRepeatedEvent(newEventObject)
                          );

                          setShowForm(false);
                        };

                        updateEventRecurring(
                          callDispatch,
                          setNewEventObject,
                          setIsFormLoading,
                          newEventObject,
                          {
                            bespokeId: draft?.id,
                            commonId: newEventObject?.events[0]?.commonId,
                          }
                        );
                      }}
                      style={{
                        padding: '0.8rem 0 0.7rem 0.8rem',
                        cursor: 'pointer',
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                      }}
                    >
                      {t('Update All Events')}
                    </span>
                    <span
                      onClick={() => {
                        const callDispatch = () => {
                          if (
                            newEventObject?.events[0]?.repeat ===
                            newEventObject?.events[0]?.prevRepeat
                          ) {
                            dispatch(
                              setDeleteEventRecurring({
                                evtId: newEventObject?.events[0]?.commonId,
                                evtDate: newEventObject?.events[0]?.startDate,
                              })
                            );
                            dispatch(setSigleEventEdit(newEventObject));
                            setShowForm(false);
                          } else {
                            dispatch(setCalendarEditEvent(newEventObject));
                            setShowForm(false);
                          }
                        };

                        updateEventRecurring(
                          callDispatch,
                          setNewEventObject,
                          setIsFormLoading,
                          newEventObject,
                          {
                            bespokeId: draft?.id,
                            commonId: newEventObject?.events[0]?.commonId,
                            evtDate: newEventObject?.events[0]?.startDate,
                          }
                        );
                      }}
                      style={{
                        padding: '0.8rem 0 0.7rem 0.8rem',
                        cursor: 'pointer',
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                      }}
                    >
                      {' '}
                      {t('Update Present Event')}
                    </span>
                  </div>
                )}
              </section>
            </Dialog>

            <div className="form_header">
              <p
                style={
                  programMenu ? { borderBottom: '2px solid #ffffff' } : null
                }
                onClick={() => setProgramMenu(true)}
              >
                Program details
              </p>
              <p
                style={
                  !programMenu ? { borderBottom: '2px solid #ffffff' } : null
                }
                onClick={() => setProgramMenu(false)}
              >
                Buttons
              </p>

              <span>
                {showDeleteIcon && (
                  <FiTrash2
                    onClick={() => {
                      if (
                        document.querySelector('.hideCommonInput').value ===
                          '' ||
                        formData?.repeat === 'Do Not Repeat'
                      ) {
                        const callDispatch = () => {
                          dispatch(
                            setDeleteEvent(
                              document.querySelector('.hideInput').value
                            )
                          );
                          setShowForm(false);
                        };

                        deleteEvent(
                          document.querySelector('.hideInput').value,
                          callDispatch,
                          setIsFormLoading
                        );
                      } else {
                        setIsMainRepeat((prevState) => !prevState);
                        setSelectedRepeatedDelete((prevState) => !prevState);
                        setSelectedRepeatedUpdate(false);
                      }
                    }}
                  />
                )}
              </span>

              <span onClick={() => setShowForm(false)}>
                <AiOutlineClose />
              </span>
            </div>
            <form className="form" onSubmit={(e) => handleSubmit(e)}>
              {/* For Passing Id nad commonId of event*/}

              {programMenu ? (
                <ProgramDetailsForm
                  formData={formData}
                  setFormData={setFormData}
                  handleChange={handleChange}
                  errForm={errForm}
                  setErrForm={setErrForm}
                />
              ) : (
                <ButtonTVGuideForm
                  formData={formData}
                  setFormData={setFormData}
                  handleChange={handleChange}
                  errForm={errForm}
                  setErrForm={setErrForm}
                />
              )}

              {formFilled?.buttonForm && formFilled?.programForm ? (
                <button type="submit" className="form_btn">
                  {editOption ? 'Update' : 'Save'}
                </button>
              ) : (
                <button
                  className="form_btn"
                  type="button"
                  onClick={() => {
                    !formFilled?.buttonForm || !formFilled?.programForm
                      ? setProgramMenu((prevState) =>
                          !formFilled?.programForm ? true : false
                        )
                      : '';
                    setInvalid(checkValidButtonFormList, formData, setErrForm);
                  }}
                >
                  Proceed
                </button>
              )}
            </form>
          </>
        ) : (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.loader}
          >
            <Grid item xs={2} md={2} sm={2} lg={2}>
              <BallTriangle
                height={'100%'}
                width={'100%'}
                radius={5}
                color="#2667b1"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            </Grid>
          </Grid>
        )}
      </div>
    </EventFormTvGuideContainer>
  );
};

// Styles
const EventFormTvGuideContainer = styled.section`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;

  .modal {
    border-radius: 8px 8px 0px 0px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 476px;
    width: 85%;
    height: 90vh;
    padding-bottom: 2rem;
    overflow-y: scroll;

    background-color: white;

    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    z-index: 3;

    .form_header {
      border-radius: 8px 8px 0px 0px;
      background: #2667b1;
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: space-between;
      padding: 15px 20px;

      p {
        font-size: 0.9rem;
        color: #fff;
        font-family: Nunito;
        font-weight: 700;
        cursor: pointer;
      }

      span {
        svg {
          color: #fff;
          cursor: pointer;
        }
      }
    }

    .form {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .form_btn {
        min-width: 100px;
        height: 36px;
        color: #fff;
        background: #2667b1;
        margin-top: 2rem;
        border-radius: 8px;
        padding: 0 0.8rem;
        align-self: center;
      }
    }

    .all_one_main_container {
      .all_one_container {
        box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px,
          rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
          rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
        max-width: 220px;
        width: 100%;
        color: #fff;

        span {
          cursor: pointer;
          &:hover {
            border-bottom: 2px solid #fff;
          }
        }
      }
    }
  }
`;

export default EventFormTvGuide;
