import styled from 'styled-components';

export const ForgotPasswordContainer = styled.div`
  width: 100%;
  max-width: 1440px;
`;

export const ForgotPasswordWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  .right-side-form {
    margin: 0 auto;
    max-width: 660px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1.9rem;
    height: 100vh;
  }

  @media screen and (max-width: 1024px) {
    gap: 1rem;
  }

  @media screen and (max-width: 990px) {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(15%, 3%);
    width: 90vw;

    .right-side-form {
      width: 100%;
    }
  }

  @media screen and (max-width: 544px) {
    width: 90vw;
    transform: translate(6%, 3%);
    .right-side-form {
      form {
        .main-form {
          width: 80%;
        }
      }

      .alt-signup {
        width: 80%;
        margin: 0 auto;
      }
    }

    .button-container {
      width: 80%;
      margin: 0 auto;
    }
  }
`;
