import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  monthNumber: 0,
  countNumberOfYears: 1,
};

const monthSlice = createSlice({
  name: 'month',
  initialState,
  reducers: {
    reloadMonthNumber: (state, { payload }) => {
      return {
        ...state,
        ...initialState,
      };
    },
    setMonthNumber: (state, { payload }) => {
      state.monthNumber = payload;
    },

    setCountNumberOfYears: (state) => {
      state.countNumberOfYears = 1;
    },

    setNextMonthNumber: (state) => {
      state.monthNumber = state.monthNumber + 1;
      // Update countNumberOfYears as month move to next year
      state.countNumberOfYears =
        state.monthNumber % 12 === 0
          ? state.monthNumber / 12 + 1
          : state.countNumberOfYears;
    },
    setPrevMonthNumber: (state) => {
      state.monthNumber = state.monthNumber - 1;
      // Update countNumberOfYears as month move to next year
      state.countNumberOfYears =
        state.monthNumber % 12 === 0
          ? state.monthNumber / 12 + 1
          : Math.floor(state.monthNumber / 12) + 1;
    },

    setCountNextYears: (state) => {
      state.countNumberOfYears = state.countNumberOfYears + 1;
    },
    setCountPrevYears: (state) => {
      state.countNumberOfYears = state.countNumberOfYears - 1;
    },

    setMonthNumberFromYear: (state) => {
      state.monthNumber = state.countNumberOfYears * 12 - 12;
    },
  },
});

export const {
  setMonthNumber,
  setNextMonthNumber,
  setPrevMonthNumber,
  reloadMonthNumber,
  setCountNextYears,
  setCountPrevYears,
  setMonthNumberFromYear,
  setCountNumberOfYears,
} = monthSlice.actions;
export default monthSlice.reducer;
