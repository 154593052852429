import React, { useState } from 'react';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { Button } from '@material-ui/core';
import client from '../../../services';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';

export default function UseFlutterwave({
  details,
  loading,
  loadFlutter,
  setLoadflutter,
}) {
  const navigate = useNavigate();

  // process.env.NODE_ENV == 'development'

  const config = {
    public_key:
      process.env.NODE_ENV == 'development'
        ? process.env.REACT_APP_FLUTTERWAVE__TEST_PUBLIC_KEY
        : process.env.REACT_APP_FLUTTERWAVE__LIVE_PUBLIC_KEY,
    tx_ref: details?.tx_ref,
    amount: details?.amount,
    currency: 'NGN',
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: details?.customer?.email,
      phone_number: details?.customer?.phonenumber,
      name: details?.customer?.name,
    },
    customizations: {
      title: 'Bespoke',
      description: 'Payment for calendar',
      logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
    },
  };

  const handleFlutterPayment = useFlutterwave(config);
  // Verify Flutterwave
  const verifyFlutterwave = (transactionref) => {
    // setSubProcessing(true);
    console.log('Txid ', transactionref);
    setLoadflutter(true);
    client
      .get(
        `/Subscription/verifyFlutterWavePayment/transactionref?transactionref=${transactionref}`
      )
      .then((res) => {
        console.log('vFlutter ', res);

        navigate('/dashboard');
        location.reload(true);
        setLoadflutter(false);

        // dispatch(cancelSubscription({ subscription: true }));
      })
      .catch((error) => {
        toast.error(error.response?.data?.Message ?? error?.message);
        setLoadflutter(false);
      });
  };

  return (
    <div className="App">
      {!loadFlutter ? (
        <Button
          variant="contained"
          disabled={loading}
          // color="primary"
          style={{
            textTransform: 'none',
            background: '#2667B1',
            color: '#fff',
            fontSize: 12,
            padding: '0 64px',
            marginTop: 20,
          }}
          onClick={() => {
            handleFlutterPayment({
              callback: (response) => {
                closePaymentModal(); // this will close the modal programmatically
                console.log(
                  ' ******** Payment is Successfully here ********* Response ',
                  response
                );

                verifyFlutterwave(details?.tx_ref);
              },
              onClose: () => {
                navigate('/dashboard');
              },
            });
          }}
        >
          {loading ? (
            <div className="preload2">
              <Oval
                height={25}
                width={25}
                color="#ffffff"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#ffffff"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          ) : (
            'Pay'
          )}
        </Button>
      ) : (
        <div className="preload2">
          <Oval
            height={55}
            width={55}
            color="#2667B1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#2667B1"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
    </div>
  );
}
