import React from 'react';
import { InputField, InputFieldLink, VariantGroup } from './helper';
import styled from 'styled-components';
import ProgramLinkImg from '../../../../../assest/images/program_link.png';
import { FaArrowRight } from 'react-icons/fa';
import Switch from '@mui/material/Switch';
import dayjs from 'dayjs';

const ButtonTVGuideForm = ({
  formData,
  setFormData,
  handleChange,
  errForm,
  setErrForm,
}) => {
  return (
    <ButtonForm>
      {' '}
      <div className="btn_page_field">
        <InputFieldLink
          formData={formData}
          name="liveVideoUrl"
          placeholder="Live video URL"
          handleChange={handleChange}
          errForm={errForm}
          setErrForm={setErrForm}
        />
        <VariantGroup
          setFormData={setFormData}
          btnName="Watch now"
          iconName="play"
          variantNam="liveVideoButtonVariant"
          variantNum={formData.liveVideoButtonVariant}
        />{' '}
        <div className="bottom_line"></div>
      </div>
      <div className="btn_page_field">
        <InputFieldLink
          formData={formData}
          name="videoUrl"
          placeholder="Video URL"
          handleChange={handleChange}
          errForm={errForm}
          setErrForm={setErrForm}
        />
        <VariantGroup
          setFormData={setFormData}
          btnName="Watch on demand"
          iconName=""
          variantNam="videoButtonVariant"
          variantNum={formData.videoButtonVariant}
        />{' '}
        <div className="bottom_line"></div>
      </div>
      <div className="btn_page_field">
        <div className="form_item">
          <span className="img_icon">
            <img src={ProgramLinkImg} alt="dot for title" className="dot_img" />
          </span>
          <InputField
            placeholder="Program page link"
            name="programLink"
            value={formData.programLink}
            handleChange={handleChange}
            errForm={errForm}
            setErrForm={setErrForm}
          />
        </div>

        <div className="program_btn_page_btn">
          <p>Visit program page </p> <FaArrowRight />
        </div>

        <div className="bottom_line"></div>
      </div>
      <div className="btn_page_field">
        <div className="toggle_switch_container">
          <p>Remind me</p>
          <Switch
            checked={formData?.isRemindMe}
            size="small"
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                isRemindMe: e.target.checked,
              }))
            }
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </div>
        <VariantGroup
          setFormData={setFormData}
          btnName="Remind me"
          iconName="bell"
          variantNam="remindButtonVariant"
          variantNum={formData.remindButtonVariant}
        />{' '}
        <div className="bottom_line"></div>
      </div>
      <div className="btn_page_field">
        <div className="toggle_switch_container">
          <p>New event</p>
          <Switch
            checked={formData?.isNewEvent}
            size="small"
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                isNewEvent: e.target.checked,
              }))
            }
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </div>
        <VariantGroup
          setFormData={setFormData}
          btnName="New"
          iconName=""
          variantNam="newEventButtonVariant"
          variantNum={formData.newEventButtonVariant}
        />{' '}
      </div>
    </ButtonForm>
  );
};

const ButtonForm = styled.section`
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  .btn_page_field {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    position: relative;

    .form_item {
      display: flex;
      gap: 1.2rem;
      align-items: center;
    }

    .bottom_line {
      border: 0.3px solid #8c8c8c;
      width: 476px;
      margin-left: -20px;
    }

    .program_btn_page_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      font-size: 1rem;
      color: #2667b1;

      svg {
        margin-top: 5px;
      }
    }

    .toggle_switch_container {
      display: flex;
      align-items: center;
      gap: 1rem;
      justify-content: space-between;
    }
  }
`;

export default ButtonTVGuideForm;
