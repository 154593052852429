import React, { useState } from 'react';
import { ProfileDropContainer } from './ProfileDropDown.style';
import Profile from '../../../assest/images/menu_drop_profile.svg';
import Vault from '../../../assest/images/menu_drop_vault.svg';
import { useSelector } from 'react-redux';
import DraftIcon from '../../../assest/images/menu_drop_logout.svg';
import Logout from '../../../assest/images/menu_drop_logout.svg';
import Profile1 from '../../../assest/images/menu_drop_profile.svg';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../authenicationProvider';
import { FiEdit, FiLock } from 'react-icons/fi';
import { googleLogout } from '@react-oauth/google';
import { getAuth, signOut } from 'firebase/auth';
import { useTranslation } from 'react-i18next';

const ProfileDropDown = ({ func, move }) => {
  const { t } = useTranslation(['dashboard']);
  const navigate = useNavigate();
  const { setUser } = useUser();
  const user = useSelector((state) => state.user);
  const logOut = () => {
    // Facebook Signout
    if (user.type == 'fb') {
      const auth = getAuth();
      signOut(auth)
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    }

    // Google Signout
    if (user.type == 'google') {
      googleLogout();
    }

    let tokenKey = 'auth_token';
    localStorage.removeItem(tokenKey);
    localStorage.removeItem('mobileAuth');
    setUser(false);
    navigate('/');
  };

  return (
    <ProfileDropContainer pos={move} className="parent_dropdown">
      <ul className="profile_drop_wrapper">
        <li
          typeof="button"
          onClick={() => {
            func(false);
            navigate('/dashboard/profile');
          }}
        >
          <img src={Profile} alt="Profile picture" />
          <p>{t('Profile')}</p>
        </li>

        <li
          typeof="button"
          onClick={() => {
            func(false);
            navigate('/dashboard/');
          }}
        >
          <img src={Vault} alt="Vault picture" />
          <p>{t('Vault')}</p>
        </li>
        {(user?.type === 'email' || user?.type === 'default') && (
          <li
            typeof="button"
            onClick={() => {
              func(false);

              navigate('/dashboard/pwd-reset');
            }}
          >
            <FiLock />
            <p>{t('Change Password')}</p>
          </li>
        )}

        <li
          typeof="button"
          onClick={() => {
            func();
            navigate('/dashboard/draft/list');
          }}
        >
          <FiEdit />
          <p>{t('Drafts')}</p>
        </li>
        <li
          typeof="button"
          onClick={() => {
            func();
            logOut();
          }}
        >
          <img src={Logout} alt="Profile picture" />
          <p>{t('Logout')}</p>
        </li>
      </ul>
    </ProfileDropContainer>
  );
};

export default ProfileDropDown;
