import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
  Grid,
  makeStyles,
  Typography,
  TextField,
  Button,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import './style/create.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useDropzone } from 'react-dropzone';
import moment from 'moment';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { setInitialMonthArray } from '../../store/features/calendarSlice';
import { handleError } from './CreatePageService';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import client from '../../services';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {
  canvasPreview,
  extractImageFileExtensionFromBase64,
  UploadImageToS3,
} from '../../functions';
import '../component/bespoke-core/calendarComponent/styles/uploadModal.css';
import { RotatingLines } from 'react-loader-spinner';
import { BsFillCloudUploadFill } from 'react-icons/bs';
import '../component/bespoke-core/calendarComponent/styles/uploadModal.css';
import Dialog from '@material-ui/core/Dialog';
import ChooseTemplate from './chooseTemplate';
import { seizer } from '../../functions';
import { useTranslation } from 'react-i18next';
import { Step, StepLabel, Stepper } from '@mui/material';
import StepperButton from '../component/stepperButton/StepperButton';

const useStyles = makeStyles((theme) => ({
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(1)',
    },
    '25%': {
      transform: 'scale(1.03)',
    },
    '50%': {
      transform: 'scale(1.06)',
    },
    '75%': {
      transform: 'scale(1.07)',
    },
    '100%': {
      transform: 'scale(1.1)',
    },
  },

  root: {
    background: '#fff',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '6.7rem',
  },

  calendarDetails: {
    display: 'flex',
    gap: '3rem',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  dialogPaper: {
    minHeight: '55vh',
    maxHeight: '55vh',
  },
  drop: {
    margin: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  preview: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  loader: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  loaderSkeleton: {
    height: '18rem',
  },
  covers: {
    marginTop: 20,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  imageList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  nextCon: {
    marginTop: 10,
  },
  reactSwitch: {
    verticalAlign: 'middle',
    marginLeft: '4px',
  },
  next: {
    background: '#2667B1',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2666B1',
    },
  },
  TextInput: {
    borderRadius: 10,
    width: '100%',
    height: 60,
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      '& fieldset': {
        border: '2px solid #2667b1',
      },
      '&.Mui-focused fieldset': {
        border: '2px solid #2667b1',
      },
    },
  },
  TextInputSocial: {
    borderRadius: 10,
    width: '100%',
    paddingLeft: '50px',
    paddingRight: '50px',
    marginBottom: 15,
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      height: 50,
      '& fieldset': {
        border: '1px solid #000000',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #2667b1',
      },
    },
  },

  floatBtn: {
    position: 'fixed',
    bottom: '100px',
    right: '3%',
    zIndex: '1000px',
    backgroundColor: '#2667B1',
    width: '4rem',
    height: '4rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',

    boxShadow: '0 0 0.5rem rgba(38, 103, 177,0.3)',
    animation: '$ripple 1.2s infinite ease-in-out',
  },

  floatBtnSvg: { fontSize: '1.5rem', color: '#ffffff' },
}));

const CreatePage = () => {
  const { t } = useTranslation(['dashboard']);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const DatePickerStartRange = new Date();
  const currentMonthNumber = DatePickerStartRange.getMonth();
  const [pickStartDate, setPickStartDate] = useState(
    DatePickerStartRange.setFullYear(DatePickerStartRange.getFullYear() - 1)
  );
  const [pickEndDate, setPickEndDate] = useState(new Date('2029/02/10'));
  const [templateError, setTemplateError] = useState(false);
  const [savedate, setSavedate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  );

  const [saveTitle, setSavedTitle] = useState('');
  const [saveSlogan, setSaveSlogan] = useState('');
  const [cover, setCover] = useState(null);
  const [navRoute, setNavRoute] = useState('studio/event');
  const [date, setDate] = useState(false);
  const [slogan, setSlogan] = useState(false);
  const [title, setTitle] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const [openTemplate, setOpenTemplate] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [templatePlan, setTemplatePlan] = useState('');

  const handleCloseTemplate = () => {
    setOpenTemplate(false);
    setLoading(false);
  };

  const handleClickOpenTemplate = (openTemplate) => {
    setOpenTemplate(openTemplate);
    setTemplateError(!openTemplate);
    setIsOpen(!isOpen);
  };

  //mui stepper
  const steps = ['Title', 'Settings', 'Events', 'Preview'];
  const activeStep = 0;
  const completed = {};

  const totalSteps = steps.length;
  const completedSteps = Object.keys(completed).length;
  const allStepsCompleted = completedSteps === totalSteps;

  //mui stepper ends

  const next = () => {
    if (savedate && navRoute && saveTitle && selectedTemplate !== '') {
      dispatch(setInitialMonthArray(savedate.getFullYear()));
      let months = [];
      let dateStart = moment(savedate);
      let dateEnd = moment(savedate).add(12, 'month');

      while (dateEnd.diff(dateStart, 'months') >= 1) {
        let month = {
          date: dateStart.toDate(),
          background: '',
          events: [],
        };

        months.push(month);
        dateStart.add(1, 'month');
      }

      let bespoke = {
        templateName: saveTitle,
        yearPicked: savedate.getFullYear(),
        coverImg: {
          id: 0,
          src: null,
          coverType: 0,
        },
        months: months,
      };

      //mui stepper update state
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      console.log('cp', activeStep);
      //mui stepper end
      // dispatch(generate(bespoke));

      return navigate('/dashboard/template-preview', {
        state: {
          saveDate: savedate,
          saveTitle: saveTitle,
          slogan: saveSlogan,
          cover: cover,
          navRoute: navRoute,
          templateId: selectedTemplate,
          templatePlan,
        },
      });
    } else {
      setTemplateError(true);
    }
  };

  // Error empty fiel handler
  const emptyFieldHandler = () => {
    const formData = {
      date: savedate,
      title: saveTitle,
      slogan: saveSlogan,
    };

    // Handle Error
    let result = handleError(formData);

    for (const el of result[1]) {
      switch (el) {
        case 'date':
          setDate(true);
          break;

        case 'slogan':
          setSlogan(true);
          break;

        case 'title':
          setTitle(true);
          break;

        default:
          return 'err';
      }
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.calendarDetails}>
        <Grid className="create-head" container>
          <Grid className="input_content">
            <Typography variant="body2" color="initial" component="p">
              {t('Title')}
              <span className="text-red">*</span>
            </Typography>
            <TextField
              className={classes.TextInput}
              value={saveTitle}
              onChange={(e) => {
                setSavedTitle(e.target.value);
                setTitle(false);
              }}
              id="outlined-basic-cover title"
              placeholder={t('Bespoke Title')}
              variant="outlined"
              size="large"
            />
            {title && (
              <span className="error-msg">{t('Please enter a title')}</span>
            )}
          </Grid>
        </Grid>

        <Grid
          className="create-head"
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid className="input_content">
            <Typography variant="body2" color="initial" component="p">
              {t('Sub Heading / Slogan')}
            </Typography>
            <TextField
              className={classes.TextInput}
              value={saveSlogan}
              onChange={(e) => {
                setSaveSlogan(e.target.value);
                setSlogan(false);
              }}
              id="outlined-basic-cover title"
              placeholder={t('Slogan')}
              variant="outlined"
              size="large"
            />
          </Grid>
        </Grid>
      </div>

      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={2} sm={2} md={2} lg={2} className={classes.nextCon}>
          <Button
            variant="raised"
            component="span"
            className={classes.next}
            onClick={() => {
              if (savedate && saveTitle) {
                handleClickOpenTemplate(true);
              } else {
                emptyFieldHandler();
              }
            }}
          >
            {t('Continue')}
          </Button>
        </Grid>
      </Grid>

      {/* Dialog for Select*/}
      <Dialog
        open={openTemplate}
        onClose={handleCloseTemplate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
        // classes={{ paper: classes.dialogPaper }}
      >
        <ChooseTemplate
          handleClick={next}
          setSelectedTemplate={setSelectedTemplate}
          selectedTemplate={selectedTemplate}
          handleClickOpenTemplate={handleClickOpenTemplate}
          error={templateError}
          setTemplatePlan={setTemplatePlan}
        />
      </Dialog>
    </div>
  );
};

export default CreatePage;
