import React, { useState, useCallback } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setMonthImage } from '../../store/features/calendarSlice';
import { setShowAddMonthImages } from '../../store/features/modalSlice';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
    height: '100%',
    width: '100%',
  },
  dropZone: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  preview: {
    marginTop: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  covers: {
    marginTop: 20,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  imageList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },

  btnContainer: {
    marginTop: 70,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  btn: {
    border: 'none',
    background: '#2667B1',
    color: '#fff',
    width: '160px',
    padding: '10px 15px',
    borderRadius: '8px',
  },
}));

const FileUpload = ({ monthNumber }) => {
  const dispatch = useDispatch();

  const [cover, setCover] = useState(null);
  const [navRoute, setNavRoute] = useState(null);
  const classes = useStyles();
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Send Content to server
        const binaryStr = reader.result;
        console.log(binaryStr);
        setCover(binaryStr);
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.png'],
    },
  });

  const handleCoverImage = () => {
    if (cover !== null) {
      dispatch(setMonthImage({ monthNumber, cover }));
      dispatch(setShowAddMonthImages(false));
    }
  };

  return (
    <div className={classes.root}>
      {!cover ? (
        <div {...getRootProps()} className={classes.dropZone}>
          <input {...getInputProps()} />
          {isDragAccept && <p>Drop the month cover here...</p>}
          {isDragReject && <p>Month cover ought to be a png file</p>}
          {!isDragActive && <p>Click to up load image</p>}
        </div>
      ) : (
        <div className={classes.preview}>
          <Grid item xs={10} sm={10} md={8} lg={8} onClick={open}>
            <img
              src={cover}
              style={{
                maxHeight: '14rem',
                width: '100%',
                objectFit: 'cover',
                imageRendering: 'crisp-edges',
              }}
            />
          </Grid>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
