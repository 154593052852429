import React, { useState, useCallback, useEffect } from 'react';
import { ProfileContainer } from './component/styles/Profile.style';
import { useSelector, useDispatch } from 'react-redux';
import {
  makeStyles,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  FormHelperText,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  withStyles,
  Badge,
  Avatar,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import 'react-phone-number-input/style.css';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import {
  Lock,
  VisibilityOff,
  Visibility,
  ExpandMore,
  AddAPhoto,
  AlternateEmail,
} from '@material-ui/icons';
import { RotatingLines } from 'react-loader-spinner';
import { useDropzone } from 'react-dropzone';
import {
  cancelSubscription,
  loadUser,
  profileImageUpdate,
  profileUpdate,
} from '../store/reducers/userReducer';
import { toast } from 'react-toastify';
import client from '../services';
import { useTranslation } from 'react-i18next';

// Form styles
const useStyles = makeStyles((theme) => ({
  TextInput: {
    borderRadius: 10,
    width: '100%',
  },
  accord: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordInput: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  largeImg: {
    width: '8rem',
    height: '8rem',
    fontSize: '60px',
    fontWeight: 500,
  },

  smallNotice: {
    marginTop: '-10px',
    fontWeight: 400,
    fontSize: '0.7rem',
    lineHeight: '19px',
    color: '#464646',
  },

  smallNoticeBirthday: {
    marginTop: '-18px',
    marginBottom: '10px',
    fontWeight: 400,
    fontSize: '0.7rem',
    lineHeight: '19px',
    color: '#464646',
  },
}));

const Profile = () => {
  const { t } = useTranslation(['dashboard']);
  const [loading, setLoading] = useState(false);
  const [subProcessing, setSubProcessing] = useState(false);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const classes = useStyles();

  // Loading State
  const [processing, setProcessing] = useState(false);

  // Error Validation
  let errorsObj = {
    userName: '',
    isUserName: '',
    email: '',
    isemail: false,
    firstName: '',
    isfirstName: false,
    lastName: '',
    islastName: false,
    organisationName: '',
    isorganisation: false,
    phone: '',
    countryAbbr: '',
    countryCode: '',
    isphone: false,
  };
  const [errors, setErrors] = useState(errorsObj);

  // User Data
  const [values, setValues] = useState({
    email: user?.email,
    firstName: user.firstName,
    lastName: user.lastName,
    organisationName: user.organisationName,
    authorizationToken: user.authorizationToken,
    accountType: user.accountType,
    phone: user.phone,
    countryAbbr: user.countryAbbr,
    countryCode: user.countryCode,
    dob: user.dob,
    userName: user.userName,
  });

  // Value change
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });

    if (event.target.value !== '') {
      setErrors({ ...errors, [prop]: '', [`is${prop}`]: false });
    }
  };
  //  Number change
  const numChange = (num) => {
    const phoneDetails = parsePhoneNumber(`${num}`);

    // For Ascenstoin Island to default to SH
    if (
      phoneDetails?.country === 'AC' &&
      phoneDetails?.countryCallingCode === '247'
    ) {
      phoneDetails['country'] = 'SH';
    }

    setValues({
      ...values,
      phone: phoneDetails?.nationalNumber,
      countryAbbr: phoneDetails?.country,
      countryCode: phoneDetails?.countryCallingCode,
    });
    console.log(values);
  };
  //  DOB change
  const dobChange = (num) => {
    setValues({ ...values, dob: num });
  };

  // Submit function
  const onSubmit = async () => {
    setProcessing(true);
    let error = false;
    const errorObj = { ...errorsObj };
    if (values?.email.trim() === '') {
      errorObj.email = 'Email is Required';
      errorObj.isemail = true;
      error = true;
    }
    if (values?.password === '') {
      errorObj.password = 'Password is Required';
      errorObj.ispassword = true;
      error = true;
    }
    if (values?.password !== values.confirmPassword) {
      errorObj.confirmPassword =
        'Password and confirm password must be the same';
      errorObj.isconfirmPassword = true;
      error = true;
    }
    if (
      values?.firstName.trim() === '' &&
      values.accountType === 'individual'
    ) {
      errorObj.firstName = 'Firstname is Required';
      errorObj.isfirstName = true;
      error = true;
    }
    if (values?.lastName.trim() === '' && values.accountType === 'individual') {
      errorObj.lastName = 'Lastname is Required';
      errorObj.islastName = true;
      error = true;
    }
    if (values?.userName.trim() === '') {
      errorObj.userName = 'User name is Required';
      errorObj.isUserName = true;
      error = true;
    }

    if (
      values.organisationName === '' &&
      values.accountType === 'organisation'
    ) {
      errorObj.organisationName = 'Organisation name is Required';
      errorObj.isorganisation = true;
      error = true;
    }
    // if (values.phone === '') {
    //   errorObj.phone = 'Phone Number is Required';
    //   errorObj.isphone = true;
    //   error = true;
    // }
    if (error) {
      setTimeout(() => {
        setProcessing(false);
        setErrors(errorObj);
      }, 3000);
    } else {
      var { ...data } = values;

      // console.log('Data sent to the backend', data);
      await client
        .put('/Account/profile-update', data)
        .then((res) => {
          setProcessing(false);
          dispatch(profileUpdate(values));
          toast.success(t('Profile update is successful'));
        })
        .catch((e) => {
          console.log(e);
          setProcessing(false);
          toast.error(e?.response?.data?.Message ?? e?.message);
        });
    }
  };

  // Upload function
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = async () => {
        setLoading(true);
        const binaryStr = reader.result;
        // Send Content to server
        await client
          .post('/Account/user-photo-upload', binaryStr)
          .then((res) => {
            console.log(res?.data?.picture, 'res');
            dispatch(profileImageUpdate(res?.data?.picture));
            //  dispatch(profileImageUpdate(binaryStr));
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            toast.error(e?.response?.data?.Message ?? e?.message);
            setLoading(false);
          });
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.png'],
    },
  });

  // Cancel Subscription
  const handleCancelSubscription = async () => {
    setSubProcessing(true);
    await client
      .post('/Subscription/cancelsubscription')
      .then((res) => {
        setSubProcessing(false);
        toast.success('subscription cancelled');
        dispatch(cancelSubscription({ cancelsubscription: true }));
        console.log(res);
      })
      .catch((error) => {
        setSubProcessing(false);
        toast.error(error.response?.data?.Message ?? error?.message);
      });
  };

  return (
    <ProfileContainer>
      <div className="main-wrap">
        {/* <h3>Welcome, {user.firstName || user.organisationName}</h3> */}
        <div className="background-container" {...getRootProps()}>
          <input {...getInputProps()} />
          {loading ? (
            <CircularProgress />
          ) : user?.picture ? (
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={<AddAPhoto />}
            >
              <Avatar
                alt="Name"
                src={user?.picture}
                className={classes.largeImg}
              />
            </Badge>
          ) : (
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={<AddAPhoto />}
            >
              <Avatar className={classes.largeImg}>{user?.firstName[0]}</Avatar>
            </Badge>
          )}
        </div>

        <p className="update">{t('Update Profile Photo')}</p>
        <h4>{t('Profile settings')}</h4>
        <form>
          <div className="main-form">
            <TextField
              className={classes.TextInput}
              value={values.userName}
              onChange={handleChange('userName')}
              error={errors.isUserName}
              helperText={errors.userName}
              id="outlined-basic-username"
              label={t('Username')}
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmail fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              className={classes.TextInput}
              value={values.email}
              onChange={handleChange('email')}
              error={errors.isemail}
              helperText={errors.email}
              id="outlined-basic-email"
              label={t('E-mail')}
              variant="outlined"
              size="small"
              disabled
            />

            {values.accountType === 'individual' && (
              <>
                <TextField
                  className={classes.TextInput}
                  value={values.firstName}
                  onChange={handleChange('firstName')}
                  error={errors.isfirstName}
                  helperText={errors.firstName}
                  id="outlined-basic-firstname"
                  label={t('First Name')}
                  variant="outlined"
                  size="small"
                />
                <TextField
                  className={classes.TextInput}
                  value={values.lastName}
                  onChange={handleChange('lastName')}
                  error={errors.islastName}
                  helperText={errors.lastName}
                  id="outlined-basic-lastname"
                  label={t('Last Name')}
                  variant="outlined"
                  size="small"
                />

                <KeyboardDatePicker
                  error={false}
                  margin="normal"
                  id="date-picker-dialog"
                  label={t('Birthday')}
                  format="dd/MM"
                  placeholder="Optional for birthday only"
                  value={values.dob}
                  onChange={dobChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <div className={classes.smallNoticeBirthday}>
                  {t('Used to notify your friends to celebrate you')}
                </div>
              </>
            )}
            {values.accountType === 'organisation' && (
              <TextField
                className={classes.TextInput}
                value={values.organisationName}
                onChange={handleChange('organisationName')}
                error={errors.isorganisation}
                helperText={errors.organisationName}
                id="outlined-basic-organisation"
                label={t('Organization Name')}
                variant="outlined"
                size="small"
              />
            )}
            {/* <TextField
              className={classes.TextInput}
              value={values.phone}
              onChange={handleChange('phone')}
              error={errors.isphone}
              helperText={errors.phone}
              id="outlined-basic-phone"
              label="Phone"
              variant="outlined"
              size="small"
            /> */}

            <PhoneInput
              style={{
                padding: '10px',
                borderBottom: '1px solid grey',
              }}
              country={values.countryAbbr}
              placeholder={t('Phone Number')}
              value={
                values.phone ? `+${values.countryCode}${values.phone}` : ''
              }
              onChange={numChange}
            />
            <span className={classes.smallNotice}>
              {t('Your phone number may be used for authentication')}
            </span>
          </div>
          <div className="button-container">
            <Button
              startIcon={
                <RotatingLines
                  strokeColor="#fff"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={processing}
                />
              }
              variant="contained"
              color="primary"
              style={{ textTransform: 'none' }}
              onClick={() => onSubmit()}
            >
              {processing ? t('Processing') : t('Update')}
            </Button>
          </div>
        </form>
        <div style={{ marginTop: '130px' }}>
          {/* <hr /> */}
          {user?.subscription ? (
            <div className={classes.accord} style={{}}>
              <Typography
                className={classes.heading}
                style={{ marginBottom: '15px' }}
              >
                {t('Subscription Plan')}
              </Typography>
              <Grid
                container
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Grid item sm={6} md={6}>
                  <Button
                    variant="contained"
                    // color="primary"
                    style={{
                      textTransform: 'none',
                      background: '#2667B1',
                      color: '#fff',
                    }}
                  >
                    {user?.subscription?.subscriptionName}
                  </Button>
                </Grid>

                {user?.cancelsubscription === false ? (
                  <Grid
                    item
                    sm={6}
                    md={6}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Button
                      startIcon={
                        <RotatingLines
                          strokeColor="#2667b1"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="20"
                          visible={subProcessing}
                        />
                      }
                      variant="outlined"
                      color="primary"
                      style={{
                        textTransform: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onClick={() => handleCancelSubscription()}
                    >
                      {subProcessing
                        ? t('processing...')
                        : t('Cancel Subscription')}
                    </Button>
                  </Grid>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{
                      textTransform: 'none',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    disabled
                  >
                    {t('Subscription cancelled')}
                  </Button>
                )}
              </Grid>
            </div>
          ) : null}
        </div>
      </div>
    </ProfileContainer>
  );
};

export default Profile;
