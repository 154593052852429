import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assest/fonts/fonts.css';
import './App.css';
import Markup from './routes';
import { onMessage } from 'firebase/messaging';
import { requestForToken, messaging } from './helpers/firebase';
import { Typography, makeStyles } from '@material-ui/core/';
import { useDispatch } from 'react-redux';
import { newNotification } from './store/reducers/userReducer';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import client from './services';
// import "../public/DateTimePicker.css";
// import "./TimePicker.css";

const stripePromise = loadStripe(
  process.env.NODE_ENV == 'development'
    ? process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY
    : process.env.REACT_APP_STRIPE_LIVE_PUBLIC_KEY
);
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY);

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
};

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: 'left',
    fontWeight: 'bold',
  },
  textCon: {
    padding: '0px 4px 0px 4px',
    width: '80%',
  },
  des: {
    textAlign: 'left',
    textOverflow: 'elispe',
    color: 'grey',
  },
  notificationCard: {
    display: 'flex',
    minWidth: '15rem',
    height: '80px',
    background: '#fff',
  },
  img: {
    width: '100px',
    height: '80px',
  },
}));

function App() {
  const classes = useStyles();
  const dispatch = useDispatch();
  React.useEffect(() => {
    // Get Device Token
    requestForToken();
  }, []);
  // Forebackground Listener
  onMessage(messaging, (payload) => {
    // console.log('payload fom fire base', payload);
    toast(
      <div className={classes.notificationCard}>
        <div className={classes.textCon}>
          <Typography className={classes.heading}>
            {payload?.notification?.title}
          </Typography>
          <Typography className={classes.des} variant="subtitle1">
            {payload?.notification?.body}
          </Typography>
        </div>
      </div>,
      {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'light',
      }
    );

    const data = {
      type: 'firebase',
      notification: {
        title: payload?.notification?.title,
        summary: payload?.data?.summary,
      },
    };

    dispatch(newNotification(data));
  });

  // Stripe initialization
  return (
    <>
      <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
        <Markup />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Elements>
    </>
  );
}

export default App;
