import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showEvent: false,
  showTime: false,
  showMonth: false,
  showAddMonthImages: false,
  showWalletDashboard: false,
};

const modalSlice = createSlice({
  name: 'calendarModal',
  initialState,
  reducers: {
    setShowEventModel: (state, { payload }) => {
      state.showEvent = payload;
    },
    setShowTimeModel: (state, { payload }) => {
      state.showTime = payload;
    },
    setShowWalletDashbaordModel: (state, { payload }) => {
      state.showWalletDashboard = payload;
    },
    setShowMonthModel: (state, { payload }) => {
      state.showMonth = payload;
    },
    setShowAddMonthImages: (state, { payload }) => {
      state.showAddMonthImages = payload;
    },
  },
});

export const {
  setShowEventModel,
  setShowTimeModel,
  setShowWalletDashbaordModel,
  setShowAddMonthImages,
  setShowMonthModel,
} = modalSlice.actions;
export default modalSlice.reducer;
