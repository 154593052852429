import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  constants: [],
  covers: [],
};

export const drafts = createSlice({
  name: 'drafts',
  initialState,
  reducers: {
    loadDrafts: (state, { payload }) => {
      return {
        ...state,
        constants: payload,
        covers: payload,
        // constants: [ ...payload],
        // covers: [...payload],
      };
    },
    loadMoreDrafts: (state) => {
      return state;
    },
    filterDraft: (state, action) => {
      let arr = [...state.constants];
      if (action?.payload?.toLowerCase() == 'all') {
        return {
          ...state,
          covers: arr,
        };
      }
      let filtered;
      filtered = arr.filter(function (item) {
        return item?.privacy?.includes(action.payload);
      });
      return {
        ...state,
        covers: filtered,
      };
    },
  },
});

export const { loadDrafts, loadMoreDrafts, filterDraft } = drafts.actions;

export default drafts.reducer;
