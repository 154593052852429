import styled from 'styled-components';

export const FAQContainer = styled.div`
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  .faq_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    margin-bottom: 4rem;
    h2 {
      font-weight: 700;
      font-size: 2rem;
      line-height: 57px;
    }

    h3 {
      font-weight: 400;
      font-size: 1rem;
      line-height: 33px;
    }
  }
`;

export const FAQWrapper = styled.div`
  margin-top: 50px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  position: relative;

  .Question {
    border: 1px solid #2667b1;

    border-radius: 8px;
    color: #fff;
    border-radius: 12px;
    padding: 12.5px 2rem;
    z-index: 7;
    position: relative;
    display: flex;
    justify-content: space-between;
    color: #0a0a0a;
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 28px;

    svg {
      font-size: 1.3rem;
      cursor: pointer;
    }

    .minus {
      color: #fff;
    }
  }

  .Answer {
    padding: 5px 30px 20px 30px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(38, 103, 177, 0.25);
    border-radius: 0px 0px 12px 12px;
    color: #161616;
    z-index: -3;
    border-radius: 5px;
    margin-top: -10px;
    gap: 20px;
    display: flex;
    flex-direction: column;

    .innerContent {
      padding: 10px 2rem;
      gap: 20px;
      display: flex;
      flex-direction: column;
      p {
        font-weight: bold;
        margin-left: -10px;
      }

      ul {
        gap: 8px;
        display: flex;
        flex-direction: column;

        li {
          list-style: disc;
          line-height: 1.5rem;
        }

        animation: fadein 0.5s ease-in-out;

        @keyframes fadein {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      }
    }
  }

  .hide {
    display: none;
  }

  .blue {
    background: #2667b1;
    color: #fff;
  }
`;
