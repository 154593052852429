import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Month from '../Month2';
import { makeStyles, Grid } from '@material-ui/core';
import { BallTriangle } from 'react-loader-spinner';
import client from '../../../services';
import { loadBespoke } from '../../../store/reducers/bespokeView';
import styled from 'styled-components';
import BrokenLink from '../../../assest/images/broken_link_logo.svg';
import LazyLoad from 'react-lazy-load';

const useStyles = makeStyles({
  root: {
    background: '#fff',
    padding: '20px',
  },
  loader: {
    width: '100%',
    height: '100vh',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tradeMark: {
    marginLeft: 10,
    display: 'flex',
    flexDirection: 'row',
    '& p': {
      color: '#000000',
      fontSize: 24,
      marginRight: 8,
    },
  },
});

const Widget = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // console.log(params, 'url param');
  const beskpoke = useSelector((state) => state.bespoke);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const classes = useStyles();

  // Fetch bespoke Details
  const getBespoke = async (id) => {
    await client
      .post(`/Souvenir/widgetlist/${id}`)
      .then((res) => {
        console.log(res?.data, 'get bespoke calendar');
        dispatch(loadBespoke(res?.data));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setError(true);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (params?.id) {
      getBespoke(params?.id);
    }
  }, []);
  return !error ? (
    loading ? (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.loader}
      >
        <Grid item xs={2} md={2} sm={2} lg={2} className={classes.center}>
          <BallTriangle
            height={'75%'}
            width={'75%'}
            radius={5}
            color="#2667b1"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
          />
        </Grid>
      </Grid>
    ) : (
      <>
        <div className="month-cover-wiget">
          <LazyLoad height={'100%'} width={'100%'} offset={200}>
            <img
              src={beskpoke?.coverImg?.src}
              className="month-cover-wiget"
              alt="cover"
            />
          </LazyLoad>
        </div>

        <section>
          <div className=" h-96 bg-contain bg-center bg-no-repeat w-full">
            <div className="flex justify-center ">
              <h1 className="text-3xl my-4 text-center">
                {beskpoke.organisationName ||
                  `${beskpoke.firstName} ${beskpoke.lastName}`}{' '}
              </h1>
            </div>

            <div className="widget-calendar">
              <Month bespoke={beskpoke.months} />
            </div>

            {beskpoke?.calendarPlan !== 'Premium' && (
              <div className={classes.tradeMark}>
                <p>Powered by lectie</p>
                <img src={require('../../../assest/images/Logo1.png')} />
              </div>
            )}
          </div>
        </section>
      </>
    )
  ) : (
    <BrokenContainer className="broken_link_container">
      <div className="broken_link__header">
        <h1>Ohh, Ohh We Are Sorry</h1>
        <p className="broken_link_text">The bespoke calendar does not exist</p>
      </div>

      <div className="img_container">
        <img src={BrokenLink} alt="Broken link picture" />
      </div>
    </BrokenContainer>
  );
};

export default Widget;

const BrokenContainer = styled.div`
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding: 2rem 0;
  margin-top: 5rem;

  .broken_link__header {
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 2rem;
      color: #0a0a0a;
      text-align: center;
    }

    p {
      font-weight: 400;
      font-size: 0.8rem;
      line-height: 33px;
      text-align: center;
      color: #0a0a0a;
    }
  }

  .btn_container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    button {
      padding: 12px;
      width: 230px;
      font-size: 0.9rem;
      text-align: center;
      color: #fff;
      background: #2667b1;
      border-radius: 12px;
      cursor: pointer;
    }
  }
  .img_container {
    margin-top: 4rem;
    max-width: 330px;

    img {
      width: 100%;
    }
  }
`;
