import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  constants: [],
  covers: [],
};

export const files = createSlice({
  name: 'files',
  initialState,
  reducers: {
    loadCovers: (state, { payload }) => {
      return {
        ...state,
        constants: [...payload],
        covers: [...payload],
      };
    },
    loadMoreCovers: (state) => {
      return state;
    },
    filterCover: (state, action) => {
      let arr = [...state.constants];
      if (action?.payload?.toLowerCase() == 'all') {
        return {
          ...state,
          covers: arr,
        };
      }
      let filtered;
      filtered = arr.filter(function (item) {
        return item?.privacy?.includes(action.payload);
      });
      return {
        ...state,
        covers: filtered,
      };
    },
    deleteCoverEvent: (state, { payload }) => {
      let filtered = state.covers.filter(({ id }) => id !== payload);
      return {
        ...state,
        covers: filtered,
      };
    },
  },
});

export const { loadCovers, loadMoreCovers, filterCover, deleteCoverEvent } =
  files.actions;

export default files.reducer;
