import React, { useState, useEffect } from 'react';
import { PlansContainer } from './style/plans.style';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Dialog,
} from '@material-ui/core';
import { BallTriangle } from 'react-loader-spinner';
import 'react-phone-number-input/style.css';
// const { userDetails } = useSelector((state) => state.user);

import { toast } from 'react-toastify';
import client from '../../services';
import { useTranslation } from 'react-i18next';
import { getCountyCode, userGeolocation } from '../../functions';

// Form styles
const useStyles = makeStyles((theme) => ({
  TextInput: {
    borderRadius: 10,
    width: '100%',
  },
}));

const Plans = () => {
  let Ucurrency = '';
  const { t } = useTranslation(['common']);
  const [loading, setLoading] = useState(true);
  const [subProcessing, setSubProcessing] = useState(false);
  const [type, setType] = useState('yearly');
  const [currency, setCurrency] = useState('');
  const [userCurrency, setUserCurrency] = useState(Ucurrency);
  const [userLocation, setUserLocation] = useState('');
  const [subscriptions, setSubscriptions] = useState();

  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  const [calendarQuantity, setCalendarQuantity] = useState({
    Basic: 1,
    Pro: 1,
    Premium: 1,
  });

  const increaseCalendarQty = (planName) => {
    setCalendarQuantity((prev) => ({
      ...prev,
      [planName]: prev[planName] + 1,
    }));
  };

  const decreaseCalendarQty = (planName) => {
    setCalendarQuantity((prev) => ({
      ...prev,
      [planName]: prev[`${planName}`] - 1,
    }));
  };

  // Fetch Subscription
  const listSubscriptions = async () => {
    setSubProcessing(true);
    const currentUserLocation = new userGeolocation().getUserCountry();

    let { Code, CountryCode } = getCountyCode(currentUserLocation) || 'NGN';

    setUserLocation(Code);
    const type = 'Yearly';

    console.log(' in currency User location === ', CountryCode);

    await client
      .get(`/Subscription/Subscription/${type}/${Code}`)
      .then((res) => {
        console.log('ResData ', res?.data, ' Country code ', CountryCode);
        let subData = res.data.subscriptions;
        setCurrency(subData[0].currency);
        setUserCurrency(subData[0].currency);

        setSubscriptions(subData);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response?.data?.Message ?? error?.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    listSubscriptions();
  }, []);

  return (
    <PlansContainer>
      <div className="main-wrap">
        <h2 className="font-extrabold font-body my-8 text-xl">
          {t('Available plan')}
        </h2>

        {loading ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.loader}
          >
            <Grid item xs={2} md={2} sm={2} lg={2}>
              <BallTriangle
                height={'100%'}
                width={'100%'}
                radius={5}
                color="#2667b1"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            </Grid>
          </Grid>
        ) : (
          <div>
            {subscriptions &&
              subscriptions.map(
                (item, id) =>
                  item.subscriptionName !== 'Basic' && (
                    <div className="p-6 mb-6 shadow-md shadow-[#407bff47] rounded-2xl">
                      <div className="text-center">
                        <h2
                          className="font-body font-extrabold"
                          style={{ fontWeight: 'bold' }}
                        >
                          {item?.subscriptionName} Plan
                        </h2>
                        <p className="text-sm">
                          Subscriptions are billed annually
                        </p>
                      </div>

                      <div class="grid md:grid-cols-4 gap-1 mt-6 md:divide-x font-body">
                        <div className=" col-span-3 ">
                          <p className="text-md font-body font-bold">
                            Features of this plan
                          </p>
                          <div className=" md:flex justify-between py-4 pr-4 font-body">
                            <p className="text-xs ">Calendar hosting</p>
                            <p className="text-xs text-left">
                              {item.calender_Hosting == true ? 'Yes' : 'No'}
                            </p>
                            <p className="text-xs">
                              Reminder notifications for events
                            </p>
                            <p className="text-xs">
                              {item.reminder_notification}
                            </p>
                          </div>
                          <div className=" md:flex justify-between py-4 pr-4">
                            <p className="text-xs">Live calendar updates</p>
                            <p className="text-xs text-left">
                              {item.calender_Update == true ? 'Yes' : 'No'}
                            </p>
                            <p className="text-xs ">
                              Add social media linked Icons
                            </p>
                            <p className="text-xs">
                              {item.socal_media_link_icon == true
                                ? 'Yes'
                                : 'No'}
                            </p>
                          </div>
                          <div className=" md:flex justify-between py-4 pr-4">
                            <p className="text-xs">
                              Widget to embed on website
                            </p>
                            <p className="text-xs">
                              {item.embed_Widget == true ? 'Yes' : 'No'}
                            </p>
                            <p className="text-xs">
                              Calendar recipients can share event invites
                            </p>
                            <p className="text-xs">
                              {item.receipicent_share_event == true
                                ? 'Yes'
                                : 'No'}
                            </p>
                          </div>
                          <div className=" md:flex justify-between py-4 pr-4 font-body">
                            <p className="text-xs">Size of audience</p>
                            <p className="text-xs">Unlimited</p>
                            <p className="text-xs">
                              Lectie watermark on website
                            </p>
                            <p className="text-xs">
                              {' '}
                              {item.watermark == true ? 'Yes' : 'No'}
                            </p>
                          </div>

                          <div className=" md:flex justify-between py-4 pr-4">
                            <p className="text-xs">Private calendar</p>
                            <p className="text-xs text-left">
                              {item.private_calendar ? 'Yes' : 'No'}
                            </p>
                            <p></p>
                            <p></p>
                          </div>
                        </div>
                        <div className=" sm:mt-8 md:mt-0 md:pl-4 sm:pl-0">
                          <p className="text-md font-body font-bold">
                            Customize your calendar plan
                          </p>

                          <div className="md:flex justify-between my-3 text-xs">
                            <p className="text-xs">Number of calendar</p>

                            <p className="text-xs bg-gray-50 rounded-md border px-2">
                              {calendarQuantity[`${item?.subscriptionName}`] >
                                1 && (
                                <span
                                  className="text-xs cursor-pointer"
                                  onClick={() =>
                                    decreaseCalendarQty(item?.subscriptionName)
                                  }
                                >
                                  {' '}
                                  -{' '}
                                </span>
                              )}
                              <span className="text-xs text-[#066D86] font-bold">
                                {' '}
                                {calendarQuantity[`${item?.subscriptionName}`]}
                              </span>{' '}
                              <span
                                className="text-xs cursor-pointer"
                                onClick={() =>
                                  increaseCalendarQty(item?.subscriptionName)
                                }
                              >
                                {' '}
                                +
                              </span>
                            </p>
                          </div>

                          <div className=" my-3 text-xs">
                            <p className="text-[12px] text-left">
                              <span className="font-bold font-body">
                                {item?.currency}{' '}
                                {(
                                  item.price *
                                  calendarQuantity[`${item?.subscriptionName}`]
                                ).toFixed(2)}
                              </span>
                              &nbsp;Per calendar/ year
                            </p>
                            <p className="text-[12px] text-left">
                              VAT and local taxes may apply
                            </p>
                          </div>
                          <Button
                            variant="contained"
                            // color="primary"
                            style={{
                              textTransform: 'none',
                              background: '#2667B1',
                              color: '#fff',
                              fontSize: 12,
                              padding: '0 64px',
                              marginTop: 20,
                            }}
                            onClick={() => {
                              navigate('/dashboard/upgrade-summary', {
                                state: {
                                  plan: item?.subscriptionName,
                                  moreQty:
                                    calendarQuantity[
                                      `${item?.subscriptionName}`
                                    ],
                                  subscriptionSelected: item,
                                  userCurrency,
                                  currency,
                                  calendarQuantity,
                                  userLocation,
                                  addPlans: true,
                                },
                              });
                            }}
                          >
                            Select
                          </Button>
                        </div>
                      </div>
                    </div>
                  )
              )}
          </div>
        )}
      </div>
    </PlansContainer>
  );
};

export default Plans;
