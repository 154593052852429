import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StepConnector from '@material-ui/core/StepConnector';
import Details from './Details';
import PaymentForm from './PaymentForm';
import SucessPage from './SucessPage';
import client from '../../services';
import Header from '../component/bespoke_header/Header';
import HeaderNoLogic from '../component/bespoke_header/HeaderNoLogic';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getCountyCode, userGeolocation } from '../../functions';
import { RotatingLines } from 'react-loader-spinner';

// Page styles
const styles = (theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
  },
  stepper: {
    padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
    '& .MuiStepIcon-completed': {
      color: '#1AFE31',
    },
    '& .MuiStepIcon-active': {
      color: '#2667B1',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
    clear: 'both',
    border: 'none',
    color: '#fff',
    height: '55px',
    width: '179px',
    // margin: '0 auto',
    background: '#2667b1',
    borderRadius: '12px',
    cursor: 'pointer',
    '& .MuiButton-label': {
      fontSize: '14px',
    },
  },
});

// Progress Line
const ColorlibConnector = withStyles({
  alternativeLabel: {
    // top: 22,
  },
  active: {
    '& $line': {
      background: '#2667B1',
    },
  },
  completed: {
    '& $line': {
      background: '#2667B1',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

// All Steps Labels
const steps = ['Details', 'Checkout', 'Confirmation'];

function Checkout(props) {
  let Ucurrency = '';

  const { t } = useTranslation(['dashboard']);
  const draft = useSelector((state) => state.draft);
  const userDeatls = useSelector((state) => state.user);
  const [activeStep, setActiveStep] = useState(0);
  const [userLocation, setUserLocation] = useState('');
  const [currency, setCurrency] = useState(Ucurrency);
  const [variation, setVariation] = useState(true);
  const [user, setUser] = useState({
    email: userDeatls?.email,
    phone: userDeatls?.phone,
  });
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const [selected, setSelected] = useState({});

  // Load plans
  const getplans = async () => {
    // var type = variation ? 'Yearly' :"Monthly";
    var type = 'Yearly';

    let country = new userGeolocation().getUserCountry(); // Eg NGN for Nigeria

    let { Code } = getCountyCode(country) || 'NGN';

    console.log(draft?.templateId, '  ID TEM ');
    await client
      .get(
        `/Subscription/fetchtemplatesubscription/${draft?.templateId}/${Code}`
      )
      .then((res) => {
        setPlans(res?.data);
        setUserLocation(country);
        setCurrency(res?.data[0].currency);
        fetchSubscription(res?.data[0].subscriptionName);
        // Default selected
        setSelected(res?.data[0]);

        console.log(res?.data, ' this is computer ');
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  // Load plans
  const getUserPlans = async (data) => {
    setLoading(true);

    await client
      .get(
        `/Subscription/fetchtemplatesubscription/${draft?.templateId}/${data?.currency}`
      )
      .then((res) => {
        setPlans(res?.data);
        // Default selected
        setSelected(res?.data[0]);

        console.log('User plans --- ', res?.data);

        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getplans();
  }, []);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const sucessful = () => {
    setActiveStep(steps.length - 1);
  };

  const [flutterDetails, setFlutterDetails] = useState({});
  const [subscriptionRes, setSubscriptionRes] = useState({});
  const [loadFlutter, setLoadflutter] = useState(false);

  // Fetch Subscription
  const fetchSubscription = async (subName) => {
    let country = new userGeolocation().getUserCountry(); // Eg NGN for Nigeria

    let { CountryCode } = getCountyCode(country) || 'NGN';

    setLoadflutter(true);
    await client
      .get(`/Subscription/fetchSubscription/${subName}/${CountryCode}`)
      .then((res) => {
        setSubscriptionRes(res.data);

        console.log(res?.data, ' THis is it ... ');
        // setSubPrice(res.data.price);
        initializeFlutterwave(res?.data.id);
        setLoadflutter(false);
      })
      .catch((error) => {
        toast.error(error.response?.data?.Message ?? error?.message);
        setLoadflutter(false);
      });
  };

  const initializeFlutterwave = async (subId) => {
    console.log(
      {
        qty: 1,
        subscriptionID: subId,
        souvenirID: draft?.id,
        calendarPlan: draft?.calendarPlan,
      },
      ' initialize flutter '
    );
    await client
      .post('/Subscription/intializeFlutterWavePayment', {
        qty: 1,
        subscriptionID: subId,
        souvenirID: draft?.id,
        calendarPlan: draft?.calendarPlan,
      })
      .then((res) => {
        res?.data;
        setFlutterDetails(res?.data);

        console.log(
          {
            ...res?.data,
          },
          ' init Futter'
        );

        setLoading(false);
        // setCurrencyValue(flutterDetails?.currency);
      })
      .catch((error) => {
        toast.error(error.response?.data?.Message ?? error?.message);
        setLoading(false);
      });
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Details
            user={user}
            setUser={setUser}
            plans={plans}
            getUserPlans={getUserPlans}
            loading={loading}
            selected={selected}
            setSelected={setSelected}
            setCurrency={setCurrency}
            currency={currency}
            variation={variation}
            setVariation={setVariation}
            flutterFunc={fetchSubscription}
          />
        );
      case 1:
        return (
          <PaymentForm
            func={sucessful}
            user={user}
            selected={selected}
            setCurrency={setCurrency}
            currency={currency}
            variation={variation}
            userLocation={userLocation}
            flutterDetails={flutterDetails}
            loadFlutter={loadFlutter}
            setLoadflutter={setLoadflutter}
            isCheckout={true}
          />
        );
      case 2:
        return <SucessPage />;
      default:
        throw new Error('Unknown step');
    }
  }

  const { classes } = props;
  return (
    <React.Fragment>
      {/* <div>header goes here</div> */}
      <HeaderNoLogic />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            {t('Calendar subscription')}
          </Typography>
          <Stepper
            activeStep={activeStep == 2 ? activeStep + 1 : activeStep}
            className={classes.stepper}
            alternativeLabel
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{t(label)}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            <React.Fragment>
              {getStepContent(activeStep)}
              {activeStep === steps.length - 1 ? null : (
                <div className={classes.buttons}>
                  {activeStep !== 0 && !loadFlutter && (
                    <Button
                      onClick={handleBack}
                      className={classes.button}
                      style={{ backgroundColor: '#2667b1' }}
                    >
                      {t('Back')}
                    </Button>
                  )}
                  {activeStep !== 1 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        if (!loadFlutter && !loading) {
                          handleNext();
                        }
                      }}
                      className={classes.button}
                      style={{ backgroundColor: '#2667b1' }}
                    >
                      {t('Proceed')}

                      {
                        <RotatingLines
                          strokeColor="#fff"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="20"
                          visible={loadFlutter}
                        />
                      }
                    </Button>
                  )}
                </div>
              )}
            </React.Fragment>
          </React.Fragment>
        </Paper>
      </main>
    </React.Fragment>
  );
}

export default withStyles(styles)(Checkout);
