import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import RoundCheck from '../../assest/images/roundCheck.png';
import { emailInvite } from '../../functions';
// import MultiSelectDropdown from "react-multiselect-dropdown";

import { BsSendFill } from 'react-icons/bs';
import { TextField } from '@material-ui/core';
import client from '../../services';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const InviteSetting = ({
  setCalendarType,
  status,
  id,
  lectieInvitees,
  pictures,
}) => {
  const [inviteSwitcher, setInviteSwitcher] = useState('lectie');
  const [publicCalendar, setPublicCalendar] = useState(true);

  const [searchQuery, setSearchQuery] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(true);
  const [dropdownData, setDropdownData] = useState([]);

  const [selectedValue, setSelectedValue] = useState('');
  const [selectedValuesArray, setSelectedValuesArray] = useState([]);

  const [emails, setEmails] = useState([]);
  const [emailInput, setEmailInput] = useState('');
  const [errorAlert, setErrorAlert] = useState('');

  // const [lectieInvitees, setLectieInvitees] = useState([]);
  // const [pictures, setPictures] = useState([]);

  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const draft = useSelector((state) => state?.draft);

  const { firstName, organisationName } = user;
  const shareText = `invited you to their calendar, \nWould you like to accept the invite?\n`;
  const shareTextEnd = `Best regards,`;
  const shareLink =
    process.env.NODE_ENV == 'development'
      ? `${process.env.REACT_APP_API_URL}/bespoke/calendar/${id}`
      : `${process.env.REACT_APP_LIVE_API_URL}/bespoke/calendar/${id}`;

  const formattedString = `${firstName || organisationName} ${shareText}\n
  ${shareLink}\n
  ${shareTextEnd}\n
  ${firstName || organisationName}.`;

  const handleEmailChange = (e) => {
    setEmailInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      addEmail();
    }
  };

  const addEmail = () => {
    if (emailInput.trim() !== '' && isValidEmail(emailInput)) {
      setEmails([...emails, emailInput]);
      setEmailInput('');
    }
  };

  const removeEmail = (emailToRemove) => {
    const updatedEmails = emails.filter((email) => email !== emailToRemove);
    setEmails(updatedEmails);
  };

  const removeLectieUser = (lectieUserToRemove) => {
    const updatedUsers = selectedValuesArray.filter(
      (lectieUser) => lectieUser !== lectieUserToRemove
    );
    setSelectedValuesArray(updatedUsers);
  };

  // Function to validate email
  const isValidEmail = (email) => {
    // Use a regular expression or any validation logic to check if email is valid
    // For simplicity, let's assume any string with "@" is a valid email
    return email.includes('@');
  };

  // Function to handle search input change
  const handleSearchChange = (event) => {
    const newSearchQuery = event.target.value;
    setSearchQuery(newSearchQuery);

    // Call a function to fetch dropdown data based on the search query
    fetchDropdownData(newSearchQuery);
  };

  // Function to fetch dropdown data based on search query
  const fetchDropdownData = async (query) => {
    const queryState = query == '';
    if (!queryState) {
      try {
        // Replace this with your API endpoint or data fetching logic
        await client
          .get(`/Social/fetchuserconnection?search=${query}`)
          .then((res) => {
            setDropdownData(res?.data);
            setDropdownOpen(true);
          })
          .catch((e) => {
            toast.error(e?.response?.data?.Message ?? e?.message);
          });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  // setCalendarType({
  //   calender: publicCalendar ? "public" : "private",
  //   lectieUsers: [] // Use selectedValuesArray here
  // });
  const updatedCalendarType = publicCalendar ? 'public' : 'private';
  const handleAddToSelectedArray = (item) => {
    const inviteesDetails = {
      id: item.userId,
      firstName: item.firstName,
      userName: item.userName,
      organisationName: item.organisationName,
    };
    setSelectedValuesArray((prevArray) => [...prevArray, inviteesDetails]);

    setCalendarType({
      calender: updatedCalendarType,
      lectieUsers: [...selectedValuesArray, inviteesDetails], // Use selectedValuesArray here
    });

    // Deselect LectieUsers(calendarType.lectieUsers)
    setSelectedValue('');
    setSearchQuery('');
    setDropdownOpen(false);
  };

  const [selectedInvite, setSelectedInvite] = useState();
  const handleSelect = (data) => {
    setSelectedInvite(data);
    setCalendarType({
      calender: publicCalendar ? 'public' : 'private',
      lectieUsers: [...data],
    });
  };

  // Send invites to Lectie Users
  const lectieUsersInvite = async () => {
    setLoading(true);
    try {
      // API endpoint or data fetching logic
      await client
        .post(`/Account/lectie-users-invite`, {
          souvenirId: id,
          lectieUsers: selectedValuesArray,
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success('Invites sent successfully');
            setLoading(false);
          }
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);

          // toast.error(e?.response?.data?.Message ?? e?.message);
        });
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setCalendarType({ calender: updatedCalendarType, lectieUsers: [] });
  }, [publicCalendar]);

  const Spinner = (
    <svg
      aria-hidden="true"
      class="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-[#2667B1]"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>
  );

  return (
    <InviteSettingConatianer>
      <div className="invite-selection">
        <div className="invite-selection-header">
          <h2>Privacy setting</h2>
          <p>Select your calendar privacy setting</p>
        </div>

        <div className="check-container">
          <div className="check-item">
            <div
              className="rounded-check"
              onClick={() => setPublicCalendar((prevState) => !prevState)}
            >
              {publicCalendar && <img src={RoundCheck} alt="check" />}
            </div>
            <p>Public</p>
          </div>

          <div className="check-item">
            <div
              className="rounded-check"
              onClick={() => setPublicCalendar((prevState) => !prevState)}
            >
              {!publicCalendar && <img src={RoundCheck} alt="check" />}
            </div>
            <p>Private</p>
          </div>
        </div>
      </div>

      {!publicCalendar && (
        <div className="invite-users-container">
          <p className="invite-users-notice">
            Only users you invite to this calendar can see/ view this calendar
          </p>

          <div className="invite-switch-container">
            <div onClick={() => setInviteSwitcher('lectie')}>
              <span className="lectie">Lectie</span>
              {inviteSwitcher === 'lectie' && (
                <div className="switcher-divider"></div>
              )}
            </div>
            <div onClick={() => setInviteSwitcher('email')}>
              <span>Email</span>
              {inviteSwitcher === 'email' && (
                <div className="switcher-divider"></div>
              )}
            </div>
          </div>

          {inviteSwitcher === 'lectie' ? (
            <span className="select-invitee">Only users on lectie</span>
          ) : (
            <span className="select-invitee">
              You can invite users via email after you have successfully created
              your calendar
            </span>
          )}

          {inviteSwitcher === 'lectie' && (
            <>
              <div className="form-selection ">
                <TextField
                  id="outlined-basic"
                  label="Search"
                  variant="outlined"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  size="small"
                  placeholder="Search..."
                />
                {inviteSwitcher === 'lectie' && status === 'published' && (
                  <button
                    onClick={lectieUsersInvite}
                    className="flex justify-center items-center"
                  >
                    {loading ? (
                      <div role="status">{Spinner}</div>
                    ) : (
                      <BsSendFill />
                    )}
                  </button>
                )}
              </div>

              <div className="dropdown">
                {dropdownOpen && (
                  <ul
                    id="dropdown-content"
                    className={dropdownOpen ? 'show' : ''}
                  >
                    {dropdownData.map((item) => (
                      <li
                        key={item.id}
                        onClick={() => {
                          handleAddToSelectedArray(item);
                        }}
                        className="list-item"
                      >
                        <div className="list-item-content">
                          <div className="thumbnail-container">
                            <img
                              src={
                                item.picture
                                  ? item.picture
                                  : 'https://picsum.photos/40/40'
                              }
                              alt="Thumbnail"
                              className="thumbnail"
                            />
                          </div>
                          <div className="name-info">
                            <span className="first-name">
                              {item.firstName} {item.lastName}{' '}
                              {item?.organisationName}
                            </span>
                            {/* <span className="last-name">{item.lastName}</span> */}
                            <span className="username">@{item.userName}</span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}

                {/* Render array list to frontend */}
                <ul className="invite-unlist">
                  {selectedValuesArray &&
                    selectedValuesArray.map((obj) => (
                      <li key={obj.id} className="invite-list">
                        <span className="email-text">
                          {obj.firstName}
                          {obj.organisationName}
                        </span>
                        <span
                          onClick={() => removeLectieUser(obj)}
                          className="remove-button"
                        >
                          {' '}
                          x
                        </span>
                      </li>
                    ))}
                </ul>
              </div>
            </>
          )}

          {inviteSwitcher === 'email' && status === undefined ? (
            <div
              role="status"
              className="flex justify-center items-center space-x-2"
            >
              {Spinner}
            </div>
          ) : (
            inviteSwitcher === 'email' &&
            status === 'published' && (
              <>
                <div className="flex justify-center items-center space-x-2">
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Add email and press enter"
                    id="fullWidth"
                    value={emailInput}
                    onChange={handleEmailChange}
                    onKeyDown={handleKeyPress}
                  />
                  {/* Uncomment this if you want to add a send button */}
                  {/* <BsSendFill onClick={addEmail} /> */}
                </div>

                {/* Render email list to frontend */}
                <ul className="invite-unlist">
                  {emails.map((email, index) => (
                    <li key={index} className="invite-list">
                      <span className="email-text">{email}</span>
                      <span
                        onClick={() => removeEmail(email)}
                        className="remove-button"
                      >
                        x
                      </span>
                    </li>
                  ))}
                </ul>
              </>
            )
          )}

          {inviteSwitcher === 'email' && status === 'published' && (
            <div className="">
              <button
                className="text-white bg-[#2667B1] hover:bg-[#2667B1]/55 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-9 py-1 mr-4 mb-2 dark:bg-[#2667B1] dark:hover:bg-[#2667B1] focus:outline-none w-32"
                onClick={() => {
                  if (emails.length > 0) {
                    emailInvite(
                      emails,
                      `You have an invitation from ${
                        firstName || organisationName
                      }`,
                      `${formattedString}`,
                      setErrorAlert
                    );
                  } else {
                    toast.warning(
                      'Please enter invitees email and press enter'
                    );
                  }
                }}
              >
                Send
              </button>
              <p className="text-red text-xs">{errorAlert}</p>
            </div>
          )}

          <div className="flex items-center px-4">
            {pictures &&
              pictures.map((picture, index) => (
                <div
                  key={index}
                  style={{
                    position: 'relative',
                    marginRight: '5px',
                    marginLeft: '-20px',
                    zIndex: pictures.length - index,
                  }}
                >
                  <img
                    src={picture}
                    alt={`User ${index}`}
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                    }}
                  />
                </div>
              ))}
            <div>
              {lectieInvitees && lectieInvitees.length > 0 && (
                <p className="font-bold -mb-2">
                  {lectieInvitees.length} people
                </p>
              )}

              <p style={{ margin: '0' }}>
                {lectieInvitees &&
                  lectieInvitees.map((user, index) => (
                    <span key={user.userName} style={{ fontSize: '0.7em' }}>
                      @{user.userName}
                      {index === lectieInvitees.length - 1 ? '...' : ', '}
                    </span>
                  ))}
              </p>
            </div>
          </div>
        </div>
      )}
    </InviteSettingConatianer>
  );
};

export default InviteSetting;

const InviteSettingConatianer = styled.section`
  border: 1px solid #43abf9;
  padding: 0.7rem 1rem;
  font-family: Nunito;
  display: flex;
  flex-direction: column;
  gap: 1.45rem;

  .invite-selection {
    display: flex;
    flex-direction: column;
    gap: 1.15rem;
    .invite-selection-header {
      display: flex;
      flex-direction: column;

      h2 {
        font-weight: 600;
        font-size: 1.2rem;
      }
    }
    .check-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .check-item {
        display: flex;
        gap: 1rem;
        align-items: center;
        .rounded-check {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 1px solid #d2d2d2;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .invite-users-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .invite-users-notice {
      font-size: 0.8rem;
    }

    .invite-switch-container {
      display: flex;
      gap: 1.3rem;
      align-items: center;
      width: 90px;
      div {
        text-align: center;
        .switcher-divider {
          height: 3px;
          width: 100%;
          background-color: #2667b1;
        }
      }
    }

    .select-invitee {
      font-size: 0.76rem;
    }

    .form-selection {
      grid-template-columns: 2fr 1fr;
      gap: 1rem;
      align-items: center;
      display: flex;
      align-items: center;
      .css-13cymwt-control {
        min-width: 260px;
        width: 300px;
      }
    }
    .invite-unlist {
      list-style-type: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
    }

    .invite-list {
      display: flex;
      align-items: center;
      padding: 5px 8px;
      margin-right: 10px;
      margin-bottom: 10px;
      background-color: rgba(255, 191, 191, 0.5);
      border-radius: 8px;
    }
    .invite-list .email-text {
      margin-right: 10px;
    }
    .invite-list .remove-button {
      margin-left: auto;
    }
    .remove-button {
      cursor: pointer;
      font-weight: bold;
      color: #e74c3c;
    }

    .span-class {
      cursor: pointer;
    }
    .list-item {
      margin: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;
    }

    .list-item:hover {
      background-color: #e0e0e0;
    }

    .list-item-content {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;
    }

    .thumbnail-container {
      margin-right: 10px;
    }

    .thumbnail {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      object-fit: cover;
    }

    .name-info {
      display: flex;
      flex-direction: column;
    }

    .first-name {
      font-weight: bold;
    }

    .last-name {
      color: #777;
    }

    .username {
      font-style: italic;
      color: #777;
    }

    .first-name,
    .last-name,
    .username {
      font-size: 12px;
      color: #333; /* Adjust color as needed */
    }
    #dropdown-content {
      display: none;
      position: absolute;
      background-color: white;
      border: 0.5px solid #afafaf;
      border-radius: 4px;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      padding: 0;
      z-index: 2;
      margin: 0;
    }

    #dropdown-content.show {
      display: block;
    }

    /* Adjust the margin-right value as needed */
    .form-selection button {
      margin-left: 10px;
    }
  }
`;
