import React, { useState } from 'react';
import { ProfileContainer } from './component/styles/Profile.style';
import {
  makeStyles,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  FormHelperText,
  Button,
} from '@material-ui/core';

import 'react-phone-number-input/style.css';
import { Lock, VisibilityOff, Visibility } from '@material-ui/icons';
import { RotatingLines } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import client from '../services';
import { useTranslation } from 'react-i18next';

// Form Styles
const useStyles = makeStyles((theme) => ({
  TextInput: {
    borderRadius: 10,
    width: '100%',
  },
  accord: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordInput: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  largeImg: {
    width: '8rem',
    height: '8rem',
    fontSize: '60px',
    fontWeight: 500,
  },

  smallNotice: {
    marginTop: '-10px',
    fontWeight: 400,
    fontSize: '0.7rem',
    lineHeight: '19px',
    color: '#464646',
  },

  smallNoticeBirthday: {
    marginTop: '-18px',
    marginBottom: '10px',
    fontWeight: 400,
    fontSize: '0.7rem',
    lineHeight: '19px',
    color: '#464646',
  },
}));

const ChangePassword = () => {
  const { t } = useTranslation(['dashboard']);
  const classes = useStyles();

  // Loading State
  const [processing, setProcessing] = useState(false);

  // Error Validation
  let errorsObj = {
    oldPassword: '',
    isOldPassword: false,
    password: '',
    ispassword: false,
    confirmPassword: '',
    isconfirmPassword: false,
    showConfirmPassword: false,
    showPassword: false,
    showOldPassword: false,
  };
  const [errors, setErrors] = useState(errorsObj);

  // User Data
  const [values, setValues] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: '',
  });

  // Value change
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });

    if (event.target.value !== '') {
      setErrors({ ...errors, [prop]: '', [`is${prop}`]: false });
    }
  };

  // Password visibility
  const handleClickShowPassword = (prop) => (event) => {
    setErrors({ ...errors, [prop]: !errors[prop] });
  };

  // Handle mouse down
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Submit function
  const onSubmit = async () => {
    setProcessing(true);
    let error = false;
    const errorObj = { ...errorsObj };

    if (values.password === '') {
      errorObj.password = 'Password is Required';
      errorObj.ispassword = true;
      error = true;
    }
    if (values.oldPassword === '') {
      errorObj.oldPassword = 'Old Password is Required';
      errorObj.isOldPassword = true;
      error = true;
    }

    if (values?.password?.length < 8) {
      errorObj.password = t('Password can not be less than 8 characters');
      errorObj.ispassword = true;
      error = true;
    }

    if (values.password !== values.confirmPassword) {
      errorObj.confirmPassword = t(
        'Password and confirm password must be the same'
      );
      errorObj.isconfirmPassword = true;
      error = true;
    }

    if (error) {
      setTimeout(() => {
        setProcessing(false);
        setErrors(errorObj);
      }, 3000);
    } else {
      var { oldPassword, password } = values;
      await client
        .post('/Account/user-password-change', {
          currentPassword: oldPassword,
          newPassWord: password,
        })
        .then((res) => {
          setProcessing(false);

          setValues({
            oldPassword: '',
            password: '',
            confirmPassword: '',
          });
          toast.success(t('Password has successfully changed'));
        })
        .catch((e) => {
          console.log(e);
          setProcessing(false);
          toast.error(e?.response?.data?.Message ?? e?.message);
        });
    }
  };

  return (
    <ProfileContainer>
      <div className="main-wrap">
        <h4>{t('Change Password')}</h4>
        <form>
          <div className="main-form">
            <div className={classes.accordInput}>
              <FormControl variant="outlined">
                <InputLabel
                  error={errors.isOldPassword}
                  htmlFor="outlined-adornment-password"
                >
                  {t('Old Password')}
                </InputLabel>
                <OutlinedInput
                  error={errors.isOldPassword}
                  id="outlined-adornment-password-old"
                  type={errors.showOldPassword ? 'text' : 'password'}
                  value={values.oldPassword}
                  onChange={handleChange('oldPassword')}
                  placeholder={t('Old Password')}
                  size="small"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword('showOldPassword')}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {errors.showOldPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <Lock />
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={160}
                />
                <FormHelperText error={errors.isOldPassword}>
                  {errors.oldPassword}
                </FormHelperText>
              </FormControl>

              <FormControl variant="outlined">
                <InputLabel
                  error={errors.ispassword}
                  htmlFor="outlined-adornment-password"
                >
                  {t('New Password')}
                </InputLabel>
                <OutlinedInput
                  error={errors.ispassword}
                  id="outlined-adornment-password"
                  type={errors.showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange('password')}
                  placeholder={t('Minimum of 8 characters')}
                  size="small"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword('showPassword')}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {errors.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <Lock />
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={160}
                />
                <FormHelperText error={errors.ispassword}>
                  {errors.password}
                </FormHelperText>
              </FormControl>

              <FormControl variant="outlined">
                <InputLabel
                  error={errors.isconfirmPassword}
                  htmlFor="outlined-adornment-password"
                >
                  {t('Re-Enter New Password')}
                </InputLabel>
                <OutlinedInput
                  error={errors.isconfirmPassword}
                  id="outlined-adornment-confirm-password-0ne"
                  type={errors.showConfirmPassword ? 'text' : 'password'}
                  value={values.confirmPassword}
                  onChange={handleChange('confirmPassword')}
                  placeholder={t('Minimum of 8 characters')}
                  size="small"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword('showConfirmPassword')}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {errors.showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <Lock />
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={220}
                />
                <FormHelperText error={errors.isconfirmPassword}>
                  {errors.confirmPassword}
                </FormHelperText>
              </FormControl>
            </div>
          </div>
          <div className="button-container">
            <Button
              startIcon={
                <RotatingLines
                  strokeColor="#fff"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={processing}
                />
              }
              variant="contained"
              color="primary"
              style={{ textTransform: 'none' }}
              onClick={() => onSubmit()}
            >
              {processing ? t('Processing') : t('Update')}
            </Button>
          </div>
        </form>
      </div>
    </ProfileContainer>
  );
};

export default ChangePassword;
