import React from 'react';
import { ToggleUserContainer } from './style/Toggle.style';
import { useTranslation } from 'react-i18next';

const ToggleUser = ({ setIndividual, setOrganization, head, toggle }) => {
  const { t } = useTranslation(['common']);

  return (
    <ToggleUserContainer toggle={toggle}>
      <h2>{t(head)}</h2>
      <div className="toggle-user">
        <p typeof="button" onClick={() => setIndividual()}>
          {t('Individual')}
        </p>
        <p typeof="button" onClick={() => setOrganization()}>
          {t('Organization')}
        </p>
      </div>
    </ToggleUserContainer>
  );
};

export default ToggleUser;
