import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowMonthModel } from '../../../../store/features/modalSlice';
import {
  setEndDate,
  setSelectedDate,
} from '../../../../store/features/timeSlice';
import { getMonth } from '../../../../util';
import { MonthModalContainer } from './styles/calendar.Styles';

const MonthMOdal = ({ dateClicked }) => {
  const dispatch = useDispatch();
  const { yearPicked } = useSelector((state) => state.calendar);
  const { monthNumber } = useSelector((state) => state.month);
  const { selectedDate, selectedEndDate } = useSelector(
    (state) => state.startEventModal
  );

  const [currentMonth, setCurrentMonth] = useState(
    getMonth(monthNumber, yearPicked)
  );

  return (
    <MonthModalContainer date={dateClicked || ''}>
      {' '}
      <div className="main-wrapper">
        {currentMonth[0].map((day, i) => (
          <p key={i} className="day-init t-center t-sm">
            {day.format('dd').charAt(0)}
          </p>
        ))}
        {currentMonth.map((row, i) => (
          <React.Fragment key={i}>
            {row.map((day, idx) => (
              <button
                key={idx}
                type="button"
                onClick={() => {
                  if (day.format('YYYY') === yearPicked.toString()) {
                    if (dateClicked === 'start-date') {
                      dispatch(setSelectedDate(day));
                      dispatch(setEndDate(day));
                      dispatch(setShowMonthModel(false));
                    } else {
                      if (selectedDate <= day) {
                        dispatch(setEndDate(day));
                        dispatch(setShowMonthModel(false));
                      }
                    }
                  }
                }}
              >
                <p
                  className={`t-sm ${
                    day.format('YYYY') === yearPicked.toString()
                      ? ''
                      : 'disabled'
                  } ${
                    dateClicked === 'end-date'
                      ? selectedDate > day
                        ? 'disabled'
                        : ''
                      : ''
                  }  `}
                >
                  {day.format('D')}
                </p>
              </button>
            ))}
          </React.Fragment>
        ))}
      </div>
    </MonthModalContainer>
  );
};

export default MonthMOdal;
