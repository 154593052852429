import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showMenu: false,
};

const studioSlice = createSlice({
  name: 'studio',
  initialState,
  reducers: {
    setShowStudioMenu: (state, { payload }) => {
      state.showMenu = payload;
    },
  },
});

export const { setShowStudioMenu } = studioSlice.actions;
export default studioSlice.reducer;
