import React from 'react';
import { FooterContainer } from './Footer.style';
import Logo from '../../../assest/images/footer-logo-dark.svg';
import { Link, useNavigate } from 'react-router-dom';
import { account, socialMedia } from './Services';
import { useTranslation } from 'react-i18next';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import dayjs from 'dayjs';

function Footer() {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();

  return (
    <FooterContainer>
      <section className="top-section">
        <div className="logo-container">
          <AnchorLink href="#homeTop">
            <img src={Logo} alt="Logo Lectie" />
          </AnchorLink>
        </div>

        <div className="socail-icons flex">
          {socialMedia.map(({ link, image, alt }) => (
            <a href={link} target="_blank" rel="noreferrer">
              <img src={image} alt={alt} />
            </a>
          ))}
        </div>
      </section>

      <hr />

      <section className="mid-section">
        <ul className="company">
          <li className="heading">{t('Company')}</li>
          <li>
            Lectie Pty Ltd <br />
            Level 1/390 <br />
            St Kilda Rd <br />
            Melbourne VIC3004 <br />
            Australia
          </li>

          <li className="company_support">support@lectie.com</li>
        </ul>

        <ul className="list-space">
          <li className="heading">{t('Service')}</li>

          <li className="service_list">
            {' '}
            <a href="/dashboard/create" target="_blank" rel="noreferrer">
              {t(`Create Events`)}
            </a>
          </li>
        </ul>

        <ul className="list-space">
          <li className="heading">{t('Resources')}</li>
          <Link to="/contact" className="linker">
            {' '}
            <li>{t('Contact Us')}</li>
          </Link>

          <li onClick={() => navigate('/FAQ')} className="linker">
            {t('FAQs')}
          </li>
        </ul>

        <ul className="list-space">
          <li className="heading">{t('Account')}</li>
          {account.map(({ name, image, link, alt, id }) => (
            <li key={id} className="social_list account">
              <a href={link} target="_blank" rel="noreferrer">
                <p>{t(name)}</p>
              </a>
            </li>
          ))}
        </ul>
      </section>

      <section className="sub-footer">
        <div className="bottom-section">
          <p>
            ©Copyright 2022 -{' '}
            {dayjs(new Date(Date.now()).toString()).format('YYYY')}.{' '}
            {t('All Rights Reserved.')}
          </p>

          <div className="right-sub-footer">
            <p onClick={() => navigate('/privacy-policy')} className="linker">
              {t('Privacy Policy')}
            </p>

            <p onClick={() => navigate('/terms')}>
              {t('Terms and Conditions')}
            </p>
          </div>
        </div>
      </section>
    </FooterContainer>
  );
}

export default Footer;
