import React from 'react';
import Banner from '../components/Header/Banner';
import Footer from '../components/Footer';
import ExtendHelmet from '../components/services/ExtendHelmet';

import LectieVaultDemo from './LectieVaultDemo';
import BespokeCalendar from './BespokeCalendar';
import LectiePitch from './LectiePitch';
import GridPitch from './GridPitch';
import Nav from './Nav';

function Main() {
  return (
    <>
      <ExtendHelmet Title="Lectie - Connect on Social Calendar" />
      <Nav curls={false} />
      <Banner />
      <BespokeCalendar />
      <GridPitch />
      <LectieVaultDemo />
      <LectiePitch />
      <Footer />
    </>
  );
}

export default Main;
