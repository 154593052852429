export const firstParagraph =
  'Timebly Pty Ltd t/as Lectie ABN 56 651 956 964 (we, us or our), understands that protecting your personal information is important. This Privacy Policy sets out our commitment to protecting the privacy of personal information provided to us, or otherwise collected by us when providing our mobile application and other services to you (Services) or when otherwise interacting with you.';

export const infoCollectedP1 =
  'is information or an opinion, whether true or not and whether recorded in a material form or not, about an individual who is identified or reasonably identifiable.';

export const infoCollectedP2 =
  'So that you can use our Services, we need to collect some personal information from you. The types of personal information we may collect about you include:';

export const infoCollectionList = [
  {
    id: 1,
    text: 'Your name',
  },
  {
    id: 2,
    text: 'Images of YourData',
  },

  {
    id: 3,
    text: 'your contact details, including email address and telephone number',
  },

  {
    id: 4,
    text: 'Your birthday',
  },
  {
    id: 5,
    text: 'Your calendar events',
  },
  {
    id: 6,
    text: 'information you make available by syncing your third party services with our mobile application',
  },
  {
    id: 7,
    text: 'your credit card or other payment details (through our third party payment processor)',
  },
  {
    id: 8,
    text: 'your preferences and/or opinions',
  },
  {
    id: 9,
    text: 'information you provide to us, including through feedback, customer surveys or otherwise',
  },
  {
    id: 10,
    text: 'your sensitive information if you provide it to us as set out below',
  },
  {
    id: 11,
    text: 'support requests submitted to us and our response to you',
  },
  {
    id: 12,
    text: 'your browser session and geo-location data, device and network information, statistics on page views and sessions, acquisition sources, search queries and/or browsing behaviour',
  },
  {
    id: 13,
    text: 'information about your access and use of our Services, including through the use of Internet cookies, your communications with our online Services, the type of browser you are using, the type of operating system you are using and the domain name of your Internet service provider;',
  },
  {
    id: 14,
    text: 'additional personal information that you provide to us, directly or indirectly, through your use of our Services, associated applications, associated social media platforms and/or accounts from which you permit us to collect information; and',
  },
  {
    id: 15,
    text: 'any other personal information requested by us and/or provided by you or a third party.',
  },
];

export const sensitiveInfo =
  'is a sub-set of personal information that is given a higher level of protection. Sensitive information means information relating to your racial or ethnic origin, political opinions, religion, trade union or other professional associations or memberships, philosophical beliefs, sexual orientation or practices, criminal records, health information or biometric information.';

export const sensitiveInfoP2 =
  'We do not actively request sensitive information about you. However, we may collect sensitive information from you by virtue of performing our Services (we do not ask you to provide this information or use this information for any purpose other than solely as required to perform our Services or as required or authorised by law).';

export const personalInfo = [
  {
    id: 1,
    bold: 'Directly:',
    text: `We collect personal information which you directly provide to us, including when you login to our mobile application, through the ‘contact us’ form on our website or when you request our assistance via email.`,
  },
  {
    id: 2,
    bold: 'Indirectly:',
    text: 'We may collect personal information which you indirectly provide to us while interacting with us, such as through third party services (such as Google) which you link to your login on our mobile application.',
  },
  {
    id: 3,
    bold: 'From third parties:',
    text: 'We collect personal information from third parties, such as where you have been invited by a user of our Services to attend an event or be participant to our mobile application. We require that our users ensure they have your permission before they provide us with any personal information about you.',
  },
];

export const socialInfo1 =
  'If you connect your account with us to a social network account, we will collect your personal information from the social network. We will do this in accordance with the privacy settings you have chosen on that social network.';

export const socialInfo2 =
  'The personal information that we may receive includes your name, ID, user name, handle, profile picture, gender, age, language, list of friends or follows and any other personal information you choose to share.';

export const socialInfo3 =
  'We use the personal information we receive from the social network to create a profile for you on our platform and for you to use our Services.';

export const socialInfo4 =
  'Where we have accessed your personal information through your Facebook account, you have the right to request the deletion of personal information that we have been provided by Facebook. To submit a request for the deletion of personal information we acquired from Facebook, please send us an email at the address at the end of this Privacy Policy and specify in your request which personal information you would like deleted. If we deny your request for the deletion of personal information, we will explain why.';

export const googleServicesLink = [
  {
    id: 1,
    text_before_link:
      "Our use of information received from Google APIs will adhere to the'",
    link: 'https://developers.google.com/terms/api-services-user-data-policy',
    content: 'Google API Services User Data Policy,',
  },
];

export const googleP1 =
  'We limit our use of the personal information that we collect to providing or improving our Services. We do not use the personal information for any other purposes.';
export const googleP2 =
  'We only transfer the personal information to others if it is necessary to provide or improve our Services or as necessary to comply with applicable law or as part of a merger, acquisition or sale of assets where we notify you of this.';
export const googleP3 =
  'We do not use or transfer the personal information for serving ads, including retargeting, personalised or interest based advertising.';
export const googleP4 =
  'These restrictions apply to the raw data we obtain from the restricted scopes and data that is aggregated, anonymised or derived from them.';
export const googleP5 =
  'We have policies and procedures in place setting out appropriate safeguards for protecting your personal information obtained with the Google APIs. We will also ensure that our employees, agents, contractors and successors comply with the Google API Services Data Policy.';

export const geoData = [
  {
    id: 1,
    text: 'to determine your time zone; and',
  },
  {
    id: 2,
    text: 'as permitted by law.',
  },
];

export const discloseInfo = [
  {
    id: 1,
    text: 'to enable you to access and use our Services, including to provide you with a login;',
  },
  {
    id: 2,
    text: 'to provide our Services to you, including to enable you to manage your appointments, organise events and to manage invitations;',
  },
  {
    id: 3,
    text: 'to enable you to access and use our associated applications and associated social media platforms;',
  },
  {
    id: 4,
    text: 'to contact and communicate with you about our Services, including in response to any support requests you lodge with us or other enquiries you make with us;',
  },

  {
    id: 5,
    text: 'for internal record keeping, administrative, invoicing and billing purposes;',
  },

  {
    id: 6,
    text: 'for analytics, market research and business development, including to operate and improve our Services, associated applications and associated social media platforms;',
  },

  {
    id: 7,
    text: 'for advertising and marketing, including to send you promotional information about our products and services and other information that we consider may be of interest to you;',
  },
  {
    id: 8,
    text: 'to comply with our legal obligations and resolve any disputes that we may have;',
  },
  {
    id: 9,
    text: 'if you have applied for employment with us; to consider your employment application; and',
  },
  {
    id: 10,
    text: 'if otherwise required or authorised by law.',
  },
];

export const giveDisclose = [
  {
    id: 1,
    text: 'third party service providers for the purpose of enabling them to provide their services, to us, including (without limitation) IT service providers, data storage, web-hosting and server providers, email marketing providers, maintenance or problem-solving providers, marketing or advertising providers, professional advisors and payment systems operators;',
  },

  {
    id: 2,
    text: 'our employees, contractors and/or related entities;',
  },
  {
    id: 3,
    text: 'our existing or potential agents or business partners;',
  },
  {
    id: 4,
    text: 'anyone to whom our business or assets (or any part of them) are, or may (in good faith) be, transferred;',
  },
  {
    id: 5,
    text: 'courts, tribunals and regulatory authorities, in the event you fail to pay for goods or services we have provided to you;',
  },
  {
    id: 6,
    text: 'courts, tribunals, regulatory authorities and law enforcement officers, as required or authorised by law, in connection with any actual or prospective legal proceedings, or in order to establish, exercise or defend our legal rights;',
  },
  {
    id: 7,
    text: 'third parties to collect and process data, such as Google Analytics (To find out how Google uses data when you use third party websites or applications, please see www.google.com/policies/privacy/partners/ or any other URL Google may use from time to time), Facebook Pixel or other relevant analytics businesses; and',
  },
  {
    id: 8,
    text: 'any other third parties as required or permitted by law, such as where we receive a subpoena.',
  },
];

export const googleAnalysis = [
  {
    id: 1,
    bold: 'Google Analytics:',
    text: 'We have enabled Google Analytics Advertising Features. We and third-party vendors use first-party cookies (such as the Google Analytics cookie) or other first-party identifiers, and third-party cookies (such as Google advertising cookies) or other third-party identifiers together.',
  },
];

export const optIn = [
  {
    id: 1,
    text_before_link:
      'You can opt-out of Google Analytics Advertising Features including using a Google Analytics Opt-out Browser add-on found',
    link: 'https://tools.google.com/dlpage/gaoptout/',
    content: 'here.',
  },
  {
    id: 2,
    text_before_link:
      'To opt-out of personalised ad delivery on the Google content network, please visit Google’s Ads Preferences Manager',
    link: 'https://adssettings.google.com/authenticated?hl=en',
    content: 'here.',
  },
  {
    id: 3,
    text_before_link:
      'or if you wish to opt-out permanently even when all cookies are deleted from your browser you can install their plugin',
    link: 'https://support.google.com/ads/answer/7395996',
    content: 'here.',
  },
];

export const optInPara =
  'To opt out of interest-based ads on mobile devices, please follow these instructions for your mobile device: On android open the Google Settings app on your device and select “ads” to control the settings. On iOS devices with iOS 6 and above use Apple’s advertising identifier. To learn more about limiting ad tracking using this identifier, visit the settings menu on your device.';

export const overseasDisclosureP1 =
  'We may store personal information overseas. Where we disclose your personal information to the third parties listed above, these third parties may also store, transfer or access personal information outside of Australia.';

export const overseasDisclosureP2 =
  'Unless we seek and receive your consent to an overseas disclosure of your personal information, we will only disclose your personal information to countries with laws which protect your personal information in a way which is substantially similar to the Australian Privacy Principles and/or we will take such steps as are reasonable in the circumstances to require that overseas recipients protect your personal information in accordance with the Australian Privacy Principles.';

export const rights1 = [
  {
    id: 1,
    bold: 'Your choice:',
    text: 'Please read this Privacy Policy carefully. If you provide personal information to us, you understand we will collect, hold, use and disclose your personal information in accordance with this Privacy Policy. You do not have to provide personal information to us, however, if you do not, it may affect our ability to provide our Services to you and your use of our Services.',
  },

  {
    id: 2,
    bold: 'Information from third parties:',
    text: 'If we receive personal information about you from a third party, we will protect it as set out in this Privacy Policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person’s consent to provide the personal information to us.',
  },

  {
    id: 3,
    bold: 'Restrict and unsubscribe:',
    text: 'To object to processing for direct marketing/unsubscribe from our email database or opt-out of communications (including marketing communications), please contact us using the details below or opt-out using the opt-out facilities provided in the communication.',
  },
  {
    id: 4,
    bold: 'Access:',
    text: 'You may request access to the personal information that we hold about you. An administrative fee may be payable for the provision of such information. Please note, in some situations, we may be legally permitted to withhold access to your personal information. If we cannot provide access to your information, we will advise you as soon as reasonably possible and provide you with the reasons for our refusal and any mechanism available to complain about the refusal. If we can provide access to your information in another form that still meets your needs, then we will take reasonable steps to give you such access.',
  },

  {
    id: 5,
    bold: 'Correction:',
    text: 'If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant or misleading, please contact us using the details below. We will take reasonable steps to promptly correct any information found to be inaccurate, out of date, incomplete, irrelevant or misleading. Please note, in some situations, we may be legally permitted to not correct your personal information. If we cannot correct your information, we will advise you as soon as reasonably possible and provide you with the reasons for our refusal and any mechanism available to complain about the refusal.',
  },
  {
    id: 6,
    bold: 'Complaints:',
    text: 'If you wish to make a complaint, please contact us using the details below and provide us with full details of the complaint. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take in response to your complaint. If you are not satisfied with our response, you also have the right to contact the Office of the Australian Information Commissioner.',
  },
];

export const Storage1 =
  'We are committed to ensuring that the personal information we collect is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures, to safeguard and secure personal information and protect it from misuse, interference, loss and unauthorised access, modification and disclosure.';
export const Storage2 =
  'While we are committed to security, we cannot guarantee the security of any information that is transmitted to or by us over the Internet. The transmission and exchange of information is carried out at your own risk.';

export const Cookies1 =
  "We may use cookies on our website from time to time. Cookies are text files placed in your computer's browser to store your preferences. Cookies, by themselves, do not tell us your email address or other personally identifiable information. However, they do recognise you when you return to our online website and allow third parties, such as Google and Facebook, to cause our advertisements to appear on your social media and online media feeds as part of our retargeting campaigns. If and when you choose to provide our online website with personal information, this information may be linked to the data stored in the cookie.";

export const Cookies2 =
  'You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our website.';

export const webLinks =
  'Our website may contain links to other party’s websites. We do not have any control over those websites and we are not responsible for the protection and privacy of any personal information which you provide whilst visiting those websites.Those websites are not governed by this Privacy Policy.';

export const Amendment =
  'We may, at any time and at our discretion, vary this Privacy Policy by publishing the amended Privacy Policy on our website. We recommend you check our website regularly to ensure you are aware of our current Privacy Policy.';
