import styled from 'styled-components';
import { Wrapper } from '../Global_Styles';
// Footer
export const FooterContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 1.5rem;
  color: #111111 !important;
  background: #ffffff;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  .linker {
    cursor: pointer;
    margin-top: 10px;
    color: #111111;
  }

  hr {
    width: 100%;
    border: 1px solid #ffffff;
  }

  .top-section,
  .mid-section,
  .bottom-section {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
  }

  .top-section {
    margin-top: 5rem;
    .logo-container {
      width: 125px;

      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
    .socail-icons {
      gap: 1rem;
      align-items: center;
      justify-content: center;
    }

    .socials {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: #111111;
      li {
        list-style-type: none;
      }
    }
  }

  .mid-section {
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    align-items: flex-start;
    flex-wrap: wrap;
    flex: 1;
    padding-bottom: 3rem;
    padding-right: 4rem;
    color: #111111;
    .company {
      .company_support {
        margin-top: 2rem;
        font-size: 0.8rem;
        color: #111111;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      color: #111111;
      .heading {
        position: relative;
        margin-bottom: 2rem;
        margin-top: 2rem;
        color: #111111;

        &::before {
          content: '';
          position: absolute;
          bottom: -9px;
          width: 100px;
          height: 4px;
          background-color: #111111;
        }
      }

      a {
        text-decoration: none;
      }

      li {
        list-style-type: none;
        color: #111111;
      }
    }

    .list-space {
      li {
        margin-bottom: 1rem;
      }
      .heading {
        margin-bottom: 2rem;
      }

      .service_list {
        a {
          text-decoration: none;
          color: #111111;
        }
      }
      .social_list {
        list-style: none;
        a {
          display: flex;
          align-items: center;
          gap: 1rem;

          p {
            color: #111111;
          }
        }
      }
    }
  }

  .sub-footer {
    width: 100%;
    height: 100%;
    background: #172a69;
    padding: 1.8rem 0;

    .bottom-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;

      p {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 24px;
      }

      .right-sub-footer {
        display: flex;
        align-items: center;

        gap: 3rem;

        p {
          padding: 0;
          margin: 0;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }

  @media screen and (max-width: 375px) {
    .top-section,
    .mid-section,
    .bottom-section {
      flex-direction: column;
      justify-content: center;
    }

    .mid-section > ul {
      width: 200px;
    }
    .list-space {
      width: 200px;
    }
  }
`;

export const FooterWrapper = styled(Wrapper)``;
