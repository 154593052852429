import React, { useState } from 'react';
import { HelpContainter } from './style/Help.style';
import HelpImg from '../../assest/images/help-img.svg';
import { handleError } from './service/service';
import { toast, ToastContainer } from 'react-toastify';
import client from '../../services';
import { RotatingLines } from 'react-loader-spinner';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const Help = () => {
  const { t } = useTranslation(['dashboard']);
  const [email, setEmail] = useState({ value: '', err: false });
  const [subject, setSubject] = useState({ value: '', err: false });
  const [message, setMessage] = useState({ value: '', err: false });
  const [loading, setLoading] = useState(false);
  //  Handle display error
  const showErr = (errVal) => {
    for (const el of errVal) {
      switch (el) {
        case 'email':
          setEmail({ ...email, err: true });
          break;

        case 'subject':
          setSubject({ ...subject, err: true });
          break;

        case 'message':
          setMessage({ ...message, err: true });
          break;

        default:
          return 'err';
      }
    }
  };

  const sendFormData = async (formData) => {
    setLoading(true);
    await client
      .post('/Account/usercontact', formData)
      .then((res) => {
        setLoading(false);
        toast.success(t('Message sent Successfully'));
        setEmail({ value: '' });
        setMessage({ value: '' });
        setSubject({ value: '' });
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      email: email.value,
      subject: subject.value,
      message: message.value,
    };

    let validationResult = handleError(formData);
    validationResult.length === 2
      ? showErr(validationResult[1])
      : sendFormData(formData);
  };

  return (
    <HelpContainter>
      <div className="main-container">
        <div className="heading">
          <h2>{t('Contact us')}</h2>
          <p> {t('How can we help today?')}</p>
        </div>
        <div className="help-info">
          <div className="img-container">
            <img src={HelpImg} alt="Help icon" />
          </div>

          <ul>
            <li>{t('Can’t login into your account?')}</li>
            <li>{t('Can’t locate a file')}</li>
            <li>{t('Editing and managing your template')}</li>
            <li>{t('How to save and continue later')}</li>
            <li>{t('Galleries, images, and video')}</li>
          </ul>
        </div>

        <div className="form-heading">
          <h4>{t('Can’t find a solution to your question?')}</h4>
          <p>
            {t(
              'Fill out the form below and our contact personnel would get in touch within 24 Hours'
            )}
          </p>
        </div>

        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="input-container">
            <p>{t('Email')}</p>
            <input
              type="text"
              placeholder="bespoke@lectie.com"
              name="email"
              value={email.value}
              onChange={(e) => setEmail({ err: false, value: e.target.value })}
              className={email.err ? 'border-err' : ''}
            />

            {email.err && (
              <span className="error-msg">{t('Please enter an email')}</span>
            )}
          </div>

          <div className="input-container">
            <p>{t('Subject')}</p>
            <input
              type="text"
              placeholder={t('How to subscribe')}
              name="subject"
              value={subject.value}
              className={subject.err ? 'border-err' : ''}
              onChange={(e) =>
                setSubject({ err: false, value: e.target.value })
              }
            />
            {subject.err && (
              <span className="error-msg">{t('Please enter a subject')}</span>
            )}
          </div>

          <div className="input-container">
            <p>{t('Message')}</p>
            <textarea
              value={message.value}
              name="message"
              onChange={(e) =>
                setMessage({ err: false, value: e.target.value })
              }
              className={message.err ? 'border-err' : ''}
            />
            {message.err && (
              <span className="error-msg">{t('Please enter a message')}</span>
            )}
          </div>
          <div className="button-container">
            <Button
              startIcon={
                <RotatingLines
                  strokeColor="#fff"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={loading}
                />
              }
              variant="contained"
              color="primary"
              style={{ textTransform: 'none' }}
              type="submit"
            >
              {loading ? t('Sending') : t('Send Message')}
            </Button>
          </div>
        </form>
      </div>
    </HelpContainter>
  );
};

export default Help;
