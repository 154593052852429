import React from 'react';

import { ToolbarSection, ToolbarItem } from '../../editor';

export const IconsSettings = () => {
  return (
    <React.Fragment>
      <ToolbarSection title="Icon">
      <ToolbarItem
          full={true}
          propKey="fontSize"
          type="slider"
          label="Font Size"
        />
      </ToolbarSection>

      <ToolbarSection
        title="Appearance"
        props={['color']}
        summary={({ color }) => {
          return (
            <div className="fletext-right">
              <p
                style={{
                  color: color && `rgba(${Object.values(color)})`,
                }}
                className="text-white text-right"
              >
                T
              </p>
            </div>
          );
        }}
      >
        <ToolbarItem full={true} propKey="color" type="color" label="Text" />
      </ToolbarSection>
    </React.Fragment>
  );
};
