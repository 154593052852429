import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  eventId: "",
  title: "",
  image: null,
  startDate: "2023-03-24T00:00:00",
  endDate: "2023-01-12T00:00:00",
  startTime: "2023-01-12T21:11:21",
  endTime: "2023-01-12T22:11:21",
  description: "",
  link: "",
  color: "#1AFE31" 
}

export const event = createSlice({
  name: 'event',
  initialState,
  reducers: {
    loadEvent: (state, action) => {
      return {
        ...state,
        ...initialState,
        ...action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {  loadEvent } = event.actions;

export default event.reducer;
