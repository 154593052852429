import { useTranslation } from 'react-i18next';

export const getLists = (lists) => {
  const { t } = useTranslation(['privacy-policy']);

  const result = lists.map((list) => <li key={list.id}>{t(list.text)} </li>);

  return result;
};

export const getBoldLists = (lists) => {
  const { t } = useTranslation(['privacy-policy']);

  const result = lists.map((list) => (
    <li key={list.id} className="contain-bold-text">
      {' '}
      <span className="bold-text">{t(list.bold)}</span> {t(list.text)}{' '}
    </li>
  ));

  return result;
};

export const getLinkText = (lists) => {
  const { t } = useTranslation(['privacy-policy']);

  const result = lists.map((list) => (
    <span style={{ display: 'inline' }} key={list.id}>
      {' '}
      {t(list.text_before_link)} &nbsp;
      <a
        href={list.link}
        target="_blank"
        rel="noreferrer"
        aria-label={list.text_before_link}
      >
        {' '}
        {t(list.content)}
      </a>
    </span>
  ));

  return result;
};

export const getListLinks = (lists) => {
  const { t } = useTranslation(['privacy-policy']);

  const result = lists.map((list) => (
    <li key={list.id}>
      {' '}
      {t(list.text_before_link)} &nbsp;
      <a
        href={list.link}
        target="_blank"
        rel="noreferrer"
        aria-label={list.text_before_link}
      >
        {' '}
        {t(list.content)}
      </a>
    </li>
  ));

  return result;
};
