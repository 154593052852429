import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BtnForgetPassword from './BtnForgetPassword';
import VerifyInputField from './VerifyInputField';
import client from '../../services';
import { useNavigate } from 'react-router-dom';
import { RotatingLines } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import ChangePassword from '../changePassword';
import ChangePasswordVerification from './ChangePasswordVerification';

const VerificationCode = ({ userMail, setIsMailed, userToken }) => {
  const initialInputValues = {
    0: '',
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
  };

  const [activeBtn, setActiveBtn] = useState(false);
  const [confirmChangePassword, setConfirmChangePassword] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [inputValues, setInputValues] = useState(
    Object.keys(userToken).length > 0
      ? { ...userToken }
      : { ...initialInputValues }
  );

  const handleValidation = (e, val) => {
    setIsError(false);
    let finalValue = '';
    let userInput = e.target.value;
    let prevUserInput = inputValues[val];
    let defaultInputLength = 6;
    let userInputLength = userInput.split('');

    if (userInputLength.length === defaultInputLength) {
      setInputValues({ ...initialInputValues }); //initialize state

      // Spread input pasted into each box
      for (let i = 0; i < defaultInputLength; i++) {
        setInputValues((prevState) => ({
          ...prevState,
          [i]: userInput[i],
        }));
      }
    } else if (userInputLength.length <= 5) {
      // Only one item allow in a box input
      // Get final base on condition
      if (prevUserInput === '' && userInput === '') {
        finalValue = prevUserInput;
      } else if (prevUserInput !== '' && userInput === '') {
        finalValue = userInput;
      } else if (prevUserInput !== '' && userInput !== '') {
        finalValue = prevUserInput;
      } else {
        finalValue = userInput;
      }

      setInputValues((prevState) => ({
        ...prevState,
        [val]: finalValue,
      }));
    }
  };

  // Resend Email
  const handleEmailSubmit = () => {
    setLoadingResend(true);
    const data = { emailaddress: userMail };

    client
      .post('/Account/forgotpassword', data)
      .then((res) => {
        console.log(res.data, ' All right good');
        toast.success('Code sent successfully!!!');
        setLoadingResend(false);
      })
      .catch((err) => {
        setLoadingResend(false);
      });
  };

  // Verify token is correct
  const handleVerifySubmit = () => {
    const data = Object.values(inputValues);
    const error = data.includes('');
    setLoading(true);

    if (!error) {
      const postData = { token: data.join('') };

      client
        .post('/Account/validateusertoken', postData)
        .then((res) => {
          console.log(res.data, ' Helloe res');

          setLoading(false);
          setConfirmChangePassword(true);
        })
        .catch((err) => {
          console.log(err);
          setIsError(true);
          setLoading(false);
        });
    } else {
      setIsError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    // Check if object is empty
    if (Object.keys(userToken).length > 0) {
      handleVerifySubmit();
    }
  }, []);

  // To know when to active button or not
  useEffect(() => {
    let inputValuesLen = Object.values(inputValues).join('').split('').length;

    if (inputValuesLen === 6) {
      setActiveBtn(true);
    } else {
      setActiveBtn(false);
    }
  }, [inputValues]);

  return (
    <VerificationCodeContainer>
      {!confirmChangePassword ? (
        <>
          <h1>Verify your Identity</h1>

          <p>Enter the code we just sent to {userMail} </p>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (!loading && !loadingResend) {
                handleVerifySubmit();
              }
            }}
          >
            <div className="input-container">
              <label>Verify your code</label>
              <div className="input-values">
                {[0, 1, 2, 3, 4, 5].map((val) => (
                  <VerifyInputField
                    key={val}
                    val={val}
                    inputValues={inputValues}
                    func={handleValidation}
                    isError={isError}
                  />
                ))}
              </div>

              {isError && <p className="error">You entered the wrong OTP</p>}
            </div>

            <BtnForgetPassword
              isActive={activeBtn}
              loading={loading}
              text="Continue"
            />
          </form>

          <div className="button">
            <button className="no-boder-btn" onClick={() => setIsMailed(false)}>
              Go back
            </button>

            <button
              className="border-btn"
              onClick={() => {
                if (!loading) {
                  handleEmailSubmit();
                }
              }}
            >
              Resend Code
              {
                <RotatingLines
                  strokeColor="#2667B1"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={loadingResend}
                />
              }
            </button>
          </div>
        </>
      ) : (
        <ChangePasswordVerification token={inputValues} />
      )}
    </VerificationCodeContainer>
  );
};

export default VerificationCode;

const VerificationCodeContainer = styled.section`
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  height: 100%;
  margin: 0 auto;
  padding: 2rem 1.5rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;

  h1 {
    color: rgba(38, 103, 177, 1);
    font-family: Nunito;
    font-size: 2rem;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center;
  }

  p {
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    .input-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;

      .input-values {
        display: flex;
        align-items: center;
        gap: 1rem;
        width: 100%;
      }

      .error {
        color: rgba(234, 15, 15, 1);
        font-size: 0.9rem;
        font-family: Nunito;

        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
      }
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    font-size: 1.3rem;
    flex-wrap: wrap;
    width: 100%;

    .no-boder-btn {
      color: rgba(87, 87, 87, 1);
      font-family: Nunito;

      font-weight: 400;
      line-height: 27px;
      letter-spacing: 0em;
    }

    .border-btn {
      width: 225px;
      height: 43px;
      padding: 8px;
      border-radius: 4px;
      border: 2px solid rgba(67, 171, 249, 1);
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        border: 2px solid rgba(67, 171, 249, 1);
      }
    }
  }

  @media screen and (max-width: 990px) {
    width: 100%;

    form {
      .input-container {
        .input-values {
          gap: 0.5rem;
        }
      }
    }
  }
`;
