import styled from 'styled-components';

export const PlansContainer = styled.div`
  padding-top: 20px;
  .main-wrap {
    width: 90%;
    margin: 0 auto;

    h3 {
      text-align: center;
      font-size: 1rem;
    }
    h4 {
      font-size: 0.8rem;
    }

    .background-container {
      display: flex !important;
      justify-content: center !important;
      position: relative;
      margin: 0 auto;
      margin-top: 3rem;
      margin-bottom: 1.25rem;
    }

    .update {
      font-size: 1rem;
      text-align: center;
      color: #0a0a0a;
      margin-bottom: 3.25rem;
    }

    form {
      .main-form {
        width: 80%;
        font-family: "Roboto", sans-serif;
        margin: 0 auto;
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .input-container {
          /* width: 100%; */
          p {
            font-size: 0.7rem;
          }
          input[type="text"],
          input[type="email"],
          input[type="password"] {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 6px;
            gap: 12px;
            width: 100%;
            background: #ffffff;
            border: 1px solid #161616;

            font-size: 0.9rem;

            &::placeholder {
              color: #d3d3d3;
            }
          }
        }
        hr {
          margin-top: 52px;
          margin-bottom: 42px;
        }

        .password-set {
          display: flex;
          gap: 10px;
          cursor: pointer;
        }

        .password-input {
          display: flex;
          align-items: center;
          gap: 3px;
          border: 1px solid #161616;
          border-radius: 8px;
          padding: 1.5px;

          img {
            width: 1.5rem;
            height: 1.5rem;
          }

          input[type="password"] {
            border: none;

            &::placeholder {
              text-align: left;
              color: #252423;
              padding-left: 1.875rem;
            }
            &:focus {
              outline: none;
            }
          }
        }
      }

      .button-container {
        margin-top: 2rem;
        width: 90%;
        button {
          align-self: flex-end;
          float: right;
          clear: both;
          border: none;
          color: #fff;
          max-width: 270px;
          width: 70%;
          height: 47px;
          margin: 0 auto;
          background: #2667b1;
          border-radius: 12px;
          cursor: pointer;
        }
      }
    }
  }
`;
