import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import client from '../../services';
import {
  NotificationContianer,
  NotificationWrapper,
} from './NotificationPage.style';
import { toast } from 'react-toastify';
import {
  getAllNotification,
  newNotification,
} from '../../store/reducers/userReducer';
import Skeleton from '@material-ui/lab/Skeleton';
import { async } from '@firebase/util';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { timeDiff } from '../../functions';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { HiOutlineTrash } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@mui/material';
import trash from '../../assest/images/trash.svg';
import bgimage from '../../assest/images/bg-image-popup.jpg';
import { Link } from 'react-router-dom';
import { Dialog, DialogTitle, DialogActions, Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    borderRadius: '50px',
  },
  stack: {
    borderRadius: '50px',
  },
}));

const tokenKey = 'auth_token';

const NotificationPage = () => {
  const { t } = useTranslation(['dashboard']);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [modal, setModal] = useState('');
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [notificationIDs, setNotificationIDs] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckBoxAll, setIsCheckBoxAll] = useState(false);
  const [passedData, setPassedData] = useState({
    data: {},
    title: '',
    desc: '',
    summary: '',
  });

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (data, id, title, desc, summary) => {
    setPassedData({
      data,
      id,
      title,
      desc,
      summary,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Accept Invite
  const acceptInvite = async () => {
    await client
      .post('/Souvenir/subscribeSouvenir', {
        id: passedData?.data?.id,
        subscribe: true,
      })
      .then((res) => {
        console.log('Resdata ', res);
        if (res?.status === 200) {
          toast.success('Invite Accepted');
          setOpen(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e?.response?.data?.Message ?? e?.message);
      });
  };

  async function fetchNotice() {
    setLoading(true);
    await client
      .get('/Notification/userNotification')
      .then((res) => {
        let data = res.data;
        dispatch(newNotification(data));
        setModal('');
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(
          ' NOtification error ',
          e?.response?.data?.Message ?? e?.message
        );
        // toast.error(e?.response?.data?.Message ?? e?.message);
      });
  }

  useEffect(() => {
    fetchNotice();
  }, [newNotification]);

  const updateReadStatus = async (id, type, data) => {
    setLoading(true);
    await client
      .put(`/Notification/updateStatusNotification?notificationId=${id}`)
      .then((res) => {
        setLoading(false);
        fetchNotice();

        if (data?.id && type === 'bespoke') {
          const bespokeId = data?.id;
          navigate(`/bespoke/calendar/${bespokeId}`);
        } else if (data?.id && type === 'event') {
          const eventId = data?.id;
          navigate(`/bespoke/event/${eventId}`);
        } else console.log(`No ${type} id`);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.Message ?? e?.message);
        setLoading(false);
      });
  };

  const handleSelectAll = (e) => {
    setIsCheckBoxAll(!isCheckBoxAll);
    // console.log('all')
    setNotificationIDs(
      user?.notification?.recent.map((oneNotification) => oneNotification.id)
    );
    if (isCheckBoxAll) {
      setNotificationIDs([]);
      // console.log('none')
    }
  };

  const handleCheckboxChange = (event) => {
    const { checked, value } = event.target;
    if (checked) {
      setNotificationIDs((prevSelectedIDs) => [...prevSelectedIDs, value]);
      console.log('NIDs ', notificationIDs);
    } else {
      setNotificationIDs((prevSelectedIDs) =>
        prevSelectedIDs.filter((id) => id !== value)
      );
    }
  };

  const deleteNotification = async (id) => {
    setLoading(true);
    await client
      .delete(`/Notification/deletenotification/${id}`)
      .then((res) => {
        setLoading(false);
        fetchNotice();
        toast.success(t('Notification Deleted Successfully'));
      })
      .catch((e) => {
        toast.error(e?.response?.data?.Message ?? e?.message);
        setLoading(false);
      });
  };

  const deleteMultipleNotification = async (idList) => {
    if (idList.length === 0) {
      toast.error(t('You have not selected any notification'));
      return;
    }
    setLoading(true);
    let data = {
      notificationID: [...idList],
    };

    let config = {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
    };

    await client
      .delete(`/Notification/bulkdeletenotification`, { data }, config)
      .then((res) => {
        setLoading(false);
        fetchNotice();
        toast.success(t('Notifications Deleted Successfully'));
        setIsCheckBoxAll(false);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.Message ?? e?.message);
        setLoading(false);
      });
  };

  const disableNotification = async (id) => {
    setLoading(true);
    await client
      .put(`/Notification/disableNotification?notificationID=${id}`)
      .then((res) => {
        setLoading(false);
        fetchNotice();
        toast.success(t('Notification Disabled Successfully'));
      })
      .catch((e) => {
        toast.error(e?.response?.data?.Message ?? e?.message);
        setLoading(false);
      });
  };

  console.log('PURL ', passedData.data.coverImage);

  const messageCardTriger = (id, type, data, isRead) => {
    data?.id && type
      ? updateReadStatus(id, type, data)
      : isRead
      ? {}
      : updateReadStatus(id, type, data);
  };

  console.log('Notifications ', user.notification.recent);
  return (
    <NotificationContianer>
      {user?.notification?.recent.length >= 0 && (
        <NotificationWrapper>
          <div className="heading">
            <h3>{t('Notifications')}</h3>

            <p>
              {' '}
              {user?.notification?.count} {t('unread notification')}
            </p>

            <div className="w-full flex justify-between items-center text-[14px] ">
              <div className="flex space-x-2 items-center justify-center">
                <input
                  type="checkbox"
                  name="selectAll"
                  id="selectAll"
                  onChange={handleSelectAll}
                  checked={isCheckBoxAll}
                  className="ml-6 "
                />
                <span className="text-sm">Select All</span>
              </div>
              <button
                className="bg-transparent hover:bg-[#2667b1] text-[#2667b1] font-semibold hover:text-white py-1 px-3 border border-[#2667b1] hover:border-transparent rounded flex  items-center space-x-2"
                onClick={() => deleteMultipleNotification(notificationIDs)}
              >
                <img
                  src={trash}
                  alt="delete icon"
                  width={25}
                  height={25}
                  className="px-1 hover:fill-white"
                />
                Delete
              </button>
            </div>
          </div>

          {loading ? (
            <div className="skeleton_container">
              {[1, 2, 3, 4, 5].map((val) => (
                <div key={val}>
                  <Skeleton variant="rect" className="skeleton_loader" />
                </div>
              ))}
            </div>
          ) : (
            <div className="message_wrapper">
              {/* Make notification Bottom to Top with slice and reverse */}
              {user?.notification?.recent.map(
                ({
                  title,
                  desc,
                  id,
                  isRead,
                  timeStamp,
                  data,
                  type,
                  summary,
                }) => (
                  <section className="message_box" key={id}>
                    <div className="message_info">
                      {' '}
                      <input
                        type="checkbox"
                        checked={notificationIDs.includes(id)}
                        onChange={(e) => handleCheckboxChange(e)}
                        value={id}
                      />
                    </div>
                    <div
                      className="message_box__header_content"
                      onClick={() => {
                        messageCardTriger(id, type, data, isRead);
                        setModal('');
                      }}
                    >
                      <span
                        className={`read_status  ${
                          isRead === true ? 'read' : 'unread'
                        }`}
                      ></span>
                      <div className="message_box__text">
                        <h5>{title}</h5>
                        <p className="message_summary__content">{summary}</p>
                      </div>
                    </div>

                    <div
                      className="message_info"
                      onClick={() => {
                        messageCardTriger(id, type, data, isRead);
                        setModal('');
                      }}
                    >
                      <p className="message_info__content">{desc}</p>
                    </div>
                    {data?.type === 'invitation' ? (
                      <div className="message_info">
                        <button
                          className="bg-transparent hover:bg-[#2667b1]   hover:text-white text-xs px-3 py-1 border border-[#2667b1] hover:border-transparent rounded flex  items-center space-x-2"
                          onClick={() =>
                            handleClickOpen(data, id, title, desc, summary)
                          }
                        >
                          View Details
                        </button>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className="message_time_options">
                      <p
                        className={`message_info__date ${
                          isRead ? 'read_date' : 'unread_date'
                        }`}
                      >
                        {timeDiff(timeStamp)}
                      </p>
                      <BiDotsHorizontalRounded
                        onClick={() => setModal(modal ? '' : id)}
                      />
                    </div>

                    {modal === id && (
                      <div className="modal">
                        <div
                          className="modal_content"
                          onClick={() => deleteNotification(id)}
                        >
                          <HiOutlineTrash className="delete_svg" />
                          <div className="modal_desc">
                            <h3 className="modal_delete">{t('Delete')}</h3>
                            <p>{t('Delete Delete this notification')}</p>
                          </div>
                        </div>

                        {/*<div
                          className="modal_content"
                          onClick={() => disableNotification(id)}
                        >
                          <HiOutlineBellSlash className="bell_svg" />
                          <div className="modal_desc">
                            <h3 className="modal_turn-off">Turn off</h3>
                            <p>
                              Turn off Stop receiving notification for this
                              event{' '}
                            </p>
                          </div>
                    </div>*/}
                      </div>
                    )}
                  </section>
                )
              )}
            </div>
          )}

          <Dialog open={open} onClose={handleClose}>
            <Stack
              p={3}
              justifyContent="center"
              alignItems="center"
              className="font-body "
            >
              <DialogTitle>
                <h1 className="leading-none font-body font-normal ">
                  {passedData?.title}
                </h1>
              </DialogTitle>
              <h2 className="-mt-2 mb-2 font-bold">{passedData?.summary}</h2>
              <img
                src={passedData?.data?.coverImage}
                alt="Example"
                width={280}
              />
              <h2 className="text-lg mt-4">{passedData?.desc}</h2>
              <DialogActions>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  spacing={2}
                >
                  <button
                    type="button"
                    onClick={acceptInvite}
                    className="text-white bg-[#2667B1] hover:bg-[#2667B1]/55 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-12 py-1   dark:bg-[#2667B1] dark:hover:bg-[#2667B1] focus:outline-none"
                  >
                    {t('Accept')}
                  </button>

                  <button
                    type="button"
                    onClick={handleClose}
                    className="outline outline-offset-2 outline-[#EA0F0F] rounded-md text-sm px-11 "
                  >
                    {t('Decline')}
                  </button>
                </Stack>
              </DialogActions>
            </Stack>
          </Dialog>
        </NotificationWrapper>
      )}
    </NotificationContianer>
  );
};

export default NotificationPage;
