import React, { useState, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FaMinus, FaPlus } from 'react-icons/fa';
import Skeleton from '@material-ui/lab/Skeleton';
import { useDropzone } from 'react-dropzone';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
  getCountyCode,
  handleError,
  seizer,
  userGeolocation,
} from '../../../../functions';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import '../../../create-page/style/create.css';
import {
  changeCoverImage,
  generate,
  statusUpdate,
} from '../../../../store/reducers/CurrentDraft';
import { toast } from 'react-toastify';
import client from '../../../../services';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {
  canvasPreview,
  extractImageFileExtensionFromBase64,
  UploadImageToS3,
} from '../../../../functions';
import { RotatingLines } from 'react-loader-spinner';
import { BsFillCloudUploadFill } from 'react-icons/bs';
import '../../../component/bespoke-core/calendarComponent/styles/uploadModal.css';
import Dialog from '@material-ui/core/Dialog';

import {
  Slider,
  Sketch,
  Material,
  Colorful,
  Compact,
  Circle,
  Wheel,
  Block,
  Github,
  Chrome,
} from '@uiw/react-color';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCalendarMonth,
  setInitialMonthArray,
} from '../../../../store/features/calendarSlice';
import moment from 'moment';
import dayjs from 'dayjs';
import { setTVDraft } from '../../../../store/reducers/draftTVEvents';

const useStyles = makeStyles((theme) => ({
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(1)',
    },
    '25%': {
      transform: 'scale(1.03)',
    },
    '50%': {
      transform: 'scale(1.06)',
    },
    '75%': {
      transform: 'scale(1.07)',
    },
    '100%': {
      transform: 'scale(1.1)',
    },
  },

  root: {
    background: '#fff',
    padding: '20px',
  },
  drop: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  preview: {
    width: '100%',
    display: 'flex',
  },
  loader: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  loaderSkeleton: {
    height: '18rem',
  },
  covers: {
    marginTop: 20,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  imageList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  nextCon: {
    marginTop: 10,
  },
  reactSwitch: {
    verticalAlign: 'middle',
    marginLeft: '4px',
  },
  next: {
    background: '#2667B1',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2666B1',
    },
  },
  TextInput: {
    borderRadius: 10,
    width: '100%',
    height: 50,
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      '& fieldset': {
        border: '2px solid #2667b1',
      },
      '&.Mui-focused fieldset': {
        border: '2px solid #2667b1',
      },
    },
  },
  TextInputSocial: {
    borderRadius: 10,
    width: '100%',
    paddingLeft: '50px',
    paddingRight: '50px',
    marginBottom: 15,
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      height: 50,
      '& fieldset': {
        border: '1px solid #000000',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #2667b1',
      },
    },
  },

  floatBtn: {
    position: 'fixed',
    bottom: '100px',
    right: '3%',
    zIndex: '1000px',
    backgroundColor: '#2667B1',
    width: '4rem',
    height: '4rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',

    boxShadow: '0 0 0.5rem rgba(38, 103, 177,0.3)',
    animation: '$ripple 1.2s infinite ease-in-out',
  },

  floatBtnSvg: { fontSize: '1.5rem', color: '#ffffff' },
}));

const PoliticsFooter = () => {
  const { t } = useTranslation(['dashboard']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const {
    saveDate,
    saveTitle,
    cover,
    navRoute,
    templateId,
    templatePlan,
    socialData,
  } = state;
  const [switchColorEffect, setSwitchColorEffect] = useState('footer');

  const beskpokeDraft = useSelector((state) => state.draft);
  const [processing, setProcessing] = useState(false);
  // Error State

  const [formError, setFormError] = useState({
    titleError: '',
    subTitleError: '',
    addressError: '',
    emailError: '',
    phoneError: '',
    footerImgUrlError: '',
  });

  // Main Footer Data state
  const [footerData, setFooterData] = React.useState({
    titleFontFamily: 'Roboto',
    titleFontSize: 42,
    subFontFamily: 'Roboto',
    subFontSize: 32,
    subTitle: '',
    title: '',
    address: '',
    email: '',
    phone: '',
    iconsColor: '#5d00ff',
    footerColor: '#43ABF9',
    footerImgUrl: '',
  });

  // Character count
  const [countChar, setCountChar] = useState({
    title: 0,
    subTitle: 0,
  });

  // Handle input from user
  const handleChange = (event) => {
    let value = event.target.value
      .replace(/[ ]{2,}/gi, ' ') // Remove multiple space
      .replace(/\n +/, '\n'); // Remove space after newline

    value = value.length > 100 ? value.slice(0, 99) : value;

    if (event.target.type === 'tel')
      value = event.target.value.replace(/([a-zA-Z])/g, '');

    // Set couint for title and sub title
    setCountChar((prevState) => ({
      ...prevState,
      [event.target.name]: value.length,
    }));

    // Set data value
    setFooterData({
      ...footerData,
      [event.target.name]: value,
    });
  };

  // Recall draft if created.(Preview)
  const handleGetDraftPreview = async (id) => {
    console.log('Draft id tem ', id);
    await client
      .get(`/Souvenir/draft/${id}`)
      .then((res) => {
        dispatch(generate(res?.data));
        dispatch(setCalendarMonth(res?.data?.months));
        setProcessing(false);
        navigate('/draft/view');
        console.log('Draft id tem res ', res?.data);
      })
      .catch((err) => {
        console.log(err?.response?.data?.Message ?? err?.message);
        setProcessing(false);
      });
  };

  // Create Tem4 Structure
  const setNewDraftTem4 = async (id) => {
    let country = new userGeolocation().getUserCountry();

    let { CountryCode } = getCountyCode(country) || 'NGN';

    // Empty the state from prev data
    dispatch(setTVDraft({ events: [] }));
    // Set new  Draft struct
    client
      .get(`/Souvenir/${id}?webDraft=webDraft&country=${CountryCode}`)
      .then((res) => {
        dispatch(setTVDraft(res?.data));
        console.log(res.data, 'Tem 4 res Header');
        setProcessing(false);
        navigate('/draft/view');
      })
      .catch((e) => {
        console.log(e);
        console.log(e?.response?.data?.Message ?? e?.message);

        setProcessing(false);
      });
    // End set draft
  };

  // Save Function
  const save = async (bespoke) => {
    setProcessing(true);
    var data = {
      ...bespoke,
    };
    console.log(data, 'Draft before save');
    if (beskpokeDraft?.templateId === '4' || beskpokeDraft?.templateId === 4) {
      await client
        .post(`/Souvenir/create-souvenir/tvguide`, data)
        .then((res) => {
          dispatch(statusUpdate(res?.data));

          setNewDraftTem4(res?.data?.id);
        })
        .catch((e) => {
          console.log(e?.response?.data?.Message ?? e?.message);
          setPublishing(false);
        });
    } else {
      console.log('tv guide Data', data);
      await client
        .post('/Souvenir/create-bespoke', data)
        .then((res) => {
          dispatch(generate(bespoke));
          dispatch(statusUpdate(res?.data));
          console.log('After savcv', res?.data);
          handleGetDraftPreview(res?.data?.id);
        })
        .catch((e) => {
          console.log(e);
          console.log(e?.response?.data?.Message ?? e?.message);
          setProcessing(false);
        });
    }
  };

  // Proceed to studio
  const next = () => {
    if (
      footerData?.title &&
      footerData?.title !== ' ' &&
      footerData?.subTitle &&
      footerData?.subTitle !== ' ' &&
      footerData?.address &&
      footerData?.address !== ' ' &&
      footerData?.email &&
      footerData?.email !== ' ' &&
      footerData?.phone &&
      footerData?.phone !== ' ' &&
      footerData?.footerImgUrl
    ) {
      dispatch(setInitialMonthArray(saveDate?.getFullYear()));
      let months = [];
      let dateStart = moment(saveDate);
      let dateEnd = moment(saveDate).add(12, 'month');
      while (dateEnd.diff(dateStart, 'months') >= 1) {
        let month = {
          date: dayjs(dateStart.toDate()).format('YYYY-M'),
          background: '',
          events: [],
        };

        months.push(month);
        dateStart.add(1, 'month');
      }
      let bespoke = {
        templateName: saveTitle,
        yearPicked: dayjs(saveDate).format('YYYY'),
        coverImg: {
          id: 0,
          src: cover,
          coverType: 0,
        },
        months: months,
        ...socialData,
        customColors: [
          {
            id: 1,
            color: '#FFDAC0',
            label: 'Birthday',
            isPresent: true,
            customId: 1,
          },
          {
            id: 2,
            color: '#FFEDAC',
            label: 'Anniversary ',
            isPresent: true,
            customId: 2,
          },
          {
            id: 3,
            color: '#FFCEEC',
            label: 'Holiday',
            isPresent: true,
            customId: 3,
          },
          {
            id: 4,
            color: '#BAFFC1',
            label: 'Birthday',
            isPresent: true,
            customId: 4,
          },
          {
            id: 5,
            color: '#B4DFFF',
            label: 'Birthday',
            isPresent: true,
            customId: 5,
          },
          {
            id: 6,
            color: '#FDB8B8',
            label: 'Birthday',
            isPresent: true,
            customId: 6,
          },
        ],
        templateId: templateId,
        footer: {
          ...footerData,
          subTitle: footerData?.subTitle.trim(),
          title: footerData?.title.trim(),
          address: footerData?.address.trim(),
          email: footerData?.email.trim(),
        },
        calendarPlan: templatePlan,
        status: 'draft',
      };

      save(bespoke);

      // return navigate('/studio/event');
    } else {
      toast.error(t('Empty Field Found!!!'));
      // Handle Error
      const result = handleError(footerData);

      for (const val of result[1]) {
        let valError = val + 'Error';

        switch (`${valError}`) {
          case 'titleError':
            setFormError((prevState) => ({
              ...prevState,
              [valError]: true,
            }));
            break;

          case 'subTitleError':
            setFormError((prevState) => ({
              ...prevState,
              [valError]: true,
            }));
            break;

          case 'addressError':
            setFormError((prevState) => ({
              ...prevState,
              [valError]: true,
            }));
            break;
          case 'emailError':
            setFormError((prevState) => ({
              ...prevState,
              [valError]: true,
            }));
            break;
          case 'phoneError':
            setFormError((prevState) => ({
              ...prevState,
              [valError]: true,
            }));
            break;
          case 'footerImgUrlError':
            setFormError((prevState) => ({
              ...prevState,
              [valError]: true,
            }));
            break;
          default:
            return 'err';
        }
      }
    }
  };

  return (
    <PoliticContainer>
      <section className="main_content">
        <section className="heading">
          <h1>{t('Footer Settings')}</h1>
          <p>
            {t(
              'This field is required in other to give your calendar a footer preview.'
            )}
          </p>
        </section>

        <section className="data_fields">
          <div className="data_field_input">
            <InputField
              maxCount={100}
              placeholder={t('Add Heading')}
              name="title"
              handleChange={handleChange}
              footerData={footerData}
              formError={formError}
              setFormError={setFormError}
              countChar={countChar}
            />

            <div className="font_container">
              <FontSelectData
                name="titleFontFamily"
                handleChange={handleChange}
                footerData={footerData}
              />

              <FontSize
                footerData={footerData}
                setFooterData={setFooterData}
                name="titleFontSize"
              />
            </div>
          </div>

          <div className="data_field_input">
            <InputField
              maxCount={100}
              placeholder={t('Sub heading')}
              name="subTitle"
              handleChange={handleChange}
              footerData={footerData}
              formError={formError}
              setFormError={setFormError}
              countChar={countChar}
            />

            <div className="font_container">
              <FontSelectData
                name="subFontFamily"
                handleChange={handleChange}
                footerData={footerData}
              />

              <FontSize
                footerData={footerData}
                setFooterData={setFooterData}
                name="subFontSize"
              />
            </div>
          </div>
        </section>

        <section className="main_data_input_container">
          <div className="data_field_input_icon">
            <InputFieldIcon
              name="address"
              footerData={footerData}
              placeholder={t('Address')}
              handleChange={handleChange}
              formError={formError}
              setFormError={setFormError}
              svgPath={
                <path
                  d="M14 1.54688L2.24219 12.4636V13.9675L14 3.0475L25.7578 13.9675V12.4636L14 1.54688ZM14 4.39062L7.00547 10.8831H20.9945L14 4.39062ZM6.50781 11.348L2.24219 15.3073V26.6331H7.875V20.1253H12.3594V26.6331H25.7578V15.3073L21.4922 11.348V18.8675H6.50781V11.348ZM8.40547 11.8675C8.47656 11.9167 8.54766 11.9714 8.62422 12.0261C9.24766 12.4745 10.0078 12.9886 10.768 13.4753C11.5227 13.962 12.2828 14.4269 12.8953 14.755C13.2016 14.9245 13.4695 15.0558 13.6773 15.1433C13.8305 15.2089 13.9617 15.2417 14 15.2527C14.0383 15.2417 14.1695 15.2089 14.3227 15.1433C14.5305 15.0558 14.7984 14.9245 15.1047 14.755C15.7172 14.4269 16.4773 13.962 17.232 13.4753C17.9922 12.9886 18.7523 12.4745 19.3758 12.0261C19.4523 11.9714 19.5234 11.9167 19.5945 11.8675H8.40547ZM14 15.2527C13.9891 15.2581 13.9836 15.2581 14 15.2581C14.0164 15.2581 14.0109 15.2581 14 15.2527ZM7.49219 12.4198V17.4566L11.2547 14.9464C10.9211 14.7441 10.5766 14.5308 10.232 14.3066C9.46094 13.8089 8.68984 13.2839 8.05547 12.83C7.85313 12.6823 7.66172 12.5456 7.49219 12.4198ZM20.5078 12.4198C20.3383 12.5456 20.1469 12.6823 19.9445 12.83C19.3102 13.2839 18.5391 13.8089 17.768 14.3066C17.4234 14.5308 17.0789 14.7441 16.7453 14.9464L20.5078 17.4566V12.4198ZM12.2008 15.4987L8.62969 17.8831H19.3703L15.7992 15.4987C15.7227 15.5425 15.6461 15.5862 15.575 15.6245C15.2523 15.7995 14.9625 15.9417 14.7109 16.0511C14.4539 16.1605 14.2734 16.2425 14 16.2425C13.7266 16.2425 13.5461 16.1605 13.2891 16.0511C13.0375 15.9417 12.7477 15.7995 12.425 15.6245C12.3539 15.5862 12.2773 15.5425 12.2008 15.4987ZM15.75 20.1253H21V23.6253H15.75V20.1253Z"
                  fill={footerData.iconsColor}
                />
              }
            />
            <InputFieldIcon
              name="email"
              footerData={footerData}
              placeholder={t('P.O Box details')}
              handleChange={handleChange}
              formError={formError}
              setFormError={setFormError}
              svgPath={
                <path
                  d="M4.66732 23.3327C4.02565 23.3327 3.47615 23.104 3.01882 22.6467C2.56149 22.1893 2.33321 21.6402 2.33399 20.9993V6.99935C2.33399 6.35768 2.56265 5.80818 3.01999 5.35085C3.47732 4.89352 4.02643 4.66524 4.66732 4.66602H23.334C23.9757 4.66602 24.5252 4.89468 24.9825 5.35202C25.4398 5.80935 25.6681 6.35846 25.6673 6.99935V20.9993C25.6673 21.641 25.4387 22.1905 24.9813 22.6478C24.524 23.1052 23.9749 23.3335 23.334 23.3327H4.66732ZM14.0007 14.9618C14.0979 14.9618 14.2002 14.9471 14.3075 14.9175C14.4148 14.888 14.5167 14.8444 14.6132 14.7869L22.8673 9.62435C23.0229 9.52713 23.1395 9.40541 23.2173 9.25918C23.2951 9.11296 23.334 8.95274 23.334 8.77852C23.334 8.38963 23.1687 8.09796 22.8382 7.90352C22.5076 7.70907 22.1673 7.7188 21.8173 7.93268L14.0007 12.8327L6.18399 7.93268C5.83399 7.7188 5.49371 7.71413 5.16315 7.91868C4.8326 8.12324 4.66732 8.40985 4.66732 8.77852C4.66732 8.97296 4.70621 9.14329 4.78399 9.28952C4.86176 9.43574 4.97843 9.54735 5.13399 9.62435L13.3882 14.7869C13.4854 14.8452 13.5877 14.8891 13.695 14.9187C13.8023 14.9482 13.9042 14.9626 14.0007 14.9618Z"
                  fill={footerData.iconsColor}
                />
              }
            />
            <InputFieldIcon
              name="phone"
              footerData={footerData}
              placeholder={t('Phone number')}
              handleChange={handleChange}
              formError={formError}
              setFormError={setFormError}
              type="tel"
              svgPath={
                <path
                  d="M22.1667 13.9414C22.1667 11.6664 21.3741 9.73674 19.789 8.15241C18.2039 6.56807 16.2742 5.77552 14 5.77474V3.44141C15.4583 3.44141 16.8245 3.71868 18.0985 4.27324C19.3725 4.8278 20.4808 5.57641 21.4235 6.51907C22.3662 7.46174 23.1148 8.57007 23.6693 9.84407C24.2239 11.1181 24.5008 12.4839 24.5 13.9414H22.1667ZM17.5 13.9414C17.5 12.9692 17.1597 12.1428 16.4792 11.4622C15.7986 10.7817 14.9722 10.4414 14 10.4414V8.10807C15.6139 8.10807 16.9898 8.67702 18.1277 9.81491C19.2656 10.9528 19.8341 12.3283 19.8333 13.9414H17.5ZM23.275 24.4997C20.7667 24.4997 18.3213 23.9456 15.939 22.8372C13.5567 21.7289 11.4469 20.2511 9.60983 18.4039C7.77272 16.5567 6.29495 14.4419 5.1765 12.0596C4.05806 9.67724 3.49922 7.2323 3.5 4.72474V4.11224C3.5 3.89835 3.51945 3.69418 3.55833 3.49974H10.3833L11.4625 9.36224L8.1375 12.7164C8.95417 14.1164 9.98006 15.4289 11.2152 16.6539C12.4503 17.8789 13.8063 18.9386 15.2833 19.8331L18.6667 16.4497L24.5 17.6164V24.4414C24.3056 24.4609 24.1014 24.4756 23.8875 24.4857C23.6736 24.4959 23.4694 24.5005 23.275 24.4997Z"
                  fill={footerData.iconsColor}
                />
              }
            />
          </div>

          <div className="color_selection">
            <div className="switcher">
              <span
                className={switchColorEffect === 'footer' ? 'span_line' : ''}
                onClick={() => setSwitchColorEffect('footer')}
              >
                {t('Footer color')}
              </span>

              <span
                className={switchColorEffect === 'icons' ? 'span_line' : ''}
                onClick={() => setSwitchColorEffect('icons')}
              >
                {t('Icon color')}
              </span>
            </div>
            <span>
              {t('Select your')} {t(switchColorEffect)} {t('color')}
            </span>
            <Wheel
              color="#fff"
              onChange={(color) => {
                let key =
                  switchColorEffect === 'icons' ? 'iconsColor' : 'footerColor';
                setFooterData({
                  ...footerData,
                  [key]: color.hex,
                });
              }}
            />
            <div className="hex_value">
              <span>HEX</span>

              <input
                type="text"
                name={
                  switchColorEffect === 'icons' ? 'iconsColor' : 'footerColor'
                }
                value={
                  switchColorEffect === 'icons'
                    ? footerData.iconsColor
                    : footerData.footerColor
                }
                maxLength={7}
                onChange={handleChange}
              />
            </div>
          </div>
        </section>
      </section>
      <section className="footer_container">
        <h3>{t('Footer Preview')}</h3>
        <Footer
          stateData={footerData}
          cover={footerData.footerImgUrl}
          setCover={setFooterData}
          socialData={socialData}
          formError={formError}
          setFormError={setFormError}
        />
      </section>

      <section className="btn_container">
        <button className="btn_back" onClick={() => navigate(-1)}>
          {t('Back')}
        </button>
        <button
          disabled={processing}
          className="btn_next"
          onClick={() => next()}
        >
          {t('Create')}{' '}
          {processing && (
            <RotatingLines
              strokeColor="#fff"
              strokeWidth="5"
              animationDuration="0.75"
              width="20"
              visible={processing}
            />
          )}
        </button>
      </section>
    </PoliticContainer>
  );
};

// Input Field
const InputField = ({
  maxCount,
  placeholder,
  name,
  handleChange,
  footerData,
  formError,
  setFormError,
  countChar,
}) => {
  const { t } = useTranslation(['dashboard']);

  const [isReadOnly, setIsReadOnly] = useState(false);
  let errorName = name + 'Error';
  const checkCharacterLimits = (event) => {
    if (event.target.value.length > maxCount - 1 && event.key !== 'Backspace') {
      setIsReadOnly(true);
    } else {
      setIsReadOnly(false);
    }
  };
  return (
    <InputFieldContainer>
      <textarea
        name={name}
        value={footerData[name]}
        type="text"
        placeholder={placeholder}
        className="input_field"
        readOnly={isReadOnly}
        onChange={(e) => {
          handleChange(e);
          setFormError({
            ...formError,
            [errorName]: false,
          });
        }}
        onKeyDown={(event) => {
          checkCharacterLimits(event);
        }}
      ></textarea>

      <div className="count_preview_container">
        <span className="count_preview">
          {countChar[name]}/{maxCount}
        </span>
        {isReadOnly && (
          <span className="count_preview_err">
            {' '}
            {t('Exceeded number of Character')}{' '}
          </span>
        )}

        {formError[`${errorName}`] && (
          <span className="count_preview_err">
            {' '}
            {t('Field can not be empty')}{' '}
          </span>
        )}
      </div>
    </InputFieldContainer>
  );
};

// Font Selection Function
const FontSelectData = ({ name, handleChange, footerData }) => {
  return (
    <FontFieldContainer>
      <Select
        labelId="demo-simple-select-label"
        name={name}
        id="demo-simple-select"
        value={footerData[name]}
        onChange={handleChange}
      >
        <MenuItem value="Roboto">Roboto</MenuItem>
        <MenuItem value="Sofia">Sofia</MenuItem>
        <MenuItem value="Nunito">Nunito</MenuItem>
        <MenuItem value="DM+Sans">DM+Sans</MenuItem>
      </Select>
    </FontFieldContainer>
  );
};

// Font Size Increament
const FontSize = ({ name, setFooterData, footerData }) => {
  return (
    <FontSizeContainer>
      <FaMinus
        onClick={() =>
          setFooterData({
            ...footerData,
            [name]: footerData[name] - 1,
          })
        }
      />
      <input type="text" name={name} value={footerData[name]} readOnly />
      <FaPlus
        onClick={() =>
          setFooterData({
            ...footerData,
            [name]: footerData[name] + 1,
          })
        }
      />
    </FontSizeContainer>
  );
};

// Input Field with Icon

const InputFieldIcon = ({
  name,
  footerData,
  handleChange,
  svgPath,
  placeholder,
  type = 'text',
  formError,
  setFormError,
}) => {
  const { t } = useTranslation(['dashboard']);
  let errorName = name + 'Error';
  return (
    <div>
      <InputFieldIconContainer>
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {svgPath}
        </svg>
        <input
          type={type}
          pattern={type === 'tel' ? '/^[+-]? d+$/' : null}
          placeholder={placeholder}
          name={name}
          value={footerData[name]}
          onChange={(e) => {
            handleChange(e);

            setFormError({
              ...formError,
              [errorName]: false,
            });
          }}
        />
      </InputFieldIconContainer>

      {formError[`${errorName}`] && (
        <span style={{ color: 'red', fontSize: '0.9rem' }}>
          {t('Field can not be empty')}
        </span>
      )}
    </div>
  );
};

//  Footer Component
const Footer = ({
  stateData,
  cover,
  setCover,
  socialData,
  formError,
  setFormError,
}) => {
  const { t } = useTranslation(['dashboard']);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [aspect, setAspect] = useState(16 / 9);
  const imgRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [temImg, setTemImg] = useState(null);
  const [loading, setLoading] = useState();
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Upload function
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = async () => {
        // setLoading(true);
        // Send Content to server
        const binaryStr = reader.result;

        var i = new Image();
        i.onload = function () {
          setAspect(seizer(i.width, i.height));
          if (i.width < 1300 && i.height < 1300) {
            setCrop({
              unit: '%', // Can be 'px' or '%'
              x: 0,
              y: 0,
              width: 100,
              height: 100,
            });
          } else {
            setCrop(
              centerAspectCrop(i.width, i.height, seizer(i.width, i.height))
            );
          }
        };
        i.src = binaryStr;

        setTemImg(binaryStr);
        setCrop(undefined); // Set an immediate crop region
        handleClickOpen();
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    // open,
  } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.png'],
    },
  });

  // Image Function
  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 100,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }
  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
    }
  }

  // Upload image (upload Icon)
  const uploadCover = async () => {
    setLoading(true);
    if (completedCrop?.width && completedCrop?.height && imgRef.current) {
      const canvas = document.createElement('canvas');
      canvasPreview(imgRef.current, canvas, completedCrop);

      const fileext = extractImageFileExtensionFromBase64(temImg);
      const imageData64 = canvas.toDataURL('image/' + fileext);

      canvas.toBlob(
        async function (blob) {
          await client
            .post('/Souvenir/image-upload', imageData64)
            .then((res) => {
              setCover((prevState) => ({
                ...prevState,
                footerImgUrl: res?.data?.picture,
              }));

              setLoading(false);
              setFormError(false);
              handleClose();
            })
            .catch((e) => {
              toast.error(e?.response?.data?.Message ?? e?.message);
              setLoading(false);
              handleClose();
            });
          handleClose();
          setLoading(false);
        },
        'image/jpeg',
        0.5
      );
    } else {
      toast.error('');
    }
  };

  return (
    <FooterContainer
      bgColor={stateData?.footerColor}
      titleFontFamily={stateData?.titleFontFamily}
      titleFontSize={stateData?.titleFontSize}
      subFontFamily={stateData?.subFontFamily}
      subFontSize={stateData?.subFontSize}
    >
      <section className="footer_wrapper">
        <div className="footer_img">
          <div className="header_mobile">
            <h2>{stateData.title}</h2>
            <p>{stateData.subTitle}</p>
          </div>
          <div>
            {loading ? (
              <div className={classes.loader}>
                <Grid item xs={10} sm={10} md={8} lg={8}>
                  <Skeleton variant="rect" className={classes.loaderSkeleton} />
                </Grid>
              </div>
            ) : !cover ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.drop}
              >
                <div {...getRootProps()} className="drop-zone">
                  <input {...getInputProps()} />
                  {isDragAccept && (
                    <p>{t('Drop the calendar cover here...')}</p>
                  )}
                  {isDragReject && (
                    <p>{t('calendar cover ought to be a png file')}</p>
                  )}
                  {!isDragActive && (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {t('Select or Drop your cover image here')}
                    </Typography>
                  )}
                </div>
              </Grid>
            ) : (
              <div className={classes.preview} {...getRootProps()}>
                <input {...getInputProps()} />
                <Grid item xs={12} sm={12} md={12} lg={12} onClick={open}>
                  <LazyLoadImage
                    className="cover-image"
                    alt={'Image cover'}
                    effect="blur"
                    src={cover}
                  />
                </Grid>
              </div>
            )}

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <Grid item md={12} lg={12}>
                <ReactCrop
                  style={{ width: '100%', height: '100%', overflowY: 'scroll' }}
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  aspect={aspect}
                  locked={true}
                  onComplete={(c) => setCompletedCrop(c)}
                  className="react_crop"
                  // disabled={true}
                >
                  <img
                    src={temImg}
                    loading="lazy"
                    style={{ width: '100%', height: '100%' }}
                    ref={imgRef}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              </Grid>

              <section
                className={classes.floatBtn}
                onClick={() => uploadCover()}
              >
                {loading ? (
                  <RotatingLines
                    strokeColor="#fff"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="20"
                    visible={loading}
                  />
                ) : (
                  <BsFillCloudUploadFill className={classes.floatBtnSvg} />
                )}
              </section>
            </Dialog>
          </div>

          {formError?.footerImgUrlError && (
            <p
              style={{
                color: 'red',
                fontSize: '1rem',
                marginTop: '0.7rem',
                textAlign: 'center',
              }}
            >
              {t("Footer image can't be blank")}
            </p>
          )}
        </div>

        <div className="footer_text">
          <div className="header">
            <h2>{stateData.title}</h2>
            <p>{stateData.subTitle}</p>
          </div>

          <section className="footer_details">
            <div className="footer_detail_head">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 1.54688L2.24219 12.4636V13.9675L14 3.0475L25.7578 13.9675V12.4636L14 1.54688ZM14 4.39062L7.00547 10.8831H20.9945L14 4.39062ZM6.50781 11.348L2.24219 15.3073V26.6331H7.875V20.1253H12.3594V26.6331H25.7578V15.3073L21.4922 11.348V18.8675H6.50781V11.348ZM8.40547 11.8675C8.47656 11.9167 8.54766 11.9714 8.62422 12.0261C9.24766 12.4745 10.0078 12.9886 10.768 13.4753C11.5227 13.962 12.2828 14.4269 12.8953 14.755C13.2016 14.9245 13.4695 15.0558 13.6773 15.1433C13.8305 15.2089 13.9617 15.2417 14 15.2527C14.0383 15.2417 14.1695 15.2089 14.3227 15.1433C14.5305 15.0558 14.7984 14.9245 15.1047 14.755C15.7172 14.4269 16.4773 13.962 17.232 13.4753C17.9922 12.9886 18.7523 12.4745 19.3758 12.0261C19.4523 11.9714 19.5234 11.9167 19.5945 11.8675H8.40547ZM14 15.2527C13.9891 15.2581 13.9836 15.2581 14 15.2581C14.0164 15.2581 14.0109 15.2581 14 15.2527ZM7.49219 12.4198V17.4566L11.2547 14.9464C10.9211 14.7441 10.5766 14.5308 10.232 14.3066C9.46094 13.8089 8.68984 13.2839 8.05547 12.83C7.85313 12.6823 7.66172 12.5456 7.49219 12.4198ZM20.5078 12.4198C20.3383 12.5456 20.1469 12.6823 19.9445 12.83C19.3102 13.2839 18.5391 13.8089 17.768 14.3066C17.4234 14.5308 17.0789 14.7441 16.7453 14.9464L20.5078 17.4566V12.4198ZM12.2008 15.4987L8.62969 17.8831H19.3703L15.7992 15.4987C15.7227 15.5425 15.6461 15.5862 15.575 15.6245C15.2523 15.7995 14.9625 15.9417 14.7109 16.0511C14.4539 16.1605 14.2734 16.2425 14 16.2425C13.7266 16.2425 13.5461 16.1605 13.2891 16.0511C13.0375 15.9417 12.7477 15.7995 12.425 15.6245C12.3539 15.5862 12.2773 15.5425 12.2008 15.4987ZM15.75 20.1253H21V23.6253H15.75V20.1253Z"
                  fill={stateData.iconsColor}
                />
              </svg>
              <p>{stateData.address}</p>
            </div>

            <ul className="footer_detail_list">
              <li>
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.66732 23.3327C4.02565 23.3327 3.47615 23.104 3.01882 22.6467C2.56149 22.1893 2.33321 21.6402 2.33399 20.9993V6.99935C2.33399 6.35768 2.56265 5.80818 3.01999 5.35085C3.47732 4.89352 4.02643 4.66524 4.66732 4.66602H23.334C23.9757 4.66602 24.5252 4.89468 24.9825 5.35202C25.4398 5.80935 25.6681 6.35846 25.6673 6.99935V20.9993C25.6673 21.641 25.4387 22.1905 24.9813 22.6478C24.524 23.1052 23.9749 23.3335 23.334 23.3327H4.66732ZM14.0007 14.9618C14.0979 14.9618 14.2002 14.9471 14.3075 14.9175C14.4148 14.888 14.5167 14.8444 14.6132 14.7869L22.8673 9.62435C23.0229 9.52713 23.1395 9.40541 23.2173 9.25918C23.2951 9.11296 23.334 8.95274 23.334 8.77852C23.334 8.38963 23.1687 8.09796 22.8382 7.90352C22.5076 7.70907 22.1673 7.7188 21.8173 7.93268L14.0007 12.8327L6.18399 7.93268C5.83399 7.7188 5.49371 7.71413 5.16315 7.91868C4.8326 8.12324 4.66732 8.40985 4.66732 8.77852C4.66732 8.97296 4.70621 9.14329 4.78399 9.28952C4.86176 9.43574 4.97843 9.54735 5.13399 9.62435L13.3882 14.7869C13.4854 14.8452 13.5877 14.8891 13.695 14.9187C13.8023 14.9482 13.9042 14.9626 14.0007 14.9618Z"
                    fill={stateData.iconsColor}
                  />
                </svg>
                <p>{stateData.email}</p>
              </li>

              {/*Web link dynamics*/}
              {socialData?.web && (
                <li>
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.0873 16.334C19.1807 15.564 19.2507 14.794 19.2507 14.0007C19.2507 13.2073 19.1807 12.4373 19.0873 11.6673H23.0307C23.2173 12.414 23.334 13.1957 23.334 14.0007C23.334 14.8057 23.2173 15.5873 23.0307 16.334M17.0223 22.8207C17.7223 21.5257 18.259 20.1257 18.6323 18.6673H22.074C20.9437 20.6136 19.1505 22.088 17.0223 22.8207ZM16.7307 16.334H11.2707C11.154 15.564 11.084 14.794 11.084 14.0007C11.084 13.2073 11.154 12.4257 11.2707 11.6673H16.7307C16.8357 12.4257 16.9173 13.2073 16.9173 14.0007C16.9173 14.794 16.8357 15.564 16.7307 16.334ZM14.0007 23.2873C13.0323 21.8873 12.2507 20.3357 11.7723 18.6673H16.229C15.7507 20.3357 14.969 21.8873 14.0007 23.2873ZM9.33398 9.33398H5.92732C7.04598 7.3824 8.83791 5.90572 10.9673 5.18065C10.2673 6.47565 9.74232 7.87565 9.33398 9.33398ZM5.92732 18.6673H9.33398C9.74232 20.1257 10.2673 21.5257 10.9673 22.8207C8.84238 22.0876 7.05297 20.613 5.92732 18.6673ZM4.97065 16.334C4.78398 15.5873 4.66732 14.8057 4.66732 14.0007C4.66732 13.1957 4.78398 12.414 4.97065 11.6673H8.91398C8.82065 12.4373 8.75065 13.2073 8.75065 14.0007C8.75065 14.794 8.82065 15.564 8.91398 16.334M14.0007 4.70232C14.969 6.10232 15.7507 7.66565 16.229 9.33398H11.7723C12.2507 7.66565 13.0323 6.10232 14.0007 4.70232ZM22.074 9.33398H18.6323C18.2672 7.88902 17.7265 6.4942 17.0223 5.18065C19.169 5.91565 20.954 7.39732 22.074 9.33398ZM14.0007 2.33398C7.54898 2.33398 2.33398 7.58398 2.33398 14.0007C2.33398 17.0948 3.56315 20.0623 5.75107 22.2502C6.83442 23.3336 8.12055 24.1929 9.53601 24.7792C10.9515 25.3656 12.4686 25.6673 14.0007 25.6673C17.0948 25.6673 20.0623 24.4382 22.2502 22.2502C24.4382 20.0623 25.6673 17.0948 25.6673 14.0007C25.6673 12.4686 25.3656 10.9515 24.7792 9.53601C24.1929 8.12055 23.3336 6.83442 22.2502 5.75107C21.1669 4.66772 19.8808 3.80836 18.4653 3.22206C17.0498 2.63575 15.5327 2.33398 14.0007 2.33398Z"
                      fill={stateData.iconsColor}
                    />
                  </svg>
                  <p>{socialData?.web}</p>
                </li>
              )}

              {/*LinkedIn link Dynamics*/}
              {socialData?.linkedin && (
                <li>
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.8573 23.8577H19.7086V17.3605C19.7086 15.8112 19.6809 13.8168 17.5509 13.8168C15.39 13.8168 15.0594 15.5048 15.0594 17.2477V23.8573H10.9108V10.4965H14.8935V12.3224H14.9493C15.3478 11.6409 15.9238 11.0802 16.6158 10.7001C17.3078 10.3201 18.0899 10.1348 18.8789 10.1641C23.0838 10.1641 23.8591 12.9299 23.8591 16.5282L23.8573 23.8577ZM6.22956 8.67022C4.89989 8.67044 3.82178 7.59266 3.82156 6.26299C3.82134 4.93332 4.89902 3.85521 6.22869 3.85499C7.55836 3.85466 8.63647 4.93244 8.63669 6.26212C8.6368 6.90065 8.38326 7.51308 7.93184 7.96469C7.48042 8.41629 6.8681 8.67008 6.22956 8.67022ZM8.30397 23.8578H4.15089V10.4965H8.30386V23.8577L8.30397 23.8578ZM25.9256 0.00203786H2.0662C0.938547 -0.0106496 0.0137813 0.892678 0 2.02033V25.9793C0.0133438 27.1075 0.938 28.0117 2.06609 27.9998H25.9256C27.0561 28.0138 27.9844 27.1096 28 25.9793V2.01848C27.9839 0.888632 27.0555 -0.0145871 25.9256 0.000178488"
                      fill={stateData.iconsColor}
                    />
                  </svg>
                  <p>{socialData?.linkedin}</p>
                </li>
              )}
              <li>
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.1667 13.9414C22.1667 11.6664 21.3741 9.73674 19.789 8.15241C18.2039 6.56807 16.2742 5.77552 14 5.77474V3.44141C15.4583 3.44141 16.8245 3.71868 18.0985 4.27324C19.3725 4.8278 20.4808 5.57641 21.4235 6.51907C22.3662 7.46174 23.1148 8.57007 23.6693 9.84407C24.2239 11.1181 24.5008 12.4839 24.5 13.9414H22.1667ZM17.5 13.9414C17.5 12.9692 17.1597 12.1428 16.4792 11.4622C15.7986 10.7817 14.9722 10.4414 14 10.4414V8.10807C15.6139 8.10807 16.9898 8.67702 18.1277 9.81491C19.2656 10.9528 19.8341 12.3283 19.8333 13.9414H17.5ZM23.275 24.4997C20.7667 24.4997 18.3213 23.9456 15.939 22.8372C13.5567 21.7289 11.4469 20.2511 9.60983 18.4039C7.77272 16.5567 6.29495 14.4419 5.1765 12.0596C4.05806 9.67724 3.49922 7.2323 3.5 4.72474V4.11224C3.5 3.89835 3.51945 3.69418 3.55833 3.49974H10.3833L11.4625 9.36224L8.1375 12.7164C8.95417 14.1164 9.98006 15.4289 11.2152 16.6539C12.4503 17.8789 13.8063 18.9386 15.2833 19.8331L18.6667 16.4497L24.5 17.6164V24.4414C24.3056 24.4609 24.1014 24.4756 23.8875 24.4857C23.6736 24.4959 23.4694 24.5005 23.275 24.4997Z"
                    fill={stateData.iconsColor}
                  />
                </svg>
                <p>{stateData?.phone}</p>
              </li>

              {/*Twitter Link Dynamics*/}

              {socialData?.twitter && (
                <li>
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4513_112198)">
                      <path
                        d="M21.4375 0H6.5625C2.93813 0 0 2.93813 0 6.5625V21.4375C0 25.0619 2.93813 28 6.5625 28H21.4375C25.0619 28 28 25.0619 28 21.4375V6.5625C28 2.93813 25.0619 0 21.4375 0Z"
                        fill={stateData.footerColor}
                      />
                      <path
                        d="M21.4375 0H6.5625C2.93813 0 0 2.93813 0 6.5625V21.4375C0 25.0619 2.93813 28 6.5625 28H21.4375C25.0619 28 28 25.0619 28 21.4375V6.5625C28 2.93813 25.0619 0 21.4375 0Z"
                        fill={stateData.iconsColor}
                      />
                      <path
                        d="M21.8282 9.99807C21.8402 10.1717 21.8402 10.3452 21.8402 10.5204C21.8402 15.8576 17.7772 22.013 10.3476 22.013V22.0097C8.15283 22.0129 6.00349 21.3843 4.15625 20.199C4.47541 20.2374 4.79609 20.2566 5.11766 20.2573C6.93674 20.2588 8.7035 19.6486 10.134 18.5249C9.29118 18.509 8.47438 18.2301 7.79783 17.7272C7.12127 17.2243 6.61877 16.5225 6.36059 15.72C6.96574 15.8367 7.5896 15.8129 8.18409 15.6505C6.29978 15.2697 4.94408 13.6141 4.94408 11.6913V11.6401C5.50582 11.953 6.13449 12.1264 6.7772 12.1457C5.00248 10.9595 4.45539 8.5984 5.52705 6.75237C6.5411 8.00023 7.8063 9.02081 9.24049 9.74782C10.6747 10.4748 12.2458 10.892 13.8517 10.9723C13.6916 10.283 13.7152 9.56384 13.9203 8.88661C14.1253 8.20938 14.5046 7.59786 15.0201 7.1132C16.647 5.58392 19.2056 5.66234 20.7349 7.28831C21.6394 7.10972 22.5068 6.77812 23.2998 6.30776C22.9983 7.24299 22.3673 8.03684 21.5242 8.54164C22.3249 8.4473 23.1069 8.23297 23.8438 7.90584C23.3017 8.71762 22.6191 9.42606 21.8282 9.99807Z"
                        fill={stateData.footerColor}
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4513_112198">
                        <rect width="28" height="28" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p>{socialData?.twitter}</p>
                </li>
              )}

              {/*Facebook Link Dynamics*/}
              {socialData?.facebook && (
                <li>
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.75 0C24.1962 0 25.4327 0.513455 26.4596 1.54036C27.4865 2.56727 28 3.80382 28 5.25V22.75C28 24.1962 27.4865 25.4327 26.4596 26.4596C25.4327 27.4865 24.1962 28 22.75 28H19.3229V17.1536H22.9505L23.4974 12.9245H19.3229V10.2266C19.3229 9.54601 19.4657 9.03559 19.7513 8.69531C20.0369 8.35503 20.5929 8.1849 21.4193 8.1849L23.6432 8.16667V4.39323C22.8776 4.28385 21.796 4.22917 20.3984 4.22917C18.7457 4.22917 17.424 4.71528 16.4336 5.6875C15.4431 6.65972 14.9479 8.03299 14.9479 9.80729V12.9245H11.3021V17.1536H14.9479V28H5.25C3.80382 28 2.56727 27.4865 1.54036 26.4596C0.513455 25.4327 0 24.1962 0 22.75V5.25C0 3.80382 0.513455 2.56727 1.54036 1.54036C2.56727 0.513455 3.80382 0 5.25 0H22.75Z"
                      fill={stateData.iconsColor}
                    />
                  </svg>
                  <p>{socialData?.facebook}</p>
                </li>
              )}
            </ul>
          </section>
        </div>
      </section>
    </FooterContainer>
  );
};

// Styles Components
const PoliticContainer = styled.section`
  position: relative;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  width: 100%;
  .main_content {
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;

    .heading {
      text-align: center;
      font-style: normal;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      gap: 0.7rem;

      h1 {
        font-size: 2rem;
        line-height: 60px;
        color: #2667b1;
      }
      p {
        font-size: 1rem;
        line-height: 27px;
      }
    }

    .data_fields,
    .main_data_input_container {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .data_field_input {
        display: grid;
        grid-template-columns: 2fr 1fr;
        align-items: flex-start;
        gap: 2rem;
      }

      .font_container {
        display: flex;
        gap: 1rem;
      }
    }

    .main_data_input_container {
      display: grid;
      grid-template-columns: 2fr 1fr;
      align-items: flex-start;
      .data_field_input_icon {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
      }

      .color_selection {
        display: flex;
        flex-direction: column;
        gap: 1.3rem;

        .hex_value {
          display: flex;
          gap: 1rem;

          span {
            width: 56px;
            height: 30px;
            border: 0.5px solid #0a0a0a;
            border-radius: 4px;
            text-align: center;
          }

          input {
            width: 100px;
            height: 30px;
            outline: none;
            border: none;
            border: 0.5px solid #0a0a0a;
            border-radius: 4px;
            text-align: center;

            &:hover,
            &:focus,
            &:active {
              outline: none;
              border: none;
            }
          }
        }

        .switcher {
          display: flex;
          align-items: center;
          gap: 1.2rem;

          span {
            display: block;
            cursor: pointer;
          }

          .span_line {
            border-bottom: 3px solid #2667b1;
          }
        }
      }
    }
  }
  .footer_container {
    h3 {
      padding-left: 1.2rem;
      font-weight: bold;
    }
  }

  .btn_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    .btn_next,
    .btn_back {
      max-width: 200px;
      height: 52px;
      border-radius: 8px;
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.2rem 3rem;
    }

    .btn_next {
      background: #2667b1;
      color: #ffffff;
    }

    .btn_back {
      border: 1px solid #2667b1;
      border-radius: 8px;
    }
  }

  @media screen and (max-width: 1024px) {
    .main_content {
      .data_fields {
        gap: 3rem;
        align-items: center;
        justify-content: center;

        .data_field_input {
          width: 100%;
          display: flex;
          flex-direction: column;

          .font_container {
            align-self: center;
          }
        }
      }
      .main_data_input_container {
        display: flex;
        flex-direction: column;

        .data_field_input_icon {
          width: 100%;
        }

        .color_selection {
          align-self: center;
        }
      }
    }
  }
`;

const InputFieldContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .input_field {
    border: 2px solid red;
    border: none;
    border-bottom: 1px solid #161518;
    width: 100%;
    font-size: 1.3rem;
    /* max-height: 48px; */

    &:focus,
    &:active {
      border: none;
      border-bottom: 1px solid #161518;
      outline: none;
    }

    &::placeholder {
      font-size: 1.3rem;
      color: #2b2a30;
    }
  }

  .count_preview_container {
    display: flex;
    gap: 1rem;
    align-items: center;

    .count_preview_err {
      color: red;
      font-size: 0.9rem;
    }
    .count_preview {
      color: #696969;
    }
  }
`;

const FontSizeContainer = styled.section`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  max-width: 132px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding: 10px;
  svg {
    font-size: 1.2rem;
    cursor: pointer;
  }

  input {
    max-width: 58px;
    text-align: center;
    font-size: 1.2rem;
  }
`;

const InputFieldIconContainer = styled.section`
  background: #ffffff;
  border: 1px solid #8c8c8c;
  border-radius: 8px;
  max-width: 500px;
  height: 48px;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;

  svg {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input {
    border: none;
    outline: none;
    height: 100%;
    width: 100%;
    padding-left: 10px;
    background-color: #ffffff;

    &:focus,
    &:active {
      border: none;
      outline: none;
      background-color: transparent;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }
  @media screen and (max-width: 1024px) {
    min-width: 100%;
  }
`;

const FooterContainer = styled.section`
  padding: 2rem 1.5rem;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : '')};

  .footer_wrapper {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    .footer_img {
      height: 100%;

      img {
        width: 100%;
        object-fit: cover;
        image-rendering: crisp-edges;
        border-radius: 20px;
      }

      .header_mobile {
        display: none;
        flex-direction: column;
        gap: 0.7rem;
        color: #fff;
        margin-bottom: 2rem;
        width: 100%;
        word-wrap: break-word;

        h2 {
          font-family: ${({ titleFontFamily }) =>
            titleFontFamily !== '' ? titleFontFamily : 'Nunito'} !important;
          font-size: ${({ titleFontSize }) =>
            titleFontSize
              ? titleFontSize / 2 / 16 + 'rem'
              : '1.5rem'} !important;
          font-style: normal;
          font-weight: 700;
          text-align: center;
        }
        p {
          font-family: ${({ subFontFamily }) =>
            subFontFamily !== '' ? subFontFamily : 'Sofia'} !important;
          font-size: ${({ subFontSize }) =>
            subFontSize ? subFontSize / 2 / 16 + 'rem' : '1rem'} !important;
          font-style: normal;
          font-weight: 400;
          align-self: center;
          text-align: center;
          width: 100%;
        }
      }
    }

    .footer_text {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      max-width: 700px;
      .header {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
        color: #fff;
        width: 100%;
        word-wrap: break-word;

        h2 {
          font-family: ${({ titleFontFamily }) =>
            titleFontFamily !== '' ? titleFontFamily : 'Nunito'} !important;
          font-style: normal;
          font-weight: 700;
          font-size: ${({ titleFontSize }) =>
            titleFontSize
              ? titleFontSize / 2 / 16 + 'rem'
              : '1.5rem'} !important;
          text-align: center;
        }
        p {
          font-family: ${({ subFontFamily }) =>
            subFontFamily !== '' ? subFontFamily : 'Sofia'} !important;
          font-style: normal;
          font-weight: 400;
          align-self: center;
          text-align: center;
          width: 100%;
          font-size: ${({ subFontSize }) =>
            subFontSize ? subFontSize / 2 / 16 + 'rem' : '1rem'} !important;
        }
      }

      .footer_details {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        color: #fff;
        .footer_detail_head {
          display: flex;
          gap: 1rem;
          align-items: center;
        }

        .footer_detail_list {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          gap: 1rem;

          li {
            display: flex;
            align-items: center;
            gap: 0.8rem;
            width: 100%;
            height: auto;
            word-break: break-all;
            p {
              max-width: 330px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .footer_wrapper {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .footer_img {
        .header_mobile {
          display: flex;
        }

        img {
          width: 40rem;
          object-fit: fill;
        }

        .cover-image {
          max-height: 100% !important;
        }
      }

      .footer_text {
        max-width: 100%;
        .header {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 675px) {
    .footer_wrapper {
      .footer_text {
        .footer_details {
          .footer_detail_list {
            li {
              align-self: flex-start;

              svg {
                min-width: 35px;
                min-height: 35px;
              }
            }
          }
        }
      }
    }
  }
`;

const FontFieldContainer = styled.section``;

export default PoliticsFooter;
