import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import {
  setPrevSmallMonthNumber,
  setNextSmallMonthNumber,
} from '../../../../store/features/smallMonthSlice';
import { setStartTime } from '../../../../store/features/timeSlice';
import { getMonth, getTime } from '../../../../util';
import { SideBarConatainer } from './styles/calendar.Styles';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import Button from '../utils/utilComponents/Button';
import ButtonColoured from '../utils/utilComponents/ButtonColored';
import { buttonBlueColor } from '../globalStyles/globalStyles.styles';
import SmallCalendar from './SmallCalendar';
import { setSmallMonthNumber } from '../../../../store/features/smallMonthSlice';
import { setShowEventModel } from '../../../../store/features/modalSlice';
import CoverBack from '../../../../assest/images/background_edit.png';
import { setInitialMonthArray } from '../../../../store/features/calendarSlice';
// import { AddMonth } from '../../../../store/reducers/bespokeView';
import {
  AddMonth,
  updateCustomColor,
} from '../../../../store/reducers/CurrentDraft';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import selectTemplate from '../../../../helpers/templateSelector';
import { useTranslation } from 'react-i18next';
import { loadCustomColorState } from '../../../../store/reducers/customColorState';
import client from '../../../../services';
import { toast } from 'react-toastify';
import DefaultCover from '../../../../assest/images/defaultCalendarCover.png';

const SideBar = ({ ColorLoader }) => {
  const { t } = useTranslation(['dashboard']);
  const navigate = useNavigate();
  const { yearPicked, months } = useSelector((state) => state.calendar);
  const [currentMonth, setCurrentMonth] = useState(getMonth(0, yearPicked));
  const dispatch = useDispatch();
  const smallDispatch = useDispatch();
  const { smallMonthNumber } = useSelector((state) => state.smallMonth);
  const draft = useSelector((state) => state.draft);
  // Save draft custom Colors  in a state
  const [customColors, setCustomColors] = useState(draft['customColors']);
  const [editable, setEditable] = useState(-1);
  const [labelValue, setLabelValue] = useState('');
  const [initColorVal, setInitColorVal] = useState('');
  const [editId, setEditId] = useState(0);
  const [oldLable, setOldLabel] = useState('');
  // To redirect to dasboard when array for mont is empty
  useEffect(() => {
    if (draft['months'].length <= 0) {
      navigate('/dashboard/create');
    }

    dispatch(
      loadCustomColorState({
        customColors: draft['customColors']?.length
          ? draft['customColors']
          : [],
      })
    );
  }, []);

  const { monthNumber } = useSelector((state) => state.month);
  let dateFormated = dayjs(new Date(dayjs().year(), smallMonthNumber)).format(
    'YYYY MMMM'
  );

  useEffect(() => {
    dispatch(setSmallMonthNumber(monthNumber));
  }, [monthNumber, dispatch]);

  useEffect(() => {
    setCurrentMonth(getMonth(smallMonthNumber, yearPicked));
  }, [smallMonthNumber]);

  const handlePreview = () => {
    navigate('/draft/view');
  };

  // Editing label
  const editLabel = (idx, event) => {
    setLabelValue(event);
    const newArr = customColors.map(
      ({ label, customId, color, id, isPresent }) => {
        if (customId === idx) {
          return {
            id,
            isPresent,
            customId: customId,
            color: color,
            label: event,
          };
        }
        return {
          id,
          isPresent,
          customId,
          color,
          label,
        };
      }
    );

    setCustomColors(newArr);
    dispatch(updateCustomColor(newArr));
    dispatch(
      loadCustomColorState({
        customColors: newArr,
      })
    );
  };

  // Stop Edit on Enter Pressed
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.NumpadEnter === 'Enter') {
      updateColorLabel();
      setEditable(-1);
    }
  };

  const updateColorLabel = async () => {
    ColorLoader(true);

    const data = {
      label: labelValue,
      id: editId,
    };

    if (data?.label !== '' && data?.label !== oldLable) {
      console.log(' In api /////');
      await client
        .put('/Souvenir/souvenir-customColor-update', data)
        .then((res) => {
          ColorLoader(false);
        })
        .catch((err) => {
          toast.error(err?.message);
          editLabel(initColorVal?.customId, initColorVal?.label);
          ColorLoader(false);
        });
    } else {
      ColorLoader(false);
      editLabel(initColorVal?.customId, initColorVal?.label);
      // toast.warning('Colour label cannot be empty');
    }
  };

  console.log(draft, ' This is Draft');

  return (
    <SideBarConatainer className="flex flex-col">
      <div className="preview">
        <ButtonColoured
          content={draft?.status === t('published') ? t('Preview') : t('Save')}
          color={buttonBlueColor}
          func={handlePreview}
        />
      </div>

      <div>
        <div className="month-move flex a-center">
          {monthNumber >= 1 && smallMonthNumber >= 1 && (
            <Button
              content={<FiChevronLeft />}
              func={() => smallDispatch(setPrevSmallMonthNumber())}
            />
          )}
          <p className="t-sm">{t(dateFormated.split(' ')[1])}</p>
          <p className="t-sm">{currentMonth[2][2].format('YYYY')}</p>
          {monthNumber <= 10 && smallMonthNumber <= 10 && (
            <Button
              content={<FiChevronRight />}
              func={() => smallDispatch(setNextSmallMonthNumber())}
            />
          )}
        </div>
        <SmallCalendar
          currentMonth={currentMonth}
          smallMonthNumber={smallMonthNumber}
        />
      </div>
      <div className="label-coverimage">
        <div className="check-event-container">
          {draft?.calendarPlan !== 'Basic' &&
            customColors?.map(
              ({ customId, color, label, id, isPresent }, index) => (
                <div className="check-event flex a-center" key={customId}>
                  <div className="flex instruct a-center">
                    {/*<input type="checkbox" name="public" />*/}

                    <div
                      className="colors"
                      style={{ backgroundColor: `${color}` }}
                    ></div>
                    <div className="editable">
                      {customId !== editable ? (
                        <p className="model t-sm" typeof="button">
                          {label}
                        </p>
                      ) : (
                        color !== '#FFCEEC' && (
                          <input
                            type="text"
                            onChange={(e) =>
                              editLabel(customId, e.target.value)
                            }
                            onKeyUp={handleKeyDown}
                            value={label}
                            autoFocus
                            className="editable_input"
                          />
                        )
                      )}
                    </div>
                  </div>

                  {customId !== editable ? (
                    color !== '#FFCEEC' ? (
                      <p
                        className="t-sm bold edit_done_text"
                        onClick={() => {
                          setEditable(customId);
                          setEditId(id);

                          setInitColorVal({
                            customId,
                            color,
                            label,
                            id,
                            isPresent,
                          });
                        }}
                      >
                        {t('Edit')}
                      </p>
                    ) : (
                      <p className="t-sm bold edit_done_text">{t('Default')}</p>
                    )
                  ) : (
                    <p
                      className="t-sm bold edit_done_text"
                      onClick={() => {
                        setOldLabel(label);
                        updateColorLabel();
                        setEditable(-1);
                      }}
                    >
                      {t('Done')}
                    </p>
                  )}
                </div>
              )
            )}
        </div>

        <div className="image-cover">
          <div className="img-container cover-img-con">
            {draft?.months.length > 0 && (
              <LazyLoadImage
                className="img-image"
                alt={'Image cover'}
                effect="blur"
                src={
                  !draft?.coverImg?.src ? DefaultCover : draft?.coverImg?.src
                }
              />
            )}
            {!draft?.coverImg?.src && (
              <div className="text-content">
                <h1>{draft?.yearPicked}</h1>
                <h1>Calendar</h1>
                <p>Stay on top of your schedule with ease</p>
              </div>
            )}
          </div>
          <div className="button-container">
            <button type="button" onClick={() => navigate('/dashboard/cover')}>
              {' '}
              {t('Change cover image')}{' '}
            </button>
          </div>
        </div>
      </div>
    </SideBarConatainer>
  );
};

export default SideBar;
