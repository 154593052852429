import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const FooterTvGuide = () => {
  const { t } = useTranslation(['dashboard']);

  const navigate = useNavigate();
  return (
    <FooterTvGuideConatainer>
      <div className="footer_content">
        <p> &#169; Copyright 2023. All Rights Reserved. </p>
        <div className="links">
          <p onClick={() => navigate('/privacy-policy')} className="linker">
            {t('Privacy Policy')}
          </p>
          <p onClick={() => navigate('/terms')} className="linker">
            {t('Terms and Conditions')}
          </p>
        </div>
      </div>
    </FooterTvGuideConatainer>
  );
};

export default FooterTvGuide;

const FooterTvGuideConatainer = styled.section`
  background-color: #172a69;
  width: 100%;

  padding: 1rem 0;

  .footer_content {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;

    p {
      font-size: 0.7rem;
    }

    .links {
      display: flex;
      align-items: center;
      gap: 2rem;

      .linker {
        cursor: pointer;
      }
      a {
        text-decoration: none;
        font-size: 0.7rem;
      }
    }
  }
`;
