import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { getMonth } from '../../../util';
import Dialog from '@material-ui/core/Dialog';
import ShareEvent from '../../component/shareEvent';
import { loadEventsGroup } from '../../../store/reducers/templateEvents';
import TemplateOneMonth from './component/templateOneMonth';
import getDaysInMonths from '../../component/bespoke-core/utils/utilComponents/getDaysInMonths';
import bespokeView, {
  addYearToBepoke,
} from '../../../store/reducers/bespokeView';
import YearLoading from '../YearLoading';
import client from '../../../services';
import dayjs from 'dayjs';
import LazyLoad from 'react-lazy-load';
import { getCountyCode, userGeolocation } from '../../../functions';

const BespokeViewTemplateOne = () => {
  let max = 2;
  let min = 0;
  var theme = Math.floor(Math.random() * (max - min + 1)) + min;
  const dispatch = useDispatch();

  const beskpoke = useSelector((state) => state.bespoke);
  const defaultImage = useSelector((state) => state.default);
  const [months, setMonths] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  // This is to get the days of the week
  const [week, setWeek] = useState([]);
  let allYear = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  // Group Event and Filtered Event on See More
  const eventsGroup = useSelector((state) => state.eventSlice);
  const [moreEvent, setMoreEvent] = useState([]);
  const [eventPopup, setEventPopup] = useState(false);
  const [popIndex, setPopIndex] = useState(0);
  const [shareOpen, setShareOpen] = useState(false);

  // Calendar scroll
  const [yearsInCalendar, setYearsInCalendar] = useState([]);
  const [loadingYear, setLoadingYear] = useState(false);
  let years = [];

  const view = useRef();
  const ref = useRef();

  // Styles

  // Number of years in bespoke
  let number_of_years_in_draft = beskpoke?.months.length / 12;

  let newDefaultImage = { ...defaultImage };

  // Retructure default images for calendar
  //  To accommodate for other years
  let count = 0;
  for (let i = 12; i <= beskpoke?.months.length; i++) {
    newDefaultImage[i] = defaultImage[count];
    count++;
    if (count > 11) {
      count = 0;
    }
  }

  console.log(beskpoke, ' Bespoke here');

  // Share Event
  const handleClickOpenShare = () => {
    setShareOpen(true);
  };
  const handleClose = () => {
    setShareOpen(false);
  };

  // Arrange bespoke Details
  const getBespoke = () => {
    setIsLoading(true);

    // Get week days
    let year = parseInt(beskpoke?.yearPicked);

    // GEt week days
    const weekObj = getMonth(0, year);
    setWeek(...weekObj);

    // Get all Calendar for the year
    let monthsInYear = allYear.map((val) => getMonth(val, year));
    let otherMonthsInYears = [];

    for (let i = 0; i < yearsInCalendar.length; i++) {
      let otherYear = parseInt(
        new Date(
          new Date().setFullYear(
            new Date(year.toString()).getFullYear() + (i + 1)
          )
        ).getFullYear()
      );

      let otherYearItem = allYear.map((val) => getMonth(val, otherYear));

      otherMonthsInYears = [...otherMonthsInYears, ...otherYearItem];
    }

    let arrangemonths = getDaysInMonths([
      ...monthsInYear,
      ...otherMonthsInYears,
    ]);

    setMonths(arrangemonths);

    let monthEventsGroup = beskpoke?.months.map(({ events }) => [events]);

    dispatch(loadEventsGroup(monthEventsGroup));
    setIsLoading(false);
  };

  // Get all year bresent in bespoke
  const saveAllPresentYear = () => {
    let allYear = [];
    for (const month of beskpoke?.months) {
      allYear.includes(dayjs(month?.date).format('YYYY'))
        ? null
        : allYear.push(dayjs(month?.date).format('YYYY'));
    }

    setYearsInCalendar(allYear);
  };

  useEffect(() => {
    saveAllPresentYear();

    getBespoke();
  }, [beskpoke?.months]);

  // Get new calendar on scroll
  const getNewCalendar = async (year) => {
    console.log(
      '.... In Func Call ',
      year,
      ' yearsInCalendar  ',
      yearsInCalendar
    );
    // yearsInCalendar.includes(yearToGet) === false
    setLoadingYear(true);

    let country = new userGeolocation().getUserCountry();
    let { CountryCode } = getCountyCode(country) || 'NGN';

    let bodyData = {
      colors: beskpoke?.customColors,
      country: CountryCode,
    };

    console.log(bodyData, ' Ti is body data ');
    await client
      .post(
        `/Souvenir/souvenirMonthsByYear/${beskpoke?.id}?year=${year}`,
        bodyData
      )
      .then((res) => {
        dispatch(addYearToBepoke(res.data));
        console.log(res.data, ' GET GET GET GET DATAT MOPNT YEARS');

        // Delete Year (Next year)
        years.push(year);

        setLoadingYear(false);
      })
      .catch((err) => {
        console.log(err, ' Error');
        setLoadingYear(false);
      });
  };

  useEffect(() => {
    let countObsevrver = 0;

    const observer = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];

      let dateVal = ref.current?.textContent;

      !years.includes(dateVal) ? years.push(dateVal) : null;

      let yearToGet = new Date(
        new Date().setFullYear(new Date(dateVal).getFullYear() + 1)
      )
        .getFullYear()
        .toString();

      console.log('HELLO IN HERE OBSERVER ', yearToGet);

      if (
        !years.includes(yearToGet) &&
        entry.isIntersecting === true &&
        countObsevrver >= 1
      ) {
        getNewCalendar(yearToGet);
      }

      countObsevrver = countObsevrver + 1;
    });

    observer.observe(view.current);
  }, []);

  return (
    <>
      <TemplateOneMonth
        popIndex={popIndex}
        setPopIndex={setPopIndex}
        isLoading={isLoading}
        eventPopup={eventPopup}
        setEventPopup={setEventPopup}
        moreEvent={moreEvent}
        setMoreEvent={setMoreEvent}
        week={week}
        setWeek={setWeek}
        months={months}
        beskpoke={beskpoke}
        defaultImage={newDefaultImage}
        theme={theme}
        eventsGroup={eventsGroup}
        handleClickOpenShare={handleClickOpenShare}
        clendarNumber={number_of_years_in_draft}
        ref={ref}
      />

      <h2 ref={view} style={{ visibility: 'hidden' }}>
        The End
      </h2>

      {loadingYear && <YearLoading />}
      {/* Share PopUp */}

      <Dialog
        open={shareOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ShareEvent data={moreEvent[popIndex]?.id} />
      </Dialog>
    </>
  );
};

export default BespokeViewTemplateOne;
