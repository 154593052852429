import React, { useState, useEffect } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import EventList from './eventList';
import Search from './search';
import Slider from './slider';
import Calendar from './calendar';
import Nav from './nav';
import './wallet.css';
import WalletDashboard from '../wallet-dashbaord/WalletDashboard';
import { BallTriangle } from 'react-loader-spinner';
import { loadCovers } from '../../store/reducers/fileReducer';
import { navDate } from '../../store/reducers/calendarReducer';
import client from '../../services';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    background: '#fff',
    padding: '20px',
    overflowY: 'hidden',
    overflowX: 'hidden',
    // height: "100%"
  },
  loader: {
    width: '100%',
    height: '100%',
  },
  scrollView: {
    height: '100%',
    overflowY: 'scroll',
  },
});

export default function Wallet() {
  const { t } = useTranslation(['dashboard']);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(true);
  const covers = useSelector((state) => state.files.covers);
  const classes = useStyles();

  // Load Bespoke calendars
  const loadCalendars = async () => {
    await client
      .get(`Souvenir/publishedsouvenir?souvenirStatus=publish`)
      .then((res) => {
        dispatch(loadCovers(res?.data));
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  // Load Event List
  const getEventList = async () => {
    console.log(' This is called now');
    setProcessing(true);
    let trimDate = dayjs().format('YYYY-MM');
    await client
      .post(`/Souvenir/eventlist?dateOnly=${trimDate}`)
      .then((res) => {
        dispatch(navDate(res?.data));
        setProcessing(false);
      })
      .catch((e) => {
        if (e.name === 'AbortError') console.log('Aborted sucessfully ');
        setProcessing(false);
      });
  };

  useEffect(() => {
    loadCalendars();
    getEventList();
  }, [loading]);

  return !loading & (covers?.length == 0) ? (
    <WalletDashboard />
  ) : loading ? (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.loader}
    >
      <Grid item xs={2} md={2} sm={2} lg={2}>
        <BallTriangle
          height={'100%'}
          width={'100%'}
          radius={5}
          color="#2667b1"
          ariaLabel="ball-triangle-loading"
          wrapperClass={{}}
          wrapperStyle=""
          visible={true}
        />
      </Grid>
    </Grid>
  ) : (
    <div className={classes.scrollView}>
      <div className={classes.root}>
        {/* <Search /> */}
        <Slider processing={processing} />
        <Nav processing={processing} setProcessing={setProcessing} />
        <Calendar processing={processing} setProcessing={setProcessing} />

        {/* Show Event cards only when page loads fully  */}
        {processing ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.loader}
          >
            <Grid item xs={2} md={2} sm={2} lg={2}>
              <BallTriangle
                height={'100%'}
                width={'100%'}
                radius={5}
                color="#2667b1"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            </Grid>
          </Grid>
        ) : (
          <EventList />
        )}
      </div>
    </div>
  );
}
