export const handleError = (formData) => {
  let isPass = true;
  let failValue = [];

  Object.entries(formData).forEach(([key, val]) => {
    console.log('val: ', val);
    if (val === '') {
      failValue.push(key);
      isPass = false;
    }
  });

  return isPass === true ? ['success'] : [isPass, failValue];
};
