import React from 'react';
import { useTranslation } from 'react-i18next';
import { GetSocialContainer, MainWrapper } from './Main.styles';
import BlueCicleMobile from '../../assest/images/lectie_vault_mobile.png';

import BlueCicle from '../../assest/images/home_vault_blue.png';

function BespokeCalendar() {
  const { t } = useTranslation(['bespoke-feature']);
  return (
    <>
      <div id="products"></div>

      <GetSocialContainer className="flex column home-fam">
        <div className="circle_design">
          <img src={BlueCicle} alt="Blue circle design" />
        </div>

        <div className="circle_design_mobile">
          <img src={BlueCicleMobile} alt="Blue circle design" />
        </div>
        <MainWrapper className="flex column">
          <div className="header_social">
            <h1>{t('Lectie magic')}</h1>

            <p>
              {t(
                `Unleash the power of shared experience and discover a new way to connect, learn, and inspire. With our innovative platform, you can effortlessly connect with others who share your interests, passions, and values.`
              )}
            </p>
          </div>
        </MainWrapper>
      </GetSocialContainer>
    </>
  );
}

export default BespokeCalendar;
