import React, { useState, useEffect } from 'react';
import { useUser } from '../../authenicationProvider';
import { googleLogout } from '@react-oauth/google';
import { getAuth, signOut } from 'firebase/auth';
import { NavLink, useNavigate } from 'react-router-dom';
import Logo from '../../assest/images/studio_fade_logo.svg';
import LogoSmall from '../../assest/images/logo-white.svg';
import Menu from '../../assest/images/menu.svg';
import { NavContainer } from './Nav.style';
import { FiX } from 'react-icons/fi';
import { HiChevronDown } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';

// Language
const languageList = [
  {
    symbol: 'en',
    language: 'English',
  },
  {
    symbol: 'fr',
    language: 'français',
  },
  {
    symbol: 'es',
    language: 'español',
  },
  {
    symbol: 'br',
    language: 'Portuguese',
  },
];

const Nav = ({ curls }) => {
  const { lang, setLang } = useUser();
  const [showDropDown, setShowDropDown] = useState(false);
  const { t } = useTranslation(['home']);
  let defualtLanValue = {
    symbol: 'en',
    language: 'English',
  };
  let langDropList = [];

  const { user, setUser } = useUser();
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const handleActiveLink = ({ isActive }) => {
    return {
      borderBottom: isActive ? '3px solid #2667B1' : '',
    };
  };
  const handleDesktopActiveLink = ({ isActive }) => {
    return {
      borderBottom: isActive ? '3px solid #ffffff' : '',
    };
  };

  const handleCloseMenu = () => {
    setShowMenu(false);
  };

  const logOut = () => {
    // console.log('heelo logout');
    // Facebook Signout
    if (user.type == 'fb') {
      window.FB.api('/me/permissions', 'delete', null, () =>
        window.FB.logout(function (response) {
          // user is now logged out
          // console.log(response, 'user status');
        })
      );
    }
    // Google Signout
    if (user.type == 'google') {
      googleLogout();
    }
    let tokenKey = 'auth_token';
    localStorage.removeItem(tokenKey);
    setUser(false);
    navigate('/');
  };

  // langDropList = languageList.filter(item => item.symbol !== lang )

  // Get List of Drop down
  languageList.forEach((val) => {
    // Dropdown Value
    if (val?.symbol !== lang && (val?.symbol !== 'en' || lang !== 'en-US')) {
      langDropList.push(val?.language);
    }
  });

  return (
    <>
      <span id="homeTop"></span>

      <NavContainer showMobile={showMenu} curls={curls}>
        <div className="main-content">
          <div className="left-side-nav">
            <div className="logo-container">
              <img src={Logo} alt="Lectie Logo" onClick={() => navigate('/')} />
            </div>
            <div className="logo-container-mobile">
              <img
                src={LogoSmall}
                alt="Lectie Logo"
                onClick={() => navigate('/')}
              />
            </div>
            <ul className="left-nav ">
              <li>
                <NavLink end to="/pricing" style={handleDesktopActiveLink}>
                  {t('Pricing')}
                </NavLink>
              </li>

              <li>
                <NavLink to="/contact" style={handleDesktopActiveLink}>
                  {t('Contact Us')}
                </NavLink>
              </li>

              <li>
                <NavLink to="/faq" style={handleDesktopActiveLink}>
                  {t('FAQs')}
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="right-side-nav">
            {!user ? (
              <>
                <button
                  type="button"
                  className="signup-button"
                  onClick={() => navigate('/login')}
                >
                  {t('Login/Sign up')}
                </button>

                {/* <button
                  type="button"
                  className="login-button"
                  onClick={() => navigate('/login')}
                >
                  Login
                </button>
            */}
              </>
            ) : (
              <button
                type="button"
                className="signup-button"
                onClick={() => navigate('/dashboard/')}
              >
                {t('Dashboard')}
              </button>
            )}

            <div
              className="translation"
              onClick={() => setShowDropDown((prevState) => !prevState)}
            >
              {
                {
                  'en-US': 'English',
                  en: 'English',
                  br: 'Portuguese',
                  es: 'español',
                  fr: 'français',
                }[lang]
              }

              <HiChevronDown />
            </div>

            {showDropDown && (
              <ul className="lang_dropdown">
                {langDropList.map((val, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setLang(
                        {
                          français: 'fr',
                          español: 'es',
                          Portuguese: 'br',
                          English: 'en',
                        }[val]
                      );

                      setShowDropDown(false);
                    }}
                  >
                    {val}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <img
            src={Menu}
            alt="Ham buger menu"
            className="ham-menu"
            onClick={() => setShowMenu(true)}
          />
        </div>

        {/* STRICTLY MOBILE*/}
        <section className="mobile_menu_section">
          <div className="mobile_menu__wrapper">
            <div className="logo-container__mobile">
              <img src={Logo} alt="Lectie Logo" />
            </div>
            <FiX onClick={() => setShowMenu(false)} />
          </div>
          <ul>
            <li onClick={() => handleCloseMenu()}>
              {' '}
              <NavLink end to="/pricing" style={handleActiveLink}>
                {t('Pricing')}
              </NavLink>
            </li>

            {user && (
              <li onClick={() => handleCloseMenu()}>
                <NavLink to="/dashboard/" style={handleActiveLink}>
                  {t('Dasboard')}
                </NavLink>
              </li>
            )}

            <li onClick={() => handleCloseMenu()}>
              <NavLink to="/contact" style={handleActiveLink}>
                {t('Contact Us')}
              </NavLink>
            </li>

            <li onClick={() => handleCloseMenu()}>
              <NavLink to="/faq" style={handleActiveLink}>
                {t('FAQs')}
              </NavLink>
            </li>

            {!user && (
              <>
                <li onClick={() => handleCloseMenu()}>
                  <NavLink to="/login" style={handleActiveLink}>
                    {t('Sign in')}
                  </NavLink>
                </li>
              </>
            )}

            {user && (
              <li
                typeof="button"
                onClick={() => {
                  handleCloseMenu();
                  logOut();
                }}
              >
                <p>{t('Log out')}</p>
              </li>
            )}
          </ul>
          <div className="translation_container">
            <div
              className="translation"
              onClick={() => setShowDropDown((prevState) => !prevState)}
            >
              {
                {
                  'en-US': 'English',
                  en: 'English',
                  br: 'Portuguese',
                  es: 'español',
                  fr: 'français',
                }[lang]
              }

              <HiChevronDown />
            </div>

            {showDropDown && (
              <ul className="lang_dropdown">
                {langDropList.map((val, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setLang(
                        {
                          français: 'fr',
                          español: 'es',
                          Portuguese: 'br',
                          English: 'en',
                        }[val]
                      );

                      setShowDropDown(false);
                      handleCloseMenu();
                    }}
                  >
                    {val}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </section>
      </NavContainer>
    </>
  );
};

export default Nav;
