import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MultiLine from '../../assest/images/muti_line.png';
import { useTranslation } from 'react-i18next';
import client from '../../services';
import Skeleton from '@material-ui/lab/Skeleton';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import {
  getCountyCode,
  getDigitInUnit,
  userGeolocation,
} from '../../functions';
import Nav from './Nav';
// import { userGeolocation } from '../../utils/UserTimeZone';

const Pricing = () => {
  const { t } = useTranslation(['common']);
  const [currency, setcurrency] = useState('USD');
  const [pricing, setPricing] = useState([]);
  const [loading, setIsLoading] = useState(false);

  // Load plans
  const getplans = async (country) => {
    var type = 'Yearly';
    setIsLoading(true);
    await client
      .get(`/Subscription/Subscription/${type}/${country}`)
      .then((res) => {
        setPricing([...res?.data?.subscriptions]);
        setcurrency(res?.data?.subscriptions[0]?.currency);
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error(e);
        setIsLoading(false);
      });
  };

  console.log(pricing, ' Price ');

  // Get default $ currency plan
  useEffect(() => {
    const country = new userGeolocation().getUserCountry();

    let { Code } = getCountyCode(country);

    getplans(Code);
  }, []);

  return (
    <>
      <Nav curls={true} />
      <PricingPageContainer>
        <section className="heading_section">
          <p className="price_paragraph">{t('Our Pricing')}</p>

          <div className="heading_container">
            <h1 className="pricing_heading">
              {t('Choose the')} <span>{t('plan')}</span> {t('that suits you')}
            </h1>
            <img
              src={MultiLine}
              alt="Muti underline"
              className="multi_line_img"
            />
          </div>

          {loading ? (
            <section className="pricing_card_grid">
              {[1, 2, 3].map((val) => (
                <Skeleton width={'100%'} height={'35rem'} />
              ))}
            </section>
          ) : (
            <section className="pricing_card_grid">
              {pricing.map(
                ({
                  id,
                  subscriptionName,
                  audience_Size,
                  calender_Number,
                  watermark,
                  calender_Hosting,
                  calender_Update,
                  embed_Widget,
                  reminder_notification,
                  socal_media_link_icon,
                  receipicent_share_event,
                  private_Calendar,
                  price,
                  currency,
                  free,
                }) => (
                  <div
                    className={`${
                      subscriptionName === 'Pro' ? 'card_item pro' : 'card_item'
                    }`}
                    key={id}
                  >
                    <div className="card_price">
                      <p className="price_type">{t(subscriptionName)}</p>
                      <p className="price">
                        {(currency === 'NGN' || currency === 'NG') &&
                        subscriptionName !== 'Basic'
                          ? currency
                          : free
                          ? ''
                          : currency}{' '}
                        {free ? t('Free') : getDigitInUnit(price)}
                      </p>
                    </div>

                    <div className="price_details">
                      <div className="detail_item">
                        <p>{t('Number of calendars')}</p>
                        <p>{calender_Number}</p>
                      </div>
                      <div className="detail_item">
                        <p>{t('Calendar hosting')}</p>
                        <p>{calender_Hosting ? t('Yes') : t('No')}</p>
                      </div>
                      <div className="detail_item">
                        <p>{t('Live calendar updates')}</p>
                        <p>{calender_Update ? t('Yes') : t('No')}</p>
                      </div>
                      <div className="detail_item">
                        <p>{t('Widget to embed on website')}</p>
                        <p>{embed_Widget ? t('Yes') : t('No')}</p>
                      </div>
                      <div className="detail_item">
                        <p>{t('Size of audience')}</p>
                        <p>{audience_Size > 2000 && 'Unlimited'}</p>
                      </div>
                      <div className="detail_item">
                        <p>{t('Lectie watermark on website')}</p>
                        <p>
                          {subscriptionName === 'Basic'
                            ? 'N/A'
                            : watermark
                            ? t('Yes')
                            : t('No')}
                        </p>
                      </div>
                      <div className="detail_item">
                        <p>{t('Reminder notifications for events')}</p>
                        <p>
                          {}{' '}
                          {subscriptionName === 'Premium'
                            ? `>${reminder_notification}`
                            : `${reminder_notification}`}
                        </p>
                      </div>

                      <div className="detail_item">
                        <p>{t('Private calendar')}</p>
                        <p>{private_Calendar ? t('Yes') : t('No')}</p>
                      </div>

                      <div className="detail_item">
                        <p>{t('Add social media linked Icons')}</p>
                        <p>{socal_media_link_icon ? t('Yes') : t('No')}</p>
                      </div>
                      <div className="detail_item">
                        <p>
                          {t('Calendar recipients can share event invites')}
                        </p>
                        <p>{receipicent_share_event ? t('Yes') : t('No')}</p>
                      </div>
                    </div>
                  </div>
                )
              )}
            </section>
          )}

          <a
            href="https://studio.lectie.com/signup"
            target="_blank"
            rel="noreferrer"
            className="btn_started"
          >
            {t('LEARN MORE')}
          </a>
        </section>
      </PricingPageContainer>
    </>
  );
};

export default Pricing;

const PricingPageContainer = styled.div`
  max-width: 1440px;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 5rem;
  margin-top: 9rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  .heading_section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    .price_paragraph {
      color: rgba(38, 103, 177, 1);
    }
    .heading_container {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: center;
      .pricing_heading {
        font-style: normal;
        font-weight: 500;
        font-size: 2rem;

        span {
          font-style: normal;
          font-weight: 500;
          font-size: 2rem;
          color: rgba(38, 103, 177, 1);
        }
      }
    }
    .currency_select {
      width: 70%;
      max-width: 100px;
    }

    .btn_started {
      color: #fff;
      font-size: 1.2rem;
      border: none;
      outline: none;
      padding: 12px;
      width: 250px;
      height: 52px;
      margin: 0 auto;
      background: #2667b1;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
    }
  }

  .pricing_card_grid {
    width: 90%;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    grid-column-gap: 1rem;
    grid-row-gap: 1.5rem;

    .pro {
      color: #ffffff;
      background-color: rgba(67, 171, 249, 1);

      .price_details {
        .detail_item {
          border-top: 1px solid #ffffff !important;
        }
      }
    }
    .card_item {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      align-items: center;
      border: 2px solid rgba(67, 171, 249, 1);
      padding-top: 2rem;
      padding-bottom: 2rem;
      align-self: center;

      .card_price {
        display: flex;
        flex-direction: column;
        align-items: center;
        .price_type {
          font-size: 1.2rem;
        }
        .price {
          font-size: 1.7rem;
        }
      }

      .price_details {
        display: flex;
        flex-direction: column;
        width: 90%;

        .detail_item {
          border-top: 1px solid #000000;
          padding: 0.5rem 0;
          display: flex;
          justify-content: space-between;
          gap: 1.5rem;
        }
      }
    }
  }

  .pricing_btn {
    background: #2667b1;
    border-radius: 12px;
    width: 200px;
    height: 45px;
    color: #fff;
    align-self: center;
  }

  @media screen and (max-width: 490px) {
    .pricing_heading {
      text-align: center;
    }
    .multi_line_img {
      display: none;
    }
  }
`;
