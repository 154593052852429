import React, { useEffect } from 'react';
import { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import client from '../../services';
// import Footer from '../home/body/Footer';
import Nav from '../Home/Nav';
import { RotatingLines } from 'react-loader-spinner';
import { Button, makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Footer from '../components/Footer';

// Form styles
const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: '#2667b1',
  },
}));
const Contact = () => {
  const { t } = useTranslation(['common']);

  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loading, setLoading] = useState(false);
  const [formData, setFormdata] = useState({
    email: '',
    subject: '',
    message: '',
  });

  const hanldeSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await client
      .post('/Account/usercontact', formData)
      .then((res) => {
        setLoading(false);
        toast.success(t('Message sent Successfully'));
        setFormdata({
          email: '',
          subject: '',
          message: '',
        });
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  };
  return (
    <>
      <Nav curls={true} />
      <ToastContainer />
      <div className="bg-white dark:bg-white">
        <div className="py-2 lg:py-2 px-4 mx-auto max-w-screen-md">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-[#0A0A0A] dark:text-[#0A0A0A]">
            {t('Contact Us')}
          </h2>
          <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-600 sm:text-lg">
            {t(
              'Fill out the form below and our contact personnel would get in touch within 24 Hours.'
            )}
          </p>
          <form
            action="#"
            className="space-y-8"
            onSubmit={(e) => hanldeSubmit(e)}
          >
            <div>
              <label
                for="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700"
              >
                {t('Email')}
              </label>
              <input
                type="email"
                id="email"
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-700 dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="name@flowbite.com"
                value={formData.email}
                onChange={(e) =>
                  setFormdata({
                    ...formData,
                    email: e.target.value,
                  })
                }
                required
              />
            </div>
            <div>
              <label
                for="subject"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700"
              >
                {t('Subject')}
              </label>
              <input
                type="text"
                id="subject"
                className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder={t('Let us know how we can help you')}
                value={formData.subject}
                onChange={(e) =>
                  setFormdata({
                    ...formData,
                    subject: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="sm:col-span-2">
              <label
                for="message"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700"
              >
                {t('Message')}
              </label>
              <textarea
                id="message"
                rows="6"
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder={t('Leave a comment...')}
                value={formData.message}
                required
                onChange={(e) =>
                  setFormdata({
                    ...formData,
                    message: e.target.value,
                  })
                }
              ></textarea>
            </div>

            <Button
              startIcon={
                <RotatingLines
                  strokeColor="#fff"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={loading}
                />
              }
              className={cx([
                classes.btn,
                'py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-[#2667B1] sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800',
              ])}
              variant="contained"
              color="primary"
              style={{ textTransform: 'none' }}
              type="submit"
            >
              {loading ? t('Sending') : t('Send Message')}
            </Button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
