import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { Link, NavLink } from 'react-router-dom';
import { MainLayoutContainer } from './MainLayout.style';
import MainLayoutHeader from './MainLayoutHeader';
import Wallet from '../assest/images/wallet.svg';
import Profile from '../assest/images/profile-big.svg';
import DraftIcon from '../assest/images/draft_icon.svg';
import PasswordIcon from '../assest/images/password_icon.svg';
import CreateIcon from '../assest/images/create_icon.svg';
import Help from '../assest/images/help-big.svg';
import Logout from '../assest/images/logout-big.svg';
import Logo from '../assest/images/logo-big.svg';
import LightMode from '../assest/images/light-mode.svg';
import { useEffect, useState } from 'react';
import { setShowStudioMenu } from '../store/features/studioSlice';
import { setShowWalletDashbaordModel } from '../store/features/modalSlice';

import { useUser } from '../authenicationProvider';
import { googleLogout } from '@react-oauth/google';
import { Badge } from '@material-ui/core';
import { NotificationsActive } from '@material-ui/icons';
import { getAuth, signOut } from 'firebase/auth';
import { useTranslation } from 'react-i18next';

const MainLayout = () => {
  const { t } = useTranslation(['dashboard']);
  const { setUser } = useUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showMenu } = useSelector((state) => state.studio);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(setShowStudioMenu(false));
    dispatch(setShowWalletDashbaordModel(false));
  }, [dispatch]);

  const handleCloseMenu = () => {
    dispatch(setShowStudioMenu(false));
  };
  const logOut = () => {
    // Facebook Signout
    if (user.type == 'fb') {
      const auth = getAuth();
      signOut(auth)
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    }

    // Google Signout
    if (user.type == 'google') {
      googleLogout();
    }
    let tokenKey = 'auth_token';
    localStorage.removeItem(tokenKey);
    localStorage.removeItem('mobileAuth');
    setUser(false);
  };

  return (
    <MainLayoutContainer sideBarMobile={showMenu}>
      <MainLayoutHeader />
      <div className="main-contaner">
        <div className="sidebar">
          <div className="top">
            <img
              src={Logo}
              alt="Lectie logo"
              className="logo"
              onClick={() => navigate("/")}
            />
            <p>
              {t("Welcome")} {user.organisationName || user.firstName}
            </p>
          </div>
          <hr />
          {showMenu === false && (
            <nav className="uppper-nav">
              <ul>
                <li>
                  <NavLink to="/dashboard/create">
                    <img src={CreateIcon} alt="Create icon" />
                    <p>{t("Create")}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink end to="/dashboard/">
                    <img src={Wallet} alt="Wallet icon" />
                    <p>{t("Vault")}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/dashboard/draft/list">
                    <img src={DraftIcon} alt="Draft icon" />
                    <p>{t("Drafts")}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="profile">
                    <img src={Profile} alt="Profile icon" />
                    <p>{t("Profile")}</p>
                  </NavLink>
                </li>

                <li>
                  <NavLink to="help">
                    <img src={Help} alt="FAQ icon" />
                    <p>{t("Help")}</p>
                  </NavLink>
                </li>

                {(user?.type === "email" || user?.type === "default") && (
                  <li>
                    <NavLink to="pwd-reset">
                      <img src={PasswordIcon} alt="FAQ icon" />
                      <p>{t("Reset Password")}</p>
                    </NavLink>
                  </li>
                )}

                <li>
                  <NavLink to="Subscription">
                    <img src={Help} alt="Subscription icon" />
                    <p>{t("Subscription Plan")}</p>
                  </NavLink>
                </li>
              </ul>

              <div className="logout" onClick={() => logOut()}>
                <img src={Logout} alt="Logout icon" />
                <p>{t("Logout")}</p>
              </div>
            </nav>
          )}

          {/* MOBILE SCREEN MENU*/}
          {showMenu === true && (
            <nav className="show-nav">
              <div className="notification">
                <img
                  src={Logo}
                  alt="Lectie logo"
                  className="logo"
                  onClick={() => navigate("/")}
                />
              </div>

              <ul>
                <li typeof="button" onClick={() => handleCloseMenu()}>
                  <NavLink to="/dashboard/create">
                    <img src={CreateIcon} alt="Create icon" />
                    <p>{t("Create")}</p>
                  </NavLink>
                </li>
                <li typeof="button" onClick={() => handleCloseMenu()}>
                  <NavLink end to="/dashboard/">
                    <img src={Wallet} alt="Wallet icon" />
                    <p>{t("Vault")}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/dashboard/draft/list"
                    onClick={() => handleCloseMenu()}
                  >
                    <img src={DraftIcon} alt="Draft icon" />
                    <p>{t("Drafts")}</p>
                  </NavLink>
                </li>
                <li typeof="button" onClick={() => handleCloseMenu()}>
                  <NavLink to="profile">
                    <img src={Profile} alt="Profile icon" />
                    <p>{t("Profile")}</p>
                  </NavLink>
                </li>
                <li typeof="button" onClick={() => handleCloseMenu()}>
                  <NavLink to="help">
                    <img src={Help} alt="FAQ icon" />
                    <p>Help</p>
                  </NavLink>
                </li>
                {(user?.type === "email" || user?.type === "default") && (
                  <li onClick={() => handleCloseMenu()}>
                    <NavLink to="pwd-reset">
                      <img src={PasswordIcon} alt="FAQ icon" />
                      <p>{t("Reset Password")}</p>
                    </NavLink>
                  </li>
                )}
                <li onClick={() => handleCloseMenu()}>
                  <NavLink to="Subscription">
                    <img src={Help} alt="Subscription icon" />
                    <p>{t("Subscription Plan")}</p>
                  </NavLink>
                </li>
              </ul>

              <div className="logout" onClick={() => logOut()}>
                <img src={Logout} alt="Logout icon" />
                <p>{t("Logout")}</p>
              </div>
            </nav>
          )}
        </div>
        <div className="outlet">
          <Outlet />
        </div>
      </div>
    </MainLayoutContainer>
  );
};

export default MainLayout;
