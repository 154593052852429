import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  eventsGroup: [],
};

export const eventsSlice = createSlice({
  name: 'eventsSlice',
  initialState,
  reducers: {
    loadEventsGroup: (state, { payload }) => {
      return {
        ...state,
        eventsGroup: payload,
      };
    },
  },
});

export const { loadEventsGroup } = eventsSlice.actions;

export default eventsSlice.reducer;
