import styled from 'styled-components';

export const HelpContainter = styled.div`
  width: 75vw;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 40px;

  .notification {
    display: ${({ success }) => (success ? 'block' : 'none')};
    position: fixed;
    top: 25px;
    right: 100%;
    background-color: #19857b;
    color: #fff;
    padding: 1rem 2.5rem;
    border-radius: 8px;
    animation: slidein 1s ease-in-out;

    @keyframes slidein {
      from {
        right: 0px;
      }
      to {
        right: 25px;
      }
    }
  }

  .main-container {
    width: 95%;
    margin: 0 auto;
    .heading {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      text-align: left;

      h2 {
        font-size: 1.5rem;
      }
      p {
        font-size: 0.9rem;
        font-weight: 400;
      }
    }

    .help-info {
      margin-top: 1.4375rem;
      display: flex;
      gap: 6.75rem;
      align-items: center;
      justify-content: center;
      .img-container {
        width: 13rem;
        img {
          width: 100%;
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 15px;
        li {
          font-weight: 400;
          text-align: left;
          font-size: 1rem;
        }
      }
    }

    .form-heading {
      text-align: center;
      margin-top: 3.5rem;
      h4 {
        font-weight: 700;
        font-size: 1.2rem;
      }

      p {
        font-size: 0.8rem;
        font-weight: 400;
      }
    }

    form {
      width: 70%;
      margin: 0 auto;
      margin-top: 1.6875rem;
      display: flex;
      flex-direction: column;

      gap: 2.125rem;
      .input-container {
        width: 100%;
        margin: 0 auto;
        p {
          font-size: 0.9rem;
          text-align: left;
        }
        input,
        textarea {
          outline: none;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 6px;
          gap: 12px;
          width: 100%;
          background: #ffffff;
          border: 1px solid #161616;
          border-radius: 8px;
          font-size: 0.9rem;

          &::placeholder {
            color: #d3d3d3;
          }
        }
        textarea {
          height: 8.625rem;
        }
      }

      .button-container {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-top: 1rem;
        button {
          border: none;
          color: #fff;
          max-width: 270px;
          width: 100%;
          height: 47px;
          background: #2667b1;
          border-radius: 12px;
          cursor: pointer;
        }
      }

      /* GENERAL FORM STYLE ERROR */

      .error-msg {
        color: #f83f23;
        font-weight: 400;
        font-size: 0.76rem;
        line-height: 20px;
        width: 100%;
        display: block;
        text-align: left;
      }

      .input-container {
        .border-err {
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 8px;
          border: 1px solid #f89687;

          &:focus {
            border: 1px solid #f89687;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
              0px 0px 0px 4px #ffd3cc;
            border-radius: 8px;
          }
        }
      }
    }
  }
  /* Laptop Screen */
  @media screen and (max-width: 1024px) {
    width: 70vw;
  }

  /* Mobile Screen */
  @media screen and (max-width: 610px) {
    margin-top: 1rem;
    width: 100vw;

    .main-container {
      width: 90%;
      .help-info {
        justify-content: space-between;

        .img-container {
          width: 60%;
        }
        ul {
          width: 70%;
        }
      }

      form {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 290px) {
    .help-info {
      flex-direction: column;
      gap: 10px;
      justify-content: center;

      .img-container {
        width: 100%;
        margin-top: 40px;
        margin-bottom: -50px;
      }
      ul {
        width: 100%;
      }
    }
  }
`;
