import styled from 'styled-components';
const bgPrimary = '#2d78c3';
export const ToggleUserContainer = styled.div`
  h2 {
    color: #2667b1;
    text-align: center;
    font-size: 1rem;
  }

  .toggle-user {
    display: flex;
    background-color: ${bgPrimary};
    max-width: 245px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    margin-top: 0.5rem;
    border-radius: 99px;

    p {
      padding: 4px 20px;
      padding-top: 6px;
      color: #fff;
      font-size: 0.7rem;
      cursor: pointer;
      width: 100%;
      width: 50%;
    }

    p:nth-child(1) {
      background-color: ${({ toggle }) => (toggle ? '' : '#1e1e1e')};
      border-radius: 99px;
    }

    p:nth-child(2) {
      background-color: ${({ toggle }) => (toggle ? '#1e1e1e' : '')};
      border-radius: 99px;
    }
  }
`;
