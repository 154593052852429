import React, { useState, useCallback, useEffect } from 'react';
import { UpgradePlansContainer } from './style/upgrade.style';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Dialog,
} from '@material-ui/core';
import { BallTriangle } from 'react-loader-spinner';
import 'react-phone-number-input/style.css';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import {
  cancelSubscription,
  loadUser,
  profileImageUpdate,
  profileUpdate,
} from '../../store/reducers/userReducer';
// const { userDetails } = useSelector((state) => state.user);
import { useTranslation } from 'react-i18next';
import ChooseUpgrade from '../subscription/chooseUpgrade';
import client from '../../services';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getCountyCode, userGeolocation } from '../../functions';

// Form styles
const useStyles = makeStyles((theme) => ({
  TextInput: {
    borderRadius: 10,
    width: '100%',
  },
}));

// console.log('UDetails ', userDetails)

const UpgradePlans = () => {
  let Ucurrency = '';

  const { t } = useTranslation(['dashboard']);
  const [loading, setLoading] = useState(true);
  const [subProcessing, setSubProcessing] = useState(false);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { souvId, subName } = state;
  const [userCurrency, setUserCurrency] = useState('');
  const [userLocation, setUserLocation] = useState('');

  const classes = useStyles();

  // console.log("State it ", souvId);

  const [open, setOpen] = React.useState(false);
  const [openTemplate, setOpenTemplate] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [upgradedSubscriptions, setUpgradedSubscriptions] = useState();

  const [calendarQuantity, setCalendarQuantity] = useState({
    Basic: 1,
    Pro: 1,
    Premium: 1,
  });

  // Loading State
  const [processing, setProcessing] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const handleCloseTemplate = () => {
    setOpenTemplate(false);
    setLoading(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenTemplate = (openTemplate) => {
    setOpenTemplate(openTemplate);
    setIsOpen(!isOpen);
  };

  // Fetch Upgradable Subscription
  const upGradableSubscriptions = async () => {
    setSubProcessing(true);
    const currentUserLocation = new userGeolocation().getUserCountry();

    let { CountryCode, Code } = getCountyCode(currentUserLocation) || 'NGN';

    await client
      .get(`/Subscription/fetchupgradedSubscription/${subName}/${Code}`)
      .then((res) => {
        console.log('Res is upgrade plan == ', res?.data);
        let subData = res.data.subscriptions;
        setUserLocation(CountryCode);
        setUserCurrency(Code);
        setUpgradedSubscriptions(subData);

        console.log('Res is ', subData);
        setLoading(false);
      })
      .catch((error) => {
        // toast.error(error.response?.data?.Message ?? error?.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    // Get the user's IP address to determine their approximate location
    upGradableSubscriptions();
  }, []);

  return (
    <UpgradePlansContainer>
      <div className="main-wrap ">
        <h2 className="text-center font-extrabold font-body my-8 text-xl">
          {t('Available plan')}
        </h2>

        {loading ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.loader}
          >
            <Grid item xs={2} md={2} sm={2} lg={2}>
              <BallTriangle
                height={'100%'}
                width={'100%'}
                radius={5}
                color="#2667b1"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            </Grid>
          </Grid>
        ) : (
          <div>
            {upgradedSubscriptions &&
              upgradedSubscriptions.map((item, id) => (
                <div className="mx-auto text-center p-6 mb-6 shadow-md shadow-[#407bff47] max-w-[700px] rounded-2xl">
                  <div className="text-center">
                    <h2
                      className="font-body font-extrabold"
                      style={{ fontWeight: 'bold' }}
                    >
                      {item.subscriptionName} Plan
                    </h2>
                    <h2
                      className="font-body font-extrabold"
                      style={{ fontWeight: 'bold' }}
                    >
                      {item.currency}&nbsp;{item.price}
                    </h2>
                    <p className="text-sm font-body">
                      Subscriptions are billed annually
                    </p>
                  </div>
                  <div class="flex justify-center mt-6 divide-x divide-[#AFAFAF]    font-body">
                    <div className="pr-4 ">
                      <div className=" flex space-x-4 py-4 font-body">
                        <p className="text-xs ">Calendar hosting</p>
                        <p className="text-xs">
                          {item.calender_Hosting ? 'Yes' : 'No'}
                        </p>
                      </div>
                      <div className="flex space-x-4 py-4 ">
                        <p className="text-xs">Live calendar updates</p>
                        <p className="text-xs text-left">
                          {item.calender_Update ? 'Yes' : 'No'}
                        </p>
                      </div>
                      <div className=" flex space-x-4 py-4  font-body">
                        <p className="text-xs ">Widget to embed on website</p>
                        <p className="text-xs">
                          {' '}
                          {item.embed_Widget ? 'Yes' : 'No'}
                        </p>
                      </div>
                      <div className="flex space-x-4 py-4 ">
                        <p className="text-xs">Size of audience</p>
                        <p className="text-xs text-left">Unlimited</p>
                      </div>
                      <div className="flex space-x-4 py-4">
                        <p className="text-xs">Lectie watermark on website</p>
                        <p className="text-xs text-left">
                          {' '}
                          {item.watermark ? 'Yes' : 'No'}
                        </p>
                      </div>
                    </div>
                    <div className="pl-4 ">
                      <div className=" flex space-x-4 py-4 pr-4 font-body">
                        <p className="text-xs ">Reminder Notification</p>
                        <p className="text-xs">{item.reminder_notification}</p>
                      </div>

                      <div className=" flex space-x-4 py-4 pr-4 font-body">
                        <p className="text-xs ">Private calendar</p>
                        <p className="text-xs">
                          {item.prevate_calendar ? 'Yes' : 'No'}
                        </p>
                      </div>

                      <div className="flex space-x-4 py-4 ">
                        <p className="text-xs">Add social media linked Icons</p>
                        <p className="text-xs text-left">
                          {' '}
                          {item.socal_media_link_icon ? 'Yes' : 'No'}
                        </p>
                      </div>
                      <div className=" flex space-x-4 py-4  font-body">
                        <p className="text-xs ">
                          Calendar recipients can share event invites
                        </p>
                        <p className="text-xs">
                          {' '}
                          {item.receipicent_share_event ? 'Yes' : 'No'}
                        </p>
                      </div>
                      <div className="flex space-x-4 py-4 ">
                        <p className="text-xs">Calendar Number</p>
                        <p className="text-xs text-left">
                          {item.calender_Number}
                        </p>
                      </div>
                      <div className="flex space-x-4 py-4">
                        <p className="text-xs">Free</p>
                        <p className="text-xs text-left">
                          {' '}
                          {item.free ? 'Yes' : 'No'}
                        </p>
                      </div>
                    </div>
                  </div>
                  <Button
                    variant="contained"
                    // color="primary"
                    style={{
                      textTransform: 'none',
                      background: '#2667B1',
                      color: '#fff',
                      fontSize: 12,
                      padding: '0 64px',
                      marginTop: 30,
                    }}
                    onClick={() => {
                      navigate('/dashboard/upgrade-summary', {
                        state: {
                          plan: item.subscriptionName,
                          id: souvId,
                          subscriptionSelected: item,
                          userLocation: userLocation,
                          userCurrency: userCurrency,
                          calendarQuantity,
                        },
                      });
                    }}
                  >
                    Select
                  </Button>{' '}
                </div>
              ))}
          </div>
        )}
      </div>

      {/* Modal component  */}
    </UpgradePlansContainer>
  );
};

export default UpgradePlans;
