import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useLayoutEffect,
} from 'react';
import { makeStyles, TextField, Box, InputAdornment } from '@material-ui/core';
import FacebookIcon from '@mui/icons-material/Facebook';
import Language from '@mui/icons-material/Language';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useDispatch, useSelector } from 'react-redux';
import '../create-page/style/create.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useDropzone } from 'react-dropzone';
import moment from 'moment';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { generate } from '../../store/reducers/CurrentDraft';
import { useLocation, useNavigate } from 'react-router-dom';
import { setInitialMonthArray } from '../../store/features/calendarSlice';
import { handleError } from '../create-page/CreatePageService';
import Switch from 'react-switch';
import 'react-lazy-load-image-component/src/effects/blur.css';

import '../component/bespoke-core/calendarComponent/styles/uploadModal.css';
import '../component/bespoke-core/calendarComponent/styles/uploadModal.css';
import { useTranslation } from 'react-i18next';
import InviteSetting from './InviteSetting';
import {
  CreateSettingContainer,
  UpdateSettingContainer,
} from './UpdateSetting.style';
import client from '../../services';
import TemplateErrorPage from '../template/TemplateErrorPage';
import TemplateLoading from '../template/TemplateLoading';
import { loadCustomColorState } from '../../store/reducers/customColorState';
import { RotatingLines } from 'react-loader-spinner';
import { getCountyCode, userGeolocation } from '../../functions';

const useStyles = makeStyles((theme) => ({
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(1)',
    },
    '25%': {
      transform: 'scale(1.03)',
    },
    '50%': {
      transform: 'scale(1.06)',
    },
    '75%': {
      transform: 'scale(1.07)',
    },
    '100%': {
      transform: 'scale(1.1)',
    },
  },

  root: {
    background: '#fff',
    padding: '20px',
  },
  drop: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  preview: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  loader: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  loaderSkeleton: {
    height: '18rem',
  },
  covers: {
    marginTop: 20,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  imageList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  nextCon: {
    marginTop: 10,
  },
  reactSwitch: {
    verticalAlign: 'middle',
    marginLeft: '4px',
  },
  next: {
    background: '#2667B1',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2666B1',
    },
  },
  TextInput: {
    borderRadius: 10,
    width: '100%',
    height: 50,
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      '& fieldset': {
        border: '2px solid #2667b1',
      },
      '&.Mui-focused fieldset': {
        border: '2px solid #2667b1',
      },
    },
  },
  TextInputSocial: {
    borderRadius: 10,
    width: '100%',
    paddingLeft: '50px',
    paddingRight: '50px',
    marginBottom: 15,
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      height: 50,
      '& fieldset': {
        border: '1px solid #000000',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #2667b1',
      },
    },
  },

  floatBtn: {
    position: 'fixed',
    bottom: '100px',
    right: '3%',
    zIndex: '1000px',
    backgroundColor: '#2667B1',
    width: '4rem',
    height: '4rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',

    boxShadow: '0 0 0.5rem rgba(38, 103, 177,0.3)',
    animation: '$ripple 1.2s infinite ease-in-out',
  },

  floatBtnSvg: { fontSize: '1.5rem', color: '#ffffff' },
}));

const UpdateSettings = () => {
  const { t } = useTranslation(['dashboard']);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { id } = state ?? {};
  const { user } = useSelector((state) => state);
  const [processing, setProcessing] = useState(false);

  console.log('State found is ', state);

  const [image, setImage] = useState(false);
  const [title, setTitle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [facebook, setFacebook] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [twitter, setTwitter] = useState('');
  const [web, setWeb] = useState('');
  const [addShare, setAddShare] = useState(false);
  const [weberrorMsg, setWebErrorMsg] = useState('');
  const [fberrorMsg, setFbErrorMsg] = useState('');
  const [linkedinerrorMsg, setLinkedinErrorMsg] = useState('');
  const [twittererrorMsg, setTwitterErrorMsg] = useState('');

  const [template, setTemplate] = useState('0');

  const [bespokeData, setBespokeData] = useState({});
  const [lectieInvitees, setLectieInvitees] = useState([]);
  const [pictures, setPictures] = useState([]);
  const [checkedShare, setCheckedShare] = useState(bespokeData?.share);
  const [calendarType, setCalendarType] = useState({
    calender: bespokeData?.calender,
    lectieUsers: [],
  });

  const [error, setError] = useState();

  const classes = useStyles();

  // Crop Image
  const [crop, setCrop] = useState();

  const { templateId, templateName, yearPicked, slogan, coverImg, status } =
    bespokeData;
  let saveDate = new Date(yearPicked).getFullYear();
  let saveTitle = templateName;
  let cover = coverImg?.src;

  // Fetch bespoke Details
  const getBespoke = async (id) => {
    setLoading(true);

    let country = new userGeolocation().getUserCountry();

    let { CountryCode } = getCountyCode(country) || 'NGN';

    await client
      .get(`/Souvenir/${id}?country=${CountryCode}`)
      .then((res) => {
        setBespokeData(res.data);
        if (res?.data.templateId !== '4') {
          // Template Id 4 has no custom color
          dispatch(
            loadCustomColorState({
              customColors: res?.data?.customColors,
            })
          );
        }

        console.log(' This is Get Bespoke ---- ');
        //Get template from res
        //if template is empty
        // then fall back to template 0
        setTemplate(res?.data?.templateId ? res?.data?.templateId : '0');
        setLoading(false);
      })
      .catch((e) => {
        console.log('Error is ', e);
        setError(true);
        setLoading(false);
      });
    // }
  };

  // Souvenir Update
  const souvenirUpdate = async () => {
    // setLoading(true);
    setProcessing(true);

    const data = {
      id: id,
      calender: bespokeData?.calender,
      share: checkedShare ?? false,
      templateName: bespokeData?.templateName,
      templateId: bespokeData?.templateId,
      yearPicked: bespokeData?.yearPicked,
      slogan: bespokeData?.slogan ?? '',
      web: bespokeData?.web ?? '',
      twitter: bespokeData?.twitter,
      calendarPlan: bespokeData?.calendarPlan,
      linkedin: bespokeData?.linkedin ?? '',
      facebook: bespokeData?.facebook ?? '',
      calender: bespokeData?.calender ?? '',
      customColors: bespokeData.customColors ?? [],
      lectieUsers: [
        {
          firstName: user?.firstName,
          userName: user?.userName,
        },
      ],
    };

    console.log(data, ' THIs is data =====');
    await client
      .put(`/Souvenir/souvenirSpecificUpdate`, data)
      .then((res) => {
        toast.success('Settings Updated Successfully');

        navigate('/dashboard', {
          state: {
            ...calendarType,
            saveDate,
            saveTitle: templateName,
            cover,
            // navRoute,
            templateId,
            // templatePlan,
            socialData: {
              linkedin,
              twitter,
              facebook,
              web,
              checkedShare,
            },
          },
        });

        setProcessing(false);
      })
      .catch((e) => {
        console.log(e);
        toast.error(e?.response?.data?.Message ?? e?.message);
        setProcessing(false);
      });
  };

  console.log('BData ', bespokeData);

  useEffect(() => {
    // dispatch(reloadState());
    getBespoke(id);
    window.scrollTo(0, 0);
  }, [id]);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleShareChange = (nextChecked) => {
    console.log(nextChecked, ' NEXT CHECKED ');
    setCheckedShare(nextChecked);
  };
  const handleAddShare = (nextChecked) => {
    setAddShare(nextChecked);
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = async () => {
        // setLoading(true);
        // Send Content to server
        const binaryStr = reader.result;
        setTemImg(binaryStr);
        setCrop(undefined); // Set an immediate crop region
        handleClickOpen();
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.png'],
    },
  });

  const goBack = () => {
    navigate(-1);
  };

  function validURL(str) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  }

  // Fetch Lectie Invitees
  const fetchLectieInvitees = async () => {
    await client
      .get(`/Account/lectie-invitees?SouvenirId=${id}`)
      .then((res) => {
        let resultArr = res?.data;
        setLectieInvitees(res?.data);
        // setLoading(false);

        const pictures = resultArr
          .map((user) => user.picture)
          .filter((picture) => picture !== null);

        setPictures(pictures);

        console.log('Pictures found are ', pictures);
      })
      .catch((error) => {
        toast.error(error.response?.data?.Message ?? error?.message);
        // setLoading(false);
      });
  };

  useEffect(() => {
    fetchLectieInvitees();
  }, [id]);

  const next = () => {
    if (!validURL(web.trim()) && web.trim() !== '') {
      setWebErrorMsg('Not a valid url');
      return;
    }
    if (!validURL(linkedin.trim()) && linkedin.trim() !== '') {
      setLinkedinErrorMsg('Not a valid url');
      return;
    }
    if (!validURL(facebook.trim()) && facebook.trim() !== '') {
      setFbErrorMsg('Not a valid url');
      return;
    }
    if (!validURL(twitter.trim()) && twitter.trim() !== '') {
      setTwitterErrorMsg('Not a valid url');
      return;
    }

    const formData = {
      date: saveDate,
      title: saveTitle,
      slogan: slogan,
      image: cover,
    };
    if (saveDate && cover && saveTitle) {
      dispatch(setInitialMonthArray(saveDate));
      let months = [];
      let dateStart = moment(saveDate);
      let dateEnd = moment(saveDate).add(12, 'month');
      while (dateEnd.diff(dateStart, 'months') >= 1) {
        let month = {
          date: dateStart.toDate(),
          background: '',
          events: [],
        };

        months.push(month);
        dateStart.add(1, 'month');
      }
      let bespoke = {
        // id: new Date().getUTCMilliseconds(),
        ...calendarType,
        templateName: saveTitle,
        yearPicked: dayjs(saveDate).format('YYYY'),
        slogan: slogan,
        coverImg: {
          id: 0,
          src: cover,
          coverType: 0,
        },
        months: months,
        share: checkedShare,
        web: web.trim() || '',
        twitter: twitter.trim() || '',
        linkedin: linkedin.trim() || '',
        facebook: facebook.trim() || '',
        customColors: [
          {
            id: 1,
            color: '#FFDAC0',
            label: 'Birthday',
            isPresent: true,
          },
          {
            id: 2,
            color: '#FFEDAC',
            label: 'Anniversary ',
            isPresent: true,
          },
          { id: 3, color: '#FFCEEC', label: 'Holiday', isPresent: true },
          { id: 4, color: '#BAFFC1', label: 'Birthday', isPresent: true },
          { id: 5, color: '#B4DFFF', label: 'Birthday', isPresent: true },
          { id: 6, color: '#FDB8B8', label: 'Birthday', isPresent: true },
        ],
        templateId: templateId,
        // calendarPlan: templatePlan,
      };

      console.log('Bespokee is ', bespoke);

      dispatch(generate(bespoke));
      if (templateId === 3) {
        return navigate('/studio/cover');
      }
      return navigate('/studio/event');
    } else {
      // Handle Error
      let result = handleError(formData);
      for (const el of result[1]) {
        switch (el) {
          case 'date':
            setDate(true);
            break;

          case 'image':
            setImage(true);
            break;

          case 'title':
            setTitle(true);
            break;

          default:
            return 'err';
        }
      }
    }
  };

  return !error ? (
    loading ? (
      <TemplateLoading />
    ) : (
      <UpdateSettingContainer className={classes.root}>
        <div className="text-center">
          <h1 className="text-[#2667B1] text-xl py-2">{t('Settings')}</h1>
        </div>

        <section className="public-option-section">
          <div className="option-section">
            <div
              style={{
                margin: '0 auto',
                backgroundColor: '#43ABF9',
                padding: '20px',
              }}
            >
              <div className="flex justify-between items-center">
                <p className="text-sm text-white">
                  {t('Receiver can also share this calendar')}
                </p>

                <Switch
                  onChange={handleShareChange}
                  checked={checkedShare}
                  className="reactSwitch"
                  onColor="#2667B1"
                  onHandleColor="#ffffff"
                  handleDiameter={10}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={15}
                  width={40}
                  id="material-switch"
                />
              </div>
              <p className="text-xs text-white w-3/4 py-2">
                {t(
                  'Clicking on this will also allow users to share this calendar with their friends.'
                )}
              </p>
            </div>
            {addShare && (
              <Box
                sx={{
                  margin: '0 auto',
                  border: '1px solid #43ABF9',
                  padding: '20px',
                }}
                className=""
              >
                <div className="flex justify-between items-center">
                  <h2 className="font-semibold">
                    {t('Add your social media link')}
                  </h2>
                </div>
                <p className="mb-4">
                  {t('This will be visible on your calendar')}
                </p>
                <TextField
                  id="input-with-icon-textfield"
                  className={classes.TextInputSocial}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Language style={{ fill: '#43ABF9' }} />{' '}
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  placeholder="https://www.website.com"
                  error={weberrorMsg}
                  helperText={weberrorMsg === '' ? '' : weberrorMsg}
                  onChange={(e) => setWeb(e.target.value)}
                />
                <TextField
                  id="input-with-icon-textfield"
                  className={classes.TextInputSocial}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TwitterIcon style={{ fill: '#1D9BF0' }} />{' '}
                      </InputAdornment>
                    ),
                  }}
                  placeholder="https://bepoke.studio/twitter"
                  error={twittererrorMsg}
                  helperText={twittererrorMsg === '' ? '' : twittererrorMsg}
                  variant="outlined"
                  onChange={(e) => setTwitter(e.target.value)}
                />
                <TextField
                  id="input-with-icon-textfield"
                  className={classes.TextInputSocial}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkedInIcon style={{ fill: '#0A66C2' }} />{' '}
                      </InputAdornment>
                    ),
                  }}
                  placeholder="https://bepoke.studio/linkedin"
                  error={linkedinerrorMsg}
                  helperText={linkedinerrorMsg === '' ? '' : linkedinerrorMsg}
                  variant="outlined"
                  onChange={(e) => setLinkedin(e.target.value)}
                />
                <TextField
                  id="input-with-icon-textfield"
                  className={classes.TextInputSocial}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FacebookIcon style={{ fill: '#43ABF9' }} />{' '}
                      </InputAdornment>
                    ),
                  }}
                  placeholder="https://bepoke.studio/facebook"
                  error={fberrorMsg}
                  helperText={fberrorMsg === '' ? '' : fberrorMsg}
                  variant="outlined"
                  onChange={(e) => setFacebook(e.target.value)}
                />
              </Box>
            )}{' '}
          </div>

          {bespokeData?.calendarPlan !== 'Basic' && (
            <InviteSetting
              setCalendarType={setCalendarType}
              status={status}
              id={id}
              lectieInvitees={lectieInvitees}
              pictures={pictures}
            />
          )}
        </section>

        <div className="text-center mt-8">
          <button
            type="button"
            className="text-white bg-[#2667B1] hover:bg-[#2667B1]/55 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-9 py-1 mr-4 mb-2 dark:bg-[#2667B1] dark:hover:bg-[#2667B1] focus:outline-none"
            onClick={() => {
              souvenirUpdate();
            }}
          >
            {t('Save')}{' '}
            {
              <RotatingLines
                strokeColor="#fff"
                strokeWidth="5"
                animationDuration="0.75"
                width="20"
                visible={processing}
              />
            }
          </button>

          <button
            type="button"
            className="text-white bg-[#2667B1] hover:bg-[#2667B1]/55 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-9 py-1 mr-4 mb-2 dark:bg-[#2667B1] dark:hover:bg-[#2667B1] focus:outline-none"
            onClick={() => {
              navigate('/dashboard');
            }}
          >
            {t('Back to dashboard')}{' '}
          </button>
        </div>
      </UpdateSettingContainer>
    )
  ) : (
    <TemplateErrorPage />
  );
};

export default UpdateSettings;
