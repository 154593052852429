import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { BallTriangle } from 'react-loader-spinner';
import Skeleton from '@material-ui/lab/Skeleton';
import { FiLink } from 'react-icons/fi';
import { FaLocationDot } from 'react-icons/fa6';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useSelector, useDispatch } from 'react-redux';
import { FiClock, FiCheck } from 'react-icons/fi';
import { BiMenuAltLeft } from 'react-icons/bi';
import { buttonBlueColor } from '../globalStyles/globalStyles.styles';
import ButtonColoured from '../utils/utilComponents/ButtonColored';
import TimeModal from './TimeModal';
import MonthModal from './MonthModal';

import AutoComplete from 'react-google-autocomplete';

import {
  setShowEventModel,
  setShowMonthModel,
  setShowTimeModel,
} from '../../../../store/features/modalSlice';
import {
  setCalendarEvent,
  setCalendarEditEvent,
  setSelectedEvent,
  setDeleteEvent,
  setDeleteEventRecurring,
} from '../../../../store/features/calendarSlice';
import { colorArr } from './service/colorService';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import { setEndTime, setStartTime } from '../../../../store/features/timeSlice';
import { useDropzone } from 'react-dropzone';
import LazyLoad from 'react-lazy-load';
import { toast } from 'react-toastify';
import { TimePicker, DatePicker } from '@material-ui/pickers';
import client from '../../../../services';
import ReactDatePicker from 'react-datepicker';
// import "react-datetime/css/react-datetime.css";

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(advanced);

import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {
  canvasPreview,
  createEvent,
  extractImageFileExtensionFromBase64,
  updateEvent,
  UploadImageToS3,
} from '../../../../functions';
import { useDebounceEffect } from './useDebounceEffect';
// import uuid from 'react-uuid';
import { RotatingLines } from 'react-loader-spinner';
import { BsFillCloudUploadFill } from 'react-icons/bs';
import './styles/uploadModal.css';
import Dialog from '@material-ui/core/Dialog';
import { BsArrowRepeat } from 'react-icons/bs';
import { BiChevronDown } from 'react-icons/bi';
import { seizer } from '../../../../functions';
import { useTranslation } from 'react-i18next';
import LocationMark from './LocationMark';

// Material styles
const useStyles = makeStyles((theme) => ({
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(1)',
    },
    '25%': {
      transform: 'scale(1.03)',
    },
    '50%': {
      transform: 'scale(1.06)',
    },
    '75%': {
      transform: 'scale(1.07)',
    },
    '100%': {
      transform: 'scale(1.1)',
    },
  },
  loader: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  loaderSkeleton: {
    height: '7rem',
  },
  floatBtn: {
    position: 'fixed',
    bottom: '100px',
    right: '3%',
    zIndex: '1000px',
    backgroundColor: '#2667B1',
    width: '4rem',
    height: '4rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    boxShadow: '0 0 0.5rem rgba(38, 103, 177,0.3)',
    animation: '$ripple 1.2s infinite ease-in-out',
  },

  floatBtnSvg: { fontSize: '1.5rem', color: '#ffffff' },
}));
// editOption to know if form Event
// should be for edit or for new form insertion
// Sent from event model

// setNewEventObject is a props pass down from event model
// To allow use passing the event object from this EventForm

let custVal = 1000;

const EventForm = ({
  editOption,
  handleUpdatePopup,
  setNewEventObject,
  setOccurVal,
  setIsFormLoading,
  formLoading,
}) => {
  let vURL; // For video URL Check
  const { t } = useTranslation(['dashboard']);
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state?.user);

  const [dateClicked, setDateClicked] = useState(null);
  const { selectedDate } = useSelector((state) => state.startEventModal);
  const { showTime, showMonth } = useSelector((state) => state.calendarModal);
  const customColorState = useSelector((state) => state.customColorState);

  const { selectedEvent } = useSelector((state) => state.calendar);
  const [showEditor, setShowEditor] = useState(false);
  const [timeClicked, setTimeClicked] = useState(null);

  // Google location place state
  const [location, setLocation] = useState(
    selectedEvent !== '' ? selectedEvent['location'] : ''
  );

  let defaultReminder = [
    {
      id: 1,
      name: 'minutes',
      value: 5,
      inMinutes: 5,
      selected: false,
    },
    {
      id: 2,
      name: 'minutes',
      value: 10,
      inMinutes: 10,
      selected: false,
    },
    {
      id: 3,
      name: 'minutes',
      value: 15,
      inMinutes: 15,
      selected: false,
    },
    {
      id: 4,
      name: 'minutes',
      value: 30,
      inMinutes: 30,
      selected: false,
    },
    {
      id: 5,
      name: 'hour',
      value: 1,
      inMinutes: 60,
      selected: false,
    },
    {
      id: 6,
      name: 'day',
      value: 1,
      inMinutes: 60 * 24,
      selected: false,
    },
    {
      id: 7,
      name: 'week',
      value: 1,
      inMinutes: 60 * 24 * 7,
      selected: false,
    },
    {
      id: 8,
      name: 'month',
      value: 1,
      inMinutes: 60 * 24 * 30,
      selected: false,
    },
    {
      id: 9,
      name: 'Custom',
      value: 1,
      inMinutes: 60 * 24 * 30,
      selected: false,
    },
  ];
  let [reminders, setReminders] = useState([...defaultReminder]);

  const [openReminder, setOpenReminder] = useState(false);

  // Crop Image
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [aspect, setAspect] = useState(16 / 9);
  const imgRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [temImg, setTemImg] = useState(null);

  // const [reminder, setReminder] = useState(30);
  const [timePicked, setTimePicked] = useState([
    {
      id: 5,
      name: 'minutes',
      value: 30,
      inMinutes: 30,
      selected: true,
    },
  ]);

  const [inputError, setInputError] = useState(false);

  // Retrieve Bespoke details
  const userDetails = useSelector((state) => state.user);

  const beskpoke = useSelector((state) => state.bespoke);
  const userId = beskpoke?.userId;
  const souvenirId = beskpoke?.id;

  // Custom Colors from draft
  const draft = useSelector((state) => state.draft);

  // Reoccuring popup
  const [isOpenReoccur, setIsOpenReOccur] = useState(false);

  // Set Image state Depends on the bove selectedEvent State
  const [image, setImage] = useState(selectedEvent['image']);
  const [loading, setLoading] = useState(false);
  const [customOpen, setCustomOpen] = useState(false);
  const [customPick, setCustomPick] = useState('');
  const [numberPicked, setNumberPicked] = useState('');
  const [checked, setChecked] = useState(false);

  const [customId, setCustomId] = useState(
    selectedEvent !== '' ? selectedEvent['labelId'] : 0
  );

  // Form values,
  // Gotten from selectedState in Global State
  // Only if there is a selected event set the
  // event state values
  const [title, setTitle] = useState(
    selectedEvent !== '' ? selectedEvent['title'] : ''
  );

  const [startDate, setStartDate] = useState(
    selectedEvent !== '' ? selectedEvent['startDate'] : selectedDate
  );

  // To check if start date is changed
  const [oldStartDate] = useState(startDate);

  const [endDate, setEndDate] = useState(
    selectedEvent !== '' ? selectedEvent['endDate'] : selectedDate
  );

  const [link, setLink] = useState(
    selectedEvent !== '' ? selectedEvent['link'] : ''
  );

  // Get current time
  let modStartDate = dayjs(startDate)
    .set('hour', dayjs(new Date()).get('h'))
    .set('minute', dayjs(new Date()).get('minutes'))
    .set('second', dayjs(new Date()).get('second'));
  let modEndDate = dayjs(endDate)
    .set('hour', dayjs(new Date()).get('h') + 1)
    .set('minute', dayjs(new Date()).get('minutes'))
    .set('second', dayjs(new Date()).get('second'));

  if (
    selectedEvent.covervideo === '' ||
    selectedEvent.covervideo === undefined
  ) {
    vURL = '';
  } else {
    vURL = `https://www.youtube.com/watch?v=${selectedEvent['covervideo']}`;
  }

  // firstTime and lastTime needs to be declare
  // underneth modeStartDate and modEndDate
  // --- Do not move ---
  const [firstTime, setFirstTime] = useState(
    selectedEvent !== '' ? selectedEvent['startTime'] : modStartDate
  );

  const [lastTime, setLastTime] = useState(
    selectedEvent !== '' ? selectedEvent['endTime'] : modEndDate
  );

  const [videoURL, setVideoURL] = useState(vURL);

  const [remindersState, setRemindersState] = useState(
    selectedEvent !== '' ? selectedEvent['reminders'] : []
  );

  const [description, setDescription] = useState(
    selectedEvent !== '' ? selectedEvent['description'] : ''
  );

  const [colored, setColor] = useState(
    selectedEvent !== '' ? selectedEvent['color'] : colorArr[0]['color']
  );

  const [id, setId] = useState(selectedEvent !== '' ? selectedEvent['id'] : '');

  // Reoccuring Values
  const [reoccuring, setReoccuring] = useState(
    selectedEvent !== '' ? selectedEvent?.repeat : 'Do Not Repeat'
  );

  const [preReoccuring] = useState(reoccuring);

  const [repeatedId] = useState(
    selectedEvent !== '' ? selectedEvent?.commonId : ''
  );

  function validateYouTubeUrl(urlToParse) {
    if (urlToParse) {
      var regExp =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      if (urlToParse.match(regExp)) {
        return true;
      }
    }
    return false;
  }

  function YouTubeGetID(url) {
    var ID = '';
    url = url
      .replace(/(>|<)/gi, '')
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      ID = url[2].split(/[^0-9a-z_\-]/i);
      ID = ID[0];
    } else {
      ID = url;
    }
    return ID;
  }

  // Set the update date
  useEffect(() => {
    // Set event details if event is not an edited event
    if (selectedEvent == '') {
      setFirstTime(
        dayjs(startDate)
          .set('hour', dayjs(new Date()).get('h'))
          .set('minute', dayjs(new Date()).get('minutes'))
          .set('second', dayjs(new Date()).get('second'))
      );
      setLastTime(
        dayjs(endDate)
          .set('hour', dayjs(new Date()).get('h') + 1)
          .set('minute', dayjs(new Date()).get('minutes'))
          .set('second', dayjs(new Date()).get('second'))
      );
    } else {
      setFirstTime(
        dayjs(startDate)
          .set('hour', dayjs(new Date(firstTime)).get('h'))
          .set('minute', dayjs(new Date(firstTime)).get('minutes'))
          .set('second', dayjs(new Date(firstTime)).get('second'))
      );
      setLastTime(
        dayjs(endDate)
          .set('hour', dayjs(new Date(lastTime)).get('h'))
          .set('minute', dayjs(new Date(lastTime)).get('minutes'))
          .set('second', dayjs(new Date(lastTime)).get('second'))
      );
    }
    setOccurVal(reoccuring);
  }, [startDate, endDate]);

  // Avoiding Duplicate reminder
  const setEventDefaultReminder = (eventReminderParam) => {
    let eventReminder = [];
    for (const item of defaultReminder) {
      let test = false;
      for (const evt of eventReminderParam) {
        if (
          `${item?.name} ${item?.inMinutes}` ===
          `${evt?.name} ${evt?.inMinutes}`
        ) {
          test = true;
        }
      }
      if (!test) {
        eventReminder.push(item);
      }
    }

    setReminders([...eventReminder]);
  };

  useEffect(() => {
    const newArr = selectedEvent?.reminders?.map((v) => {
      if (v.selected === undefined) {
        return {
          ...v,
          id: v?.remindersId,
          selected: true,
        };
      }
      return v;
    });

    if (selectedEvent.reminders) {
      setTimePicked([...newArr]);
      setEventDefaultReminder(selectedEvent?.reminders);
    } else {
      setEventDefaultReminder(timePicked);
    }
  }, []);

  const handleCheckChange = () => {
    setChecked(!checked);
  };

  const optionChange = (e) => {
    setCustomPick(e.target.value);
  };
  const inputChange = (e) => {
    setNumberPicked(e.target.value);
  };

  // Calculate custom reminder
  const calculateCustom = (time, duration) => {
    let custObj = {
      id: custVal,
      name: duration,
      value: time,
      inMinutes: 15,
      selected: true,
    };
    custVal++;
    setTimePicked((current) => [...current, custObj]);
    setEventDefaultReminder([...timePicked, custObj]), setOpenReminder(false);

    let customObj = {
      id: custVal,
      name: 'custom',
      value: parseInt(time),
      inMinutes: parseInt(time),
      selected: true,
    };

    if (duration === 'minutes') {
      customObj.name = duration;
      customObj.value = parseInt(time);
      customObj.inMinutes = parseInt(time);
    }
    if (duration === 'hours') {
      customObj.name = duration;
      customObj.value = time;
      customObj.inMinutes = time * 60;
    }
    if (duration === 'days') {
      customObj.name = duration;
      customObj.value = time;
      customObj.inMinutes = time * 60 * 24;
    }
    if (duration === 'weeks') {
      customObj.name = duration;
      customObj.value = time;
      customObj.inMinutes = time * 60 * 24 * 7;
    }
    reminders.push(customObj);
    setCustomOpen(!customOpen);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Delete Event from Backend
  const deleteEvent = async (eventId) => {
    setIsFormLoading(true);
    await client
      .delete(`/event/deleteBespokeEvent?eventId=${eventId}`)
      .then((res) => {
        console.log(' Event Deletig single event...');
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Message ?? err?.message);
      });
  };

  // Submission of form (All data must be present)
  const handleSubmit = async (e) => {
    e.preventDefault();
    let remindersArr = [];

    //Generate id if id is not present

    const idx = id !== '' ? id : '00000000-0000-0000-0000-000000000000';

    let monthDate = dayjs(selectedDate).format('YYYY-M');

    // public event Update Endpoint values
    const eventId = idx;
    const bespokeId = draft?.id;

    const remindMe = timePicked.map((evt) => ({ ...evt, selected: true }));

    // Check if the videoURL is a valid Youtube URL
    const eventObj = {
      date: monthDate,
      background: 'test',
      events: [
        {
          id: idx,
          userID: user?.id,
          title: title,
          startDate: dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss'),
          endDate: dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss'),
          startTime: dayjs(firstTime).format('YYYY-MM-DDTHH:mm:ssZ'),
          endTime: dayjs(lastTime).format('YYYY-MM-DDTHH:mm:ssZ'),
          description: description,
          link: link,
          covervideo: videoURL,
          color: colored,
          image: image ? image : '',
          commonId: repeatedId,
          repeat: reoccuring,
          prevRepeat: preReoccuring,
          reminderTime: 0,
          reminders: [...remindMe],
          labelId: customId,
          location: location,
        },
      ],
    };
    console.log(eventObj, ' This is event Object ... ');

    // Checker edit conditions
    if (!editOption) {
      setIsFormLoading(true);
      await client
        .post(`/event/CreatePublicEvents/${bespokeId}`, eventObj?.events[0])
        .then((res) => {
          console.log(
            res.data,
            ' Creation take place == ',
            eventObj?.events[0]
          );
          dispatch(
            setCalendarEvent({ ...eventObj, events: [{ ...res?.data }] })
          );
          dispatch(setShowEventModel(false));
          setIsFormLoading(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.Message ?? err?.message);
          setIsFormLoading(false);
        });
    } else if (
      editOption === true &&
      (reoccuring === 'Do Not Repeat' ||
        reoccuring === '' ||
        reoccuring === null) &&
      !eventObj?.events[0]?.commonId
    ) {
      // call dispatch
      const callDispatch = () => {
        dispatch(setCalendarEditEvent(eventObj));
        dispatch(setShowEventModel(false));
      };

      // call update
      updateEvent(
        eventId,
        bespokeId,
        eventObj?.events[0],
        callDispatch,
        setIsFormLoading
      );
    } else if (
      (reoccuring !== 'Do Not Repeat' ||
        reoccuring !== '' ||
        reoccuring !== null) &&
      oldStartDate !== eventObj?.events[0]?.startDate &&
      eventObj?.events[0]?.commonId
    ) {
      dispatch(
        setDeleteEventRecurring({
          evtId: eventObj?.events[0]?.commonId,
          evtDate: eventObj?.events[0]?.startDate,
        })
      );

      setIsFormLoading(true);
      const dataSet = {
        ...eventObj,
        events: [{ ...eventObj?.events[0], commonId: '' }],
      };

      await client
        .post(`/event/CreatePublicEvents/${bespokeId}`, eventObj?.events[0])
        .then((res) => {
          dispatch(
            setCalendarEvent({ ...dataSet, events: [{ ...res?.data }] })
          );
          dispatch(setShowEventModel(false));
          setIsFormLoading(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.Message ?? err?.message);
          setIsFormLoading(false);
        });
    } else if (
      (reoccuring !== 'Do Not Repeat' ||
        reoccuring !== '' ||
        reoccuring !== null) &&
      !eventObj?.events[0]?.commonId
    ) {
      setIsFormLoading(true);
      await client
        .post(`/event/CreatePublicEvents/${bespokeId}`, eventObj?.events[0])
        .then((res) => {
          dispatch(setDeleteEvent(eventObj?.events[0]?.id));
          deleteEvent(eventObj?.events[0]?.id);
          dispatch(
            setCalendarEvent({ ...eventObj, events: [{ ...res?.data }] })
          );
          dispatch(setShowEventModel(false));
          setIsFormLoading(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.Message ?? err?.message);
          setIsFormLoading(false);
        });
    } else {
      // Call popup  in Event Model
      handleUpdatePopup();
      setNewEventObject(eventObj);
    }
  };

  // Image upoad for Event Form
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = async () => {
        // setLoading(true);
        // Send Content to server
        const binaryStr = reader.result;
        var i = new Image();
        i.onload = function () {
          setAspect(seizer(i.width, i.height));
          if (i.width < 1300 && i.height < 1300) {
            setCrop({
              unit: '%', // Can be 'px' or '%'
              x: 0,
              y: 0,
              width: 100,
              height: 100,
            });
          } else {
            setCrop(
              centerAspectCrop(i.width, i.height, seizer(i.width, i.height))
            );
          }
        };

        i.src = binaryStr;
        setTemImg(binaryStr);
        setCrop(undefined); // Set an immediate crop region
        handleClickOpen();
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    // open,
  } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.png'],
    },
  });

  // Image Function
  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 100,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }
  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
    }
  }

  // Upload image
  const uploadCover = async () => {
    setLoading(true);
    if (completedCrop?.width && completedCrop?.height && imgRef.current) {
      const canvas = document.createElement('canvas');
      canvasPreview(imgRef.current, canvas, completedCrop);

      const fileext = extractImageFileExtensionFromBase64(temImg);
      const imageData64 = canvas.toDataURL('image/' + fileext);

      canvas.toBlob(
        async function (blob) {
          await client
            .post('/Souvenir/image-upload', imageData64)
            .then((res) => {
              setImage(res?.data?.picture);
              setLoading(false);
              handleClose();
            })
            .catch((e) => {
              console.log(e);
              toast.error(
                'Image upload fail, Please try again with a smaller Image file'
              );
              setLoading(false);
              handleClose();
            });
        },
        'image/jpeg',
        0.5
      );
    } else {
      console.log('error');
      // toast.error("");
    }
  };

  return (
    <>
      <form className="flex flex-col" onSubmit={(e) => handleSubmit(e)}>
        <input
          type="text"
          value={id}
          placeholder="no id"
          onChange={() => {}}
          className="hideInput"
          hidden
        />
        <input
          type="text"
          value={repeatedId}
          placeholder="no "
          onChange={() => {}}
          className="hideCommonInput"
          hidden
        />

        <input
          type="text"
          value={startDate}
          placeholder="date"
          onChange={() => {}}
          className="startDate"
          hidden
        />
        {!formLoading ? (
          <>
            {draft?.templateId === '2' || draft?.templateId === 2 ? (
              <>
                <div className="flex flex-col gap-6 color-contain">
                  <p className="text-lg">{t('Select color label')}</p>
                  <div className="flex items-center justify-center gap-3">
                    {draft?.customColors.map(({ color, customId, label }) => (
                      <div
                        style={{ backgroundColor: color }}
                        key={customId}
                        className="flex colors"
                        typeof="button"
                        onClick={() => {
                          setColor(color);
                          setTitle(label);
                          setCustomId(customId);
                        }}
                      >
                        {color === colored && <FiCheck />}
                      </div>
                    ))}
                  </div>
                </div>
                {isOpenReoccur && (
                  <div
                    className="backdrop"
                    onClick={() => setIsOpenReOccur(false)}
                  ></div>
                )}

                <input
                  type="text"
                  name="event-title"
                  value={
                    draft?.templateId === '2' || draft?.templateId === 2
                      ? customColorState?.customColors[customId]?.label
                      : title
                  }
                  placeholder={t('color label')}
                  readOnly={true}
                  required
                />

                <div className="time-date flex">
                  <FiClock />
                  <div className="time-date-input flex flex-col">
                    <section className="date-intrval">
                      <div>
                        <label>{t('Start date:')}</label>
                        <DatePicker
                          value={startDate}
                          onChange={(e) => {
                            setStartDate(e);
                            setEndDate(e);
                          }}
                        />
                      </div>
                      {/*  End Date */}
                      <div>
                        <label>{t('End date:')}</label>
                        <DatePicker
                          value={endDate}
                          minDate={startDate}
                          onChange={setEndDate}
                        />
                      </div>
                      {showMonth && <MonthModal dateClicked={dateClicked} />}
                    </section>
                    <div className="time-container flex ">
                      <TimePicker
                        value={firstTime}
                        onChange={(e) => {
                          setFirstTime(e);
                          if (
                            new Date(startDate).getTime() ==
                            new Date(endDate).getTime()
                          ) {
                            setLastTime(dayjs(e).add(1, 'hour'));
                          } else {
                            setLastTime(
                              dayjs(endDate)
                                .set('hour', dayjs(new Date(e)).get('h') + 1)
                                .set(
                                  'minute',
                                  dayjs(new Date(e)).get('minutes')
                                )
                                .set('second', dayjs(new Date(e)).get('second'))
                            );
                          }
                        }}
                      />
                      <div>-</div>
                      <TimePicker
                        value={lastTime}
                        onChange={(e) => {
                          setLastTime(e);
                        }}
                      />
                      {showTime && <TimeModal timeClicked={timeClicked} />}
                    </div>
                  </div>
                </div>

                <div className="meeting-link location">
                  <LocationMark location={location} setLocation={setLocation} />
                </div>

                <div className="flex justify-start items-center space-x-3">
                  <NotificationsNoneIcon sx={{ fontSize: '25px' }} />

                  <p
                    onClick={() => {
                      setOpenReminder(!openReminder);
                    }}
                    className="cursor-pointer hover:bg-gray-100 py-1.5 px-1 w-full"
                  >
                    <span>{t('Select Reminder(s)')}</span>
                  </p>
                </div>

                {timePicked &&
                  timePicked.map((item, id) => (
                    <div
                      className="flex justify-between items-center px-9"
                      key={id}
                    >
                      <p className="">{`${item.value} ${t(item.name)}`}</p>
                      <p
                        onClick={() => {
                          let newTime = timePicked.filter(
                            (time) =>
                              `${time?.name} ${time?.inMinutes}` !==
                              `${item?.name} ${item?.inMinutes}`
                          );
                          setTimePicked([...newTime]);
                          setEventDefaultReminder([...newTime]);
                        }}
                        className="cursor-pointer"
                      >
                        x
                      </p>
                    </div>
                  ))}

                {openReminder && (
                  <div>
                    {reminders
                      .filter((item) => item?.selected == false)
                      .map((item, index) => (
                        <div key={index}>
                          {item.name === 'Custom' ? (
                            <div
                              onClick={() => {
                                setCustomOpen(!customOpen);
                              }}
                              key={id}
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 10,
                                padding: 8,
                              }}
                            >
                              {/* <Checkbox checked={item.selected}/> */}
                              <p
                                style={{
                                  fontSize: 16,
                                  fontWeight: '300',
                                  padding: 2,
                                }}
                                className="hover:text-[#2667B1] hover:cursor-pointer"
                              >
                                {' '}
                                {t(item.name)}
                              </p>
                            </div>
                          ) : (
                            <button
                              onClick={() => {
                                draft?.calendarPlan === 'Basic' &&
                                timePicked.length > 0
                                  ? (toast.warning(
                                      'You can have only one(1) reminder for Basic plan'
                                    ),
                                    setOpenReminder(
                                      false
                                    )) /*Stop event modalfrom closing, when toasted*/
                                  : draft?.calendarPlan === 'Pro' &&
                                    timePicked.length > 4
                                  ? (toast.warning(
                                      'You can have only five(5) reminder for Pro plan'
                                    ),
                                    setOpenReminder(false))
                                  : (setTimePicked((current) => [
                                      ...current,
                                      item,
                                    ]),
                                    setOpenReminder(false),
                                    setEventDefaultReminder([
                                      ...timePicked,
                                      item,
                                    ]),
                                    item.name == 'Custom' &&
                                      setCustomOpen(true));
                              }}
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 10,
                                padding: 8,
                              }}
                              disabled={checked}
                            >
                              {/* <Checkbox checked={item.selected}/> */}
                              <p
                                style={{
                                  fontSize: 16,
                                  fontWeight: '300',
                                }}
                                className="hover:text-[#2667B1] hover:cursor-pointer "
                              >
                                {' '}
                                {/* {item.value} {item.name} before */}
                                {item.name === 'Custom'
                                  ? t(item.name)
                                  : `${item.value} ${t(item.name)} ${t(
                                      'before'
                                    )}`}
                              </p>
                            </button>
                          )}
                        </div>
                      ))}

                    {customOpen && (
                      <div className="content-start">
                        <div>
                          <input
                            type="number"
                            className="peer block min-h-[auto] w-full border-2 bg-transparent px-3 py-[0.32rem] "
                            id="exampleFormControlInputNumber"
                            placeholder="Type a value"
                            onChange={inputChange}
                            required
                          />
                          {!numberPicked && (
                            <span className="text-red text-xs text-center flex self-center ml-8 pt-2">
                              {t('Please add a value here')}
                            </span>
                          )}
                        </div>
                        <ul className="w-44 mt-4 text-sm font-medium text-gray-900 bg-white">
                          <li className="w-full">
                            <div className="flex items-center">
                              <input
                                id="minutes"
                                type="radio"
                                value="minutes"
                                name="list-radio"
                                className="w-4 h-4 bg-gray-100"
                                onChange={optionChange}
                              />
                              <label
                                htmlFor="minutes"
                                className="w-full py-3  text-sm font-medium dark:text-gray-900"
                              >
                                {t('Minutes before')}{' '}
                              </label>
                            </div>
                          </li>
                          <li className="w-full">
                            <div className="flex items-center ">
                              <input
                                id="hours"
                                type="radio"
                                value="hours"
                                name="list-radio"
                                className="w-4 h-4 bg-gray-100"
                                onChange={optionChange}
                                required
                              />
                              <label
                                for="hours"
                                className="w-full py-3 text-sm font-medium dark:text-gray-900"
                              >
                                {t('Hours')}
                              </label>
                            </div>
                          </li>
                          <li className="w-full dark:border-gray-600">
                            <div className="flex items-center ">
                              <input
                                id="days"
                                type="radio"
                                value="days"
                                name="list-radio"
                                className="w-4 h-4 bg-gray-100"
                                onChange={optionChange}
                              />
                              <label
                                for="days"
                                className="w-full py-3  text-sm font-medium dark:text-gray-900"
                              >
                                {t('Days')}
                              </label>
                            </div>
                          </li>
                          <li className="w-full  dark:border-gray-600">
                            <div className="flex items-center ">
                              <input
                                id="weeks"
                                type="radio"
                                value="weeks"
                                name="list-radio"
                                className="w-4 h-4 bg-gray-100"
                                onChange={optionChange}
                              />
                              <label
                                for="weeks"
                                className="w-full py-3 text-sm font-medium dark:text-gray-900"
                              >
                                {t('Weeks')}
                              </label>
                            </div>
                          </li>
                        </ul>
                        <button
                          type="button"
                          className="text-white bg-blue-900 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 pt-1 pb-2 ml-8 mt-2 dark:bg-blue-900 dark:hover:bg-blue-900 focus:outline-none dark:focus:ring-blue-900"
                          onClick={() => {
                            draft?.calendarPlan === 'Basic' &&
                            timePicked.length > 0
                              ? (toast.warning(
                                  'You can have only one(1) reminder for Basic plan'
                                ),
                                setOpenReminder(
                                  false
                                )) /*Stop event modalfrom closing, when toasted*/
                              : draft?.calendarPlan === 'Pro' &&
                                timePicked.length > 4
                              ? (toast.warning(
                                  'You can have only five(5) reminder for Pro plan'
                                ),
                                setOpenReminder(false))
                              : (numberPicked !== ''
                                  ? calculateCustom(numberPicked, customPick)
                                  : '',
                                setNumberPicked(''));
                          }}
                          disabled={inputError}
                        >
                          {t('Set Custom')}
                        </button>
                      </div>
                    )}
                    <div className="flex items-center justify-start ">
                      <p className="w-4 h-4 ml-2">
                        <input
                          id="default-checkbox"
                          type="checkbox"
                          value=""
                          checked={checked}
                          onChange={handleCheckChange}
                          className="ml-4 text-[#2667B1] bg-gray-100 border-2 border-gray-300 rounded   dark:bg-gray-700 dark:border-gray-600  "
                        />
                      </p>
                      <label
                        for="default-checkbox"
                        className=" pt-2 text-sm font-medium text-black dark:text-gray-500"
                      >
                        {t('Turn Off Reminder')}
                      </label>
                    </div>
                  </div>
                )}

                <div className="reoccuring_container">
                  <BsArrowRepeat />

                  <div className="reoccur">
                    <div
                      className="selcted_reoccur"
                      onClick={() => setIsOpenReOccur(true)}
                    >
                      <p>
                        {reoccuring === null ||
                        reoccuring === '' ||
                        reoccuring === 'Do Not Repeat'
                          ? t('Do Not Repeat')
                          : reoccuring === 'forthNight'
                          ? t('Fortnight')
                          : t(
                              reoccuring.charAt(0).toUpperCase() +
                                reoccuring.slice(1)
                            )}
                      </p>
                      <BiChevronDown />
                    </div>
                    {isOpenReoccur && (
                      <div className="reoccur_drop_down">
                        <span
                          onClick={() => {
                            setReoccuring('Do Not Repeat');
                            setIsOpenReOccur(false);
                          }}
                        >
                          {t('Do Not Repeat')}
                        </span>
                        <span
                          onClick={() => {
                            setReoccuring('Daily');
                            setIsOpenReOccur(false);
                          }}
                        >
                          {t('Daily')}
                        </span>
                        <span
                          onClick={() => {
                            setReoccuring('Weekly');
                            setIsOpenReOccur(false);
                          }}
                        >
                          {t('Weekly')}
                        </span>
                        <span
                          onClick={() => {
                            setReoccuring('Fortnight');
                            setIsOpenReOccur(false);
                          }}
                        >
                          {t('Fortnight')}
                        </span>
                        <span
                          onClick={() => {
                            setReoccuring('Monthly');
                            setIsOpenReOccur(false);
                          }}
                        >
                          {t('Monthly')}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                {isOpenReoccur && (
                  <div
                    className="backdrop"
                    onClick={() => setIsOpenReOccur(false)}
                  ></div>
                )}

                <input
                  type="text"
                  name="event-title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder={t('Add Title')}
                  required
                />

                <div className="time-date flex">
                  <FiClock />
                  <div className="time-date-input flex flex-col">
                    <section className="date-intrval">
                      <div>
                        <label>{t('Start date:')}</label>
                        <DatePicker
                          value={startDate}
                          onChange={(e) => {
                            setStartDate(e);
                            setEndDate(e);
                          }}
                        />
                      </div>
                      {/*  End Date */}
                      <div>
                        <label>{t('End date:')}</label>
                        <DatePicker
                          value={endDate}
                          minDate={startDate}
                          onChange={setEndDate}
                        />
                      </div>
                      {showMonth && <MonthModal dateClicked={dateClicked} />}
                    </section>
                    <div className="time-container flex ">
                      <TimePicker
                        value={firstTime}
                        onChange={(e) => {
                          setFirstTime(e);
                          if (
                            new Date(startDate).getTime() ==
                            new Date(endDate).getTime()
                          ) {
                            setLastTime(dayjs(e).add(1, 'hour'));
                          } else {
                            setLastTime(
                              dayjs(endDate)
                                .set('hour', dayjs(new Date(e)).get('h') + 1)
                                .set(
                                  'minute',
                                  dayjs(new Date(e)).get('minutes')
                                )
                                .set('second', dayjs(new Date(e)).get('second'))
                            );
                          }
                        }}
                      />
                      <div>-</div>
                      <TimePicker
                        value={lastTime}
                        onChange={(e) => {
                          setLastTime(e);
                        }}
                      />
                      {showTime && <TimeModal timeClicked={timeClicked} />}
                    </div>
                  </div>
                </div>

                <div
                  className={`description flex ${
                    showEditor ? '' : 'a-center'
                  } `}
                >
                  <BiMenuAltLeft />
                  {!showEditor && (
                    <p
                      className={`t-sm  ${showEditor ? '' : 'hovDescription'}`}
                      typeof="button"
                      onClick={() => setShowEditor(true)}
                    >
                      {t('Add description')}
                    </p>
                  )}
                  {showEditor && (
                    <div>
                      <textarea
                        placeholder={t('Add description')}
                        className="text-area"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  )}
                </div>

                <div className="meeting-link">
                  <FiLink />
                  <input
                    type="text"
                    name="link"
                    placeholder={t('Optional meeting link')}
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                  />
                </div>

                <div className="meeting-link location">
                  <LocationMark location={location} setLocation={setLocation} />
                </div>

                <div className="flex justify-start items-center space-x-3">
                  <NotificationsNoneIcon sx={{ fontSize: '25px' }} />

                  <p
                    onClick={() => {
                      setOpenReminder(!openReminder);
                    }}
                    className="cursor-pointer hover:bg-gray-100 py-1.5 px-1 w-full"
                  >
                    <span>{t('Select Reminder(s)')}</span>
                  </p>
                </div>

                {timePicked &&
                  timePicked.map((item, id) => (
                    <div
                      className="flex justify-between items-center px-9"
                      key={id}
                    >
                      <p className="">
                        {item.name === 'Event Time'
                          ? t(item.name)
                          : `${item.value} ${t(item.name)}`}
                      </p>
                      <p
                        onClick={() => {
                          let newTime = timePicked.filter(
                            (time) =>
                              `${time?.name} ${time?.inMinutes}` !==
                              `${item?.name} ${item?.inMinutes}`
                          );
                          setTimePicked([...newTime]);
                          setEventDefaultReminder([...newTime]);
                        }}
                        className="cursor-pointer"
                      >
                        x
                      </p>
                    </div>
                  ))}

                {openReminder && (
                  <div>
                    {reminders
                      .filter((item) => item?.selected == false)
                      .map((item, index) => (
                        <div key={index}>
                          {item.name === 'Custom' ? (
                            <div
                              onClick={() => {
                                setCustomOpen(!customOpen);
                              }}
                              key={id}
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 10,
                                padding: 8,
                              }}
                            >
                              {/* <Checkbox checked={item.selected}/> */}
                              <p
                                style={{
                                  fontSize: 16,
                                  fontWeight: '300',
                                  padding: 2,
                                }}
                                className="hover:text-[#2667B1] hover:cursor-pointer"
                              >
                                {' '}
                                {t(item.name)}
                              </p>
                            </div>
                          ) : (
                            <button
                              onClick={() => {
                                draft?.calendarPlan === 'Basic' &&
                                timePicked.length > 0
                                  ? (toast.warning(
                                      'You can have only one(1) reminder for Basic plan'
                                    ),
                                    setOpenReminder(
                                      false
                                    )) /*Stop event modalfrom closing, when toasted*/
                                  : draft?.calendarPlan === 'Pro' &&
                                    timePicked.length > 4
                                  ? (toast.warning(
                                      'You can have only five(5) reminder for Pro plan'
                                    ),
                                    setOpenReminder(false))
                                  : (setTimePicked((current) => [
                                      ...current,
                                      item,
                                    ]),
                                    setOpenReminder(false),
                                    setEventDefaultReminder([
                                      ...timePicked,
                                      item,
                                    ]),
                                    item.name == 'Custom' &&
                                      setCustomOpen(true));
                              }}
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 10,
                                padding: 8,
                              }}
                              disabled={checked}
                            >
                              {/* <Checkbox checked={item.selected}/> */}
                              <p
                                style={{
                                  fontSize: 16,
                                  fontWeight: '300',
                                }}
                                className="hover:text-[#2667B1] hover:cursor-pointer "
                              >
                                {' '}
                                {/* {item.value} {item.name} before */}
                                {item.name === 'Custom' ||
                                item.name === 'Event Time'
                                  ? t(item.name)
                                  : `${item.value} ${t(item.name)} ${t(
                                      'before'
                                    )}`}
                              </p>
                            </button>
                          )}
                        </div>
                      ))}

                    {customOpen && (
                      <div className="content-start">
                        <div>
                          <input
                            type="number"
                            className="peer block min-h-[auto] w-full border-2 bg-transparent px-3 py-[0.32rem] "
                            id="exampleFormControlInputNumber"
                            placeholder="Type a value"
                            onChange={inputChange}
                            required
                          />
                          {!numberPicked && (
                            <span className="text-red text-xs text-center flex self-center ml-8 pt-2">
                              {t('Please add a value here')}
                            </span>
                          )}
                        </div>
                        <ul className="w-44 mt-4 text-sm font-medium text-gray-900 bg-white">
                          <li className="w-full">
                            <div className="flex items-center">
                              <input
                                id="minutes"
                                type="radio"
                                value="minutes"
                                name="list-radio"
                                className="w-4 h-4 bg-gray-100"
                                onChange={optionChange}
                              />
                              <label
                                htmlFor="minutes"
                                className="w-full py-3  text-sm font-medium dark:text-gray-900"
                              >
                                {t('Minutes before')}{' '}
                              </label>
                            </div>
                          </li>
                          <li className="w-full">
                            <div className="flex items-center ">
                              <input
                                id="hours"
                                type="radio"
                                value="hours"
                                name="list-radio"
                                className="w-4 h-4 bg-gray-100"
                                onChange={optionChange}
                                required
                              />
                              <label
                                for="hours"
                                className="w-full py-3 text-sm font-medium dark:text-gray-900"
                              >
                                {t('Hours')}
                              </label>
                            </div>
                          </li>
                          <li className="w-full dark:border-gray-600">
                            <div className="flex items-center ">
                              <input
                                id="days"
                                type="radio"
                                value="days"
                                name="list-radio"
                                className="w-4 h-4 bg-gray-100"
                                onChange={optionChange}
                              />
                              <label
                                for="days"
                                className="w-full py-3  text-sm font-medium dark:text-gray-900"
                              >
                                {t('Days')}
                              </label>
                            </div>
                          </li>
                          <li className="w-full  dark:border-gray-600">
                            <div className="flex items-center ">
                              <input
                                id="weeks"
                                type="radio"
                                value="weeks"
                                name="list-radio"
                                className="w-4 h-4 bg-gray-100"
                                onChange={optionChange}
                              />
                              <label
                                for="weeks"
                                className="w-full py-3 text-sm font-medium dark:text-gray-900"
                              >
                                {t('Weeks')}
                              </label>
                            </div>
                          </li>
                        </ul>
                        <button
                          type="button"
                          className="text-white bg-blue-900 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 pt-1 pb-2 ml-8 mt-2 dark:bg-blue-900 dark:hover:bg-blue-900 focus:outline-none dark:focus:ring-blue-900"
                          onClick={() => {
                            draft?.calendarPlan === 'Basic' &&
                            timePicked.length > 0
                              ? (toast.warning(
                                  'You can have only one(1) reminder for Basic plan'
                                ),
                                setOpenReminder(
                                  false
                                )) /*Stop event modalfrom closing, when toasted*/
                              : draft?.calendarPlan === 'Pro' &&
                                timePicked.length > 4
                              ? (toast.warning(
                                  'You can have only five(5) reminder for Pro plan'
                                ),
                                setOpenReminder(false))
                              : (numberPicked !== ''
                                  ? calculateCustom(numberPicked, customPick)
                                  : '',
                                setNumberPicked(''));
                          }}
                          disabled={inputError}
                        >
                          {t('Set Custom')}
                        </button>
                      </div>
                    )}
                    <div className="flex items-center justify-start ">
                      <p className="w-4 h-4 ml-2">
                        <input
                          id="default-checkbox"
                          type="checkbox"
                          value=""
                          checked={checked}
                          onChange={handleCheckChange}
                          className="ml-4 text-[#2667B1] bg-gray-100 border-2 border-gray-300 rounded   dark:bg-gray-700 dark:border-gray-600  "
                        />
                      </p>
                      <label
                        for="default-checkbox"
                        className=" pt-2 text-sm font-medium text-black dark:text-gray-500"
                      >
                        {t('Turn Off Reminder')}
                      </label>
                    </div>
                  </div>
                )}

                <div className="reoccuring_container">
                  <BsArrowRepeat />

                  <div className="reoccur">
                    <div
                      className="selcted_reoccur"
                      onClick={() => setIsOpenReOccur(true)}
                    >
                      <p>
                        {reoccuring === null ||
                        reoccuring === '' ||
                        reoccuring === 'Do Not Repeat'
                          ? t('Do Not Repeat')
                          : reoccuring === 'forthNight'
                          ? t('Fortnight')
                          : t(
                              reoccuring.charAt(0).toUpperCase() +
                                reoccuring.slice(1)
                            )}
                      </p>
                      <BiChevronDown />
                    </div>
                    {isOpenReoccur && (
                      <div className="reoccur_drop_down">
                        <span
                          onClick={() => {
                            setReoccuring('Do Not Repeat');
                            setIsOpenReOccur(false);
                          }}
                        >
                          {t('Do Not Repeat')}
                        </span>
                        <span
                          onClick={() => {
                            setReoccuring('Daily');
                            setIsOpenReOccur(false);
                          }}
                        >
                          {t('Daily')}
                        </span>
                        <span
                          onClick={() => {
                            setReoccuring('Weekly');
                            setIsOpenReOccur(false);
                          }}
                        >
                          {t('Weekly')}
                        </span>
                        <span
                          onClick={() => {
                            setReoccuring('Fortnight');
                            setIsOpenReOccur(false);
                          }}
                        >
                          {t('Fortnight')}
                        </span>
                        <span
                          onClick={() => {
                            setReoccuring('Monthly');
                            setIsOpenReOccur(false);
                          }}
                        >
                          {t('Monthly')}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div></div>

                <div className="add-image flex a-center">
                  {/* IMAGE UPLOAD */}
                  {loading ? (
                    <div className={classes.loader}>
                      <Grid item xs={10} sm={10} md={8} lg={8}>
                        <Skeleton
                          variant="rect"
                          className={classes.loaderSkeleton}
                        />
                      </Grid>
                    </div>
                  ) : !image ? (
                    <Grid item xs={12} sm={12} md={12} lg={12} className="drop">
                      <div {...getRootProps()} className="drop-zone">
                        <input {...getInputProps()} />
                        {isDragAccept && (
                          <p>{t('Drop the month cover here...')}</p>
                        )}
                        {isDragReject && (
                          <p>{t('Month cover ought to be a png file')}</p>
                        )}
                        {!isDragActive && (
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            {t(
                              'Select or Drop your cover image for current event here'
                            )}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                  ) : (
                    <div className="preview" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Grid item xs={10} sm={10} md={8} lg={8} className="grid">
                        <LazyLoad height={'100%'} width={'100%'} offset={200}>
                          <img
                            src={image}
                            style={{
                              height: '100%',
                              width: '100%',
                              objectFit: 'cover',
                            }}
                          />
                        </LazyLoad>
                      </Grid>
                    </div>
                  )}
                </div>
                <div></div>

                <div className="meeting-link">
                  <FiLink />
                  <input
                    type="text"
                    name="vurl"
                    placeholder={t('Event video url')}
                    value={videoURL || ''}
                    onChange={(e) => {
                      setVideoURL(e.target.value);
                    }}
                  />
                </div>

                <span className="text-[12px] text-blue-400 pl-8 -mt-6">
                  Video used here will appear on your status
                </span>

                <div className="flex color-contain">
                  {colorArr.map(({ color, id }) => (
                    <div
                      style={{ backgroundColor: color }}
                      key={id}
                      className="flex colors"
                      typeof="button"
                      onClick={() => setColor(color)}
                    >
                      {color === colored && <FiCheck />}
                    </div>
                  ))}
                </div>
              </>
            )}

            <div className="button-container flex">
              {id !== '00000000-0000-0000-0000-000000000000' && (
                <ButtonColoured
                  content={t('Save')}
                  color={buttonBlueColor}
                  type="submit"
                  Type={'submit'}
                />
              )}
            </div>

            {/* IMage Crop Modal*/}
            <Dialog
              // fullScreen
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <Grid item md={12} lg={12}>
                <ReactCrop
                  style={{ width: '100%', height: '100%', overflowY: 'scroll' }}
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  aspect={aspect}
                  locked={true}
                  onComplete={(c) => setCompletedCrop(c)}
                  className="react_crop"
                  // disabled={true}
                >
                  <img
                    src={temImg}
                    loading="lazy"
                    style={{ width: '100%', height: '100%' }}
                    ref={imgRef}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              </Grid>

              <section
                className={classes.floatBtn}
                onClick={() => uploadCover()}
              >
                {loading ? (
                  <RotatingLines
                    strokeColor="#fff"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="20"
                    visible={loading}
                  />
                ) : (
                  <BsFillCloudUploadFill className={classes.floatBtnSvg} />
                )}
              </section>
            </Dialog>
          </>
        ) : (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.loader}
          >
            <Grid item xs={2} md={2} sm={2} lg={2}>
              <BallTriangle
                height={'100%'}
                width={'100%'}
                radius={5}
                color="#2667b1"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            </Grid>
          </Grid>
        )}
      </form>
    </>
  );
};

export default EventForm;
