import React from 'react';
import BlueStar from '../../assest/images/BlueStar.svg';
import { useTranslation } from 'react-i18next';

const GridPitchComponent = ({ showBottomStar, title, content }) => {
  const { t } = useTranslation(['grid-pitch']);
  return (
    <div className="section-item flex column">
      <h3>{t(title)}</h3>

      <p>{t(content)}</p>

      <div className="top_left_star">
        <img src={BlueStar} alt="star icon" />
      </div>
      <div className="top_right_star">
        <img src={BlueStar} alt="star icon" />
      </div>

      {showBottomStar && (
        <>
          <div className="bottom_left_star">
            <img src={BlueStar} alt="star icon" />
          </div>
          <div className="bottom_right_star">
            <img src={BlueStar} alt="star icon" />
          </div>
        </>
      )}
    </div>
  );
};

export default GridPitchComponent;
